import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Divider from '@mui/material/Divider';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {Globals} from '../../Context';
import classNames from "classnames";
import {NavLink, useNavigate} from "react-router-dom";
import Tooltip from '@mui/material/Tooltip'
import MarketPlaceView from './MarketPlaceView'
import CloseIcon from '@mui/icons-material/Close';
import head from 'lodash/head';

const popularModels = [
	{ name: 'Rolex Datejust 41', image: '/Images/MarketPlace/popular-models_1.jpeg', brand: 'Rolex', series: 'Datejust'},
	{ name: 'Rolex Submariner', image: '/Images/MarketPlace/popular-models_2.jpeg', brand: 'Rolex', series: 'Submariner'},
	{ name: 'Rolex Submariner', image: '/Images/MarketPlace/popular-models_3.jpeg', brand: 'Rolex', series: 'Submariner'},
	{ name: 'Rolex Day-Date', image: '/Images/MarketPlace/popular-models_4.jpeg', brand: 'Rolex', series: 'Day-Date'},
	{ name: 'Rolex Daytona', image: '/Images/MarketPlace/popular-models_5.jpeg', brand: 'Rolex', series: 'Daytona'},
	{ name: 'Rolex Datejusr 36', image: '/Images/MarketPlace/popular-models_6.jpeg', brand: 'Rolex', series: 'Datejust'},
	{ name: 'AP Royal Oak', image: '/Images/MarketPlace/popular-models_7.jpeg', brand: 'Audemars Piguet', series: 'Royal Oak'},
	{ name: 'AP Royal Oak Offshore', image: '/Images/MarketPlace/popular-models_8.jpeg', brand: 'Audemars Piguet', series: 'Royal Oak Offshore'},
	{ name: 'Patek Nautilus', image: '/Images/MarketPlace/popular-models_9.jpeg', brand: 'Patek', series: 'Nautilus'},
	{ name: 'Patek Aquanaut', image: '/Images/MarketPlace/popular-models_10.jpeg', brand: 'Patek', series: 'Aquanaut'},
	{ name: 'Rolex SkyDweller', image: '/Images/MarketPlace/popular-models_11.jpeg', brand: 'Rolex', series: 'SkyDweller'},
	{ name: 'Rolex Oyster Perpetual', image: '/Images/MarketPlace/popular-models_12.jpeg', brand: 'Rolex', series: 'Oyster Perpetual'},
  ];

  const brandsNameDefault = [
	{ name: 'Rolex', image: '/Images/MarketPlace/rolex.png' },
	{ name: 'Audemars Piguet', image: '/Images/MarketPlace/ap.png' },
	{ name: 'Patek Philippe', image: '/Images/MarketPlace/patek.png' },
	{ name: 'Omega', image: '/Images/MarketPlace/omega.png' },
  ];

const MarketPlaceHome = ({
	handleProductCount,
	goToViewMarketPlace,
	marketFilter,
	addProductCount,
	homePageIdx,
	handleDisableInputSearch,
	setMarketplaceFilters,
	marketplaceFilters,
	activeTab,
	filterDataSelected,
	handleSetCurrentDropdownFilter,
	currentDropdownFilter,
	onChangeFilterData,
	filterPriorities,
	isPresentFilterDataSelected}) => {
    const [brandsList, setBrandsList] = useState([])
    const [newArrivalsWatch, setNewArrivalsWatch] = useState([])
    const [recentlyWatches, setRecentlyWatches] = useState([])
	const [isAtBeginning, setIsAtBeginning] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
        setNewArrivalsWatch([]);
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homePageIdx]);

	const groupBrandsWithLetter = useCallback((brands) => {
        const groupedBrands = brands.reduce((acc, brand) => {
            const letter = brand.charAt(0).toUpperCase()
            if (!acc[letter]) {
                acc[letter] = []
            }

            acc[letter].push(brand)
            return acc
        }, {})
        return groupedBrands
    }, [])

	const fetchData = useCallback(async () => {
		try {
			const response = await Globals.New_Axios().get(
				`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/marketplaces/dashboard`
			)

			const {brands, newest_watches, recently_viewed } = response.data
			brands && setBrandsList(groupBrandsWithLetter(brands))
			newest_watches && setNewArrivalsWatch(newest_watches)
			recently_viewed && setRecentlyWatches(recently_viewed)

		} catch (error) {
			console.log(error)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

    const handleLetterClick = (letter) => {
        const element = document.getElementById(letter)

        element?.scrollIntoView({
            behavior: 'smooth',
			block: 'start'
        })
    }

	const handleContactForPriceNavigation = (data, event) => {
		event.preventDefault()

		trackingUserAction('contact_for_price', data.id)

		navigate(`/messages/reachout/${data.id}/price`)
	}

	const trackingUserAction = (action, itemId) => {
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/marketplace_trackings`, {
				action: action,
				watch_id: itemId,
			})
			.then(response => {
			})
			.catch(error => {
			})
	}

	const handleClearAllFilter = useCallback(() => {
		setMarketplaceFilters(
			{
				filterDataSelected:
					{
						brand: [],
						reference_number: [],
						series: [],
						condition: [],
						box_papers: [],
						dial: [],
						bracelet: [],
						wholesale_price: [],
					},
				filterPriorities: [],
				specialFilters: {
					type: null,
					title: null,
					watchId: null,
					spaceId: null,
				},
				searchKeywords: '',
				unwornPreOwned: null,
				papers: false,
				box: false,
			}
		)
    }, [marketplaceFilters])

    const alphabet = useMemo(() => {
        return Array.from({ length: 26 }, (_, i) => String.fromCharCode('A'.charCodeAt(0) + i));
    }, [])

    const alphabetForBrands = useMemo(() => {
        return alphabet.map((letter) => {
            return {
                text: letter,
                exist: letter in brandsList
            }
        })
    }, [brandsList, alphabet])

	const handleSelectedItem = (item) => {
		let specialFilters = {
			type: item.type
		}

		if (item.type === 'brand') {
			specialFilters['title'] = item.name
			specialFilters['brand'] = item.name
		} else if (item.type === 'newArrivals') {
			specialFilters['title'] = item.name
			specialFilters['watchId'] = item.watchId
		} else if (item.type === 'popular') {
			specialFilters['title'] = item.name
			specialFilters['brand'] = item.brand
			specialFilters['series'] = item.series
		}

		setMarketplaceFilters({specialFilters})
	}

	const visibleFields = (data) => {
		return data.space.global_watch_column_configs.filter(item => item.visible).map(item => item.key)
	}

	const handleResetWindow = useCallback(() => {
		setMarketplaceFilters({
			specialFilters: {
				type: null
			}
		})
		setIsAtBeginning(true)
	}, []);

    return (
		<React.Fragment>
			{!marketplaceFilters.specialFilters.type && (
				<div className="px-[50px] pb-[40px] overflow-y-auto overflow-x-hidden bg-white">
					{/* Shop By Brand */}
					<div className="mt-[40px]">
						<h3 className="text-[24px] font-bold">Shop By Brand</h3>
						<Divider className="my-4"/>
						<div className="flex flex-wrap justify-between items-center gap-4 my-10">
							{brandsNameDefault.map((brand, index) => (
								<div
                                    key={index}
                                    className="flex flex-col items-center cursor-pointer"
                                    onClick={() => handleSelectedItem({name: brand.name, type: 'brand'})}
                                >
									<img src={brand.image} alt={brand.name} className="max-w-[200px] max-h-[200px] object-contain" />
								</div>
							))}
							<div
								className="cursor-pointer flex flex-col items-center max-w-200 max-h-200 text-[#5D6E81] text-[25px] font-bold mr-[40px]"
								onClick={() => setMarketplaceFilters({specialFilters: {type: 'allBrands'}})}
							>
                                <span>See All</span>
								 <p>Brands</p>
							</div>
						</div>
					</div>
					{/* New Arrivals */}
					<div className="">
						<h3 className="text-[24px] font-bold">New Arrivals</h3>
						<Divider className="my-4 h-1" />
						<div className="flex justify-between items-center gap-x-[70px] mt-[37px] px-8 relative">
							<Swiper
								navigation={{
									nextEl: '.next-swiper-new-arrival',
									prevEl: '.prev-swiper-new-arrival',
								}}
								modules={[Navigation]}
								className="mySwiper"
								autoHeight={true}
								slidesPerView={5}
								breakpoints={{
									320: {
										slidesPerView: 1,
										spaceBetween: 10,
									},
									640: {
										slidesPerView: 2,
										spaceBetween: 20,
									},
									768: {
										slidesPerView: 3,
										spaceBetween: 40,
									},
									1024: {
										slidesPerView: 3,
										spaceBetween: 50,
									},
									1280: {
										slidesPerView: 5,
										spaceBetween: 60,
									},
                                    1440: {
                                        slidesPerView: 5,
                                        spaceBetween: 70,
                                    }
								}}
								onSlideChange={(swiper) => {
									setIsAtBeginning(swiper.isBeginning);
								}}
							>
								{newArrivalsWatch.map((watch, index) => (
									<SwiperSlide key={index} onClick={() => handleSelectedItem({ name: 'New Arrivals', type: 'newArrivals', watchId: watch.id })}>
									<div className="flex flex-col cursor-pointer max-w-[230px]">
									  <img
										src={head(watch.images) !== undefined ? `${head(watch.images).url}` : `/watch_icon.png`}
										alt={watch.name}
										className="w-[173px] mx-auto h-[179px] max-w-[173px] max-h-[179px] object-contain"
									  />
									  <div className="flex-grow mt-[27px]">
										<p className="text-[#5D6E81] text-[10px] font-normal line-clamp-4 h-[45px]">
										  {watch.marketplace_name}
										</p>
									  </div>

									  {visibleFields(watch).includes('wholesale_price') && (
										<div className="text-[#5D6E81] text-[12px] font-bold mt-[4px]">
											{!!watch.wholesale_price ? (
											`$${Globals.readCurrencyNumber(watch.wholesale_price)}`
											) : (
											<Tooltip placement="left-start" arrow>
												<div className="mt-1 font-bold text-center">
													<NavLink
														to={`/messages/reachout/${watch.id}/price`}
														className="align-text-top text-blue-500 text-xs"
														onClick={(e) => handleContactForPriceNavigation(watch, e)}
													>
														Contact for price
													</NavLink>
												</div>
											</Tooltip>
											)}
										</div>
									  )}
									</div>
								  </SwiperSlide>
								))}
							</Swiper>
							<div className="next-swiper-new-arrival absolute top-[76px] right-0 cursor-pointer">
								<ArrowForwardIosIcon sx={{width: '28px', height: '26px'}} />
							</div>
							<div
								style={{ display: isAtBeginning ? 'none' : 'block' }} className="prev-swiper-new-arrival absolute top-[76px] left-0 cursor-pointer"
							>
								<ArrowBackIosIcon sx={{width: '28px', height: '26px'}} />
							</div>
						</div>
					</div>
					{/* Popular Models */}
					<div className="mt-[40px]">
						<h3 className="text-[24px] font-bold">Popular Models</h3>
						<Divider className="my-4 h-1" />
						<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-y-[15px] sm:gap-y-[30px] lg:gap-y-[54px] mt-[48px]">
							{popularModels.map((item, index) => (
								<div
									key={index}
									onClick={() => handleSelectedItem({name: item.name, brand: item.brand, series: item.series, type: 'popular'})} className="flex flex-col items-center cursor-pointer"
								>
									<img src={item.image} alt={item.name} className="w-[173px] h-[179px] max-w-[173px] max-h[179px] object-contain" />
									<p className="text-[#5D6E81] text-[12px] font-bold mt-[18px] text-center">
										{item.name.split(" ").map((word, idx) => (
											<React.Fragment key={idx}>
												{idx === 0 ? <span style={{ display: 'block' }}>{word}</span> : word}
												{idx !== item.name.split(" ").length - 1 ? ' ' : ''}
											</React.Fragment>
										))}
									</p>
								</div>
							))}
						</div>
					</div>

					{/* Recently Viewed */}
					<div className="mt-[40px]">
						<h3 className="text-[24px] font-bold">Recently Viewed</h3>
						<Divider className="my-4 h-1" />
						<div className="flex justify-between items-center gap-x-[70px] mt-[37px] px-8 relative">
							<Swiper
								navigation={{
									nextEl: '.next-swiper-new-arrival',
									prevEl: '.prev-swiper-new-arrival',
								}}
								modules={[Navigation]}
								className="mySwiper"
								autoHeight={true}
								slidesPerView={5}
								breakpoints={{
									320: {
										slidesPerView: 1,
										spaceBetween: 10,
									},
									640: {
										slidesPerView: 2,
										spaceBetween: 20,
									},
									768: {
										slidesPerView: 3,
										spaceBetween: 40,
									},
									1024: {
										slidesPerView: 3,
										spaceBetween: 50,
									},
									1280: {
										slidesPerView: 5,
										spaceBetween: 60,
									},
                                    1440: {
                                        slidesPerView: 5,
                                        spaceBetween: 70,
                                    }
								}}
								onSlideChange={(swiper) => {
									setIsAtBeginning(swiper.isBeginning);
								}}
							>
								{recentlyWatches.map((watch, index) => (
									<SwiperSlide key={index} onClick={() => handleSelectedItem({ name: 'New Arrivals', type: 'newArrivals', watchId: watch.id })}>
										<div className="flex flex-col cursor-pointer max-w-[230px]">
											<img
												src={head(watch.images) !== undefined ? `${head(watch.images).url}` : `/watch_icon.png`}
												alt={watch.name}
												className="w-[173px] mx-auto h-[179px] max-w-[173px] max-h-[179px] object-contain"
											/>
											<div className="flex-grow mt-[27px]">
												<p className="text-[#5D6E81] text-[10px] font-normal line-clamp-4 h-[45px]">
												{watch.marketplace_name}
												</p>
											</div>
											{
												visibleFields(watch).includes('wholesale_price') && (
													<div className="text-[#5D6E81] text-[12px] font-bold mt-[4px]">
														{!!watch.wholesale_price ? (
														`$${Globals.readCurrencyNumber(watch.wholesale_price)}`
														) : (
														<Tooltip placement="left-start" arrow>
															<div className="mt-1 font-bold text-center">
																<NavLink
																	to={`/messages/reachout/${watch.id}/price`}
																	className="align-text-top text-blue-500 text-xs"
																	onClick={(e) => handleContactForPriceNavigation(watch, e)}
																>
																	Contact for price
																</NavLink>
															</div>
														</Tooltip>
														)}
													</div>
												)
											}
										</div>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
				</div>
			)}

			{marketplaceFilters.specialFilters.type === 'allBrands' && (
				<div className="px-[50px] overflow-y-auto overflow-x-hidden bg-white">
					<div className="mt-[40px]">
						<div className="mt-[40px]">
							<div className="flex justify-between items-center gap-4">
                            	<h3 className="text-[24px] font-bold">Shop By Brand</h3>
								<CloseIcon className="cursor-pointer" onClick={() => handleResetWindow()} />
							</div>
                            <Divider className="my-4 h-1" />
                        </div>
						<Divider />
						<div className="flex flex-wrap bg-gray-200 text-[#000] py-2 px-4 rounded-[10px] mt-4 text-[18px] w-10/12 ml-4 mb-8">
							<span className="mr-4">All Brands: </span>
							<span className="flex flex-wrap items-center gap-x-5 text-[#000] ">
								{alphabetForBrands.map((letter, index) => (
									<div
										key={index}
										className={classNames('alphabet-item', {
											'text-[#000]': letter.exist,
											'text-[#5D6E81]': !letter.exist,
                                            'cursor-pointer': letter.exist,
										})}
										onClick={() => letter.exist && handleLetterClick(letter.text)}
									>
										{letter.text}
									</div>
								))}
								<div className="alphabet-item" onClick={() => handleLetterClick('x')}>
									#
								</div>
							</span>
						</div>

						<div className="justify-between items-center gap-4 mt-4">
							{brandsList && Object.keys(brandsList).length > 0 ? (
								Object.keys(brandsList).map((letter, index) => (
									<React.Fragment key={index}>
										<div key={index} className="cursor-pointer" id={letter}>
											<h3 className="text-[18px] font-bold text-[#000]">{letter}</h3>
											<div className="flex flex-wrap items-center">
												{brandsList[letter].map((brand, bIndex) => (
													<div
                                                        key={bIndex}
                                                        className="items-center cursor-pointer w-3/12"
                                                        onClick={() => handleSelectedItem({name: brand, type: 'brand'})}
                                                    >
														<p className="text-[#000] text-[16px] mt-4">{brand}</p>
													</div>
												))}
											</div>
										</div>
										<Divider sx={{my: 4, backgroundColor: '#000', borderBottomWidth: 1}} />
									</React.Fragment>
								))
							) : (
								<p>No brands available.</p>
							)}
						</div>
					</div>
				</div>
			)}

			{['brand', 'newArrivals', 'popular'].includes(marketplaceFilters.specialFilters.type) && (
				<MarketPlaceView
					marketplaceFilters={marketplaceFilters}
					handleProductCount={handleProductCount}
					marketFilter={marketFilter}
					addProductCount={addProductCount}
					setMarketplaceFilters={setMarketplaceFilters}
					renderByHome={true}
					goToViewMarketPlace={goToViewMarketPlace}
					handleDisableInputSearch={handleDisableInputSearch}
					activeTab={activeTab}
					filterDataSelected={filterDataSelected}
					handleSetCurrentDropdownFilter={handleSetCurrentDropdownFilter}
					currentDropdownFilter={currentDropdownFilter}
					onChangeFilterData={onChangeFilterData}
					filterPriorities={filterPriorities}
					isPresentFilterDataSelected={isPresentFilterDataSelected}
					handleClearAllFilter={handleClearAllFilter}
				/>
            )}
		</React.Fragment>
	)
}

export default MarketPlaceHome
