import React, {useMemo, useState} from 'react'
import Classnames from 'classnames'

const CustomDropdown = ({options, value, label, onOpen, onChange, mt, disabled}) => {
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

	const handleOpen = () => {
		setDropdownIsOpen(!dropdownIsOpen)
		if (onOpen) {
			onOpen()
		}
	}

	const handleSelect = option => {
		onChange(option)
		setDropdownIsOpen(false)
	}
	const buttonLabel = useMemo(() => {
		return options.find(option => option.value === value)?.label || label
	}, [ value, options])

	return (
		<div className="relative">
			<button
				className={Classnames(
					`flex items-center mt-[${mt || "24px"}] px-[12px] w-full h-[49px] inputLogin  !font-semibold text-[14px] border-[#D9E2EE] text-[#5D6E81] cursor-pointer`
				)}
				onClick={handleOpen}
				disabled={disabled}
			>
				{buttonLabel}
				<img className="absolute right-[12px] top-1/2 transform -translate-y-1/2 text-[#5D6E81] pointer-events-none" src="/Images/icon_dropdown.svg" alt="dropdown-icon" />
			</button>
			{dropdownIsOpen && (
				<ul className="absolute z-10 bg-white border border-[#D9E2EE] w-full text-[14px] rounded-[5px]" style={{boxShadow: '1px 1px 1px #9E9E9E'}}>
					{options.map(optionItem => (
						<li
							key={optionItem.value}
							onClick={() => handleSelect(optionItem)}
							className={Classnames('px-[12px] py-[8px] flex items-center justify-between cursor-pointer hover:bg-[#F1F5F9]', {'text-[#4B7CBE]': optionItem.value === value})}
						>
							{optionItem.label}
							{optionItem.value === value && (
								<svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
									<path d="M11.4725 0L13 1.56281L4.33333 10.3674L0 5.97611L1.53833 4.4243L4.33333 7.25278L11.4725 0Z" fill="#4B7CBE" />
								</svg>
							)}
						</li>
					))}
				</ul>
			)}
		</div>
	)
}

export default CustomDropdown
