import React, {useEffect, useState, useRef} from 'react'
import Classnames from 'classnames'
import {Globals} from '../../Context'
import Moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import {connect} from 'react-redux'

import ProductsHunter from './ProductsHunter'
import AddNewMiscProduct from './AddNewMiscProduct'
import {focusElement, onPressEscape} from '../../utils/keyboardHandler'
import AddressDropdown from '../Customers/AddressDropdown'
import UsersDropdown from "../Customers/UsersDropdown"
import { formatPhoneNumber } from '../Customers/AddNewCustomer'
import useOutsideClick from '../../hooks/useOutsideClick'
import ServerAutoSuggestAddress from '../ServerAutoSuggestAddress'
import CommentForm from './CommentForm'
import AddNoteForLineItem from './AddNoteForLineItem'

import isNaN from 'lodash/isNaN'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import isNil from 'lodash/isNil'
import uniqueId from 'lodash/uniqueId'
import isFunction from 'lodash/isFunction'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import remove from 'lodash/remove'
import assignIn from 'lodash/assignIn'
import sum from 'lodash/sum'
import upperFirst from 'lodash/upperFirst'

const initAddress = {
	visible: false,
	street: '',
	apt_unit_number: '',
	city: '',
	is_manual: false,
	state: '',
	country: '',
	zip_code: '',
	full_address: '',
	save_to_contact: false,
}

const getDefaultNewBillingAddress = (customer) => {
	return {
		...(customer?.customer_billing_addresses.find(address => address.is_billing_default) || {
		company_name: '',
		contact_name: '',
		phone_number: '+1'
		}),
		...initAddress,
		id: null
	};
	};

const getDefaultNewShippingAddress = (customer) => {
	return {
		...(customer?.customer_shipping_addresses.find(address => address.is_shipping_default) || {
		company_name: '',
		contact_name: '',
		phone_number: '+1'
		}),
		...initAddress,
		id: null
	};
};


const EditMemo = ({handleOpenEdit, memoData, props, updateMemoItem, ref, handleUpdateItemInInventory, handlePrimaryTab, currentSpace, loadedUsers}) => {
	const [memoDetails, setMemoDetails] = useState(memoData)
	const [checkedItems, setCheckedItems] = useState([])
	const [productsHunter, setProductsHunter] = useState(false)
	const [isCustomerLabeled, setIsCustomerLabeled] = useState(memoData.customer_label)
	const [memoedItems, setMemoedItems] = useState([])
	const [loadedProducts, setLoadedProducts] = useState()
	const [shipping, setShipping] = useState(memoData.shipping_price)
	const [removedItems, setRemovedItems] = useState([])
	const [miscItems, setMiscItems] = useState(memoData.misc_items)
	const [addNewMiscProduct, setAddNewMiscProduct] = useState(false)
	const [trackingNumber, setTrackingNumber] = useState('')
	const [isFlagTrackingNumber, setIsFlagTrackingNumber] = useState(false)
  const [openNoteModal, setOpenNoteModal] = useState(false)
  const [memoItemSelected, setMemoItemSelected] = useState(null)

	const [customer, setCustomer] =useState(memoData.customer)
	const [selectedShippingAddress, setSelectedShippingAddress] =useState(memoData.shipping_address)
	const [selectedBillingAddress, setSelectedBillingAddress] =useState(memoData.billing_address)
	const [newBillingAddress, setNewBillingAddress] = useState(getDefaultNewBillingAddress(customer))
	const [newShippingAddress, setNewShippingAddress] = useState(getDefaultNewShippingAddress(customer))
	const [selectedSalesPerson, setSelectedSalesPerson] = useState()
	const [selectedComment, setSelectedComment] = useState()
	const [loadedSpaceUsers, setLoadedSpaceUsers] = useState(loadedUsers || [])
	const [isVisibleComments, setIsVisibleComments] = useState(false)
	const [sendingComment, setSendingComment] = useState(false)
	const [loadingProduct, setLoadingProduct] = useState(false)
	const [memoComments, setMemoComments] = useState([])
	const [commentField, setCommentField] = useState({content: '', visible_on_pdf: false})
	const [isManualBillingAddress, setIsManualBillingAddress] = useState(false)
	const [isManualShippingAddress, setIsManualShippingAddress] = useState(false)
	const [billingAddress, setBillingAddress] = useState('')
	const [shippingAddress, setShippingAddress] = useState('')
	const [errors, setErrors] = useState([])

	const trackingNumberInputRef = useRef(null)

	useOutsideClick(trackingNumberInputRef, () => handleClickOutTrackingNumber());

	const loadProducts = keyword => {
		setLoadingProduct(true)
		const params = {
			kind: 'inventory',
			q: {
				g: {
					0: {
						status_in: 'on_hand'
					}
				},
			},
			per_page: 10000,
		}

		if (!!keyword) {
			params.q[Globals.watchSearchParams] = keyword
		}


		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches`, {
				params: params,
			})
			.then(response => {
				const items = memoedItems
				let loadeddProducts = response.data.watches.map(post => {
					post.inBag = items.map(item => item.memoable.id).includes(post.id)
					post.qty = 1

					return post
				})
				setLoadedProducts(loadeddProducts)
				setLoadingProduct(false)
			})
			.catch(error => {
				setLoadingProduct(false)
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const handleClose = () => {
		handleOpenEdit()
	}

	const handleShippingChange = e => {
		const shippingValue = Globals.formatCurrency(e.target.value)
		setShipping(!isNaN(shippingValue) ? shippingValue : 0)
	}

	const handleItemPriceChange = (e, memoItem) => {
		const newMemoedItems = memoedItems.map(item => {
			if (item.memoable.id === memoItem?.memoable?.id) {
				let nPrice = e.target.value.replaceAll(',', '')
				return {...item, price: nPrice}
			} else {
				return item
			}
		})

		setMemoedItems(newMemoedItems)
	}
	const handleRemoveItem = i => {
		let memoItems = memoedItems.map(item => {
			if (item.memoable.id === i.memoable.id) {
				return {...item, _destroy: true}
			} else {
				return item
			}
		})

		setMemoedItems(memoItems)
	}
	const handleOpenProductsHunter = () => {
		setProductsHunter(true)
	}
	const handleCloseProductsHunter = () => {
		setProductsHunter(false)
		focusElement('.single-memo-view-edit')
		setErrors([])
	}
	const handleAddingItemToMemo = item => {
		let memoItems = memoedItems
		if (!isEmpty(memoItems.filter(it => it.id === item.id))) return
		let memIt = {
			quantity: item.qty,
			memoable_id: item.id,
			price: item.wholesale_price,
			memoable_type: 'Watch',
			memoable: item,
			box: item.box,
		}
		memoItems.push(memIt)

		setMemoedItems(memoItems)
		setLoadedProducts(
			loadedProducts.map(product => {
				if (product.id === item.id) product.inBag = true
				return product
			})
		)
	}
	const handleRemovingItemToMemo = (item, $event) => {
		$event.stopPropagation()
		setLoadedProducts(
			loadedProducts.map(product => {
				if (product.id === item.id) product.inBag = false
				return product
			})
		)
		setMemoedItems(memoedItems.filter(product => product.memoable.id !== item.id))
	}
	const handleSearchProducts = debounce($event => {
		const keyword = $event.target.value

		if (isEmpty(keyword)) {
			//Pre load latest products.
			loadProducts()
		}

		if (keyword.length < 2) return

		loadProducts(keyword)
	}, 1000)

	const handleRemovingMiscItem = i => {
		setMiscItems(miscItems.filter((product, index) => index !== i))
	}

	const handleClickOutTrackingNumber = () => {
		if(productsHunter || addNewMiscProduct) return
		if(isFlagTrackingNumber) {
			handleSubmitTrackingNumber()
			setIsFlagTrackingNumber(false)
		}
	}

	const handleMemoMiscPriceChange = (i, e) => {
		const _price = e.target.value.replaceAll(',', '')

		const items = miscItems.map((product, index) => (index === i ? {...product, price: _price} : product))
		setMiscItems(items)
	}

	const hideAddNewMiscProduct = () => {
		setAddNewMiscProduct(false)
		focusElement('.single-memo-view-edit')
	}

	const handleMiscItemList = newItem => {
		let items = miscItems
		items.push(newItem)
		setMiscItems(items)
	}

	const toggleShipping = () => {
		setIsCustomerLabeled(prevState => !prevState)
		setShipping(0)
	}

	const saveMemo = () => {
		miscItems.forEach(item => {
			if (isNil(item.id)) {
				item.id = uniqueId('misc_')
			}
		})

    let updateShippingPriceForMemo = shipping
		if(isCustomerLabeled) {
			updateShippingPriceForMemo = 0
		}

		const memoParams = {
			customer_id: memoDetails.customer?.id,
			memo_items_attributes: memoedItems,
			misc_items: miscItems,
			shipping_price: updateShippingPriceForMemo,
			status: memoDetails.status,
			customer_label: isCustomerLabeled,
			salesperson_id: selectedSalesPerson?.id,
			tracking_number: trackingNumber,
		}

		if(selectedSalesPerson) {
			memoParams['salesperson_id'] = selectedSalesPerson.id
		} else {
			memoParams['salesperson_id'] = null
		}


		if (selectedBillingAddress) {
			memoParams.billing_address_id = selectedBillingAddress.id
		} else {
			memoParams.billing_address_attributes = {...newBillingAddress}
			if (newBillingAddress.phone_number === '+1') {
				memoParams.billing_address_attributes['phone_number'] = ''
			}

			memoParams.billing_address_attributes['in_contact'] = newBillingAddress.save_to_contact
			memoParams.billing_address_attributes['is_manual'] = isManualBillingAddress
			memoParams.billing_address_attributes['customer_id'] = customer.id
		}

		if (selectedShippingAddress) {
			memoParams.shipping_address_id = selectedShippingAddress.id
		} else {
			memoParams.shipping_address_attributes = {...newShippingAddress}
			if (newShippingAddress.phone_number === '+1') {
				memoParams.shipping_address_attributes['phone_number'] = ''
			}

			memoParams.shipping_address_attributes['in_contact'] = newShippingAddress.save_to_contact
			memoParams.shipping_address_attributes['is_manual'] = isManualShippingAddress
			memoParams.shipping_address_attributes['customer_id'] = customer.id
		}

		if(!!memoParams?.billing_address_attributes && !!memoParams?.shipping_address_attributes && !memoParams?.billing_address_attributes?.company_name && !memoParams?.shipping_address_attributes?.company_name && !memoParams?.billing_address_attributes.contact_name && !memoParams?.shipping_address_attributes?.contact_name) {
			return props.addToast({
				title: 'Error',
				text: 'Billing and Shipping addresses must include either a Company Name or a Contact Name.',
				type: 'error',
			})
		}

		if (isFunction(handlePrimaryTab)) handlePrimaryTab()

		Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos/${memoDetails.id}`, {
				memo: memoParams
			})
			.then(response => {
				// Change inventory status back to on hand after deleted a memo item
				const memoCloned = cloneDeep(response.data)
				memoCloned.memo_items = concat(memoCloned.memo_items, memoedItems.filter(item => item._destroy).map(item => {
					item.memoable.status = 'on_hand'
					item.memoable.status_details = null
					return item
				}))

				updateMemoItem(response.data)
				handleUpdateItemInInventory(memoCloned)
				handleOpenEdit()
				props.addToast({
					title: 'Success',
					text: 'Memo Updated Successfully',
					type: 'success',
				})
			}).catch((error) => {
				props.addToast({
					title: 'Error',
					text: error.response?.data?.message,
					type: 'error',
				})
			})
	}
	useState(() => {
		loadProducts()
	}, [])

	const handlePriceChange = (item, price) => {
		let _price = price.replaceAll(',', '')
		_price = isNaN(_price) ? 0 : _price

		const newMemoedItems = memoedItems.map(product => (product.memoable?.id === item.id ? {...product, price: _price} : product))
		setMemoedItems(newMemoedItems)
		const allProducts = loadedProducts.map(product => {
			if (product.id === item.id) product.wholesale_price = _price
			return product
		})
		setLoadedProducts(allProducts)
	}

	const handleUpdateItemProduct = (item) => {
		const newMemoedItems = memoedItems.map(product => (product.memoable?.id === item.id ? {...product, box: item.box} : product))
		setMemoedItems(newMemoedItems)
		const allProducts = loadedProducts.map(product => {
			if (product.id === item.id) product.box = item.box
			return product
		})
		setLoadedProducts(allProducts)
	}

	const handleChangeMemoBox = (event, item) => {
		event.stopPropagation()
		let newMemoedItems
		if(!item.memoable_id) {
			newMemoedItems = memoedItems.map(product => (product.id === item.id ? {...product, box: !item.box} : product))
		} else {
			newMemoedItems = memoedItems.map(product => (product.memoable?.id === item.memoable_id ? {...product, box: !item.box} : product))
		}
		setMemoedItems(newMemoedItems)
	}

	const handleNewAddressChange = (type, field, value) => {
		if (field === 'phone_number') {
			value = formatPhoneNumber(value)
		}

		if (type === "newBillingAddress") {
			let newValue = field === 'save_to_contact' ? !newBillingAddress.save_to_contact : value

			setNewBillingAddress({
				...newBillingAddress,
				[field]: newValue,
			})
		} else if (type === "newShippingAddress") {
			let newValue = field === 'save_to_contact' ? !newShippingAddress.save_to_contact : value

			setNewShippingAddress({
				...newShippingAddress,
				[field]: newValue,
			})
		}
	}

	const handleSelectAddress = (type, address) => {
		if (type === 'billing') {
			setSelectedBillingAddress(address)
			setNewBillingAddress({
				...getDefaultNewBillingAddress(customer),
				visible: false,
			})
		} else {
			setSelectedShippingAddress(address)
			setNewShippingAddress({
				...getDefaultNewShippingAddress(customer),
				visible: false,
			})
		}
	}

	const handleAddNewAddress = type => {
		if (type === 'billing') {
			setSelectedBillingAddress(null)
			setNewBillingAddress({
				...newBillingAddress,
				visible: true,
			})
		} else {
			setSelectedShippingAddress(null)
			setNewShippingAddress({
				...newShippingAddress,
				visible: true,
			})
		}
	}

	const handleSelectSalesPerson = user => {
		if(!!user) {
			setSelectedSalesPerson(user)
		} else {
			setSelectedSalesPerson(null)
		}
	}

	const handleSetSalePerson = (users) => {
		const defaultSelectedSalesPerson = users.find(user => user.id === memoDetails.salesperson_id) || props.currentSpace?.space_general_setting?.memo_saleperson
		setSelectedSalesPerson(defaultSelectedSalesPerson)
	}

	const handleLoadUsers = () => {
		handleSetSalePerson(loadedSpaceUsers)
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/users`, {
				params: {
					q: {
						s: 'created_at desc',
					},
					per_page: 10000,
				},
			})
			.then(response => {
				let {users} = response.data

				setSelectedSalesPerson(users.find(user => user.id === memoDetails.salesperson_id))
				handleSetSalePerson(users)
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return props.addToast({
					text: message,
					type: 'error',
				})
			})
	}

	const loadSingleMemoComments = memoId => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/Memo/${memoId}/comments`)
			.then(response => {
				setMemoComments(response.data.comments)
			})
			.catch(error => {
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const handleSubmitComment = $event => {
		$event.preventDefault()

		setSendingComment(true)

		if (sendingComment) return

		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/Memo/${memoDetails.id}/comments`, {
				comment: commentField,
			})
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					setCommentField({content: '', visible_on_pdf: false})
					setSendingComment(false)
					setIsVisibleComments(!isVisibleComments)

					return setMemoComments([...memoComments, response.data])
				}
			})
			.catch(error => {
				setSendingComment(false)
        const messageErr = error.response?.data?.message || 'Something went wrong please try again later.'

				return props.addToast({
					text: messageErr,
					type: 'error',
				})
			})
	}

	const handleUpdateComment = $event => {
		$event.preventDefault()

		setSendingComment(true)

		if (sendingComment) return

		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/Memo/${memoDetails.id}/comments/${selectedComment.id}`, {
				comment: commentField,
			})
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					setCommentField({content: '', visible_on_pdf: false})
					setSendingComment(false)
					setSelectedComment(null)
					setIsVisibleComments(!isVisibleComments)

					return setMemoComments(memoComments.map(comment => (comment.id === response.data.id ? response.data : comment)))
				}
			})
			.catch(error => {
				setSendingComment(false)
        const messageErr = error.response?.data?.message || 'Something went wrong please try again later.'

				return props.addToast({
					text: messageErr,
					type: 'error',
				})
			})

	}

	const handleDeleteComment = commentId => {
		return Globals.New_Axios()
			.delete(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/Memo/${memoDetails.id}/comments/${commentId}`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					setMemoComments(memoComments.filter(comment => comment.id !== commentId))
          if (selectedComment?.id === commentId) {
            setSelectedComment(null)
          }
				}
			})
			.catch(error => {
        const messageErr = error.response?.data?.message || 'Something went wrong please try again later.'
				return props.addToast({
					text: messageErr,
					type: 'error',
				})
			})
	}

	const handleToogleInputComment = (type, selectedComment = null) => {
		setIsVisibleComments(!isVisibleComments)

		if (type === 'new') {
			setSelectedComment(null)
		}

		if (type === 'edit') {
			setSelectedComment(selectedComment)
			setCommentField(selectedComment)
		}
	}

	const handleVisibleManualBillingAddress = () => {
		if(!isManualBillingAddress) {
			if(!newBillingAddress.address) {
				const address = {
					...newBillingAddress,
					address: newBillingAddress.full_address
				}
				setNewBillingAddress(address)
			}
		} else {
			const address = {
				...newBillingAddress,
				address: billingAddress
			}
			setNewBillingAddress(address)
		}
		setIsManualBillingAddress(!isManualBillingAddress)
	}

	const handleVisibleManualShippingAddress = () => {
		if(!isManualShippingAddress) {
			if(!newShippingAddress.address) {
				const address = {
					...newShippingAddress,
					address: newShippingAddress.full_address
				}
				setNewShippingAddress(address)
			}
		} else {
			const address = {
				...newShippingAddress,
				address: shippingAddress
			}
			setNewShippingAddress(address)
		}
		setIsManualShippingAddress(!isManualShippingAddress)
	}

	const handleChangeTrackingNumber = (event) => {
		setIsFlagTrackingNumber(true)
		setTrackingNumber(event.target.value)
	}

	const handleSubmitTrackingNumber = () => {
		Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos/${memoDetails.id}`, {
				memo: {
					tracking_number: trackingNumber,
				},
			})
			.then(response => {
				updateMemoItem(response.data)
				props.addToast({
					text: 'Tracking Number Updated Successfully',
					type: 'success',
				})

			}).catch(error => {
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const handleKeyDownTrackingNumber = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			trackingNumberInputRef.current.blur();
			handleSubmitTrackingNumber()
		}
	};

	const handleCheckCountries = (value) => {
		if(value.long_name === 'Japan' && value.types.includes('country')) {
			if(!value.types.includes('street_number') || !value.types.includes('route')) {
				return false
			}
		}
		return true
	}

	const handleChangeGoogleAddress = (place) => {
		const tmpAddress = []
    let subpremise

		if (!place.address_components) return

		(place.address_components || []).forEach(value => {
			if (value.types.includes('street_number') || value.types.includes('route')) {
        tmpAddress.push(value.long_name)
			}

      if (value.types.includes('subpremise')) {
        const match = value.long_name.match(/\d+$/);
        subpremise = match ? match[0] : value.long_name;
      }

			const isCheckCountries = handleCheckCountries(value)
			if(!isCheckCountries) {
				const part =  place.formatted_address.split(", ")
				tmpAddress.push(part[0])
			}
		})

		let city = place.address_components.find(component => component.types.includes('locality'))?.long_name || ''
		if(!city) {
			city = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || ''
		}

		//Note: Check Japan Country

		const streetNumber = place.address_components.find(component => component.types.includes('street_number'))?.long_name || ''
		const streetName = place.address_components.find(component => component.types.includes('route'))?.long_name || ''
		const searchedAddress = {
			full_address: place.formatted_address.replace(` ${subpremise}`, ''),
			address: tmpAddress?.toString().replaceAll(',', ' ').trimStart(),
			street: (streetNumber ? streetNumber + ' ' : '' )+ streetName,
			city: city,
			state: place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '',
			country: place.address_components.find(component => component.types.includes('country'))?.long_name || '',
			zip_code: place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '',
		}

    if (subpremise) {
      searchedAddress.apt_unit_number = subpremise
    }

		return searchedAddress
	}

	const handleChangeBillingAddress = (place) => {
		const searchedAddress =  handleChangeGoogleAddress(place)
		if (!isManualBillingAddress) {
			const newAddress = assignIn({}, newBillingAddress, searchedAddress)
			setBillingAddress(searchedAddress.address)
			setNewBillingAddress(newAddress)
		}
	}

	const handleChangeShippingAddress = (place) => {
		const searchedAddress =  handleChangeGoogleAddress(place)
		if (!isManualShippingAddress) {
			const newAddress = assignIn({}, newShippingAddress, searchedAddress)
			setShippingAddress(searchedAddress.address)
			setNewShippingAddress(newAddress)
		}
	}

  const handleOpenNoteModal = (event, memoItem) => {
    setMemoItemSelected(memoItem)
    setOpenNoteModal(true)
  }

  const handleCloseNoteModal = () => {
    setOpenNoteModal(false)
    setMemoItemSelected(null)
  }


  const setNoteData = (event, value) => {
    setMemoedItems(memoedItems.map(item => {
      if (item.id === memoItemSelected.id && item.memoable_id === memoItemSelected.memoable_id) {
        item.note = value
      }
      return item
    }))

    setOpenNoteModal(false)
    setMemoItemSelected(null)
  }

	useEffect(() => {
		handleLoadUsers()
		loadSingleMemoComments(memoDetails.id)
		setTrackingNumber(memoDetails.tracking_number)
	}, [memoDetails])

	useEffect(() => {
		const memoItems = remove([...memoData.memo_items], item => {
			return !item.returned && !item.invoiced
		})

		setMemoedItems(memoItems)
	}, [memoData.memo_items])

	useEffect(() => {
		focusElement('.single-memo-view-edit')
	}, [])

	return (
		<>
			{productsHunter && (
				<ProductsHunter
					loadedProducts={loadedProducts}
					handleCloseProductsHunter={handleCloseProductsHunter}
					handleAddingItemToMemo={handleAddingItemToMemo}
					handleRemovingItemToMemo={handleRemovingItemToMemo}
					memoedItems={memoedItems}
					handleSearchProducts={handleSearchProducts}
					props={props}
					handleItemPriceChange={handlePriceChange}
					handleUpdateItemProduct={handleUpdateItemProduct}
					loadingProduct={loadingProduct}
				/>
			)}
			{addNewMiscProduct && <AddNewMiscProduct handleMiscItemList={handleMiscItemList} hideAddNewMiscProduct={hideAddNewMiscProduct} props={props} />}
			<div
				onKeyDown={onPressEscape((event) => handleClose())}
 				className="single-memo-view-edit bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-30" tabIndex="-1">
				<div className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-scroll h-full" style={{maxWidth: '1100px', width: '100%'}}>
					<div className="flex items-center gap-3 bg-white border-b py-6 px-10">
						<div className="flex items-center justify-between w-full">
							<div className='flex'>
								<button
									onClick={() => {
										handleClose()
									}}
								>
									<svg className="w-6 h-6" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
									</svg>
								</button>
								{<h2 className="font-semibold text-2xl uppercase">Edit Memo: #{memoData?.memo_number}</h2>}
							</div>
							<div className="flex justify-between items-center pb-6 md:pb-0">
									<div className=" mr-5">
										<Tooltip title="Add a product from your inventory" placement="bottom" arrow>
											<button
												onClick={() => handleOpenProductsHunter()}
												className="bg-[#2863EB] text-white border border-blue-500 flex items-center px-3 py-2 relative rounded w-full"
											>
												<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
													<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
												</svg>
												<span className="font-normal text-sm text-center">Add products</span>
											</button>
										</Tooltip>
									</div>
									<div className=" mr-5">
										<Tooltip title="Add a miscellaneous item" placement="bottom" arrow>
											<button
												onClick={() => setAddNewMiscProduct(true)}
												className="bg-[#2863EB] text-white border border-blue-500 flex items-center px-3 py-2 relative rounded w-full"
											>
												<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
													<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
												</svg>
												<span className="font-normal text-sm text-center">Add Misc Item</span>
											</button>
										</Tooltip>
									</div>
								</div>
						</div>
					</div>
					<div className="pb-5 pt-2">
						<div className='px-[24px]'>
							<table className="relative min-w-full border-b bg-transparent overflow-scroll">
								<thead className="bg-[#5D6E81] text-white uppercase font-semibold text-[14px]">
									<tr>
										<th className="w-6/12 px-[15px] py-2 text-sm font-semibold tracking-worderer rounded-tl-[5px] rounded-bl-[5px]">Product</th>
										<th className="w-2/12 py-2 text-sm font-semibold tracking-worderer">SKU</th>
										<th className="w-2/12 py-2 text-sm font-semibold tracking-worderer">Price</th>
										<th className="w-1/12 pr-[60px] py-2 text-sm font-semibold tracking-worderer text-right  rounded-tr-[5px] rounded-br-[5px]">Action</th>
									</tr>
								</thead>
								<tbody className="">
									{isEmpty(memoedItems) && isEmpty(miscItems) &&  <div className="text-xs py-4">No Item(s) Memoed</div>}

									{!isEmpty(memoedItems) && memoedItems.map((memoItem, index) => {
										return (
											!memoItem._destroy && (
												<tr key={index} className={Classnames('cursor-pointer hover:bg-gray-100 bg-white border-b')}>
													<td className="py-6 px-[15px] text-sm font-normal tracking-worderer w-7/12">
														<div className="w-10/12">
															<p className='text-[14px] font-semibold'>{memoItem?.memoable?.name}</p>
                              <div className='flex gap-4'>
															  <p onClick={(event) => {handleChangeMemoBox(event, memoItem)}} className='text-[14px] font-semibold w-max border-[1px] rounded-[5px] p-[2px]'>{memoItem?.box ? "With Box" : "Without Box"}</p>
															  <p
                                  onClick={(event) => {
                                    handleOpenNoteModal(event, memoItem)
                                  }}
                                  className={Classnames(`text-[14px] font-semibold w-max border-[1px] rounded-[5px] p-[2px]`, {
                                    'border-[#E0E0E0] text-[#E0E0E0]': !memoItem?.note,
                                  })}
                                >
                                  Notes
                                </p>
                              </div>
														</div>
													</td>
													<td className="py-6  text-sm font-normal tracking-worderer text-[14px] font-semibold">{memoItem?.memoable?.sku}</td>
													<td className="py-6  text-sm font-normal tracking-worderer">
														<input
															onChange={e => handleItemPriceChange(e, memoItem)}
															type="text"
															className="border px-2 py-1 rounded text-[14px] font-semibold"
															value={Globals.formatCurrency(memoItem.price, true)}
															placeholder="Price"
														/>
														{errors.map(value => {
															return (
																value.price.index === index &&
																	<span className='text-red-700'>{value.price.error}</span>
															)
														})}
													</td>
													<td className="py-6 px-[15px] tracking-worderer" align="center">
														{memoedItems.length > 0 && (
																<button
																onClick={() => handleRemoveItem(memoItem)}
																className="flex flex-col text-xs rounded items-center pl-1 pr-2 py-0.5"
															>
																<img src="/Images/remove_icon.png" alt="remove icon" className="w-[22px] h-[22px]" />
																<span className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
															</button>
														)}
													</td>
												</tr>
											)
										)
									})}
									{!isEmpty(miscItems) && miscItems.map((product, index) => {
										return (
											!product._destroy && (
												<tr key={index} className={Classnames('cursor-pointer hover:bg-gray-100 bg-white border-b')}>
													<td className="py-2 px-[15px] text-sm font-normal tracking-worderer w-7/12">
														<div className="w-10/12">
															<p className='text-[14px] font-semibold'>{product.product_name}</p>
														</div>
													</td>
													<td className="py-2  text-sm font-normal tracking-worderer text-[14px] font-semibold">{product.sku}</td>
													<td className="py-2  text-sm font-normal tracking-worderer">
														<input
															onChange={e => handleMemoMiscPriceChange(index, e)}
															type="text"
															className="border px-2 py-1 rounded text-[14px] font-semibold"
															value={Globals.formatCurrency(product.price, true)}
															placeholder="Item Price"
														/>
													</td>
													<td className="py-2 px-[15px] tracking-worderer" align="center">
														<button
															onClick={() => handleRemovingMiscItem(index)}
															className="flex flex-col text-xs rounded items-center pl-1 pr-2 py-0.5"
														>
																<img src="/Images/remove_icon.png" alt="remove icon" className="w-[22px] h-[22px]" />
																<span className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
														</button>
													</td>
												</tr>
											)
										)
									})}
								</tbody>
							</table>
							{(!isEmpty(memoedItems) || !isEmpty(miscItems)) && (
								<div className="flex justify-between items-start w-full pt-2">
									<div className="w-1/4 text-[13px] font-normal px-[35px] tracking-worderer">
										Total Products: {memoedItems.length + miscItems.length}
									</div>
									<div className="w-[400px] pr-[30px] text-sm font-semibold tracking-worderer">
										<br />
										<div className="flex justify-between items-center">
											<div className="text-black text-base font-normal">Subtotal</div>
											<div className="text-black text-base font-normal">
												{Globals.readCurrencyNumber(
													sum(memoedItems.filter(item => !item._destroy).map(t => (t.price ? Globals.revertPrice(t.price) : 0 * parseInt(t.quantity)))) +
														sum(miscItems.filter(item => !item.disable).map(t => Globals.revertPrice(t.price)))
												, true)}
											</div>
										</div>
										<div className="flex justify-between items-center pb-[4px]">
											<div className="text-black text-base font-normal">Shipping</div>
											<div className="text-black text-base font-normal">
												<div className="text-sm font-normal flex">
													<div
														onClick={(e) => toggleShipping(e)}
														className={Classnames("text-center w-[120px] h-[23px] cursor-pointer flex items-center justify-center rounded-[5px] border-[1px] border-[#5d6e8133]", {
															"text-[#4B7CBE] bg-[#5D6E81] bg-opacity-20": isCustomerLabeled,
															"text-[#4B7CBE] bg-white": !isCustomerLabeled,
														})}
													>
														Customer Label
													</div>
													{!isCustomerLabeled ?
														<input
															onChange={$event => {
																handleShippingChange($event)
															}}
															className="border h-[23px] w-[51px] rounded-[5px] ml-[7px] text-right"
															readOnly={isCustomerLabeled}
															disabled={isCustomerLabeled}
															value={isCustomerLabeled ? '' : shipping ? shipping : ''}
														/> : <></>
													}
												</div>
											</div>
										</div>
										<hr className="m-0 border-black w-4/12 float-right"/>
										<div className="flex justify-between items-center w-full pt-[4px]">
											<div className="text-black text-base font-normal">Total</div>
											<div className="text-black text-base font-normal">
												{Globals.readCurrencyNumber(
													sum(memoedItems.filter(item => !item._destroy).map(t => (t.price ? Globals.revertPrice(t.price) : 0 * parseInt(t.quantity)))) +
														sum(miscItems.filter(item => !item.disable).map(t => Globals.revertPrice(t.price ? t.price : 0))) +
													Globals.revertPrice(shipping)
												, true)}
											</div>
										</div>
									</div>
								</div>
							)}
						</div>

						<div className="mt-[48px] bg-[#F4F4F4]">
							<div className="px-[39px] py-5">
								<div className="text-[#5D6E81] text-[22px] font-bold">Customer Details</div>
								<div className="w-full flex justify-between mt-[15px]">
									<div className="justify-between">
										<div className="flex items-center gap-x-[13px] mb-[30px]">
											<img src="./Images/company.png" alt="" className="h-[24px] w-[24px]" />
											<span>{memoData.customer?.company_name}</span>
										</div>
										<div className="flex items-center gap-x-[13px] mb-[30px]">
											<img src="./Images/phone.png" alt="" className="h-[24px] w-[24px]" />
											<div className="flex flex-col">
												{
													customer?.customer_phone_numbers && customer?.customer_phone_numbers[0] && (
														<div>
															<span>{customer.customer_phone_numbers[0].phone_number_formatted}</span>
														</div>
													)
												}
											</div>
										</div>
										<div className="flex items-center gap-x-[13px]">
											<img src="./Images/mail.png" alt="" className="h-[24px] w-[24px]" />
											<span>{memoData.customer?.email}</span>
										</div>
									</div>
									<div className="flex flex-col">
										<div className="flex items-center gap-x-[13px] mb-[11px]">
											<img src="./Images/people.png" alt="" className="h-[24px] w-[24px]" />
											<span className="text-[#5D6E81] text-[14px] font-semibold">Billing</span>
											<AddressDropdown
												addressOptions={customer?.customer_billing_addresses}
												addressType="billing"
												selectedAddress={selectedBillingAddress}
												onSelect={handleSelectAddress}
												onAddNew={handleAddNewAddress}
											></AddressDropdown>
										</div>
										<div className="ms-[43px] max-w-[235px]">
											{newBillingAddress.visible ? (
												<React.Fragment>
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Company Name"
														value={newBillingAddress.company_name}
														onChange={e => handleNewAddressChange('newBillingAddress', 'company_name', e.target.value)}
													/>
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Contact Name"
														value={newBillingAddress.contact_name}
														onChange={e => handleNewAddressChange('newBillingAddress', 'contact_name', e.target.value)}
													/>
													{!isManualBillingAddress ?
														<>
															<div>
																<div className='flex w-[235px] gap-1'>
																	<ServerAutoSuggestAddress
																		formData={{full_address: newBillingAddress.full_address}}
																		handleChangeGoogleAddress={handleChangeBillingAddress}
																		className="border rounded-[5px] w-full h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		view={'memo'}
																	/>
																	<input
																		type="text"
																		className="border rounded-[5px] w-[38%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		placeholder="Apt/Unit #"
																		name='apt_unit_number'
																		value={newBillingAddress.apt_unit_number}
																		onChange={e => handleNewAddressChange('newBillingAddress', 'apt_unit_number', e.target.value)}
																	/>
																</div>

																<span
																	onClick={handleVisibleManualBillingAddress}
																	className='text-xs mt-[1px] cursor-pointer underline'
																>
																	Add Manually
																</span>
															</div>
														</>
														:
														<>
															<div>
																<div className='flex w-[235px] gap-1'>
																	<input
																		type="text"
																		className="border rounded-[5px] w-[70%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		placeholder="Street"
																		name='street'
																		value={newBillingAddress.street}
																		onChange={e => handleNewAddressChange('newBillingAddress', 'street', e.target.value)}
																	/>
																	<input
																		type="text"
																		name='apt_unit_number'
																		className="border rounded-[5px] w-[30%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		placeholder="Apt/Unit #"
																		value={newBillingAddress.apt_unit_number}
																		onChange={e => this.handleNewAddressChange('newBillingAddress', 'apt_unit_number', e.target.value)}
																	/>
																</div>
																<span
																	onClick={handleVisibleManualBillingAddress}
																	className='text-xs mt-[1px] cursor-pointer underline'
																>
																	Use Google autocomplete
																</span>
															</div>
															<div className='flex w-[235px] gap-1'>
																<input
																	type="text"
																	name='city'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="City"
																	value={newBillingAddress.city}
																	onChange={e => handleNewAddressChange('newBillingAddress', 'city', e.target.value)}
																/>
																<input
																	type="text"
																	name='state'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="State"
																	value={newBillingAddress.state}
																	onChange={e => handleNewAddressChange('newBillingAddress', 'state', e.target.value)}
																/>
															</div>
															<div className='flex w-[235px] gap-1'>
																<input
																	type="text"
																	name='zip_code'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="Zip Code"
																	value={newBillingAddress.zip_code}
																	onChange={e => handleNewAddressChange('newBillingAddress', 'zip_code', e.target.value)}
																/>
																<div className="flex items-center border rounded-[5px] w-1/2 h-[30px] text-[12px]">
																	<select
																		id="country"
																		name="country"
																		className="w-full"
																		defaultValue={newBillingAddress?.country || ''}
																		onChange={e => handleNewAddressChange('newBillingAddress', 'country', e.target.value)}
																		>
																		<option value="" disabled>
																			Select Country
																		</option>
																		{Globals.countries.map(c => (
																			<option key={c.code} value={c.code}>
																				{c.name}
																			</option>
																		))}
																	</select>
																</div>
															</div>

														</>
													}
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Phone Number"
														value={newBillingAddress.phone_number}
														onChange={e => handleNewAddressChange('newBillingAddress', 'phone_number', e.target.value)}
													/>
													<div className="flex items-center">
														<label htmlFor="save_to_contact_billing">
															<input
																type="checkbox"
																id="save_to_contact_billing"
																className="hidden peer"
																checked={newBillingAddress.save_to_contact}
																onChange={e => handleNewAddressChange('newBillingAddress', 'save_to_contact', e.target.checked)}
															/>
															<div className="flex items-center text-center">
																<span
																	htmlFor="save_to_contact_billing"
																	className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300"
																>
																	{newBillingAddress.save_to_contact && (
																		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
																			<path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0" />
																		</svg>
																	)}
																</span>
																<span className="mt-1 text-[12px] text-[#5D6E81] font-normal">Save Address to Contact</span>
															</div>
														</label>
													</div>
												</React.Fragment>
											) : (
												<React.Fragment>
													<p>{selectedBillingAddress?.company_name}</p>
													<p>{selectedBillingAddress?.contact_name}</p>
													<p>{selectedBillingAddress?.full_address}</p>
													<p>{selectedBillingAddress?.phone_number_formatted}</p>
												</React.Fragment>
											)}
										</div>
									</div>
									<div className="flex flex-col">
										<div className="flex items-center gap-x-[13px] mb-[11px]">
											<img src="./Images/location_detail.png" alt="" className="h-[24px] w-[24px]" />
											<span className="text-[#5D6E81] text-[14px] font-semibold">Shipping</span>
											{/* Customer form goes here */}
											<AddressDropdown
												addressOptions={customer?.customer_shipping_addresses}
												addressType="shipping"
												selectedAddress={selectedShippingAddress}
												onSelect={handleSelectAddress}
												onAddNew={handleAddNewAddress}
											></AddressDropdown>
										</div>
										<div className="ms-[43px] max-w-[235px]">
											{newShippingAddress.visible ? (
												<React.Fragment>
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Company Name"
														autoComplete='off'
														value={newShippingAddress.company_name}
														onChange={e => handleNewAddressChange('newShippingAddress', 'company_name', e.target.value)}
													/>
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Contact Name"
														value={newShippingAddress.contact_name}
														onChange={e => handleNewAddressChange('newShippingAddress', 'contact_name', e.target.value)}
													/>
													{!isManualShippingAddress ?
														<>
															<div>
																<div className='flex w-[235px] gap-1'>
																	<ServerAutoSuggestAddress
																		formData={{full_address: newShippingAddress.full_address}}
																		handleChangeGoogleAddress={handleChangeShippingAddress}
																		className="border rounded-[5px] w-full h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		view={'memo'}
																	/>
																	<input
																		type="text"
																		name='apt_unit_number'
																		className="border rounded-[5px] w-[38%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		placeholder="Apt/Unit #"
																		value={newShippingAddress.apt_unit_number}
																		onChange={e => this.handleNewAddressChange('newShippingAddress', 'apt_unit_number', e.target.value)}
																	/>
																</div>
																<span
																	onClick={handleVisibleManualShippingAddress}
																	className='text-xs mt-[1px] cursor-pointer underline'
																>
																	Add Manually
																</span>
															</div>
														</>
														:
														<>
															<div>
																<div className='flex w-[235px] gap-1'>
																	<input
																		type="text"
																		className="border rounded-[5px] w-[70%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		placeholder="Street"
																		name='street'
																		value={newShippingAddress.street}
																		onChange={e => handleNewAddressChange('newShippingAddress', 'street', e.target.value)}
																	/>
																	<input
																			type="text"
																			name='apt_unit_number'
																			className="border rounded-[5px] w-[30%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																			placeholder="Apt/Unit #"
																			value={newShippingAddress.apt_unit_number}
																			onChange={e => this.handleNewAddressChange('newShippingAddress', 'apt_unit_number', e.target.value)}
																	/>
																</div>
																<span
																	onClick={handleVisibleManualShippingAddress}
																	className='text-xs mt-[1px] cursor-pointer underline'
																>
																	Use Google autocomplete
																</span>
															</div>
															<div className='flex w-[235px] gap-1'>
																<input
																	type="text"
																	name='city'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="City"
																	value={newShippingAddress.city}
																	onChange={e => handleNewAddressChange('newShippingAddress', 'city', e.target.value)}
																/>
																<input
																	type="text"
																	name='state'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="State"
																	value={newShippingAddress.state}
																	onChange={e => handleNewAddressChange('newShippingAddress', 'state', e.target.value)}
																/>
															</div>
															<div className='flex w-[235px] gap-1'>
																<input
																	type="text"
																	name='zip_code'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="Zip Code"
																	value={newShippingAddress.zip_code}
																	onChange={e => handleNewAddressChange('newShippingAddress', 'zip_code', e.target.value)}
																/>
																<div className="flex items-center border rounded-[5px] w-1/2 h-[30px] text-[12px]">
																	<select
																		id="country"
																		name="country"
																		className="w-full"
																		defaultValue={newShippingAddress?.country || ''}
																		onChange={e => handleNewAddressChange('newShippingAddress', 'country', e.target.value)}
																		>
																		<option value="" disabled>
																			Select Country
																		</option>
																		{Globals.countries.map(c => (
																			<option key={c.code} value={c.code}>
																				{c.name}
																			</option>
																		))}
																	</select>
																</div>
															</div>

														</>
													}
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Phone Number"
														value={newShippingAddress.phone_number}
														onChange={e => handleNewAddressChange('newShippingAddress', 'phone_number', e.target.value)}
													/>
													<div className="flex items-center">
														<label htmlFor="save_to_contact_shipping">
															<input
																type="checkbox"
																id="save_to_contact_shipping"
																className="hidden peer"
																defaultChecked={newShippingAddress.save_to_contact}
																onChange={e => handleNewAddressChange('newShippingAddress', 'save_to_contact', e.target.checked)}
															/>
															<div className="flex items-center text-center">
																<span
																	htmlFor="save_to_contact_shipping"
																	className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300"
																>
																	{newShippingAddress.save_to_contact && (
																		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
																			<path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0" />
																		</svg>
																	)}
																</span>
																<span className="mt-1 text-[12px] text-[#5D6E81] font-normal">Save Address to Contact</span>
															</div>
														</label>
													</div>
												</React.Fragment>
											) : (
												<React.Fragment>
													<p>{selectedShippingAddress?.company_name}</p>
													<p>{selectedShippingAddress?.contact_name}</p>
													<p>{selectedShippingAddress?.full_address}</p>
													<p>{selectedShippingAddress?.phone_number_formatted}</p>
												</React.Fragment>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="px-10 w-full flex mt-[21px] min-h-[153px] relative">
							<div className="w-1/2">
								<div className="text-[22px] text-[#5D6E81] font-bold">Memo Details</div>
								<div className="flex flex-col pl-[22px] pr-[37px] pt-[20px] gap-y-[13px]">
									<div className="flex justify-between">
										<div>Memo Date:</div>
										<div>{Moment(memoData.created_at).local().format('MM/DD/YYYY')}</div>
									</div>
									{
										currentSpace.space_general_setting?.visible_salesperson_on_memo &&
										<div className="flex justify-between">
											<div>Salesperson:</div>
											<UsersDropdown
												className="bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] text-center pl-[21px] pr-[30px] w-[184px]"
												userOptions={loadedSpaceUsers}
												selectedUser={selectedSalesPerson}
												onSelect={handleSelectSalesPerson}
											/>
										</div>
									}
									<div className="flex justify-between">
										<div>Tracking Number</div>
										<input
											name="tracking_number"
											value={trackingNumber}
											onChange={handleChangeTrackingNumber}
											onKeyDown={handleKeyDownTrackingNumber}
											ref={trackingNumberInputRef}
											className='bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] w-[184px] text-end px-1'
										/>
									</div>
								</div>
							</div>
							<hr className="w-[1px] h-[153px] bg-[#E5E7EB] mt-10 mx-[20px]"/>
							<div className="w-1/2 pl-[22px]">
								<div className="text-[22px] text-[#5D6E81] font-bold flex items-center gap-x-[37px]">
									<span>Comments</span>
									<img
										src="./Images/plus_radius.png"
										alt=""
										className="w-[22px] h-[22px]"
										onClick={() => setIsVisibleComments({isVisibleComments: !isVisibleComments})}
									/>
								</div>
								<div className="flex flex-col">
                  {
                    memoComments.map((comment, index) => {
                      return (
                        <>
                          <div className="py-3 text-sm flex items-center justify-between gap-x-2 px-3" key={index}>
                            <div className="">
                              <div className="text-[#5D6E81] font-medium">{comment.content}</div>
                              <div className="text-xs">{Moment(comment.created_at).format('M/DD/YYYY')} - {comment.user.full_name}</div>
                            </div>
                            <div className="flex gap-x-3 ">
                              <button
                                className="underline font-normal text-[12px] cursor-pointer"
                                onClick={() => handleToogleInputComment('edit', comment)}
                              >
                                Edit
                              </button>
                              <button
                                className="underline font-normal text-[12px] cursor-pointer"
                                onClick={() => handleDeleteComment(comment.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                          {isVisibleComments && selectedComment?.id === comment.id && (
                            <CommentForm
                              commentField={commentField}
                              setCommentField={setCommentField}
                              sendingComment={sendingComment}
                              selectedComment={selectedComment}
                              handleUpdateComment={handleUpdateComment}
                              handleSubmitComment={handleSubmitComment}
                            />
                          )}
                        </>
                      )
                    })
                  }

                  {isVisibleComments && isEmpty(selectedComment) && (
                    <CommentForm
                      commentField={commentField}
                      setCommentField={setCommentField}
                      sendingComment={sendingComment}
                      selectedComment={selectedComment}
                      handleUpdateComment={handleUpdateComment}
                      handleSubmitComment={handleSubmitComment}
                    />
                  )}
								</div>
							</div>
						</div>
						<div className="mt-6 flex gap-4 items-center justify-center px-10 mx-auto gap-x-[16px]">
							<button
								className="transition w-[84px] h-[38px] rounded-[5px] border border-[#5d6e8147] text-[#5D6E81] font-semibold text-[12px] hover:bg-gray-300"
								onClick={handleClose}
							>
								Cancel
							</button>
							<button className="w-[66px] h-[38px] transition rounded-[5px] bg-[#2863EB] text-white text-[12px] hover:bg-blue-800" onClick={saveMemo}>
								Save
							</button>
						</div>
					</div>
				</div>
        {openNoteModal && (
          <AddNoteForLineItem
            noteValue={memoItemSelected?.note}
            handleChangeNote={setNoteData}
            handleClose={handleCloseNoteModal}
            isEdit={!isEmpty(memoItemSelected?.note) ? true : false}
            addToast={props.addToast}
          />
        )}
			</div>
		</>
	)
}

const mapStateToProps = (state, props) => {
	return {
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(EditMemo)
