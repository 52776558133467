import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {focusElement} from '../../../utils/keyboardHandler'
import QRCode from './components/QrCode'
import QrSticker from './components/QrSticker'

export const labelSizeLabelPrinter = [
	{
		label: 'Large (4in x 6in)',
		value: '4_x_6',
	},
	{
		label: 'Medium (4in x 2 1/4in)',
		value: '4_x_2.25',
	},
	{
		label: 'Medium Long (4in x 1in)',
		value: '4_x_1',
	},
	{
		label: 'Medium Tall (2in x 4in)',
		value: '2_x_4',
	},
	{
		label: 'Small (2in x 1in)',
		value: '2_x_1',
	},
	{
		label: '4in x 0.5in',
		value: '4_x_0.5',
	},
	{
		label: '3in x 2in',
		value: '3_x_2',
	},
	{
		label: '3in x 1.5in',
		value: '3_x_1.5',
	},
	{
		label: '3in x 1in',
		value: '3_x_1',
	},
	{
		label: '3in x 0.5in',
		value: '3_x_0.5',
	},
	{
		label: '2in x 1in',
		value: '2_x_1',
	},
	{
		label: '2.5in x 0.5in',
		value: '2.5_x_0.5',
	},
	{
		label: '1in x 1in',
		value: '1_x_1',
	}
]

const QRCodeWrapper = ({handleVisibleQRCode, data, addToast}) => {
	const selectedSpace = useSelector(state => state.currentSpace)

	useEffect(() => {
		focusElement('.watch-qr-view')
	}, [])

	return (
		<div
			tabIndex="-1"
			aria-hidden="true"
			className="watch-qr-view bg-black/50 fixed flex h-screen items-center justify-center left-0 overflow-hidden inset-0 w-full z-50 "
			onKeyDown={event => {
				if (event.key === 'Escape' && event.target.classList.contains('watch-qr-view')) {
					handleVisibleQRCode(false)
				}
			}}
		>
			<div className="relative min-h-[529px] min-w-[519px]">
				<div className="relative bg-white rounded-xl shadow h-full">
					{selectedSpace?.qr_type === 'qr_code' ? (
						<QRCode data={data} addToast={addToast} handleVisibleQRCode={handleVisibleQRCode} />
					) : (
						<QrSticker data={data} addToast={addToast} handleVisibleQRCode={handleVisibleQRCode} />
					)}
				</div>
			</div>
		</div>
	)
}

export default QRCodeWrapper
