import React, {useState} from 'react'
import {connect} from 'react-redux'
import {Globals} from '../../Context'

import {addToast} from '../../app/appActions'
import ConfirmPopUp from '../ConfirmPopUp'

const ShareEmailDialog = (props) => {
	const { emailDialog, singleDataProvider } = props
	const [fromData, setFormData] = useState({email: 0, product: !!singleDataProvider?.id ? singleDataProvider.id : props.data.id, additional_detail: ' '})
	const [sendingSendEmail, setSendingSendEmail] = useState(false)

	const shareViaEmail = () => {
		if (sendingSendEmail) return

		if (!Globals.validateEmail(fromData.email)) {
			props.addToast({
				text: 'Invalid email address',
				type: 'error',
			})

			return
		}

		if (fromData.additional_detail.length > 2000) {
			props.addToast({
				text: 'Your additional details field reached the maximum limit',
				type: 'error',
			})
			return
		}

		setSendingSendEmail(true)
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/marketplaces/${singleDataProvider ? singleDataProvider.id : props.data.uid}/share`, {...fromData, ...props.dataEmail, share_method: 'email'})
			.then(res => {
				setSendingSendEmail(false)

				if (![200, 201].includes(res.status)) {
					props.addToast({
						text: 'Something went wrong please try again later.',
						type: 'error',
					})
					return
				}
				props.addToast({
					title: 'Email has been sent',
					type: 'success',
				})
				emailDialog(false)
				setFormData({email: '', product: '', additional_detail: ''})
			})
			.catch(error => {
				setSendingSendEmail(false)
				props.addToast({
					title: 'Error',
					text: error.response?.data?.message || 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	return (
		<div tabIndex="-1" aria-hidden="true" className="bg-black/50 fixed flex h-screen items-center justify-center left-0 overflow-x-hidden overflow-y-auto p-4 right-0 top-0 w-full z-50">
			<div className="relative w-full max-w-2xl h-full md:h-auto m-auto">
				<div className="relative bg-white rounded-xl shadow">
					<div className="flex justify-between items-start px-8 py-4 rounded-t-xl border-b bg-slate-600 text-white">
						<h3 className="text-white font-bold text-lg">Share via email</h3>
						<button
							onClick={() => {
								emailDialog(false)
							}}
							type="button"
							className="bg-transparent rounded-xl text-sm py-1.5 ml-auto inline-flex items-center"
						>
							<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clipRule="evenodd"
								></path>
							</svg>
						</button>
					</div>

					<div className="px-8 py-4 space-y-6">
						<div>
							<label htmlFor="email-address-icon" className="block text-base bg-transparent font-normal py-2">
								Email address
							</label>
							<div className="relative">
								<div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
									<svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
										<path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
										<path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
									</svg>
								</div>
								<input
									value={FormData.email}
									onChange={e => {
										setFormData({...fromData, email: e.target.value})
									}}
									type="text"
									id="email-address-icon"
									className="block pl-10 py-3 p-4 md:w-full w-80 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
									placeholder="Email address"
								/>
							</div>
						</div>

						<div>
							<label htmlFor="AdditionalDetails" className="block text-base bg-transparent font-normal py-2">
								Additional Details
							</label>
							<textarea
								value={fromData.additional_detail}
								onChange={e => {
									setFormData({...fromData, additional_detail: e.target.value})
								}}
								id="AdditionalDetails"
								name="AdditionalDetails"
								className="block p-4 w-full inputLogin appearance-none outline-none text-black text-sm font-medium"
								placeholder="Maximum of 20000 characters."
								maxLength="20000"
								cols="30"
								rows="4"
							></textarea>
							<div>
								<small>{fromData?.additional_detail?.length}</small>
								<small>
									<small>/</small>
								</small>
								<small>
									<small>2000</small>
								</small>
							</div>
						</div>
					</div>

					<div className="flex justify-end items-center px-8 py-4 space-x-2 rounded-b-xl border-t border-gray-200">
						<ConfirmPopUp
							onConfirm={shareViaEmail}
							title="Confirm share via email"
							content="Are you sure you want to share this product via email?"
						>
							<button
								type="button"
								className="px-8 py-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer bg-blue uppercase"
								disabled={sendingSendEmail}
							>
								{!sendingSendEmail && <span>Send</span>}
								{sendingSendEmail && (
									<span role="status">
										<svg className="inline w-5 h-5 animate-spin fill-blue-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
												fill="currentColor"
											/>
											<path
												d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
												fill="currentFill"
											/>
										</svg>
									</span>
								)}
							</button>
						</ConfirmPopUp>
						<button
							onClick={() => {
								emailDialog(false)
							}}
							type="button"
							className="px-8 py-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer bg-red-700 uppercase"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
	}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(ShareEmailDialog)
