import React, {useEffect, useState, useRef} from 'react'
import Classnames from 'classnames'
import { flushSync } from 'react-dom'

import {Globals} from '../../Context'
import {focusElement, onPressEscape} from '../../utils/keyboardHandler'
import 'react-intl-tel-input/dist/main.css'
import 'react-phone-number-input/style.css'
import './AddNewCustomer.scss'
import ServerAutoSuggestAddress from '../ServerAutoSuggestAddress'

import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'
import trim from 'lodash/trim'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import compact from 'lodash/compact'
import cloneDeep from 'lodash/cloneDeep'
import assignIn from 'lodash/assignIn'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import xor from 'lodash/xor'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import { AsYouType } from 'libphonenumber-js';

const initAddress = {
	company_name: '',
	contact_name: '',
	street: '',
	apt_unit_number: '',
	city: '',
	state: '',
	country: '',
	zip_code: '',
	full_address: '',
	is_billing_default: true,
	is_shipping_default: true,
	primary: true,
	_destroy: false,
	isManual: false
}

const phoneTypeListInit = [
	{phone_label: 'Mobile', phone_type: 'mobile'},
	{phone_label: 'Office', phone_type: 'office'},
	{phone_label: 'Home', phone_type: 'home'},
]

const initPhoneNumber = {
	phone_number: '+1',
	phone_type: 'mobile',
	phone_custom_type: null,
}

const initFormData = {
	company_name: '',
	first_name: '',
	last_name: '',
	email: '',
	certificate: '',
	phone_number: '',
}

export const formatPhoneNumber = value => {
	if (value.length >= 10 && !value.startsWith('+')) {
		// NOTE: pickup from chrome autofill form
		value = `+1${value}`;
	}

	const cleaned = value.replace(/\D/g, '');

	if (value.startsWith('+1')) {
		const match = cleaned.match(/^(\d{1})(\d{3})(\d{0,3})(\d{0,4})$/);
		if (match) {
		  return `+${match[1]} (${match[2]}${match[3] ? `) ${match[3]}` : ''}${match[4] ? `-${match[4]}` : ''}`;
		}
	} else {
		const match = cleaned.match(/^(\d{2})(\d{2})(\d{0,3})(\d{0,2})(\d{0,2})$/);

		if (match) {
			return `+${match[1]} ${match[2]}${match[3] ? ` ${match[3]}` : ''}${match[4] ? ` ${match[4]}` : ''}${match[5] ? ` ${match[5]}` : ''}`;
		}
	}

	return value;
}

const capitalizeFirstLetter = string => {
	if (typeof string !== 'string') return ''
	return upperFirst(toLower(string))
}

const formatContactName = (firstName, lastName) => {
	const trimmedFirstName = trim(firstName || '')
	const trimmedLastName = trim(lastName || '')
	return trim(`${trimmedFirstName} ${trimmedLastName}`)
}

export const handleCheckCountries = (value) => {
	if(value.long_name === 'Japan' && value.types.includes('country')) {
		if(!value.types.includes('street_number') || !value.types.includes('route')) {
			return false
		}
	}
	return true
}

const updateDefaultStatus = (address, undeletedAddresses, key) => {
	if (address[key]) {
	  const index = findIndex(undeletedAddresses, { [key]: true });
	  address[key] = false;
	  if (index > 0) {
		undeletedAddresses[index][key] = true;
	  } else {
		undeletedAddresses[0][key] = true;
	  }
	}
  };

const setMissingDefault = (undeletedAddresses, addresses, primaryIndex, key) => {
	if (!find(undeletedAddresses, { [key]: true })) {
		addresses[primaryIndex][key] = true;
	}
};

export const checkAndSetDefaultAddressIfMissing = (addresses, index) => {
	const defaultAddress = addresses[index];
	const undeletedAddresses = addresses.filter(address => !address._destroy);
	const primaryAddressIndex = findIndex(addresses, { primary: true });

	if (defaultAddress && defaultAddress._destroy) {
		addresses.forEach((address, addressIndex) => {
			if ((address.id === defaultAddress.id || (!defaultAddress.id && addressIndex === index)) && defaultAddress._destroy) {
				updateDefaultStatus(address, undeletedAddresses, 'is_billing_default');
				updateDefaultStatus(address, undeletedAddresses, 'is_shipping_default');

				if (address.primary) {
					address.primary = false;
					undeletedAddresses[0].primary = true;
				}
			}
		});
	} else {
		setMissingDefault(undeletedAddresses, addresses, primaryAddressIndex, 'is_billing_default');
		setMissingDefault(undeletedAddresses, addresses, primaryAddressIndex, 'is_shipping_default');
	}

	return addresses;
};

export const showAddressName = (form, address) => {
	const fullContactName = address.contact_name
	const {company_name, contact_name, address_without_suite} = address
	const isDifferentPrimaryContact = company_name !== form.company_name || contact_name !== fullContactName
	if (isDifferentPrimaryContact && address_without_suite) {
		if(!address.primary) {
			return compact([address.company_name, fullContactName, address_without_suite]).join(', ')
		} else {
			return address_without_suite || ''
		}
	}

	return address_without_suite || ''
}

export const renderedPhoneNumbers = (phoneNumbers) => {
	return phoneNumbers.filter(phone => !phone._destroy)
}

const AddNewCustomer = ({handleCloseForm, props, handleLoadCustomers, handleVisibleSingleOrderItem, handlePrimaryTab}) => {
	const [form, setForm] = useState(initFormData)
	const [formErrors, setFormErrors] = useState({})
	const [sendingData, setSendingData] = useState(false)
	const [friendlyId, setFriendlyId] = useState('')
	const [defaultShippingAddress, setDefaultShippingAddress] = useState(true)
	const [currentAddressIndex, setCurrentAddressIndex] = useState(-1)
	const [addresses, setAddresses] = useState([initAddress])
	const [initAddresses, setInitAddresses] = useState([initAddress])
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [phoneNumbers, setPhoneNumbers] = useState([initPhoneNumber])
	const [phoneTypeList, setPhoneTypeList] = useState(phoneTypeListInit)
	const [customType, setCustomType] = useState('')
	const [isOpenDropdownPhoneType, setIsOpenDropdownPhoneType] = useState({})
	const [isCustomType, setIsCustomType] = useState(false)
	const [isManual, setIsManual] = useState(false)
	const [newAddress, setNewAddress] = useState('')

	const dropdownRef = useRef(null)

	useEffect(() => {
		focusElement('.add-new-customer')

		const handleClickOutside = event => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpenDropdownPhoneType({})
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleChangeGoogleAddress = (place) => {
		const tmpAddress = []
    let subpremise

		if (!place.address_components) return

		(place.address_components || []).forEach(value => {
			if (value.types.includes('street_number') || value.types.includes('route')) {
        tmpAddress.push(value.long_name)
			}

      if (value.types.includes('subpremise')) {
        const match = value.long_name.match(/\d+$/);
        subpremise = match ? match[0] : value.long_name;
      }

			const isCheckCountries = handleCheckCountries(value)
			if(!isCheckCountries) {
				const part =  place.formatted_address.split(", ")
				tmpAddress.push(part[0])
			}
		})

		let city = place.address_components.find(component => component.types.includes('locality'))?.long_name || ''
		if(!city) {
			city = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || ''
		}

		//Note: Check Japan Country
		const streetNumber = place.address_components.find(component => component.types.includes('street_number'))?.long_name || ''
		const streetName = place.address_components.find(component => component.types.includes('route'))?.long_name || ''

		const searchedAddress = {
			full_address: place.formatted_address.replace(` ${subpremise}`, ''),
			address: tmpAddress?.toString().replaceAll(',', ' ').trimStart(),
			street: (streetNumber ? streetNumber + ' ' : '' )+ streetName,
			city: city,
			state: place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '',
			country: place.address_components.find(component => component.types.includes('country'))?.long_name || '',
			zip_code: place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '',
		}

    if (subpremise) {
      searchedAddress.apt_unit_number = subpremise
    }

		const newAddresses = cloneDeep(addresses)
		const primaryAddressIndex = findIndex(addresses, {primary: true})
		const newPrimaryAddress = assignIn({}, newAddresses[primaryAddressIndex], searchedAddress)
		newAddresses[primaryAddressIndex] = newPrimaryAddress
		setAddresses(newAddresses)
		const undeletedAddresses = newAddresses.filter(address => !address._destroy)
		setInitAddresses(undeletedAddresses)
		setNewAddress(searchedAddress.address)
	}

	const handleFriendlyId = $event => {
		setFriendlyId($event.target.value.trim())
	}

	const searchFriendlyId = $event => {
		if (isEmpty(friendlyId)) {
			props.addToast({
				title: 'Add Customer',
				text: 'Enter Friendly ID before adding.',
				type: 'error',
			})
		} else {
			Globals.New_Axios()
				.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/users/friendly_user/${friendlyId}`)
				.then(res => {
					const data = res.data

					setForm({
						first_name: data.first_name,
						last_name: data.last_name,
						email: data.email,
						company_name: data.company_name,
						street: data.street,
						country: data.country,
						city: data.city,
						state: data.state,
						zip_code: data.zip_code,
						website: data.website,
						certificate: data.certificate,
						phone_number: data.phone,
					})
				})
				.catch(err => {
					props.addToast({
						title: 'Add Customer',
						text: err.response?.data?.message || 'Customer not found',
						type: 'error',
					})
				})
		}
	}

	const handleChange = $event => {
		if ($event.target.name === 'phonenumber' || $event.target.name === 'secondary_shipping_phone_number') {
			setForm({
				...form,
				[$event.target.name]: formatPhoneNumber($event.target.value.trim()),
			})
		} else if ($event.target.name === 'use_company_address_as_default') {
			setDefaultShippingAddress($event.target.checked)
			setForm({
				...form,
				[$event.target.name]: $event.target.checked,
			})
		} else if ($event.target.name === 'email') {
			setForm({
				...form,
				[$event.target.name]: $event.target.value.trim(),
			})
		}
		 else if ($event.target.name === 'company_name' && $event.target.value.trim().length > 50) {
			props.addToast({
				title: 'Add Customer',
				text: 'Company name should not exceed 50 characters',
				type: 'error',
			})
		} else {
			setForm({
				...form,
				[$event.target.name]: $event.target.value,
			})
		}
	}

	const handleBlur = $event => {
		setForm({
			...form,
			[$event.target.name]: $event.target.value.trim(),
		})
	}

	const handleChangeAddress = event => {
		event.preventDefault()
		let newAddresses = cloneDeep(addresses)[0]
		newAddresses = {
			...newAddresses,
			[event.target.name]: event.target.value,
		}
		addresses[0] = newAddresses
		setAddresses([...addresses])
		setInitAddresses([...addresses])
	}

	const handleSubmit = $event => {
		$event.preventDefault()
		if (sendingData) return

		let errors = []

		const requiredAddressFields = ['address', 'city', 'state', 'country', 'zip_code']
		const requiredPhoneFields = ['phone_number', 'phone_type']

		// Validate addresses
		if (addresses.length > 0) {
			form.customer_addresses_attributes = addresses

			const defaultBillingAddress = addresses.find(address => address.is_billing_default && !address._destroy)
			const defaultShippingAddress = addresses.find(address => address.is_shipping_default && !address._destroy)

			if (!defaultBillingAddress) {
				errors = {...errors, defaultBillingAddress: 'Default billing address is required'}
			}

			if (!defaultShippingAddress) {
				errors = {...errors, defaultShippingAddress: 'Default shipping address is required'}
			}

			addresses.forEach((value, index) => {
				if (value.primary && !value._destroy) {
					if (!value.phone_number && phoneNumbers.length > 0) {
						addresses[index].phone_number = phoneNumbers[0];
					}
				}
			});

			form.customer_addresses_attributes = addresses.map(address => ({
				...address,
				phone_number: address.phone_number
			}));

			addresses.forEach((address, index) => {
				requiredAddressFields.forEach(field => {
					if (!address[field]) {
						errors[`address_${index}_${field}`] = `${field.replace('_', ' ')} is required`
					}
				})
			})
		}

		// Validate phone numbers
		if (phoneNumbers.length > 0) {
			const phoneNumbersHaveValue = phoneNumbers.filter(phone => phone.phone_number.length > 0)
			form.customer_phone_numbers_attributes = phoneNumbersHaveValue
			phoneNumbersHaveValue.forEach((phone, index) => {
				requiredPhoneFields.forEach(field => {
					if (!phone[field]) {
						errors[`phone_${index}_${field}`] = `${field.replace('_', ' ')} is required`
					}
				})
			})
		}

		if(!!form.company_name) {
			form.company_name = form.company_name.trim()
		}

		setFormErrors(errors)

		if (!isEmpty(errors)) {
			return Object.values(errors)
				.reverse()
				.map(error => {
					return props.addToast({
						title: 'Cannot add new contact',
						text: error,
						type: 'error',
					})
				})
		}
		if (isFunction(handlePrimaryTab)) handlePrimaryTab()

		setSendingData(true)
		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers`, {
				customer: {...form, status: 'active'},
			})
			.then(response => {
				setSendingData(false)
				setForm({})
				handleLoadCustomers()
				isFunction(handleVisibleSingleOrderItem) && handleVisibleSingleOrderItem(response.data)
				setTimeout(() => {
					setSendingData(false)
					setForm({})
					setFormErrors({})
					handleCloseForm($event)
				}, 1000)

				return props.addToast({
					title: 'Inventory Customers',
					text: 'Customer has been added successfully',
					type: 'success',
				})
			})
			.catch(error => {
				setSendingData(false)
				const errorMessages = error?.response?.data?.messages || 'Something went wrong please try again later.'

				return props.addToast({
					text: errorMessages.replaceAll("Customer phone numbers phone number", ''),
					type: 'error',
				})
			})
	}

	// Action for address

	const handleOpenModal = index => {
		setCurrentAddressIndex(index)
		setIsModalOpen(true)
	}

	const handleModalClose = () => {
		setIsModalOpen(false)
		setCurrentAddressIndex(-1)
	}

	const handleAddAdditionalAddressField = () => {
		handleOpenModal(-1)
		if (!isAllAddressesValid()) {
			return props.addToast({
				text: 'Please fill out the current address before adding a new one',
				type: 'error',
			})
		}

		handleOpenModal(-1)
	}

	const onChangePrimaryAddress = (e) => {
		const newAddresses = cloneDeep(addresses)
		const primaryAddressIndex = findIndex(addresses, {primary: true})
		newAddresses[primaryAddressIndex].full_address = e.target.value
		const undeletedAddresses = newAddresses.filter(address => !address._destroy)
		setAddresses(newAddresses)
		setInitAddresses(undeletedAddresses)
	}

	const handleSaveAddress = newAddress => {
		let newAddresses = cloneDeep(addresses)
		if (newAddress.is_billing_default) {
			newAddresses.map((address, index) => {
				address.is_billing_default = false
				return address
			})
		}

		if (newAddress.is_shipping_default) {
			newAddresses.map((address, index) => {
				address.is_shipping_default = false
				return address
			})
		}

		if (currentAddressIndex > -1) {
			// Note: Add a new address
			newAddresses[currentAddressIndex] = newAddress
		} else {
			// Edit a exiting address
			newAddresses.push(newAddress)
		}

		newAddresses = checkAndSetDefaultAddressIfMissing(newAddresses)

		setAddresses(newAddresses)
		setInitAddresses(newAddresses)
		setIsModalOpen(false)
		setCurrentAddressIndex(-1)
	}

	const handleRemoveNewAddress = (addressIndex) => {
		if (addressIndex < 0) {
			// Note: While adding new and then user cancel
			return
		}

		let newAddresses = cloneDeep(addresses)
		newAddresses[addressIndex]['_destroy'] = true
		newAddresses = checkAndSetDefaultAddressIfMissing(newAddresses, addressIndex)
		const undeletedAddresses = newAddresses.filter(address => !address._destroy)
		setInitAddresses(undeletedAddresses)
		setAddresses(newAddresses)
	}

	// Action for phone number

	const handleAddPhoneNumberField = () => {
		if (!isAllPhoneNumbersValid()) {
			return props.addToast({
				text: 'Please fill out the phone number before adding a new one',
				type: 'error',
			})
		}

		const selectedTypes = renderedPhoneNumbers(phoneNumbers).map(phone => phone.phone_type)
		const phoneTypes = phoneTypeList.map(phoneType => phoneType.phone_type)

		let availablePhoneTypes = xor(phoneTypes, selectedTypes);
		let newType = availablePhoneTypes[0] || 'custom'

		setPhoneNumbers([...phoneNumbers, {phone_number: '+1', phone_type: newType}])
	}

	const isAllPhoneNumbersValid = () => {
		const emptyPhoneNumbers = phoneNumbers.filter(item => item.phone_number.length < 9)
		return emptyPhoneNumbers.length === 0
	}

	const handleRemovePhoneNumberField = index => {
		const updatedPhoneNumbers = cloneDeep(phoneNumbers)
		updatedPhoneNumbers[index]['_destroy'] = true

		const sortedPhoneNumbers = updatedPhoneNumbers.sort((a, b) =>
		  a._destroy === b._destroy ? 0 : a._destroy ? 1 : -1
		);

		setPhoneNumbers(sortedPhoneNumbers)
	}

	const handlePhoneTypeChange = (index, type, label) => {
		let updatedPhoneNumbers = []
		if (type === 'custom') {
			updatedPhoneNumbers = phoneNumbers.map((phone, i) => (i === index ? {...phone, phone_type: type, phone_custom_type: label} : phone))
		} else {
			updatedPhoneNumbers = phoneNumbers.map((phone, i) => (i === index ? {...phone, phone_type: type} : phone))
		}
		setPhoneNumbers(updatedPhoneNumbers)
		setIsOpenDropdownPhoneType(false)
		setIsCustomType(false)
	}

	const handlePhoneNumberChange = (index, event) => {
		let input = event.target.value;
		let startsWithPlus = input.startsWith('+');
		if (startsWithPlus) {
			input = input.slice(1);
		}
		const updatedPhoneNumbers = cloneDeep(phoneNumbers);
		const cursorPos = event.target.selectionStart;

		const shouldRemoveAdditionalDigit = input.indexOf('(') !== -1 &&
        input.indexOf('(') === input.lastIndexOf('(') &&
        input.indexOf(')') === -1;

		if (shouldRemoveAdditionalDigit) {
			input = input.slice(0, -1); // Remove an additional digit at the end
		}
		try {
			let formattedNumber = input;

			// Use AsYouType formatter for dynamic formatting
			const asYouType = new AsYouType('US');
			formattedNumber = asYouType.input(input);

			// updatedPhoneNumbers[index].phone_number = formattedNumber;
			if (startsWithPlus) {
				updatedPhoneNumbers[index].phone_number = `+${formattedNumber}`;
			}else {
				updatedPhoneNumbers[index].phone_number = formattedNumber;
			}
			flushSync(() => {
				setPhoneNumbers(updatedPhoneNumbers);
			});

			// Adjust cursor position based on formatting
			const newPos = cursorPos + (formattedNumber.length - input.length);
			event.target.setSelectionRange(newPos, newPos);

		} catch (error) {
			console.warn('Invalid phone number:', error);
			updatedPhoneNumbers[index].phone_number = input;
			setPhoneNumbers(updatedPhoneNumbers);
		}
	}



	const handleAddCustomType = (index) => {
		const newCustomType = {phone_label: customType, phone_type: 'custom'}
		setIsCustomType(false)
		setCustomType('')
		setPhoneTypeList(pre => {
			const newPhoneTypeList = [...pre]
			newPhoneTypeList.push(newCustomType)
			return newPhoneTypeList
		})
		handlePhoneTypeChange(index, newCustomType.phone_type, newCustomType.phone_label)
	}

	const handleCancelCustomType = index => {
		setIsCustomType(false)
		setCustomType('')
		setIsOpenDropdownPhoneType(prevState => ({...prevState, [index]: false}))
	}

	const toggleDropdownPhoneType = index => {
		setIsOpenDropdownPhoneType(prevState => ({...prevState, [index]: !prevState[index]}))
	}

	const isAllAddressesValid = () => {
		const emptyAddresses = addresses.filter(item => !item.street)
		return emptyAddresses.length === 0
	}

	const handleVisibleManualForm = () => {
		if(!isManual) {
			if(!addresses[0].address) {
				addresses[0] = {
					...addresses[0],
					address: addresses[0].full_address,
					is_manual: !isManual
				}
				setAddresses([...addresses])
				setInitAddresses([...addresses])
			}
		} else {
			addresses[0] = {
				...addresses[0],
				address: newAddress,
				is_manual: !isManual
			}
			setAddresses([...addresses])
			setInitAddresses([...addresses])
		}
		setIsManual(!isManual)
	}

	return (
		<>
			<div
				onKeyDown={onPressEscape(event => handleCloseForm(event))}
				className="add-new-customer bg-black/50 bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50 md:grid"
				tabIndex="-1"
			>
				<div className="max-w-[670px] w-full h-screen flex flex-col justify-center">
					<div className="w-full mt-auto md:mt-[unset]">
						<div className="bg-[#4B7CBE] md:bg-[#5D6E81] flex items-center px-8 py-4 relative rounded-t-xl text-white w-full md:h-[67px]">
							<span className="text-white font-bold text-[24px]">Add New Contact</span>
							<button
								className="absolute right-0 mr-5 cursor-pointer"
								onClick={e => {
									handleCloseForm(e)
								}}
							>
								<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>
						</div>
						<div className="h-[80vh]  bg-white text-gray-500 rounded-b-xl md:px-[28px] max-h-[700px]  px-8 md:py-[unset] overflow-y-scroll">
							<form onSubmit={handleSubmit} className="bg-white py-[26px]">
								<Box sx={{display: 'flex', flexDirection: 'row', gap: '5px'}}>
									<TextField
										label="Add Using Friendly ID &nbsp;"
										variant="outlined"
										value={friendlyId || ''}
										onChange={handleFriendlyId}
										type="text"
										name="friendly-id"
										fullWidth
										InputLabelProps={{
											sx: {
												color: '#D1D3D4',
												fontWeight: 'bold',
											},
										}}
										InputProps={{
											sx: {
												height: '49px',
											},
										}}
									/>
									<Button
										variant="contained"
										onClick={searchFriendlyId}
										disableElevation={true}
										sx={{p: '12px 14px', borderRadius: '5px', backgroundColor: '#2C79E0', color: '#fff', height: '49px', fontWeight: 'bold', textTransform: 'none'}}
									>
										Add
									</Button>
								</Box>

								{/* Company Information */}
								<h3 className="mt-[25px] mb-[16px] text-[20px] font-semibold text-[#5D6E81]">Company Information</h3>
								<Grid container columnSpacing={1.5} rowSpacing={1.5}>
									<Grid item xs={12}>
										<div className="">
											<div className="outlin relative w-full h-[49px]">
												<input
													defaultValue={form?.company_name}
													value={form?.company_name || ''}
													type="text"
													name="company_name"
													placeholder=" "
													className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-sm border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Company Name</label>
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="">
											<div className="outlin relative w-full h-[49px]">
												<input
													defaultValue={form?.first_name}
													value={form?.first_name}
													type="text"
													name="first_name"
													placeholder=" "
													className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">First Name</label>
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="">
											<div className="outlin relative w-full h-[49px]">
												<input
													defaultValue={form?.last_name}
													value={form?.last_name}
													type="text"
													name="last_name"
													placeholder=" "
													className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Last Name</label>
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="">
											<div className="outlin relative w-full h-[49px]">
												<input
													defaultValue={form?.email}
													value={form?.email}
													type="text"
													name="email"
													placeholder=" "
													className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
													onChange={handleChange}
												/>
												<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Email</label>
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<div className="">
											<div className="outlin relative w-full h-[49px]">
												<input
													defaultValue={form?.certificate}
													value={form?.certificate}
													type="text"
													name="certificate"
													placeholder=" "
													className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Resale Certificate</label>
											</div>
										</div>
									</Grid>
									{renderedPhoneNumbers(phoneNumbers).map((phoneNumber, index) => {
										const originalIndex = phoneNumbers.findIndex(phone => phone === phoneNumber);
										if (!phoneNumber._destroy) {
											return (
												<Grid key={index} item xs={12} sm={index > 1 ? 12 : 6} sx={{display: 'flex', justifyContent: index > 1 ? 'flex-end' : 'flex-start'}}>
													<div className={`w-full flex ${index > 1 && "md:!w-1/2 md:pl-[6px]"}`}>
														<div className="outlin relative h-[49px] flex-grow">
															<input
																value={phoneNumber?.phone_number}
																type="text"
																name={`phone_number_${index}`}
																placeholder=" "
																className="block pl-[12px] pr-[70px] pt-[12px] pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
																onChange={event => handlePhoneNumberChange(originalIndex, event)}
															/>
															<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Phone Number</label>
															<div className="absolute top-0 right-0" onClick={() => toggleDropdownPhoneType(index)}>
																<div className=" h-[49px] relative flex items-center cursor-pointer">
																	<div className="text-[14px] text-[#5D6E81BF] font-semibold">
																		{phoneNumber?.phone_type === 'custom' ? phoneNumber.phone_custom_type : capitalizeFirstLetter(phoneNumber?.phone_type)}
																	</div>
																	<img src="./Images/arrow_up_down.png" alt="" />
																</div>
															</div>
															{isOpenDropdownPhoneType[index] && (
																<div
																	ref={dropdownRef}
																	className="absolute top-[49px] right-0 w-auto max-w-[400px] bg-white border-[#D9E2EE] border-t-0 rounded-b-[12px] shadow-md z-10"
																>
																	{phoneTypeList.map((phoneType, i) => (
																		<div
																			key={i}
																			className="py-2 px-4 text-[14px] text-[#5D6E81] font-semibold cursor-pointer hover:bg-[#F7F8FA]"
																			onClick={() => handlePhoneTypeChange(originalIndex, phoneType.phone_type, phoneType.phone_label)}
																		>
																			{phoneType.phone_label}
																		</div>
																	))}
																	{isCustomType ? (
																		<div className="relative">
																			<input
																				value={customType}
																				type="text"
																				name={customType?.toLowerCase()}
																				placeholder="Please enter custom type"
																				className="py-2 pl-4 pr-[66px] h-[35px] text-[14px] text-[#5D6E81] font-semibold border-t"
																				onChange={event => setCustomType(event.target.value)}
																				onKeyDown={(e) => (e.key === Globals.keyboardButton.enter && handleAddCustomType(originalIndex))}
																			/>
																			<div className="absolute h-full top-0 right-0 cursor-pointer flex">
																				<span className="p-2"
																					onClick={() => handleAddCustomType(originalIndex)}
																				>
																					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-[16px] h-[16px]">
																						<path
																							fill="#005c0f"
																							d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
																						/>
																					</svg>
																				</span>
																				<span className="p-2" onClick={() => handleCancelCustomType(originalIndex)}>
																					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="w-[16px] h-[16px]">
																						<path
																							fill="#595959"
																							d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
																						/>
																					</svg>
																				</span>
																			</div>
																		</div>
																	) : (
																		<div className="py-2 px-4 text-[14px] text-[#5D6E81] font-semibold cursor-pointer hover:bg-[#F7F8FA]" onClick={() => setIsCustomType(true)}>
																			Custom
																		</div>
																	)}
																</div>
															)}
														</div>
														<button className="ml-2" type="button" onClick={() => (index !== 0 ? handleRemovePhoneNumberField(index) : handleAddPhoneNumberField())}>
															<img src={`/Images/${index !== 0 ? 'minus_circle' : 'plus_circle'}.png`} alt="" height={23} width={26} />
														</button>
													</div>
												</Grid>
											)
										}
									})}
								</Grid>

								{/* Address */}
								<h3 className="mt-[30px] mb-[16px] text-[20px] font-semibold text-[#5D6E81]">Address</h3>
								{addresses.map((address, index) => {
									if (!address._destroy) {
										return(
											<div className="w-full flex mt-[10px]" key={index}>
												<div className="outlin relative h-auto flex-grow">
													{!isManual ?
														<>
															{initAddresses.length > 1 ? (
																<>
																	<input
																		onClick={() => initAddresses.length > 1 && handleOpenModal(index)}
																		className={`top-[0] block pl-[12px] pr-[130px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE] nowrap truncate overflow-hidden cursor-pointer ${ !address.primary ? 'hover:text-clip' : '' }`}
																		readOnly={true}
																		value={showAddressName(form, address) || ''}
																	/>
																	<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Address</label>
																</>
                              ) : (
                                <Grid container columnSpacing={1.5} rowSpacing={1.5}>
                                  <Grid item xs={12} sm={9}>
                                    <ServerAutoSuggestAddress
                                      formData={{full_address: showAddressName(form, address) || ''}}
                                      handleChangeGoogleAddress={handleChangeGoogleAddress}
                                      className={'block pl-[12px] pr-[130px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE] nowrap truncate overflow-hidden hover:text-clip'}
                                      view={'customer'}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <input
                                      type="text"
                                      name="apt_unit_number"
                                      placeholder=" "
                                      value={`${address.apt_unit_number}` || ''}
                                      onChange={handleChangeAddress}
                                      className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
                                    />
                                    <label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
                                      Apt/Unit #
                                    </label>
                                  </Grid>
                                </Grid>
                              )}
															<div>
																{index == 0 &&
																	<span
																		onClick={handleVisibleManualForm}
																		className='text-xs mt-[1px] cursor-pointer underline'
																	>
																		Add Manually
																	</span>
																}
															</div>
														</>
														:
														index === 0 ?
														<Grid container columnSpacing={1.5} rowSpacing={1.5}>
															<Grid item xs={12} sm={9}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																		<input
																			type="text"
																			name={'address'}
																			role="presentation"
																			autocomplete="off"
																			defaultValue={address?.address}
																			placeholder=" "
																			className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
																			onChange={handleChangeAddress}
																		/>
																		<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Street *</label>
																	</div>
																</div>

																<span
																	onClick={handleVisibleManualForm}
																	className='text-xs mt-[1px] cursor-pointer underline'
																>
																	Use Google autocomplete
																</span>
															</Grid>
															<Grid item xs={12} sm={3}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																	<input
																		type="text"
																		name="apt_unit_number"
																		placeholder=" "
																		value={address?.apt_unit_number || ''}
																		onChange={handleChangeAddress}
																		className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
																	/>
																	<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
																		Apt/Unit #
																	</label>
																	</div>
																</div>
														    </Grid>
															<Grid item xs={12} sm={6}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																		<input
																			type="text"
																			name="city"
																			defaultValue={address?.city}
																			placeholder=" "
																			className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
																			onChange={handleChangeAddress}
																		/>
																		<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">City *</label>
																	</div>
																</div>
															</Grid>
															<Grid item xs={12} sm={6}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																		<input
																			defaultValue={address?.state}
																			type="text"
																			name="state"
																			placeholder=" "
																			className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
																			onChange={handleChangeAddress}
																		/>
																		<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">State *</label>
																	</div>
																</div>
															</Grid>
															<Grid item xs={12} sm={6}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																		<input
																			defaultValue={address?.zip_code}
																			type="text"
																			name="zip_code"
																			placeholder=" "
																			className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
																			onChange={handleChangeAddress}
																		/>
																		<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">Zip Code *</label>
																	</div>
																</div>
															</Grid>
															<Grid item xs={12} sm={6}>
																<div className="">
																	<div className="outlin relative w-full h-[49px]">
																		<select
																			id="country"
																			name="country"
																			className="block px-[12px] w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
																			defaultValue={find(Globals.countries, (value) => value.name === address?.country)?.code || ''}
																			onChange={handleChangeAddress}
																		>
																			<option value="" disabled>
																				Choose a country *
																			</option>
																			{Globals.countries.map(c => (
																				<option key={c.code} value={c.code}>
																					{c.name}
																				</option>
																			))}
																		</select>
																	</div>
																</div>
															</Grid>
														</Grid> :
														<input
															value={showAddressName(form, address) || ''}
															type="text"
															name={`address_${index}`}
															onClick={() => !address.primary && handleOpenModal(index)}
															placeholder=" "
															readOnly={!address.primary}
															className="block pl-[12px] pr-[130px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE] nowrap truncate overflow-hidden hover:text-clip"
															onChange={address.primary ? onChangePrimaryAddress : handleChange}
														/>
													}
													<>
														<div
                              className={`absolute top-[5px] flex flex-col items-center min-w-[112px] leading-normal`}
                              style={{right: initAddresses.length > 1 ? '10px' : '160px'}}
                            >
															{address.is_billing_default || address.is_shipping_default ? (
																<>
																	<div className="font-bold text-[10px] nowrap text-[#D1D3D4]">Default</div>
																	<div className="font-bold text-[12px] nowrap text-[#5D6E81]">
																		{address.is_billing_default && address.is_shipping_default ? 'Billing & Shipping' : address.is_billing_default ? 'Billing' : 'Shipping'}
																	</div>
																</>
															) : null}
														</div>
													</>
												</div>
											</div>
										)
									}

								})}

								{/* Add New Address Modal */}
								{
									isModalOpen && (
										<AddressModal
											formData={form}
											setForm={setForm}
											currentAddress={addresses[currentAddressIndex]}
											currentAddressIndex={currentAddressIndex}
											open={isModalOpen}
											handleClose={handleModalClose}
											onSaveAddress={handleSaveAddress}
											onRemoveNewAddress={handleRemoveNewAddress}
											addToast={props.addToast}
											isManual={isManual}
											phoneNumbers={phoneNumbers}
											handlePhoneNumberChange={handlePhoneNumberChange}
											addresses={addresses}
										/>
									)
								}
								<span
									className="flex items-center text-[#5D6E81BF] text-[12px] font-semibold underline underline-offset-2 mt-[22px] ms-[21px] cursor-pointer w-auto"
									onClick={handleAddAdditionalAddressField}
								>
									Add Additional Address
								</span>
								<div className={Classnames('flex flex-row justify-center space-x-4 mt-[20px]', {'pt-8': defaultShippingAddress})}>
									<button
										type="button"
										className="border-[1px] border-[#FF000080] cursor-pointer text-[#FF000080] rounded px-[14px] py-[12px] w-[93px] h-[49px] hover:bg-[#FF000080] hover:text-white font-semibold"
										onClick={e => {
											handleCloseForm(e)
										}}
									>
										Cancel
									</button>
									<button className="bg-[#4B7CBE] text-white rounded px-[14px] py-[12px] w-[93px] h-[49px] hover:bg-[#2C79E0] hover:text-white font-semibold" type="submit">
										Save
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export const AddressModal = ({open, handleClose, formData, onSaveAddress, onRemoveNewAddress, currentAddress, currentAddressIndex, addToast, setFormData, phoneNumbers, handlePhoneNumberChange, addresses}) => {
	const [newAddress, setNewAddress] = useState({
		id: currentAddress?.id,
		company_name: currentAddress?.company_name ? currentAddress.company_name : currentAddress?.company_name === "" ? "" : formData?.company_name || '',
		contact_name: currentAddress?.contact_name ? currentAddress.contact_name : currentAddress?.contact_name === "" ? "" : formatContactName(formData?.first_name, formData?.last_name),
		street: currentAddress?.street || '',
		apt_unit_number: currentAddress?.apt_unit_number || '',
		city: currentAddress?.city || '',
		state: currentAddress?.state || '',
		country: currentAddress?.country || '',
		zip_code: currentAddress?.zip_code || '',
		full_address: currentAddress?.address_without_suite || '',
		phone_number: currentAddress?.phone_number || '',
		is_billing_default: currentAddress?.is_billing_default || false,
		is_shipping_default: currentAddress?.is_shipping_default || false,
		is_manual: currentAddress?.is_manual || false,
		primary: currentAddress?.primary || false,
	})
	const [addressError, setAddressError] = useState('')
	const addressInputRef = useRef(null)
	const [address, setAddress] = useState('')

	const handleChangeGoogleAddress = (place) => {
		const tmpAddress = []
    let subpremise
		if (!place.address_components) return

		(place.address_components || []).forEach(value => {
			if (value.types.includes('street_number') || value.types.includes('route')) {
        tmpAddress.push(value.long_name)
			}

      if (value.types.includes('subpremise')) {
        const match = value.long_name.match(/\d+$/);
        subpremise = match ? match[0] : value.long_name;
      }

			const isCheckCountries = handleCheckCountries(value)
			if(!isCheckCountries) {
				const part =  place.formatted_address.split(", ")
				tmpAddress.push(part[0])
			}
		})

		let city = place.address_components.find(component => component.types.includes('locality'))?.long_name || ''
		if(!city) {
			city = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || ''
		}

		const streetNumber = place.address_components.find(component => component.types.includes('street_number'))?.long_name || ''
		const streetName = place.address_components.find(component => component.types.includes('route'))?.long_name || ''

		const searchedAddress = {
			full_address: place.formatted_address,
			address_without_suite: place.formatted_address.replace(` ${subpremise},`, ''),
			address: tmpAddress?.toString().replace(',', ' ').trimStart(),
			street: (streetNumber ? streetNumber + ' ' : '' )+ streetName,
			city: city,
			state: place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '',
			country: place.address_components.find(component => component.types.includes('country'))?.long_name || '',
			zip_code: place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '',
		}

    if(subpremise) {
      searchedAddress.apt_unit_number = subpremise
    }

		setNewAddress(assignIn({}, newAddress, searchedAddress))
		setAddress(searchedAddress.address)
	}


	useEffect(() => {
		if(currentAddressIndex === 0 && phoneNumbers.length > 0) {
			setNewAddress(prevAddress => ({
				...prevAddress,
				phone_number: phoneNumbers[0].phone_number
			}))
		}
	}, [])

	const handleChange = event => {
		const {name, value, type, checked} = event.target
		setNewAddress(prevAddress => ({
			...prevAddress,
			[name]: type === 'checkbox' ? checked : value || '',
		}))
	}

	const handlePhoneNumberChangeModal = event => {
		const input = event.target.value
		let formattedNumber = formatPhoneNumber(input)

		setNewAddress(prevAddress => ({
			...prevAddress,
			phone_number: formattedNumber,
		}))
		if(currentAddressIndex === 0) {
			handlePhoneNumberChange(0, event)
		}
	}

	const handleSave = () => {
		let {company_name, contact_name, street, apt_unit_number, city, state, country, zip_code, phone_number, id, is_billing_default, is_shipping_default, primary} = newAddress

		const missingFields = []
		let errorMessages = []
		if (!street) missingFields.push('Street')
		if (!city) missingFields.push('City')
		if (!state) missingFields.push('State')
		if (!country) missingFields.push('Country')
		if (!zip_code) missingFields.push('Zip Code')
		const primaryAddressIndex = findIndex(addresses, (value) => {	return value.id === id && !!value.primary })

		if(primaryAddressIndex === currentAddressIndex && addresses.length > 1) {
			if(!!addresses[primaryAddressIndex]?.is_billing_default && !is_billing_default) {
				errorMessages.push('billing dddress')
			}
			if(!!addresses[primaryAddressIndex]?.is_shipping_default && !is_shipping_default) {
				errorMessages.push('shipping address')
			}
		}

		if (missingFields.length > 0) {
			setAddressError(`Your address is missing: ${missingFields.join(', ')}`)
			return
		}

		if (!company_name && !contact_name) {
			setAddressError('Company Name or Contact Name is required')
			return
		}

		if (errorMessages.length > 0) {
			setAddressError(`Must have default: ${errorMessages.join(', ')}`)
			return
		}

		let buildAddress = newAddress
		if(newAddress.is_manual) {
			buildAddress = {
				...newAddress,
				full_address: `${address}${apt_unit_number ? `, ${apt_unit_number}` : ''}, ${city}, ${state}, ${zip_code}, ${country}`
			}
		}
		if(primary) {
			onSaveAddress(buildAddress)
			handleClose()
		} else {
			Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers/validate_phone_number`, {
				phone_number: phone_number
			})
			.then(response => {
				onSaveAddress(buildAddress)
				handleClose()
			})
			.catch(error => {
				const message = error.response?.data?.messages || 'Something went wrong please try again later.'
				addToast({
					title: 'Error',
					text: message,
					type: 'error',
				})
			})
		}
	}

	const handleDelete = () => {
		if (newAddress.primary) {
			const undeletedAddresses = addresses.filter(address => !address._destroy);
			const currentPrimaryIndex = undeletedAddresses.findIndex(address => address.primary);

			if (undeletedAddresses.length > 1) {
			  const nextPrimaryAddress = undeletedAddresses[currentPrimaryIndex + 1];
			  const isSameContact =
				nextPrimaryAddress.contact_name === formData?.company_name ||
				nextPrimaryAddress.contact_name === formatContactName(formData?.first_name, formData?.last_name);

				if (!isSameContact) {
					addToast({
					title: 'Error',
					text: 'Cannot delete the primary address if a secondary address has a different Company Name or Contact Name.',
					type: 'error',
					});

					return;
				}
			}
		}

		onRemoveNewAddress(currentAddressIndex, newAddress);
		handleClose();
	};

	const handleVisibleManualForm = () => {
		if(!newAddress.is_manual) {
			if(!newAddress.address) {
				const buildAddress = {
					...newAddress,
					address: newAddress.address,
					is_manual: !newAddress.is_manual
				}
				setNewAddress({...buildAddress})
			} else {
				setNewAddress({
					...newAddress,
					is_manual: !newAddress.is_manual
				})
			}
		} else {
			const buildAddress = {
				...newAddress,
				address: address,
				is_manual: !newAddress.is_manual
			}
			setNewAddress({...buildAddress})
		}
	}

	const isAddingNewAddress = currentAddressIndex < 0

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			sx={{
				'& .MuiDialog-paper': {
					width: '476px',
					height: 'auto',
					borderRadius: '12px 12px 0 0',
				},
			}}
		>
			<DialogTitle
				sx={{
					backgroundColor: '#5D6E81',
					color: '#fff',
					fontWeight: 'bold !important',
					fontSize: '17px',
					height: '55px',
				}}
			>
				{isAddingNewAddress ? 'ADD NEW' : 'EDIT'} ADDRESS
				{
					!isAddingNewAddress && (
						<button
							className="absolute right-0 mr-5 cursor-pointer"
							onClick={handleClose}
						>
							<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					)
				}
			</DialogTitle>
			<DialogContent
				sx={{
					color: '#5D6E81',
					padding: '26px 40px 0px 40px !important',
				}}
			>
				{!currentAddress?.primary &&
				 	<>
						<div className="">
							<div className="outlin relative w-full h-[49px]">
								<input
									defaultValue={newAddress.company_name || ''}
									type="text"
									name="company_name"
									placeholder=" "
									className={Classnames("block px-[12px] pt-5 pb-3 w-full h-[49px] appearance-none outline-none border-[#D9E2EE] text-[14px] !font-semibold text-[#5D6E81] bg-transparent inputLogin",)}
									onChange={({target: {value}}) => setNewAddress({...newAddress, company_name: value})}
								/>
								<label htmlFor="company_name" className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
									Company Name
								</label>
							</div>
						</div>
						<div className="mt-2">
							<div className="outlin relative w-full h-[49px]">
								<input
									defaultValue={newAddress.contact_name || ''}
									type="text"
									name="contact_name"
									placeholder=" "
									className={Classnames("block px-[12px] pt-5 pb-3 w-full h-[49px] appearance-none outline-none border-[#D9E2EE] text-[14px] !font-semibold text-[#5D6E81] bg-transparent inputLogin")}
									onChange={({target: {value}}) => setNewAddress({...newAddress, contact_name: value})}
								/>
								<label htmlFor="contact_name" className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
									Contact Name
								</label>
							</div>
						</div>
				 	</>
				}
				{!newAddress.is_manual ?
					<div>
						<div className="mt-2 flex gap-2 ">
							<div className="outlin relative w-full h-[49px]">
								<ServerAutoSuggestAddress
									formData={{full_address: newAddress.full_address || ''}}
									handleChangeGoogleAddress={handleChangeGoogleAddress}
									className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none bg-transparent border-[#D9E2EE] text-[14px] !font-semibold text-[#5D6E81] nowrap truncate overflow-hidden hover:text-clip"
									view={'additional_customer'}
								/>
							</div>
							<div className="outlin relative w-[calc(40%)] h-[49px]">
								<input
									type="text"
									name="apt_unit_number"
									placeholder=" "
									value={newAddress.apt_unit_number || ''}
									onChange={({target: {value}}) => setNewAddress({...newAddress, apt_unit_number: value})}
									className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] text-[#5D6E81] !font-semibold text-[14px]"
								/>
								<label className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
									Apt/Unit #
								</label>
							</div>
						</div>
						<div
							onClick={handleVisibleManualForm}
							className='text-xs mt-[1px] cursor-pointer underline'>
								Add Manually
						</div>
					</div>
					:
					<>
						<div>
							<div className="mt-2 flex gap-2">
								<div className="outlin relative w-[70%] h-[49px]">
									<input
										defaultValue={newAddress.street || ''}
										type="text"
										name="street"
										placeholder=" "
										className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none bg-transparent border-[#D9E2EE] text-[14px] !font-semibold text-[#5D6E81] nowrap truncate overflow-hidden hover:text-clip"
										onChange={({target: {value}}) => setNewAddress({...newAddress, street: value})}
									/>
									<label htmlFor="street" className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
										Street
									</label>
								</div>
								<div className="outlin relative w-[30%] h-[49px]">
									<input
										value={newAddress.apt_unit_number || ''}
										type="text"
										name="apt_unit_number"
										placeholder=" "
										className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none bg-transparent border-[#D9E2EE] text-[14px] !font-semibold text-[#5D6E81] nowrap truncate overflow-hidden"
										onChange={({target: {value}}) => setNewAddress({...newAddress, apt_unit_number: value})}
									/>
									<label htmlFor="full_address" className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
										Apt/Unit #
									</label>
								</div>
							</div>
							<div
								onClick={handleVisibleManualForm}
 								className='text-xs mt-[1px] cursor-pointer underline'>
									Use Google autocomplete
							</div>
						</div>
						<div className="mt-2 flex gap-2 ">
							<div className="outlin relative w-full h-[49px]">
								<input
									defaultValue={newAddress.city || ''}
									type="text"
									name="city"
									placeholder=" "
									className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none bg-transparent border-[#D9E2EE] text-[14px] !font-semibold text-[#5D6E81] nowrap truncate overflow-hidden"
									onChange={({target: {value}}) => setNewAddress({...newAddress, city: value})}
								/>
								<label htmlFor="full_address" className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
									City
								</label>
							</div>
							<div className="outlin relative w-full h-[49px]">
								<input
									defaultValue={newAddress.state || ''}
									type="text"
									name="state"
									placeholder=" "
									className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none bg-transparent border-[#D9E2EE] text-[14px] !font-semibold text-[#5D6E81] nowrap truncate overflow-hidden"
									onChange={({target: {value}}) => setNewAddress({...newAddress, state: value})}
								/>
								<label htmlFor="full_address" className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
									State
								</label>
							</div>
						</div>
						<div className="mt-2 flex gap-2 ">
							<div className="outlin relative w-full h-[49px]">
								<input
									defaultValue={newAddress.zip_code || ''}
									type="text"
									name="zip_code"
									placeholder=" "
									className="block px-[12px] pt-5 pb-3 w-full h-[49px] inputLogin appearance-none outline-none bg-transparent border-[#D9E2EE] text-[14px] !font-semibold text-[#5D6E81] nowrap truncate overflow-hidden"
									onChange={({target: {value}}) => setNewAddress({...newAddress, zip_code: value})}
								/>
								<label htmlFor="full_address" className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
									Zip Code
								</label>
							</div>
							<div className="outlin relative w-full h-[49px]">
								<select
									id="country"
									name="country"
									className="block px-[12px] w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
									defaultValue={find(Globals.countries, (value) => value.name === newAddress?.country)?.code || ''}
									onChange={({target: {value}}) => setNewAddress({...newAddress, country: value})}
									>
									<option value="" disabled>
										Choose a country *
									</option>
									{Globals.countries.map(c => (
										<option key={c.code} value={c.code}>
											{c.name}
										</option>
									))}
								</select>
							</div>
						</div>
					</>
				}
				{!currentAddress?.primary &&
					<div className="mt-2">
						<div className="outlin relative w-full h-[49px]">
							<input
								value={formatPhoneNumber(newAddress?.phone_number)}
								type="text"
								name="phone_number"
								placeholder=" "
								className="block pl-[12px] pr-[70px] pt-[12px] pb-3 w-full h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE]"
								onChange={handlePhoneNumberChangeModal}
							/>
							<label htmlFor="city" className="pointer-events-none absolute top-0 bg-transparent p-[12px] duration-300 origin-0 font-semibold text-[#D1D3D4]">
								Phone Number
							</label>
						</div>
					</div>
				}

				<div className="mt-[10px] pl-[9px] flex gap-x-3">
					<div className="flex items-center">
						<label htmlFor="is_billing_default">
							<input onChange={handleChange} type="checkbox" id="is_billing_default" name="is_billing_default" checked={newAddress.is_billing_default} className="hidden peer" />
							<div className="flex items-center text-center">
								<span
									htmlFor="is_billing_default"
									className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300"
								>
									{newAddress.is_billing_default && (
										<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
											<path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0" />
										</svg>
									)}
								</span>
								<span className="mt-1 text-[12px]">Default Billing</span>
							</div>
						</label>
					</div>
					<div className="flex items-center">
						<label htmlFor="is_shipping_default">
							<input onChange={handleChange} type="checkbox" id="is_shipping_default" name="is_shipping_default" checked={newAddress.is_shipping_default} className="hidden peer " />
							<div className="flex items-center text-center">
								<span
									htmlFor="is_shipping_default"
									className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300"
								>
									{newAddress.is_shipping_default && (
										<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
											<path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0" />
										</svg>
									)}
								</span>
								<span className="mt-1 font-normal text-[12px]">Default Shipping</span>
							</div>
						</label>
					</div>
				</div>
				{addressError && <div className="mt-2 text-red-600 text-center">{addressError}</div>}
			</DialogContent>
			<DialogActions
				sx={{
					justifyContent: 'center',
					margin: '20px auto 16px',
					padding: '0px !important',
				}}
			>
				<button
					type="button"
					className="border-[1px] border-[#FF000080] cursor-pointer text-[#FF000080] rounded w-[84px] h-[33px] hover:bg-[#FF000080] hover:text-white font-semibold text-[12px]"
					onClick={handleDelete}
				>
					{ isAddingNewAddress ? "Cancel" : "Delete" }
				</button>
				<Button
					onClick={handleSave}
					variant="contained"
					disableElevation={true}
					sx={{borderRadius: '5px', height: '33px', backgroundColor: '#2C79E0', color: '#fff', fontWeight: 'bold', textTransform: 'none', fontSize: '12px'}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default AddNewCustomer
