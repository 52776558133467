import {useState, useCallback, useMemo} from 'react'
import {Globals} from '../../../../../Context'
import {useDispatch} from 'react-redux'
import {setCurrentSpace} from '../../../../../app/appActions'

function useInvoiceSettings({currentSpace, addToast}) {
	const [isShowLimitReached, setIsShowLimitReached] = useState(false)
	const [loadedSpaceUsers, setLoadedSpaceUsers] = useState([])
	const [formErrors, updateFormErrors] = useState({})
	const [invoiceSettingsTemplates, setInvoiceSettingsTemplates] = useState({})
	const [selectedTemplate, setSelectedTemplate] = useState('default')
	const [isSpaceDataReady, setIsSpaceDataReady] = useState(false)
	const [isProcessingLogo, setIsProcessingLogo] = useState(false)
	const dispatch = useDispatch()
	const refreshCurrentSpace = useCallback(() => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}`)
			.then(response => {
				const receivedSpace = response.data
				dispatch(setCurrentSpace(response.data))
				return receivedSpace
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return addToast({
					text: message,
					type: 'error',
				})
			})
	}, [setCurrentSpace, addToast])

	const handleLoadUsers = useCallback(() => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/users`, {
				params: {
					q: {
						s: 'created_at desc',
					},
					per_page: 10000,
				},
			})
			.then(response => {
				let {users} = response.data
				setLoadedSpaceUsers(users)

				return users
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return addToast({
					text: message,
					type: 'error',
				})
			})
	}, [currentSpace, selectedTemplate, addToast])

	const loadBackendInvoiceSettings = useCallback(() => {
		setIsSpaceDataReady(false)

		return refreshCurrentSpace()
			.then(freshSpace => {
				const defaultInvoiceSettings = freshSpace?.invoice_settings.find(invoice_setting => invoice_setting.template_type === 'default') || {}
				const alternativeInvoiceSettings = freshSpace?.invoice_settings.find(invoice_setting => invoice_setting.template_type === 'alternative') || {}

				const autoFillTemplate = template => {
					return {
						...template,
						company_name: template.company_name || freshSpace.name || '',
						address: template.full_address || freshSpace.full_address || '',
						email: template.email || freshSpace.email || '',
						phone_number: template.phone_number || freshSpace.phone || '',
					}
				}

				setInvoiceSettingsTemplates({
					default: autoFillTemplate(defaultInvoiceSettings),
					alternative: autoFillTemplate(alternativeInvoiceSettings),
				})

				return handleLoadUsers()
			})
			.finally(() => {
				setIsSpaceDataReady(true)
			})
	}, [currentSpace, handleLoadUsers, refreshCurrentSpace])

	return {
		isShowLimitReached,
		setIsShowLimitReached,
		loadedSpaceUsers,
		formErrors,
		updateFormErrors,
		invoiceSettingsTemplates,
		setInvoiceSettingsTemplates,
		selectedTemplate,
		setSelectedTemplate,
		isSpaceDataReady,
		isProcessingLogo,
		setIsProcessingLogo,
		loadBackendInvoiceSettings,
		refreshCurrentSpace,
	}
}

export default useInvoiceSettings
