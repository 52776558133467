import {useState, useEffect, useRef} from 'react'
import {useSelector, connect} from 'react-redux'
import {NavLink, useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {Globals} from '../Context'
import Privacy from '../Components/Privacy'
import {addToast, logOut} from './../app/appActions'
import TermsOfUse from './TermsOfUse'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {buildUserProperties} from '../utils/AppcuesProperties'
import {checkAndHandleSingleSpace, updateCredentialsFromResponse, loginIntoSpace} from '../utils/UserSessionHelper'
import AppcuesService from '../services/AppcuesService'
import { formatPhoneNumber } from '../utils/PhoneNumberFormatter'
import { handlePhoneNumberChange } from '../utils/phoneNumberUtils';
import FormRegister from './FormRegister'
import isEmpty from 'lodash/isEmpty'
import {renderValidationItem} from '../utils/validationItem'
import { AsYouType } from 'libphonenumber-js';

const Register = props => {
	const {loggedin} = useSelector(state => state),
		[formData, updateFormData] = useState({}),
		[formCompanyAddress, setFormCompanyAddress] = useState({}),
		[complementaryData, setComplementaryData] = useState({}),
		[formErrors, updateFormErrors] = useState({}),
		[loading, setLoading] = useState(false),
		[selectedPlan, setSelectedPlan] = useState(null),
		[billingPeriod, setBillingPeriod] = useState('yearly'),
		[spaceId, setSpaceId] = useState(null),
		navigate = useNavigate()
	const [showPassword, setShowPassword] = useState({
		password: false,
		confirmPassword: false,
	})
	const [validations, setValidations] = useState({
		length: false,
		uppercase: false,
		lowercase: false,
		digit: false,
		specialChar: false,
	});

	const [showTerms, setShowTerms] = useState(false)
	const [isVisbleCompanyAddressAutoComplete, setIsVisbleCompanyAddressAutoComplete] = useState(false)
	const hideTerms = () => {
		setShowTerms(false)
	}

	const {token} = useParams()
	const [searchParams] = useSearchParams();
	const new_user = searchParams.get('new_user')
	const is_new_user = new_user === 'true'
	const {continuous, internalInvite, isUserSignedIn} = props
  const [isLoadingTokenData, setIsLoadingTokenData] = useState(!!token);
  const [isLoadInviteDataDone, setIsLoadInviteDataDone] = useState(false);

	const handleChange = e => {
		e.preventDefault()
		if(!!e.target.value) {
			if(formErrors[e.target.name]) formErrors[e.target.name] = ''
		}
		updateFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}

	const validatePassword = (password) => {
		const validationRules = {
			length: /.{7,}/,
			uppercase: /[A-Z]/,
			lowercase: /[a-z]/,
			digit: /[0-9]/,
			specialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
		};

		setValidations({
		  length: validationRules.length.test(password),
		  uppercase: validationRules.uppercase.test(password),
		  lowercase: validationRules.lowercase.test(password),
		  digit: validationRules.digit.test(password),
		  specialChar: validationRules.specialChar.test(password),
		});
	  };

	const handleChangePassword = e => {
		e.preventDefault()
		if(!!e.target.value) {
			if(formErrors[e.target.name]) formErrors[e.target.name] = ''
		}
		validatePassword(e.target.value);

		updateFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	}

	const handleOnChangePhoneNumber = e => {
		handlePhoneNumberChange({
            event: e,
            formErrors,
            formData,
            updateFormData,
        });
	}

	useEffect(() => {
		updateFormData({
			...formData,
			...formCompanyAddress
		})
	}, [formCompanyAddress])

	const handleSubmit = async (_event, options={}) => {
		const { plan, acceptToc} = options
		_event.preventDefault()
		let errors = {}
		//Make sure all the data is being correct.
		if ((!continuous || internalInvite) && isEmpty(formData.company_name)) errors = {...errors, company_name: 'Company name is required'}
		if ((is_new_user || internalInvite) && isEmpty(formData.name)) errors = {...errors, name: 'Please fill in your name'}
		if ((is_new_user || internalInvite) && isEmpty(formData.lastname)) errors = {...errors, lastname: 'Please fill in your last name'}
		if ((is_new_user || internalInvite || !continuous)) {
			if (isEmpty(formData.password)) {
				errors = {...errors, password: 'Password cannot be empty'}
			} else if (formData.password.length < 6) {
				errors = {...errors, password: 'password must be at least 6 characters long'}
			} else if (formData.password !== formData.confirm_password && (internalInvite || is_new_user)) {
				errors = {...errors, confirm_password: 'Confirmation password does not match'}
			}
		}

		if ((!continuous || internalInvite) && isEmpty(formData.company_address)) errors = {...errors, company_address: 'Company address is required'}
		if (isEmpty(formData.email) || !Globals.validateEmail(formData.email)) errors = {...errors, email: 'Company email is required'}
		if ((!continuous || internalInvite) && isEmpty(formData.phone)) errors = {...errors, phone: 'Company phone is required'}

		//if (!continuous && isEmpty(formData.reference)) errors = {...errors, reference: 'Reference is required'}
		//if (!continuous && isEmpty(formData.certificate))
		//errors = {...errors, certificate: 'Certificate is required'}

		if (!continuous && !formData.terms) errors = {...errors, terms: 'Accept terms and conditions'}
		if (continuous && !formData.terms && new_user === 'true') errors = {...errors, terms: 'Accept terms and conditions'}

		updateFormErrors(errors)
		if (!isEmpty(errors)) return

		setLoading(true)
		if(!!acceptToc) {
			formData['terms'] = true;
			setShowTerms(false)
		}

		if (!isEmpty(token) && continuous && !isEmpty(complementaryData)) {
			//This means the user is about to finish the account registration, and join a space straigh away.
			return Globals.New_Axios()
				.post(`${Globals.NEW_API_URL}/v1/accept_invite/${token}`, {
					user: {
						email: formData.email,
						first_name: formData.name,
						last_name: formData.lastname,
						password: formData.password,
						password_confirmation: formData.password_confirmation,
						accepted_tos: formData.terms
					},
					deviceId: Globals.deviceId,
				})
				.then(response => {
					setLoading(false)
					props.addToast({
						title: 'Registration',
						text: "Thank you for signing up!",
						type: 'success',
						duration: 1000000000,
					})

					const {data} = response;

          const isCurrentUser = isUserSignedIn && Globals.Cache.get(Globals.USERPROFILE) && JSON.parse(Globals.Cache.get(Globals.USERPROFILE)).email === data.user.email;

          if (!isCurrentUser) {
            updateCredentialsFromResponse(response)

            Globals.Cache.set(Globals.USERPROFILE, JSON.stringify(data.user))

            AppcuesService.initialize(data.user.id, buildUserProperties(data.user))
          }

					loginIntoSpace(data.space)

					setLoading(false)
          setIsLoadingTokenData(false)
					// return updateFormErrors({})
				})
				.catch(error => {
					setLoading(false)
          setIsLoadingTokenData(false)
					if(error?.response?.data?.message === 'You must accept the terms of service before continuing.' && error?.response?.status === 422) {
						setShowTerms(true)
						props.addToast({
							title: 'Error',
							text: error?.response?.data?.message || 'Something went wrong please try again later.',
							type: 'error',
						})
					} else {
						errors = {
							...errors,
							error: error.response?.data?.message || 'Something went wrong please try again later.',
						}
						updateFormErrors(errors)
					}
					return
				})
		}
		const registrationParams = {
			user: {
				email: formData.email,
				first_name: formData.name,
				last_name: formData.lastname,
				password: formData.password,
				password_confirmation: formData.password,
				accepted_tos: formData.terms,
				referred_by: formData.reference,
				spaces_attributes: [{
					name: formData.company_name,
					street: formData.company_address,
					apt_unit_number: formData.apt_unit_number,
					city: formData.city,
					state: formData.state,
					zip_code: formData.zip_code,
					country: formData.country,
					phone_number: formData.phone,
					resale_certificate: formData.certificate,
				}],
				info_references_attributes: [
					{
						full_name: formData.reference_full_name_1,
						company_name: formData.reference_company_name_1,
						email_or_phone: formData.reference_email_or_phone_number_1
					},
					{
						full_name: formData.reference_full_name_2,
						company_name: formData.reference_company_name_2,
						email_or_phone: formData.reference_email_or_phone_number_2
					},
				],

			},
			deviceId: Globals.deviceId,
		}

		if (internalInvite) {
			const spaceId = complementaryData.space.id
			registrationParams.invite_code = token
			registrationParams.user.spaces_attributes[0].id = spaceId
			registrationParams.user.password_confirmation = formData.confirm_password

			return Globals.New_Axios()
				.post(`${Globals.NEW_API_URL}/v1/users/onboarding`, registrationParams)
				.then(response => {
					const {data} = response;

          const isCurrentUser = isUserSignedIn && JSON.parse(Globals.Cache.get(Globals.USERPROFILE)).email === data.user.email;

          if (!isCurrentUser) {
            updateCredentialsFromResponse(response)

            Globals.Cache.set(Globals.USERPROFILE, JSON.stringify(response.data))

            AppcuesService.initialize(response.data.id, buildUserProperties(data))
          }

					checkAndHandleSingleSpace(data);

					setLoading(false)
          setIsLoadingTokenData(false)
          navigate('/register/internal_invite/success')
				}).catch(error => {
					setLoading(false)
          setIsLoadingTokenData(false)
					errors = {
						...errors,
						error: error.response?.data?.message || 'Something went wrong please try again later.',
					}
					updateFormErrors(errors)
				})
		}

		//This is for a frech profile with a space creation.
		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}`, registrationParams)
			.then(response => {
				setLoading(false)
				const spaceId = response.data.spaces[0].id
				setSpaceId(spaceId)
				if(plan === 'starter') {
					let params = {
						email: formData.email,
						space_id: spaceId ? spaceId : Globals.SELECTEDSPACEID,
						internal_subscription: {
							plan_type: plan,
							billing_period: billingPeriod,
						},
					}
					handleCreatePayment(spaceId, params)
					navigate('/thank-you')
				} else {
				}
			})
			.catch(error => {
				setLoading(false)
				errors = {
					...errors,
					error: error.response?.data?.message || 'Something went wrong please try again later.',
				}
				updateFormErrors(errors)
			})
	}

	const handleCreatePayment = async (id, params) => {
		await Globals.New_Axios().post(
			`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${id}/internal_subscriptions`,params
		);
	}

	const displayError = key => {
		if (!isEmpty(formErrors[key])) return <div className="inline-block text-sm my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{formErrors[key]}</div>
	}

	useEffect(() => {
		if (loggedin && !continuous) return navigate('/inventory')
	})

	useEffect(() => {
		if (!isEmpty(token) && continuous) {
			setLoading(true)

			Globals.New_Axios({logOutWhenUnauthorized: false})
				.post(`${Globals.NEW_API_URL}/v1/find_invited_user/${token}`)
				.then(async response => {
					setLoading(false)
          setIsLoadingTokenData(false)
					updateFormData({
						email: response.data.email,
					})


          if (isUserSignedIn && JSON.parse(Globals.Cache.get(Globals.USERPROFILE)).email !== response.data.email) {
            props.logOut(false);
          }

          setIsLoadInviteDataDone(true);

					return setComplementaryData({
						user: response.data.user,
						space: response.data.space,
					})
				})
				.catch(error => {
					//Display error message.
          const message = error?.response?.data?.message || 'Something went wrong please try again later.'

          if (message === "This link has either expired or been used already") {
            props.addToast({
              title: 'Space invitation',
              text: message,
              type: 'warning',
            })

            setTimeout(() => {
              setLoading(false)
              setIsLoadingTokenData(false)
              navigate('/login')
              props.logOut(false);
            }, 1000)
          } else {
              props.addToast({
                title: 'Space invitation',
                text: message,
                type: 'error',
              })
            }
          })
		}
	}, [props, continuous, token])

	useEffect(() => {
		if (!isEmpty(token) && internalInvite) {
			setLoading(true)

			Globals.New_Axios({logOutWhenUnauthorized: false})
				.post(`${Globals.NEW_API_URL}/v1/validate_internal_invite/${token}`)
				.then(async response => {
					setLoading(false)
          setIsLoadingTokenData(false)

					updateFormData({
						name: response.data.user.first_name,
						lastname: response.data.user.last_name,
						email: response.data.user.email,
						company_name: response.data.space.name,
						company_address: response.data.space.street,
						apt_unit_number: response.data.space.apt_unit_number,
						city: response.data.space.city,
						state: response.data.space.state,
						zip_code: response.data.space.zip_code,
						country: response.data.space.country,
						phone: response.data.space.phone_number,
						certificate: response.data.space.resale_certificate,
					})

          if (isUserSignedIn && JSON.parse(Globals.Cache.get(Globals.USERPROFILE)).email !== response.data.email) {
            props.logOut(false, false, true)
          }

          setIsLoadInviteDataDone(true);

					return setComplementaryData({
						user: response.data.user,
						space: response.data.space,
					})
				})
				.catch(error => {
          if (error.response?.data?.message === "already accepted the invitation" && token !== 'success') {
            props.addToast({
              title: 'Error',
              text: 'You have already accepted this invitation.',
              type: 'error',
            })
          }

					setTimeout(() => {
  					setLoading(false)
            setIsLoadingTokenData(false)
						navigate('/login')
					}, 1000)
				})
		}
	}, [props, internalInvite, token])

	const autocompleteInput = useRef(null);

  const textCompanyAddress = () => {
	if(internalInvite) {
		return `${formData.company_address}, ${formData.city}, ${formData.state} ${formData.zip_code}`
	} else if(!continuous && !isVisbleCompanyAddressAutoComplete) {
		return formData.full_address
	} else {
		return ''
	}
  }

  const handleCheckCountries = (value) => {
	if(value.long_name === 'Japan' && value.types.includes('country')) {
		if(!value.types.includes('street_number') || !value.types.includes('route')) {
			return false
		}
	}
	return true
  }

  const handleChangeGoogleAddress = (place) => {
		let form = {};
		let companyAddress = []
		place.address_components.map(value => {
			if (value.types.includes('postal_code')) {
				form = {
					...form,
					zip_code: value.long_name
				}
			}
			if (value.types.includes('locality')) {
				form = {
					...form,
					city: value.long_name
				}
			}
			if (value.types.includes('administrative_area_level_1')) {
				form = {
					...form,
					state: value.short_name
				}
			}
			if (value.types.includes('country')) {
				form = {
					...form,
					country: value.long_name,
					country_code: value.short_name
				}
			}

			const isCheckCountries = handleCheckCountries(value)
			if (value.types.includes('street_number') || value.types.includes('route')) {
				if(value.types.includes('street_number')) {
					companyAddress.push(value.long_name)
				} else {
					companyAddress.push(value.long_name)
				}
			}
			//Note: Check Japan Country
			if(!isCheckCountries) {
				const part =  place.formatted_address.split(", ")
				companyAddress.push(part[0])
			}
		})

		form = {
			...form,
			full_address: place.formatted_address,
			company_address: companyAddress.toString().replace(",", " "),
			selected_autocomplete_address: place.formatted_address
		}
		setFormCompanyAddress(form)
  }

  	const handleShowPassword = (key) => {
		setShowPassword({
			...showPassword,
			[key]: !showPassword[key],
		})
	}

  if (isLoadingTokenData) {
    return (
      <div className="bg-white/50 bottom-0 fixed flex items-center justify-center left-0 right-0 rounded-lg top-0 z-50">
        <div role="status">
          <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      </div>
    )
  }

	return (
		<div className="flex md:flex-row flex-col-reverse w-screen bgLinearBlue md:!bg-none md:!bg-[#2C79E0] min-h-screen">
			{loading && (
				<div className="bg-white/50 bottom-0 fixed flex items-center justify-center left-0 right-0 rounded-lg top-0 z-50">
					<div role="status">
						<svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								fill="currentColor"
							/>
							<path
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								fill="currentFill"
							/>
						</svg>
					</div>
				</div>
			)}

			{
				!continuous && !internalInvite  ?
					<FormRegister
						ref={autocompleteInput}
						handleSubmit={handleSubmit}
						handleChange={handleChange}
						isVisbleCompanyAddressAutoComplete={isVisbleCompanyAddressAutoComplete}
						setIsVisbleCompanyAddressAutoComplete={setIsVisbleCompanyAddressAutoComplete}
						formData={formData}
						showPassword={showPassword}
						textCompanyAddress={textCompanyAddress}
						handleOnChangePhoneNumber={handleOnChangePhoneNumber}
						displayError={displayError}
						token={token}
						complementaryData={complementaryData}
						handleShowPassword={handleShowPassword}
						updateFormData={updateFormData}
						showTerms={showTerms}
						setShowTerms={setShowTerms}
						hideTerms={hideTerms}
						updateFormErrors={updateFormErrors}
						formErrors={formErrors}
						setSelectedPlan={setSelectedPlan}
						setBillingPeriod={setBillingPeriod}
						billingPeriod={billingPeriod}
						setLoading={setLoading}
						handleChangeGoogleAddress={handleChangeGoogleAddress}
						handleChangePassword={handleChangePassword}
						validations={validations}
					/> : (isLoadInviteDataDone && (
            <div className="md:w-2/3 flex flex-col items-center justify-center bgLogin md:pb-0 pb-16 md:pt-0 pt-10">
              <form action="" autocomplete="off" className="max-w-2xl flex flex-col mt-5 md:mt-0 max-w-[640px]" onSubmit={handleSubmit}>
                <div>
                  <div className="flex flex-col font-bold items-center md:items-start text-xl w-full">
                    <h3
                      className="font-normal pt-2 text-sm tracking-wider"
                      dangerouslySetInnerHTML={{
                        __html: `You are about to join the space <strong>${complementaryData.space.name}</strong>, please finish signing up`,
                      }}
                    />
                  </div>
                </div>

                {(is_new_user || internalInvite) && (
                  <div className="mt-8">
                    <h2 className="md:hidden">
                      <strong>Personal Information</strong>
                    </h2>
                    <span className="hidden 2xl:block lg:block text-[20px]">
                      <strong>
                        Personal Information
                      </strong>
                    </span>

                    <div>
                      <div className="mt-2 grid sm:grid-cols-1 lg:grid-cols-2 gap-2">
                        <div>
                          <label for="name" className="sm:block 2xl:hidden lg:hidden mb-3">
                            {' '}
                            Name*
                          </label>
                          <div className="outlin relative w-full md:min-w-[316px]">
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              placeholder=" "
                              className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                              onChange={handleChange}
                            />
                            <label htmlFor="name" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                              First Name
                            </label>
                          </div>
                          {displayError('name')}
                        </div>
                        <div className=" 2xl:block lg:block">
                          <label htmlFor="lastname" className="hidden text-base bg-transparent font-normal py-2">
                            Last Name*
                          </label>
                          <div className="outlin relative w-full md:min-w-[316px]">
                            <input
                              type="text"
                              name="lastname"
                              value={formData.lastname}
                              placeholder=" "
                              className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                              onChange={handleChange}
                            />
                            <label htmlFor="lastname" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                              Last Name
                            </label>
                          </div>
                          {displayError('lastname')}
                        </div>
                      </div>
                    </div>
                    <div className="my-3">
                      <label for="email" className="sm:block 2xl:hidden lg:hidden mt-1">
                        Email*
                      </label>
                      <label htmlFor="email" className="hidden text-base bg-transparent font-normal py-2"></label>
                      <div className="outlin relative w-full">
                        {
                          (() => {
                            if (!isEmpty(token) && (continuous || internalInvite) && !isEmpty(complementaryData)) {
                              return(
                                <input
                                  type="email"
                                  name="email"
                                  placeholder=" "
                                  disabled
                                  className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-gray-500 text-sm font-medium"
                                  defaultValue={complementaryData.user.email}
                                />
                              )
                            } else {
                              return(
                                <input
                                  type="email"
                                  name="email"
                                  placeholder=" "
                                  value={formData.email}
                                  className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                                  onChange={handleChange}
                                />
                              )
                            }
                          })()
                        }
                        <label htmlFor="email" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">Email</label>
                      </div>
                      {displayError('email')}
                    </div>

                    <div className='mt-2 grid sm:grid-cols-1 lg:grid-cols-2 gap-2'>
                      <div className="mt-2  2xl:block lg:block">
                        <label htmlFor="password" className="sm:block 2xl:hidden lg:hidden text-base bg-transparent font-normal py-2">
                          Password*
                        </label>
                        <div className="outlin relative w-full">
                          <div
                            onClick={() => handleShowPassword('password')}
                            className="absolute right-[20px] top-[50%] cursor-pointer translate-y-[-50%] text-[#A2ACBA]"
                          >
                            {showPassword.password ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </div>
                          <input
                            type={showPassword.password ? 'text' : 'password'}
                            name="password"
                            placeholder=" "
                            className="block px-4 pt-5 pr-[50px] pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                            onChange={handleChangePassword}
                          />
                          <label htmlFor="password" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                            Password
                          </label>
                        </div>
                        {formData.password && formData.password.length > 0 && (
                          <>
                            {renderValidationItem('Minimum Length (7 characters)', validations.length)}
                            {renderValidationItem('Must include at least one uppercase letter (A-Z).', validations.uppercase)}
                            {renderValidationItem('Must include at least one lowercase letter (a-z).', validations.lowercase)}
                            {renderValidationItem('Must include at least one numeric digit (0-9).', validations.digit)}
                            {renderValidationItem('Must include at least one special character (e.g., !@#$%^&*()_+-=~`{}[]|:;"<>,.?/)', validations.specialChar)}
                          </>
                        )}
                        {displayError('password')}
                      </div>
                      <div className="mt-2 2xl:block lg:block">
                        <label htmlFor="confirm_password" className="sm:block 2xl:hidden lg:hidden text-base bg-transparent font-normal py-2">
                          Confirm Password*
                        </label>
                        <div className="outlin relative w-full">
                          <div
                            onClick={() => handleShowPassword('confirmPassword')}
                            className="absolute right-[20px] top-[50%] cursor-pointer translate-y-[-50%] text-[#A2ACBA]"
                          >
                            {showPassword.confirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </div>
                          <input
                            type={showPassword.confirmPassword ? 'text' : 'password'}
                            name="confirm_password"
                            placeholder=" "
                            className="block px-4 pt-5 pr-[50px] pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                            onChange={handleChange}
                          />
                          <label htmlFor="confirm_password" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                            Confirm Password
                          </label>
                        </div>
                        {displayError('confirm_password')}
                      </div>
                    </div>
                  </div>
                )}
                {!continuous && (
                <div className="mt-4 md:mt-8">
                  <h2 className="md:hidden">
                    <strong>Company Details</strong>
                  </h2>
                  <span className="hidden 2xl:block lg:block text-[20px]">
                    <strong>Company Details</strong>
                  </span>
                  <div className="mt-2 2xl:block lg:block">
                    <label htmlFor="company_name" className="hidden text-base bg-transparent font-normal py-2">
                      Company Name*
                    </label>
                    <div className="outlin relative w-full">
                      <input
                        type="text"
                        name="company_name"
                        placeholder=" "
                        value={formData.company_name}
                        className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                        onChange={handleChange}
                      />
                      <label htmlFor="company_name" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                        Company Name
                      </label>
                    </div>
                    {displayError('company_name')}
                  </div>
                  {!isVisbleCompanyAddressAutoComplete ?
                    <>
                      {!isEmpty(formData?.company_address) && internalInvite ?
                        <div className="mt-2 2xl:block lg:block">
                          <label htmlFor="company address" className="hidden text-base bg-transparent font-normal py-2">
                          </label>
                          <div className="outlin relative w-full">
                            <input
                              ref={autocompleteInput}
                              type="text"
                              name="company_address"
                              placeholder=" "
                              className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                              role="presentation"
                              autocomplete="off"
                              defaultValue={textCompanyAddress()}
                            />
                            <label htmlFor="company address" className="pointer-events-none absolute top-1  text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                              Company Address
                            </label>
                          </div>
                          {displayError('company_address')}
                        </div> :
                        <>
                          <div className="mt-2 2xl:block lg:block">
                            <label htmlFor="company address" className="hidden text-base bg-transparent font-normal py-2">
                            </label>
                            <div className="outlin relative w-full">
                              <input
                                ref={autocompleteInput}
                                type="text"
                                name="company_address"
                                placeholder=" "
                                className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                                role="presentation"
                                autocomplete="off"
                              />
                              <label htmlFor="company address" className="pointer-events-none absolute top-1  text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                                Company Address
                              </label>
                            </div>
                            {displayError('company_address')}
                          </div>
                          <span onClick={() => {setIsVisbleCompanyAddressAutoComplete(true)}} className='text-xs mt-[1px] cursor-pointer underline'>Add Manually</span>
                          <div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2">
                            <div className="mt-2">
                              <label htmlFor="phone" className="hidden text-base bg-transparent font-normal py-2">
                                Phone Number
                              </label>
                              <div className="outlin relative w-full">
                                <input
                                  type="tel"
                                  name="phone"
                                  placeholder=" "
                                  value={formData.phone}
                                  className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                                  onChange={handleOnChangePhoneNumber}
                                />
                                <label htmlFor="phone" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                                  Phone Number
                                </label>
                              </div>
                              {displayError('phone')}
                            </div>

                            <div className="mt-2">
                              <label htmlFor="Resale Certificate" className="hidden text-base bg-transparent font-normal py-2">
                                Resale Certificate
                              </label>
                              <div className="outlin relative w-full">
                                <input
                                  type="text"
                                  name="certificate"
                                  placeholder=" "
                                  value={formData.certificate}
                                  className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                                  onChange={handleChange}
                                />
                                <label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                                  Resale Certificate
                                </label>
                              </div>
                              {displayError('certificate')}
                            </div>
                          </div>
                        </>

                      }
                    </> :
                    <>
                      <div className="mt-2 2xl:block lg:block">
                        <div className="outlin relative w-full">
                          <input
                            type="text"
                            id="1"
                            name="company_address"
                            placeholder=" "
                            autocomplete="off"
                            className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                            onChange={handleChange}
                          />
                          <label htmlFor="company address" className="pointer-events-none absolute top-1  text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                            Company Address
                          </label>
                        </div>
                        {displayError('company_address')}
                      </div>
                      <span onClick={() => {setIsVisbleCompanyAddressAutoComplete(false)}} className='text-xs mt-[1px] cursor-pointer underline'>Use Google autocomplete</span>
                      <div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2">
                        <div className="mt-2">
                          <label htmlFor="city" className="hidden text-base bg-transparent font-normal py-2">
                            City
                          </label>
                          <div className="outlin relative w-full">
                            <input
                              type="text"
                              name="city"
                              placeholder=" "
                              className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                              onChange={handleChange}
                            />
                            <label htmlFor="city" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                              City
                            </label>
                          </div>
                          {displayError('city')}
                        </div>
                        <div className="mt-2">
                          <label htmlFor="state" className="hidden text-base bg-transparent font-normal py-2">
                            State
                          </label>
                          <div className="outlin relative w-full">
                            <input
                              type="text"
                              name="state"
                              placeholder=" "
                              className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                              onChange={handleChange}
                            />
                            <label htmlFor="state" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                              State
                            </label>
                          </div>
                          {displayError('state')}
                        </div>

                        <div className="mt-2">
                          <label htmlFor="zip_code" className="hidden text-base bg-transparent font-normal py-2">
                            Zip Code
                          </label>
                          <div className="outlin relative w-full">
                            <input
                              type="text"
                              name="zip_code"
                              placeholder=" "
                              className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                              onChange={handleChange}
                            />
                            <label htmlFor="zip_code" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                              Zip Code
                            </label>
                          </div>
                          {displayError('zip_code')}
                        </div>
                        <div className="mt-2">
                          <label htmlFor="country" className="hidden text-base bg-transparent font-normal py-2">
                            Choose a country
                          </label>
                          <div className="outlin relative w-full">
                            <select
                              id="country"
                              name="country"
                              className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                              defaultValue=""
                              onChange={handleChange}
                            >
                              <option value="" disabled>
                                Choose a country
                              </option>
                              {Globals.countries.map(c => (
                                <option key={c.code} value={c.code}>
                                  {c.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {displayError('country')}
                        </div>
                        <div className="mt-2">
                          <label htmlFor="phone" className="hidden text-base bg-transparent font-normal py-2">
                            Phone Number
                          </label>
                          <div className="outlin relative w-full">
                            <input
                              type="tel"
                              name="phone"
                              placeholder=" "
                              value={formData.phone}
                              className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                              onChange={handleChange}
                            />
                            <label htmlFor="phone" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                              Phone Number
                            </label>
                          </div>
                          {displayError('phone')}
                        </div>

                        <div className="mt-2">
                          <label htmlFor="Resale Certificate" className="hidden text-base bg-transparent font-normal py-2">
                            Resale Certificate
                          </label>
                          <div className="outlin relative w-full">
                            <input
                              type="text"
                              name="certificate"
                              placeholder=" "
                              value={formData.certificate}
                              className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
                              onChange={handleChange}
                            />
                            <label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
                              Resale Certificate
                            </label>
                          </div>
                          {displayError('certificate')}
                        </div>
                      </div>
                    </>
                  }
                </div>
              )}

                <div className="mt-4 text-center">{displayError('error')}</div>
                {(!continuous || is_new_user) && (
                  <>
                    <div className="outlin relative w-full  items-center flex">
                      <label htmlFor="Terms and conditions*" className=" text-base bg-transparent font-normal py-2">
                        <input
                          type="checkbox"
                          name="terms"
                          defaultChecked={formData.terms}
                          style={{opacity: 1}}
                          checked={formData.terms}
                          onClick={() => {
                            updateFormData({
                              ...formData,
                              terms: !formData.terms,
                            })
                          }}
                        />
                        <a
                          onClick={() => {
                            setShowTerms(true)
                          }}
                          className="text-blue-brand font-medium "
                          href="#"
                          style={{color: '#2C79E0', paddingLeft: '30px'}}
                        >
                          Terms and conditions*
                        </a>
                      </label>
                    </div>
                    <div className="mt-1">{displayError('terms')}</div>
                  </>
                )}
                {showTerms &&
                  <TermsOfUse
                    hideTerms={hideTerms}
                    formData={formData}
                    updateFormData={updateFormData}
                    showButtons={true}
                    handleSubmit={handleSubmit}
                    newUser={new_user}
                  />}
                <div className="w-full mt-4 flex justify-center">
                  {continuous && !is_new_user && (
                    <button className="w-full p-4 rounded text-white cursor-pointer bg-[#2C79E0] font-medium md:w-[348px] md:rounded-full md:h-[43px] md:p-0" type="submit">
                      <span>Accept and join the space</span>
                    </button>
                  )}

                  {continuous && is_new_user && (
                    <button className="w-full p-4 rounded text-white cursor-pointer bg-[#2C79E0] font-medium md:w-[348px] md:rounded-full md:h-[43px] md:p-0" type="submit">
                      <span>Complete registration</span>
                    </button>
                  )}

                  {internalInvite && (
                    <button className="w-full p-4 rounded text-white cursor-pointer bg-[#2C79E0] font-medium md:w-[348px] md:rounded-full md:h-[43px] md:p-0" type="submit">
                      <span>Complete registration</span>
                    </button>
                  )}

                </div>
              </form>
              <Privacy />
            </div>
          ))
			}


			<div className="md:w-1/3 md:flex hidden flex-col justify-start text-white px-16">
				<div className="pt-60">
					<h1 className="text-[50px] font-bold text-center">ELEFTA</h1>
					<p className="font-medium text-[30px] pt-10 text-center	">Built by dealers, for dealers</p>
					<p className='text-[20px] font-medium text-center'> The most advanced watch-specific inventory system and software</p>
				</div>
			</div>

			<div className="md:hidden flex items-center justify-center mt-3 mb-3 sm:h-auto mpt-5 lg:pt-0 lg:h-screen">
				<img src="/logo-trans-white.png" className=" relative" width="200" height={'auto'} alt="Elefta"
					 loading="lazy" />
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {}
}

const mapActionsToProps = {
	addToast,
	logOut,
}

export default connect(mapStateToProps, mapActionsToProps)(Register)
