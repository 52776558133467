import { OrderStatusMapping } from "./Constants";

const StatusLabel = ({ orderStatus }) => {
  const labelText = OrderStatusMapping[orderStatus].labelText;
  const labelColor = OrderStatusMapping[orderStatus].labelColor;

  return (
    <small className={`bg-${labelColor}-100 text-${labelColor}-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center`}>
      <span className={`w-1.5 h-1.5 inline-flex rounded-full bg-${labelColor}-700`}></span> {labelText}
    </small>
  );
}

export default StatusLabel;
