import React from 'react'
import {useSelector} from 'react-redux'

const templateOptionsOrder = ['default', 'alternative']
const TemplateSwitcher = ({ selectedTemplate, onTemplateChange, templateOptions, setInvoiceSettingsTemplates}) => {
    const currentSpace = useSelector(state => state.currentSpace)

    const handleSwitchTemplate = (template) => {
        if (selectedTemplate !== template) {
            onTemplateChange(template);
            const spaceTemplatesObject = currentSpace?.invoice_settings.reduce((acc, current) => {
                acc[current.template_type] = current
                return acc
            }, {})
            setInvoiceSettingsTemplates(spaceTemplatesObject)
        }
    }
    return (
        <div
            className="max-w-[450px] min-w-[250px] flex flex-row mt-7"
            style={{
                backgroundColor: 'rgba(61, 61, 61, 0.5)',
                border: '1px solid rgba(61, 61, 61, 0.5)',
                borderRadius: 20,
                marginBottom: 20
            }}
        >
            {templateOptionsOrder.map(template => (
                <button
                    className="text-md flex py-[9px] flex-row flex-1 font-bold px-4 pr-3 justify-center items-center capitalize"
                    key={template}
                    onClick={()=>handleSwitchTemplate(template)}
                    type={'button'}
                    style={{
                        backgroundColor: selectedTemplate === template ? '#fff' : 'transparent',
                        borderRadius: 20,
                    }}
                >
          <span
              style={{
                  lineHeight: 1.1,
                  fontSize: 14,
                  color: selectedTemplate === template ? '#3D3D3D' : '#ffffff',
              }}
          >
            {templateOptions?.[template]?.name ||  `Template ${template === 'default' ? '1' : '2'}`}
          </span>
                </button>
            ))}
        </div>
    )

}
export default TemplateSwitcher
