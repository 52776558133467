import {useState, useCallback} from 'react'
import {Globals} from '../../../../../Context'

function useGeneralSettings({addToast}) {
	const [loadedSpaceUsers, setLoadedSpaceUsers] = useState([])

	const handleLoadUsers = useCallback(() => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/users`, {
				params: {
					q: {
						s: 'created_at desc',
					},
					per_page: 10000,
				},
			})
			.then(response => {
				let {users} = response.data
				setLoadedSpaceUsers(users)

				return users
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return addToast({
					text: message,
					type: 'error',
				})
			})
	}, [addToast])

	return {
		loadedSpaceUsers,
		handleLoadUsers,
	}
}

export default useGeneralSettings
