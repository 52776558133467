import {store} from '../../../../app/store'
import {Globals} from '../../../../Context'
import ConfirmPopUp from '../../../ConfirmPopUp'
import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {addToast, setCurrentSpace} from '../../../../app/appActions'
import Classnames from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import {fetchProfile} from '../../../../utils/UserSessionHelper'
import useOutsideClick from '../../../../hooks/useOutsideClick'

const TeamTable = ({spaceUsers, getSpaceUsers , setVisibleInvitationDialog, setSendingInvitation}) => {
	const refDropdownManageTeam = useRef()
	const profile = useSelector(state => state.profile)

	const [switchUserRoleDropDown, setSwitchUserRoleDropDown] = useState(0)
	const [checkVisibleConfirmPopup, setCheckVisibleConfirmPopup] = useState(false)
	const dispatch = useDispatch()

	useOutsideClick(refDropdownManageTeam, event => {
		if (event.target.getAttribute('text') === 'dropdown' || checkVisibleConfirmPopup) return
		setSwitchUserRoleDropDown(0)
	})

	const getSpaceDetail = async () => {
		await Globals.New_Axios({logOutWhenUnauthorized: false})
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}`)
			.then(response => {
				store.dispatch(setCurrentSpace(response.data))
			})
			.catch(error => {
				console.log(error)
			})
	}

	const handleCheckVisibleConfirmPopup = async value => {
		setCheckVisibleConfirmPopup(value)
	}

	const resendInvitation = spaceUser => {
		setSendingInvitation(false)

		return Globals.New_Axios()
			.post(`${Globals.baseUri}/spaces_users/${spaceUser.id}/resend_invite`)
			.then(async response => {
				setSendingInvitation(false)

				//Check on the response validation.
				setVisibleInvitationDialog(false)
				return dispatch(
					addToast({
						title: 'Invite users',
						text: `The invitation has been sent to ${spaceUser.email}.`,
						type: 'success',
					})
				)
			})
			.catch(error => {
				setSendingInvitation(false)
				//Display error message.
				return dispatch(
					addToast({
						title: 'Invite users',
						text: error.response?.data?.message || 'Something went wront please try again later.',
						type: 'error',
					})
				)
			})
			.finally(() => {
				setSendingInvitation(false)
			})
	}

	const removeUserFromSpace = spaceUser => {
		return Globals.New_Axios()
			.delete(`${Globals.baseUri}/spaces_users/${spaceUser.id}`)
			.then(async response => {
				setSendingInvitation(false)
				setVisibleInvitationDialog(false)
				//Reload users list.
				getSpaceUsers()
				return dispatch(addToast({title: 'Space user', text: `User have been removed`, type: 'success'}))
			})
			.catch(error => {
				setSendingInvitation(false)
				//Display error message.
				return dispatch(
					addToast({
						title: 'Space user',
						text: error.response?.data?.message || 'Something went wront please try again later.',
						type: 'error',
					})
				)
			})
	}

	const leaveSpace = $event => {
		return Globals.New_Axios()
			.delete(`${Globals.baseUri}/spaces_users/leave`)
			.then(async response => {
				setSendingInvitation(false)
				//Check on the response validation.
				//If all good then reload the window and direct the user to pickup a space.
				Globals.Cache.deleteStorage(Globals.USERSELECTEDSPACE)
				Globals.Cache.deleteCookie(Globals.USERSELECTEDSPACE)
				if (response.data.message === 'User has left the space') {
					return window.location.replace('/pickspace')
				} else {
					dispatch(logout())
					return window.location.replace('/login')
				}
			})
			.catch(error => {
				console.log(error.response)
				setSendingInvitation(false)
				//Display error message.
				return dispatch(
					addToast({
						title: 'Leave space errors',
						text: error.response.data.message || 'Something went wront please try again later.',
						type: 'error',
					})
				)
			})
	}

	const isCurrentUserAnOwner = () => {
		return profile.role === 'owner'
	}

	const switchUserRole = (spaceUser, role) => {
		setSwitchUserRoleDropDown(0)
		return Globals.New_Axios()
			.patch(`${Globals.baseUri}/spaces_users/${spaceUser.id}`, {
				role,
			})
			.then(async response => {
				//Reload users list.
				await fetchProfile()
				await getSpaceDetail()

				await getSpaceUsers()
				return dispatch(addToast({title: 'Members role', text: `User role has been updated`, type: 'success'}))
			})
			.catch(error => {
				//Display error message.
				return dispatch(
					addToast({
						title: 'Members role',
						text: error.response?.data.message || 'Something went wront please try again later.',
						type: 'error',
					})
				)
			})
	}

	return (
		<div className="overflow-visible">
			<table className="relative min-w-full border bg-transparent overflow-scroll">
				<thead className="sticky bg-white border-b top-[18px] z-[40]">
					<tr className="divide-x">
						<th className="p-4 text-sm font-bold tracking-worderer">
							<button className="flext text-gray-500">
								<span>Name</span>
							</button>
						</th>
						<th className="p-4 text-sm font-bold tracking-worderer">
							<button className="flext text-gray-500">
								<span>Email</span>
							</button>
						</th>
						<th className="p-4 text-sm font-bold tracking-worderer w-40">
							<button className="flext text-gray-500">
								<span>Role</span>
							</button>
						</th>
						<th className="p-4 text-sm font-bold tracking-worderer text-right w-56">
							<button className="flext text-gray-500">
								<span>Status</span>
							</button>
						</th>
						<th className="p-4 text-sm font-bold tracking-worderer text-right">
							<button className="flext text-gray-500">
								<span>Action</span>
							</button>
						</th>
					</tr>
				</thead>

				<tbody className="divide-y">
					{spaceUsers.map((spaceUser, index) => {
						const user = spaceUser.user
						const isCurrentDropdown = switchUserRoleDropDown === user.id
						const ownerEditingSelf = isCurrentUserAnOwner() && isCurrentDropdown && Globals.currentUserId === user.id

						const ownerEditingOther = isCurrentUserAnOwner() && isCurrentDropdown && (spaceUser.role !== 'owner' || ownerEditingSelf)

						return (
							<tr key={index} className={Classnames('cursor-pointer divide-x hover:bg-gray-100 bg-gray-50', {'bg-white': (index + 1) % 2 === 0})}>
								<th className="p-4 text-sm font-normal tracking-worderer capitalize">{user.full_name}</th>
								<th className="p-4 text-sm font-normal tracking-worderer">{user.email || spaceUser.email}</th>
								<th className="p-4 text-sm font-normal tracking-worderer">
									{user.is_active && (
										<div className="relative">
											<small
												onClick={e => setSwitchUserRoleDropDown(user.id === switchUserRoleDropDown ? 0 : user.id)}
												className={Classnames('font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center', {
													'bg-green-100 text-green-700': spaceUser.role === 'admin',
													'bg-yellow-100 text-yellow-700': spaceUser.role === 'editor',
													'bg-orange-100 text-orange-700': spaceUser.role === 'manager',
													'bg-red-100 text-red-700': spaceUser.role === 'owner',
												})}
												text={'dropdown'}
											>
												<span
													className={Classnames('w-1.5 h-1.5 inline-flex rounded-full', {
														'bg-green-700': spaceUser.role === 'admin',
														'bg-yellow-700': spaceUser.role === 'editor',
														'bg-orange-700': spaceUser.role === 'manager',
														'bg-red-700': spaceUser.role === 'owner',
													})}
												></span>{' '}
												{spaceUser.role_label}
											</small>
											{(ownerEditingOther || ownerEditingSelf) && (
												<React.Fragment>
													<div className="absolute z-30 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
														<ul ref={refDropdownManageTeam} className="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
															<li>
																{!ownerEditingSelf && (
																	<ConfirmPopUp
																		onCancel={e => setSwitchUserRoleDropDown(0)}
																		onConfirm={switchUserRole.bind(this, spaceUser, 'owner')}
																		title="Update user role"
																		content="Are you sure you want to switch this member into an Owner?"
																		handleCheckVisibleConfirmPopup={handleCheckVisibleConfirmPopup}
																	>
																		<button className="w-full text-xs text-left block px-4 py-2 hover:bg-gray-100">Switch to Owner</button>
																	</ConfirmPopUp>
																)}
																{spaceUser.role !== 'admin' && (
																	<ConfirmPopUp
																		onCancel={e => setSwitchUserRoleDropDown(0)}
																		onConfirm={switchUserRole.bind(this, spaceUser, 'admin')}
																		title="Update user role"
																		content="Are you sure you want to switch this member into an Admin?"
																		handleCheckVisibleConfirmPopup={handleCheckVisibleConfirmPopup}
																	>
																		<button className="w-full text-xs text-left block px-4 py-2 hover:bg-gray-100">Switch to Admin</button>
																	</ConfirmPopUp>
																)}
																{spaceUser.role !== 'editor' && (
																	<ConfirmPopUp
																		onCancel={e => setSwitchUserRoleDropDown(0)}
																		onConfirm={switchUserRole.bind(this, spaceUser, 'editor')}
																		title="Update user role"
																		content="Are you sure you want to switch this member into an Editor?"
																		handleCheckVisibleConfirmPopup={handleCheckVisibleConfirmPopup}
																	>
																		<button className="w-full text-xs text-left block px-4 py-2 hover:bg-gray-100">Switch to Editor</button>
																	</ConfirmPopUp>
																)}
																{spaceUser.role !== 'manager' && (
																	<ConfirmPopUp
																		onCancel={e => setSwitchUserRoleDropDown(0)}
																		onConfirm={switchUserRole.bind(this, spaceUser, 'manager')}
																		title="Update user role"
																		content="Are you sure you want to switch this member into a Manager?"
																		handleCheckVisibleConfirmPopup={handleCheckVisibleConfirmPopup}
																	>
																		<button className="w-full text-xs text-left block px-4 py-2 hover:bg-gray-100">Switch to Manager</button>
																	</ConfirmPopUp>
																)}
															</li>
														</ul>
													</div>
													<div className="hidden z-20 fixed top-0 left-0 right-0 bottom-0" onClick={e => setSwitchUserRoleDropDown(0)}></div>
												</React.Fragment>
											)}
										</div>
									)}
								</th>
								<th className="p-4 text-sm font-normal tracking-worderer text-right">
									{user.is_active && (
										<small className="bg-green-100 text-green-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center">
											<span className="w-1.5 h-1.5 inline-flex rounded-full bg-green-700"></span> active
										</small>
									)}
									{isCurrentUserAnOwner() && !user.is_active && (
										<small className="bg-yellow-100 text-yellow-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-centerr">
											<span className="w-1.5 h-1.5 inline-flex rounded-full bg-yellow-700"></span>{' '}
											<ConfirmPopUp onConfirm={resendInvitation.bind(this, spaceUser)} title="Send invitation" content="Are you sure you want to send a new invitation email?">
												<span>Click to invite again</span>
											</ConfirmPopUp>
										</small>
									)}
								</th>
								<th className="p-4 text-sm font-normal tracking-worderer capitalize text-right">
									{/* if the current user is a space owner (and in the future if the user role is admin) show the delete button. */}
									{spaceUser.role !== 'owner' && isCurrentUserAnOwner() && (
										<ConfirmPopUp onConfirm={removeUserFromSpace.bind(this, spaceUser)} title="Remove user" content="Are you sure you want to remove this user from this space?">
											<Tooltip title="Delete the user from space" placement="left">
												<button className="text-red-500">
													<svg
														className="w-5 h-5"
														fill="none"
														stroke="currentColor"
														strokeWidth={1.5}
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
														aria-hidden="true"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
														/>
													</svg>
												</button>
											</Tooltip>
										</ConfirmPopUp>
									)}

									{profile.id === user.id && (
										<ConfirmPopUp onConfirm={leaveSpace} title="Remove user" content="Are you sure you want to leave this space?">
											<Tooltip title="Leave space" placement="left">
												<button className="text-red-500">
													<svg
														className="w-5 h-5"
														fill="none"
														stroke="currentColor"
														strokeWidth={1.5}
														viewBox="0 0 24 24"
														xmlns="http://www.w3.org/2000/svg"
														aria-hidden="true"
													>
														{' '}
														<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
													</svg>
												</button>
											</Tooltip>
										</ConfirmPopUp>
									)}
								</th>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

export default TeamTable
