import Classnames from 'classnames'
import React from 'react'
import {connect} from 'react-redux'
import {addToast, toggleSidebar, toggleDetailsCardInventory} from './../app/appActions'
import {Globals} from '../Context'
import SideBar from '../Components/SideBar'
import TopBar from '../Components/TopBar'
import Tooltip from '@mui/material/Tooltip'
import Moment from 'moment'
import ActionCable from 'actioncable'

import AddNewCustomer, {formatPhoneNumber} from '../Components/Customers/AddNewCustomer'
import SingleItemDetail from '../Components/Sales/SingleItemDetail'
import SalesProductsHunter from '../Components/Sales/SalesProductsHunter'
import AddNewMiscProduct from '../Components/Memo/AddNewMiscProduct'
import AddressDropdown from '../Components/Customers/AddressDropdown'
import UsersDropdown from '../Components/Customers/UsersDropdown'
import TermsDropdown from '../Components/Sales/TermsDropdown'
import TradeInDialog from '../Components/Sales/TradeInDialog'
import DrawnWatchDetail from '../Components/DrawnWatchDetail'
import CustomerList from '../Components/Customers/CustomerList'
import ServerAutoSuggestAddress from '../Components/ServerAutoSuggestAddress'
import PerformanceLimitAccessModal from '../Components/common/PerformanceLimitAccessModal'
import { focusElement, onPressEscape, onPressFocusSearchInput } from '../utils/keyboardHandler'
import AddNoteForLineItem from '../Components/Sales/AddNoteForLineItem'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import assignIn from 'lodash/assignIn'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import findIndex from 'lodash/findIndex'
import isNaN from 'lodash/isNaN'
import sum from 'lodash/sum'
import head from 'lodash/head'
import isNull from 'lodash/isNull'
import upperFirst from 'lodash/upperFirst'
import unionBy from 'lodash/unionBy'
import InfiniteScroll from 'react-infinite-scroll-component'
import Dialog from '@mui/material/Dialog'
import DataTable from '../Components/Sales/DataTable'

const withTheme = Component => {
	return props => {
		const theme = useTheme()
		const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
		return <Component {...props} theme={theme} isMobile={isMobile} />
	}
}

const initAddress = {
	visible: false,
	company_name: '',
	contact_name: '',
	is_manual: false,
	street: '',
	apt_unit_number: '',
	city: '',
	state: '',
	country: '',
	zip_code: '',
	full_address: '',
	phone_number: '+1',
	save_to_contact: false
}

const initNewAddress = {
	visible: false,
	is_manual: false,
	street: '',
	apt_unit_number: '',
	city: '',
	state: '',
	country: '',
	zip_code: '',
	full_address: '',
	save_to_contact: false
}

let cableApp = {}

class SalesView extends React.Component {
	constructor() {
		super()
		this.searchSaleInputRef = React.createRef()
		this.searchCustomerInputRef = React.createRef()
		this.billingAddressInputRef = React.createRef()
		this.shippingAddressInputRef = React.createRef()
		this.trackingNumberInputRef = React.createRef()
		this.selectCustomerRef = React.createRef()
		this.listWrapperRef = React.createRef()
		this.state = {
			sendingData: false,
			visibleStatusDropDown: false,
			activeTab: 'all',
			step: 1,
			isVisibleAddSale: false,
			openPerformanceLimitAccessModal: false,
			isVisibleProductsHunter: false,
			loadedCustomers: null,
			originalLoadedCustomers: null,
			loadingCustomers: false,
			selectedCustomer: null,
			orderedItems: [],
			tradeInItems: [],
			showTradeIn: false,
			loadedProducts: [],
			originalLoadedProducts: [],
			orderComment: {content: '', visible_on_pdf: false},
			customerFormVisible: false,
			searchString: null,
			orderBy: 'created_at',
			order: 'DESC',

			originalOrdersList: [],
			ordersList: [],
			ordersListPending: [],
			ordersListCancelled: [],
			ordersListComplete: [],
			isVisibleMiscProducts: false,
			miscItems: [],

			isVisibleSingleOrder: false,
			singleOrderItem: null,
			singleOrderItemId: null,

			shippingPrice: 0,
			isCustomerLabeled: false,
			inventorySearch: '',
			offset: 1,
      customerListHeight: null,
			loadingData: false,
			watchItem: {},
			isVisibleSearchInput: false,
			loadingMoreData: false,
			pagination: {
				totalEntries: 0
			},
      isHaveCustomers: null,
      customerPagination: {
				nextPage: 1,
				totalPages: 1,
				totalCount: 0,
				totalEntries: 0,
				currentPage: 1,
			},
			searchCustomer: '',
			selectedSalesPerson: null,
			addSaleTax: null,
			selectedTerm: Globals.saleTerms[0],
			selectedBillingAddress: null,
			selectedShippingAddress: null,
			newBillingAddress: initAddress,
			newShippingAddress: initAddress,
			isVisibleComments: false,
			loadedSpaceUsers: null,
			trackingNumber: '',
			termCustomField: '',
			termCustomSelect: {value: 'days', label: 'Days'},
			isTaxExempt: true,
			taxRate: 0,
			loadingProduct: false,
			isManualBillingAddress: false,
			isManualShippingAddress: false,
			errors: [],
			isPrimaryTab: false,
			billingAddress: '',
			shippingAddress: '',
			isVisibleAllowSyncQuickbooks: false,
			syncToQuickbook: false,
      openNoteModal: false,
      saleItemSelected: null,
		}

		this.handleFetchTaxRate = this.handleFetchTaxRate.bind(this)
		this.handleCalculateTax = this.handleCalculateTax.bind(this)
		this.calculateTotalIncludeTax = this.calculateTotalIncludeTax.bind(this)
		this.calculateTotalExcludeTax = this.calculateTotalExcludeTax.bind(this)

    this.customerListRef = React.createRef()
	}

	componentDidMount() {
		if(!!this.listWrapperRef.current) {
			this.setState({height: this.listWrapperRef.current.clientHeight})
		}
		if (!this.props.loggedin) return window.location.replace('/login')
		this.handleLoadOrders()
		window.addEventListener('keydown', this.handleKeyDown)
		this.handleLoadUsers()
		this.handleBuildDataCustomTerms()
		const saleperson = this.props.currentSpace?.space_general_setting?.sales_saleperson
		const addSaleTax = this.props.currentSpace?.space_general_setting?.add_sale_tax
		this.setState({
			selectedSalesPerson: saleperson,
			addSaleTax: addSaleTax
		})

		cableApp?.cable?.disconnect()
		cableApp.cable = ActionCable.createConsumer(`${Globals.actionCableURL}?access_token=${Globals.getAccessToken()}`)
		cableApp.cable.subscriptions.create({channel: 'SaleChannel', space_id: this.props.currentSpace.id}, {
			received: (data) => {
				if (this.state.isPrimaryTab) return this.setState({isPrimaryTab: false})
				if (data.action === 'create') {
					Globals.New_Axios()
						.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales/${data.sale_id}`)
						.then(async response => {
							this.handleGetSale(response.data)
						}).catch(error => {
						console.log(error)
					})
				} else if (data.action === 'update') {
					Globals.New_Axios()
						.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales/${data.sale_id}`)
						.then(async response => {
							this.updateItemInSalesWhenLiveReload(response.data)
						}).catch(error => {
						console.log(error)
					})
				}
			}
		})
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	handleResize = () => {
		if(!!this.listWrapperRef.current) {
			this.setState({height: this.listWrapperRef.current.clientHeight})
		}
	};

	componentDidUpdate(prevProps, prevState) {
		const {isManualBillingAddress, isManualShippingAddress} = this.state
		if (!isManualBillingAddress) {
			setTimeout(() => {
				if (this.billingAddressInputRef.current) {
					loadGoogleMapsScript(() => {
						initializeGoogleMapsAutocomplete(this.billingAddressInputRef, (searchedAddress) => {
							const newAddress = assignIn({}, this.state.newBillingAddress, searchedAddress)
							this.setState({newBillingAddress: newAddress, billingAddress: searchedAddress.address})
						})
					})
				}
			}, 100)
		}
		if (!isManualShippingAddress) {
			setTimeout(() => {
				if (this.shippingAddressInputRef.current) {
					loadGoogleMapsScript(() => {
						initializeGoogleMapsAutocomplete(this.shippingAddressInputRef, (searchedAddress) => {
							const newAddress = assignIn({}, this.state.newShippingAddress, searchedAddress)
							this.setState({newShippingAddress: newAddress, shippingAddress: searchedAddress.address}, () => {
								this.handleFetchTaxRate()
							})
						})
					})
				}
			}, 100)
		}

    if (prevState.originalLoadedCustomers == null && this.state.originalLoadedCustomers != prevState.originalLoadedCustomers && this.customerListRef.current) {
      this.setState({customerListHeight: this.customerListRef.current?.clientHeight})
    }
	}

	handleGetSale = (sale) => {
		this.setState({
			ordersList: [sale, ...this.state.ordersList],
			originalOrdersList: [sale, ...this.state.originalOrdersList],
			ordersListPending:  [sale, ...this.state.ordersListPending]
		})
	}

	handleBuildDataCustomTerms = () => {
		const { currentSpace } = this.props
		if(!currentSpace?.space_general_setting?.payment_term_method) return
		if(currentSpace?.space_general_setting?.payment_term_method === 'cod') {
			return this.setState({selectedTerm: {value: currentSpace?.space_general_setting?.payment_term_method, label: 'C.O.D.'}})
		} else if(currentSpace?.space_general_setting?.payment_term_method === 'net_3') {
			return this.setState({selectedTerm: {value: currentSpace?.space_general_setting?.payment_term_method, label: 'Net 3 Days'}})
		} else if(currentSpace?.space_general_setting?.payment_term_method === 'net_7') {
			return this.setState({selectedTerm: {value: currentSpace?.space_general_setting?.payment_term_method, label: 'Net 7 Days'}})
		} else if(currentSpace?.space_general_setting?.payment_term_method === 'net_14') {
			return this.setState({selectedTerm: {value: currentSpace?.space_general_setting?.payment_term_method, label: 'Net 14 Days'}})
		}  else if(currentSpace?.space_general_setting?.payment_term_method === 'net_30') {
			return this.setState({selectedTerm: {value: currentSpace?.space_general_setting?.payment_term_method, label: 'Net 30 Days'}})
		} else if(currentSpace?.space_general_setting?.payment_term_method === 'days') {
			this.setState({termCustomField: currentSpace?.space_general_setting?.payment_term_days})
			this.setState({termCustomSelect: {value: 'days', label: 'Days'}})
			this.setState({selectedTerm: {value: 'custom', label: 'Custom'}})
		} else if(currentSpace?.space_general_setting?.payment_term_method === 'months') {
			this.setState({termCustomField: currentSpace?.space_general_setting?.payment_term_days})
			this.setState({value: 'months', label: 'Months'})
			this.setState({selectedTerm: {value: 'custom', label: 'Custom'}})
		}
	}

	handlePressEscape = (event) => {
		if(event.target.classList.contains('add-new-sale-view')) {
			this.setState({
				step: 1,
				isVisibleAddSale: false,
				loadedCustomers: null,
				originalLoadedCustomers: null,
				selectedCustomer: null,
        isHaveCustomers: null,
        customerPagination: {
          nextPage: 1,
          totalPages: 1,
          totalCount: 0,
          totalEntries: 0,
          currentPage: 1,
        },
        searchCustomer: '',
				orderedItems: [],
				loadedProducts: [],
				miscItems: [],
				tradeInItems: []
			})
		}
	}

	setIsTaxExempt = (value) => {
		this.setState({isTaxExempt: value})
	}

	handleChangeTermCustomField = (event) => {
		const value = event.target.value;
        if (!isNaN(value) || value === '') {
			this.setState({termCustomField: value})
        }
	}

	handleKeyDown = (event) => {
		const { isOpenDetailsInventory } = this.props
		const { isVisibleAddSale, customerFormVisible, isVisibleSingleMemo, isVisibleProductsHunter } = this.state
		if(isVisibleAddSale) {
			if(!this.searchCustomerInputRef?.current || customerFormVisible) return
			onPressFocusSearchInput(event, this.searchCustomerInputRef.current)
		} else {
			if(customerFormVisible || isVisibleSingleMemo || isVisibleProductsHunter || isOpenDetailsInventory  ) return
			onPressFocusSearchInput(event, this.searchSaleInputRef.current)
		}
	}

	handleClickOut = $event => {
		if ($event.target.classList.contains('single-customer-view')) this.setState({isVisibleSingleOrder: false})
	}

	handleChangeTrackingNumber = (event) => {
		this.setState({trackingNumber: event.target.value})
	}

	handleLoadOrders = (isScroll = false) => {
		const loadOrders = debounce(() => {
			if (isScroll) this.setState({ loadingMoreData: true });
			else this.setState({ loadingData: true });

			let params = {
				q: {
					s: `${this.state.orderBy} ${this.state.order}`,
				},
				page: this.state.offset,
				per_page: 30,
			}

			switch (this.state.activeTab) {
				case 'cancelled':
					Object.assign(params.q, {status_eq: 1})
					break
				case 'pending':
					Object.assign(params.q, {status_eq: 0})
					break
				case 'complete':
					Object.assign(params.q, {status_eq: 2})
					break

				default:
					Object.assign(params.q, {status_eq: undefined})
					break
			}

			if (this.state.searchString) {
				Object.assign(params.q, {
					order_number_str_or_customer_first_name_or_customer_last_name_or_customer_company_name_or_watches_sku_or_watches_reference_number_or_misc_items_str_i_cont: this.state.searchString,
				})
			}
			return Globals.New_Axios()
				.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales`, {params: params})
				.then(response => {
					if (response.status && response.status >= 200 && response.status < 300) {
						this.handleDataResponse(response.data);
					}
				})
				.catch(error => {
					const message = error.response?.data?.message || 'Something went wrong please try again later.'
					this.setState({ loadingData: false });
					return this.props.addToast({
						text: message,
						type: 'error',
					})
				})
		}, isScroll ? 0 : 800)

		loadOrders()
	}

	handleDataResponse = (data) => {
		const { current_page } = data;
		const newDataOriginalOrdersList = current_page === 1 ? data.sales : [...this.state.originalOrdersList, ...data.sales];
		const newDataOrdersList = current_page === 1 ? data.sales : [...this.state.ordersList, ...data.sales];
		const newDataOrdersListCancelled = current_page === 1 ? data.sales : [...this.state.ordersListCancelled, ...data.sales];
		const newDataOrdersListPending = current_page === 1 ? data.sales : [...this.state.ordersListPending, ...data.sales];
		const newDataOrdersListComplete = current_page === 1 ? data.sales : [...this.state.ordersListComplete, ...data.sales];
		// Update state with the new data
		this.setState(
			{
				originalOrdersList: newDataOriginalOrdersList,
				ordersList: newDataOrdersList,
				ordersListCancelled: newDataOrdersListCancelled,
				ordersListPending: newDataOrdersListPending,
				ordersListComplete: newDataOrdersListComplete,
				offset: data.next_page ?? 1,
				loadingMoreData: false,
				loadingData: false,
				pagination: {
					totalEntries: data.total_entries,
				},
			}
		);
	};

	loadMoreData = () => {
		const {
			loadingMoreData,
			ordersList,
			ordersListPending,
			ordersListCancelled,
			ordersListComplete,
			activeTab,
			pagination
		} = this.state

		const hasReachedTotalEntries = {
			all: ordersList.length >= pagination.totalEntries,
			pending: ordersListPending.length >= pagination.totalEntries,
			cancelled: ordersListCancelled.length >= pagination.totalEntries,
			complete: ordersListComplete.length >= pagination.totalEntries
		}

		const isDataLoadingOrComplete = loadingMoreData || hasReachedTotalEntries[activeTab]
		if (isDataLoadingOrComplete) return

		if (!hasReachedTotalEntries[activeTab]) {
			this.handleLoadOrders(true)
		}
	}

	toggleShipping = () => {
		this.setState(
			prevState => ({isCustomerLabeled: !prevState.isCustomerLabeled}),
			this.setState({shippingPrice: 0})
		)
	}

	handleChangeCustomerLabel = () => {
		this.setState(
			prevState => ({isCustomerLabeled: !prevState.isCustomerLabeled})
		)
	}

	handlePrimaryTab = () => {
		this.setState({isPrimaryTab: true})
	}

  loadMoreDataCustomer = async () => {
    const {loadedCustomers, customerPagination} = this.state
    const hasReachedTotalEntries = loadedCustomers.length >= customerPagination.totalEntries
    const isDataLoadingOrComplete = hasReachedTotalEntries
    if (isDataLoadingOrComplete) return

    if (!hasReachedTotalEntries) {
      await this.handleLoadCustomers(true)
    }
  }

	handleLoadCustomers = (isScroll = false) => {
		if (!isScroll) {
      this.setState({
        loadingCustomers: true
      })
    }

    const { searchStringCustomer } = this.state

    let sortOrder = "q[s]=created_at desc"

    sortOrder = !isEmpty(searchStringCustomer)
      ? `${sortOrder}&q[company_name_or_name_or_email_or_phone_number_or_phone_country_or_certificate_or_customer_addresses_street_or_customer_phone_numbers_phone_number_i_cont]=${searchStringCustomer}`
      : sortOrder

    let queryParams=''

    if (isScroll) {
      queryParams = `&page=${this.state.customerPagination.nextPage}&per_page=50`
    }

		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers?${sortOrder}${queryParams}`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					const {customers, current_page, next_page, total_entries, total_pages} = response.data

          let allDataCustomer;

          if (isScroll && this.state.loadedCustomers) {
            allDataCustomer = unionBy(this.state.loadedCustomers, customers, 'id')
          } else {
            allDataCustomer = customers;
          }

          if (this.state.isHaveCustomers == null) {
            this.setState({
              isHaveCustomers: customers.length > 0
            })
          }

					return this.setState({
						loadedCustomers: allDataCustomer,
            customerOffset: next_page,
						originalLoadedCustomers: allDataCustomer,
						loadingCustomers: false,
            customerPagination: {
							nextPage: next_page ?? 1,
							totalEntries: total_entries,
							totalPages: total_pages,
							currentPage: current_page,
						},
					})
				}
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return this.props.addToast({
					text: message,
					type: 'error',
				})
			})
	}

	loadInventory = () => {
		this.setState({loadingProduct: true})
		Globals.New_Axios()
			.get(
				`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches?kind=inventory&q[g][0][status_in][]=on_hand&frontend_filter_enabled=true${this.state.inventorySearch}`
			)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					const orderedItems = this.state.orderedItems
					//let loadedProducts = response.data.watches

					let loadedProducts = response.data.watches.map(post => {
						post.inBag = orderedItems.map(item => item.id).includes(post.id)
						post.qty = 1
						return post
					})
					this.setState({loadingProduct: false})
					return this.setState({
						loadedProducts: loadedProducts,
						originalLoadedProducts: loadedProducts,
					})
				}

				this.setState({loadingProduct: false})
				return this.setState({
					loadedProducts: [],
				})
			})
			.catch(error => {
				this.setState({loadingProduct: false})
				return this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	handleLoadUsers = () => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/users`, {
				params: {
					q: {
						s: 'created_at desc',
					},
					per_page: 10000,
				},
			})
			.then(response => {
				let {users} = response.data
				return this.setState({
					loadedSpaceUsers: users,
				})
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return this.props.addToast({
					text: message,
					type: 'error',
				})
			})
	}

  handleSearchCustomerDebounce = debounce($event => {
      this.setState({
        searchStringCustomer: $event.target.value,
      }, () => {
        this.handleLoadCustomers()
      })
    }, 1000)

	handleOpenOrderFrom = $event => {
		if (this.props.currentSpace?.internal_subscription.plan_type === 'performance' && this.props.currentSpace?.internal_subscription.status === 'active') {
			this.setState({openPerformanceLimitAccessModal: true})
		} else {
			this.handleLoadCustomers()
			this.setState({
				isVisibleAddSale: true,
			}, () => {
				setTimeout(() => {
					this.selectCustomerRef?.current?.focus()
				}, 100)
			})
		}
	}

	handleOpenPerformanceLimitAccessModal = () => {
		this.setState({openPerformanceLimitAccessModal: true})
	}

	getDefaultNewBillingAddress = (customer) => {
		return {
			...(customer?.customer_addresses.find(address => address.is_billing_default) || {
			company_name: '',
			contact_name: '',
			phone_number: '+1'
			}),
			...initNewAddress,
			id: null
		};
		};

	getDefaultNewShippingAddress = (customer) => {
		return {
			...(customer?.customer_addresses.find(address => address.is_shipping_default) || {
			company_name: '',
			contact_name: '',
			phone_number: '+1'
			}),
			...initNewAddress,
			id: null
		};
	};


	pickCustomer = customer => {
		this.setState({
			selectedCustomer: customer,
			selectedBillingAddress: customer.customer_addresses.find(address => address.is_billing_default),
			selectedShippingAddress: customer.customer_addresses.find(address => address.is_shipping_default),
			newBillingAddress: this.getDefaultNewBillingAddress(customer),
			newShippingAddress: this.getDefaultNewShippingAddress(customer),
			step: 2,
		})

    this.loadInventory()
	}

	handleAddingItemToOrder = item => {
		const orderedItems = this.state.orderedItems
		if (!isEmpty(orderedItems.filter(_item => _item.id === item.id))) return

		this.setState({
			orderedItems: [...this.state.orderedItems, item],
			loadedProducts: this.state.loadedProducts.map(product => {
				if (product.id === item.id) product.inBag = true
				return product
			}),
		})
	}

	handleRemovingItemToOrder = (item, $event) => {
		if(!!$event) {
			$event.stopPropagation()
		}
		this.setState({
			orderedItems: this.state.orderedItems.filter(product => product.id !== item.id),
			loadedProducts: this.state.loadedProducts.map(product => {
				if (product.id === item.id) product.inBag = false
				return product
			}),
		})
	}

	handleItemPriceChange = (item, price) => {
		let nPrice = price.replaceAll(',', '')
		const newOrderedItems = this.state.orderedItems.map(product => (product.id === item.id ? {...product, wholesale_price: nPrice} : product))
		this.setState({
			loadedProducts: this.state.loadedProducts.map(product => {
				if (product.id === item.id) product.wholesale_price = nPrice
				return product
			}),
			orderedItems: newOrderedItems,
		})
	}

	handleOrderItemPriceChange = (item, $event) => {
		let _price = $event.target.value || ''
		_price = _price.replaceAll(',', '')

		this.setState({
			orderedItems: this.state.orderedItems.map(product => {
				if (product.id === item.id) product.wholesale_price = _price
				return product
			}),
		})
	}

	handleOrderItemQtyChange = debounce((item, $event) => {
		this.setState({
			orderedItems: this.state.orderedItems.map(product => {
				if (product.id === item.id) product.qty = $event.target.value
				return product
			}),
		})
	}, 500)

	handleSearchProducts = debounce($event => {
		const keyword = $event.target.value
		let search = ''
		if (!isEmpty(keyword)) {
			search = `&q[${Globals.watchSearchParams}]=${keyword}`
		}
		this.setState(
			{
				inventorySearch: search,
			},
			async () => {
				this.loadInventory()
			}
		)
	}, 1000)

	hideAddNewMiscProduct = () => {
		this.setState({isVisibleMiscProducts: false})
		focusElement('.add-new-sale-view')
	}

	handleMiscItemList = newMiscItem => {
		const _miscItems = this.state.miscItems
		this.setState({miscItems: [..._miscItems, newMiscItem]})
	}

	handleRemovingMiscItemMemo = i => {
		this.setState({
			miscItems: this.state.miscItems.filter((product, index) => index !== i),
		})
	}

	handleMemoMiscPriceChange = (event, i) => {
		let _price = event.target.value.replaceAll(',', '')
		_price = isNaN(_price) ? 0 : _price

		const _miscItems = this.state.miscItems.map((product, index) => (index === i ? {...product, price: _price} : product))
		this.setState({
			miscItems: _miscItems,
		})
	}

	setIsVisibleAllowSyncQuickbook = (value) => {
		this.setState({isVisibleAllowSyncQuickbook: value})
	}

	handleAllowSyncQuickbook = (e) => {
		this.setState({
			syncToQuickbook: true,
			isVisibleAllowSyncQuickbook: false
		}, () => {
			this.handleSubmitOrder(e)
		})
	}

	handleChangeMemoBox = (event, item) => {
		event.stopPropagation()
		const _orderedItems = this.state.orderedItems.map(product => (product.id === item.id ? {...product, box: !product.box} : product))
		this.setState({
			orderedItems: _orderedItems,
		})
	}

	handleSubmitOrder = $event => {
		$event.preventDefault()

		const state = this.state
		let {selectedCustomer, orderedItems, shippingPrice, tradeInItems} = state
		selectedCustomer = selectedCustomer.id
		let updateShippingPriceForSale = shippingPrice
		if (state.isCustomerLabeled) {
			updateShippingPriceForSale = 0
		}

		orderedItems = orderedItems.map(item => {
			return {
        saleable_id: item.id,
        quantity: item.qty,
        price: Globals.revertPrice(item.wholesale_price),
        saleable_type: 'Watch',
        note: item.note,
        box: item.box
      }
		})

		if (state.sendingData) return

		this.setState({sendingData: true})

    const commentAttributes = !state.isVisibleComments || isEmpty(state.orderComment.content) ? [] : [state.orderComment]

		const saleParams = {
			customer_id: selectedCustomer,
			sale_items_attributes: orderedItems,
			status: 'pending_payment',
			comments_attributes: commentAttributes,
			shipping_price: updateShippingPriceForSale,
			is_tax_exempt: state.isTaxExempt,
			misc_items: state.miscItems,
			trade_in_items: tradeInItems,
			customer_label: state.isCustomerLabeled,
			tracking_number: state.trackingNumber,
			payment_term_method: state.selectedTerm.value,
			sync_to_quickbook: this.props.currentSpace.quickbook_integration?.invoice_sync_type === 'all_item' ? true : state.syncToQuickbook
		}

		if(state.selectedSalesPerson) {
			saleParams['salesperson_id'] = state.selectedSalesPerson.id
		}

		if (state.selectedTerm.value === 'custom') {
			saleParams['payment_term_days'] = state.termCustomField
			saleParams['payment_term_method'] = state.termCustomSelect.value
		}
		if (state.selectedBillingAddress) {
			saleParams.billing_address_id = state.selectedBillingAddress.id
		} else {
			saleParams.billing_address_attributes = {...state.newBillingAddress}
			if (state.newBillingAddress.phone_number === '+1') {
				saleParams.billing_address_attributes['phone_number'] = ''
			}

			saleParams.billing_address_attributes['in_contact'] = state.newBillingAddress.save_to_contact
			saleParams.billing_address_attributes['is_manual'] = state.isManualBillingAddress
			saleParams.billing_address_attributes['customer_id'] = selectedCustomer
		}

		if (state.selectedShippingAddress) {
			saleParams.shipping_address_id = state.selectedShippingAddress.id
		} else {
			saleParams.shipping_address_attributes = {...state.newShippingAddress}
			if (state.newShippingAddress.phone_number === '+1') {
				saleParams.shipping_address_attributes['phone_number'] = ''
			}

			saleParams.shipping_address_attributes['in_contact'] = state.newShippingAddress.save_to_contact
			saleParams.shipping_address_attributes['is_manual'] = state.isManualShippingAddress
			saleParams.shipping_address_attributes['customer_id'] = selectedCustomer
		}

		if (saleParams.is_tax_exempt) {
			saleParams.tax_rate = 0
		} else {
			saleParams.tax_rate = state.taxRate
		}

		if(!!saleParams?.billing_address_attributes && !!saleParams?.shipping_address_attributes && !saleParams?.billing_address_attributes?.company_name && !saleParams?.shipping_address_attributes?.company_name && !saleParams?.billing_address_attributes.contact_name && !saleParams?.shipping_address_attributes?.contact_name) {
			this.setState({sendingData: false})
			return this.props.addToast({
				title: 'Error',
				text: 'Billing and Shipping addresses must include either a Company Name or a Contact Name.',
				type: 'error',
			})
		}

		if (isFunction(this.handlePrimaryTab)) this.handlePrimaryTab()

		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales`, {
				sale: saleParams,
			})
			.then(response => {
				this.setState({sendingData: false})

				//Push the item into the list.
				if (response.status && response.status >= 200 && response.status < 300) {
					const order = response.data
					return this.setState({
						ordersList: [order, ...this.state.ordersList],
						originalOrdersList: [order, ...this.state.originalOrdersList],
						step: 1,
						isVisibleAddSale: false,
						loadedCustomers: null,
            isHaveCustomers: null,
            customerPagination: {
              nextPage: 1,
              totalPages: 1,
              totalCount: 0,
              totalEntries: 0,
              currentPage: 1,
            },
            searchCustomer: '',
						originalLoadedCustomers: null,
						selectedCustomer: null,
						orderedItems: [],
						loadedProducts: [],
						miscItems: [],
						tradeInItems: [],
						shippingPrice: 0,
						isCustomerLabeled: false,
						selectedTerm: Globals.saleTerms[0],
						trackingNumber: '',
						orderComment: {content: '', visible_on_pdf: false},
						termCustomField: '',
						termCustomSelect: {value: 'days', label: 'Days'},
						newBillingAddress: initAddress,
						newShippingAddress: initAddress,
						selectedSalesPerson: null,
						isVisibleComments: false,
						isManualBillingAddress: false,
						isManualShippingAddress: false,
						syncToQuickbook: false,
						errors: []
					})
				}

				return this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
			.catch(error => {
				this.setState({sendingData: false})
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return this.props.addToast({
					text: message,
					type: 'error',
				})
			})
	}

	loadSingleOrder = order => {
		this.setState({isVisibleSingleOrder: true, singleOrderItemId: order.id})
	}

	handleKeyDownTrackingNumber = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.trackingNumberInputRef.current.blur();
		}
	};

	updateOrderStatus = orders => {
		const cancelledOrders = orders.filter(order => order.status === 'cancel'),
			pendingOrders = orders.filter(order => order.status === 'pending_payment'),
			completeOrders = orders.filter(order => order.status === 'payment_received')

		this.setState({
			ordersList: orders,
			ordersListCancelled: cancelledOrders,
			ordersListPending: pendingOrders,
			ordersListComplete: completeOrders,
		})
	}

	handleUpdateItemProduct = (item) => {
		const neworderedItems = this.state.orderedItems.map(product => (product.id === item.id ? {...product,  box: item.box} : product))
		this.setState({orderedItems: neworderedItems})
	}

	handleSearchOrder = $event => {
		let searchString = $event.target.value
		searchString = new DOMParser().parseFromString(searchString, 'text/html')
		searchString = searchString.documentElement.textContent
		const searchValue = searchString.toLowerCase().replaceAll('\\', '')
		this.setState(
			{
				searchString: searchValue,
				offset: 1
			},
			() => {
				this.handleLoadOrders()
			}
		)
	}

	handleCloseCustomerForm = $event => {
		$event.preventDefault()
		this.setState({
			customerFormVisible: false,
		})
		focusElement('.add-new-sale-view')
	}

	handleNewAddCustomer = customer => {
		const loadedCustomers = [...this.state.loadedCustomers, customer]
		const originalLoadedCustomers = [...this.state.originalLoadedCustomers, customer]
		this.setState({
			loadedCustomers: loadedCustomers,
			originalLoadedCustomers: originalLoadedCustomers,
		})
	}

	updateTradeIn = item => {
		let items = this.state.tradeInItems
		items.push(item)
		this.setState({tradeInItems: items})
	}

	hideTradeInDialog = () => {
		this.setState({showTradeIn: false})
		focusElement('.add-new-sale-view')
	}

	handleRemovetradeIn = i => {
		let items = this.state.tradeInItems
		items = items.filter((item, index) => index !== i)
		this.setState({tradeInItems: items})
	}

	handleTradeInPriceChange = (i, value) => {
		let _price = value.replaceAll(',', '')
		_price = isNaN(_price) ? 0 : _price

		let items = this.state.tradeInItems
		items = items.map((item, index) => {
			if (index === i) {
				item.price = !!_price ? _price : 0
				return item
			} else {
				return item
			}
		})
		this.setState({tradeInItems: items})
	}

	closeSingleOrderItem = () => {
		this.setState({isVisibleSingleOrder: false})
	}
	handleCloseProductsHunter = () => {
		this.setState({isVisibleProductsHunter: false, inventorySearch: '', loadedProducts: [], errors: []}, () => {
			this.loadInventory()
		})
		focusElement('.add-new-sale-view')
	}

	setTabState = tabState => {
		this.setState(
			{
				activeTab: tabState,
				offset: 1,
				ordersList: [],
				ordersListCancelled: [],
				ordersListPending: [],
				ordersListComplete: [],
			},
			() => {
				this.handleLoadOrders()
			}
		)
	}

	updateItemInSales = async (saleData) => {
		const { activeTab, ordersList, ordersListPending, ordersListCancelled } = this.state
		if(activeTab === 'all') {
			const index = findIndex(ordersList, { id: saleData.id })
			ordersList[index] = saleData
			this.setState(
				{
					ordersList: [...ordersList],
				}
			);
		} else if(activeTab === 'pending') {
			const index = findIndex(ordersListPending, { id: saleData.id })
			ordersListPending[index] = saleData
			const pendingPayment = ordersListPending.filter(sale => sale.status === 'pending_payment')
			this.setState(
				{
					ordersListPending: [...pendingPayment],
				}
			);
		} else if(activeTab === 'cancelled') {
			const index = findIndex(ordersListCancelled, { id: saleData.id })
			ordersListCancelled[index] = saleData
			const cancelledPayment = ordersListCancelled.filter(sale => sale.status === 'cancel')
			this.setState(
				{
					ordersListCancelled: [...cancelledPayment],
				}
			);
		} else if(activeTab === 'complete') {
			const index = findIndex(ordersListCancelled, { id: saleData.id })
			ordersListCancelled[index] = saleData
			const completeOrders = ordersListCancelled.filter(sale => sale.status === 'payment_received')
			this.setState(
				{
					ordersListCancelled: [...completeOrders],
				}
			);
		};
	}

	updateItemInSalesWhenLiveReload = async (saleData) => {
		const { activeTab, ordersList, ordersListPending, ordersListCancelled, ordersListComplete } = this.state
		if(activeTab === 'all') {
			const index = findIndex(ordersList, { id: saleData.id })
			ordersList[index] = saleData
			this.setState(
				{
					ordersList: [...ordersList],
				}
			);
		} else if(activeTab === 'pending') {
			const index = findIndex(ordersListPending, { id: saleData.id })
			if(index > -1) {
				ordersListPending[index] = saleData
				const pendingPayment = ordersListPending.filter(sale => sale.status === 'pending_payment')
				this.setState(
					{
						ordersListPending: pendingPayment,
					}
				);

			} else {
				const pendingPayment = [saleData, ...ordersListPending].filter(sale => sale.status === 'pending_payment')
				this.setState({
					ordersListPending: pendingPayment,
				})
			}
		} else if(activeTab === 'cancelled') {
			const index = findIndex(ordersListCancelled, { id: saleData.id })
			if(index > -1) {
				ordersListCancelled[index] = saleData
				const cancellingPayment = ordersListCancelled.filter(sale => sale.status === 'cancel')
				this.setState(
					{
						ordersListCancelled: cancellingPayment,
					}
				);

			} else {
				const cancelledData = [saleData, ...ordersListCancelled].filter(sale => sale.status === 'cancel')
				this.setState({
					ordersListCancelled: cancelledData,
				})
			}
		} else if(activeTab === 'complete') {
			const index = findIndex(ordersListComplete, { id: saleData.id })
			if(index > -1) {
				ordersListComplete[index] = saleData
				const pendingData = ordersListComplete.filter(sale => sale.status === 'payment_received')
				this.setState(
					{
						ordersListComplete: [...pendingData],
					}
				);

			} else {
				const pendingDatas = [saleData, ...ordersListComplete].filter(sale => sale.status === 'payment_received')
				this.setState({
					ordersListComplete: pendingDatas,
				})
			}
		};
	}

	handleShowDetailWatch = (watch) => {
		this.setState({loadingData: true})
		Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${watch.id}`)
		.then(async response => {
			this.setState(
				{
					watchItem: response.data,
					loadingData: false
				},
				() => this.props.toggleDetailsCardInventory(true)
			)
		})
		.catch(error => {
			this.setState({loadingData: true})
			return this.props.addToast({
				text: 'Something went wrong please try again later.',
				type: 'error',
			})
		})
	}

	handleCloseCustomerList = () => {
		this.setState({
			step: 1,
			isVisibleAddSale: false,
			loadedCustomers: null,
			originalLoadedCustomers: null,
			selectedCustomer: null,
      isHaveCustomers: null,
      customerPagination: {
        nextPage: 1,
        totalPages: 1,
        totalCount: 0,
        totalEntries: 0,
        currentPage: 1,
      },
      searchCustomer: '',
			orderedItems: [],
			miscItems: [],
			loadedProducts: [],
			tradeInItems: []
		})
	}

	handleChangeShippingPrice = (event) => {
		this.setState({
			shippingPrice: Globals.revertPrice(event.target.value),
		})
	}

	handleCloseAddSale = () => {
		this.setState({
			step: 1,
			isVisibleAddSale: false,
			loadedCustomers: null,
			originalLoadedCustomers: null,
			selectedCustomer: null,
      isHaveCustomers: null,
      customerPagination: {
        nextPage: 1,
        totalPages: 1,
        totalCount: 0,
        totalEntries: 0,
        currentPage: 1,
      },
      searchCustomer: '',
			orderedItems: [],
			loadedProducts: [],
			miscItems: [],
			tradeInItems: [],
			shippingPrice: 0,
			trackingNumber: '',
			termCustomField: '',
			termCustomSelect: {value: 'days', label: 'Days'},
			selectedTerm: Globals.saleTerms[0],
			selectedBillingAddress: null,
			selectedShippingAddress: null,
			newBillingAddress: initAddress,
			newShippingAddress: initAddress,
			selectedSalesPerson: null,
			isVisibleComments: false,
			isManualBillingAddress: false,
			isManualShippingAddress: false,
			errors: []
		})
	}

	handleSelectAddress = (type, address) => {
		if (type === 'billing') {
			this.setState({
				selectedBillingAddress: address,
				newBillingAddress: {
					...this.getDefaultNewBillingAddress(this.state.selectedCustomer),
					visible: false
				},
			})
		} else {
			this.setState({
				selectedShippingAddress: address,
				newShippingAddress: {
					...this.getDefaultNewShippingAddress(this.state.selectedCustomer),
					visible: false
				},
			}, () => {
				this.handleFetchTaxRate()
			})
		}
	}

	handleAddNewAddress = type => {
		if (type === 'billing') {
			this.setState({
				selectedBillingAddress: null,
				newBillingAddress: {
					...this.state['newBillingAddress'],
					visible: true
				},
			})
		} else {
			this.setState({
				selectedShippingAddress: null,
				newShippingAddress: {
					...this.state['newShippingAddress'],
					visible: true,
				},
			})

			this.handleFetchTaxRate()
		}
	}

	handleCheckCountries = (value) => {
		if(value.long_name === 'Japan' && value.types.includes('country')) {
			if(!value.types.includes('street_number') || !value.types.includes('route')) {
				return false
			}
		}
		return true
	}

	handleChangeGoogleAddress = (place) => {
		const tmpAddress = []
    let subpremise

		if (!place.address_components) return

		(place.address_components || []).forEach(value => {
			if (value.types.includes('street_number') || value.types.includes('route')) {
        tmpAddress.push(value.long_name)
			}

      if (value.types.includes('subpremise')) {
        const match = value.long_name.match(/\d+$/);
        subpremise = match ? match[0] : value.long_name;
      }

			const isCheckCountries = this.handleCheckCountries(value)
			if(!isCheckCountries) {
				const part =  place.formatted_address.split(", ")
				tmpAddress.push(part[0])
			}
		})

		let city = place.address_components.find(component => component.types.includes('locality'))?.long_name || ''
		if(!city) {
			city = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || ''
		}

		//Note: Check Japan Country

		const streetNumber = place.address_components.find(component => component.types.includes('street_number'))?.long_name || ''
		const streetName = place.address_components.find(component => component.types.includes('route'))?.long_name || ''
		const searchedAddress = {
			full_address: place.formatted_address.replace(` ${subpremise}`, ''),
			address: tmpAddress?.toString().replaceAll(',', ' ').trimStart(),
			street: (streetNumber ? streetNumber + ' ' : '' )+ streetName,
			city: city,
			state: place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '',
			country: place.address_components.find(component => component.types.includes('country'))?.long_name || '',
			zip_code: place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '',
		}

    if (subpremise) {
      searchedAddress.apt_unit_number = subpremise
    }

		return searchedAddress
	}

	handleChangeBillingAddress = (place) => {
		const {isManualBillingAddress} = this.state

		if (!place) {
			return
		}

		const searchedAddress =  this.handleChangeGoogleAddress(place)
		if (!isManualBillingAddress && searchedAddress) {
			let newAddress = assignIn({}, this.state.newBillingAddress, searchedAddress)

			this.setState({
				newBillingAddress: newAddress,
				billingAddress: searchedAddress?.address || ''
			})
		}
	}

	handleChangeShippingAddress = (place) => {
		const {isManualShippingAddress} = this.state

		if (!place) {
		  return
		}

		const searchedAddress = this.handleChangeGoogleAddress(place)

		if (!isManualShippingAddress && searchedAddress) {
		  const newAddress = assignIn({}, this.state.newShippingAddress, searchedAddress)
		  this.setState({
			newShippingAddress: newAddress,
			shippingAddress: searchedAddress?.address || ''
		  }, () => {
			this.handleFetchTaxRate()
		  })
		}
  }

  handleCommentChange = $event => {
    let value = $event.target.value

    if ($event.target.type === 'checkbox') {
      value = $event.target.checked
    }

    this.setState({
      orderComment: {
        ...this.state.orderComment,
        [$event.target.name]: value
      }
    })
  }

	handleSelectSalesPerson = user => {
		if(!!user) {
			this.setState({selectedSalesPerson: user})
		} else {
			this.setState({selectedSalesPerson: null})
		}
	}

	handleSelectCustomTerm = selectedTermObject => {
		if(selectedTermObject.value === 'custom') {
			this.setState({selectedTerm: selectedTermObject, termCustomSelect: {value: 'days', label: 'Days'}, termCustomField: ''})
		} else {
			this.setState({termCustomSelect: selectedTermObject})
		}
	}

	handleSelectTerms = value => {
		this.setState({selectedTerm: value})
	}

	handleNewAddressChange = (type, field, value) => {
		if (field === 'phone_number') {
			this.setState(prevState => ({
				[type]: {
					...prevState[type],
					phone_number: formatPhoneNumber(value),
				},
			}))
		} else if (field === 'save_to_contact') {
			this.setState(prevState => ({
				[type]: {
					...prevState[type],
					[field]: !prevState[type][field],
				},
			}))
		} else {
			this.setState(prevState => ({
				[type]: {
					...prevState[type],
					[field]: value,
				},
			}), () => {
				if(['zip_code', 'country'].includes(field)) {
					this.handleFetchTaxRate()
				}
			})
		}
	}

	handleCalculateTax = () => {
		const { taxRate, isTaxExempt, orderedItems, tradeInItems, miscItems  } = this.state

		if (isTaxExempt) {
			return 0
		}

		let total = 0
		total += sum(orderedItems.map(t => (t.wholesale_price ? Globals.revertPrice(t.wholesale_price) : 0)))
		total += sum(miscItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0)))
		total -= sum(tradeInItems.map(t => Globals.revertPrice(t.price)))

		return total * taxRate
	}

	calculateTotalIncludeTax = () => {
		return this.calculateTotalExcludeTax() + this.handleCalculateTax()
	}

	calculateTotalExcludeTax = () => {
		const { orderedItems, tradeInItems, miscItems, isCustomerLabeled, shippingPrice } = this.state;
		let total = 0;
		total += sum(orderedItems.map(t => (t.wholesale_price ? Globals.revertPrice(t.wholesale_price) : 0)));
		total += sum(miscItems.map(t => {
			if (t.price) {
				return Globals.revertPrice(t.price);
			}
			return 0;
		}));
		total += (isCustomerLabeled ? 0 : Globals.revertPrice(shippingPrice));
		total -= sum(tradeInItems.map(t => Globals.revertPrice(t.price)));

		return total;
	}

	handleChangeTaxExempt = (event) => {
		const { isTaxExempt, taxRate } = this.state
		this.setState({isTaxExempt: !isTaxExempt}, () => {
			if (!taxRate) {
				this.handleFetchTaxRate()
			}
		})
	}

	handleFetchTaxRate = debounce(() => {
		const { isTaxExempt } = this.state;
		if (isTaxExempt) {
			return
		}

		const { selectedShippingAddress, newShippingAddress } = this.state

		let zipCode = 0;

		if (!!selectedShippingAddress) {
			zipCode = selectedShippingAddress?.zip_code
		} else {
			zipCode = newShippingAddress?.zip_code
		}

		if (!!zipCode) {
			Globals.New_Axios()
				.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales/fetch_tax_rate`, {
					params: {
						zip_code: zipCode,
					},
				})
				.then(response => {
					this.setState({taxRate: response.data?.taxSales || 0})
				})
				.catch(error => {
					const message = error.response?.data?.message || 'Something went wrong please try again later.'
					return this.props.addToast({
						text: message,
						type: 'error',
					})
				})
		} else {
			this.setState({taxRate: 0})
		}
	}, 500)

	handleVisibleManualBillingAddress = () => {
		const { newBillingAddress, isManualBillingAddress, billingAddress } = this.state
		if(!isManualBillingAddress) {
			if(!newBillingAddress.address) {
				const address = {
					...newBillingAddress,
					address: newBillingAddress.full_address
				}
				this.setState({newBillingAddress: address})
			}
		} else {
			const address = {
				...newBillingAddress,
				address: billingAddress
			}
			this.setState({newBillingAddress: address})
		}
		this.setState({isManualBillingAddress: !this.state.isManualBillingAddress})
	}

	handleVisibleManualShippingAddress = () => {
		const { newShippingAddress, isManualShippingAddress, shippingAddress } = this.state
		if(!isManualShippingAddress) {
			if(!newShippingAddress.address) {
				const address = {
					...newShippingAddress,
					address: newShippingAddress.full_address
				}
				this.setState({newShippingAddress: address})
			}
		} else {
			const address = {
				...newShippingAddress,
				address: shippingAddress
			}
			this.setState({newBillingAddress: address})
		}
		this.setState({isManualShippingAddress: !this.state.isManualShippingAddress})
	}
	refreshListAfterProductEdit = (data) => {
		const updatedOrdersList = this.state.ordersList.map((order) => {
			const updatedSaleItems = order.sale_items.map((item) => {
				if (item.saleable.id === data.id) {
					return {
						...item,
						saleable: {
							...item.saleable,
							reference_number: data.reference_number,
							sku: data.sku,
							images: data.images,
						},
					};
				}
				return item;
			});
			return { ...order, sale_items: updatedSaleItems };
		});

		this.setState({ ordersList: updatedOrdersList, watchItem: data });
	};

  handleOpenNoteModal = (event, saleItem) => {
    this.setState({
      saleItemSelected: saleItem,
      openNoteModal: true
    })
  }

  handleCloseNoteModal = () => {
    this.setState({
      saleItemSelected: null,
      openNoteModal: false
    })
  }


  setNoteData = (event, value) => {
    const newOrderedItems = this.state.orderedItems.map(item => {
      if (item.id === this.state.saleItemSelected.id) {
        return {
          ...item,
          note: value
        }
      }
      return item
    })

    this.setState({
      orderedItems: newOrderedItems,
      openNoteModal: false,
      saleItemSelected: null
    })
  }

	handleActiveTabDataList = () => {
		const activeTab = this.state.activeTab;

		const tabMappingWithDataList = {
			'all': this.state.ordersList,
			'pending': this.state.ordersListPending,
			'cancelled': this.state.ordersListCancelled,
			'complete': this.state.ordersListComplete
		}

		return tabMappingWithDataList[activeTab];
	}

	render() {
		const state = this.state
		const props = this.props
		const { isMobile } = this.props
		return (
			<React.Fragment>
				{props.loggedin && (
					<React.Fragment>
						{state.openPerformanceLimitAccessModal && (
							<PerformanceLimitAccessModal
								closeModal={() => this.setState({openPerformanceLimitAccessModal: false})}
							/>
						)}

							<section className="hidden relative md:block">
								<TopBar toggleSideBar={() => props.toggleSidebar(!props.isOpenSideBar)} />
								<div className="flex relative">
									<SideBar isOpen={props.isOpenSideBar} />
									<main className="flex flex-col relative w-full h-screen overflow-y-hidden" style={{paddingTop: (props.currentSpace.should_show_upgrade_announcement) ? '6.6rem' : '4rem'}}>
										<div className="bg-white overflow-hidden rounded-tl-lg w-full h-full flex flex-col">
											<div className="flex relative w-full mt-6 mb-6 px-5 flex-col md:flex-row">
												<div className="flex flex-col items-center">
													<h1 className="font-semibold text-3xl">Sales</h1>
												</div>
												<div className="flex relative w-full justify-center md:justify-end items-center space-x-2">
													<div>
														<div className="text-gray-400 relative">
															<input
																ref={this.searchSaleInputRef}
																className="text-gray-700 text-xs bg-white border flex items-center max-w-xs outline-blue-300 px-3 pr-9 relative rounded w-full md:w-screen h-11"
																type="text"
																name="search"
																placeholder="Search Here..."
																onChange={this.handleSearchOrder}
															/>
															<button className="pointer-events-none absolute right-0 top-0 h-full px-3">
																<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																	<path
																		fillRule="evenodd"
																		d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
																		clipRule="evenodd"
																	/>
																</svg>
															</button>
														</div>
													</div>
													<div>
														<Tooltip title="Add new order" placement="bottom" arrow>
															<button className="bg-white border flex items-center px-3 py-[11px] relative rounded w-full" onClick={this.handleOpenOrderFrom}>
																<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																	<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
																</svg>
																<span className="ml-1 font-normal text-sm text-center">Add</span>
															</button>
														</Tooltip>
													</div>
												</div>
											</div>

												<div
													className="text-sm font-medium text-center text-gray-500 border-t border-b border-gray-200">
													<ul className="flex flex-wrap -mb-px space-x-4">
														<li>
															<button
																onClick={() => this.setTabState('all')}
																className={Classnames('inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300', {
																	'text-blue-600 border-blue-600 hover:text-blue-600 hover:border-blue-600': state.activeTab === 'all'
																})}
															>
																All
															</button>
														</li>
														<li>
															<button
																onClick={() => this.setTabState('pending')}
																className={Classnames('inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300', {
																	'text-blue-600 border-blue-600 hover:text-blue-600 hover:border-blue-600': state.activeTab === 'pending'
																})}
															>
																Pending
															</button>
														</li>
														<li>
															<button
																onClick={() => this.setTabState('cancelled')}
																className={Classnames('inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300', {
																	'text-blue-600 border-blue-600 hover:text-blue-600 hover:border-blue-600': state.activeTab === 'cancelled'
																})}
															>
																Cancelled
															</button>
														</li>
														<li>
															<button
																onClick={() => this.setTabState('complete')}
																className={Classnames('inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300', {
																	'text-blue-600 border-blue-600 hover:text-blue-600 hover:border-blue-600': state.activeTab === 'complete'
																})}
															>
																Complete
															</button>
														</li>
													</ul>
												</div>

												<div
													style={{height: '100%'}}
													ref={this.listWrapperRef}
												>
													<InfiniteScroll
														loader={() => null}
														dataLength={state.ordersList?.length || 0} //This is important field to render the next data
														next={this.loadMoreData}
														height={(this.state.height || 0) + 'px'}
														scrollThreshold={'50%'}
														hasMore={true}
													>
                            <div className="flex h-full flex-col relative ">
                              <div className="inline-block w-full relative">
																<DataTable dataList={this.handleActiveTabDataList()} loadSingleOrder={this.loadSingleOrder} handleShowDetailWatch={this.handleShowDetailWatch} />
                              </div>
                            </div>
													</InfiniteScroll>
												</div>
											</div>
											{state.loadingData && (
												<div
													className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
													<button
														type="button"
														className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
														disabled
													>
														<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
															 xmlns="http://www.w3.org/2000/svg" fill="none"
															 viewBox="0 0 24 24">
															{' '}
															<circle className="opacity-25" cx="12" cy="12" r="10"
																	stroke="currentColor" strokeWidth="4" />
															<path
																className="opacity-75"
																fill="currentColor"
																d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
															/>
														</svg>
														<span>Loading... </span>
													</button>
												</div>
											)}
										</main>
									</div>
								</section>

						{state.isVisibleAddSale && (
							<>
								<div
									className="hidden add-new-sale-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-40 md:block"
									tabIndex="-1"
									ref={this.selectCustomerRef}
									onKeyDown={onPressEscape((event) => this.handlePressEscape(event))}
								>
									<div className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-hidden h-full" style={{maxWidth: '90%', width: '100%'}}>
										<div className="flex items-center gap-3 bg-white p-[24px]">
											<div className="flex flex-row items-center justify-between w-full">
												<div className="flex gap-1">
													<button
														onClick={this.handleCloseAddSale}
													>
														<svg className="w-6 h-6" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
														</svg>
													</button>
													<h2 className="text-[#5D6E81] text-[24px] font-bold uppercase ms-[9px]">Add new order</h2>
												</div>
											</div>
											{state.step === 1 && (
												<div className=" w-60 mr-[16px]">
													<Tooltip title="Add new customer" placement="bottom" arrow>
														<button
															className="bg-white border flex items-center justify-center px-3 py-2 relative rounded w-full"
															onClick={$event =>
																this.setState({
																	customerFormVisible: true,
																})
															}
														>
															<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
															</svg>
															<span className="ml-1 font-normal text-sm text-center">Add New Customer</span>
														</button>
													</Tooltip>
												</div>
											)}

											{state.step === 2 && (
												<div className="flex justify-end items-center w-full">
													<div className="mr-5">
															<Tooltip title="Add a product from your inventory" placement="bottom" arrow>
																<button
																	onClick={() =>
																		this.setState({
																			isVisibleProductsHunter: true,
																		})
																	}
																	className="bg-[#2863EB] text-white border border-blue-500 flex items-center px-3 py-2 relative rounded w-full"
																>
																	<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																		<path
																			fillRule="evenodd"
																			d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
																			clipRule="evenodd"
																		/>
																	</svg>
																	<span className="font-normal text-sm text-center">Add products</span>
																</button>
															</Tooltip>
													</div>
													<div className="mr-5">
														<Tooltip title="Add a product that is not inventory" placement="bottom" arrow>
															<button
																onClick={() =>
																	this.setState({
																		isVisibleMiscProducts: true,
																	})
																}
																className="bg-[#2863EB] text-white border border-blue-500 flex items-center px-3 py-2 relative rounded w-full"
															>
																<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																	<path
																		fillRule="evenodd"
																		d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
																		clipRule="evenodd"
																	/>
																</svg>
																<span className="font-normal text-sm text-center">Add misc products</span>
															</button>
														</Tooltip>
													</div>
													<div>
														<Tooltip title="Add a trade-in item." placement="bottom" arrow>
															<button
																onClick={() =>
																	this.setState({
																		showTradeIn: true,
																	})
																}
																className="bg-[#2863EB] text-white border border-blue-500 flex items-center px-3 py-2 relative rounded w-full"
															>
																<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																	<path
																		fillRule="evenodd"
																		d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
																		clipRule="evenodd"
																	/>
																</svg>
																<span className="font-normal text-sm text-center">Add Trade-In</span>
															</button>
														</Tooltip>
													</div>
												</div>
											)}
										</div>

										<div className="h-screen divide-y divide-gray-200 px-0 pb-28 overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
											{state.step === 1 && (
												<React.Fragment>
													{isEmpty(state.originalLoadedCustomers) && state.loadingCustomers && (
														<div className="flex h-full items-center justify-center">
															<button
																disabled
																type="button"
																className="text-white bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 inline-flex items-center"
															>
																<svg
																	role="status"
																	className="inline mr-3 w-4 h-4 text-white animate-spin"
																	viewBox="0 0 100 101"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
																		fill="#E5E7EB"
																	/>{' '}
																	<path
																		d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
																		fill="currentColor"
																	/>
																</svg>
																<span>Loading customers...</span>
															</button>
														</div>
													)}

													{isEmpty(state.originalLoadedCustomers) && !state.loadingCustomers && isEmpty(state.searchStringCustomer) && (
														<div className="flex h-full items-center justify-center">
															<button
																disabled
																type="button"
																className="text-white bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 inline-flex items-center"
															>
																<span>
																	You have no customers in your space please create some in{' '}
																	<a className="underline" href="/Customers" alt="Create customers">
																		here
																	</a>
																</span>
															</button>
														</div>
													)}

													{state.isHaveCustomers && (
														<div className="px-10 h-full flex flex-col">
															<div className="flex relative w-full justify-between items-center py-5 border-b grow-0">
																<div>
																	<h2 className="font-semibold text-xl">Select a customer</h2>
																</div>
																<div>
																	<div className="text-gray-400 relative">
																		<input
																			ref={this.searchCustomerInputRef}
																			className="text-gray-700 text-xs bg-white border flex items-center max-w-xs outline-blue-300 px-3 pr-9 relative rounded w-screen h-11"
																			type="text"
																			name="search"
																			placeholder="Find a customer"
																			onChange={this.handleSearchCustomerDebounce}
																		/>
																		<button className="pointer-events-none absolute right-0 top-0 h-full px-3">
																			<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																				<path
																					fillRule="evenodd"
																					d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
																					clipRule="evenodd"
																				/>
																			</svg>
																		</button>
																	</div>
																</div>
															</div>
															<div
                                className='w-full overflow-hidden h-full flex-1'
                                ref={this.customerListRef}
                              >
                                {!isEmpty(state.originalLoadedCustomers) && !state.loadingCustomers && (
                                  <InfiniteScroll
                                    loader={() => null}
                                    dataLength={state.loadedCustomers?.length || 0} //This is important field to render the next data
                                    next={this.loadMoreDataCustomer}
                                    height={(this.state.customerListHeight || 0) + 'px'}
                                    scrollThreshold={'50%'}
                                    hasMore={true}
                                  >
                                    <table className="relative min-w-full border-b bg-transparent overflow-scroll">
                                      <thead
                                        className="bg-white sticky top-0 border-b border-[#e5e7eb]"
                                        style={{
                                          boxShadow: "inset 0 -1px 0 #e5e7eb"
                                        }}
                                      >
                                        <tr>
                                          <th className="p-2 pl-0 text-sm font-bold tracking-worderer">ID</th>
                                          <th className="p-2 text-sm font-bold tracking-worderer">Company</th>
                                          <th className="p-2 text-sm font-bold tracking-worderer">Name</th>
                                          <th className="p-2 text-sm font-bold tracking-worderer">Email</th>
                                          <th className="p-2 text-sm font-bold tracking-worderer">Phone Number</th>
                                        </tr>
                                      </thead>

                                      <tbody className="divide-y">
                                        {state.loadedCustomers.map((customer, index) => {
                                          return (
                                            <tr
                                              key={index}
                                              onClick={this.pickCustomer.bind(this, customer)}
                                              className={Classnames('cursor-pointer hover:bg-gray-100 bg-white')}
                                            >
                                              <th className="p-2 pl-0 text-sm font-normal tracking-worderer ">
                                                <div className='truncate max-w-[250px]'>
                                                  {customer.friendly_id ? `#${customer.friendly_id}` : ""}
                                                </div>
                                              </th>
                                              <th className="p-2 text-sm font-normal tracking-worderer">
                                                <div className='truncate max-w-[250px]'>
                                                  {customer.company_name}
                                                </div>
                                              </th>
                                              <th className="p-2 text-sm font-normal tracking-worderer">
                                                <div className='truncate max-w-[250px]'>
                                                  {`${customer.name}`}
                                                </div>
                                              </th>
                                              <th className="p-2 text-sm font-normal tracking-worderer">
                                                <div className='truncate max-w-[250px]'>
                                                  {customer.email}
                                                </div>
                                              </th>
                                              <th className="p-2 text-sm font-normal tracking-worderer">
                                                <div className='truncate max-w-[250px]'>
                                                  {customer.customer_phone_numbers[0]?.phone_number_formatted}
                                                </div>
                                              </th>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </InfiniteScroll>
                                )}
															</div>
														</div>
													)}
												</React.Fragment>
											)}

											{state.step === 2 && (
												<React.Fragment>
													{this.state.showTradeIn && <TradeInDialog updateTradeIn={this.updateTradeIn} props={this.props} hideTradeInDialog={this.hideTradeInDialog} />}
													<div>
														<div className="px-[24px]">
															<table className="min-w-full bg-transparent overflow-scroll px-[15px]">
																<thead className="bg-[#5D6E81] text-white uppercase font-semibold text-[14px]">
																	<tr>
																		<th className="w-1/4 px-[15px] py-2 text-sm font-semibold tracking-worderer rounded-tl-[5px] rounded-bl-[5px]">Product</th>
																		<th className="w-1/6 py-2 text-sm font-semibold tracking-worderer pl-2">SKU</th>
																		<th className="py-2 text-sm font-semibold tracking-worderer pl-4">Price</th>
																		<th className="px-[15px] py-2 text-sm font-semibold tracking-worderer text-center  rounded-tr-[5px] rounded-br-[5px]">Action</th>
																	</tr>
																</thead>
																<tbody className="divide-y">
																	{state.orderedItems.map((product, index) => {
																		return (
																			<tr key={index} className={Classnames('cursor-pointer hover:bg-gray-100 bg-white border-b text-[#5D6E81] font-medium')}>
																				<td className="py-6 px-[15px] text-sm font-normal tracking-worderer w-7/12">
																					<div className="w-10/12">
																						<p className="text-[#5D6E81] font-medium mb-[8px]">{product.name}</p>
                                            <div className='flex gap-4'>
                                              <p onClick={(event) => {this.handleChangeMemoBox(event, product)}} className="text-[14px] font-semibold w-max border-[1px] rounded-[5px] px-[4px] py-[2px]">{product?.box ? "With Box" : "Without Box"}</p>
                                              <p
                                                onClick={(event) => {this.handleOpenNoteModal(event, product)}}
                                                className={Classnames(`text-[14px] font-semibold w-max border-[1px] rounded-[5px] p-[2px]`, {
                                                  'border-[#E0E0E0] text-[#E0E0E0]': !product?.note,
                                                })}
                                              >
                                                Notes
                                              </p>
                                            </div>
																					</div>
																				</td>
																				<td className="py-6 text-sm text-[#5D6E81] font-medium tracking-worderer">{product.sku}</td>
																				<td className="py-6 text-sm font-normal tracking-worderer">
																					<div className="flex flex-col ml-[16px]">
																						<input
																							onChange={this.handleOrderItemPriceChange.bind(this, product)}
																							type="text"
																							className="border px-2 py-1 rounded text-[#5D6E81] font-medium"
																							value={Globals.formatCurrency(product.wholesale_price, true)}
																							placeholder="Item Price"
																							style={{
																								maxWidth: 100,
																							}}
																						/>
																						{state.errors.map(value => {
																							return (
																								value.price.index === index &&
																									<span className='text-red-700'>{value.price.error}</span>
																							)

																						})}
																					</div>
																				</td>
																				<td lassName="py-6 px-[15px] tracking-worderer" align="center">
																					{!product.inBag && (
																						<button
																							onClick={this.handleAddingItemToOrder.bind(this, product)}
																							className="inline-flex text-xs bg-green-100 text-green-700 border border-green-100 hover:bg-green-200 hover:border-green-200 transition rounded items-center pl-1 pr-2 py-0.5"
																						>
																							<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																								<path
																									fillRule="evenodd"
																									d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
																									clipRule="evenodd"
																								/>
																							</svg>
																							<span>Add</span>
																						</button>
																					)}
																					{product.inBag && (
																						<button
																							onClick={this.handleRemovingItemToOrder.bind(this, product)}
																							className="flex flex-col text-xs rounded items-center pl-1 pr-2 py-0.5"
																						>
																							<img src="/Images/remove_icon.png" alt="remove icon" className="w-[22px] h-[22px] cursor-pointer" />
																							<span className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
																						</button>
																					)}
																				</td>
																			</tr>
																		)
																	})}
																	{state.miscItems.map((product, index) => {
																		return (
																			<tr key={index} className={Classnames('cursor-pointer hover:bg-gray-100 bg-white !border-b text-[#5D6E81] font-medium')}>
																				<td className="py-6 px-[15px] text-sm font-normal tracking-worderer w-7/12">
																					<p className="text-[#5D6E81] font-medium mb-[8px]">{product.product_name}</p>
																				</td>
																				<td className="py-6 text-sm text-[#5D6E81] font-medium tracking-worderer">{product.sku}</td>
																				<td className="py-6 text-sm font-normal tracking-worderer">
																					<div className="ml-[16px]">
																						<input
																							onChange={e => this.handleMemoMiscPriceChange(e, index)}
																							type="text"
																							className="border px-2 py-1 rounded text-[#5D6E81] font-medium"
																							value={Globals.formatCurrency(product.price, true)}
																							placeholder="Item Price"
																							style={{
																								maxWidth: 100,
																							}}
																						/>
																					</div>
																				</td>
																				<td className="py-6 px-[15px] tracking-worderer" align="center">
																					<button
																						onClick={() => this.handleRemovingMiscItemMemo(index)}
																						className="flex flex-col text-xs rounded items-center pl-1 pr-2 py-0.5"
																						>
																							<img src="/Images/remove_icon.png" alt="remove icon" className="w-[22px] h-[22px] cursor-pointer" />
																							<span className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
																					</button>
																				</td>
																			</tr>
																		)
																	})}
																	{!isEmpty(this.state.tradeInItems) &&
																		this.state.tradeInItems?.map((item, index) => {
																			return (
																				<tr key={index} className={Classnames('cursor-pointer hover:bg-gray-100 bg-white border-b text-[#5D6E81] font-medium')}>
																					<td className="py-6 px-[15px] text-sm font-normal tracking-worderer w-7/12">
																						<p className="text-[#5D6E81] font-medium mb-[8px]">{item.product_name}</p>
																					</td>
																					<td className="py-6 text-sm text-[#5D6E81] font-medium tracking-worderer">{item.sku}</td>
																					<td className="py-6 text-sm font-normal tracking-worderer">
																						<span className="mr-2">-</span>
																						<input
																							onChange={e => this.handleTradeInPriceChange(index, e.target.value)}
																							type="text"
																							className="border px-2 py-1 rounded text-[#5D6E81] font-medium"
																							value={Globals.formatCurrency(item.price, true)}
																							placeholder="Price"
																							style={{
																								maxWidth: 100,
																							}}
																						/>
																					</td>
																					<td className="py-6 px-[15px] tracking-worderer" align="center">
																						<button
																							onClick={() => this.handleRemovetradeIn(index)}
																							className="flex flex-col text-xs rounded items-center pl-1 pr-2 py-0.5"
																						>
																							<img src="/Images/remove_icon.png" alt="remove icon" className="w-[22px] h-[22px] cursor-pointer" />
																							<span className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
																						</button>
																					</td>
																				</tr>
																			)
																		})}
																</tbody>
															</table>

															{(!isEmpty(state.orderedItems) || !isEmpty(state.miscItems)) && (
																<div className="flex justify-between items-start w-full pt-2">
																	<div className="w-1/4 text-[13px] font-normal px-[35px] tracking-worderer">
																		Total Products: {state.orderedItems.length + state.tradeInItems.length + state.miscItems.length}
																	</div>
																	<div className="w-[400px] pr-[30px] text-sm font-semibold tracking-worderer">
																		<br />
																		<div className="flex justify-between items-center">
																			<div className="text-black text-base font-normal">Subtotal</div>
																			<div className="text-black text-base font-normal">
																				{Globals.readCurrencyNumber(
																						sum(state.orderedItems.map(t => (t.wholesale_price ? Globals.revertPrice(t.wholesale_price) : 0))) +
																							sum(state.miscItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0))) -
																							sum(state.tradeInItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0)))
																					, true)
																				}
																			</div>
																		</div>
																		<div className="flex justify-between items-center pb-[4px]">
																			<div className="text-black text-base font-normal">Shipping</div>
																			<div className="text-black text-base font-normal">
																				<div className="text-sm font-normal flex">
																					<div
																						onClick={e => this.toggleShipping(e)}
																						className={Classnames(
																							'text-center w-[120px] h-[23px] cursor-pointer flex items-center justify-center rounded-[5px] border-[1px] border-[#5d6e8133]',
																							{
																								'text-[#4B7CBE] bg-[#5D6E81] bg-opacity-20': this.state.isCustomerLabeled,
																								'text-[#4B7CBE] bg-white': !this.state.isCustomerLabeled,
																							}
																						)}
																					>
																						Customer Label
																					</div>
																					{!this.state.isCustomerLabeled ? (
																						<input
																							onChange={$event => {
																								this.setState({
																									shippingPrice: Globals.formatCurrency($event.target.value),
																								})
																							}}
																							className="border h-[23px] w-[51px] rounded-[5px] ml-[7px] text-right"
																							readOnly={this.state.isCustomerLabeled}
																							disabled={this.state.isCustomerLabeled}
																							value={this.state.isCustomerLabeled ? '' : state.shippingPrice}
																						/>
																					) : (
																						<></>
																					)}
																				</div>
																			</div>
																		</div>
																		{state.addSaleTax &&
																			<div className="flex justify-between items-center pb-[4px]">
																				<div className="text-black text-base font-normal">Tax</div>
																				<div className="text-black text-base font-normal">
																					<div className="text-sm font-normal flex">
																						{this.state.isTaxExempt ? (
																							<div
																								onClick={this.handleChangeTaxExempt}
																								className="border h-[23px] pl-[13px] pr-[14px] rounded-[5px] ml-[7px] text-right cursor-pointer text-[#DFE2E6]"
																							>
																								Exempt
																							</div>
																						) : (
																							<div
																								onClick={this.handleChangeTaxExempt}
																								className="border h-[23px] min-w-[51px] pl-[13px] pr-1 rounded-[5px] ml-[7px] text-right cursor-pointer"
																							>
																								{Globals.readCurrencyNumber(this.handleCalculateTax(), true)}
																							</div>
																						)}
																					</div>
																				</div>
																			</div>
																		}
																		<hr className="m-0 border-black w-4/12 float-right" />
																		<div className="flex justify-between items-center w-full pt-[4px]">
																			<div className="text-black text-base font-normal">Total</div>
																			<div className="text-black text-base font-normal">
																				{Globals.readCurrencyNumber(this.calculateTotalIncludeTax(), true)}
																			</div>
																		</div>
																	</div>
																</div>
															)}
															{isEmpty(state.orderedItems) && isEmpty(state.tradeInItems) && <div className="text-xs py-4">No Item(s) Invoiced</div>}
														</div>

														<div className="mt-[48px] bg-[#F4F4F4]">
															<div className="px-[39px] py-5">
																<div className="text-[#5D6E81] text-[22px] font-bold">Customer Details</div>
																<div className="w-full flex justify-between mt-[15px]">
																	<div className="justify-between">
																		<div className="flex items-center gap-x-[13px] mb-[30px]">
																			<img src="./Images/company.png" alt="" className="h-[24px] w-[24px]" />
																			<span>{state.selectedCustomer?.company_name}</span>
																		</div>
																		<div className="flex items-center gap-x-[13px] mb-[30px]">
																			<img src="./Images/phone.png" alt="" className="h-[24px] w-[24px]" />
																			<div className="flex flex-col">
																				{
																					state.selectedCustomer?.customer_phone_numbers[0] && (
																						<div>
																							<span>{state.selectedCustomer?.customer_phone_numbers[0].phone_number_formatted}</span>
																						</div>
																					)
																				}
																			</div>
																		</div>
																		<div className="flex items-center gap-x-[13px]">
																			<img src="./Images/mail.png" alt="" className="h-[24px] w-[24px]" />
																			<span>{state.selectedCustomer?.email}</span>
																		</div>
																	</div>
																	<div className="flex flex-col">
																		<div className="flex items-center gap-x-[13px] mb-[11px]">
																			<img src="./Images/people.png" alt="" className="h-[24px] w-[24px]" />
																			<span className="text-[#5D6E81] text-[14px] font-semibold">Billing</span>
																			<AddressDropdown
																				addressOptions={state.selectedCustomer.customer_addresses}
																				addressType="billing"
																				selectedAddress={state.selectedBillingAddress}
																				onSelect={this.handleSelectAddress}
																				onAddNew={this.handleAddNewAddress}
																			></AddressDropdown>
																		</div>
																		<div className="ms-[43px] max-w-[235px]">
																			{state.newBillingAddress.visible ? (
																				<React.Fragment>
																					<input
																						type="text"
																						className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																						placeholder="Company Name"
																						value={state.newBillingAddress.company_name}
																						onChange={e => this.handleNewAddressChange('newBillingAddress', 'company_name', e.target.value)}
																					/>
																					<input
																						type="text"
																						className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																						placeholder="Contact Name"
																						value={state.newBillingAddress.contact_name}
																						onChange={e => this.handleNewAddressChange('newBillingAddress', 'contact_name', e.target.value)}
																					/>
																					{!this.state.isManualBillingAddress ?
																						<>
																							<div>
																								<div className='flex w-[235px] gap-1'>
																									<ServerAutoSuggestAddress
																										formData={{full_address: state.newBillingAddress.full_address}}
																										handleChangeGoogleAddress={this.handleChangeBillingAddress}
																										className="border rounded-[5px] w-full h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										view={'memo'}
																									/>
																									<input
																										type="text"
																										name='apt_unit_number'
																										className="border rounded-[5px] w-[38%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										placeholder="Apt/Unit #"
																										value={state.newBillingAddress.apt_unit_number}
																										onChange={e => this.handleNewAddressChange('newBillingAddress', 'apt_unit_number', e.target.value)}
																									/>
																								</div>
																								<span
																									onClick={this.handleVisibleManualBillingAddress}
																									className="text-xs mt-[1px] cursor-pointer underline"
																								>
																									Add Manually
																								</span>
																							</div>
																						</>
																						:
																						<>
																							<div>
																								<div className='flex w-[235px] gap-1'>
																									<input
																										type="text"
																										className="border rounded-[5px] w-[70%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										placeholder="Street"
																										name='street'
																										value={state.newBillingAddress.street}
																										onChange={e => this.handleNewAddressChange('newBillingAddress', 'street', e.target.value)}
																									/>
																									<input
																										type="text"
																										name='apt_unit_number'
																										className="border rounded-[5px] w-[30%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										placeholder="Apt/Unit #"
																										value={state.newBillingAddress.apt_unit_number}
																										onChange={e => this.handleNewAddressChange('newBillingAddress', 'apt_unit_number', e.target.value)}
																									/>
																								</div>
																								<span
																									onClick={this.handleVisibleManualBillingAddress}
																									className='text-xs mt-[1px] cursor-pointer underline'
																								>
																									Use Google autocomplete
																								</span>
																							</div>
																							<div className='flex w-[235px] gap-1'>
																								<input
																									type="text"
																									name='city'
																									className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																									placeholder="City"
																									value={state.newBillingAddress.city}
																									onChange={e => this.handleNewAddressChange('newBillingAddress', 'city', e.target.value)}
																								/>
																								<input
																									type="text"
																									name='state'
																									className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																									placeholder="State"
																									value={state.newBillingAddress.state}
																									onChange={e => this.handleNewAddressChange('newBillingAddress', 'state', e.target.value)}
																								/>
																							</div>
																							<div className='flex w-[235px] gap-1'>
																								<input
																									type="text"
																									name='zip_code'
																									className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																									placeholder="Zip Code"
																									value={state.newBillingAddress.zip_code}
																									onChange={e => this.handleNewAddressChange('newBillingAddress', 'zip_code', e.target.value)}
																								/>
																								<div className="flex items-center border rounded-[5px] w-1/2 h-[30px] text-[12px]">
																									<select
																										id="country"
																										name="country"
																										className="w-full"
																										defaultValue={state.newBillingAddress?.country || ''}
																										onChange={e => this.handleNewAddressChange('newBillingAddress', 'country', e.target.value)}
																										>
																										<option value="" disabled>
																											Select Country
																										</option>
																										{Globals.countries.map(c => (
																											<option key={c.code} value={c.code}>
																												{c.name}
																											</option>
																										))}
																									</select>
																								</div>
																							</div>

																						</>
																					}
																					<input
																						type="text"
																						className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																						placeholder="Phone Number"
																						value={state.newBillingAddress.phone_number_formatted}
																						onChange={e => this.handleNewAddressChange('newBillingAddress', 'phone_number', e.target.value)}
																					/>
																					<div className="flex items-center">
																						<label htmlFor="save_to_contact_billing">
																							<input
																								type="checkbox"
																								id="save_to_contact_billing"
																								className="hidden peer"
																								checked={state.newBillingAddress.save_to_contact}
																								onChange={e => this.handleNewAddressChange('newBillingAddress', 'save_to_contact', e.target.checked)}
																							/>
																							<div className="flex items-center text-center">
																								<span
																									htmlFor="save_to_contact_billing"
																									className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300"
																								>
																									{state.newBillingAddress.save_to_contact && (
																										<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
																											<path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0" />
																										</svg>
																									)}
																								</span>
																								<span className="mt-1 text-[12px] text-[#5D6E81] font-normal">Save Address to Contact</span>
																							</div>
																						</label>
																					</div>
																				</React.Fragment>
																			) : (
																				<React.Fragment>
																					<p>{state.selectedBillingAddress?.company_name}</p>
																					<p>{state.selectedBillingAddress?.contact_name}</p>
																					<p>{state.selectedBillingAddress?.full_address}</p>
																					<p>{state.selectedBillingAddress?.phone_number_formatted}</p>
																				</React.Fragment>
																			)}
																		</div>
																	</div>
																	<div className="flex flex-col">
																		<div className="flex items-center gap-x-[13px] mb-[11px]">
																			<img src="./Images/location_detail.png" alt="" className="h-[24px] w-[24px]" />
																			<span className="text-[#5D6E81] text-[14px] font-semibold">Shipping</span>
																			{/* Customer form goes here */}
																			<AddressDropdown
																				addressOptions={state.selectedCustomer.customer_addresses}
																				addressType="shipping"
																				selectedAddress={state.selectedShippingAddress}
																				onSelect={this.handleSelectAddress}
																				onAddNew={this.handleAddNewAddress}
																			></AddressDropdown>
																		</div>
																		<div className="ms-[43px] max-w-[235px]">
																			{state.newShippingAddress.visible ? (
																				<React.Fragment>
																					<input
																						type="text"
																						className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																						placeholder="Company Name"
																						value={state.newShippingAddress.company_name}
																						onChange={e => this.handleNewAddressChange('newShippingAddress', 'company_name', e.target.value)}
																					/>
																					<input
																						type="text"
																						className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																						placeholder="Contact Name"
																						value={state.newShippingAddress.contact_name}
																						onChange={e => this.handleNewAddressChange('newShippingAddress', 'contact_name', e.target.value)}
																					/>
																						{
																						!this.state.isManualShippingAddress ?
																						<>
																							<div>
																								<div className='flex w-[235px] gap-1'>
																									<ServerAutoSuggestAddress
																										formData={{full_address: state.newShippingAddress.full_address}}
																										handleChangeGoogleAddress={this.handleChangeShippingAddress}
																										className="border rounded-[5px] w-full h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										view={'memo'}
																									/>
																									<input
																										type="text"
																										name='apt_unit_number'
																										className="border rounded-[5px] w-[38%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										placeholder="Apt/Unit #"
																										value={state.newShippingAddress.apt_unit_number}
																										onChange={e => this.handleChangeShippingAddress('newBillingAddress', 'apt_unit_number', e.target.value)}
																									/>
																								</div>
																								<span
																									onClick={this.handleVisibleManualShippingAddress}
																									className="text-xs mt-[1px] cursor-pointer underline"
																								>
																									Add Manually
																								</span>
																							</div>
																						</>
																						:
																						<>
																							<div>
																								<div className='flex w-[235px] gap-1'>
																									<input
																										type="text"
																										className="border rounded-[5px] w-[70%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										placeholder="Street"
																										name='street'
																										value={state.newShippingAddress.street}
																										onChange={e => this.handleNewAddressChange('newShippingAddress', 'street', e.target.value)}
																									/>
																									<input
																										type="text"
																										name='apt_unit_number'
																										className="border rounded-[5px] w-[30%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										placeholder="Apt/Unit #"
																										value={state.newShippingAddress.apt_unit_number}
																										onChange={e => this.handleNewAddressChange('newShippingAddress', 'apt_unit_number', e.target.value)}
																									/>
																								</div>
																								<span
																									onClick={this.handleVisibleManualShippingAddress}
																									className='text-xs mt-[1px] cursor-pointer underline'
																								>
																									Use Google autocomplete
																								</span>
																							</div>
																							<div className='flex w-[235px] gap-1'>
																								<input
																									type="text"
																									name='city'
																									className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																									placeholder="City"
																									value={state.newShippingAddress.city}
																									onChange={e => this.handleNewAddressChange('newShippingAddress', 'city', e.target.value)}
																								/>
																								<input
																									type="text"
																									name='state'
																									className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																									placeholder="State"
																									value={state.newShippingAddress.state}
																									onChange={e => this.handleNewAddressChange('newShippingAddress', 'state', e.target.value)}
																								/>
																							</div>
																							<div className='flex w-[235px] gap-1'>
																								<input
																									type="text"
																									name='zip_code'
																									className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																									placeholder="Zip Code"
																									value={state.newShippingAddress.zip_code}
																									onChange={e => this.handleNewAddressChange('newShippingAddress', 'zip_code', e.target.value)}
																								/>
																								<div className="flex items-center border rounded-[5px] w-1/2 h-[30px] text-[12px]">
																									<select
																										id="country"
																										name="country"
																										className="w-full"
																										defaultValue={state.newShippingAddress?.country || ''}
																										onChange={e => this.handleNewAddressChange('newShippingAddress', 'country', e.target.value)}
																										>
																										<option value="" disabled>
																											Select Country
																										</option>
																										{Globals.countries.map(c => (
																											<option key={c.code} value={c.code}>
																												{c.name}
																											</option>
																										))}
																									</select>
																								</div>
																							</div>

																						</>
																					}
																					<input
																						type="text"
																						className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																						placeholder="Phone Number"
																						value={state.newShippingAddress.phone_number_formatted}
																						onChange={e => this.handleNewAddressChange('newShippingAddress', 'phone_number', e.target.value)}
																					/>
																					<div className="flex items-center">
																						<label htmlFor="save_to_contact_shipping">
																							<input
																								type="checkbox"
																								id="save_to_contact_shipping"
																								className="hidden peer"
																								defaultChecked={state.newShippingAddress.save_to_contact}
																								onChange={e => this.handleNewAddressChange('newShippingAddress', 'save_to_contact', e.target.checked)}
																							/>
																							<div className="flex items-center text-center">
																								<span
																									htmlFor="save_to_contact_shipping"
																									className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300"
																								>
																									{state.newShippingAddress.save_to_contact && (
																										<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
																											<path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0" />
																										</svg>
																									)}
																								</span>
																								<span className="mt-1 text-[12px] text-[#5D6E81] font-normal">Save Address to Contact</span>
																							</div>
																						</label>
																					</div>
																				</React.Fragment>
																			) : (
																				<React.Fragment>
																					<p>{state.selectedShippingAddress?.company_name}</p>
																					<p>{state.selectedShippingAddress?.contact_name}</p>
																					<p>{state.selectedShippingAddress?.full_address}</p>
																					<p>{state.selectedShippingAddress?.phone_number_formatted}</p>
																				</React.Fragment>
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="px-[39px] w-full flex mt-[21px] min-h-[153px] relative">
															<div className="w-1/2">
																<div className="text-[22px] text-[#5D6E81] font-bold">Order Details</div>
																<div className="flex flex-col pl-[22px] pr-[37px] pt-[20px] gap-y-[13px]">
																	<div className="flex justify-between">
																		<div>Order Date</div>
																		<div>{Moment().format('MM/DD/YYYY')}</div>
																	</div>
																	<div className="flex justify-between">
																		<div>Terms</div>
																		<TermsDropdown
																			termOptions={Globals.saleTerms}
																			selectedTerm={state.selectedTerm}
																			onSelect={this.handleSelectTerms}
																			termCustomField={state.termCustomField}
																			handleChangeTermCustomField={this.handleChangeTermCustomField}
																			termCustomSelect={state.termCustomSelect}
																			handleSelectCustomTerm={this.handleSelectCustomTerm}
																		/>
																	</div>

																	{

																		this.props.currentSpace.space_general_setting?.visible_salesperson_on_sales &&
																		<div className="flex justify-between">
																			<div>Salesperson</div>
																			<UsersDropdown
																				userOptions={state.loadedSpaceUsers}
																				selectedUser={state.selectedSalesPerson}
																				onSelect={this.handleSelectSalesPerson}
																				className={"bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] text-center w-[184px] truncate"}
																			/>
																		</div>
																	}
																	<div className="flex justify-between">
																		<div>Tracking Number</div>
																		<input
																			name="tracking_number"
																			value={state.trackingNumber}
																			ref={this.trackingNumberInputRef}
																			onKeyDown={this.handleKeyDownTrackingNumber}
																			onChange={this.handleChangeTrackingNumber}
																			className='bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81]
																			w-[184px] text-end px-1'
																		/>
																	</div>
																</div>
															</div>
															<hr className="w-[1px] h-[153px] bg-[#0000004D] mt-10 mx-[20px]" />
															<div className="w-1/2 pl-[22px]">
																<div className="text-[22px] text-[#5D6E81] font-bold flex items-center gap-x-[37px]">
																	<span>Comments</span>
																	<img
																		src="./Images/plus_radius.png"
																		alt=""
																		className="w-[22px] h-[22px]"
																		onClick={() => this.setState({isVisibleComments: !state.isVisibleComments})}
																	/>
																</div>
																<div className="flex flex-col">
																	{state.isVisibleComments && (
                                    <>
                                      <textarea
                                        className="border rounded-[5px] w-[100%] h-[100px] px-[10px] py-[10px] mt-[20px]"
                                        placeholder="Add a comment"
                                        name='content'
                                        value={state.orderComment.content}
                                        onChange={this.handleCommentChange}
                                      />
                                      <div className='flex justify-between items-center'>
                                        <div className='items-center flex h-full mt-2'>
                                          <span className="__checkbox h-[22px]" style={{transform: 'scale(.8)'}}>
                                            <input
                                              id={`checkbox-visible-on-pdf`}
                                              type="checkbox"
                                              name="visible_on_pdf"
                                              onChange={this.handleCommentChange}
                                              checked={state.orderComment.visible_on_pdf}
                                            />
                                            <Tooltip title="Visible on Invoice" placement="top" arrow>
                                              <label htmlFor={`checkbox-visible-on-pdf`}></label>
                                            </Tooltip>
                                          </span>
                                          <label htmlFor={`checkbox-visible-on-pdf`} className='text-[12px] ml-[5px] cursor-pointer'>Visible on Invoice</label>
                                        </div>
                                      </div>
                                    </>
																	)}
																</div>
															</div>
														</div>

														<div className="mt-6 flex gap-4 items-center justify-center px-10 mx-auto gap-x-[16px]">
															<button
																className="transition w-[84px] h-[38px] rounded-[5px] border border-[#5d6e8147] text-[#5D6E81] font-semibold text-[12px] hover:bg-gray-300"
																onClick={this.handleCloseAddSale}
															>
																Cancel
															</button>
															<button
																className="w-[66px] h-[38px] transition rounded-[5px] bg-[#2863EB] text-white text-[12px] hover:bg-blue-800"
																onClick={(e) => {
																	if(this.props.currentSpace.quickbook_integration?.invoice_sync_type === 'individually')	{
																		this.setIsVisibleAllowSyncQuickbook(true)
																	} else {
																		this.handleSubmitOrder(e)
																	}
																}}
															>
																Save
															</button>
														</div>
														{/*
														<div className="grid grid-cols-2 gap-6 mt-6">
															<div>
																<div className="flex justify-between items-center border-b pb-5">
																	<div>
																		<h3 className="font-medium text-md mt-6">Order Totals</h3>
																	</div>
																</div>
																{(!isEmpty(state.orderedItems) || !isEmpty(state.miscItems)) && (
																	<div className="py-3">
																		<table className="w-full font-semibold">
																			<tbody>
																				<tr>
																					<td className="py-1">Subtotal</td>
																					<td className="py-1 text-right">
																						{Globals.moneyFormatter
																							.format(
																								sum(state.orderedItems.map(t => (t.wholesale_price ? parseFloat(t.wholesale_price) : 0))) +
																									sum(state.miscItems.map(t => (t.price ? parseFloat(t.price) : 0))) -
																									sum(state.tradeInItems.map(t => parseFloat(t.price)))
																							)
																							.replace('.00', '')
																							.replace('$', '')}
																					</td>
																				</tr>
																				<tr>
																					<td className="py-1">Shipping</td>
																					<td className="flex justify-end items-center py-1 text-right">
																						<div className="text-sm font-normal">
																							<input
																								onChange={$event => {
																									this.setState({
																										shippingPrice: !isNaN(parseFloat($event.target.value)) ? parseFloat($event.target.value) : 0,
																									})
																								}}
																								type="number"
																								className="border px-2 py-1 rounded"
																								readOnly={this.state.isCustomerLabeled}
																								disabled={this.state.isCustomerLabeled}
																								value={this.state.isCustomerLabeled ? '' : state.shippingPrice ? state.shippingPrice : ''}
																								placeholder={this.state.isCustomerLabeled ? 'Label Provided' : 'Shipping Price'}
																							/>
																						</div>
																						<div
																							style={{
																								width: '50% !important',
																								marginLeft: '5px',
																							}}
																						>
																							<input
																								onChange={(e) => this.toggleShipping(e)}
																								onSelect={(e) => this.toggleShipping(e)}
																								style={{
																									opacity: '1',
																									width: '15px',
																								}}
																								type="checkbox"
																								defaultChecked={this.state.isCustomerLabeled}
																							/>
																							<span
																								htmlFor="no"
																								className="pl-5 truncate"
																								style={{
																									width: '30px !important',
																								}}
																							>
																								Customer Label
																							</span>
																						</div>
																					</td>
																				</tr>
																				<tr>
																					<td className="py-1">Total</td>
																					<td className="py-1 text-right">
																						{Globals.moneyFormatter
																							.format(
																								sum(state.orderedItems.map(t => (t.wholesale_price ? parseFloat(t.wholesale_price) : 0))) -
																									sum(state.tradeInItems.map(t => parseFloat(t.price))) +
																									sum(state.miscItems.map(t => (t.price ? parseFloat(t.price) : 0))) +
																									(state.isCustomerLabeled ? 0 : parseFloat(state.shippingPrice))
																							)
																							.replace('.00', '')
																							.replace('$', '')}
																					</td>
																				</tr>
																			</tbody>
																		</table>
																	</div>
																)}
														</div>
														<div className="mt-6 flex gap-4 items-center justify-end">
															<div>
																<button
																	className="hover:bg-red-200 transition rounded bg-red-100 text-red-700 px-5 py-2 text-md"
																	onClick={() => {
																		this.setState({
																			step: 1,
																			isVisibleAddSale: false,
																			loadedCustomers: null,
																			originalLoadedCustomers: null,
																			selectedCustomer: null,
																			orderedItems: [],
																			miscItems: [],
																			loadedProducts: [],
																			tradeInItems: [],
																		})
																	}}
																>
																	Cancel
																</button>
															</div>
															<div>
																<button className="hover:bg-blue-400 transition rounded bg-blue-500 text-white px-5 py-2 text-md" onClick={this.handleSubmitOrder}>
																	Submit order
																</button>
															</div>
														</div> */}
													</div>
												</React.Fragment>
											)}
										</div>
									</div>
								</div>
								<CustomerList
									pickCustomer={this.pickCustomer}
									selectedCustomer={state.selectedCustomer}
									handleCloseCustomerList={this.handleCloseCustomerList}
									addSaleTax={state.addSaleTax}
									isTaxExempt={state.isTaxExempt}
									taxRate={state.taxRate}
									setIsTaxExempt={this.setIsTaxExempt}
									handleSearchCustomer={this.handleSearchCustomer}
									loadedCustomers={state.loadedCustomers}
									originalLoadedCustomers={state.originalLoadedCustomers}
									loadingCustomers={state.loadingCustomers}
									props={this.props}
									handleLoadCustomers={this.handleLoadCustomers}
									loadInventory={this.loadInventory}
									loadedProducts={state.loadedProducts}
									handleAddingItemToOrder={this.handleAddingItemToOrder}
									handleRemovingItemToOrder={this.handleRemovingItemToOrder}
									orderedItems={state.orderedItems}
									handleItemPriceChange={this.handleItemPriceChange}
									updateTradeIn={this.updateTradeIn}
									handleMiscItemList={this.handleMiscItemList}
									tradeInItems={state.tradeInItems}
									miscItems={state.miscItems}
									handleOrderItemPriceChange={this.handleOrderItemPriceChange}
									handleSearchProducts={this.handleSearchProducts}
									handleRemovingMiscItemMemo={this.handleRemovingMiscItemMemo}
									handleRemovetradeIn={this.handleRemovetradeIn}
									handleMemoMiscPriceChange={this.handleMemoMiscPriceChange}
									handleTradeInPriceChange={this.handleTradeInPriceChange}
									isCustomerLabeled={state.isCustomerLabeled}
									shippingPrice={state.shippingPrice}
									handleChangeShippingPrice={this.handleChangeShippingPrice}
									handleSubmitOrder={this.handleSubmitOrder}
									handleCloseAddSale={this.handleCloseAddSale}
									handleChangeCustomerLabel={this.handleChangeCustomerLabel}
									handleChangeTaxExempt={this.handleChangeTaxExempt}
									handleCalculateTax={this.handleCalculateTax}
								/>
							</>
						)}
							{props.isOpenDetailsInventory && (
								<DrawnWatchDetail
									data={state.watchItem}
									refreshListAfterProductEdit={this.refreshListAfterProductEdit}
								/>
						)}

						{
							this.props.currentSpace.quickbook_integration?.invoice_sync_type === 'individually' && this.state.isVisibleAllowSyncQuickbook &&
							<Dialog
								open={open}
								onKeyUp={e => {
									if (e.key === 'Escape') this.setIsVisibleAllowSyncQuickbook(false)
								}}
							>
								<div className="confirmation-dialog-box-inner p-8">
									<div className='flex justify-end items-end'>
										<button
											onClick={() => {
												this.setIsVisibleAllowSyncQuickbook(false)
											}}
										>
											<svg className="w-4 h-4" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
											</svg>
										</button>
									</div>
									<div className="confirmation-dialog-box-head">
										{<h2 className="font-bold text-xl mb-2 text-black">Sync To Quickbook</h2>}
									</div>
									<div className="confirmation-dialog-box-content mb-8 text-slate-500">
										<p>Do you want to sync this sale to Quickbooks?</p>
									</div>
									<div className="confirmation-dialog-box-footer mt-5 grid grid-cols-2 gap-2">
										<button className="transition-all hover:bg-red-300 bg-red-200 font-semibold md:py-2.5 md:px-4 rounded text-red-700 text-sm uppercase" autoFocus w-full onClick={(e) => {
											this.setState({ isVisibleAllowSyncQuickbook: false }, () => this.handleSubmitOrder(e))
											}}>
											No
										</button>
										<button className="transition-all hover:bg-gray-300  bg-gray-200 font-semibold md:py-2.5 md:px-4 rounded text-gray-700 text-sm uppercase w-full" onClick={this.handleAllowSyncQuickbook}>
											Yes
										</button>
									</div>
								</div>
							</Dialog>
						}

						{state.isVisibleMiscProducts && (
							<AddNewMiscProduct hideAddNewMiscProduct={this.hideAddNewMiscProduct} handleMiscItemList={this.handleMiscItemList} props={this.props} />
						)}

						{state.customerFormVisible && (
							<AddNewCustomer
								handleCloseForm={this.handleCloseCustomerForm}
								props={this.props}
								handleLoadCustomers={() => this.handleLoadCustomers}
								handlePrimaryTab={this.handlePrimaryTab}
							/>
						)}
						{state.isVisibleProductsHunter && (
							<SalesProductsHunter
								loadedProducts={state.loadedProducts}
								handleCloseProductsHunter={this.handleCloseProductsHunter}
								handleAddingItemToOrder={this.handleAddingItemToOrder}
								handleRemovingItemToOrder={this.handleRemovingItemToOrder}
								orderedItems={this.state.orderedItems}
								handleSearchProducts={this.handleSearchProducts}
								handleItemPriceChange={this.handleItemPriceChange}
								props={this.props}
								handleUpdateItemProduct={this.handleUpdateItemProduct}
								loadingProduct={this.state.loadingProduct}
							/>
						)}
						{state.isVisibleSingleOrder && (
							<SingleItemDetail
								props={this.props}
								handleLoadOrders={this.handleLoadOrders}
								orderId={this.state.singleOrderItemId}
								updateOrderStatus={this.updateOrderStatus}
								ordersList={this.state.ordersList}
								closeSingleOrderItem={this.closeSingleOrderItem}
								handleClickOut={this.handleClickOut}
								handleOpenPerformanceLimitAccessModal={this.handleOpenPerformanceLimitAccessModal}
								updateItemInSales={this.updateItemInSales}
								selectedCustomer={this.state.selectedCustomer}
								currentSpace={this.props.currentSpace}
								profile={this.props.profile}
								handlePrimaryTab={this.handlePrimaryTab}
							/>
						)}
            {state.openNoteModal && (
              <AddNoteForLineItem
                noteValue={state.saleItemSelected?.note}
                handleChangeNote={this.setNoteData}
                handleClose={this.handleCloseNoteModal}
                isEdit={!isEmpty(state.saleItemSelected?.note) ? true : false}
                addToast={this.props.addToast}
              />
            )}
					</React.Fragment>
				)}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		isOpenSideBar: state.isOpenSideBar,
		loggedin: state.loggedin,
		currentSpace: state.currentSpace,
		isOpenDetailsInventory: state.isOpenDetailsInventory,
	}
}

const mapActionsToProps = {
	addToast,
	toggleSidebar,
	toggleDetailsCardInventory
}

export default connect(mapStateToProps, mapActionsToProps)(withTheme(SalesView))