export const MoneyBagSvg = ({color}) => {
  return (
    <svg className="mr-2" version="1.1" viewBox="0 0 682.66669 682.66669" width="18" height="21" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
        <g>
          <g>
            <path
              d="M 211,354 h -33.334 c -13.255,0 -24,10.745 -24,24 v 0 c 0,13.255 10.745,24 24,24 h 156.667 c 13.255,0 24,-10.745 24,-24 v 0 c 0,-13.255 -10.745,-24 -24,-24 H 89.999"
              fill="none"
              stroke={color}
              strokeWidth="20"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g transform="translate(334.3333,354)">
            <path
              d="M 0,0 83.774,-131.326 C 142.501,-223.387 76.377,-344 -32.82,-344 h -91.026 c -109.198,0 -175.322,120.613 -116.595,212.674 L -156.667,0"
              fill="none"
              stroke={color}
              strokeWidth="20"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g transform="translate(151.5245,493.2841)">
            <path
              d="m 0,0 c 11.524,11.524 30.208,11.524 41.731,0 l 0.074,-0.073 C 53.3,-11.568 71.927,-11.601 83.463,-0.146 l 0.294,0.292 C 95.292,11.601 113.92,11.568 125.415,0.073 L 125.488,0 c 11.524,-11.524 30.208,-11.524 41.732,0 v 0 c 11.523,11.524 30.207,11.524 41.731,0 l -26.11,-91.284 H 26.175 Z"
              fill="none"
              stroke={color}
              strokeWidth="20"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g transform="translate(283.4928,236.1173)">
            <path
              d="m 0,0 c 0,0 -18.065,13.686 -39.766,7.167 -22.151,-6.655 -27.646,-36.973 -12.597,-48.777 8.642,-6.778 21.644,-12.35 39.168,-18.545 39.22,-13.863 24.721,-69.174 -15.174,-69.44 -15.569,-0.103 -22.847,0.885 -36.524,9.832"
              fill="none"
              stroke={color}
              strokeWidth="20"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g transform="translate(256,264)">
            <path d="M 0,0 V -177.667" fill="none" stroke={color} strokeWidth="20" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </g>
      </g>
    </svg>
  )
}
