import General from './General'
import SensitiveInformation from './SensitiveInformation'
import PaymentReminder from './PaymentReminders'
import Notifications from './Notifications'
import Invoice from './Invoice'
import WatchDetailSetting from './WatchDetailSetting'
import QRCode from './QRCode'

export const settingsOptions = [
	{
		key: 'general',
		label: 'General',
		managerRestricted: true,
	},
	{
		key: 'qrCode',
		label: 'QR Code',
		managerRestricted: true,
	},
	{
		key: 'paymentReminders',
		label: 'Payment Reminders',
	},
	{
		key: 'notifications',
		label: 'Notifications',
	},
	{
		key: 'invoice',
		label: 'Invoice',
		adminOrOwner: true,
	},
	{
		key: 'watchDetailSetting',
		label: 'Watch Detail Setting',
		owner: true,
	},
	{
		key: 'sensitiveInformation',
		label: 'Sensitive Information',
	},
]
export const keyToContent = {
	general: General,
	sensitiveInformation: SensitiveInformation,
	paymentReminders: PaymentReminder,
	notifications: Notifications,
	invoice: Invoice,
	watchDetailSetting: WatchDetailSetting,
	qrCode: QRCode,
}
