import React, {useEffect, useImperativeHandle, useRef} from 'react'
import Dialog from '@mui/material/Dialog'
import isFunction from 'lodash/isFunction'

const ConfirmPopUp = React.forwardRef(({children, onConfirm, onCancel, title, content, handleCheckVisibleConfirmPopup, isFirstPressEscape, handlFirstPressEscape, onToggle}, ref) => {
	const [open, setOpen] = React.useState(false)
	const buttonsRef = useRef([])

	useImperativeHandle(ref, () => ({
		open() {
			setOpen(true)
		},
		close() {
			setOpen(false)
		},
	}))

	useEffect(() => {
		if (onToggle) {
			onToggle(open)
		}
	}, [onToggle, open])

	const handleClose = e => {
		e.preventDefault()
		setOpen(false)
		if (isFunction(onCancel)) onCancel(e)
		if (isFunction(handleCheckVisibleConfirmPopup)) handleCheckVisibleConfirmPopup(false)
	}

	const handleConfirmation = e => {
		e.preventDefault()
		setOpen(false)
		if (isFunction(onConfirm)) onConfirm(e)
		if (isFunction(handleCheckVisibleConfirmPopup)) handleCheckVisibleConfirmPopup(false)
	}

	const handleChildClick = e => {
		e.preventDefault()
		setOpen(true)
		if (isFunction(handleCheckVisibleConfirmPopup)) handleCheckVisibleConfirmPopup(true)
	}

	const handleKeyDown = e => {
		if (e.key === 'Escape' && !isFirstPressEscape) {
			handleClose(e)
		}
		if (isFunction(handlFirstPressEscape)) handlFirstPressEscape()

		if (open) {
			if (e.key === 'ArrowRight') {
				e.preventDefault()
				const currentIndex = buttonsRef.current.indexOf(document.activeElement)
				const nextIndex = (currentIndex + 1) % buttonsRef.current.length
				buttonsRef.current[nextIndex].focus()
			} else if (e.key === 'ArrowLeft') {
				e.preventDefault()
				const currentIndex = buttonsRef.current.indexOf(document.activeElement)
				const prevIndex = (currentIndex - 1 + buttonsRef.current.length) % buttonsRef.current.length
				buttonsRef.current[prevIndex].focus()
			}
		}
	}

	return (
		<React.Fragment>
			<Dialog open={open} onKeyDown={handleKeyDown}>
				<div className="confirmation-dialog-box-inner p-8 md:pl-[100px] md:pr-[100px] text-center">
					<div className="confirmation-dialog-box-head">{title && <h2 className="font-bold text-xl mb-2 text-black">{title}</h2>}</div>
					<div className="confirmation-dialog-box-content mb-8 text-slate-500">{content}</div>
					<div className="confirmation-dialog-box-footer mt-5 text-center flex justify-center">
						<button
							ref={el => (buttonsRef.current[0] = el)}
							className="transition-all hover:bg-gray-300 bg-gray-200 font-semibold md:py-2.5 md:px-4 rounded text-gray-700 text-sm uppercase mr-[40px] md:w-[80px] md:flex-none flex-1"
							onClick={handleClose}
						>
							Close
						</button>
						<button
							ref={el => (buttonsRef.current[1] = el)}
							className="transition-all hover:bg-red-300 bg-red-200 font-semibold md:py-2.5 md:px-4 rounded text-red-700 text-sm uppercase md:w-[80px] md:flex-none flex-1"
							onClick={handleConfirmation}
							onTouchEnd={handleConfirmation}
							autoFocus
						>
							Yes
						</button>
					</div>
				</div>
			</Dialog>
			<React.Fragment>{React.cloneElement(children, {onClick: handleChildClick})}</React.Fragment>
		</React.Fragment>
	)
})

export default ConfirmPopUp
