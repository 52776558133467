import React, {useState, useRef} from 'react'
import useOutsideClick from '../../../../hooks/useOutsideClick'

const SelectSalepersonDropdown = ({
	userOptions,
	selectedUser,
	onSelect,
	className,
	disabled
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const dropdownSalesPerson = useRef(null)
	useOutsideClick(dropdownSalesPerson, () => setIsOpen(false));
	return(
		<div className="relative">
			<div onClick={() => {
					if(disabled) return
					setIsOpen(!isOpen)
				}} className={className ? className : "bg-white w-auto relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] text-center pl-[21px] pr-[30px]"}>
				<span className="px-2 mr-2">
					{selectedUser ? selectedUser?.first_name + ' ' + selectedUser?.last_name : ''}
				</span>
				<img src="./Images/dropdown.png" alt="" className="w-[13px] h-[20px] absolute top-0 right-[4px]" />
			</div>
			{
				isOpen && (
					<ul className="rounded-[5px] border-[1px] border-[#5D6E81] absolute z-[999] bottom-[20px] bg-white min-w-[158px] text-[#5D6E81] text-[12px]" ref={dropdownSalesPerson}>
						{
							userOptions?.map((user, index) => {
								return(
									<li className="text-[#5D6E81] cursor-pointer px-[16px] py-[2px] whitespace-nowrap relative hover:bg-[#F1F2F4]"
										key={index}
										onClick={() => {
												setIsOpen(false)
												onSelect(user)
										}}
                  >
										{user?.first_name + ' ' + user?.last_name}
										{selectedUser && selectedUser.id === user.id && (
											<div className="absolute top-[7px] left-[6px]">
												<svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
													<path d="M6.1775 0L7 1.04124L2.33333 6.90735L0 3.98163L0.828333 2.94772L2.33333 4.83221L6.1775 0Z" fill="#5D6E81"/>
												</svg>
											</div>
										)}
									</li>
								)
							})
						}
					</ul>
				)
		  }
		</div>
	)
}

export default SelectSalepersonDropdown
