import React from 'react'
import ClassNames from 'classnames'
import Moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import {connect} from 'react-redux'
import 'react-swipeable-list/dist/styles.css';

import { getUnopenedConversationCount, getConversationsCount } from '../../app/appActions'
import DropdownMenu from '../DropdownMenu'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import {Globals} from '../../Context'

const WatchImage = React.memo(function Image({ src }) {
    return (
        <div
            className="bg-center bg-contain bg-no-repeat cursor-pointer h-16 md:h-24 m-auto w-16 md:w-24 max-h-[60px] max-w-[60px]"
            style={{
                backgroundImage: src !== undefined ? `url('${src}')` : `url('/watch_icon.png')`,
            }}
        ></div>
    )
});

const SidebarItem = props => {
    const { selectedThread, thread, onOpenConversation, toggleReadStatus, tab, currentSpace } = props


    if (isNil(thread)) {
        return null
    }

    return (
        <div
            className={ClassNames(
                'grid grid-cols-10 py-3 transition-all hover:opacity-90 cursor-pointer p-5 py-0 transition-all hove:opacity-80',
                {
                    'bg-gray-50': !isEmpty(selectedThread) && selectedThread.id === thread.id,
                }
            )}
            onClick={() => {
                onOpenConversation(thread)
            }}
        >

            <div className="col-span-8 relative">
                {
                    !thread.read && (
                        <div className="absolute top-[50%] mt-[-20px] left-[-18px] text-blue-500">
                            ●
                        </div>
                    )
                }
                <div className="flex flex-row gap-3 items-center">
                    <div className="basis-1/4">
                        <div className="inline-block rounded-full text-white overflow-hidden">
                            <WatchImage src={(thread.watch?.images && !!head(thread.watch.images)) ? `${head(thread.watch.images).url}` : `/watch_icon.png`} />
                        </div>
                    </div>
                    <div className="basis-full min-w-0">
                        <div className="">
                            {
                                tab === 'buy' && (
                                    <h4 className="font-medium text-[14px] truncate text-[#333]">{thread.watch?.short_name}</h4>
                                )
                            }

                            {
                                tab === 'sell' && (
                                    <h4 className="font-medium text-[14px] truncate text-[#333]">{thread.watch?.short_name_sell}</h4>
                                )
                            }
                            {thread.status === 'archived' ? (
                                <div className="font-medium text-sm text-[#333] mt-1">
                                    No longer available
                                </div>
                            ) : (!isNil(thread.watch?.wholesale_price) ? (
                                        <div className="font-medium text-sm text-blue-500 mt-1">
                                            {Globals.readCurrencyNumber(thread.watch?.wholesale_price)}
                                        </div>
                                    ) :
                                    <div className="font-medium text-sm text-blue-500 mt-1">
                                        Contact for Price
                                    </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-span-2 min-w-0 relative">
                <Tooltip title={Moment(thread.created_at).format('Do of MMMM YYYY @HH:mm')} placement="right" arrow>
                    <div className="pt-2.5 text-gray-400 text-xs text-right truncate">
                        {Moment(thread.created_at).format('MM/DD/YYYY')}
                    </div>
                </Tooltip>

                <DropdownMenu
                    containerStyles="text-[18px] leading-none text-right whitespace-nowrap cursor-pointer relative"
                    label="..."
                    items={[
                        {
                            label: thread.read ? 'Mark as unread' : 'Mark as read',
                            onClick: () => {
                                toggleReadStatus(thread, !thread.read)
                            },
                        }
                    ]}
                />
                {
                    tab === 'buy' && (
                        <div className="bottom-0 text-gray-400 text-xs text-right absolute right-0 whitespace-nowrap">
                            {thread.watch?.space.name}
                        </div>
                    )
                }

                {
                    tab === 'sell' && (
                        <div className="flex flex-col text-gray-400 text-xs text-right absolute right-0 whitespace-nowrap">
                            <span>{thread.sender_space.name}</span>
                        </div>
                    )
                }

            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
        currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
    getUnopenedConversationCount,
    getConversationsCount
}

export default connect(mapStateToProps, mapActionsToProps)(SidebarItem)
