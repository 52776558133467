import React, { useRef } from 'react'
import TextField from '@mui/material/TextField'
import { Controller } from 'react-hook-form'
import {Globals} from '../../../../Context'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const sortDefaultOptions = [
  { value: 'date_purchased desc', label: 'Date Purchased (Newest First)' },
  { value: 'date_purchased asc', label: 'Date Purchased (Oldest First)' },
  { value: 'sku asc', label: 'Dealer SKU (Ascending)' },
  { value: 'sku desc', label: 'Dealer SKU (Descending)' },
  { value: 'reference_number asc', label: 'Reference Number (Ascending)' },
  { value: 'reference_number desc', label: 'Reference Number (Descending)' },
  { value: 'brand asc', label: 'Brand (Ascending)' },
  { value: 'brand desc', label: 'Brand (Descending)' },
  { value: 'cost asc', label: 'Cost (Low to High)' },
  { value: 'cost desc', label: 'Cost (High to Low)' },
]

const InventoryConfigGroup = ( { control, isAutoSuggestEnabled, profile, triggerSubmit }) => {
  const suggestDetailRef = useRef(null);

  const handleMouseEnterSuggestDetail = () => {
    if (suggestDetailRef.current) {
      suggestDetailRef.current.classList.remove("hidden");
    }
  };

  const handleMouseLeaveSuggestDetail = () => {
    if (suggestDetailRef.current) {
      suggestDetailRef.current.classList.add("hidden");
    }
  };

  return (
    <div className="mt-[17px]">
      {profile.role === 'owner' &&
        <>
          <div className='flex items-center'>
            <div className="mx-6 text-[16px]">Do you want Elefta to auto-suggest your next SKU number?</div>
            <Controller
                name="auto_suggest_sku_enabled"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby="auto-suggest-sku-number-group"
                    sx={{
                      display: 'contents'
                    }}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      triggerSubmit()
                    }}
                    row
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label={
                        <Box component="div">
                          Yes
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" />}
                      label={
                        <Box component="div">
                          No
                        </Box>
                      }
                    />
                  </RadioGroup>
                )}
            />
            {isAutoSuggestEnabled && (
              <>
                <Controller
                  name="auto_suggest_sku_prefix"
                  control={control}
                  defaultValue={''}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      sx={{ ml: 2}}
                      label="Prefix"
                      size='small'
                      variant="outlined"
                      inputProps={{
                        style: {color: '#5D6E81'},
                        className: '!px-2 !pt-3 !pb-1',
                        maxLength: 3,
                      }}
                      classes={{root: 'w-[60px]'}}
                      InputLabelProps={{style: {color: '#D1D6DA', transform: 'translate(5px, 0px)', fontSize: '8px' , lineHeight: '14px'}, shrink: false}}
                      onBlur={(e) => {
                        field.onChange(e.target.value)
                        triggerSubmit()
                      }}
                      value={field.value}
                      name="auto_suggest_sku_prefix"
                      type="text"
                    />
                  }
                />
                <Controller
                  name="auto_suggest_sku_starting_number"
                  control={control}
                  defaultValue={''}
                  onChange={(e) => {
                    e.preventDefault()
                  }}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      sx={{ ml: 2}}
                      label="Starting Number*"
                      size='small'
                      variant="outlined"
                      inputProps={{
                        style: {color: '#5D6E81'},
                        className: '!px-2 !pt-3 !pb-1',
                        min: 1,
                        maxLength: 12
                      }}
                      classes={{root: 'w-[100px]'}}
                      InputLabelProps={{style: {color: '#D1D6DA', transform: 'translate(5px, 0px)', fontSize: '8px' , lineHeight: '14px'}, shrink: false}}
                      onBlur={(e) => {
                        field.onChange(e.target.value)
                        triggerSubmit()
                      }}
                      name="auto_suggest_sku_starting_number"
                      value={field.value}
                      type="number"
                    />
                  }
                />
                <Controller
                  name="auto_suggest_sku_suffix"
                  control={control}
                  defaultValue={''}
                  onChange={(e) => {
                    e.preventDefault()
                  }}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      sx={{ ml: 2}}
                      label="Suffix"
                      size='small'
                      variant="outlined"
                      inputProps={{
                        style: {color: '#5D6E81'},
                        className: '!px-2 !pt-3 !pb-1',
                        maxLength: 3,
                      }}
                      classes={{root: 'w-[60px]'}}
                      InputLabelProps={{style: {color: '#D1D6DA', transform: 'translate(5px, 0px)', fontSize: '8px' , lineHeight: '14px'}, shrink: false}}
                      onBlur={(e) => {
                        field.onChange(e.target.value)
                        triggerSubmit()
                      }}
                      name="auto_suggest_sku_suffix"
                      value={field.value}
                      type="text"
                    />
                  }
                />
                <div className='relative'>
                  <img src="/Images/info-icon-gray.svg" alt="info-icon-gray.svg" width={25} className="ml-3 cursor-pointer" onMouseEnter={handleMouseEnterSuggestDetail} onMouseLeave={handleMouseLeaveSuggestDetail}/>
                  <div className="absolute top-full right-0 w-[500px] bg-white mt-2 p-4 rounded-lg shadow-lg z-10 hidden" ref={suggestDetailRef}>
                    <div className="text-[#5D6E81] text-[12px]">
                      <div className="flex items-center">
                        <span className="ml-2">Prefix (Optional) – Add letters before the number (e.g., "W" or "ABC"). Max 3 letters.</span>
                      </div>
                      <div className="flex items-center">
                        <span className="ml-2">Starting Number – The first number in the sequence (cannot be changed later). Max 12 numbers.</span>
                      </div>
                      <div className="flex items-center">
                        <span className="ml-2">Suffix (Optional) – Add letters after the number (e.g., "TD", "XYZ"). Max 3 letters.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      }

      {Globals.adminOrOwnerOrEditor(profile) &&
        <div className='flex items-center opacity-60'>
          <div className="mx-6 text-[16px]">Do you want to quick edit from the inventory page?</div>
            <Controller
              name="quick_edit_visible"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby="wholesale-price-visibility-group"
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio size="small" />}
                    label={
                      <Box component="div">
                        Yes
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio size="small" />}
                    label={
                      <Box component="div">
                        No
                      </Box>
                    }
                  />
                </RadioGroup>
                )}
              />
        </div>
      }

      {Globals.adminOrOwnerOrEditor(profile) &&
        <div className='flex items-center mt-2'>
          <div className="mx-6 text-[16px]">Default sort order for Inventory:</div>
            <Controller
                name="default_sort_order_inventory"
                control={control}
                sx={{
                  borderRadius: '8px',
                  fontSize: '14px',
                }}
                defaultValue={''}
                render={({ field }) => (
                  <div className="relative w-[200px]">
                    <label className='absolute top-[2px] left-[20px] text-[#D1D6DB] text-[6px]'>
                      Default
                    </label>
                    <Select
                      {...field}
                      variant="outlined"
                      size="small"
                      sx={{
                        width: '270px',
                        borderRadius: '8px',
                        fontSize: '14px',
                        color: '#5D6E81',
                        outline: 'none',
                      }}
                      defaultValue={""}
                      classes={{
                        root: 'rounded-[8px]',
                      }}
                      value={field.value}
                      inputProps={{
                        className: `!px-[18px] !pt-2 !pb-1`,
                      }}
                      IconComponent={(props) => (
                        <img
                          {...props}
                          src="./Images/dropdown.png"
                          alt=""
                          className="w-[20px] h-full py-[6px] absolute top-[-1px] right-[5px] pointer-events-none"
                        />
                      )}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        sx: {
                          borderRadius: '8px',
                          fontSize: '14px',
                          outline: 'none',
                        },
                        classes: {
                          paper: '!rounded-[8px]',
                          list: 'text-[#5D6E81] rounded-[8px] border-[1px] border-[#5D6E81]',
                        },
                        getContentAnchorEl: null
                      }}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        triggerSubmit()
                      }}
                    >
                      {sortDefaultOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
            />
        </div>
      }

      {Globals.adminOrOwnerOrEditor(profile) &&
        <div className='flex items-center mt-3'>
            <div className="mx-6 text-[16px]">Default sort order for Sold Inventory:</div>
            <Controller
                name="default_sort_order_sold_inventory"
                control={control}
                sx={{
                  borderRadius: '8px',
                  fontSize: '14px',
                }}
                defaultValue={''}
                render={({ field }) => (
                  <div className="relative">
                    <label className='absolute top-[2px] left-[20px] text-[#D1D6DB] text-[6px]'>
                      Default
                    </label>
                    <Select
                      {...field}
                      variant="outlined"
                      size="small"
                      sx={{
                        width: '270px',
                        borderRadius: '8px',
                        fontSize: '14px',
                        color: '#5D6E81',
                        outline: 'none',
                      }}
                      defaultValue={""}
                      value={field.value}
                      classes={{
                        root: 'rounded-[8px]',
                      }}
                      inputProps={{
                        className: `!px-[18px] !pt-2 !pb-1`,
                      }}
                      IconComponent={(props) => (
                        <img
                          {...props}
                          src="./Images/dropdown.png"
                          alt=""
                          className="w-[20px] h-full py-[6px] absolute top-[-1px] right-[5px] pointer-events-none"
                        />
                      )}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        sx: {
                          borderRadius: '8px',
                          fontSize: '14px',
                          outline: 'none',
                        },
                        classes: {
                          paper: '!rounded-[8px]',
                          list: 'text-[#5D6E81] rounded-[8px] border-[1px] border-[#5D6E81]',
                        },
                        getContentAnchorEl: null
                      }}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        triggerSubmit()
                      }}
                    >
                      {sortDefaultOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
            />
        </div>
      }
    </div>
  );
};

export default InventoryConfigGroup
