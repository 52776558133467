import React from 'react'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Box from '@mui/material/Box'

const options = [
    {
        name: 'All Watches In Inventory',
        value: 'all_item_inventory'
    },
    {
        name: 'Individually Choose',
        value: 'individually_inventory'
    },
    {
        name: 'None',
        value: 'nothing_inventory'
    }
]

const InventoryOptionsList = (props) => {
    const { syncTypeSelected, setSyncTypeSelected } = props

    const handleOptionChange = (e) => {
        setSyncTypeSelected(e.target.value)
    }

    return (
        <div className="w-full">
            <div className="flex flex-col w-full">
                <div className="flex flex-row w-full">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-row w-full">
                            <h2 className="text-lg font-semibold">Inventory Sync Type</h2>
                        </div>
                        <div className="ml-4 mt-2 space-y-2">
                            <RadioGroup
                                aria-labelledby="wholesale-price-visibility-group"
                                value={syncTypeSelected}
                                onChange={handleOptionChange}>
                                {options.map(option => (
                                    <FormControlLabel
                                        value={option.value}
                                        control={<Radio size="small" />}
                                        label={
                                            <Box component="div">
                                                {option.name}
                                            </Box>
                                        }
                                    />
                                ))}
                            </RadioGroup>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InventoryOptionsList
