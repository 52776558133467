import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Typography from '@mui/material/Typography'
import QrStickerConfigList from './components/QrSticerConfigList'
import QRPreviewBlock from './components/QRPreviewBlock'
import useQRCodeSettings from '../../../../hooks/useQRCodeSettings'

const QrCodeSettings = () => {
	const selectedSpace = useSelector(state => state.currentSpace)
	const {updateSpaceQRType, getStickerSettings, stickerSettings} = useQRCodeSettings()
	const [selectedQrType, setSelectedQrType] = useState(selectedSpace.qr_type)
	const [configData, setConfigData] = useState(null)

	const toggleType = type => {
		if (selectedQrType !== type) {
			setSelectedQrType(type)
			updateSpaceQRType({qr_type: type === 'qr_sticker' ? 1 : 0})
		}
	}

	useEffect(() => {
		if (stickerSettings && !configData) {
			setConfigData(stickerSettings)
		}
	}, [stickerSettings, configData])

	useEffect(() => {
		getStickerSettings()
	}, [])

	useEffect(() => {
		if (stickerSettings && !configData) {
			setConfigData(stickerSettings)
		}
	}, [stickerSettings, configData])

	if (selectedSpace.qr_type === 'qr_sticker' && !stickerSettings) {
		return null
	}

	return (
		<div className="w-full h-full px-5 pt-2 pb-10">
			<div className="flex flex-row px-4 justify-between pt-[60px]">
				<div className={'flex flex-1 justify-center pt-[10px]'} style={{maxWidth: 434}}>
					<div style={{width: 360}}>
						<Typography variant="h6" sx={{fontWeight: 'bold', mr: 2, mt: 2, textAlign: 'center'}}>
							Display Options
						</Typography>
						<div
							className="w-full flex flex-row mt-7"
							style={{
								backgroundColor: 'rgba(61, 61, 61, 0.5)',
								border: '1px solid rgba(61, 61, 61, 0.5)',
								borderRadius: 20,
							}}
						>
							<button
								className="text-md flex flex-row flex-1 font-bold px-4 py-1.5 pr-3 justify-center items-center capitalize"
								onClick={() => toggleType('qr_code')}
								style={{
									backgroundColor: selectedQrType === 'qr_code' ? '#fff' : 'transparent',
									borderRadius: 20,
								}}
							>
								<span
									style={{
										fontSize: 14,
										color: selectedQrType === 'qr_code' ? '#3D3D3D' : '#ffffff',
									}}
								>
									QR Code
								</span>
							</button>
							<button
								className="text-md flex flex-row flex-1 font-bold px-4 py-1.5 pr-3 justify-center items-center capitalize"
								onClick={() => toggleType('qr_sticker')}
								style={{
									backgroundColor: selectedQrType === 'qr_sticker' ? '#fff' : 'transparent',
									borderRadius: 20,
								}}
							>
								<span
									style={{
										fontSize: 14,
										color: selectedQrType === 'qr_sticker' ? '#3D3D3D' : '#ffffff',
									}}
								>
									QR CODE + DETAILS
								</span>
							</button>
						</div>
					</div>
				</div>

				<QRPreviewBlock mocked selectedQrType={selectedQrType} configData={configData || []} />
			</div>

			{selectedQrType === 'qr_sticker' && <QrStickerConfigList setConfigData={setConfigData} configData={configData || []} />}
		</div>
	)
}

export default QrCodeSettings
