import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form'

import { Globals } from '../../Context'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'

const ContactDialogViewCard = ({ open, setOpen, contactCardData, webStoreSetting, space }) => {
    const [isVisibleContactForm, setVisibleContactForm] = useState(false)
    const { handleSubmit, control, reset } = useForm({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const handleClose = () => {
        setOpen(false)
        setVisibleContactForm(false)
        const defaultFormValues = {
            name: '',
            email: '',
            phone: '',
            message: ''
        };
        reset(defaultFormValues);
    };

    const onSubmit = async (data) => {
        await Globals.New_Axios().post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/store/contact_store/${webStoreSetting.uid}/${webStoreSetting.slug}`, { contact: data } ).then((response) => {
            setVisibleContactForm(true)
            const defaultFormValues = {
                name: '',
                email: '',
                phone: '',
                message: ''
            };
            reset(defaultFormValues);
          }).catch(error => {
            console.log(error)
          })
    }

    const phoneFormatter = (value) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (cvLength < 4) return currentValue;
        if (cvLength < 7) return `${currentValue.slice(0, 3)}.${currentValue.slice(3)}`;
        return `${currentValue.slice(0, 3)}.${currentValue.slice(3, 6)}.${currentValue.slice(6, 10)}`;
    }

    return (
            <Dialog
                open={open}
                onClose={setOpen}
                onKeyUp={e => {
                    if (e.key === 'Escape') handleClose()
                }}
                fullScreen
                PaperProps={{
                sx: {
                        height: '100vh',
                        maxWidth: 483,
                        margin: 0,
                        position: 'fixed',
                        right: 0,
                        borderTopLeftRadius: "36px",
                    },
                }}
            >
                <DialogTitle sx={{ backgroundColor: '#E8E8E8', position: 'relative' }} >
                    <div className='text-[45px] font-bold text-[#000000]'>
                        <span>{webStoreSetting.store_company_name}</span>
                    </div>
                    <div
                        onClick={handleClose}
                        className='absolute top-2 right-2 cursor-pointer'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                            <path d="M11.7003 14.6231L6.28195 9.01228L8.69174 6.68518L14.1101 12.296L20.0806 6.53036L22.4077 8.94015L16.4372 14.7058L21.8903 20.3526L19.4805 22.6797L14.0274 17.0329L8.02091 22.8333L5.6938 20.4235L11.7003 14.6231Z" fill="black" fill-opacity="0.5"/>
                        </svg>
                    </div>
                </DialogTitle>
                <DialogContent   sx={{border: 0, paddingBottom: 10}} dividers>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-[23px]'>
                            <div className='text-[#5D6E81] font-semibold text-[25px] font-poppins mt-[7px] px-1'>
                                We Love Helping Our Customers!
                            </div>
                            <div className='text-[18px] font-light text-[#5D6E81] font-poppins px-1'>
                                Available around the clock, our dedicated <br/> team of client advisors, skilled watchmakers,<br/>and knowledgeable experts are poised to <br/> assist you through every stage!
                            </div>
                            {webStoreSetting.store_address_enabled || contactCardData?.phone || contactCardData?.email ?
                                <div className='border-t-[1px] border-[#000000] border-b-[1px] mt-[30px] px-1'>
                                    <div class="flex flex-col justify-center py-4 text-[16px] text-[#5D6E81]">
                                        {webStoreSetting.store_address_enabled && webStoreSetting?.address && (
                                                <div className='flex text-[#5D6E81] font-medium'>
                                                    <React.Fragment>
                                                        <img className='mr-2' src="/Images/location_card.svg" alt="location_card" />
                                                        <div>
                                                            {webStoreSetting?.address}
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            )
                                        }
                                        {
                                            contactCardData?.phone && (
                                                <div className='flex text-[#5D6E81] font-medium mt-2'>
                                                    <React.Fragment>
                                                    <img className='mr-2' src="/Images/phone_card.svg" alt="phone_card" />
                                                        <div>
                                                            {contactCardData?.phone}
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            )
                                        }
                                        {contactCardData?.email &&
                                            <div className='flex text-[#5D6E81] font-medium mt-2'>
                                                <React.Fragment>
                                                <img className='mr-2' src="/Images/email_card.svg" alt="email_card" />
                                                    <div>
                                                        {contactCardData?.email}
                                                    </div>
                                                </React.Fragment>
                                            </div>
                                        }
                                    </div>
                                </div> : <></>
                            }
                        </div>
                        <div className='mt-[30px] px-4'>
                            <span className='text-[20px] font-semibold text-[#5D6E81] font-poppins ml-1'>
                                Drop Us A Line
                            </span>
                            {isVisibleContactForm ?
                                <div className='flex flex-col items-center bg-[#E8E8E8] h-[123px] w-[416px] rounded-[8px] px-2 mt-2'>
                                    <span className='text-[16px] font-semibold text-[#5D6E81] mt-2 leading-[19px]'>Thank you for contacting us!</span>
                                    <span className='text-[16px] font-semibold text-[#5D6E81] mt-2 text-center leading-[19px]'>Your message has been sent, and one of <br /> our experts will reach out to you as soon as possible</span>
                                </div> :
                                <div className=''>

                                    <Controller
                                        name="name"
                                        control={control}
                                        defaultValue={''}
                                        render={({ field }) =>
                                        <TextField {...field}
                                            margin="dense"
                                            variant="outlined"
                                            size="small"
                                            label="First And Last Name"
                                            InputLabelProps={{style: {color: '#5D6E8175', fontWeight: 600}}}
                                            required
                                            fullWidth
                                        />}
                                    />
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue={''}
                                        render={({ field }) =>
                                        <TextField {...field}
                                            margin="dense"
                                            variant="outlined"
                                            size="small"
                                            label="Email"
                                            required
                                            type="email"
                                            InputLabelProps={{style: {color: '#5D6E8175', fontWeight: 600}}}
                                            fullWidth
                                        />}
                                    />
                                    <Controller
                                        name="phone"
                                        control={control}
                                        defaultValue={''}
                                        render={({ field }) =>
                                        <TextField {...field}
                                            margin="dense"
                                            variant="outlined"
                                            size="small"
                                            label="Phone Number"
                                            InputLabelProps={{style: {color: '#5D6E8175', fontWeight: 600}}}
                                            required
                                            fullWidth
                                            onChange={(e) => {
                                                field.onChange(phoneFormatter(e.target.value))
                                            }}
                                        />}
                                    />
                                    <Controller
                                        name="message"
                                        control={control}
                                        defaultValue={''}
                                        render={({ field }) =>
                                        <TextField {...field}
                                            margin="dense"
                                            variant="outlined"
                                            size="small"
                                            InputLabelProps={{style: {color: '#5D6E8175', fontWeight: 600}}}
                                            label="Message"
                                            required
                                            fullWidth
                                            multiline
                                            rows={6}
                                        />}
                                    />
                                </div>
                            }
                        </div>
                        {!isVisibleContactForm &&
                            <div className='flex justify-center mt-[14px]'>
                                <button  type="submit" className='py-2 px-4 bg-[#5D6E81] text-[#fff] rounded-[8px] cursor-pointer'>
                                    Send Message
                                </button>
                            </div>
                        }
                    </form>

                    {
                      !webStoreSetting.white_label && (
                        <div className='absolute bottom-[10px] right-[10px] w-[181px] h-[39px] rounded-[10px] bg-[#5D6E8130] flex items-center px-1'>
                            <span className='text-[12px] font-normal leading-[18px] font-poppins text-[#5D6E81]'>Powered By</span>
                            <img className='h-[23px]'  src="/Images/logo_footer.png"/>
                        </div>
                      )
                    }
                </DialogContent>
            </Dialog>
    );
};

export default ContactDialogViewCard;
