import React from 'react';
import Tooltip from '@mui/material/Tooltip'

const CommentForm = ({ commentField, setCommentField, sendingComment, selectedComment, handleUpdateComment, handleSubmitComment }) => {
  return (
    <div className='text-right'>
      <textarea
        value={commentField.content}
        onChange={e => setCommentField({...commentField, content: e.target.value})}
        className="border rounded-[5px] w-[100%] h-[100px] px-[10px] py-[10px] mt-[20px]"
        placeholder="Add a comment"
      />
      <div className='flex justify-between items-center'>
        <div className='items-center flex h-full'>
          <span className="__checkbox h-max" style={{transform: 'scale(.8)'}}>
            <input
              id={`checkbox-visible-on-pdf`}
              type="checkbox"
              onChange={e => setCommentField({...commentField, visible_on_pdf: e.target.checked})}
              checked={commentField.visible_on_pdf}
            />
            <Tooltip title="Visible on Memo" placement="top" arrow>
              <label htmlFor={`checkbox-visible-on-pdf`}></label>
            </Tooltip>
          </span>
          <label htmlFor={`checkbox-visible-on-pdf`} className='text-[12px] ml-[5px] cursor-pointer'>Visible on Memo</label>
        </div>
        <button
          className="bg-blue-600 capitalize font-medium hover:opacity-90 inline-flex items-center px-4 py-1.5 rounded text-center text-sm text-white"
          disabled={sendingComment}
          onClick={e => {
            selectedComment ? handleUpdateComment(e, selectedComment.id) : handleSubmitComment(e)
          }
          }
        >
          {selectedComment ? 'Update' : 'Comment'}
        </button>
      </div>
    </div>
  );
}

export default CommentForm;