export const mockedQrData = {
	type: 'qr_code', // qr_code || qr_sticker
	config: [
		{
			key: 'price_key_3',
			value: 'msrp_price',
			visible: true,
		},
		{
			key: 'price_key_2',
			value: 'online_price',
			visible: true,
		},
		{
			key: 'price_key_1',
			value: 4.56,
			visible: true,
		},
		{
			key: 'brand',
			visible: true,
		},
		{
			key: 'series',
			visible: true,
		},
		{
			key: 'reference_number',
			visible: true,
		},
		{
			key: 'serial_number',
			visible: true,
		},
		{
			key: 'scope_of_delivery',
			visible: true,
		},
		{
			key: 'size',
			visible: null,
		},
	],
}
export const dummyWatchData = {
	cost: 5000,
	msrp_price: 10000,
	sku: 'T27187',
	online_price: 8000,
	brand: 'Rolex',
	wholesale_price: 6000,
	series: 'Royal Oak 41mm Chrono Frosted',
	reference_number: '26331BC.GG.1224BC.02',
	box: true,
	warranty_papers: true,
	serial_number: '7919040',
	warranty: '2021-10-10',
}
const valuesToInches = {
	large: {
		width: 4,
		height: 6,
	},
	medium: {
		width: 4,
		height: 2.25,
	},
	medium_long: {
		width: 4,
		height: 1,
	},
	medium_tall: {
		width: 2,
		height: 4,
	},
	small: {
		width: 2,
		height: 1,
	},
}
export const qrFieldsConfig = {
	price_key_3: {
		label: 'Price Position 3, Display: ',
		fieldType: 'select',
		defaultValue: 'msrp_price',
	},
	price_key_2: {
		label: 'Price Position 2, Display:',
		fieldType: 'select',
		defaultValue: 'wholesale_price',
	},
	price_key_1: {
		label: 'Price Position 1, Display: ',
		fieldType: 'numeric',
		subLabel: 'Multiply my cost by',
	},
	brand: {
		label: 'Brand',
	},
	series: {
		label: 'Series',
	},
	reference_number: {
		label: 'Reference Number',
	},
	serial_number: {
		label: 'Serial Number',
	},
	scope_of_delivery: {
		label: 'Scope of Delivery',
	},
	size: {
		label: 'Size',
		value: '',
	},
}
export const priceOptions = [
	{
		value: 'msrp_price',
		label: 'MSRP Price',
	},
	{
		value: 'wholesale_price',
		label: 'Wholesale Price',
	},
	{
		value: 'online_price',
		label: 'Online Price',
	},
]
export const printingConsts = [
	[
		{
			key: 'value.spacing.col1.x',
			label: 'X',
		},
		{
			key: 'value.spacing.col1.y',
			label: 'Y',
		},
		{
			key: "value.spacing.col1.font_size",
			label: 'Font Size',
		},
		{
			key: 'value.spacing.col1.gap',
			label: 'Line Gap',
		},
	],
	[
		{
			key: 'value.spacing.col2.x',
			label: 'X',
		},
		{
			key: 'value.spacing.col2.y',
			label: 'Y',
		},
		{
			key: "value.spacing.col2.qrSize",
			label: 'QR Size',
			type: 'select',
		},
	],
	[
		{
			key: 'value.spacing.col3.x',
			label: 'X',
		},
		{
			key: 'value.spacing.col3.y',
			label: 'Y',
		},
		{
			key: "value.spacing.col3.font_size",
			label: 'Font Size',
		},
		{
			key: 'value.spacing.col3.gap',
			label: 'Line Gap',
		},
	],
]
