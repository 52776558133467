import React, { useMemo } from 'react'
import { useSelector} from 'react-redux'
import { Controller } from 'react-hook-form'
import {Globals} from '../../../../Context'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import useGeneralSettings from './hooks/useGeneralSettings'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useEffect } from 'react'
import SelectSalepersonDropdown from './SelectSalepersonDropdown'
import TermsDropdown from '../../../Sales/TermsDropdown'
import Box from '@mui/material/Box'
import { addToast } from '../../../../app/appActions'

const SalesConfig = ( { control, triggerSubmit, setValue }) => {
  const { currentSpace, profile } = useSelector(state => ({currentSpace: state.currentSpace, profile: state.profile}))
  const { space_general_setting } = currentSpace

  const [addSaleTax, setAddSaleTax] = React.useState(space_general_setting.add_sale_tax)
  const [visibleSalespersonOnSales, setVisibleSalespersonOnSales] = React.useState(space_general_setting.visible_salesperson_on_sales)
  const [paymentTermMethod, setPaymentTermMethod] = React.useState(space_general_setting.payment_term_method)
  const [termCustomSelect, setTermCustomSelect] = React.useState(space_general_setting.payment_term_method)
  const [paymentRangeCount, setPaymentRangeCount] = React.useState(space_general_setting.payment_term_days)
  const [selectedSalePersonId, setSelectedSalePersonId] = React.useState(space_general_setting.sales_saleperson_id)
  const [selectedPerson, setSelectedPerson] = React.useState({ first_name: '', last_name: '' })
  
  const termOptions = [
    {value: 'cod', label: 'C.O.D.'},
    {value: 'net_3', label: 'Net 3'},
    {value: 'net_7', label: 'Net 7'},
    {value: 'net_14', label: 'Net 14'},
    {value: 'net_30', label: 'Net 30'},
    {value: 'custom', label: 'Custom'},
  ]

	const {
		loadedSpaceUsers,
    handleLoadUsers
	} = useGeneralSettings({addToast})

  useEffect(() => {
    handleLoadUsers()
  }, [currentSpace])

  useEffect(() => {
    setSelectedPerson((loadedSpaceUsers || []).find(user => user.id === selectedSalePersonId))
  }, [loadedSpaceUsers])

  const selectedTerm = useMemo(() => {
    const method = paymentTermMethod
    switch (method) {
      case 'cod':
        return {value: 'cod', label: 'C.O.D.'}
      case 'net_3':
        return {value: 'net_3', label: 'Net 3'}
      case 'net_7':
        return {value: 'net_7', label: 'Net 7'}
      case 'net_14':
        return {value: 'net_14', label: 'Net 14'}
      case 'net_30':
        return {value: 'net_30', label: 'Net 30'}
      case 'custom':
      case 'days':
      case 'months':
        return {value: 'custom', label: 'Custom'}
      default:
        return {value: 'net_30', label: 'Net 30'}
    }
  }, [paymentTermMethod, currentSpace])

  const termCustomSelectObject = useMemo(() => {
		const method = termCustomSelect

		if (method === 'days') return {value: 'days', label: 'Days'}
		if (method === 'months') return {value: 'months', label: 'Months'}
		return { value: 'days', label: 'Days' }
	}, [termCustomSelect])

  return (
    <div className="mt-[17px]">
      {Globals.adminOrOwnerOrEditor(profile) &&
        <>
          <div className="flex items-center">
            <div className="ml-6 text-[16px]">Default Payment Terms:</div>
            <div className="ml-6">
              <Controller
                name="payment_term_method"
                control={control}
                defaultValue={false}
                render={({ field, ...rest }, extra) => (
                  <>
                    <TermsDropdown
                      disabled={!Globals.adminOrOwner(profile)}
                      termOptions={termOptions}
                      selectedTerm={selectedTerm || {}}
                      onSelect={(option) => {
                        setPaymentTermMethod(option.value)
                        if (option.value !== 'custom') {
                          setValue('payment_term_days', null)
                          field.onChange(option.value)
                        } else {
                          field.onChange('days')
                          setPaymentRangeCount('0')
                          setValue('payment_term_days', '0')
                        }
                        triggerSubmit()
                      }}
                      termCustomField={paymentRangeCount}
                      handleChangeTermCustomField={(e) => {
                        setValue('payment_term_days', e.target.value)
                        setPaymentRangeCount(e.target.value)
                        triggerSubmit()
                      }}
                      termCustomSelect={termCustomSelectObject}
                      handleSelectCustomTerm={(option) => {
                        if (option.value !== 'days' && option.value !== 'months') {
                          field.onChange(option.value)
                          setValue('payment_term_days', null)
                          setPaymentTermMethod(option.value)
                        } else {
                          setValue('payment_term_method', option.value)
                          setTermCustomSelect(option.value)
                          setValue('payment_term_days', paymentRangeCount || '0')
                        }
                        triggerSubmit()
                      }}
                      termCustomSelectStyle={{left: 10}}
                    />
                  </>
                )}
              />
            </div>
          </div> 
          <div className='flex items-center'>
            <div className="mx-6 text-[16px]">Do you want the ability to add sales tax to an invoice?</div>
            <div>
              <Controller
                name="add_sale_tax"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby="add-sale-tax-group"
                    sx={{
                      display: 'contents'
                    }}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      setAddSaleTax(!addSaleTax)
                      triggerSubmit()
                    }}
                    row
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label={
                        <Box component="div">
                          Yes
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" />}
                      label={
                        <Box component="div">
                          No
                        </Box>
                      }
                    />
                  </RadioGroup>
                )}
              />
            </div>
          </div>
          <div className='flex items-center'>
            <div className="mx-6 text-[16px]">Do you want your salesperson visible on the invoice?</div>
              <Controller
                name="visible_salesperson_on_sales"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby="visible-salesperson-on-sales-group"
                    sx={{
                      display: 'contents'
                    }}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      setVisibleSalespersonOnSales(!visibleSalespersonOnSales)
                      handleLoadUsers()
                      triggerSubmit()
                    }}
                    row
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label={
                        <Box component="div">
                          Yes
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" />}
                      label={
                        <Box component="div">
                          No
                        </Box>
                      }
                    />
                  </RadioGroup>
                )}
              />
              {visibleSalespersonOnSales && (
                <>
                  <Controller
                    name="sales_saleperson_id"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <SelectSalepersonDropdown
                        disabled={!Globals.adminOrOwner(profile)}
                        userOptions={loadedSpaceUsers}
                        selectedUser={selectedPerson}
                        onSelect={(value) => {
                          field.onChange(value.id)
                          setSelectedSalePersonId(value ? value.id : null)
                          triggerSubmit()
                        }}
                        className={'bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] text-center min-w-[120px] truncate'}
                      />
                    )}
                  />
                </>
              )}
          </div>
        </>
      }
    </div>
  );
};

export default SalesConfig
