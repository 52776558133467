import {connect} from 'react-redux'
import update from 'immutability-helper'
import React, {useRef} from 'react'
import {useDrag, useDrop} from 'react-dnd'
import Tooltip from '@mui/material/Tooltip'
import {addToast} from './../../app/appActions'
import isFunction from 'lodash/isFunction'

const TableColumn = ({index, column, moveTableColumn, deleteTableColumn, freezTableColumn}) => {
	const ref = useRef(null)
	const id = column.id

	const [{handlerId}, drop] = useDrop({
		accept: 'card',
		collect(monitor) {
			return {handlerId: monitor.getHandlerId()}
		},
		hover(item, monitor) {
			if (!ref.current) return
			const dragIndex = item.index
			const hoverIndex = index

			if (dragIndex === hoverIndex) return
			const hoverBoundingRect = ref.current?.getBoundingClientRect(),
				hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2,
				clientOffset = monitor.getClientOffset(),
				hoverClientY = clientOffset.y - hoverBoundingRect.top

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

			moveTableColumn(dragIndex, hoverIndex)
			item.index = hoverIndex
		},
	})

	const [{isDragging}, drag] = useDrag({
		type: 'card',
		item: () => {
			return {id, index}
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	})

	drag(drop(ref))

	return (
		<div key={id} ref={ref} className="px-1 flex items-center justify-between py-5 px-5 border-b relative cursor-move" style={{background: '#F8F8F8'}} data-handler-id={handlerId} data-dragging={isDragging}>
			<div className='flex items-center w-full justify-between'>
				<div>
					<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.38878 0.156094C5.17166 -0.0520313 4.82853 -0.0520313 4.61141 0.156094L0.298908 4.28109C0.132783 4.44009 0.0802831 4.68384 0.165783 4.89722C0.251283 5.11059 0.457533 5.25009 0.687408 5.25009H9.31241C9.54191 5.25009 9.74891 5.11022 9.83478 4.89722C9.92028 4.68422 9.86741 4.44009 9.70166 4.28109L5.38878 0.156094ZM9.31241 6.75009H0.687408C0.457533 6.75009 0.250908 6.88997 0.165408 7.10297C0.0799079 7.31597 0.132783 7.56009 0.298533 7.71909L4.61103 11.8441C4.71978 11.948 4.85966 12.0001 4.99991 12.0001C5.14016 12.0001 5.28003 11.948 5.38878 11.8441L9.70128 7.71909C9.86703 7.56009 9.91991 7.31634 9.83441 7.10297C9.74891 6.88997 9.54191 6.75009 9.31241 6.75009Z"
							fill="#5B5B5B"
						/>
					</svg>
				</div>
				<span className="w-3/4 text-sm inline-block overflow-hidden truncate"> {column.label} </span>
				<div className='flex gap-2'>
					<span className="__checkbox h-[22px]" style={{transform: 'scale(.8)'}}>
						<input id={`checkbox-freez-${id}`} type="checkbox" onChange={freezTableColumn.bind(this, id)} defaultChecked={column.freezed} />
						<Tooltip title="Freeze this column" placement="top" arrow>
							<label htmlFor={`checkbox-freez-${id}`}></label>
						</Tooltip>
					</span>
					<button onClick={() => deleteTableColumn(id)} className="cursor-pointer">
						<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9.37181 0.033122C9.21767 0.0367602 9.07123 0.101275 8.96451 0.212565L5.00194 4.17513L1.03938 0.212565C0.984949 0.156617 0.919848 0.112159 0.847927 0.0818251C0.776007 0.0514914 0.698731 0.0358987 0.620676 0.0359703C0.50468 0.036114 0.39136 0.0708332 0.295192 0.135693C0.199024 0.200553 0.124374 0.292608 0.0807784 0.4001C0.0371825 0.507591 0.02662 0.625639 0.0504402 0.739163C0.0742603 0.852687 0.131382 0.956533 0.214507 1.03744L4.17707 5L0.214507 8.96257C0.158523 9.01632 0.113826 9.0807 0.0830344 9.15194C0.052243 9.22318 0.0359761 9.29985 0.0351865 9.37746C0.0343968 9.45507 0.0491 9.53205 0.0784354 9.60391C0.107771 9.67576 0.151148 9.74104 0.206028 9.79592C0.260907 9.8508 0.326185 9.89417 0.398039 9.92351C0.469892 9.95284 0.546877 9.96755 0.624484 9.96676C0.702091 9.96597 0.77876 9.9497 0.850002 9.91891C0.921244 9.88812 0.985626 9.84342 1.03938 9.78744L5.00194 5.82487L8.96451 9.78744C9.01826 9.84342 9.08264 9.88812 9.15388 9.91891C9.22512 9.9497 9.30179 9.96597 9.3794 9.96676C9.45701 9.96755 9.53399 9.95285 9.60585 9.92351C9.6777 9.89418 9.74298 9.8508 9.79786 9.79592C9.85274 9.74104 9.89611 9.67576 9.92545 9.60391C9.95478 9.53205 9.96949 9.45507 9.9687 9.37746C9.96791 9.29985 9.95164 9.22318 9.92085 9.15194C9.89006 9.0807 9.84536 9.01632 9.78938 8.96257L5.82681 5L9.78938 1.03744C9.87426 0.956041 9.93256 0.850894 9.95664 0.735783C9.98071 0.620672 9.96944 0.500972 9.92429 0.392382C9.87914 0.283792 9.80223 0.191382 9.70364 0.127271C9.60505 0.0631604 9.48938 0.0303427 9.37181 0.033122Z"
								fill="#5B5B5B"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	)
}

class EleftaTableConfiguration extends React.Component {
	constructor() {
		super()
		this.state = {
			columns: [],
		}
	}

	componentDidMount() {
		const props = this.props
		const {columns} = props

		this.setState({columns: columns})
	}
	componentDidUpdate(oldProps) {
		if (this.props.columns !== oldProps.columns) {
			this.setState({columns: this.props.columns})
		}
	}

	handleFreezingColumn = (index, status) => {
		const {columns} = this.state

		let newColumns = columns.map(column => {
			if (column.id === index) column.freezed = status.target.checked
			return column
		})

		if (newColumns.filter(column => column.freezed).length > 4) {
			status.target.checked = false
			newColumns = columns.map(column => {
				if (column.id === index) column.freezed = status.target.checked
				return column
			})

			this.props.addToast({
				title: 'Columns Configuration',
				text: 'Only maximum of 4 columns are allowed to be frozen',
				type: 'error',
			})
		}

		this.setState({columns: newColumns}, () => {
			if (isFunction(this.props.columnsUpdate)) this.props.columnsUpdate(newColumns)
		})
	}

	handleMoveTableColumn = (dragIndex, hoverIndex) => {
		const {columns} = this.state
		const newColumns = update(
			columns,
			{
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, columns[dragIndex]],
				],
			},
			() => {
				//Do nothing.
			}
		)
		this.setState({columns: newColumns}, () => {
			if (isFunction(this.props.columnsUpdate)) this.props.columnsUpdate(newColumns)
		})
	}

	handleDeleteTableColumn = index => {
		const {columns} = this.state
		const newColumns = columns.map(column => {
			if (column.id === index) column.enabled = false
			return column
		})

		this.setState({columns: newColumns}, () => {
			if (isFunction(this.props.columnsUpdate)) this.props.columnsUpdate(newColumns)
		})
	}

	render() {
		const state = this.state
		const {columns} = state

		return (
			<React.Fragment>
				<div className="w-full">
					{columns.map((column, index) => {
						if (!column.enabled) return <React.Fragment key={column.key} />

						return (
							<TableColumn
								id={column.id}
								key={column.key}
								index={index}
								column={column}
								moveTableColumn={this.handleMoveTableColumn}
								deleteTableColumn={this.handleDeleteTableColumn}
								freezTableColumn={this.handleFreezingColumn}
							/>
						)
					})}
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(EleftaTableConfiguration)
