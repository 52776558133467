import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'

const ContactCartDialog = ({ logo, space, open, setOpen, contactCardData, isMobile }) => {
    return (
        <Dialog
            open={open}
            onClose={setOpen}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '0px',
                    border: isMobile ? '1px solid #000' : '1px solid #2C79E0',
                    minWidth: isMobile ? '100%' :'534px',
                    margin: isMobile ? '0px' : 'auto',
                    minHeight: '231px',
                    position: 'relative',
                    bottom: isMobile ? '100px' : '0px',
                },
            }}
        >
            <DialogContent
                sx={{
                    padding: '0px',
                    border: isMobile ? '1px solid #000' : '1px solid #2C79E0',
                    '& .MuiDialogContent-root': {
                        padding: '0px',
                    }
                }}
            >
                <IconButton
                    edge="end"
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: '14px',
                        top: '0px',
                        padding: '12px',
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28" fill="none">
                        <path d="M20.8048 4.25691C20.5272 4.26407 20.2635 4.3909 20.0713 4.6097L12.9358 12.4L5.80028 4.6097C5.70227 4.4997 5.58504 4.4123 5.45553 4.35266C5.32602 4.29303 5.18687 4.26237 5.04631 4.26251C4.83743 4.26279 4.63337 4.33105 4.4602 4.45857C4.28703 4.58608 4.1526 4.76706 4.0741 4.97839C3.99559 5.18971 3.97657 5.42179 4.01947 5.64498C4.06236 5.86817 4.16522 6.07233 4.31491 6.23138L11.4504 14.0217L4.31491 21.8121C4.21409 21.9178 4.13361 22.0443 4.07816 22.1844C4.02271 22.3244 3.99342 22.4752 3.992 22.6278C3.99058 22.7803 4.01705 22.9317 4.06988 23.0729C4.1227 23.2142 4.20081 23.3425 4.29964 23.4504C4.39846 23.5583 4.51601 23.6436 4.6454 23.7013C4.77479 23.759 4.91342 23.7879 5.05317 23.7863C5.19292 23.7848 5.33098 23.7528 5.45927 23.6922C5.58755 23.6317 5.70349 23.5438 5.80028 23.4338L12.9358 15.6434L20.0713 23.4338C20.1681 23.5438 20.2841 23.6317 20.4124 23.6922C20.5406 23.7528 20.6787 23.7848 20.8185 23.7863C20.9582 23.7879 21.0968 23.759 21.2262 23.7013C21.3556 23.6436 21.4732 23.5583 21.572 23.4504C21.6708 23.3425 21.7489 23.2142 21.8017 23.0729C21.8546 22.9317 21.8811 22.7803 21.8796 22.6278C21.8782 22.4752 21.8489 22.3244 21.7935 22.1844C21.738 22.0443 21.6575 21.9178 21.5567 21.8121L14.4212 14.0217L21.5567 6.23138C21.7096 6.07136 21.8146 5.86464 21.8579 5.63833C21.9013 5.41203 21.881 5.1767 21.7997 4.96321C21.7184 4.74973 21.5799 4.56805 21.4023 4.44201C21.2248 4.31597 21.0165 4.25145 20.8048 4.25691Z" fill="black"/>
                    </svg>
                </IconButton>
                <div className="mt-[15px] ml-[20px] mr-[35px]">
                    <div className="flex items-start space-x-3 w-full">
                        {logo?.url &&
                            <div className="flex-grow-0 shrink-0 basis-[85px] h-[85px] w-[85px] md:basis-[116px] md:w-[116px] md:h-[116px]">
                                <img src={logo?.url} alt="logo" className="w-auto h-auto max-h-[85px] max-w-[85px] md:max-h-[116px] md:max-w-[116px] object-cover" />
                            </div>
                        }
                        <div className="w-full">
                            <div className="text-black font-bold text-[25px] md:text-[40px] mt-[20px] md:mt-[15px] mb-[10px]">{contactCardData?.store_company_name}</div>
                            <div className="flex flex-col mt-3 text-[#5D6E81]">
                                <div className="flex gap-x-[12px] md:gap-x-[24px] items-start mb-[15px] md:mb-[22px] text-[13px] md:text-[20px] font-normal">
                                    {
                                        contactCardData?.address && (
                                            <>
                                                <img src="/Images/location.png" alt=""/>
                                                <div className="ml-2">
                                                    {contactCardData?.address}
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="flex items-center gap-x-[12px] md:gap-x-[24px] mb-[15px] md:mb-[22px] text-[13px] md:text-[20px] font-normal">
                                    {
                                        contactCardData?.phone && (
                                            <React.Fragment>
                                                <img src="/Images/phone.png" alt="" />
                                                <div className="ml-2">
                                                    {contactCardData?.phone}
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                                <div className="flex items-center gap-x-[12px] md:gap-x-[24px] mb-[15px] md:mb-[22px] text-[13px] md:text-[20px] font-normal">
                                    {
                                        contactCardData?.email && (
                                            <React.Fragment>
                                                <img src="/Images/mail.png" alt="" />
                                                <div className="ml-2">
                                                    {contactCardData?.email}
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ContactCartDialog
