import React, { useEffect, useState } from 'react'
import {connect, useSelector} from 'react-redux'
import { Globals } from '../../../Context'

import Classnames from 'classnames'

import BuildItemField from './BuildItemField'
import findIndex from 'lodash/findIndex'
import isFunction from 'lodash/isFunction'
import head from 'lodash/head'

const ContentDialog = (props) => {
	const { globalColumnConfigs } = props

	const [visibleFields, setVisibleFields] = useState([])
    const { handleSubmit, data, handlePreview, openGalleryItems } = props
    const [openMarkUpPrice, setOpenMarkUpPrice] = useState(false)
    const [formData, setFormData] =useState(data)
    const [buildDatas, setBuildDatas] = useState([])
    const spaceData = useSelector(state => state.currentSpace)
    console.log("spaceData")
    console.log(spaceData)
    useEffect(() => {
        setVisibleFields(globalColumnConfigs.filter(item => item.visible).map(item => item.key))

        setBuildDatas([
            {
                label: "SKU",
                key: "sku",
                visible: globalColumnConfigs.find(item => item.key === 'sku').visible
            },
            {
                label: "Price",
                key: "wholesale_price",
                visible: globalColumnConfigs.find(item => item.key === 'wholesale_price').visible
            },
            {
                label: "Reference Number",
                key: "reference_number",
                visible: globalColumnConfigs.find(item => item.key === 'reference_number').visible
            },
            {
                label: "Brand",
                key: "brand",
                visible: globalColumnConfigs.find(item => item.key === 'brand').visible
            },
            {
                label: "Series",
                key: "series",
                visible: globalColumnConfigs.find(item => item.key === 'series').visible
            },
            {
                label: "Serial Number",
                key: "serial_number",
                visible: globalColumnConfigs.find(item => item.key === 'serial_number').visible
            },
            {
                label: "Condition",
                key: "condition",
                visible: globalColumnConfigs.find(item => item.key === 'condition').visible
            },
            {
                label: "Dial",
                key: "dial",
                visible: globalColumnConfigs.find(item => item.key === 'dial').visible
            },
            {
                label: "Bezel",
                key: "bezel",
                visible: globalColumnConfigs.find(item => item.key === 'bezel').visible
            },
            {
                label: "Bracelet",
                key: "bracelet",
                visible: globalColumnConfigs.find(item => item.key === 'bracelet').visible
            },
            {
                label: "Warranty Paper",
                key: "warranty_papers",
                visible: globalColumnConfigs.find(item => item.key === 'warranty_papers').visible
            },
            {
                label: "Warranty Date",
                key: "warranty",
                visible: globalColumnConfigs.find(item => item.key === 'warranty').visible
            },
            {
                label: "Box",
                key: "box",
                visible: globalColumnConfigs.find(item => item.key === 'box').visible
            },
            {
                label: "Link Count",
                key: "link_count",
                visible: globalColumnConfigs.find(item => item.key === 'link_count').visible
            },
            {
                label: "Additional Details",
                key: "addition_details",
                visible: globalColumnConfigs.find(item => item.key === 'addition_details').visible
            },
        ])
    }, [globalColumnConfigs])

    const handleVisibleField = (item, visible) => {
        const index = findIndex(buildDatas, (value) => value.key === item.key)
        if(index > 0) {
            buildDatas[index].visible = visible
            setBuildDatas(buildDatas)
        }
    }

    const handleViewImages = (images) => {
        if(isFunction(handlePreview)) {
            handlePreview(head(formData?.images))
        } else {
            openGalleryItems(images)
        }
    }

    return (
        <div className='overflow-hidden'>
            <div className="px-12 pt-4 space-y-6 overflow-scroll h-[calc(100vh-145px)] max-h-[857px]">
                <div className='border-[1px] pb-4'>
                    <div className='px-2 bg-[#DEDEDE] py-2 ' >
                        <div className='flex items-center h-[77px]'>
                            {spaceData?.logo?.url &&
                                <img
                                    src={spaceData?.logo?.url}
                                    className='p-1 h-auto w-auto max-h-[77px] max-w-[30%] '
                                    alt={formData?.watch_name || 'Watch'}
                                    style={{ display: 'block' }}
                                />
                            }
                            <div className='flex justify-center pl-2 items-center ml-[1px] font-semibold text-[22px] text-[#000000]'>
                                <p style={{lineHeight: 1.2}} >{spaceData?.name}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center pb-1'>
                        <div className="h-[254px] w-[246px] grid place-items-center">
                            <img
                                src={head(formData?.images)?.url ? head(formData?.images)?.url : `/watch_icon.png`}
                                className='p-1 w-auto h-auto max-h-[254px] max-w-[246px]'
                                alt={formData?.watch_name || 'Watch'}
                                style={{ display: 'block' }}
                            />
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <button className='cusor-pointer flex flex-col items-center justify-center font-semibold text-[15px] leading-[6px] text-[#000000] underline pt-3 pb-5'>
                            {formData?.images.length === 1 &&
                                <p  onClick={() => {handleViewImages(formData)}} >View Image</p>
                            }
                            {formData?.images.length > 1 &&
                                <p onClick={() => {handleViewImages(formData)}} >View More Images</p>
                            }
                        </button>
                    </div>
                    {!!buildDatas && buildDatas.map((item, index) => (
                        visibleFields.includes(item.key) &&
                        <BuildItemField key={index} item={item} {...props} openMarkUpPrice={openMarkUpPrice} handleSubmit={handleSubmit} formData={formData} handleVisibleField={handleVisibleField} setFormData={setFormData}/>
                    ))}
                </div>
            </div>
            <div className='flex justify-end h-full gap-2 p-3'>
                <div onClick={() => setOpenMarkUpPrice(!openMarkUpPrice)} className={Classnames('border-[1px] font-bold text-[16px] leading-[24px] px-[5px] py-[8px] rounded-[5px] cursor-pointer', {'bg-[#5D6E81] text-[#fff]': openMarkUpPrice})}>
                    Mark Up Price
                </div>
                <div onClick={() => handleSubmit(buildDatas, formData)} className='border-[1px] font-bold text-[16px] leading-[24px] px-[20px] py-[8px] rounded-[5px] bg-[#2C79E0] text-[#FFFFFF] cursor-pointer	'>
                    Send
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
	return {
		globalColumnConfigs: state.globalColumnConfigs,
	}
}

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(ContentDialog)
