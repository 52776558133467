import React, {useRef} from 'react'
import {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'

import {Globals} from '../../Context'
import ShareEmailDialog from './../Inventory/ShareEmailDialog'
import ShareWhatAppEmailDialog from './ShareWhatAppEmailDialog/index'
import {findOptionByCharacter} from '../../utils/keyboardHandler'
import {addToast} from '../../app/appActions'

export const ShareWhatsappEmail = React.forwardRef((props, ref) => {
	const dispatch = useDispatch()
	const [isOpen, setIsOpen] = useState(false)
	const [shareMethod, setShareMethod] = useState('email')
	const [titleHeader, setTitleHeader] = useState('')
	const [dataEmail, setDataEmail] = useState({})
	const selectButtonRef = useRef(null)
	const emailRef = useRef(null);
	const whatsappRef = useRef(null);

	//-------------------------------SHARE WhatsApp function

	React.useImperativeHandle(ref, () => (selectButtonRef.current))

	const emailDialog = flag => {
		props.handleVisibleShareEmail(flag)
	}

	const goToNextOption = (event, selectButtonRef, nextIndex, currentIndex, itemRefs) => {
		event.preventDefault();
		if (selectButtonRef.current === document.activeElement) handleOpenDropDown(true);

		if (currentIndex < itemRefs.length - 1) {
			nextIndex = currentIndex + 1;
			itemRefs[nextIndex]?.current?.focus();
		} else {
			selectButtonRef.current.focus();
		}
	}

	const goToPreviousOption = (event, currentIndex, nextIndex, itemRefs, selectButtonRef) => {
		event.preventDefault();
		if (currentIndex > 0) {
			nextIndex = currentIndex - 1;
			itemRefs[nextIndex]?.current?.focus();
		} else {
			const currentIndex = itemRefs.findIndex(ref => ref.current === document.activeElement);
			if(currentIndex > -1) {
				selectButtonRef.current.focus();
				props.handleVisibleShareWhatAppEmailDropDown(false);
			}
		}
	}

	const handleKeyDown = (event) => {
		const itemRefs = [emailRef, whatsappRef];
		const currentIndex = itemRefs.findIndex(ref => ref.current === document.activeElement);
		let nextIndex = currentIndex;
		switch (event.key) {
			case Globals.keyboardButton.arrowDown:
				goToNextOption(event, selectButtonRef, nextIndex, currentIndex, itemRefs)
				break;
			case Globals.keyboardButton.arrowUp:
				goToPreviousOption(event, currentIndex, nextIndex, itemRefs, selectButtonRef)
				break;
			case Globals.keyboardButton.escape:
				event.preventDefault();
				props.handleVisibleShareWhatAppEmailDropDown(false);
				props.handleVisibleShareEmail(false)
				props.handleVisibleDialog(false)
				break;
			default:
				findOptionByCharacter(event, itemRefs, false)
				break;
		}
	};

	const handleOpenDropDown = (isVisible) => {
		props.handleVisibleShareWhatAppEmailDropDown(isVisible)
	}

	const buildMessageWhatApp = (imageUrl, data, spaceName) => {
		let string = '';

		if (data.selected_attributes.includes('reference_number') && props.data?.reference_number?.trim?.()) {
			string += `Reference Number: ${props.data.reference_number}%0A`;
		}

		if (data.selected_attributes.includes('dial') && props.data?.dial?.trim?.()) {
			string += `Dial: ${props.data.dial}%0A`;
		}

		if (data.selected_attributes.includes('bracelet') && props.data?.bracelet?.trim?.()) {
			string += `Bracelet: ${props.data.bracelet}%0A`;
		}

		if (data.selected_attributes.includes('warranty_papers') && props.data?.warranty_papers?.trim?.()) {
			const warranty_papers = props.data.warranty_papers === "no" ? "No" : "Yes";
			string += `Warranty Papers: ${warranty_papers}%0A`;
		}

		if (data.selected_attributes.includes('box') && data.watch?.box !== null) {
			const box = data.watch.box ? "Yes" : "No";
			string += `Box: ${box}%0A`;
		}

		if (data.selected_attributes.includes('wholesale_price') && data?.custom_price) {
			const price = Globals.formatCurrency(data.custom_price);
			if (price) {
				string += `Price: ${price}%0A`;
			}
		}

		return string + `Full Details: ${encodeURIComponent(imageUrl)}`;
	}

	const handleSubmit = (buildDatas, formData) => {
		let selectedAttributes = []
		buildDatas.map(value => {
			if(value.visible) {
				selectedAttributes.push(value.key)
			}
		})
		if(shareMethod === 'email') {
			setDataEmail({
				selected_attributes: selectedAttributes,
				custom_price: !!formData.wholesale_price ? Globals.revertPrice(formData.wholesale_price) : null,
			})
			props.handleVisibleShareEmail(true)
		} else {
			Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/marketplaces/${props.data.uid}/share`, {
				selected_attributes: selectedAttributes,
				custom_price: !!formData.wholesale_price ? Globals.revertPrice(formData.wholesale_price) : null,
				share_method: 'whatsapp'
			})
			.then(res => {
				const imageUrl = `${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/marketplaces/shared_watch_preview?sharing_code=${res.data.code}`
				const message = buildMessageWhatApp(imageUrl, res.data, props.currentSpace.name)

				window.open(`https://api.whatsapp.com/send/?text=${message}`, '_blank')
			})
			.catch(error => {
				dispatch(
					addToast({
						title: 'Error',
						text: error.response?.data?.message || 'Something went wrong please try again later.',
						type: 'error',
					}))
			})

		}
		props.handleVisibleDialog(false)
	}

	  useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
		  window.removeEventListener('keydown', handleKeyDown);
		};
	  }, []);

	return (
		<>
			<div className=" px-4 ">
				<div className="relative flex gap-1.5">
					<button
						ref={selectButtonRef}
						className="text-sm Button flex  items-center lg:h-1/2  bg-white px-2 py-2"
						style={{
							background: props.visibleShareWhatAppEmailDropDown && '#2C79E0',
							color: props.visibleShareWhatAppEmailDropDown && 'white',
						}}
						onClick={e => handleOpenDropDown(!props.visibleShareWhatAppEmailDropDown)}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" fill="none" viewBox="0 0 20 21" stroke="currentColor" className="mr-2">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								fill={props.visibleShareWhatAppEmailDropDown ? 'white' : '#5D6E81'}
								d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
							/>
						</svg>

						<span className="font-normal">Share</span>
						<svg className={props.visibleShareWhatAppEmailDropDown ? 'rotate-180 ml-2' : 'ml-2'} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
								fill={props.visibleShareWhatAppEmailDropDown ? 'white' : '#5D6E81'}
							/>
						</svg>
					</button>

					{props.visibleShareWhatAppEmailDropDown && (
						<div  className="absolute bg-white divide-gray-100 divide-y rounded shadow top-11 w-44 z-10 right-30">
							<ul className="py-1 text-sm text-gray-700" onClick={() => setIsOpen(!isOpen)}>
								<li>
									<button
										ref={emailRef}
										className="w-full text-left flex block py-2 px-4 hover:bg-gray-100"
										onClick={() => {
											props.handleVisibleDialog(true)
											handleOpenDropDown(!props.visibleShareWhatAppEmailDropDown)
											setTitleHeader('Send Via Email-Preview')
											setShareMethod('email')
										}}
									>
										<svg className="mr-2" fill="currentColor" width="25" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
												clipRule="evenodd"
											/>
										</svg>

										<span className="font-normal">Email</span>
									</button>
								</li>
								<li>
									<button
										ref={whatsappRef}
										className="w-full text-left flex block py-2 px-4 hover:bg-gray-100"
										onClick={e => {
											props.handleVisibleDialog(true)
											handleOpenDropDown(!props.visibleShareWhatAppEmailDropDown)
											setTitleHeader('Send Via Whatsapp-Preview')
											setShareMethod('whatapp')
										}}
									>
										<svg className="mr-2" viewBox="0 0 20 21" width="25" height="21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path d="M15.74,6.18C14.86,2.53,11.74,.04,7.96,0c-.5,0-1.02,.05-1.53,.16C1.16,1.23-1.57,7.1,1,11.83c.07,.13,.07,.23,.03,.36C.69,13.44,.35,14.69,.01,15.93c-.04,.14,.01,.19,.15,.15,1.29-.34,2.58-.68,3.87-1.02,.11-.03,.19-.02,.29,.03,1.66,.85,3.41,1.1,5.24,.75,4.37-.85,7.23-5.32,6.19-9.66h0Zm-6.66,8.38c-.36,.06-.72,.09-.91,.09-1.4,0-2.54-.32-3.6-.96-.13-.08-.25-.1-.41-.06-.71,.2-1.42,.37-2.13,.56-.15,.05-.16,.01-.13-.12,.19-.68,.36-1.35,.56-2.01,.05-.19,.03-.33-.07-.5-1.45-2.35-1.39-5.27,.23-7.44C4.32,1.86,6.62,.93,9.37,1.52c2.71,.57,4.44,2.33,5.07,5.03,.87,3.7-1.63,7.38-5.36,8Z" />
											<path d="M10.05,11.84c-.28,.01-.56-.05-.83-.13-1.83-.53-3.2-1.68-4.31-3.19-.42-.57-.8-1.16-.93-1.87-.15-.85,.09-1.57,.72-2.16,.2-.18,.81-.27,1.06-.15,.09,.05,.15,.13,.19,.21,.23,.55,.46,1.1,.69,1.65,.04,.11,.02,.21-.02,.32-.12,.25-.3,.46-.49,.66-.14,.15-.15,.28-.04,.46,.65,1.08,1.54,1.87,2.71,2.34,.17,.07,.31,.05,.42-.1,.19-.24,.4-.48,.58-.73,.13-.18,.27-.2,.45-.12,.39,.18,.77,.36,1.16,.54,.09,.04,.17,.08,.26,.12,.39,.2,.39,.2,.35,.63-.07,.85-.7,1.27-1.45,1.47-.17,.04-.34,.05-.51,.04h0Z" />
										</svg>
										<span className="font-normal">WhatsApp</span>
									</button>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>
			{props.openEmail && <ShareEmailDialog emailDialog={emailDialog} dataEmail={dataEmail} {...props} />}
			{props.openDialog &&  <ShareWhatAppEmailDialog titleHeader={titleHeader} handlePreview={props.handlePreview} handleSubmit={handleSubmit}  {...props}/>}
		</>
	)
})
