import React, { useState } from 'react'
import { plansCollection } from '../utils/collections'
import PricingCard from '../Pages/PricingCard'
import classNames from 'classnames'
import { Globals } from '../Context'

const StripePlansModal = props => {
  const { setIsOpen, space } = props
  const [billingPeriod, setBillingPeriod] = useState('monthly')

  const handleChangePlan = (event, plan) => {
    Globals.New_Axios().post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${space.id}/internal_subscriptions/generate_checkout_link`, {
      plan_type: plan.value,
      billing_period: billingPeriod,
    }).then(response => {
      const url = response.data.checkout_url
      window.open(url, '_blank')
    })
  }

  return (
    <div className='w-full h-full fixed z-index-50 bg-white'>
      <div className="flex-grow">
        <div className="relative grid grid-cols-[1fr_auto_1fr] px-6 py-4 md:pb-10 md:pt-[4.5rem]">
          <div />
          <div className="my-1 flex flex-col items-center justify-center md:mb-0 md:mt-0">
            <div className="text-2xl font-semibold md:text-3xl">
              Upgrade Your Plan
            </div>
          </div>
          <button className="justify-self-end text-token-text-primary opacity-50 transition hover:opacity-75 md:absolute md:right-6 md:top-6" onClick={() => setIsOpen(false)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon-lg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.63603 5.63604C6.02656 5.24552 6.65972 5.24552 7.05025 5.63604L12 10.5858L16.9497 5.63604C17.3403 5.24552 17.9734 5.24552 18.364 5.63604C18.7545 6.02657 18.7545 6.65973 18.364 7.05025L13.4142 12L18.364 16.9497C18.7545 17.3403 18.7545 17.9734 18.364 18.364C17.9734 18.7545 17.3403 18.7545 16.9497 18.364L12 13.4142L7.05025 18.364C6.65972 18.7545 6.02656 18.7545 5.63603 18.364C5.24551 17.9734 5.24551 17.3403 5.63603 16.9497L10.5858 12L5.63603 7.05025C5.24551 6.65973 5.24551 6.02657 5.63603 5.63604Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>

        <div className="flex items-center justify-center pb-8">
          <div className="flex border-2 border-[#e0c789] rounded-full">
            <div
              className={classNames('px-6 py-3 rounded-full transition-colors duration-300 cursor-pointer', {
                'bg-[#5D6E81] text-white cursor-pointer': billingPeriod === 'monthly',
                'bg-transparent text-black cursor-pointer': billingPeriod !== 'monthly',
              })}
              onClick={() => {
                setBillingPeriod('monthly')
              }}
            >
              Monthly Billing
            </div>
            <div
              className={classNames('px-6 py-3 rounded-full transition-colors duration-300 cursor-pointer relative', {
                'bg-[#5D6E81] text-white cursor-pointer': billingPeriod === 'yearly',
                'bg-transparent text-black cursor-pointer': billingPeriod !== 'yearly',
              })}
              onClick={() => {
                setBillingPeriod('yearly')
              }}
            >
              Yearly Billing
              <div className="absolute z-[999] w-full text-center left-0 bottom-[-12px]">
                <span className="bg-[#ffc800] pl-[10px] pr-[10px] rounded-[10px] font-[13px]">Save 20%</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center gap-4 px-3 py-3 md:min-h-[30rem] md:flex-row md:gap-0 md:py-0">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10">
            {plansCollection.filter((plan) => plan.value !== "starter").map((plan, index) => (
              <PricingCard
                key={index}
                plan={plan}
                onClick={() => {
                  updateFormData({
                    ...formData,
                    membership: plan.value,
                  })
                }}
                handleChangePlan={handleChangePlan}
                billingPeriod={billingPeriod}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StripePlansModal