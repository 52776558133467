import {useEffect, useMemo, useState} from 'react'

const useMobileDevice = () => {
	const [isMobile, setIsMobile] = useState(null)
	useEffect(() => {
		const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches
		setIsMobile(isMobile)
	}, [])

	const platform = useMemo(() => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera
		if (/android/i.test(userAgent)) {
			return 'Android'
		}

		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS'
		}
	}, [])

	return useMemo(
		() => ({
			isMobile,
			platform,
		}),
		[isMobile, platform]
	)
}

export default useMobileDevice
