import React, {useEffect, useRef, useState} from 'react';
import Classnames from 'classnames';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useOutsideClick from '../../hooks/useOutsideClick';
import { getConditionLabel } from '../../utils/LabelsHelper'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import filter from 'lodash/filter'
import indexOf from 'lodash/indexOf'

const defaultFiltersConfigData = [
    { id: 'brand', label: 'Brand', options: [] },
    { id: 'reference_number', label: 'Reference Number', options: [] },
    { id: 'series', label: 'Series', options: [] },
    { id: 'condition', label: 'Condition', options: ['Unworn', 'Like New', 'Pre-Owned'] },
    { id: 'box_papers', label: 'Box & Papers', options: ['None', 'Box', 'Papers', 'Box & Papers'] }
];

const FilterOption = React.memo(({ item, selected, onSelect, viewGrid }) => (
  <div
    className={Classnames('flex justify-between items-center px-3 py-1 cursor-pointer text-[14px] hover:bg-slate-100', {
      'text-[#2C79E0]': selected
    })}
    onClick={() => onSelect(item)}
  >
    <span className={Classnames({'text-[#5D6E81]': !viewGrid})}>{getConditionLabel(item)}</span>
    {selected && <CheckIcon sx={{ color: "#2C79E0", fontSize: '18px', fontWeight: '700' }} />}
  </div>
));

const FilterDropdown = ({ id, label, options, filterDataSelected, onChangeFilterData, currentDropdownFilter, setCurrentDropdownFilter, viewType }) => {
  const isActive = currentDropdownFilter === id;
  const isFiltering = filterDataSelected[id].length > 0;
  const viewGrid = viewType !== 'card';
  const dropdownRef = useRef(null);
  const [keySearchInDropdown, setKeySearchInDropdown] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)

  useOutsideClick(dropdownRef, () => {
    if (isActive) {
      setCurrentDropdownFilter('');
      setKeySearchInDropdown('')
    }
  });

  const handleSearchInFilter = (e) => {
    setKeySearchInDropdown(e.target.value)
  }

  const handleSelectOption = (option) => {
    onChangeFilterData(id, option);
    setCurrentDropdownFilter('');
  };

  useEffect(() => {
    if(keySearchInDropdown === '') {
      setFilteredOptions(options)
      return
    } else {
      setFilteredOptions(options.filter(option => option?.toLowerCase().includes(keySearchInDropdown?.toLowerCase())))
    }

  } , [keySearchInDropdown, options])

  return (
    <div className="relative min-w-[158px] w-auto max-w-[185px] 2xl:max-w-[unset] 2xl:w-2/12" ref={dropdownRef}>
      <div
        className={Classnames('cursor-pointer text-sm border h-12 rounded flex justify-between items-center p-3', {
          'bg-[#2C79E0] text-white border-[#2C79E0]': isActive,
          'bg-transparent text-[#5D6E81]': !isActive && viewGrid,
          'border-[#2C79E0] bg-[#fff]': isFiltering && !isActive,
          'border-[#5D6E81] bg-[#fff] text-[#5D6E81]': !isFiltering && !isActive && !viewGrid
        })}
        onClick={() => setCurrentDropdownFilter(isActive ? '' : id)}
      >
        <span
           className={Classnames('', {
            'text-[#2C79E0]': filterDataSelected[id].length > 0,
            'bg-[#2C79E0] text-white': isActive,
          })}
        >{label}</span>
        <ExpandMoreIcon  className={Classnames('', {
            'text-[#2C79E0]': filterDataSelected[id].length > 0,
            'bg-[#2C79E0] text-white': isActive,
          })}/>
      </div>
      {isActive && (
        <div className={Classnames("absolute lg:w-[120%] right-0 bg-white border border-1 rounded-[5px] z-10", {'border-[1px] border-[#2C79E0]':  !viewGrid})}>
          <div className={Classnames("relative", {'border-b-[1px] border-b-[#2C79E0]': !viewGrid})}>
            <SearchIcon className={Classnames("absolute top-1/2 left-3 transform -translate-y-1/2 rotate-90", {'text-[#5D6E81]':  !viewGrid})} />
            <input
              type="text"
              placeholder="Search here..."
              className="w-full border border-1 h-[34px] rounded-[5px] pl-10 pr-2 text-[14px]"
              onChange={handleSearchInFilter}
            />
          </div>

          <div className="max-h-[160px] overflow-y-auto">
            {filteredOptions.map((filteredOption, index) => (
              <FilterOption
                key={index}
                item={filteredOption}
                selected={filterDataSelected[id].includes(filteredOption)}
                onSelect={handleSelectOption}
                viewGrid={viewGrid}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const FilterWebStore = ({ onChangeFilterData, filterDataSelected, currentDropdownFilter, setCurrentDropdownFilter, keySearch, onSearch, onClearSearch, filterData, viewType, firstLoadFilterData, filterPriorities, visibleFields}) => {
  const [filtersConfigData, setFiltersConfigData] = useState(defaultFiltersConfigData);

  const handleChangeFilters = (id, selectedValue) => {
    onChangeFilterData(id, selectedValue);
  }

  useEffect(() => {
    if (filterData) {
      let previousFilterData = cloneDeep(firstLoadFilterData)
      let newFiltersConfigData = cloneDeep(filtersConfigData)

      filterPriorities.forEach((filterItem) => {
          const options = cleanupOptions(filterItem, previousFilterData)
          const index = findIndex(newFiltersConfigData, { id: filterItem })
          const newfilterData = newFiltersConfigData[index]
          newFiltersConfigData[index].options = options
          newFiltersConfigData[index] = newfilterData

          previousFilterData = filter(previousFilterData, (item) => {
            switch(filterItem) {
              case 'condition':
                return filterDataSelected[filterItem].includes(getConditionLabel(item))
              case 'box_papers':
                return filterDataSelected[filterItem].includes(item.box && (item.warranty_papers && item.warranty_papers.toLowerCase() !== 'no') ? 'Box & Papers' : item.box ? 'Box' : item.warranty_papers && item.warranty_papers.toLowerCase() !== 'no' ? 'Papers' : 'None')
              default:
                return filterDataSelected[filterItem].includes(item[filterItem])
            }
          })
      })

      filtersConfigData.forEach((item) => {
        if (indexOf(filterPriorities, item.id) > -1) {
          return
        }

        const index = findIndex(newFiltersConfigData, { id: item.id })
        const options = cleanupOptions(item.id, filterData)
        newFiltersConfigData[index].options = options
      })

      setFiltersConfigData(newFiltersConfigData);
    }
  }, [filterData, filterPriorities, firstLoadFilterData]);

  const cleanupOptions = (id, data) => {
    switch (id) {
      case 'condition':
        return compact(uniq(data.map(data => {
            switch (data.condition) {
              case "unworn": return "Unworn";
              case "like_new": return "Like New";
              case "pre_owned":
              case "good":
              case "very_good":
              case "excellent":
              case "fair": return "Pre-Owned";
              default: return data.condition;
            }
          }))).sort()

      case 'box_papers':
        const boxPapersOptions = [
          data.some(data => data.box && (data.warranty_papers && data.warranty_papers.toLowerCase() !== 'no') && visibleFields.includes('box') && visibleFields.includes('warranty_papers')) ? 'Box & Papers' : null,
          data.some(data => data.box && visibleFields.includes('box')) ? 'Box' : null,
          data.some(data => (data.warranty_papers && data.warranty_papers.toLowerCase() !== 'no') && visibleFields.includes('warranty_papers')) ? 'Papers' : null,
          data.some(data => (!data.warranty_papers || data.warranty_papers.toLowerCase() === 'no')  && visibleFields.includes('warranty_papers')) ? 'None' : null
        ];
        return compact(boxPapersOptions)
      default:
        return compact(uniq(data.map(data => data[id]))).sort()
    }
  }

  return (
      <div className={Classnames('flex w-full my-[15px] gap-[12px] xl:justify-center flex-wrap xl:flex-nowrap', {
        'px-20': viewType !=='card',
      })}>
          <div className="relative min-w-[155px] xl:w-[400px] shrink-1 w-auto 2xl:w-2/12">
            <input
              type="text"
              placeholder="Search here..."
              className={Classnames("w-full border border-1 h-[49px] rounded-[5px] px-3 max-w-[410px] 2xl:max-w-3/12",
                {'px-[30px] placeholder:text-[#5D6E81] border-[1px] border-[#5D6E81] placeholder:text-[14px]': viewType =='card'})}
              onChange={onSearch}
              value={keySearch}
            />
            {
              !keySearch
              ?
                <SearchIcon className={Classnames("absolute transform -translate-y-1/2 hover:text-[#2C79E0]", {'top-1/2 left-2 w-[16px] h-[16px] text-[#5D6E81]': viewType =='card', ' top-1/2 right-3': viewType !== 'card'})}/>
              :
                <CloseIcon className="absolute top-1/2 right-3 transform -translate-y-1/2 hover:text-[#2C79E0] cursor-pointer" onClick={onClearSearch} />
            }
          </div>
        {filtersConfigData.map(filter => {
            if (['condition', 'series'].includes(filter.id) && visibleFields.indexOf(filter.id) === -1) {
              return null;
            }
            if((filter.id==='box_papers') && visibleFields.indexOf('box') === -1 && visibleFields.indexOf('warranty_papers') === -1) {
              return null;
            }
          return (
              <FilterDropdown
                key={filter.id}
                id={filter.id}
                label={filter.label}
                options={filter.options}
                filterDataSelected={filterDataSelected}
                onChangeFilterData={handleChangeFilters}
                currentDropdownFilter={currentDropdownFilter}
                setCurrentDropdownFilter={setCurrentDropdownFilter}
                viewType={viewType}
              />
            )
        }
        )}
      </div>
  );
};

export default FilterWebStore;
