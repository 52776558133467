import React from 'react'
import CommentForm from './CommentForm'

const CommentModal = ({handleCloseCommentModal, handleSubmitComment, commentField, setCommentField, isAddCommentModal= true, commentForSale, handleEditSubmitComment}) => {
	const handleSubmit = (event) => {
		if(isAddCommentModal) {
			handleSubmitComment(event)
			handleCloseCommentModal()
		} else {
			handleEditSubmitComment(event, commentForSale.id)
			handleCloseCommentModal()
		}
	}

	return (
		<>
			<div className="bg-black/50 bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50 md:hidden">
				<div className="max-w-xl w-full">
					<div className="bg-[#4B7CBE] flex items-center px-8 py-4 relative rounded-t-xl text-white w-full max-h-[43px]">
						<span className="text-white font-bold text-[22px]">{isAddCommentModal ? "Add Comment" : "Edit Comment"}</span>
						<button className="absolute right-0 mr-5 cursor-pointer" onClick={handleCloseCommentModal}>
							<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
							<path d="M11.4874 14.1231L6.06906 8.51228L8.47885 6.18518L13.8972 11.796L19.8677 6.03036L22.1948 8.44015L16.2243 14.2058L21.6774 19.8526L19.2676 22.1797L13.8145 16.5329L7.80802 22.3333L5.48091 19.9235L11.4874 14.1231Z" fill="white"/>
							</svg>
						</button>
					</div>
					<div className="bg-white text-gray-500 px-8 py-4 w-full">
						<div>
							<textarea
								value={commentField.content}
								onChange={e => setCommentField({ ...commentField, content: e.target.value })}
								placeholder="write a comment"
								className="border mb-2 mt-3 p-3 resize-none rounded text-left w-full h-[120px]"
								spellCheck="false"
							/>
              <div className='items-center flex h-full'>
                <span className="__checkbox h-max" style={{transform: 'scale(.8)'}}>
                  <input
                    id={`checkbox-visible-on-pdf`}
                    type="checkbox"
                    onChange={e => setCommentField({...commentField, visible_on_pdf: e.target.checked})}
                    checked={commentField.visible_on_pdf}
                  />
                  <Tooltip title="Visible on Memo" placement="top" arrow>
                    <label htmlFor={`checkbox-visible-on-pdf`}></label>
                  </Tooltip>
                </span>
                <label htmlFor={`checkbox-visible-on-pdf`} className='text-[12px] ml-[5px] cursor-pointer'>Visible on Memo</label>
              </div>
							<div className="flex flex-row mt-4 justify-center space-x-4">
								<button
									className="border-2 cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text[#000000] text-xs"
									onClick={handleCloseCommentModal}
								>
									Cancel
								</button>
								<button className="bg-blue cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text-white text-xs uppercase" onClick={(e) => handleSubmit(e)} type="submit">
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CommentModal
