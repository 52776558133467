import {useCallback, useMemo, useState} from 'react'
import {Globals} from '../Context'
import {useDispatch} from 'react-redux'
import {addToast, setCurrentSpace} from '../app/appActions'
import {mockedQrData} from '../Components/Account/settings/QRCode/qrcodeConsts'

const useQRCodeSettings = () => {
	const [loading, setLoading] = useState({})
	const [stickerSettings, setStickerSettings] = useState(null)
	const [error, setError] = useState(null)
	const dispatch = useDispatch()

	const updateSpaceQRType = useCallback(async data => {
		setLoading(current => ({...current, updateSpaceQRType: true}))
		Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}`, data)
			.then(response => {
				if (response.status === 200 || response.status === 201) {
					dispatch(setCurrentSpace(response.data))
					return {success: true}
				}
			})
			.catch(e => {
				setError(e.message)
				dispatch(addToast({type: 'error', text1: e.message, autoHide: true}))
				return {error: true}
			})
			.finally(() => {
				setLoading(current => ({...current, updateSpaceQRType: false}))
			})
	}, [])

	const updateQRSettingsField = useCallback(async configField => {
		setLoading(current => ({...current, updateSpaceQRType: true}))
		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/qr_settings/${configField.id}`, configField)
			.then(response => {
				if (response.status === 200 || response.status === 201) {
					return {success: true}
				}
			})
			.catch(e => {
				setError(e.message)
				dispatch(addToast({type: 'error', text: e.message, autoHide: true}))
				return {error: true}
			})
			.finally(() => {
				setLoading(current => ({...current, updateSpaceQRType: false}))
			})
	}, [])

	const getStickerSettings = useCallback(async data => {
		setLoading(current => ({...current, getStickerSettings: true}))
		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/qr_settings?q[qr_type_eq]=1`)
			.then(async response => {
				const updatedPrinterSettings = response.data?.qr_settings.map(item => {
					if (item.key === "printer_settings") {

						return {...item, value: JSON.parse(item.value)}
					}
					return item
				})
				setStickerSettings(updatedPrinterSettings || [])
			})
			.catch(error => {
				console.log(error)
			})
			.finally(() => {
				setLoading(current => ({...current, getStickerSettings: false}))
			})
	}, [])

	return useMemo(
		() => ({
			loading,
			updateSpaceQRType,
			error,
			getStickerSettings,
			stickerSettings,
			updateQRSettingsField,
		}),
		[loading, updateSpaceQRType, getStickerSettings, stickerSettings, error, updateQRSettingsField]
	)
}

export default useQRCodeSettings
