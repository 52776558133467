import Classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {removeToast} from './../../app/appActions'
import isFunction from 'lodash/isFunction'

const notificationIcons = (
	<div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-blue-500 bg-blue-100 rounded-lg">
		<svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
		</svg>
	</div>
)
const successfullIcons = (
	<div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-500 bg-green-100 rounded-lg">
		{' '}
		<svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
		</svg>
	</div>
)
const errorIcons = (
	<div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500 bg-red-100 rounded-lg">
		{' '}
		<svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
				clipRule="evenodd"
			></path>
		</svg>
	</div>
)
const warningIcons = (
	<div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-orange-500 bg-orange-100 rounded-lg">
		{' '}
		<svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
				clipRule="evenodd"
			></path>
		</svg>
	</div>
)
const messagesIcon = (
	<div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-blue-500 bg-blue-100 rounded-lg">
		<svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6 7H6.01M10 7H10.01M14 7H14.01M7 13H3C1.89543 13 1 12.1046 1 11V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V11C19 12.1046 18.1046 13 17 13H12L7 18V13Z"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	</div>
)

class Toast extends React.Component {
	constructor() {
		super()
		this.state = {
			isVisible: false,
			isHover: false,
		}
	}

	componentDidMount() {
		// this will animate the toast.
		setTimeout(() => {
			this.setState({
				isVisible: true,
			})
		}, 100)

		this.autoDismiss()
	}

	autoDismiss = () => {
		// this will dismiss the toast after 8 seconds.
		setTimeout(() => {
			// if user mouse is over the toast then wait.
			if (this.state.isHover) return this.autoDismiss()

			this.setState(
				{
					isVisible: false,
				},
				() => {
					setTimeout(this.props.onDismissClick, 600)
				}
			)
		}, this.props.duration)
	}

	forceDismiss = () => {
		this.setState(
			{
				isVisible: false,
			},
			() => {
				setTimeout(this.props.onDismissClick, 600)
			}
		)
	}

	handleClickToasts = () => {
		if (isFunction(this.props.onClick)) this.props.onClick()
	}

	render() {
		const state = this.state
		const props = this.props
		const notificationType = props.type
		const iconSwitch = () => {
			switch (notificationType) {
				case 'success':
					return successfullIcons
				case 'error':
					return errorIcons
				case 'warning':
					return warningIcons
				case 'messages':
					return messagesIcon
				default:
					return notificationIcons
			}
		}

		return (
			<React.Fragment>
				<div
					onMouseEnter={() => this.setState({isHover: true})}
					onMouseLeave={() => this.setState({isHover: false})}
					className={Classnames(
						{'translate-x-full opacity-0': !state.isVisible},
						' duration-500 transition flex items-start m-2 p-4 mb-0 w-fit max-w-md text-gray-500 bg-white rounded-lg shadow shadow-md'
					)}
					role="alert"
				>
					{iconSwitch()}
					<div className="ml-3 text-sm font-normal" onClick={this.handleClickToasts}>
						{props.title && <span className="mb-1 text-sm font-semibold text-gray-900">{props.title}</span>}
						{props.text && notificationType === "messages" ?
							<p className="line-clamp-1 toast-text-message">
								{props.text}
							</p> :
							<div>{props.text}</div>}
					</div>
					<button
						onClick={this.forceDismiss}
						type="button"
						className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-1 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8"
						aria-label="Close"
					>
						<span className="sr-only">Close</span>
						<svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								clipRule="evenodd"
							></path>
						</svg>
					</button>
				</div>
			</React.Fragment>
		)
	}

	// shouldComponentUpdate() {
	// 	return false;
	// }
}

Toast.propTypes = {
	onDismissClick: PropTypes.func.isRequired,
	title: PropTypes.string,
	text: PropTypes.any,
	type: PropTypes.string,
	onClick: PropTypes.func,
	duration: PropTypes.number,
}

Toast.defaultProps = {
	duration: 3000,
}

const Toasts = ({actions, toasts}) => {
	const {removeToast} = actions
	return (
		<div className="elefta-toast fixed max-h-screen overflow-y-scroll right-0 top-0 flex flex-col items-end pb-1.5">
			{toasts.map(toast => {
				const {id} = toast
				return <Toast {...toast} key={id} onDismissClick={() => removeToast(id)} />
			})}
		</div>
	)
}

Toasts.propTypes = {
	actions: PropTypes.shape({
		removeToast: PropTypes.func.isRequired,
	}).isRequired,
	toasts: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({removeToast}, dispatch),
})

const mapStateToProps = state => ({
	toasts: state.toasts,
})

export default connect(mapStateToProps, mapDispatchToProps)(Toasts)
