export const statusesCollection = []

export const inventoryConditionsCollection = [
	{option: 'Unworn', value: 'unworn'},
  {option: 'Pre-Owned', value: 'pre_owned'},
	{option: 'Like New', value: 'like_new'},
	{option: 'Excellent', value: 'excellent'},
	{option: 'Very Good', value: 'very_good'},
	{option: 'Good', value: 'good'},
	{option: 'Fair', value: 'fair'},
]

export const inventoryStatusesCollection = [
	{value: 'on_hand', option: 'On Hand'},
	{value: 'transit_from', option: 'Transit From'},
	{value: 'memo_to', option: 'Memo to'},
	{value: 'on_hold_for', option: 'On Hold for'},
	{value: 'pending_sale_to', option: 'Pending Sale to'},
	{value: 'sold_to', option: 'Sold to'},
	{value: 'repair_with', option: 'Repair with'},
	{value: 'other', option: 'Other'},
]


export const plansCollection = [
    {
        title: 'Starter',
        price: {
            monthly: {
                value: 'Free',
                subtitle: "1 Month Free",
            },
            yearly: {
                value: 'Free',
                subtitle: "1 Month Free",
            },
            full_amount: {
                value: '',
            }
        },
        value: 'starter',
        descriptions: [
            'Full Access',
            'No CC required'
        ]
    },
    {
        title: 'Performance',
        price: {
            monthly: {
                subtitle: "Per Month, Billed Monthly",
                value: '$99',
            },
            yearly: {
                subtitle: "Per Month, Billed Annually",
                value: '$79',
            },
            full_amount: {
                value: '$948',
            }
        },
        value:'performance',
        descriptions: [
            'Marketplace Access',
            'Unlimited Inquries',
            'Access to Mobile App',
            'Sharing Capabilities',
            'Concierge Service',
            'Unlimited Users',
        ]
    },
	{
        title: 'Platinum',
        price: {
            monthly: {
                subtitle: "Per Month, Billed Monthly",
                value: '$149',
            },
            yearly: {
                subtitle: "Per Month, Billed Annually",
                value: '$119',
            },
            full_amount: {
                value: '$1,428',
            }
        },
        value: 'platinum',
        descriptions: [
            'Marketplace Access',
            'Unlimited Inquries',
            'Access to Mobile App',
            'Sharing Capabilities',
            'Concierge Service',
            'Unlimited Users',
            'Add and Manage Your Inventory',
            'Unlimited Memos and Invoices',
            'Source and Sell Through the Marketplace',
            'White Glove Service',
            'Business Analytics',
        ]
    },
];