import React, {useState, useRef, useEffect} from 'react'
import Dialog from '@mui/material/Dialog';
import Classnames from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import classNames from 'classnames';

import * as collections from '../../utils/collections'
import { toYesNo } from '../../utils/LabelsHelper'
import {Globals} from '../../Context'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import useOutsideClick from '../../../src/hooks/useOutsideClick';
import find from 'lodash/find'
import lowerCase from 'lodash/lowerCase'

const Info = ({activeDataItem, column , watchCondition, watchWarrantyPapers}) => {
    const textRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
      let { scrollWidth, clientWidth } = textRef.current;
      if(clientWidth < 154) {
        clientWidth = 154
      }
      setIsTruncated(scrollWidth > clientWidth);
    }, [activeDataItem]);

    const renderText = () => {
        if(column === 'serial_number') {
           return  activeDataItem?.serial_number ? `${activeDataItem?.serial_number.substring(0,2)}XXXXX` : ''
        } else if(column === 'condition') {
            return watchCondition?.option
        } else if(column === 'warranty_papers') {
            return !!watchWarrantyPapers?.option ? watchWarrantyPapers?.option : ''
        }
        else if(column === 'box') {
            return toYesNo(activeDataItem[column])
        } else {
            return activeDataItem[column]
        }
    }

	return (
        !isTruncated ?
        <span ref={textRef} className={'font-semibold truncate mt-3'}>
            {renderText()}
        </span>
        :
        <Tooltip title={renderText()} placement="bottom" arrow>
            <span ref={textRef} className={'font-semibold truncate mt-3 cursor-pointer'}>
                {renderText()}
            </span>
        </Tooltip>
	)
}

const DetailWebStoreCard = ({visibleItem,
    closeItemDetail,
    activeDataItem,
    webStoreSetting,
    openGalleryItemCardView,
    activeGalleryItem,
    visibleGallery,
    visibleFields
}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [imageIndex, setImageIndex] = useState(0);
	const [isAtBeginning, setIsAtBeginning] = useState(true);
    const watchCondition = find(collections.inventoryConditionsCollection, {value: activeDataItem?.condition})
    const warrantyCardsList = Globals.warrantyCardsList[lowerCase(activeDataItem?.brand)] || Globals.warrantyCardsList.other
    const watchWarrantyPapers = find(warrantyCardsList, {value: activeDataItem?.warranty_papers})

    const swiperRef = useRef(null);
    const handleKeyDown = (event) => {
        if(!visibleGallery) {
            if (swiperRef.current && swiperRef.current.swiper) {
                if (event.key === 'ArrowRight') {
                swiperRef.current.swiper.slideNext();
                } else if (event.key === 'ArrowLeft') {
                swiperRef.current.swiper.slidePrev();
                }
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
        document.removeEventListener('keydown', handleKeyDown);
        };
    }, [visibleGallery]);

    const handleClose = () => {
        closeItemDetail()
        setThumbsSwiper(null)
        setIsAtBeginning(true)
        setImageIndex(0)
    }

    const dialogRef = useRef(null);

    useOutsideClick(dialogRef, () => {
        if(!visibleGallery) {
            handleClose()
        }
    });

    const renderWarranty = () => {
        if(activeDataItem?.display_warranty) {
            return activeDataItem?.display_warranty
        } else if (activeDataItem?.warranty ) {
            return moment(activeDataItem.warranty).format('MM/DD/YYYY')
        }
        return ''
    }

    return (
        <>
            <Dialog
                fullScreen
                open={visibleItem}
                classes={{ paper: 'transparent-dialog' }}
                onKeyUp={e => {
                    if (e.key === 'Escape') handleClose()
                }}
            >
                <div className="bg-[#90909080] h-full w-full p-10 ">
                    <div className="h-full w-full relative">
                        {activeDataItem && (
                            <div className="h-full w-full p-10 flex items-center justify-center">
                                <div ref={dialogRef} className="relative bg-white max-h-[710px] 2xl:max-h-[730px] max-w-[1087px] border-[1px] border-black flex gap-[24px] py-[26px] px-[50px] overflow-y-scroll">
                                    {webStoreSetting.images_visible &&
                                        <div className="h-full w-1/2 flex flex-col">
                                            <div className={Classnames('h-full w-full', {'flex items-center': activeGalleryItem.length == 0})}>
                                                <div className="relative h-full w-full">
                                                    <Swiper
                                                        ref={swiperRef}
                                                        navigation={{
                                                            nextEl: '.next-swiper-new-arrival',
                                                            prevEl: '.prev-swiper-new-arrival',
                                                        }}
                                                        spaceBetween={10}
                                                        className="mySwiper1"
                                                        thumbs={{ swiper: thumbsSwiper }}
                                                        modules={[FreeMode, Navigation, Thumbs]}
                                                        autoHeight={true}
                                                        slidesPerView={1}
                                                        onSlideChange={(swiper) => {
                                                            setImageIndex(swiper.activeIndex)
                                                            setIsAtBeginning(swiper.isBeginning);
                                                        }}
                                                    >
                                                        {activeGalleryItem.length > 0 ?
                                                        activeGalleryItem.map((image, index) => (
                                                            <SwiperSlide key={index}>
                                                            <div
                                                                className="flex flex-col cursor-pointer max-w-[494px] h-[520px] items-center justify-center"
                                                            >
                                                                {image.type.includes('video') ? (
                                                                        <video controls className="w-full h-full">
                                                                            <source src={image.video_url} type="video/mp4" />
                                                                        </video>
                                                                    ) : (
                                                                        <img onClick={() => openGalleryItemCardView(activeDataItem, index)} key={image.url} src={image.url} alt="." className='max-h-full max-w-full'/>
                                                                    )}
                                                            </div>
                                                        </SwiperSlide>
                                                        )) :
                                                        <img src="/watch_icon.png" className="w-full h-full" alt="" />

                                                    }
                                                    </Swiper>
                                                    <div className="next-swiper-new-arrival top-1/2 right-2 absolute cursor-pointer z-[999999] rounded-full border-[1px] border-[#212121]">
                                                        <ChevronRightIcon sx={{width: '28px', height: '26px'}} />
                                                    </div>
                                                    <div
                                                        style={{ display: isAtBeginning ? 'none' : 'block' }} className="prev-swiper-new-arrival absolute cursor-pointer top-1/2 left-2  z-[999999] border-[1px] border-[#212121] rounded-full"
                                                    >
                                                        <ChevronLeftIcon sx={{width: '28px', height: '26px'}} />
                                                    </div>
                                                </div>
                                                <div className='relative h-auto w-auto px-[17px] mt-[5px]'>
                                                    <Swiper
                                                        onSwiper={setThumbsSwiper}
                                                        spaceBetween={10}
                                                        slidesPerView={4}
                                                        freeMode={true}
                                                        watchSlidesProgress={true}
                                                        modules={[FreeMode, Navigation, Thumbs]}
                                                    >
                                                        {activeGalleryItem.map((image, index) => (
                                                                <SwiperSlide key={index}>
                                                                <div className={ "flex flex-col cursor-pointer h-[97px]"}>
                                                                {image.type.includes('video') ? (
                                                                        <video controls className="w-full">
                                                                            <source src={image.video_url} type="video/mp4" />
                                                                        </video>
                                                                    ) : (
                                                                        <img key={image.url} src={image.url} alt="." className={Classnames("h-full max-w-full object-contain  absolute", {'border-[1px] border-[#5D6E81]': index === imageIndex})}/>
                                                                    )}
                                                                </div>
                                                            </SwiperSlide>
                                                            ))}
                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className={classNames("h-full flex-col", {'w-full': !webStoreSetting.images_visible,
                                         'w-1/2': webStoreSetting.images_visible
                                    })}>
                                        <div className='h-full w-full'>
                                            <div className='text-[18px] text-[#5D6E81]'>
                                                SKU: {activeDataItem?.sku}
                                                {activeDataItem?.on_hold && (
                                                    <Chip color="error" size="small" label="Reserved" className="ml-3"/>
                                                )}
                                            </div>

                                            <div className='text-[22px] text-[#5D6E81] font-semibold mt-[4px]'>{activeDataItem?.marketplace_name}</div>
                                            {visibleFields.includes('wholesale_price')  &&
                                                <div className='mt-3'>
                                                    {webStoreSetting?.price_visible &&
                                                        activeDataItem.price ? (
                                                            <span className="text-[22px] text-[#5D6E81] font-bold mt-[4px]">
                                                                {`$${activeDataItem.price}`}
                                                            </span>
                                                        ) : (
                                                            <span className=" text-[22px] text-[#5D6E81] font-bold mt-[4px]">
                                                                Contact for price
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            }
                                            <div className={ classNames('border-t-4 border-[#5D6E8130] border-b-4 mt-3', {
                                                'hidden': !visibleFields.includes('condition') && !visibleFields.includes('box') && !visibleFields.includes('warranty_papers') && !visibleFields.includes('warranty')
                                            })} >
                                                <div class="flex justify-between px-14 py-2 text-[14px] text-[#5D6E81]">
                                                    {visibleFields.includes('condition')  &&
                                                        <div className='flex flex-col'>
                                                            <span>
                                                                Condition
                                                            </span>
                                                            <span className='font-semibold'>
                                                                {watchCondition?.option}
                                                            </span>

                                                        </div>
                                                    }
                                                    {visibleFields.includes('box')  &&
                                                        <div className='flex flex-col'>
                                                            <span>
                                                                Box
                                                            </span>
                                                            <span className='font-semibold'>
                                                                {toYesNo(activeDataItem?.box)}
                                                            </span>
                                                        </div>
                                                    }
                                                    {visibleFields.includes('warranty_papers')  &&
                                                        <div className='flex flex-col'>
                                                            <span>
                                                                Papers
                                                            </span>
                                                            <span className='font-semibold'>
                                                                {toYesNo(activeDataItem?.papers)}
                                                            </span>

                                                        </div>
                                                    }
                                                    {visibleFields.includes('warranty')  &&
                                                        <div className='flex flex-col'>
                                                            <span>
                                                                Year
                                                            </span>
                                                            <span className='font-semibold'>
                                                                {activeDataItem?.warranty ?
                                                                    moment(activeDataItem?.warranty).year(): null
                                                                }
                                                            </span>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <div class="grid grid-cols-2 gap-x-20 text-[17px] text-[#5D6E81] items-center">
                                                    {visibleFields.includes('reference_number')  &&
                                                        <>
                                                            <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Ref. Number
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='reference_number'/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('brand')  &&
                                                        <>
                                                            <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Brand
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='brand'/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('serial_number')  &&
                                                        <>
                                                             <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Serial Number
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='serial_number'/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('series')  &&
                                                        <>
                                                              <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Series
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='series'/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('condition')  &&
                                                        <>
                                                           <div className='ml-8 mt-3'>
                                                                <span>
                                                                Condition
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='condition' watchCondition={watchCondition}/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('dial')  &&
                                                        <>
                                                           <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Dial
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='dial'/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('bezel')  &&
                                                        <>
                                                            <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Bezel
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='bezel'/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('bracelet')  &&
                                                        <>
                                                            <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Bracelet
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='bracelet'/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('warranty_papers')  &&
                                                        <>
                                                            <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Papers
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='warranty_papers' watchWarrantyPapers={watchWarrantyPapers}/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('warranty')  &&
                                                        <>
                                                            <div className='ml-8 mt-3'>
                                                                <span>
                                                                Warranty Date
                                                                </span>
                                                            </div>
                                                            <span className='font-semibold truncate mt-3'>
                                                                {renderWarranty()}
                                                            </span>
                                                        </>
                                                    }
                                                    {visibleFields.includes('box')  &&
                                                        <>
                                                            <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Box
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='box'/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('link_count')  &&
                                                        <>
                                                            <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Link Count
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='link_count'/>
                                                        </>
                                                    }
                                                    {visibleFields.includes('addition_details')  &&
                                                        <>
                                                            <div className='ml-8 mt-3'>
                                                                <span>
                                                                    Additional Details
                                                                </span>
                                                            </div>
                                                            <Info activeDataItem={activeDataItem} column='addition_details'/>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default DetailWebStoreCard
