import {Globals} from '../Context'
import {useState, useRef} from 'react'
import {NavLink} from 'react-router-dom'

import {addToast, logOut} from './../app/appActions'
import {store} from './../app/store'

import {useSelector, connect} from 'react-redux'
import AppNotifications from './AppNotifications'
import useOutsideClick from '../hooks/useOutsideClick'
import isUndefined from 'lodash/isUndefined'
import classNames from 'classnames'

const TopBar = props => {
	const {isOpenSideBar} = useSelector(state => state)

	const [showDropDown, setShowDropDown] = useState(false)
	const showSubItems = () => setShowDropDown(!showDropDown)
	const {profile} = store.getState()
	const dropdownRef = useRef(null)

	useOutsideClick(dropdownRef, () => setShowDropDown(false));

	const openBillingPortal = () => {
		Globals.New_Axios().post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/internal_subscriptions/billing_portal`)
			.then(response => {
				const url = response.data.url
				window.open(url, '_blank')
			}
		)
	}

	const logOutSpace = () => {
		localStorage.setItem('logoutSpace', Date.now())
		Globals.Cache.deleteCookie(Globals.USERSELECTEDSPACE)
		Globals.Cache.deleteStorage(Globals.USERSELECTEDSPACE)
		Globals.Cache.deleteCookie(Globals.Sensitive_Data_Column)
		Globals.Cache.deleteStorage(Globals.Sensitive_Data_Column)
		return window.location.reload()
	}

	const headingObj = {
		inventory: 'Inventory',
		'inventory-sold': 'Sold Inventory',
		marketplace: 'MarketPlace',
		messages: 'Messages',
		memo: 'Memos',
		Sales: 'Sales',
		customers: 'Contacts',
		dashboard: 'dashboard',
	}

	return (
		<>
			{props.currentSpace.should_show_upgrade_announcement && (
				<div target="_blank" rel="noopener noreferrer" className="fixed flex justify-center items-center w-full z-30 bg-blue-500 text-white p-2">
					<div className="center">
						<span>Your Starter plan will be expired in {props.currentSpace.internal_subscription?.countdown_to_upgrade} days. Please{" "}
						</span>
						<span
							onClick={openBillingPortal}
							className="cursor-pointer text-white underline">
							Upgrade Now
						</span>
					</div>
				</div>
			)}
			<nav className={classNames("flex flex-row fixed z-30 bgPrimary items-center justify-between w-full py-3", {"top-10": props.currentSpace.should_show_upgrade_announcement})}>
				<div className="hidden desktop-view-sidebarss flex items-center justify-start space-x-3 md:flex">
					<div className="select-none">
						<img src="/logo-trans.png" className="ml-6 relative" width="150" height="30" alt="Elefta" />
					</div>

					<button className=" md:flex text-gray-600" onClick={props.toggleSideBar}>
						<svg className="cursor-pointer w-5" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 12H9V10H0V12ZM0 7H18V5H0V7ZM0 2H18V0H0V2Z" fill="#5D6E81" />
						</svg>
					</button>
				</div>

				{/* --------------------------Mobile */}

				{!isOpenSideBar ? (
					<div className="items-center justify-start space-x-3 mobile-view-sidebar ">
						<button className="md:flex text-gray-600 ml-2" onClick={props.toggleSideBar}>
							<svg className="cursor-pointer w-5" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0 12H9V10H0V12ZM0 7H18V5H0V7ZM0 2H18V0H0V2Z" fill="#5D6E81" />
							</svg>
						</button>
						<div className="select-none font-bold text-3xl">
							<h1 className="ml-2" style={{color: '#5D6E81'}}>
								{headingObj[window.location.pathname.replace('/', '')]}
							</h1>
						</div>
					</div>
				) : (
					<div></div>
				)}

				{/* -------------------------- */}

				<div className="flex select-none ">
					<div className="flex items-center logout-button">
						<button className="hidden">
							<svg className="mx-3" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M6 7H6.01M10 7H10.01M14 7H14.01M7 13H3C1.89543 13 1 12.1046 1 11V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V11C19 12.1046 18.1046 13 17 13H12L7 18V13Z"
									stroke="#5D6E81"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						<button className="hidden">
							<svg className="mx-3" width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M1 4L8.8906 9.2604C9.5624 9.70827 10.4376 9.70827 11.1094 9.2604L19 4M3 15H17C18.1046 15 19 14.1046 19 13V3C19 1.89543 18.1046 1 17 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15Z"
									stroke="#5D6E81"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
						{['owner', 'admin', 'editor'].includes(props.currentProfile.role) &&
							<AppNotifications />
						}

						<button className="hidden">
							<svg className="mx-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM9.984 18H10C14.4167 17.9956 17.9942 14.4127 17.992 9.99602C17.9898 5.57931 14.4087 2.00002 9.992 2.00002C5.57528 2.00002 1.99421 5.57931 1.992 9.99602C1.98979 14.4127 5.56729 17.9956 9.984 18ZM11 16H9V14H11V16ZM11 13H9C8.9684 11.6977 9.64614 10.4808 10.77 9.82202C11.43 9.31602 12 8.88002 12 8.00002C12 6.89545 11.1046 6.00002 10 6.00002C8.89543 6.00002 8 6.89545 8 8.00002H6V7.91002C6.01608 6.48096 6.79333 5.16902 8.03897 4.46842C9.28461 3.76781 10.8094 3.78496 12.039 4.51342C13.2685 5.24187 14.0161 6.57096 14 8.00002C13.9284 9.07904 13.3497 10.0603 12.44 10.645C11.6177 11.1612 11.0847 12.0328 11 13Z"
									fill="#5D6E81"
								/>
							</svg>
						</button>
						<button onClick={showSubItems}>
							<span className="bg-blue-500 flex font-medium h-9 items-center justify-center mx-3 rounded-full text-white w-4 w-9 uppercase">
								{!isUndefined(profile?.first_name) ? profile?.first_name[0] : 'E'}
							</span>
						</button>
						<div ref={dropdownRef} className="origin-top-right absolute right-2 lg:top-12 top-32 w-56 rounded-md shadow-lg bg-white z-20">
							<div className={showDropDown ? 'block py-1' : 'hidden py-1'}>
								<NavLink to="/account" className="w-full block text-gray-700 px-4 py-2 text-sm text-left hover:bg-gray-300 hover:text-white">
									Account
								</NavLink>
								<button onClick={logOutSpace} className="w-full block text-gray-700 px-4 py-2 text-sm text-left hover:bg-gray-300 hover:text-white">
									Switch Space
								</button>
								<NavLink onClick={logOut} to="/login" className="w-full block text-gray-700 px-4 py-2 text-sm text-left hover:bg-gray-300 hover:text-white">
									Logout
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</>
	)
}

const mapStateToProps = (state, props) => {
	return {
		currentSpace: state.currentSpace,
		currentProfile: state.profile,
	}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(TopBar)
