const OrderStatusMapping = {
  'pending_payment': {
    labelText: 'pending',
    labelColor: 'blue'
  },
  'payment_received': {
    labelText: 'complete',
    labelColor: 'green'
  },
  'cancel': {
    labelText: 'cancelled',
    labelColor: 'yellow'
  },
}

const dataTableColumns = [
  {
    index: 0,
    heading: 'Order',
    columnStyles: ''
  },
  {
    index: 1,
    heading: 'Date',
    columnStyles: ''
  },
  {
    index: 2,
    heading: 'Customer',
    columnStyles: ''
  },
  {
    index: 3,
    heading: 'Items',
    columnStyles: ''
  },
  {
    index: 4,
    heading: 'Total',
    columnStyleClasses: 'w-40'
  },
  {
    index: 5,
    heading: 'Status',
    columnStyleClasses: 'w-28'
  },
];

export { OrderStatusMapping, dataTableColumns };
