import React, { useEffect, useState } from 'react'
import { focusElement, onPressEscape } from '../../utils/keyboardHandler'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'

const maxChars = 150

const AddNoteForLineItem = ({noteValue, handleChangeNote, handleClose, isEdit, addToast }) => {
  useEffect(() => {
    focusElement('.add-note-view')
  }, [])

  const [note, setNote] = useState(noteValue || '')

  const handleKeyDown = (event) => {
    handleHideAddNotes()
    event.stopPropagation()
  }

  const handleCloseOrDelete = (event) => {
    if (isEdit) {
      handleChangeNote(event, null)
    }

    handleClose()
  }

  const onChange = (event) => {
    event.preventDefault()

    if (event.target.value.length > maxChars) {
      return
    }

    setNote(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (note === '') {
      addToast({
        text: 'Note cannot be blank.',
        type: 'error',
      })
      return
    }

    if (note.length > 150) {
      addToast({
        text: 'Note is too long 150 character.',
        type: 'error',
      })
      return
    }

    handleChangeNote(event, note)
  }


  return (
    <div
      className="add-note-view fixed top-0 left-0 right-0 bottom-0 w-screen h-screen bg-black/50 flex items-center justify-center z-50"
      tabIndex="-1"
      onKeyDown={onPressEscape((event) => handleKeyDown(event))}
    >
      <div className="bg-white drop-shadow-xl rounded-xl sm:w-1/2">
        <div className="w-full borderCardChrono relative px-4 py-4 flex items-center">
          <span className="text-white font-bold text-lg">{isEdit ? 'EDIT NOTE' : 'ADD NOTE'}</span>
          <button onClick={handleClose} className="absolute right-0 mr-4 cursor-pointer z-20 p-3">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"
                fill="white"
              />
            </svg>
          </button>
        </div>

        <div className="flex flex-col px-8 pt-4 pb-8">
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="my-2">
              <div className='text-sm mb-4 text-center'>
                <p>Add any relevant details about this watch, such as delivery content, service history, or unique features.</p>
                <strong>These notes will be printed and visible on the Invoice.</strong>
              </div>
              <div className='relative'>
                <textarea
                  placeholder="Type Here..."
                  value={note}
                  onChange={onChange}
                  maxLength="20000"
                  name="content"
                  id=""
                  cols="20"
                  rows="4"
                  className="block p-4 md:w-full w-80 inputLogin text-base appearance-none outline-none bg-transparent text-black text-sm font-medium"
                ></textarea>
                <span className="absolute bottom-2 right-2 text-xs text-gray-500">
                  {note.length}/{maxChars}
                </span>
              </div>
            </div>
            <div className="flex flex-row place-content-center">
              <button
                onClick={handleCloseOrDelete}
                style={{ color: '#5D6E81' }}
                className="p-2 lg:p-3 mt-2 lg:mt-5 rounded-md text-xs font-normal leading-5 lg:text-sm cursor-pointer border"
              >
                {isEdit ? 'Delete' : 'Cancel'}
              </button>
              <button
                style={{ backgroundColor: '#2C79E0' }}
                disabled={isEmpty(note)}
                className="flex items-center ml-4 mr-1 p-2 lg:p-3 mt-2 lg:mt-5 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer uppercase disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span className="uppercase">
                  Save
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddNoteForLineItem
