import React from 'react'
import Dialog from '@mui/material/Dialog'
import Classnames from 'classnames'
import {permissionsArray, rolesArray, permissionsConfig} from '../manageTeamConsts'

const RolesPermissionsModal = ({setRolesModalVisible}) => {
	return (
		<Dialog maxWidth={'800px'} open={true} onClose={() => setRolesModalVisible(false)}>
			<div className={'w-[100vw] flex flex-col py-[40px] px-[20px] m-auto max-w-[800px] h-[100vh] max-h-[80%] bg-white relative overflow-hidden'}>
				<button className="absolute right-3 top-3 cursor-pointer" onClick={() => setRolesModalVisible(false)}>
					<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
					</svg>
				</button>
				<h2 className="font-bold text-2xl mb-3">User Permissions</h2>
				<div className="flex flex-1 overflow-scroll">
					<table className="relative min-w-full border bg-transparent overflow-scroll">
						<thead className="sticky bg-white border-b top-[0px] z-[40]">
							<tr className="divide-x">
								{['Activity Name', ...rolesArray.map(role => role.label)].map(headerItem => (
									<th className="p-4 text-sm font-bold tracking-worderer">
										<button className="flext text-gray-500">
											<span>{headerItem}</span>
										</button>
									</th>
								))}
							</tr>
						</thead>

						<tbody className="divide-y">
							{permissionsArray.map((permissionItem, index) => {
								return (
									<tr key={index} className={Classnames('cursor-pointer divide-x hover:bg-gray-100 bg-gray-50', {'bg-white': (index + 1) % 2 === 0})}>
										<th className="p-4 text-sm font-normal tracking-worderer capitalize">{permissionItem.label}</th>
										{rolesArray.map((role, roleIndex) => (
											<th className="p-4 text-sm font-normal tracking-worderer capitalize text-center">
												{permissionsConfig[role.fieldKey]?.[permissionItem.fieldKey] ? (
													<span className={'text-[25px] text-[green] color'}>&#10003;</span>
												) : (
													<span className={'text-[25px] text-[red] color'}>&#120;</span>
												)}
											</th>
										))}
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		</Dialog>
	)
}


export default RolesPermissionsModal
