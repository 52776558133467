import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

export const deepCompare = function(a, b) {

	var result = {
		different: [],
		missing_from_first: [],
		missing_from_second: []
	};

	reduce(a, function(result, value, key) {
		if (b?.hasOwnProperty(key)) {
			if (isEqual(value, b[key])) {
				return result;
			} else {
				if (typeof (a[key]) !== typeof ({}) || typeof (b[key]) !== typeof ({})) {
					//dead end.
					result.different.push(key);
					return result;
				} else {
					var deeper = deepCompare(a[key], b[key]);
					result.different = result.different.concat(map(deeper.different, (sub_path) => {
						return key + '.' + sub_path;
					}));

					result.missing_from_second = result.missing_from_second.concat(map(deeper.missing_from_second, (sub_path) => {
						return key + '.' + sub_path;
					}));

					result.missing_from_first = result.missing_from_first.concat(map(deeper.missing_from_first, (sub_path) => {
						return key + '.' + sub_path;
					}));
					return result;
				}
			}
		} else {
			result.missing_from_second.push(key);
			return result;
		}
	}, result);

	reduce(b, function(result, value, key) {
		if (a?.hasOwnProperty(key)) {
			return result;
		} else {
			result.missing_from_first.push(key);
			return result;
		}
	}, result);

	return result;
};
