import React, {useState} from 'react'
import {useDispatch} from 'react-redux'

import {Globals} from '../../Context'
import {addToast} from '../../app/appActions'
import Dialog from '@mui/material/Dialog'

const AddNewQuickSale = ({formData, hideAddNewQuickSale, customer, handleUpdateInventoriesAfterAddQuickMemoOrSale, closeEditInventory, handleSubmit, currentSpace}) => {
	const [productItem, setProductItem] = useState({
		product_name: formData.name,
		sku: formData.sku,
		price: Globals.revertPrice(formData.wholesale_price),
		shipping: 0,
	})
	const [isVisibleAllowSyncQuickbook, setIsVisibleAllowSyncQuickbook] = useState(false)
	const dispatch = useDispatch()
	const handleFormSubmit = (event, {allowSyncQB = false} = {}) => {
		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales`, {
				sale: {
					customer_id: customer.id,
					customer_label: false,
					deletedItems: [],
					sale_items_attributes: [
						{
							quantity: 1,
							price: Globals.revertPrice(productItem.price),
							saleable_id: formData.id,
							saleable_type: 'Watch',
						},
					],
					shipping_price: Globals.revertPrice(productItem.shipping),
					trade_in_items: [],
					misc_items: [],
					status: 'pending_payment',
					sync_to_quickbook: currentSpace.quickbook_integration?.invoice_sync_type === 'all_item' ? true : allowSyncQB,
				},
			})
			.then(response => {
				if (response.status && response.status === 201) {
					hideAddNewQuickSale()
					Globals.New_Axios()
						.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${formData.id}`)
						.then(res => {
							if (res.data) {
								handleSubmit(event)
							}
							closeEditInventory()
						})
				}
			})
			.catch(error => {
				dispatch(
					addToast({
						title: 'Error',
						text: error.response?.data?.message || 'Something went wront please try again later.',
						type: 'error',
					})
				)
			})
	}
	return (
		<div className="bg-black/50 bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50 p-3">
			<div className="w-[719px]">
				<div className="bg-slate-600 flex items-center px-8 py-4 relative rounded-tl-[5px] rounded-tr-[5px] text-white w-full">
					<span className="text-xl font-extrabold">{`Create Quick Order to ${customer.company_name || customer.name}`}</span>
				</div>
				<div className="bg-white text-gray-500 rounded-bl-[5px] rounded-br-[5px] px-8 py-4 w-full">
					<div className="grid grid-cols-2 gap-4">
						<div className="text-[#6C727F]">
							<div className="text-base font-semibold">Product Name</div>
							<div className="font-normal text-base">{productItem.product_name}</div>
						</div>
						<div className="grid grid-cols-2 border-b-2">
							<div className="text-[#6C727F]">
								<div className="text-base font-semibold">SKU</div>
								<div className="font-normal text-base">{productItem.sku}</div>
							</div>
							<div className="text-[#6C727F]">
								<div className="text-base font-semibold">Price</div>
								<div className="font-normal text-base">
									<input
										value={productItem.price}
										onChange={e => {
											if (!!e.target.value) {
												setProductItem({
													...productItem,
													price: Globals.formatCurrency(e.target.value, true),
												})
											} else {
												setProductItem({...productItem, price: ''})
											}
										}}
										name="price"
										className="w-[121px] border-[1px] rounded-[5px] text-base"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 pt-2">
						<div></div>
						<div className="flex justify-center">
							<div className="grid grid-cols-2 gap-4 text-base">
								<div>
									<div className="pt-2 font-semibold">Subtotal</div>
									<div className="pt-2 font-semibold">Shipping</div>
									<div className="pt-2 font-semibold">Total</div>
								</div>
								<div>
									<div className="pt-2 font-normal">{Globals.readCurrencyNumber(productItem.price ? Globals.revertPrice(productItem.price) : 0, true)}</div>
									<div className="pt-2 font-normal">
										<input
											value={productItem.shipping}
											onChange={e => {
												if (!!e.target.value) {
													setProductItem({...productItem, shipping: Globals.formatCurrency(e.target.value, true)})
												} else {
													setProductItem({...productItem, shipping: ''})
												}
											}}
											name="shipping"
											className="w-[121px] border-[1px] rounded-[5px] text-base"
										/>
									</div>
									<div className="pt-2 font-normal">
										{Globals.readCurrencyNumber((productItem.price ? Globals.revertPrice(productItem.price) : 0) + (productItem.shipping ? Globals.revertPrice(productItem.shipping) : 0), true)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex justify-center">
						<div className="grid grid-cols-2 gap-2 pt-12">
							<button className="w-[150px] h-[39px] bg-[#B91B20] rounded-[5px] text-[#fff] font-bold text-base" onClick={hideAddNewQuickSale}>
								CANCEL
							</button>
							<button
								className="w-[150px] h-[39px] bg-[#004094] rounded-[5px] text-[#fff] font-bold text-base"
								onClick={e => {
									if (currentSpace.quickbook_integration?.invoice_sync_type === 'individually') {
										setIsVisibleAllowSyncQuickbook(true)
									} else {
										handleFormSubmit(e)
									}
								}}
							>
								CREATE ORDER
							</button>
						</div>
					</div>
				</div>
			</div>
			{currentSpace.quickbook_integration?.invoice_sync_type === 'individually' && isVisibleAllowSyncQuickbook && (
				<Dialog
					open={open}
					onKeyUp={e => {
						if (e.key === 'Escape') setIsVisibleAllowSyncQuickbook(false)
					}}
				>
					<div className="confirmation-dialog-box-inner p-8">
						<div className="flex justify-end items-end">
							<button
								onClick={() => {
									setIsVisibleAllowSyncQuickbook(false)
								}}
							>
								<svg className="w-4 h-4" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
								</svg>
							</button>
						</div>
						<div className="confirmation-dialog-box-head">{<h2 className="font-bold text-xl mb-2 text-black">Sync To Quickbook</h2>}</div>
						<div className="confirmation-dialog-box-content mb-8 text-slate-500">
							<p>Do you want to sync this sale to Quickbooks?</p>
						</div>
						<div className="confirmation-dialog-box-footer mt-5 grid grid-cols-2 gap-2">
							<button
								className="transition-all hover:bg-red-300 bg-red-200 font-semibold md:py-2.5 md:px-4 rounded text-red-700 text-sm uppercase"
								autoFocus
								w-full
								onClick={e => handleFormSubmit(e)}
							>
								No
							</button>
							<button
								className="transition-all hover:bg-gray-300  bg-gray-200 font-semibold md:py-2.5 md:px-4 rounded text-gray-700 text-sm uppercase w-full"
								onClick={e => handleFormSubmit(e, {allowSyncQB: true})}
							>
								Yes
							</button>
						</div>
					</div>
				</Dialog>
			)}
		</div>
	)
}
export default AddNewQuickSale
