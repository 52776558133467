import {Globals} from '../Context'
import AppcuesService from '../services/AppcuesService'
import {buildSpaceProperties} from './AppcuesProperties'
import {logIn, storeProfile, setCurrentSpace} from '../app/appActions'
import {store} from '../app/store'

export const updateCredentialsFromResponse = response => {
	const accessToken = response.headers.get('authorization')
	const refreshToken = response.headers.get('Refresh_token') || ''
	const refreshTokenExpiry = response.headers.get('Refresh_token_expiry') || ''
	const newTokenKeyExpiry = response.headers.get('Auth_token_expiry') || ''

	Globals.Cache.set(Globals.New_TokenKey, accessToken)
	Globals.Cache.setCookie(Globals.New_TokenKey, accessToken)

	Globals.Cache.set(Globals.New_TokenKey_Expiry, newTokenKeyExpiry)
	Globals.Cache.setCookie(Globals.New_TokenKey_Expiry, newTokenKeyExpiry)

	Globals.Cache.set(Globals.REFRESH_TOKEN, refreshToken)
	Globals.Cache.setCookie(Globals.REFRESH_TOKEN, refreshToken)

	Globals.Cache.set(Globals.REFRESH_TOKEN_EXPIRY, refreshTokenExpiry)
	Globals.Cache.setCookie(Globals.REFRESH_TOKEN_EXPIRY, refreshTokenExpiry)
}

export const redirectAfterLogin = () => {
	if(window.location.pathname !== '/login'){
		const queryParams = new URLSearchParams(window.location.search);
		const newUserParam = queryParams.get('new_user');
		if(!!newUserParam) {
			 setTimeout(() => (window.location = '/'), 1000)
		} else {
			setTimeout(() => (window.location = window.location.href), 1000)
		}
	} else {
		setTimeout(() => (window.location = '/'), 1000)
	}
}

export async function checkAndHandleSingleSpace(data) {
	localStorage.setItem('loginSpace', Date.now());
	if (data.spaces && data.spaces.length === 1) {
		increaseUserSignInCount(data.spaces[0])
		await loginIntoSpace(data.spaces[0])
	} else {
		redirectAfterLogin()
	}
}

export async function loginIntoSpace(space) {
	Globals.Cache.set(Globals.USERSELECTEDSPACE, JSON.stringify(space))
  localStorage.setItem('loginSpace', Date.now());

	try {
		await AppcuesService.updateUserInfo(buildSpaceProperties(space))
		redirectAfterLogin()
	} catch (error) {
		console.error('Failed to update Appcues user info', error)
		redirectAfterLogin()
	}
}

export const fetchProfile = async profile => {
	await Globals.getProfile()
		.then(profile => {
			//Dispatch profile actions first.
			store.dispatch(storeProfile(profile))
			store.dispatch(logIn())
		})
		.catch(error => {
			console.warn(error)
		})
}

export const getSpaceDetail = async () => {
	await Globals.New_Axios({logOutWhenUnauthorized: false})
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}`)
		.then(response => {
			Globals.Cache.set(Globals.USERSELECTEDSPACE, JSON.stringify(response.data))
			store.dispatch(setCurrentSpace(response.data))
		})
		.catch(error => {
			console.log(error)
		})
}

export const increaseUserSignInCount = (selectedSpace) => {
	Globals.New_Axios()
		.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${selectedSpace.id}/spaces_users/increase_sign_in_count`)
		.then(response => {
			console.log('User sign in count updated')
		})
		.catch(error => {
			console.log(error)
		})
}