import { AsYouType } from 'libphonenumber-js';

export const formatPhoneNumber = value => {
	if (!value) return value;

    // Handle Chrome autofill or cases where value length is at least 10 without "+"
    if (value.length >= 14 && !value.startsWith('+')) {
        value = `+1${value}`;
    }

    const startsWithPlus = value.startsWith('+');

	if (startsWithPlus) {
		value = value.slice(1);
	}

	// Detect unbalanced parentheses
    const hasUnbalancedParentheses = str =>
        str.indexOf('(') !== -1 &&
        str.indexOf('(') === str.lastIndexOf('(') &&
        str.indexOf(')') === -1;

		if (hasUnbalancedParentheses(value)) {
			value = value.slice(0, -1); // Remove the extra digit causing unbalanced parentheses
		}

		try {
			let formattedNumber = value;
	
			// Use AsYouType formatter for dynamic formatting
			const asYouType = new AsYouType('US'); // Replace 'US' with your desired region if needed
			formattedNumber = asYouType.input(value);
	
			// Apply "+" prefix handling logic
			if (startsWithPlus) {
				return `+${formattedNumber}`; // Ensure "+" is only applied once
			} else {
				return formattedNumber;
			}
		} catch (error) {
			console.warn('Invalid phone number:', error);
		}

	return value;
}