import {store} from '../../../app/store'
import {setCurrentSpace} from '../../../app/appActions'
import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Globals} from '../../../Context'
import InvoiceOptionsList from './InvoiceOptionsList'
import InventoryOptionsList from './InventoryOptionsList'
import ConfirmPopUp from '../../ConfirmPopUp'

const QuickBooks = props => {
	const {currentSpace, query, addToast} = props
	const location = useLocation()
	const navigate = useNavigate()
	const [invoiceSyncTypeSelected, setInvoiceSyncTypeSelected] = useState(currentSpace.quickbook_integration?.invoice_sync_type)
	const [inventorySyncTypeSelected, setInventorySyncTypeSelected] = useState(currentSpace.quickbook_integration?.inventory_sync_type)

	const getSpaceDetail = async () => {
		await Globals.New_Axios({logOutWhenUnauthorized: false})
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}`)
			.then(response => {
				store.dispatch(setCurrentSpace(response.data))
			})
			.catch(error => {
				console.log(error)
			})
	}

	const integrateQuickbook = () => {
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/quickbooks/login`)
			.then(response => {
				const {data} = response
				window.location = data.auth_link
			})
			.catch(error => {
				console.log(error)
			})
	}

	const disconnectQuickbook = () => {
		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/quickbooks/disconnect`)
			.then(response => {
				const {data} = response

				getSpaceDetail()

				addToast({
					text: data.message,
					type: 'success',
				})
			})
			.catch(error => {
				console.log(error)
			})
	}

	const updateQuickbook = () => {
		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/quickbooks`, {
				quickbook_integration: {
					invoice_sync_type: invoiceSyncTypeSelected,
					inventory_sync_type: inventorySyncTypeSelected,
				},
			})
			.then(response => {
				const {data} = response

				getSpaceDetail()

				addToast({
					text: data.message,
					type: 'success',
				})
			})
			.catch(error => {
				console.log(error)
			})
	}

	useEffect(() => {
		const {success} = query
		if (success === 'true') {
			addToast({
				text: 'Quickbook integration successful',
				type: 'success',
			})
		} else if (success === 'false') {
			addToast({
				text: 'Quickbook integration failed',
				type: 'error',
			})
		}

		removeAllQueryParams()
	}, [query])

	const removeAllQueryParams = () => {
		const {pathname} = location
		navigate(pathname)
	}

	return (
		<div className="relative pb-24 w-full">
			<div className="flex mt-16 justify-center">
				<div className="text-center">
					{currentSpace.quickbook_integration?.connected ? (
						<>
							<div className='space-y-4'>
								<InvoiceOptionsList addToast={addToast} syncTypeSelected={invoiceSyncTypeSelected} setSyncTypeSelected={setInvoiceSyncTypeSelected} />
								<InventoryOptionsList addToast={addToast} syncTypeSelected={inventorySyncTypeSelected} setSyncTypeSelected={setInventorySyncTypeSelected} />
							</div>

							<div className="mt-8 space-x-4">
								<ConfirmPopUp
									onConfirm={disconnectQuickbook}
									title="Disconnect Quickbook"
									content="Are you sure you want to disconnect with Quickbook?"
								>
									<button className="text-white bg-gray-500 hover:opacity-90 font-medium rounded text-sm px-4 py-1.5 pr-3 text-center inline-flex items-center capitalize">
										Disconnect
									</button>
								</ConfirmPopUp>

								<button
									onClick={updateQuickbook}
									className="text-white bg-blue-600 hover:opacity-90 font-medium rounded text-sm px-4 py-1.5 pr-3 text-center inline-flex items-center capitalize"
								>
									Save
								</button>
							</div>
						</>
					) : (
						<button
							onClick={integrateQuickbook}
							className="text-white bg-blue-600 hover:opacity-90 font-medium rounded text-sm px-4 py-1.5 pr-3 text-center inline-flex items-center capitalize"
						>
							Integrate Quickbook
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

export default QuickBooks
