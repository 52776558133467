import React, {useState, useMemo, useEffect} from 'react'
import {connect} from 'react-redux'
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import QuickBooks from './QuickBooks'
import Shopify from './Shopify'
import {addToast} from './../../../app/appActions'

function useQuery() {
	const { search } = useLocation();
	return useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
}

const Integrations = (props) => {
	const query = useQuery();

	const {currentSpace, addToast} = props
	const [isVisibleQuickBooks, setIsVisibleQuickBooks] = useState(props.profile.role == 'owner')
	const [isVisibleShopify, setIsVisibleShopify] = useState(false)

	const handleOpenQuickBooks = () => {
		setIsVisibleQuickBooks(true)
		setIsVisibleShopify(false)
	}
	const handleOpenShopify = () => {
		setIsVisibleQuickBooks(false)
		setIsVisibleShopify(true)
	}

	useEffect(() => {
		const { subtab } = query;

		if (subtab === 'shopify') {
			handleOpenShopify();
		}
	}, [query])

	return (
		<div className="w-full">
			<div className="relative w-full">
				<div className="flex border-b-2 w-full pl-5">
					{props.profile.role == 'owner' && (
						<button
							className="cursor-pointer flex flex-row justify-center items-center border-b-2 mr-4 sm:mr-4"
							style={{
								fontWeight: isVisibleQuickBooks ? '700' : '500',
								borderColor: isVisibleQuickBooks ? '#2C79E0' : 'transparent',
							}}
							onClick={handleOpenQuickBooks}
						>
							<span className="lg:text-sm text-xxs w-full">QuickBooks</span>
						</button>
					)}
					<button
						className="cursor-pointer flex flex-row justify-center items-center border-b-2 mr-4 sm:mx-4 py-3"
						style={{
							fontWeight: isVisibleShopify ? '700' : '500',
							borderColor: isVisibleShopify ? '#2C79E0' : 'transparent',
						}}
						onClick={handleOpenShopify}
					>
						<span className="lg:text-sm text-xxs w-full">Shopify</span>
					</button>
				</div>
			</div>
			{isVisibleQuickBooks && <QuickBooks currentSpace={currentSpace} query={query} addToast={addToast} />}
			{isVisibleShopify && <Shopify currentSpace={currentSpace} query={query} addToast={addToast} />}
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	addToast
}

export default connect(mapStateToProps, mapActionsToProps)(Integrations)
