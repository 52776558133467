import React, {useEffect, useState} from 'react'
import {Globals} from '../../../../Context'
import QRPreviewBlock from '../../../Account/settings/QRCode/components/QRPreviewBlock'
import PrinterAdjustments from '../../../Account/settings/QRCode/components/PrinterAdjustments'
import LoadingBlock from './LoadingBlock'
import useQzTray from '../../../../hooks/useQzTray'
import cloneDeep from 'lodash/cloneDeep'
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'


import { styled } from '@mui/material/styles';

const CustomAccordion = styled(Accordion)(({ theme }) => {
	return {
		boxShadow: 'none', // this styles directly apply to accordion
		border: `0px solid gray`,

		'&::before': { // ✅ Removes the ::before pseudo-element
			display: 'none',
		},
		'.MuiAccordionDetails-root': {
			padding: 0,
			borderWidth: 0
		},
		'.MuiAccordionSummary-root': {
			padding: 0,
			borderWidth: 0,
			border: `0px solid gray`,

		},
		'.MuiAccordionSummary-content': {
			margin: 0,
		}
	};
});

const QRSticker = ({handleVisibleQRCode, data}) => {
	const [loading, setLoading] = useState(false)
	const [watch, setWatch] = useState(data)
	const [qrCodeData, setQrCodeData] = useState()
	const [selectedPrinter, setSelectedPrinter] = useState()
	const [printerCustomSettings, setPrinterCustomSettings] = useState([])
	const {printPPLZ, stickerSettings, printStickerImage} = useQzTray()

	useEffect(() => {
		if (stickerSettings && stickerSettings.length > 0) {
			setPrinterCustomSettings(stickerSettings)
			const printer = stickerSettings.find(item => item.key === 'printer_settings')?.value?.printer
			if (printer) {
				setSelectedPrinter(printer)
			}
		}
	}, [stickerSettings])

	const handleGenerateQRCode = () => {
		setLoading(true)
		const params = {
			watch_qr_code_setting: {
				label_size: 'medium_long',
				page_size: 'label_printer',
			},
		}
		Globals.New_Axios()
			.post(`${Globals.baseUri}/watches/${data.id}/qr_code_settings`, params)
			.then(response => {
				const {data} = response
				setWatch(data.watch)
				setQrCodeData(data)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (watch.has_qr_code) {
			setLoading(true)
			Globals.New_Axios()
				.get(`${Globals.baseUri}/watches/${data.id}/qr_code_settings`, {})
				.then(response => {
					const {data} = response
					setQrCodeData(data)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}, [watch])

	useEffect(() => {
		if (data && !data?.has_qr_code) {
			handleGenerateQRCode()
		}
	}, [data])

	const printWithQZTray =  (language) => {
		if (language === 'PPLB') {
			printStickerImage({customPrintingSettings: printerCustomSettings})
		}else  {
			printPPLZ({
				watchData: {...watch, short_url: qrCodeData?.short_url},
				customPrintingSettings: printerCustomSettings,
			})
		}
	}

	const handlePrintingOptionsChange = async newPrintValue => {
		setPrinterCustomSettings(prevState => {
			const index = prevState.length - 1
			const newConfigData = cloneDeep(prevState)
			newConfigData[index] = newPrintValue
			return newConfigData
		})
	}

	return (
		<>
			<div className="flex justify-between items-center h-[60px] rounded-t-[8px] border-b bg-slate-600 text-white px-[13px]">
				<h3 className="text-white font-bold text-lg">{watch.has_qr_code ? 'QR Code' : 'Create QR Code'}</h3>
				<button
					onClick={() => {
						handleVisibleQRCode(false)
					}}
					type="button"
					className="bg-transparent rounded-xl text-sm py-1.5 ml-auto inline-flex items-center"
				>
					<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
							clipRule="evenodd"
						></path>
					</svg>
				</button>
			</div>

			<div className={'min-h-[260px] flex items-center justify-center'}>
				<QRPreviewBlock hideBorder hideTitle qrCodeURL={qrCodeData?.qr_code_url} configData={printerCustomSettings || []} watchData={watch} />
			</div>
			<LoadingBlock loading={loading} />

			<CustomAccordion>
				<AccordionSummary
					aria-controls="panel1a-content"
					id="panel1a-header"
					classes={{content: 'flex justify-between'}}
					className="pt-2 pb-[0px] border-[0px] border-[#D9E2EE] cursor-pointer"
				>
					<p className={'pl-[30px] text-[#5D6E81] font-bold text-[12px] cursor-pointer'}>
						Customize printing <ExpandMoreIcon />
					</p>
				</AccordionSummary>
				<AccordionDetails>
					<PrinterAdjustments pt={'0px'} configData={printerCustomSettings} updatePrintingData={handlePrintingOptionsChange} hidePrint />
				</AccordionDetails>
			</CustomAccordion>

			<div className="flex w-full justify-around gap-[29px] mt-[20px] pb-[30px] flex-row">
				<button
					onClick={() => {
						handleVisibleQRCode(false)
					}}
					className="px-[14px] py-[12px] border-[1px] rounded-[5px] text-[#5D6E81] font-bold text-[14px] cursor-pointer"
				>
					Cancel
				</button>

				<button onClick={() => printWithQZTray('PPLB')} className="px-[14px] py-[12px] border-[1px] rounded-[5px] text-[#5D6E81] font-bold text-[14px] cursor-pointer">
					Download PDF
					<span className={"text-[10px]"} >(PPLB/EPL formatted)</span>
				</button>

				<button
					disabled={loading}
					className="px-[24px] py-[12px] border-[1px] rounded-[5px] text-[#FFFFFF] font-bold text-[14px] bg-[#4B7CBE] cursor-pointer"
					onClick={() => printWithQZTray('PPLZ')}
				>
					Print
				</button>
			</div>
		</>
	)
}

export default QRSticker
