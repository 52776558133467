import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Globals } from '../Context';
import WebStoreList from '../Components/WebStore/WebStoreList';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ContactCartDialog from "../Components/WebStore/ContactCartDialog";
import { isEmpty } from 'lodash';

import "../Components/WebStore/webStore.css";
import { styled } from '@mui/system';
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'

const StyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1.5, 4),
    fontSize: '1rem',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0px 4px 6px rgba(0,0,0,0.3)',
    },
    '&.MuiButton-containedPrimary': {
        backgroundColor: '#2563eb',
        '&:hover': {
            backgroundColor: '#1d4ed8',
        },
    },
}));

let slugAsSubdomain = '';

if (window.location.hostname.endsWith(`.${Globals.storeHostName}`)) {
  slugAsSubdomain = window.location.hostname.split('.')[0];
}

const WebStore = () => {
    const { slug, uid } = useParams();
    const [isAuthorized, setIsAuthorized] = useState('');
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const [storeExistsAndEnabled, setStoreExistsAndEnabled] = useState(true);
    const [contactCardData, setContactCardData] = useState({});
    const [space, setSpace] = useState({});
    const [logo, setLogo] = useState({});
    const [openContactCartDialog, setOpenContactCartDialog] = useState(false);
    const slugWebStore = slug ? slug : slugAsSubdomain;

    if ((window.location.host.startsWith('app.') || window.location.host.startsWith('share.'))) {
      window.location.href = `${window.location.protocol}//${slugWebStore}.${Globals.storeHostName}/store/${uid}`;
    }

    useEffect(() => {
      const authenticateWebStore = async () => {
        const url = `${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/store/check_store_availability/${uid}/${slugWebStore}`;
        setLoading(true);
            try {
                const response = await Globals.New_Axios().post(url);
                setIsAuthorized(!response.data.password_required);

            } catch (error) {
                if (error.response?.status === 301) {
                  const storeUrl = error.response?.data?.store_url;

                  if (storeUrl) {
                    window.location.href = storeUrl
                  }
                }

                setStoreExistsAndEnabled(false);
                setContactCardData(error?.response?.data?.contact_card || {});
                setSpace(error?.response?.data?.space || {});
                setLogo(error?.response?.data?.logo || {});

                let errorMessage = error.response?.data?.message || 'Store you are trying to access does not exist or is not enabled.';

                if (window.location.hostname.endsWith(`.${Globals.storeHostName}`)) {
                  errorMessage = error.message;
                }

                setErrorMessage(errorMessage);
            } finally {
                setLoading(false);
            }
        };
        authenticateWebStore();
    }, [slugWebStore]);


    const handlePasswordChange = (event) => setPassword(event.target.value);

    const handleSubmit = async () => {
        if (!password) {
            setErrorMessage('Please enter a password.');
            return;
        }
        setLoading(true);
        try {
            const response = await fetchStoreAuthorizationWithPassword(password);
            setIsAuthorized(response.password_correct);
            if (!response.password_correct) {
                setErrorMessage('Incorrect password. Please try again.');
            }
        } catch (error) {
            setErrorMessageFromError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchStoreAuthorizationWithPassword = async (password) => {
        const url = `${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/store/check_store_availability/${uid}/${slugWebStore}`;
        const response = await Globals.New_Axios().post(url, { password });
        return response.data;
    };

    const setErrorMessageFromError = (error) => {
        const message = error.response?.data?.message || 'An unexpected error occurred.';
        setErrorMessage(message);
    };

    if (loading) {
        return <LoadingScreen />;
    }

    if (!storeExistsAndEnabled) {
        return (
        <>
            <NotFoundDialog errorMessage={errorMessage} contactCardData={contactCardData} setOpenContactCartDialog={setOpenContactCartDialog} />
            {/* Dialog */}
            <ContactCartDialog
                open={openContactCartDialog}
                setOpen={setOpenContactCartDialog}
                space={space}
                logo={logo}
                contactCardData={contactCardData}
                isMobile={false}
            />
        </>
        )}

    return (
      <>
        {isAuthorized && <WebStoreList slug={slugWebStore} uid={uid} />}
        {isAuthorized === false && (
          <PasswordDialog
            open={!isAuthorized}
            password={password}
            onPasswordChange={handlePasswordChange}
            onSubmit={handleSubmit}
            errorMessage={errorMessage}
          />
        )}
      </>
    )
};

const LoadingScreen = () => (
    <div className="flex justify-center items-center h-screen">
        <Box textAlign="center">
            <CircularProgress color="primary" />
            <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
            <Typography variant="body2" color="textSecondary">We're fetching the latest data for you. Hang tight!</Typography>
        </Box>
    </div>
);

const PasswordDialog = ({ open, password, onPasswordChange, onSubmit, errorMessage }) => (
    <Dialog open={open}>
        <DialogTitle className="flex justify-center items-center">
            <LockOutlinedIcon className="mr-2 text-blue-500" />
            Enter the password to access this page
        </DialogTitle>
        <DialogContent className="flex flex-col items-center">
            {errorMessage && <Alert severity="error" className="mb-4">{errorMessage}</Alert>}
            <TextField
                autoFocus
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={onPasswordChange}
            />
        </DialogContent>
        <DialogActions className="!flex !justify-around p-4 w-full mb-4">
            <StyledButton onClick={onSubmit} color="primary" variant="contained">
                Submit
            </StyledButton>
        </DialogActions>
    </Dialog>
);

const NotFoundDialog = ({errorMessage, contactCardData, setOpenContactCartDialog}) => (
    <Dialog open={true}>
        <DialogTitle className="flex justify-center items-center">
            <LockOutlinedIcon className="mr-2 text-blue-500" />
            This page does not exist
        </DialogTitle>
        <DialogContent className="flex flex-col items-center">
            <Typography variant="body2" color="textSecondary">{errorMessage ? errorMessage : "The store you are trying to access does not exist or is not enabled."}</Typography>
        </DialogContent>
        <DialogActions className="!flex !justify-around p-4 w-full mb-4">
            {contactCardData ? (
                <>
                    {
                        (contactCardData.phone || contactCardData.email || contactCardData.address) ?
                        <StyledButton
                            color="primary"
                            variant="contained"
                            onClick={() => setOpenContactCartDialog(prev => !prev)}
                        >
                            Contact Card
                        </StyledButton>
                        : null
                    }
                </>
            ) : (
                <StyledButton href="/" color="primary" variant="contained">
                    Go to home
                </StyledButton>
            )}

        </DialogActions>
    </Dialog>
);

export default WebStore;
