import useMobileDevice from '../hooks/useMobileDevice'
import appIcon  from "../../public/Images/appIcon.png"
import mobile  from "../../public/Images/mobile.png"
import logoMobile  from "../../public/Images/logoMobileScreen.jpg"

export const playStoreLink = "https://play.google.com/store/apps/details?id=com.elefta&hl=en"
export const appStoreLink = "https://apps.apple.com/ua/app/elefta-app/id6499310721"

const MobileDetectorWrapper = ({children}) => {
	const {isMobile, platform} = useMobileDevice()
	const handleOpenMarket = () => {
		if (platform === 'iOS') {
			window.open(appStoreLink, '_blank')
		} else {
			window.open(playStoreLink, '_blank')
		}
	}
	const isSignUp = window.location.pathname === '/Register' ||
	window.location.pathname.indexOf('register') > -1 ||
	window.location.pathname.indexOf('reset/password') > -1 ||
	window.location.pathname.indexOf('share') > -1 ||
	window.location.pathname.indexOf('store') > -1

	return isMobile === null || isMobile === false || isSignUp? (
		children
	) : (
		<div className={'w-full bg-white min-h-[100vh] flex flex-col items-center justify-between'}>
			<div className={'w-full bg-[#5D6E81] flex flex-row items-center pt-3 pb-4 px-3 drop-shadow-xl'}>
				<img src={appIcon} alt={'elefta.app.logo'} className={'w-[74px] h-[74px]'} />
				<div className={'flex-1 px-3'}>
					<p className={'text-white text-[16px] font-bold'}>Elefta</p>
					<p className={'text-white text-[13px] font-reg'}>Buy, Sell, Manage and More!</p>
				</div>
				<button onClick={handleOpenMarket} className={'px-4 py-1 rounded-[17px] bg-[#2C7ADF] '}>
					<span className={'text-white text-[15px] font-reg'}>Get</span>
				</button>
			</div>

			<div className={'flex flex-col pt-10 items-center'}>
				<img className={'w-[103px] h-[86px]'} src={logoMobile} alt="" />
				<p className={'w-full mt-5 text-center text-[25px] font-semibold'}>Do more on mobile</p>
				<p className={'w-full mt-3 text-center text-[16px] font-reg max-w-[260px]'}>All the powerful tools from desktop, in the palm of your hands</p>
				<button onClick={handleOpenMarket} className={'px-4 mt-10 w-full py-3 rounded-[4px] bg-[#2C7ADF] '}>
					<span className={'text-white text-[20px] font-reg '}>Download Now</span>
				</button>
			</div>

			<div className={'w-full px-5'}>
				<img className={'w-full h-auto'} src={mobile} alt="" />
			</div>
		</div>
	)
}

export default MobileDetectorWrapper
