import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import {arrayMoveImmutable} from 'array-move'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {v4 as uuid} from 'uuid'
import Tooltip from '@mui/material/Tooltip'
import ProcessUpload from './../utils/ProcessUpload'
import './UploadMedia.scss'
import isObject from 'lodash/isObject'
import isFunction from 'lodash/isFunction'

class UploadMedia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filesList: props.initialFilesList || [],
			errors: [],
			previewPicture: null,
		};
	}

	// Update filesList if the prop changes dynamically
	componentDidUpdate(prevProps) {
		// Only update filesList if initialFilesList prop changes and filesList hasn't been modified
		if (prevProps.initialFilesList !== this.props.initialFilesList) {
			this.setState((prevState) => {
				const prevStockImage = prevState.filesList.find(file => file.name?.startsWith('stock'));
				const newStockImage = this.props.initialFilesList.find(file => file.name?.startsWith('stock'));

				if (prevStockImage?.name !== newStockImage?.name) {
					return { filesList: this.props.initialFilesList };
				}
				return null; // No state update needed
			});
		}
	}

	componentDidMount() {
		let loadedImages = []
		if (this.props.defaultValue) {
			let defaultImage = !isObject(this.props.defaultValue) ? JSON.parse(this.props.defaultValue) : this.props.defaultValue

			defaultImage.forEach(image => {
				loadedImages.push({
					id: image,
					post_id: image,
					done: true,
					name: image.name,
					file: undefined,
					size: undefined,
					type: image.type,
					progress: 100,
					src: image.url,
					videoSrc: image.video_url,
				})
			})
			this.setState({filesList: loadedImages}, () => this.props.uploadUpdate(this.state.filesList))
		}
		if (this.props.defaultImages) {
			this.props.defaultImages.forEach((image, index) => {
				loadedImages.push({
					id: index,
					post_id: index,
					done: true,
					file: undefined,
					size: undefined,
					type: undefined,
					progress: 100,
					src: image,
				})
			})
			this.setState({filesList: loadedImages})
		}
	}

	handleFileReading = async $file => {
		try {
			return new Promise((resolve, reject) => {
				const fileReader = new FileReader()
				fileReader.readAsDataURL($file)
				fileReader.onloadend = $response => {
					const {
						target: {result},
					} = $response
					return resolve(result)
				}
			})
		} catch (error) {
			return false
		}
	}

	handleInputChange = async $event => {
		if (this.props.disabled) {
			$event.preventDefault()
			return
		}
		try {
			if(isFunction(this.props.handleLoadingUploadImage)) this.props.handleLoadingUploadImage(true)
			const state = this.state
			const {files} = $event.target

			let newPreviews = await Promise.all(
				Object.values(files).map(async file => {
					if (!this.props.allowedTypes.includes(file.type)) {
						this.setState({
							errors: [...state.errors, `${file.name} type is not allowed`],
						})
						if(isFunction(this.props.handleLoadingUploadImage)) this.props.handleLoadingUploadImage(false)
					} else if (files[0].size > 10000000) {
						this.setState({
							errors: [...state.errors, `File size is limited to 10MB`],
						})
						if(isFunction(this.props.handleLoadingUploadImage)) this.props.handleLoadingUploadImage(false)
						return null
					} else {
						this.setState({
							errors: [],
						})
					}

					//this.props.uploadUpdate(file)

					if (this.props.allowedTypes.includes(file.type)) {
						const src = await this.handleFileReading(file)

						const fileId = uuid()
						const _file = {
							id: fileId,
							src: src,
							file: file,
							type: file.type,
							size: file.size,
							progress: 0,
							done: false,
						}

						ProcessUpload(
							_file.file,
							100000,
							res => {
								//Progress update
								let filesList = this.state.filesList
								filesList = filesList.map(file => {
									if (!file) return null
									if (file.id === fileId) {
										file.progress = Math.round(res)
									}
									return file
								})

								this.setState({filesList: filesList})
							},
							res => {
								//Done
								let filesList = this.state.filesList
								filesList = filesList.map(file => {
									if (!file) return null
									if (file.id === fileId) {
										file.progress = 100
										file.done = true
										file.post_id = res.upload
									}
									return file
								})

								this.setState({filesList: filesList}, () => this.props.uploadUpdate(this.state.filesList))
								if(isFunction(this.props.handleLoadingUploadImage)) this.props.handleLoadingUploadImage(false)
							},
							error => {
								//Error happened.
								let filesList = this.state.filesList
								filesList = filesList.filter(_file => _file.id !== fileId)
								this.setState({filesList: filesList}, () => this.props.uploadUpdate(this.state.filesList))
								if(isFunction(this.props.handleLoadingUploadImage)) this.props.handleLoadingUploadImage(false)
								this.setState({
									errors: [...this.state.errors, `unable to upload file: ${file.name}`],
								})

							},
							this.props.uploadTo
						)
						return _file
					}
					return null
				})
			)

			let {filesList} = state
			newPreviews = newPreviews.filter(file => file !== null) //clear files
			// NOTE: EL-1335 | Should only be able to upload one Logo
			if(!this.props.multipe) {
				filesList = [...newPreviews]
			} else {
				filesList = [...filesList, ...newPreviews]
			}
			//this.UploadField.value = null
			this.setState({filesList})
		} catch (error) {
			console.warn(error)
		}
	}

	handleRemovePicture = async (id, $event) => {
		if (this.props.disabled) {
			$event.preventDefault()
			return
		}
		$event.preventDefault()
		let filesList = this.state.filesList
		filesList = filesList.filter(file => file.id !== id)
		this.setState({filesList: filesList}, () => this.props.uploadUpdate(this.state.filesList))
	}

	handleDrop = async $event => {
		if (this.props.disabled) {
			$event.preventDefault();
			return;
		}

		const isValidDrop = $event.dataTransfer.types.includes("Files");
		if (!isValidDrop) {
			$event.preventDefault();
			return;
		}

		$event.preventDefault()
		$event.target = $event.dataTransfer
		this.handleInputChange($event)
	};

	dismissError = _index => {
		let errors = this.state.errors
		errors = errors.filter((error, index) => index !== _index)
		this.setState({errors: errors})
	}

	handlePreview = (file, $event) => {
		$event.preventDefault()
		this.setState({previewPicture: file}, () => this.previewPictureContainer.focus())
	}

	render() {
		const props = this.props
		const state = this.state
		const SortableItem = SortableElement(({value}) => <div>{value}</div>)
		const SortableList = SortableContainer(({items}) => {
			const isAnyFileLoading = state.filesList.some(file => !file.done)
			return (
				<div
					className={props.innerClassNames ? props.innerClassNames : 'upload-media-container grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 gap-5 my-5'}
					onDragOver={e => {
						e.preventDefault()
					}}
					onDragEnter={e => {
						if (!e.dataTransfer.types.includes('Files')) {
							e.preventDefault()
							return
						}
					}}
					onDrop={this.handleDrop}
				>
					{!props.hidePlaceholder && (
						<div key="empty-index" className="select-none">
							<label className="relative cursor-pointer rounded-xl border-2 border-dashed border-gray-200 bg-white p-6 block" style={{paddingTop: '50%', paddingBottom: '50%'}}>
								<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center pointer-events-none text-center">
									<div>
										<svg className="m-3 inline-block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M18 20H4V6H13V4H4C2.9 4 2 4.9 2 6V20C2 21.1 2.9 22 4 22H18C19.1 22 20 21.1 20 20V11H18V20ZM10.21 16.83L8.25 14.47L5.5 18H16.5L12.96 13.29L10.21 16.83ZM20 4V1H18V4H15C15.01 4.01 15 6 15 6H18V8.99C18.01 9 20 8.99 20 8.99V6H23V4H20Z"
												fill="#5D6E81"
											/>
										</svg>
										<div>
											<small>{props.text}</small>
										</div>
									</div>
								</div>
								<input
									disabled={this.props.disabled}
									type="file"
									id="dropzone-file"
									className="hidden"
									multiple={this.props.maximum === 1 ? false : true}
									onChange={this.handleInputChange}
									accept={props.allowedTypes.join(',')}
									ref={UploadField => {
										this.UploadField = UploadField
									}}
								/>
							</label>
						</div>
					)}

					{items.map((value, index) => (
						<SortableItem key={index} index={index} value={value} disabled={isAnyFileLoading} />
					))}
				</div>
			)
		})

		return (
			<div>
				<div className="upload-media-container">
					<SortableList
						axis="xy"
						helperClass="z-50"
						onSortMove={e => {
							//debugger
						}}
						onSortEnd={({oldIndex, newIndex}) => {
							this.setState(
								({filesList}) => ({
									filesList: arrayMoveImmutable(filesList, oldIndex, newIndex),
								}),
								() => {
									this.props.uploadUpdate(this.state.filesList)
								}
							)
						}}
						items={state.filesList.map((file, index) => {
							if (!file) return ''
							const isVideo = file.type == undefined ? false : file.type.includes('video')
							return (
								<div key={index} className="relative bg-gray-100 rounded-xl border-2 border-dashed border-gray-100 overflow-hidden" style={{paddingTop: '100%'}}>
									{isVideo && (
										<video className="absolute inset-0 object-cover h-full">
											<source src={file.src} type="video/mp4" />
										</video>
									)}
									<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-cover bg-center">
										<div className={'absolute w-full h-full z-0 '}>
											<img src={file.src} className="object-cover h-full w-full contain" style={{objectFit: 'contain'}} />
										</div>
										<button
											type={'button'}
											className="bg-blue rounded-full p-2 z-1 absolute top-2 right-2 z-auto" onClick={this.handleRemovePicture.bind(this, file.id)}>
											<svg className="pointer-events-none" width="10" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"
													fill="white"
												/>
											</svg>
										</button>
										{!file.done && (
											<div className="progress">
												<svg
													width="40"
													viewBox="0 0 120 120"
													style={{
														transform: 'rotate(-90deg)',
													}}
												>
													<circle cx="60" cy="60" r="54" fill="none" stroke="white" strokeWidth="12" pathLength="100"></circle>
													<circle
														strokeDashoffset={-file.progress}
														strokeDasharray="100"
														cx="60"
														cy="60"
														r="54"
														fill="none"
														stroke="black"
														strokeWidth="12"
														pathLength="100"
													/>
												</svg>
											</div>
										)}
										{file.done && (
											<div className="preview z-[1]">
												<Tooltip title="View media" placement="bottom" arrow>

													<button type={'button'} onClick={this.handlePreview.bind(this, file)} className="preview-btn bg-blue p-2 opacity-1 rounded-full">
														{isVideo ? (
															<svg
																className="pointer-events-none"
																xmlns="http://www.w3.org/2000/svg"
																xmlns:xlink="http://www.w3.org/1999/xlink"
																fill="white"
																height="20"
																width="20"
																version="1.1"
																id="Layer_1"
																viewBox="0 0 512 512"
																xml:space="preserve"
															>
																<g>
																	<g>
																		<path d="M256,0C114.51,0,0,114.497,0,256c0,141.49,114.495,256,256,256c141.491,0,256-114.497,256-256    C512,114.509,397.503,0,256,0z M256,478.609c-122.746,0-222.609-99.862-222.609-222.609S133.252,33.391,256,33.391    S478.609,133.254,478.609,256S378.746,478.609,256,478.609z" />
																	</g>
																</g>
																<g>
																	<g>
																		<path d="M358.185,241.544l-155.778-90.01c-11.128-6.426-25.048,1.63-25.048,14.456V346.01c0,12.922,14.01,20.833,25.048,14.456    l155.778-90.01C369.301,264.032,369.287,247.96,358.185,241.544z M210.75,317.081V194.919L316.461,256L210.75,317.081z" />
																	</g>
																</g>
															</svg>
														) : (
															<svg className="pointer-events-none" width="20" height="20" viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg" fill="white">
																<path d="M11 2C14.79 2 18.17 4.13 19.82 7.5C18.17 10.87 14.79 13 11 13C7.21 13 3.83 10.87 2.18 7.5C3.83 4.13 7.21 2 11 2ZM11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 5C12.38 5 13.5 6.12 13.5 7.5C13.5 8.88 12.38 10 11 10C9.62 10 8.5 8.88 8.5 7.5C8.5 6.12 9.62 5 11 5ZM11 3C8.52 3 6.5 5.02 6.5 7.5C6.5 9.98 8.52 12 11 12C13.48 12 15.5 9.98 15.5 7.5C15.5 5.02 13.48 3 11 3Z" />
															</svg>
														)}
													</button>
												</Tooltip>
											</div>
										)}
									</div>
								</div>
							)
						})}
					/>
				</div>

				<div className="upload-media-errors">
					{state.errors.map((error, index) => (
						<div key={index} className="text-xs text-red-700 bg-red-200 rounded p-2 my-1 relative">
							<span>{error}</span>
							<button type={'button'} onClick={this.dismissError.bind(this, index)} className="bg-red-700 rounded-full p-1 absolute top-2 right-2 z-10">
								<svg width="6" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									{' '}
									<path
										d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"
										fill="white"
									/>
								</svg>
							</button>
						</div>
					))}
				</div>
				<div className="hidden text-xs text-slate-500 bg-gray-200 rounded p-2 md:block">
					<p>
						<strong>Important:</strong> only {props.allowedTypes.join(', ').replaceAll('image/', '').replaceAll('video/', '')} & a maximum of <strong>{props.maximum}</strong> file{props.maximum > 1 ? "s" : ""}.
					</p>
				</div>

				{state.previewPicture && (
					<div
						ref={previewPictureContainer => {
							this.previewPictureContainer = previewPictureContainer
						}}
						className="upload-media-lightbox fixed top-0 right-0 left-0 bottom-0 z-50 p-11"
						tabIndex="-1"
						onKeyDown={e => (e.key === 'Escape' ? this.setState({previewPicture: null}) : '')}
					>
						<div className="upload-media-lightbox-inner w-full h-full p-11">
							<div className="upload-media-lightbox-image relative w-full h-full flex justify-center items-center p-4">
								<div className="relative h-full">
									<Tooltip title="Close preview" arrow>
										<button
											type={'button'}
											onClick={e => {
												e.preventDefault()
												this.setState({
													previewPicture: null,
												})
											}}
											className="bg-black rounded-full p-2 absolute top-4 right-4 z-10"
										>
											<svg fill="white" width="18" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
												<path d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z" />
											</svg>
										</button>
									</Tooltip>
									{state.previewPicture.type?.includes('video') ? (
										<video className="max-h-full max-w-full bg-gray-100" controls>
											<source src={state.previewPicture.src} type="video/mp4" />
										</video>
									) : (
										<img src={state.previewPicture.src} className="max-h-full max-w-full bg-gray-100" alt="preview" />
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		)
	}
}

UploadMedia.defaultProps = {
	text: 'Add Photos',
	maximum: 40,
	allowedTypes: ['image/jpeg', 'image/jpg', 'image/png', 'video/mp4', 'video/x-m4v', 'video/quicktime', 'video/webm'],
	uploadUpdate: response => {},
}

UploadMedia.propTypes = {
	text: PropTypes.string,
	maximum: PropTypes.number,
	allowedTypes: PropTypes.array,
	uploadUpdate: PropTypes.func,
}

export default UploadMedia
