import React, { useEffect, useState, useRef } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import Classnames from 'classnames'

import { Globals } from '../../../Context'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { addToast } from '../../../app/appActions'
import ConfirmPopUp from '../../ConfirmPopUp'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MenuItem  from '@mui/material/MenuItem';
import ServerAutoSuggestAddress from '../../ServerAutoSuggestAddress'
import { Select, Checkbox, Autocomplete, Chip, Popper} from '@mui/material';
import UploadMedia from '../../UploadMedia'
import { capitalize, set, zip } from 'lodash';
import { formatPhoneNumber } from '../../../utils/PhoneNumberFormatter';


const WebStoreContent = ({item, handleSetWebStoresAfterUpdate, handleSetWebStoresAfterDelete, handleSetWebStoresRegenerate, handleShowPassword, showPassword, profile, handleCancelWebstore}) => {
    const dispatch = useDispatch()
    const { handleSubmit, control, watch, reset, setValue } = useForm({});
    const [copyText, setCopyText] = useState('Click to Copy')
    const [uid, setUid] = useState('')
    const [slug, setSlug] = useState('')
    const [formErrors, setFormErrors] = useState({})
    const [isManual, setIsManual] = useState(false)
    const [showBrandDropdown, setShowBrandDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [brand_options, setBrandOptions] = useState([]);
    const [loadingUploadImage, setLoadingUploadImage] = useState(false)
    const [logo, setLogo] = useState({})
    const [selectedWatchFilter, setSelectedWatchFilter] = useState('brand')
    const [selectedWatchFilterLabel, setSelectedWatchFilterLabel] = useState('Brand')
    const [brandSearch, setBrandSearch] = useState("");




    useEffect(() => {
        const defaultAddress={
            full_address: item?.is_new_webstore ? item?.space?.full_address : item?.address_without_suite,
            apt_unit_number: item?.is_new_webstore ? item?.space?.apt_unit_number : item?.apt_unit_number,
            city: item?.is_new_webstore ? item?.space?.city : item?.city,
            street: item?.is_new_webstore ? item?.space?.street : item?.street,
            state: item?.is_new_webstore ? item?.space?.state : item?.state,
            zip_code: item?.is_new_webstore ? item?.space?.zip_code : item?.zip_code,
            country: item?.is_new_webstore ? item?.space?.country : item?.country
        }

        const defaultFormValues = {
            enable: item?.store_enabled,
            wholesale_price_visible: item?.wholesale_price_visible,
            price_to_show: item?.price_to_show || 'wholesale_price',
            watch_filter: item?.watch_filter || 'visible_on_marketplace',
            on_hold_filter: item?.on_hold_filter || ["none"],
            brand_filter: item?.brand_filter,
            markup_price_percentage: isNil(item?.markup_price_percentage) ? '' : item?.markup_price_percentage,
            markup_price_fixed:  isNil(item?.markup_price_fixed) ? '' : item?.markup_price_fixed,
            store_email_enabled: item?.store_email_enabled,
            store_email: item?.is_new_webstore ? item?.space?.email : item?.store_email,
            store_phone_enabled: item?.store_phone_enabled,
            store_phone: item?.is_new_webstore ? item?.space?.phone_number_formatted : item?.store_phone,
            store_address_enabled: item?.store_address_enabled,
            store_address: defaultAddress,
            store_company_name: item?.is_new_webstore ? item?.space?.name : item?.store_company_name,
            password_required: item?.password_required,
            images_visible: item?.images_visible,
            store_password: item?.store_password || '',
            slug: item?.slug,
            view_ui: !!item?.view_ui ? (item?.view_ui === 'grid' ? true : false) : null
        };
        setUid(item?.uid)
        setSlug(item?.slug)
        const logo = item?.is_new_webstore ? item?.space?.logo : item?.logo
        setLogo(logo)
        setIsManual(false)


        //Set Brand Options
        const selectedValue = item?.watch_filter || "visible_on_marketplace";
        const customColumns = item?.space?.custom_columns || {};
        let labelFilterSelected = capitalize(selectedValue)
        let options = [];

        if (selectedValue === "brand") {
            options = item?.brand_options || [];
        } else if (customColumns[selectedValue]) {
            labelFilterSelected = customColumns[selectedValue].label

            options = customColumns[selectedValue].options.map((value) => ({
                value,
                label: value
            }));
        }

        options = options.sort((a, b) => a.label.localeCompare(b.label));

        setSelectedWatchFilter(selectedValue);
        setSelectedWatchFilterLabel(labelFilterSelected);
        setBrandOptions(options);
        setShowBrandDropdown(options.length > 0);
        reset(defaultFormValues);
    }, [item]);

    const onSubmit = async (data) => {
        let errors = {}
        if(JSON.parse(data.password_required) && data.store_password === '') {
			errors = {...errors, store_password: 'Please enter your password.'}
            setFormErrors(errors)
        }
        let viewUI
        if(data?.view_ui === true || data?.view_ui === 'true') {
            viewUI = 'grid'
        } else if(data?.view_ui === false || data?.view_ui === 'false') {
            viewUI = 'card'
        } else {
            viewUI = null
			errors = {...errors, view_ui: 'Please select view UI.'}
            setFormErrors(errors)
        }

        if (showBrandDropdown && data.brand_filter.length === 0) {
            errors = {...errors, brand_filter: `Please select at least one sub-filter for the ${data.watch_filter}.`}
            setFormErrors(errors)
        }

        if (loadingUploadImage) {
            errors = {...errors, logo: 'Please wait for the image to finish uploading.'}
            setFormErrors(errors)
        }

        if (!isEmpty(errors)) {
			return Object.values(errors)
				.reverse()
				.map((error) => {
					dispatch(
                        addToast({
                            title: 'Cannot save web store settings',
                            text: error,
                            type: 'error',
                        })
                    )
				})
		}

        let full_address = ''
        if  (data.store_address.street === '' || data.store_address.city === '' || data.store_address.state === '' || data.store_address.zip_code === '' || data.store_address.country === '' || data.store_address.apt_unit_number === '') {
            full_address = data.store_address.full_address
        }else {
            full_address = `${data.store_address.street}, ${data.store_address.apt_unit_number}, ${data.store_address.city}, ${data.store_address.state}, ${data.store_address.zip_code}, ${data.store_address.country}`
        }

        const store_email_enabled = data.store_email === '' ? false : data.store_email_enabled
        const store_phone_enabled = data.store_phone === '' ? false : data.store_phone_enabled
        const store_address_enabled = full_address === '' ? false : data.store_address_enabled

        const webStoreSettingData = {
            web_store_setting: {
                enabled: item.enabled,
                markup_price_fixed: data.markup_price_fixed,
                markup_price_percentage: data.markup_price_percentage,
                password_required: data.password_required,
                slug: data.slug,
                address: full_address,
                street: data.store_address.street,
                city: data.store_address.city,
                state: data.store_address.state,
                zip_code: data.store_address.zip_code,
                country: data.store_address.country,
                apt_unit_number: data.store_address.apt_unit_number,
                store_company_name: data.store_company_name,
                store_address_enabled: store_address_enabled,
                store_email: data.store_email,
                store_email_enabled: store_email_enabled,
                store_password: data.store_password,
                store_phone: data.store_phone,
                store_phone_enabled: store_phone_enabled,
                title: !!item.title ? item.title : '(Add Title)',
                price_to_show: data.price_to_show,
                wholesale_price_visible: data.wholesale_price_visible,
                watch_filter: data.watch_filter,
                on_hold_filter: data.on_hold_filter,
                brand_filter: data.brand_filter.map(option => option.value),
                images_visible: data.images_visible,
                view_ui: viewUI,
                uid: uid,
                logo_id: logo?.id || '',
            }
        }

        try {
            if(item.id) {
                const response = await Globals.New_Axios().patch(`${Globals.baseUri}/web_store_settings/${item.id}`, webStoreSettingData)
                handleSetWebStoresAfterUpdate(response.data)
            } else {
                const response = await Globals.New_Axios().post(`${Globals.baseUri}/web_store_settings`, webStoreSettingData)
                handleSetWebStoresAfterUpdate(response.data)
            }
            dispatch(
                addToast({
                    title: 'Success',
                    text: 'Web Store Setting has been saved.',
                    type: 'success',
                }))

            setFormErrors({})
        } catch (error) {
            dispatch(
                addToast({
                    title: 'Error',
                    text: error.response?.data?.message || 'Something went wront please try again later.',
                    type: 'error',
                }))

            setFormErrors({})
        }
    }

    const isPersisted = () => {
        return !!item.id;
    }

    const copySuccess = () => {
        dispatch(addToast({
            title: 'Success',
            text: 'URL Copied to Clipboard',
            type: 'success',
        }));
        setCopyText('Copied');

        setTimeout(() => {
            setCopyText('Click to Copy');
        }, 2000);
    };

    const copyError = (errorText) => {
        dispatch(addToast({
            title: 'Error',
            text: errorText,
            type: 'error',
        }));
    };

    const copyToClipboard = (text) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(text).then(copySuccess).catch(() => copyError('Could not copy text'));
        } else {
            copyError('Your browser does not support copying to clipboard');
        }
    };

    const handleChangeWholesalePrice = (event) => {
        let defaultValues = {
            ...watch(),
            [event.target.name]: event.target.value
        }
        if (event.target.name === "markup_price_percentage") {
            defaultValues = {
                ...defaultValues,
                markup_price_fixed: ''
            }
        } else {
            defaultValues = {
                ...defaultValues,
                markup_price_percentage: ''
            }
        }
        reset(defaultValues);
    }

    const phoneFormatter = (value) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (cvLength < 4) return currentValue;
        if (cvLength < 7) return `${currentValue.slice(0, 3)}.${currentValue.slice(3)}`;
        return `${currentValue.slice(0, 3)}.${currentValue.slice(3, 6)}.${currentValue.slice(6, 10)}`;
    }


    const handleChangeLink = (event) => {
        let defaultValues = {
            ...watch(),
            [event.target.name]: event.target.value
        }

        reset(defaultValues);
    }

    const handleDeleteStore = async () => {
        try {
            if(item.id) {
                const response = await Globals.New_Axios().delete(`${Globals.baseUri}/web_store_settings/${item.id}`)
                handleSetWebStoresAfterDelete(response.data)
                dispatch(
                    addToast({
                        title: 'Success',
                        text: 'Web Store Setting has been deleted.',
                        type: 'success',
                    }))
            } else {
                dispatch(
                    addToast({
                        title: 'Error',
                        text: 'Web Store Not Delete',
                        type: 'error',
                    }))
            }

        } catch (error) {
            dispatch(
                addToast({
                    title: 'Error',
                    text: error.response?.data?.message || 'Something went wront please try again later.',
                    type: 'error',
                }))
        }
    }

    const handleRegenerateUniqueLink = async () => {
        if(item.id) {
            const response = await Globals.New_Axios().post(`${Globals.baseUri}/web_store_settings/regenerate?uid=${uid}`)
            handleSetWebStoresRegenerate(response.data.old_web_store_setting, response.data.new_web_store_setting)
        }
    }

    const displayError = key => {
        if (!isEmpty(formErrors[key])) return <div className="my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700 inline">{formErrors[key]}</div>
    }

    const isStoreEmailEnabled = watch('store_email_enabled') && watch('store_email_enabled').toString() === 'true'
    const isStoreAddressEnabled = watch('store_address_enabled') && watch('store_address_enabled').toString() === 'true'
    const isStorePhoneEnabled = watch('store_phone_enabled') && watch('store_phone_enabled').toString() === 'true'
    const isPasswordRequired = watch('password_required') && watch('password_required').toString() === 'true'
    const isWholesalePriceEnabled = watch('wholesale_price_visible') && watch('wholesale_price_visible').toString() === 'true'

    const uploadImages = pictures => {
		const lastPicture = pictures[pictures.length - 1]?.post_id
        setLogo(lastPicture)
	}

    const handleLoadingUploadImage = (visible) => {
		setLoadingUploadImage(visible)
	}

    const on_hold_options = [
        { value: 'none', label: 'None' },
        { value: 'transit_from', label: 'Transit From' },
        { value: 'memo_to', label: 'Memo To' },
        { value: 'on_hold_for', label: 'On Hold For' },
        { value: 'repair_with', label: 'Repair With' },
        { value: 'other', label: 'Other' },
    ]
    const lastSelected = useRef(null);

    const handleCheckCountries = (value) => {
        if(value.long_name === 'Japan' && value.types.includes('country')) {
            if(!value.types.includes('street_number') || !value.types.includes('route')) {
                return false
            }
        }
        return true
    }

    const handleChangeGoogleAddress = (place) => {
		let form = {
            zip_code: '',
            city: '',
            state: '',
            country: '',
            country_code: '',
            street: '',
            full_address: '',
            apt_unit_number: ''
        }
		let companyAddress = []
        let city
        let apt_unit_number
        let address_component_types = []
        let streetNumber
		let streetName

		place.address_components.map(value => {
            address_component_types.push(value.types)

			if (value.types.includes('postal_code')) {
				form = {
					...form,
					zip_code: value.long_name
				}
			}
            if (value.types.includes('subpremise')) {
                const match = value.long_name.match(/\d+$/)
                apt_unit_number = match ? match[0] : value.long_name
				form = {
					...form,
					apt_unit_number: apt_unit_number
				}
			}
			if (value.types.includes('locality')) {
                city = value.long_name
				form = {
					...form,
					city: city
				}
			}
			if (value.types.includes('administrative_area_level_1')) {
                if (!city) { 
                    form = {
                        ...form,
                        state: value.short_name,
                        city: value.long_name
                    }
                } else {
                    form = {
                        ...form,
                        state: value.short_name
                    }
                }
			}
			if (value.types.includes('country')) {
				form = {
					...form,
					country: value.long_name,
					country_code: value.short_name
				}
			}
			if (value.types.includes('street_number')){
				streetNumber = value.long_name
			}

			if (value.types.includes('route')){
				streetName = value.long_name
			}

			//Note: Check Japan Country
            const isCheckCountries = handleCheckCountries(value)
			if(!isCheckCountries) {
				const part =  place.formatted_address.split(", ")
				companyAddress.push(part[0])
			}
		})

        if (!address_component_types.flat().includes('subpremise')) {
            apt_unit_number = ''
        }

        form = {
			...form,
			street: (streetNumber ? streetNumber + ' ' : '' ) + streetName
		}

        form = {
            ...form,
            full_address: place.formatted_address.replace(` ${apt_unit_number}`, ''),
            apt_unit_number: apt_unit_number
        }

		let defaultValues = {
			...watch(),
			store_address: form
		}
		reset(defaultValues);
  }

  const handleWatchFilterChange = (selectedValue, field) => {
    field.onChange(selectedValue);
    setSelectedWatchFilter(selectedValue);
    const customColumns = item?.space?.custom_columns || {};

    let labelFilterSelected = capitalize(selectedValue)
    let options = [];

    if (selectedValue === "brand") {
        options = item?.brand_options || [];
    } else if (customColumns[selectedValue]) {
        labelFilterSelected = customColumns[selectedValue].label

        options = customColumns[selectedValue].options.map((value) => {
          return {
            value,
            label: value
        }});
    }

    options = options.sort((a, b) => a.label.localeCompare(b.label));

    setBrandOptions(options);
    setSelectedWatchFilterLabel(labelFilterSelected);
    setShowBrandDropdown(options.length > 0);

    const defaultValues = {
        ...watch(),
        brand_filter: []
    };
    reset(defaultValues);
};

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {isEmpty(uid) ? (
              <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm bg-white bg-opacity-60 min-h-[600px]">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
                  disabled
                >
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    {' '}
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  <span>Loading... </span>
                </button>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-start my-12">
                    <Typography variant="h6" sx={{ fontWeight: 'bold',mr: 3, fontSize: 26 }}>Link</Typography>
                    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}} variant="outlined">
                        <div>
                          <span className='text-[#5D6E81] text-[16px]'>{item?.store_url}</span>
                        </div>
                        <Box
                            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            onClick={() => {
                              copyToClipboard(item?.store_url)
                            }}
                        >
                            <IconButton type="button" sx={{  color: '#A2ACBA' }} aria-label="search">
                                <ContentCopyIcon sx={{ fontSize: 20 }}/>
                            </IconButton>
                            <Typography sx={{ color: '#A2ACBA', mr: 2, width: 'max-content', fontSize: 16 }} variant="inherit">
                                {copyText}
                            </Typography>
                        </Box>
                    </Paper>
                    <ConfirmPopUp
                        onConfirm={handleRegenerateUniqueLink}
                        title="Confirm Regeneration"
                        content="Regenerating this link will retain your current settings but remove access for anyone who previously had this link. Are you sure you want to proceed?"
                    >
                        <img onClick={handleRegenerateUniqueLink} src="/Images/regenerate.svg" className='ml-4 cursor-pointer'/>
                    </ConfirmPopUp>
                </div>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 8, fontSize: 26 }}>General</Typography>
                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px] w-40">Display View</div>
                    <Controller
                        name="view_ui"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <RadioGroup {...field} row>
                                <FormControlLabel
                                    value={true}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            Grid View
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio size="small" />}
                                    label={
                                        <div className='flex items-center'>
                                            <Box sx={{mr:1}} component="div">
                                                Card View
                                            </Box>
                                            <Box component="div" fontSize={13}>
                                                (Recommended for End-User)
                                            </Box>
                                        </div>
                                    }
                                />
                            </RadioGroup>
                        )}
                    />
                </div>
                {displayError('view_ui')}

                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px] w-40">Display Images</div>
                        <Controller
                            name="images_visible"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                                <RadioGroup {...field} aria-labelledby="wholesale-price-visibility-group" row>
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio size="small" />}
                                        label={
                                            <Box component="div">
                                                Yes
                                            </Box>
                                        }
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio size="small" />}
                                        label={
                                            <Box component="div">
                                                No
                                            </Box>
                                        }
                                    />
                                </RadioGroup>
                            )}
                        />
                </div>

                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px]">Password Protected</div>

                    <Controller
                        name="password_required"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <RadioGroup {...field} row>
                                <FormControlLabel
                                    value={true}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            Yes
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            No
                                        </Box>
                                    }
                                />
                            </RadioGroup>
                        )}
                    />

                    {isPasswordRequired &&
                        <Controller
                            name="store_password"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => <TextField
                                {...field}
                                sx={{ ml: 2, minWidth: '350px' }}
                                label="Password"
                                variant="outlined"
                                size="small"
                                classes={{ root: isPasswordRequired ? '' : 'bg-gray-200' }}
                                type={showPassword ? "text" : "password"}
                                InputLabelProps={{style: {color: '#5D6E81'}}}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={handleShowPassword}
                                            sx={{ p: '10px', color: '#A2ACBA', cursor: 'pointer' }}
                                            aria-label="search"
                                        >
                                            {showPassword ? <VisibilityOffIcon  sx={{ fontSize: 20 }}/> : <VisibilityIcon sx={{ fontSize: 20 }}/>}
                                        </IconButton>
                                    ),
                                    style: {color: '#5D6E81'}
                                }}
                            />}
                        />
                    }

                </div>
                {displayError('store_password')}
                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px] " style={{ color: '#5D6E81'}}>Select watches based on...</div>
                    <Controller
                        name="watch_filter"
                        control={control}
                        defaultValue={"visible_on_marketplace"}
                        render={({ field }) => (
                            <TextField
                                select
                                {...field}
                                sx={{
                                    width: 430,
                                    ml: 2,
                                    '& .MuiInputBase-input': { height: 25 },
                                }}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ style: { color: '#5D6E81' } }}
                                inputProps={{ style: { color: '#5D6E81' } }}
                                onChange={(event) => {
                                        const selectedValue = event.target.value;
                                        handleWatchFilterChange(selectedValue, field)
                                    }}
                                >
                                <MenuItem value="visible_on_marketplace">Anything Visible in Marketplace</MenuItem>
                                <MenuItem value="on_hand_only">On Hand Only</MenuItem>
                                <MenuItem value="wholesale_price_not_blank">Wholesale Price is not blank</MenuItem>
                                <MenuItem value="website_price_not_blank">Website Price is not blank</MenuItem>
                                <MenuItem value="everything">All Watches</MenuItem>
                                <MenuItem value="brand">Brand</MenuItem>
                                {Object.entries(item?.space?.custom_columns || {})
                                    .filter(([key, value]) => value.field_type === 'list')
                                    .map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {value.label} <span style={{ fontStyle: "italic" }}>(custom)</span>
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </div>

                {/* Conditional Brand Dropdown */}
                {showBrandDropdown && (
                    <div className="flex items-center mt-4 ml-[22px]">
                    <div className="mr-6 text-[16px] min-w-[230px]" style={{ color: "#5D6E81" }}>Select {selectedWatchFilterLabel}...</div>
                    <Controller
                        name="brand_filter"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    size="small"
                                    options={brand_options}
                                    getOptionLabel={(option) => option.label}
                                    defaultValue={[]}
                                    filterSelectedOptions
                                    sx={{
                                        width: 430,
                                        "& .MuiInputBase-root": {
                                            minHeight: "40px",
                                            fontSize: "14px",
                                            padding: "2px 8px",
                                        },
                                        "& .MuiChip-root": {
                                            fontSize: "14px",
                                            height: "24px",
                                        },
                                        "& .MuiAutocomplete-input": {
                                            padding: "4px 6px",
                                        }
                                    }}
                                    value={field.value}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue)
                                    }}
                                    isOptionEqualToValue={(option, value) => option.label === value.label   } // Custom equality check
                                    PopperComponent={(props) => (
                                        <Popper
                                            {...props}
                                            placement="bottom-start" // Force dropdown to open downward
                                            modifiers={[
                                                {
                                                    name: "flip",
                                                    enabled: false, // Disable flipping (upward opening)
                                                },
                                            ]}
                                        />
                                    )}
                                    renderTags={(selected, getTagProps) =>
                                        selected.map((option, index) => {
                                          const { key, ...tagProps } = getTagProps({ index });
                                          return (
                                            <Chip
                                              key={key}
                                              label={option.label} // Directly use label
                                              variant="filled"
                                              {...tagProps}
                                            />
                                          );
                                        })
                                      }
                                    renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={selectedWatchFilterLabel}
                                    />
                                    )}
                                />
                            </>
                        )}
                        />
                    </div>
                )}

                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px]" style={{ color: '#5D6E81'}}>Show “On Hold” icon for watches with status...</div>
                    <Controller
                        name="on_hold_filter"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <Select
                            labelId={`on_hold_filter-label`}
                            id={`on_hold_filter-select`}
                            multiple
                            value={field.value}
                            sx={{
                                width: 280,
                                ml: 2,
                                '& .MuiInputBase-input': { height: 20, paddingY: "8.5px" },
                            }}
                            onChange={(event) => {
                                const newValue = event.target.value;
                                const currentValueSet = new Set(field.value);
                                const lastAdded = newValue.find((item) => !currentValueSet.has(item));
                                lastSelected.current = lastAdded;

                                if (lastAdded === 'none' || newValue.length === 0) {
                                    field.onChange(['none']);
                                } else if (newValue.includes('none')) {
                                    field.onChange(newValue.filter((item) => item !== 'none'));
                                } else {
                                    field.onChange(newValue);
                                }
                            }}
                            renderValue={(selected) =>
                            selected
                                .map(
                                (selectedValue) =>
                                    on_hold_options.find((option) => option.value === selectedValue)
                                    ?.label || ''
                                )
                                .join(', ')
                            }
                            >
                                {on_hold_options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <Checkbox checked={field.value?.includes(option.value)} />
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </div>

                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 8, fontSize: 26 }}>Pricing</Typography>

                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px]" style={{ color: isWholesalePriceEnabled ? '#5D6E81' : '#A0A0A0' }}>Which Price to display?</div>
                    <Controller
                        name="price_to_show"
                        control={control}
                        defaultValue={"wholesale_price"}
                        render={({ field }) => (
                            <TextField
                                select
                                {...field}
                                sx={{
                                    width: 210,
                                    ml: 2,
                                    '& .MuiInputBase-input': { height: 25 },
                                }}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ style: { color: '#5D6E81' } }}
                                inputProps={{ style: { color: '#5D6E81' } }}
                                disabled={!isWholesalePriceEnabled}
                            >
                                <MenuItem key="wholesale_price" value="wholesale_price">Wholesale Price</MenuItem>
                                <MenuItem key="online_price" value="online_price">Website Price</MenuItem>
                                <MenuItem key="msrp_price" value="msrp_price">MSRP Price</MenuItem>
                                {Object.entries(item?.space?.custom_columns || {})
                                    .filter(([key, value]) => value.field_type != 'list')
                                    .map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {capitalize(value.label)} <span style={{ fontStyle: "italic" }}>(custom)</span>
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                    <Controller
                        name="wholesale_price_visible"
                        control={control}
                        defaultValue={null}
                        render={({ field: {value} }) => (
                            <IconButton
                            aria-label="toggle email enabled"
                            onClick={() => setValue('wholesale_price_visible', !value)}
                            edge="end"
                            sx={{ ml: 1 }}
                        >
                            {value ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        )}
                    />
                </div>

                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px]">Mark-up your price?</div>
                    <span className="w-9"></span>
                    <Controller
                        name="markup_price_percentage"
                        control={control}
                        render={({ field }) => {
                            return(
                                <TextField
                                    {...field}
                                    sx={{ width: 100, minWidth: 100}}
                                    label="% Mark Up"
                                    value={isNil(field.value) ? '' : field.value}
                                    inputProps={{style: {color: '#5D6E81'}}}
                                    InputLabelProps={{style: {color: '#5D6E81', fontSize: '15px'}}}
                                    variant="outlined"
                                    size="small"
                                    onChange={(event) => {
                                        handleChangeWholesalePrice(event)
                                    }}
                                />
                            )
                        }}
                    />
                    <Controller
                        name="markup_price_fixed"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                {...field}
                                sx={{ width: 100, minWidth:100, ml: 2,  "& .MuiInputBase-input": { height: 25 }}}
                                label="$ Mark Up"
                                variant="outlined"
                                value={isNil(field.value) ? '' : field.value}
                                size="small"
                                inputProps={{style: {color: '#5D6E81'}}}
                                InputLabelProps={{style: {color: '#5D6E81', fontSize: '15px'}}}
                                onChange={(event) => {
                                    handleChangeWholesalePrice(event)
                                }}
                            />
                        }
                    />
                </div>

                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 8, fontSize: 26 }}>Company Details</Typography>

                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px] w-40" style={{ color: '#5D6E81' }}>Company Name</div>
                    <Controller
                            name="store_company_name"
                            control={control}
                            defaultValue={''}
                            render={({ field }) =>
                            <TextField {...field}
                                sx={{ ml: 2, minWidth: '400px' }}
                                inputProps={{
                                    style: { color: '#5D6E81'},
                                    className: 'disabled:!bg-gray-200'
                                }}
                                InputLabelProps={{style: {color: '#5D6E81'}}}
                                variant="outlined"
                                size="small"
                            />}
                        />
                </div>

                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px] w-40 pb-10" style={{ color: isStoreAddressEnabled ? '#5D6E81' : '#A0A0A0' }}>Address</div>
                    <Controller
                            name="store_address"
                            control={control}
                            defaultValue={{
                                full_address: '',
                                apt_unit_number: '',
                                city: '',
                                street: '',
                                state: '',
                                zip_code: '',
                                country: ''
                            }}
                            render={({ field: { onChange, value } }) => (
                                <div className="ml-[16px]">
                                    <div className="outlin relative flex flex-col gap-2">
                                        {!isManual ? (
                                            <>
                                                {/* // Auto-Suggest Address Input */}
                                                <div>
                                                    <ServerAutoSuggestAddress
                                                        formData={{ full_address: value?.full_address || '' }}
                                                        value={value.full_address || ''}
                                                        handleChangeGoogleAddress={handleChangeGoogleAddress}
                                                        disabled={!isStoreAddressEnabled}
                                                        setValue={(newValue) => {
                                                            onChange({
                                                                ...value,
                                                                full_address: newValue
                                                            })
                                                        }}
                                                        className={
                                                            'block pl-[12px] pt-5 pb-3 min-w-[400px] w-[251px] h-[49px] inputLogin appearance-none outline-none text-[#5D6E81] !font-semibold text-[14px] border-[#D9E2EE] nowrap truncate overflow-hidden hover:text-clip'
                                                        }
                                                        style={{backgroundColor: isStoreAddressEnabled ? '#ffffff' : '#e5e7eb'}}
                                                        view="customer"
                                                    />
                                                </div>
                                                <div>
                                                    <input 
                                                        type="text"
                                                        name="apt_unit_number"
                                                        placeholder="Apt Unit Number"
                                                        value={value?.apt_unit_number || ''}
                                                        onChange={(e) =>
                                                            onChange({
                                                                ...value,
                                                                apt_unit_number: e.target.value
                                                            })
                                                        }
                                                        disabled={!isStoreAddressEnabled}
                                                        className={`block px-[12px] pt-5 pb-3 min-w-[251px] h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] ${
                                                            isStoreAddressEnabled ? 'text-[#5D6E81]' : 'text-[#A0A0A0]'
                                                        } !font-semibold text-[14px]`}
                                                        style={{backgroundColor: isStoreAddressEnabled ? '#ffffff' : '#e5e7eb'}}
                                                    />
                                                </div>
                                                
                                            </>
                                        ) : (
                                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '16px'}}>
                                                {/* Full Address Input */}
                                                <input
                                                    type="text"
                                                    name="full_address"
                                                    placeholder="Street Address"
                                                    value={value?.street || ''}
                                                    onChange={(e) =>
                                                        onChange({
                                                            ...value,
                                                            street: e.target.value
                                                        })
                                                    }
                                                    disabled={!isStoreAddressEnabled}
                                                    className={`block px-[12px] pt-5 pb-3 min-w-[251px] h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] ${
                                                        isStoreAddressEnabled ? 'text-[#5D6E81]' : 'text-[#A0A0A0]'
                                                    } !font-semibold text-[14px]`}
                                                    style={{backgroundColor: isStoreAddressEnabled ? '#ffffff' : '#e5e7eb'}}
                                                />
                                                {/* Apt/Unit Number */}
                                                <input
                                                    type="text"
                                                    name="apt_unit_number"
                                                    placeholder="Apt/Unit #"
                                                    defaultValue={value?.apt_unit_number || ''}
                                                    onChange={(e) =>
                                                        onChange({
                                                            ...value,
                                                            apt_unit_number: e.target.value
                                                        })
                                                    }
                                                    disabled={!isStoreAddressEnabled}
                                                    className={`block px-[12px] pt-5 pb-3 min-w-[251px] h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] ${
                                                        isStoreAddressEnabled ? 'text-[#5D6E81]' : 'text-[#A0A0A0]'
                                                    } !font-semibold text-[14px]`}
                                                    style={{backgroundColor: isStoreAddressEnabled ? '#ffffff' : '#e5e7eb'}}
                                                />
                                                {/* City */}
                                                <input
                                                    type="text"
                                                    name="city"
                                                    placeholder="City"
                                                    defaultValue={value?.city || ''}
                                                    onChange={(e) =>
                                                        onChange({
                                                            ...value,
                                                            city: e.target.value
                                                        })
                                                    }
                                                    disabled={!isStoreAddressEnabled}
                                                    className={`block px-[12px] pt-5 pb-3 min-w-[251px] h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] ${
                                                        isStoreAddressEnabled ? 'text-[#5D6E81]' : 'text-[#A0A0A0]'
                                                    } !font-semibold text-[14px]`}
                                                    style={{backgroundColor: isStoreAddressEnabled ? '#ffffff' : '#e5e7eb'}}
                                                />
                                                {/* State */}
                                                <input
                                                    type="text"
                                                    name="state"
                                                    placeholder="State"
                                                    defaultValue={value?.state || ''}
                                                    onChange={(e) =>
                                                        onChange({
                                                            ...value,
                                                            state: e.target.value
                                                        })
                                                    }
                                                    disabled={!isStoreAddressEnabled}
                                                    className={`block px-[12px] pt-5 pb-3 min-w-[251px] h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] ${
                                                        isStoreAddressEnabled ? 'text-[#5D6E81]' : 'text-[#A0A0A0]'
                                                    } !font-semibold text-[14px]`}
                                                    style={{backgroundColor: isStoreAddressEnabled ? '#ffffff' : '#e5e7eb'}}
                                                />
                                                {/* Zip Code */}
                                                <input
                                                    type="text"
                                                    name="zip_code"
                                                    placeholder="Zip Code"
                                                    defaultValue={value?.zip_code || ''}
                                                    onChange={(e) =>
                                                        onChange({
                                                            ...value,
                                                            zip_code: e.target.value
                                                        })
                                                    }
                                                    disabled={!isStoreAddressEnabled}
                                                    className={`block px-[12px] pt-5 pb-3 min-w-[251px] h-[49px] inputLogin appearance-none outline-none border-[#D9E2EE] ${
                                                        isStoreAddressEnabled ? 'text-[#5D6E81]' : 'text-[#A0A0A0]'
                                                    } !font-semibold text-[14px]`}
                                                    style={{backgroundColor: isStoreAddressEnabled ? '#ffffff' : '#e5e7eb'}}
                                                />
                                                {/* Country Dropdown */}
                                                <select
                                                    id="country"
                                                    name="country"
                                                    value={value?.country || ''}
                                                    onChange={(e) =>
                                                        onChange({
                                                            ...value,
                                                            country: e.target.value
                                                        })
                                                    }
                                                    disabled={!isStoreAddressEnabled}
                                                    className={`block px-[12px] min-w-[251px]  h-[49px] inputLogin appearance-none outline-none ${
                                                        isStoreAddressEnabled ? 'text-[#5D6E81]' : 'text-[#A0A0A0]'
                                                    } !font-semibold text-[14px] border-[#D9E2EE]`}
                                                    style={{backgroundColor: isStoreAddressEnabled ? '#ffffff' : '#e5e7eb'}}
                                                >
                                                    <option value="" disabled>
                                                        Choose a country
                                                    </option>
                                                    {Globals.countries.map((c) => (
                                                        <option key={c.code} value={c.code}>
                                                            {c.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                        {/* Toggle Between Manual and Auto-Suggest */}
                                        <div className="mb-2">
                                            <span
                                                onClick={() => setIsManual(!isManual)}
                                                className="cursor-pointer underline text-sm text-[#5D6E81]"
                                            >
                                                {isManual
                                                    ? 'Switch to Auto-Suggest'
                                                    : 'Add Address Manually'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                        <Controller
                            name="store_address_enabled"
                            control={control}
                            defaultValue={null}
                            render={({ field: {value} }) => (
                                <IconButton
                                aria-label="toggle email enabled"
                                onClick={() => setValue('store_address_enabled', !value)}
                                edge="end"
                                sx={{ ml: 1, marginBottom: '40px' }}
                            >
                                {value ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                            )}
                        />
                </div>

                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px] w-40" style= {{ color: isStoreEmailEnabled ? '#5D6E81' : '#A0A0A0'}}>E-Mail</div>
                        <Controller
                            name="store_email"
                            control={control}
                            defaultValue={''}
                            render={({ field }) =>
                            <TextField {...field}
                                sx={{ ml: 2, minWidth: '400px' }}
                                inputProps={{
                                    style: { color: isStoreEmailEnabled ? '#5D6E81' : '#A0A0A0'},
                                    className: 'disabled:!bg-gray-200'
                                }}
                                InputLabelProps={{style: {color: '#5D6E81'}}}
                                variant="outlined"
                                size="small"
                                type="email"
                                disabled={!isStoreEmailEnabled}
                            />}
                        />
                        <Controller
                            name="store_email_enabled"
                            control={control}
                            defaultValue={null}
                            render={({ field: {value} }) => (
                                <IconButton
                                aria-label="toggle email enabled"
                                onClick={() => setValue('store_email_enabled', !value)}
                                edge="end"
                                sx={{ ml: 1 }}
                            >
                                {value ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                            )}
                        />
                </div>

                <div className="flex items-center mt-4 ml-6">
                    <div className="mr-6 text-[16px] w-40" style= {{ color: isStorePhoneEnabled ? '#5D6E81' : '#A0A0A0' }}>Phone Number</div>
                    <Controller
                            name="store_phone"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => <TextField
                                {...field}
                                sx={{ ml: 2, minWidth: '400px' }}
                                variant="outlined"
                                size="small"
                                disabled={!isStorePhoneEnabled}
                                inputProps={{
                                    style: { color: isStorePhoneEnabled ? '#5D6E81' : '#A0A0A0'},
                                    className: 'disabled:!bg-gray-200'
                                }}
                                InputLabelProps={{style: {color: '#5D6E81'}}}
                                value={formatPhoneNumber(field.value)}
                                onChange={(e) => {
                                    field.onChange(formatPhoneNumber(e.target.value))
                                }}
                            />}
                        />
                        <Controller
                            name="store_phone_enabled"
                            control={control}
                            defaultValue={null}
                            render={({ field: {value} }) => (
                                <IconButton
                                aria-label="toggle phone enabled"
                                onClick={() => setValue('store_phone_enabled', !value)}
                                edge="end"
                                sx={{ ml: 1 }}
                            >
                                {value ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                            )}
                        />

                </div>

                <div className="mt-2 w-1/2 ml-6">
                    <label htmlFor="Resale Certificate*" className=" text-base bg-transparent font-normal py-2">
                        Logo
                    </label>
                    <div className="outlin relative w-full">
                        {!logo?.id && (
                            <UploadMedia
                                innerClassNames="gap-5 grid grid-cols-2 my-5 upload-media-container"
                                text="Logo"
                                uploadTo="uploadlogo"
                                maximum={1}
                                uploadUpdate={uploadImages}
                                allowedTypes={['image/jpeg', 'image/jpg', 'image/png']}
                                multipe={false}
                                handleLoadingUploadImage={handleLoadingUploadImage}
                            />
                        )}
                        {logo?.id && (
                            <UploadMedia
                                key={logo.id}
                                innerClassNames="gap-5 grid grid-cols-2 my-5 upload-media-container"
                                text="Upload"
                                maximum={1}
                                uploadUpdate={uploadImages}
                                uploadTo="uploadlogo"
                                defaultValue={[logo]}
                                allowedTypes={['image/jpeg', 'image/jpg', 'image/png']}
                                multipe={false}
                                handleLoadingUploadImage={handleLoadingUploadImage}
                            />
                        )}
                    </div>
                </div>

                <div className="flex items-center mt-12 justify-center">
                    {
                        isPersisted() && profile?.role === 'owner' && (
                            <ConfirmPopUp
                                onConfirm={handleDeleteStore}
                                title="Confirm deleting web store settings"
                                content="Are you sure you want to delete it?"
                            >
                                <div
                                    className="flex justify-center p-2 lg:p-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-[#FF0000] cursor-pointer uppercase border border-[#FF0000] w-[90px] h-[47px] mr-12"
                                >
                                    <span>Delete</span>
                                </div>
                        </ConfirmPopUp>
                        )
                    }
                    {
                        !isPersisted() && (
                            <ConfirmPopUp
                                onConfirm={() => handleCancelWebstore(item)}
                                title="Confirm cancelling web store settings"
                                content="Are you sure you want to cancel it?"
                            >
                                <div
                                    className="flex justify-center p-2 lg:p-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-[#FF0000] cursor-pointer uppercase border border-[#FF0000] w-[90px] h-[47px] mr-12"
                                >
                                    <span>Cancel</span>
                                </div>
                        </ConfirmPopUp>
                        )
                    }
                    <button
                        className="p-2 lg:p-3 rounded-md text-xs font-bold leading-5 lg:text-sm text-[#2C79E0] cursor-pointer uppercase border border-[#2C79E0] w-[90px] h-[47px]"
                        type="submit"
                    >
                        <span>Save</span>
                    </button>
                </div>
              </>
            )}
        </form>
    )
}

export default WebStoreContent
