import qz from 'qz-tray'
import {useCallback, useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {handlePrice} from '../Components/Account/settings/QRCode/components/QRPreviewBlock'
import useQRCodeSettings from './useQRCodeSettings'
import {addToast} from './../app/appActions'
import {useDispatch} from 'react-redux'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

let defaultSize = {width: 100, height: 15}
let toggleLH = false // ✅ This will alternate between 0,0 and 1,1

export const mockedPrintingConfig = {
	printer: '',
	sizing: {
		width: 4,
		height: 1,
	},
	spacing: {
		col1: {
			x: 20,
			y: 20,
			gap: 20,
			font_size: 18,
		},
		col2: {
			x: 100,
			y: 0,
			gap: 20,
			size: 3,
		},
		col3: {
			x: 230,
			y: 10,
			gap: 20,
			font_size: 18,
		},
	},
}

const storageConfig = localStorage.getItem('printingConfig') ? JSON.parse(localStorage.getItem('printingConfig')) : mockedPrintingConfig

const useQzTray = () => {
	const [configState, setConfigState] = useState(null)
	const [printersList, setPrintersList] = useState(null)
	const {getStickerSettings, stickerSettings} = useQRCodeSettings()

	const dispatch = useDispatch()

	const getPrinters = useCallback(async () => {
		if (!qz.websocket.isActive()) {
			await qz.websocket.connect()
		}
		return await qz.printers.find().then(printers => {
			setPrintersList(printers)
			return printers
		})
	}, [])

	useEffect(() => {
		getStickerSettings()
	}, [])

	const formPPLZCommands = useCallback(({stickerData, printingConfig = storageConfig}) => {
		const dpi = 203
		const {
			value: {
				sizing,
				spacing: {col1, col2, col3},
			},
		} = printingConfig

		const widthDots = sizing.width * dpi
		const heightDots = sizing.height * dpi
		toggleLH = !toggleLH // ✅ Flip the LH value on each print

		const lhX = toggleLH ? 1 : 0
		const lhY = toggleLH ? 1 : 0
		const commands = [
			'^XA', // Start ZPL
			'^XA^JC^XZ', // ✅ Auto-calibrate media (paper, gaps, etc.)
			'^XA^JUS^XZ',
			'^XA',
			'^JUS', // Reset print settings before printing
			'^LT0', // Disable auto label adjustment
			`^LH${lhX},${lhY}`, // Set label home position to avoid shifts
			`^LL${Math.round(heightDots) + 10}`, // Fixed label length
			`^PW${Math.round(widthDots)}`, // Fixed print width
			'^LS0', // No label shift
			'^PRA', // Prevent print buffering
			'^PR2,2', // Adjust speed
			'^MD10', // Adjust darkness

			`^FO${col1.x},${col1.y}^A0N,${Number(col1.font_size) || 18 + 4},${Number(col1.font_size) || 18 + 4}^FD ^FS`, // empty line to avoid printing issues
			`^FO${col1.x},${Number(col1.y) + 1}^A0N,${Number(col1.font_size) || 18 + 4},${Number(col1.font_size) || 18 + 4}^FD${stickerData.sku}^FS`,
			stickerData.price1
				? `^FO${Number(col1.x)},${Number(col1.y) + Number(col1.gap)}^A0N,${Number(col1.font_size) || 18},${Number(col1.font_size) || 18}^FD${handlePrice(stickerData.price1, true)}^FS`
				: null,
			stickerData.price2
				? `^FO${Number(col1.x)},${Number(col1.y) + Number(col1.gap) * 2}^A0N,${Number(col1.font_size) || 18},${Number(col1.font_size) || 18}^FD${handlePrice(stickerData.price2)}^FS`
				: null,
			stickerData.price3
				? `^FO${Number(col1.x)},${Number(col1.y) + Number(col1.gap) * 3}^A0N,${Number(col1.font_size) || 18},${Number(col1.font_size) || 18}^FD${handlePrice(stickerData.price3)}^FS`
				: null,

			`^FO${col2.x},${col2.y}^BQN,3,${col2.qrSize || 3}^FDA,${stickerData.short_url}^FS`,

			stickerData.brand ? `^FO${Number(col3.x)},${Number(col3.y)}^A0N,${Number(col3.font_size) || 18},${Number(col3.font_size) || 18}^FD${stickerData.brand}^FS` : null,
			stickerData.series ? `^FO${Number(col3.x)},${Number(col3.y) + Number(col3.gap)}^A0N,${Number(col3.font_size) || 18},${Number(col3.font_size) || 18}^FD${stickerData.series}^FS` : null,
			stickerData.serial_number
				? `^FO${Number(col3.x)},${Number(col3.y) + Number(col3.gap) * 2}^A0N,${Number(col3.font_size) || 18},${Number(col3.font_size) || 18}^FD${stickerData.serial_number}^FS`
				: null,
			stickerData.reference_number
				? `^FO${Number(col3.x)},${Number(col3.y) + Number(col3.gap) * 3}^A0N,${Number(col3.font_size) || 18},${Number(col3.font_size) || 18}^FD${stickerData.reference_number}^FS`
				: null,
			stickerData.scope_of_delivery
				? `^FO${Number(col3.x)},${Number(col3.y) + Number(col3.gap) * 4}^A0N,${Number(col3.font_size) || 18},${Number(col3.font_size) || 18}^FD${stickerData.scope_of_delivery}^FS`
				: null,
			'^XZ',
		]
		return commands.filter(value => !!value)
	}, [])

	const formStickerData = useCallback(
		({watchData, customPrintingSettings}) => {
			const configSource = customPrintingSettings || stickerSettings
			return {
				sku: watchData.sku,
				price1: configSource[2].visible ? watchData?.cost * configSource?.[2]?.value : null,
				price2: configSource[1].visible ? watchData[configSource?.[1]?.value] || watchData.custom_column_values?.[configSource?.[0]?.value] : null,
				price3: configSource[0]?.visible ? watchData[configSource?.[0]?.value] || watchData.custom_column_values?.[configSource?.[0]?.value] : null,
				brand: configSource[3].visible ? watchData.brand : null,
				series: configSource[4].visible ? watchData.series : null,
				reference_number: configSource[5].visible ? watchData.reference_number : null,
				serial_number: configSource[6].visible ? watchData.serial_number : null,
				scope_of_delivery: configSource[7].visible
					? `${watchData.box ? 'B' : ''} ${(watchData.box && watchData.warranty_papers && watchData.warranty_papers !== 'no' && '+ ') || ''}${
							watchData.warranty_papers && watchData.warranty_papers !== 'no' ? 'P' : ''
					  } ${watchData.warranty ? moment(watchData.warranty).format('YYYY') : ''}`
					: null,
				short_url: watchData.short_url,
			}
		},
		[stickerSettings]
	)

	const printPPLZ = useCallback(
		async ({watchData, customPrintingSettings}) => {
			if (!qz.websocket.isActive()) {
				await qz.websocket.connect()
			}
			let printer = customPrintingSettings[customPrintingSettings.length - 1].value.printer
			if (!printer) {
				dispatch(addToast({text: 'Please select a printer', type: 'error'}))
				return
			}

			let config = configState
			let printerSettings = customPrintingSettings.find(item => item.key === 'printer_settings')?.value || {}
			const {width, height} = printerSettings?.sizing || {}
			let printingSize = null
			if (width && height) {
				printingSize = {
					width: Math.round(width * 25.4 * 100) / 100,
					height: Math.round(height * 25.4 * 100) / 100,
				}
			} else {
				printingSize = defaultSize
			}
			if (!config || customPrintingSettings) {
				config = qz.configs.create(customPrintingSettings?.printer || printer, {
					encoding: 'ISO-8859-1', // Forces QZ Tray to send raw data
					altPrinting: true, // Enables alternative printing mode (Recommended)
					spool: false, // Disables OS print spooling
					rasterize: false, // Prevents image conversion
					copies: 1, // Ensures only one copy is printed
					size: {width: printingSize.width, height: printingSize.height}, // Matches label size (in mm)
					units: 'mm', // Units for label size
					margin: {top: 0, right: 0, bottom: 0, left: 0}, // Removes auto margins
				})
				setConfigState(config)
			}
			const stickerData = formStickerData({watchData, customPrintingSettings})
			const printingConfig = customPrintingSettings.find(item => item.key === 'printer_settings')
			const commands = formPPLZCommands({
				stickerData,
				printingConfig,
			})
			await qz.print(config, commands)
		},
		[configState, formStickerData, formPPLZCommands]
	)

	const createAndUsePdf = async printingSize => {
		const element = document.getElementById('qr-render')
		element.style.border= "none"
		element.style.transform= "rotate(180deg)"
		// 1. Render to canvas (keep it 1:1 scale with accurate sizing)
		const canvas = await html2canvas(element, {
			useCORS: true,
			backgroundColor: '#fff',
			scale: 2, // Sharper image without stretching PDF
		})

		// 2. Get image data from canvas
		const imgData = canvas.toDataURL('image/png')

		// 3. Convert canvas pixel size to mm (DPI 96)
		const pxToMm = px => (px * 25.4) / 96
		const imgMmWidth = pxToMm(canvas.width)
		const imgMmHeight = pxToMm(canvas.height)

		// 4. Final label size (half-page or full label size)
		const pdfWidth = printingSize.width
		const pdfHeight = printingSize.height

		// 5. Optional: scale image to fit half the label
		const scaleFactor = 0.5 // ✅ You want it to take 1/2 of the label
		const displayWidth = imgMmWidth * scaleFactor
		const displayHeight = imgMmHeight * scaleFactor

		// 6. Center the image or align left
		const marginX = pdfWidth / 2 // Left-aligned
		const marginY = (pdfHeight - displayHeight) / 2

		// 7. Create PDF
		const pdf = new jsPDF({
			orientation: 'landscape',
			unit: 'mm',
			format: [pdfWidth, pdfHeight],
		})

		// 8. Draw image at real size (not stretched)
		pdf.addImage(imgData, 'PNG', marginX, marginY, displayWidth, displayHeight)

		// ✅ Optional: Save and return base64
		pdf.save('label.pdf')
		element.style.border= "1px solid rgba(0, 0, 0, 0.5)"
		element.style.transform= "rotate(0deg)"
		const base64 = btoa(pdf.output())
		return base64
	}
	const printStickerImage = async ({customPrintingSettings}) => {
		let printerSettings = customPrintingSettings.find(item => item.key === 'printer_settings')?.value || {}
		const {width, height} = printerSettings?.sizing || {}
		let printingSize = null
		if (width && height) {
			printingSize = {
				width: Math.round(width * 25.4 * 100) / 100,
				height: Math.round(height * 25.4 * 100) / 100,
			}
		} else {
			printingSize = defaultSize
		}

		 await createAndUsePdf(printingSize)
	}

	return useMemo(
		() => ({
			getPrinters,
			printersList,
			printPPLZ,
			formPPLZCommands,
			formStickerData,
			stickerSettings,
			printStickerImage,
		}),
		[getPrinters, printPPLZ, formPPLZCommands, formStickerData, stickerSettings, printersList, printStickerImage]
	)
}
export default useQzTray
