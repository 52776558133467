export const permissionsConfig = {
	owner: {
		inventoryColumnsCRUD: true,
		createSpace: true,
		inventoryAddEditNotes: true,
		inventoryDeleteNotes: true,
		inventoryAddEditRepairs: true,
		inventoryDeleteRepairs: true,
		inventoryAddSingleWatch: true,
		inventoryViewHistory: true,
		inventoryBulkUpload: true,
		inventoryShare: true,
		inventoryUpdateWatch: true,
		inventoryDeleteWatch: true,
		inventoryViewSensitiveInformation: true,
		memosCreateEditUpdate: true,
		salesCreateEditUpdate: true,
		settingsUpdateSpaceDetails: true,
		settingsAddRemoveTeamMembers: true,
		settingsWebstoresAddEditUpdateLinks: true,
		settingsWebstoresDeleteLinks: true,
		settingsPaymentRemindersSetChangeOptions: true,
		settingsSensitiveInformationAdjustUpdate: true,
		customersAddEdit: true,
		customersDelete: true,
		marketplaceShareSave: true,
		marketplaceMessageSeller: true,
		qrCreate: true,
		qrDelete: true,
		settingsPDP: true,
		settingsInvoice: true,
		settingsManageSubscriptions: true,
		memosCanView: true,
		customersCanView: true,
		analyticsCanView: true,
	},
	admin: {
		inventoryColumnsCRUD: true,
		createSpace: true,
		inventoryAddEditNotes: true,
		inventoryDeleteNotes: true,
		inventoryAddEditRepairs: true,
		inventoryDeleteRepairs: true,
		inventoryAddSingleWatch: true,
		inventoryViewHistory: true,
		inventoryBulkUpload: true,
		inventoryShare: true,
		inventoryUpdateWatch: true,
		inventoryDeleteWatch: true,
		inventoryViewSensitiveInformation: true,
		memosCreateEditUpdate: true,
		salesCreateEditUpdate: true,
		settingsUpdateSpaceDetails: true,
		settingsAddRemoveTeamMembers: false,
		settingsWebstoresAddEditUpdateLinks: true,
		settingsWebstoresDeleteLinks: false,
		settingsPaymentRemindersSetChangeOptions: true,
		settingsSensitiveInformationAdjustUpdate: true,
		customersAddEdit: true,
		customersDelete: true,
		marketplaceShareSave: true,
		marketplaceMessageSeller: true,
		qrCreate: true,
		qrDelete: true,
		memosCanView: true,
		customersCanView: true,
		analyticsCanView: true,
	},
	editor: {
		createSpace: false,
		inventoryAddEditNotes: true,
		inventoryDeleteNotes: false,
		inventoryAddEditRepairs: true,
		inventoryDeleteRepairs: false,
		inventoryAddSingleWatch: true,
		inventoryViewHistory: false,
		inventoryBulkUpload: false,
		inventoryShare: true,
		inventoryUpdateWatch: true,
		inventoryDeleteWatch: false,
		inventoryViewSensitiveInformation: true,
		memosCreateEditUpdate: true,
		salesCreateEditUpdate: true,
		settingsUpdateSpaceDetails: false,
		settingsAddRemoveTeamMembers: false,
		settingsWebstoresAddEditUpdateLinks: false,
		settingsWebstoresDeleteLinks: false,
		settingsPaymentRemindersSetChangeOptions: false,
		settingsSensitiveInformationAdjustUpdate: false,
		customersAddEdit: true,
		customersDelete: false,
		marketplaceShareSave: true,
		marketplaceMessageSeller: true,
		qrCreate: true,
		qrDelete: false,
		memosCanView: true,
		customersCanView: true,
	},
	manager: {
		inventoryShare: true,
		marketplaceShareSave: true,
	}
};
// todo: permissions array order
// On Log-In: Create new space
// Inventory: Columns (Add, Edit, Delete)
// Inventory: Add or Edit Notes
// Inventory: Delete Notes
// Inventory: Add or Edit Repairs
// Inventory: Delete Repairs
// Inventory: Add Single Watch
// Inventory: View History
// Inventory: Bulk Upload
// Inventory: Share
// Inventory: Update Watch
// Inventory: Delete Watch
// Inventory: View sensitive information
// Memos: Create, Edit, Update
// Sales: Create, Edit, Update
// Settings: Update Space Details
// Settings: Add or Remove Team Members
// Settings - Webstores: Add, Edit, Update Links
// Settings - Webstores: Delete Links
// Settings - Payment Reminders: Set or change options
// Settings - Sensitive Information: Adjust or Update
// Customers: View
// Customers: Add or Edit
// Customers: Delete
// Marketplace: Share or Save
// Marketplace: Message Seller
// Inventory: QR Code
// Inventory: QR Code (delete)
// Analytics Page: View
export const rolesArray = [
	{
		label: "Owner",
		fieldKey: "owner",
	},
	{
		label: "Admin",
		fieldKey: "admin",
	},
	{
		label: "Editor",
		fieldKey: "editor",
	},
	{
		label: "Manager",
		fieldKey: "manager",
	}
]
export const permissionsArray = [
	{
		label: 'On Log-In: Create new space',
		fieldKey: 'createNewSpace',
	},
	{
		label: 'Inventory: Columns (Add, Edit, Delete)',
		fieldKey: 'inventoryColumnsCRUD',

	},
	{
		label: "Inventory: Add or Edit Notes",
		fieldKey: "inventoryAddEditNotes",
	},
	{
		label: 'Inventory: Delete Notes',
		fieldKey: 'inventoryDeleteNotes',
	},
	{
		label: 'Inventory: Add or Edit Repairs',
		fieldKey: 'inventoryAddEditRepairs',
	},
	{
		label: 'Inventory: Delete Repairs',
		fieldKey: 'inventoryDeleteRepairs',
	},
	{
		label: 'Inventory: Add Single Watch',
		fieldKey: 'inventoryAddSingleWatch',
	},
	{
		label: 'Inventory: View History',
		fieldKey: 'inventoryViewHistory',
	},
	{
		label: 'Inventory: Bulk Upload',
		fieldKey: 'inventoryBulkUpload',
	},
	{
		label: 'Inventory: Share',
		fieldKey: 'inventoryShare',
	},
	{
		label: 'Inventory: Update Watch',
		fieldKey: 'inventoryUpdateWatch',
	},
	{
		label: 'Inventory: Delete Watch',
		fieldKey: 'inventoryDeleteWatch',
	},
	{
		label: 'Inventory: QR Code',
		fieldKey: 'qrCreate',
	},
	{
		label: 'Inventory: QR Code (delete)',
		fieldKey: 'qrDelete',
	},
	{
		label: 'Inventory: View sensitive information',
		fieldKey: 'inventoryViewSensitiveInformation',
	},
	{
		label: 'Memos: View',
		fieldKey: 'memosCanView',
	},
	{
		label: 'Memos: Create, Edit, Update',
		fieldKey: 'memosCreateEditUpdate',
	},
	{
		label: 'Sales: Create, Edit, Update',
		fieldKey: 'salesCreateEditUpdate',
	},
	{
		label: 'Settings: Update Space Details',
		fieldKey: 'settingsUpdateSpaceDetails',
	},
	{
		label: 'Settings: Add or Remove Team Members',
		fieldKey: 'settingsAddRemoveTeamMembers',
	},
	{
		label: 'Settings - Webstores: Add, Edit, Update Links',
		fieldKey: 'settingsWebstoresAddEditUpdateLinks',
	},
	{
		label: 'Settings - Webstores: Delete Links',
		fieldKey: 'settingsWebstoresDeleteLinks',
	},
	{
		label: 'Settings - Payment Reminders: Set or change options',
		fieldKey: 'settingsPaymentRemindersSetChangeOptions',
	},
	{
		label: 'Settings - Sensitive Information: Adjust or Update',
		fieldKey: 'settingsSensitiveInformationAdjustUpdate',
	},
	{
		label: 'Settings - PDP',
		fieldKey: 'settingsPDP',
	},
	{
		label: 'Settings - Invoice',
		fieldKey: 'settingsInvoice',
	},
	{
		label: 'Settings - Manage Subscriptions',
		fieldKey: 'settingsManageSubscriptions',
	},
	{
		label: 'Customers: View',
		fieldKey: 'customersCanView',
	},
	{
		label: 'Customers: Add or Edit',
		fieldKey: 'customersAddEdit',
	},
	{
		label: 'Customers: Delete',
		fieldKey: 'customersDelete',
	},
	{
		label: 'Marketplace: Share or Save',
		fieldKey: 'marketplaceShareSave',
	},
	{
		label: 'Marketplace: Message Seller',
		fieldKey: 'marketplaceMessageSeller',
	},
	{
		label: 'Analytics Page: View',
		fieldKey: 'analyticsCanView',
	}
]
