import ClassNames from 'classnames'
import ActionCable from "actioncable";
import {v4 as uuidv4} from 'uuid'
import React from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import {connect} from 'react-redux'
import Moment from 'moment'
import 'react-swipeable-list/dist/styles.css';

import Tooltip from '@mui/material/Tooltip'
import {Globals} from '../Context'
import SideBar from '../Components/SideBar'
import TopBar from '../Components/TopBar'
import {addToast, toggleSidebar, getConversationsCount, setMarketplaceFilters, getUnopenedConversationCount} from './../app/appActions'
import './Messages.scss'
import Info from '../Components/Inventory/Info'
import {withRouter} from '../utils/withRouter';
import FilterListIcon from '@mui/icons-material/FilterList';

import './Discussion.scss'
import ProductMessageGallery from '../Components/Helper/ProductMessageGallery'
import SidebarItem from '../Components/Messages/SidebarItem'
import SlateEditor from '../Components/Messages/SlateEditor'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import UserProfileModal from '../Components/UserProfileModal'
import ShareEmailDialog from '../Components/Inventory/ShareEmailDialog'
import {onPressFocusSearchInput} from '../utils/keyboardHandler'
import { generateFormData } from '../utils/generateIndividualFormData';
import isEmpty from 'lodash/isEmpty'
import {Emoji} from 'emoji-picker-react'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import findIndex from 'lodash/findIndex'
import debounce from 'lodash/debounce'
import flatten from 'lodash/flatten'
import head from 'lodash/head'
import map from 'lodash/map'

let CableApp = {}

const withParams = Component => {
	return props => <Component {...props} params={useParams()} searchParams={useSearchParams()}/>
}

const withTheme = Component => {
	return props => {
		const theme = useTheme()
		const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
		return <Component {...props} theme={theme} isMobile={isMobile}/>
	}
}

class Discussions extends React.Component {
	constructor() {
		super()
		this.searchInputRef = React.createRef()
		this.instanceRef = React.createRef();
		this.state = {
			activeThreadTab: 'sell',
      		previousActiveTab: 'sell',
			chatInputText: '',
			activeScrollDown: false,
			threads: [],
			threadsLoaded: false,
			messages: [],
			conversationLoaded: [],
			conversationOffset: 0,
			selectedThread: null,
			showUnReadOnly: false,
			isTryingToReachout: false,
			reachoutProduct: null,

			visibleDetailsDrawer: false,
			parmVal: '',
			conversationId: '',
			openNoteModal: false,
			openRepairModal: false,
			openEditWatchModal: false,
      		pageName: 'MessageList',
			mobileSearchEnabled: false,
			searchTerm: '',
			attachments: null,
			resetEditor: false,
			isVisibleUserProfile: false,
			singleDataProvider: null,
			openEmail: false,
			paginate: {
				currentPage: 1,
				totalEntries: 0,
				perPage: 25,
				totalPages: 0,
				offset: 1,
			  },
			loadingMoreData: false,
			sellThreadTotalEntries: 0,
			buyThreadTotalEntries: 0,
			loadingData: false,
			visibleFields: [],
		}

		this.subscribeToConversation = this.subscribeToConversation.bind(this)
		this.promisedSetState = this.promisedSetState.bind(this)
		this.handleOnChangeTab = this.handleOnChangeTab.bind(this)
		this.removeThread = this.removeThread.bind(this)
		this.handleSearchChange = this.handleSearchChange.bind(this)
		this.setReadStatus = this.setReadStatus.bind(this)
		this.toggleReadStatus = this.toggleReadStatus.bind(this)
		this.onOpenConversation = this.onOpenConversation.bind(this)
		this.setSelectedThread = this.setSelectedThread.bind(this)
		this.toggleSearchInput = this.toggleSearchInput.bind(this)
		this.selectedChannel = null

	}

	async componentDidMount() {
		this.setState({
			visibleFields: this.props.globalColumnConfigs.filter(item => item.visible).map(item => item.key)
		})

		this.instanceRef.current = this;

		window.addEventListener('keydown', this.handleKeyDown);
		if (!this.props.loggedin) return window.location.replace('/login')

		CableApp.cable = ActionCable.createConsumer(`${Globals.actionCableURL}?access_token=${Globals.getAccessToken()}`)

		const {
			params: {product, type},
		} = this.props

		if (type === 'reachout' && !isEmpty(product)) {
			await this.promisedSetState({activeThreadTab: 'buy'})
		}

		if (!!this.props.params.conversation_id) {
			const conversation = await this.loadConversationByID(this.props.params.conversation_id)
			if(conversation?.sender_space_id === this.props.currentSpace.id) {
				await this.promisedSetState({activeThreadTab: 'buy'})
			} else {
				await this.promisedSetState({activeThreadTab: 'sell'})
			}
		}

		await this.loadAndSearchThreads()
		this.props.getConversationsCount()

		if (type === 'reachout' && !isEmpty(product)) {
			this.handlePreparingThread()
		} else {
			const { threads } = this.state
			const { currentSpace, params } = this.props
			if (!!params.conversation_id) {
				const conversationId = params.conversation_id
				const conversation = await this.loadConversationByID(conversationId)
				if(conversation?.sender_space.id === currentSpace.id) {
					this.handleOnChangeTab('buy')
				} else {
					this.handleOnChangeTab('sell')
				}
				this.onOpenConversation(conversation)
			} else {
				if (!!threads[0]) {
					await this.setState({ selectedThread: threads[0] })
				}
			}
		}

		CableApp.cable.subscriptions.create({channel: "NewConversationChannel"}, {
			connected: () => {
			},
			received: async (data) => {
				await Globals.New_Axios()
					.get(`${Globals.baseUri}/conversations/${data.conversation_id}?no_set_status=true`)
					.then(response => {
						let allThreads = cloneDeep(this.state.threads)
						if (!find(allThreads, {id: response.data.id})){
							allThreads.unshift(response.data)
						}
						this.setState({threads: allThreads})
						if (isNil(this.state.selectedThread)) {
							this.setState({selectedThread: allThreads[0]})
						}
					})
					.catch(error => {
						console.log(error)
						return this.props.addToast({
							text: 'Something went wrong cannot load conversations',
							type: 'error',
						})
					})
			}
		})

		this.subscribeToNewMessageChannel()
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.params.conversation_id !== this.props.params.conversation_id) {
			const conversation = await this.loadConversationByID(this.props.params.conversation_id)
			if(conversation?.sender_space.id === this.props.currentSpace.id) {
				this.handleOnChangeTab('buy')
			} else {
				this.handleOnChangeTab('sell')
			}
			this.onOpenConversation(conversation)

		}
		if (prevState.selectedThread?.id !== this.state.selectedThread?.id) {
			this.handleSelectedThreadChange();
		}
		this.instanceRef.current = this;
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown);
		if (this.selectedChannel) {
			this.selectedChannel.unsubscribe()
		}
	}

	handleKeyDown = (event) => {
		onPressFocusSearchInput(event, this.searchInputRef.current)
	};

	toggleSearchInput($event) {
		const { mobileSearchEnabled } = this.state

		this.setState({
			mobileSearchEnabled: !mobileSearchEnabled,
			searchTerm: ''
		}, () => {
			this.loadAndSearchThreads()
		})
	}

	async componentWillReceiveProps(nextProps) {
		const { location, navigate } = nextProps

		if(!!location?.state?.fromNotification) {
			// NOTE: This is to handle the case when user clicks on notification
			const conversation = await this.loadConversationByID(nextProps.params.conversation_id)

			let tab
			if(conversation?.sender_space_id === this.props.currentSpace.id) {
				tab = 'buy'
			} else {
				tab = 'sell'
			}

			await this.promisedSetState({activeThreadTab: tab, selectedThread: conversation})

			if (this.state.activeThreadTab !== tab) {
				// NOTE: in case user on "Sell" tab and they clicks on the notification of "Buy" message
				await this.loadAndSearchThreads()
			}

			if (!!conversation) {
				await this.toggleReadStatus(conversation, true)
			}

			this.props.getConversationsCount()

			navigate(location.pathname);
			return
		}

		if (this.props.params.conversation_id !== nextProps.params.conversation_id) {
			const { threads } = this.state
			const { currentSpace, params } = nextProps
			if (!!params.conversation_id) {
				const conversationId = params.conversation_id

				const thread = threads.filter(thread => String(thread.id) === String(conversationId));
				await this.setState({ selectedThread: {...thread[0] }, conversationId: conversationId})
				if(thread[0].sender_space_id === currentSpace.id) {
					this.setState({activeThreadTab: 'buy'})
				} else {
					this.setState({activeThreadTab: 'sell'})
				}
			}
		}
	}

	subscribeToConversation = (conversationID) => {
		const { getUnopenedConversationCount, getConversationsCount } = this.props

		this.selectedChannel = CableApp.cable.subscriptions.create({channel: "MessageChannel", conversation_id: conversationID}, {
			connected: () => {
        		console.log(`Connected to conversation ${conversationID}`)
			},
			received: (data) => {
				const status = data.conversation_status || this.state.selectedThread.status;

				Globals.New_Axios()
				.get(`${Globals.baseUri}/conversations/${data.conversation_id}/messages/${data.id}`)
				.then(response => {
						let allMessages = [...this.state.messages]
						allMessages.push(response.data)
						this.setState({ messages: allMessages, selectedThread: {...this.state.selectedThread, status: status} })
						setTimeout(() => {
							this.handleScrollConversation()
							this.handleScrollConversationMobile()
							getUnopenedConversationCount()
							getConversationsCount()
						}, 400)
					})
					.catch(error => {
						console.log(error)
						return this.props.addToast({
							text: 'Something went wrong cannot load conversations',
							type: 'error',
						})
					})
			}
		})
	}

	setReadStatus = async (thread, status) => {
		await Globals.New_Axios().post(`${Globals.baseUri}/conversations/${thread.id}/set_read_status`, {
			status: status,
		})
	}

	toggleReadStatus = async (thread, status) => {
		await this.setReadStatus(thread, status)

		const { getUnopenedConversationCount, getConversationsCount } = this.props
		const { threads } = this.state
		const newThreads = threads.map(t => {
			if (t.id === thread.id) {
				t.read = status
			}
			return t
		})
		this.setState({
			threads: cloneDeep(newThreads)
		})
		getUnopenedConversationCount()
		getConversationsCount()
	}

	onOpenConversation = async (thread) => {
		await this.setReadStatus(thread, true)
		const { navigate, getUnopenedConversationCount, getConversationsCount } = this.props
		const { threads } = this.state
		const newThreads = cloneDeep(threads)
		const index = findIndex(threads, { id: thread.id})

		thread.read = true
		newThreads[index] = thread;

		this.setState({
			threads: newThreads,
			selectedThread: thread,
			conversationId: thread.id
		})

		getUnopenedConversationCount()
		getConversationsCount()
		navigate(`/messages/${thread.id}`)
		this.handleLoadingConversation(thread)
	}

	subscribeToNewMessageChannel = () => {
		const { getUnopenedConversationCount, getConversationsCount, currentSpace } = this.props

		this.newMessageChannel = CableApp.cable.subscriptions.create({channel: "NewMessageChannel", space_id: currentSpace.id}, {
			connected: () => {
        		console.log(`Connected to new message channel`)
			},
			received: async (data) => {
				const { selectedThread } = this.state
				const newThreads = cloneDeep(this.state.threads)

				if (selectedThread?.id === data.conversation_id) {
					await this.setReadStatus(selectedThread, true)
					getUnopenedConversationCount()
					getConversationsCount()
					return
				}

				const index = findIndex(newThreads, { id: data.conversation_id})

				if (index > -1) {
					newThreads[index].read = false
					await this.setReadStatus(newThreads[index], false)
				}

				this.setState({ threads: newThreads })
				getUnopenedConversationCount()
				getConversationsCount()
			}
		})
	}

	handlePreparingThread = () => {
		const {
			params: {product},
		} = this.props

		if (!isEmpty(product)) {
			//Check if the product exist and thread can be created.
			return Globals.New_Axios()
				.post(`${Globals.NEW_API_URL}/v1/conversations/reachout_product`, {
					watch_id: product,
					space_id: this.props.currentSpace.id,
				})
				.then(response => {
					const product = response.data
					const { currentSpace } = this.props

					if (product.space.id === currentSpace.id) {
						return this.props.addToast({
							text: 'Unable to start a conversation.',
							type: 'error',
						})
					}

					return this.setState({
						isTryingToReachout: true,
						reachoutProduct: product,
					})
				})
				.catch(error => {
					console.log(error)
					return this.props.addToast({
						text: 'Unable to start a conversation.',
						type: 'error',
					})
				})
		}
	}

	promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

	loadAndSearchThreads = async (isScroll = false) => {
		const { searchTerm,
			loadingMoreData,
			paginate,
			activeThreadTab,
			showUnReadOnly } = this.state;
		const pageRequest = `&page=${paginate.offset}&per_page=${paginate.perPage}`;
		let params = {};

        if (isScroll && loadingMoreData) return;

        if (isScroll) {
            this.setState({ loadingMoreData: true });
        } else {
            this.setState({ loadingData: true });
        }

		let search_name = '';

		activeThreadTab === 'sell' ? search_name = 'sender_space_name_or_search_title_or_watch_short_name_sell' : search_name = 'receiver_space_name_or_search_title_or_watch_short_name';

        if (searchTerm) {
            params.q = {
                [`watch_sku_or_watch_reference_number_or_watch_brand_or_watch_series_or_watch_serial_number_or_watch_dial_or_watch_bezel_or_watch_bracelet_or_watch_addition_details_or_watch_vendor_or_${search_name}_i_cont`]: searchTerm
            };
        }

        try {
            let response = await Globals.New_Axios().get(`${Globals.baseUri}/conversations?${pageRequest}&conversation_type=${activeThreadTab}&unread_only=${showUnReadOnly}`, { params });
            const {
				current_page,
				total_pages,
				conversations,
				unread_sell_threads_count,
				unread_buy_threads_count
			} = response.data;

			const threadsWillBeLoaded = current_page === 1 ? conversations : [...this.state.threads, ...conversations];

			await this.promisedSetState({
                paginate: {
                    ...this.state.paginate,
                    currentPage: current_page,
                    totalPages: total_pages,
                    offset: current_page + 1,
                },
                sellThreadTotalEntries: unread_sell_threads_count,
                buyThreadTotalEntries: unread_buy_threads_count,
                threads: threadsWillBeLoaded,
                threadsLoaded: true,
            });
        } catch (error) {
            console.log(error);
            this.props.addToast({
                text: 'Something went wrong cannot load conversations',
                type: 'error',
            });
        } finally {
			this.setState({
				loadingMoreData: false,
				loadingData: false
			});
        }
    };

    debouncedLoadAndSearchThreads = debounce(() => {
        const resetPagination = {
            currentPage: 1,
            totalEntries: 0,
            perPage: 25,
            totalPages: 0,
            offset: 1,
        };

        this.setState({
            paginate: resetPagination,
            threads: [],
        }, () => {
            this.loadAndSearchThreads();
        });
    }, 500);

	handleSearchChange = (e) => {
        this.setState({ searchTerm: e.target.value });
        this.debouncedLoadAndSearchThreads();
    };

	loadMoreData = async (event) => {
		const { loadingMoreData } = this.state;
		if (loadingMoreData) return;
		const isDistanceToBottomEqualHalf = event.target.scrollTop + event.target.clientHeight  >= event.target.scrollHeight;
		if (isDistanceToBottomEqualHalf) {
			await this.loadAndSearchThreads(true);
		}
	};

	handleOnChangeTab = async (tab) => {
		if (tab === this.state.activeThreadTab) return

		const resetPagination = {
			currentPage: 1,
			totalEntries: 0,
			perPage: 25,
			totalPages: 0,
			offset: 1,
		}

		this.setState({
			activeThreadTab: tab,
			previousActiveTab: this.state.activeThreadTab,
			showUnReadOnly: false,
			mobileSearchEnabled: false,
			searchTerm: '',
			threads: [],
			paginate: resetPagination,
		}, async () => {
			await this.loadAndSearchThreads()
		})

		const { threads } = this.state

		if (tab === 'sell' && !isNil(threads[0])) {
			this.setState({selectedThread: threads[0]})
		} else if (tab === 'buy' && !isNil(threads[0])) {
			this.promisedSetState({selectedThread: threads[0]})
		}
	}

	handleOnChangeShowUnreadOnly = () => {
		this.setState({
			showUnReadOnly: !this.state.showUnReadOnly,
			paginate: {
				offset: 1,
				totalEntries: 0,
				perPage: 25,
			}
		}, async () => {
			await this.loadAndSearchThreads()
		})
	}

	handleLoadingConversation = (thread) => {
		if (!thread) return

		this.setState({
			conversationLoaded: false,
		})

		return Globals.New_Axios()
			.get(`${Globals.baseUri}/conversations/${thread.id}/messages`)
			.then(response => {
				let { messages } = response.data
				return this.setState(
					{
						messages: messages,
						conversationLoaded: true,
					},
					() => {
						setTimeout(() => {
							this.handleScrollConversation()
							this.handleScrollConversationMobile()
						}, 400)
					}
				)
			})
			.catch(error => {
				console.log(error)
				return this.props.addToast({
					text: error.response?.data?.message || 'Something went wrong cannot load conversations',
					type: 'error',
				})
			})
	}

	loadConversationByID = async (id) => {
		try {
			const { data } = await Globals.New_Axios().get(`${Globals.baseUri}/conversations/${id}?no_set_status=true`)
			return data
		} catch (error) {
			console.log(error)
			return null
		}

	}

	handleScrollConversation = () => {
		if (!this.conversationContents) return
		this.conversationContents.scrollTop = parseInt(this.conversationContents.scrollHeight - this.conversationContents.offsetHeight)
	}

	handleConversationScroll = () => {
		this.setState({
			activeScrollDown: this.conversationContents.scrollTop === parseInt(this.conversationContents.scrollHeight - this.conversationContents.offsetHeight),
		})
	}

	handleScrollConversationMobile = () => {
		if (!this.conversationContentsMobile) return
		this.conversationContentsMobile.scrollTop = parseInt(this.conversationContentsMobile.scrollHeight - this.conversationContentsMobile.offsetHeight)
	}

	emitMessage = () => {
		const { chatInputText, selectedThread, attachments } = this.state;
		if (isEmpty(chatInputText) && attachments.length === 0) return;

		const messageData = {
			message: {
				content: JSON.stringify(chatInputText),
				attachments: attachments
			}
		};

		const formData = generateFormData(messageData);

		this.setState({
			chatInputText: '',
			attachments: [],
		});

		Globals.New_Axios()
			.post(`${Globals.baseUri}/conversations/${selectedThread.id}/messages`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
			.then(response => {
				setTimeout(() => {
					this.handleScrollConversation()
					this.handleScrollConversationMobile()
				}, 400)
				this.handleScrollConversation()
				this.handleScrollConversationMobile()
				this.triggerResetEditor()
			})
			.catch(error => {
				console.log(error)
				return this.props.addToast({
					text: error.response?.data?.message || 'Something went wrong cannot load conversations',
					type: 'error',
				})
			})
	}

	handleOpeningThread = async $content => {
		if (isEmpty($content)) return
		const { attachments } = this.state;

		const messageData = {
			conversation: {
				receiver_space_id: this.state.reachoutProduct.space_id,
				watch_id: this.state.reachoutProduct.id,
			},
			message: JSON.stringify($content),
			attachments: attachments
		};

		const formData = generateFormData(messageData);

		const response = await Globals.New_Axios().post(`${Globals.baseUri}/conversations`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		const conversation = response.data

		const allCurrentThreads = cloneDeep(this.state.threads)
		allCurrentThreads.unshift(conversation)

		return this.setState(
			{
				threads: allCurrentThreads,
				threadsLoaded: true,
				isTryingToReachout: false,
				activeThreadTab: 'buy',
				reachoutProduct: null,
				selectedThread: find(allCurrentThreads, { id: conversation.id }),
			},
			() => {
				window.history.pushState('', '', '/messages')
				this.triggerResetEditor()
				this.handleLoadingConversation(this.state.selectedThread)
			}
		)
	}

	triggerResetEditor = () => {
		this.setState({ resetEditor: true }, () => {
			this.setState({ resetEditor: false });
		});
	};

	formatMessage = messageText => {
		if (typeof messageText === 'object' && messageText !== null) {
			messageText = JSON.stringify(messageText)
		}
		try {
			let messages =  flatten([JSON.parse(messageText)])

			map(messages, (message, index) => {
				message.children = message.children.map(child => {
					if (child.bold) child.text = <strong key={uuidv4()}>{child.text}</strong>
					if (child.italic) child.text = <em key={uuidv4()}>{child.text}</em>
					if (child.underline) child.text = <u key={uuidv4()}>{child.text}</u>
					if (child.code) child.text = <code key={uuidv4()}>{child.text}</code>
					if (child.strike) child.text = <strike key={uuidv4()}>{child.text}</strike>
					if (child.emoji)
						child.text = (
							<span className="elefta-discussion-emoji">
								<Emoji unified={child.text} size="20" />
							</span>
						)

					child.text = <span key={uuidv4()}>{child.text}</span>
					child.key=uuidv4()

					return child
				})
			})

			return messages
		} catch (error) {
			console.log(error)
		}
	}

	handleVisibleDetails = () => {
		this.setState({
			visibleDetailsDrawer: !this.state.visibleDetailsDrawer,
		})
	}

	async handleSelectedThreadChange() {
		if (this.state.selectedThread) {
			if (this.selectedChannel) {
				await this.selectedChannel.unsubscribe()
			}
			await this.subscribeToConversation(this.state.selectedThread.id)
		}
	}

	removeThread = (thread) => {
		let threads = this.state.threads
		threads = threads.filter(t => t.id !== thread.id)
		this.setState({threads})
	}

	isEmptyContent = (content) => {
		let messageContent;

		if (typeof content === 'string') {
			try {
				messageContent = JSON.parse(content);
			} catch (error) {
				return content.trim() === '';
			}
		} else if (typeof content === 'object' && content !== null) {
			messageContent = content;
		} else {
			return true;
		}

		const isTextEmpty = messageContent.length > 0 &&
                    messageContent[0].hasOwnProperty('children') &&
                    messageContent[0].children.length > 0 &&
                    (messageContent[0].children[0].text === "" || messageContent[0].children[0].text == null);
		return isTextEmpty;
	}

	setSelectedThread = (thread) => {
		this.setState({selectedThread: thread})
	}

	handleFilterBySpace = (item) => {
		const { setMarketplaceFilters } = this.props
		const newState = {
			activeTab: 'marketplace',
			specialFilters: {
				type: 'space',
				title: item.name,
				spaceId: item.spaceId,
			},
		}
		setMarketplaceFilters(newState)
	}

	handleCloseUserProfileModal = () => {
		this.setState({isVisibleUserProfile: false})
	}

	emailDialog = flag => {
		this.setState({openEmail :flag})
	}

	handleOpenUserProfileModal = (data) => {
		let buildData = data
		if(this.state.activeThreadTab === "sell") {
			buildData = {space: data.sender_space, id: data.watch.id}
		}
		this.setState({singleDataProvider: buildData})
		this.setState({isVisibleUserProfile: true})
	}

	createTextMessage = () => {
		const { params } = this.props;

		if (params.price === 'price') {
		  return 'Hi, how much?';
		} else if (params.price === 'availability') {
		  return 'Hi, is this available?';
		} else {
		  return '';
		}
	}

	render() {
		const state = this.state
		const { selectedThread, pageName, visibleFields } = state
		const props = this.props


		return (
			<React.Fragment>
				{/* Desktop view: Conversations list */}
				<section className="relative hidden md:block">
					<TopBar toggleSideBar={() => props.toggleSidebar(!props.isOpenSideBar)} />
					<div className="flex relative">
						<SideBar isOpen={props.isOpenSideBar} />
						<main className="flex flex-col relative w-full h-screen overflow-hidden"
							  style={{paddingTop: (props.currentSpace.should_show_upgrade_announcement) ? '6.6rem' : '4rem'}}>
							<div className="bg-white flex gap-0 h-full overflow-hidden rounded-tl-lg w-full">
								<div className="border-r h-full max-w-sm relative w-full"
									 style={{maxWidth: '32%', minWidth: '380px'}}>
									{!state.threadsLoaded && (
										<div className="flex items-center justify-center h-full">
											<button disabled type="button"
													className="text-white bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 inline-flex items-center">
												<svg role="status"
													 className="inline mr-3 w-4 h-4 text-white animate-spin"
													 viewBox="0 0 100 101" fill="none"
													 xmlns="http://www.w3.org/2000/svg">
													<path
														d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
														fill="#E5E7EB"
													/>
													{' '}
													<path
														d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
														fill="currentColor"
													/>
												</svg>
												<span>Loading conversations...</span>
											</button>
										</div>
									)}

									{state.threadsLoaded && (
										<div className="flex flex-col h-full justify-between max-h-full">
											<div className="p-5 pb-0 relative">
												<div
													className="border-b border-gray-200 flex items-center justify-between gap-4 pb-5">
													<div className="hidden">
														<div className="__checkbox">
															<input id="checkbox-thread-all" type="checkbox" />
															<label htmlFor="checkbox-thread-all"></label>
														</div>
													</div>
													<div className="w-full flex items-center">
														<div className="text-gray-400 relative w-11/12">
															<input
																ref={this.searchInputRef}
																className="bg-white border flex items-center outline-blue-300 pr-9 px-3 relative rounded text-gray-700 text-xs w-full h-11"
																type="text"
																name="search"
																value={state.searchTerm}
																onChange={(e) => {
																	this.handleSearchChange(e)
																}}
																placeholder="Search Here..."
															/>
															<button
																className="pointer-events-none absolute right-0 top-0 h-full px-3">
																<svg className="w-6 h-6" fill="currentColor"
																	 viewBox="0 0 20 20"
																	 xmlns="http://www.w3.org/2000/svg">
																	<path
																		fillRule="evenodd"
																		d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
																		clipRule="evenodd"
																	/>
																</svg>
															</button>
														</div>
														<div
															className={ClassNames('w-[30px] border text-gray-400 h-[30px] flex items-center justify-center rounded-full bg-white ms-2', {
																'!bg-blue-500 !text-white': state.showUnReadOnly,
															})}
															onClick={this.handleOnChangeShowUnreadOnly}
														>
															<FilterListIcon />
														</div>
													</div>
												</div>
												<div className="border-b border-gray-200 flex">
													<div className="w-full">
														<div className="text-gray-400 relative">
															<div
																className="text-center text-sm font-medium grid grid-cols-2">
																<div>
																	<button
																		onClick={() => this.handleOnChangeTab('sell')}
																		className={ClassNames('relative uppercase text-gray-600 border-gray-200 w-full py-3 border-b-2', {
																			'!border-blue-500 !text-blue-500': state.activeThreadTab === 'sell',
																		})}
																	>
																			<span
																				className="bg-gray-200 font-semibold inline-flex items-center justify-center mr-1 px-1 rounded text-gray-800 text-xs">

																			{props.conversationsCounter.unreadSellThreadsCount}
																			</span>
																		<span>Sell</span>
																	</button>
																</div>
																<div>
																	<button
																		onClick={() => this.handleOnChangeTab('buy')}
																		className={ClassNames('uppercase text-gray-600 border-gray-200 w-full py-3 border-b-2', {
																			'!border-blue-500 !text-blue-500': state.activeThreadTab === 'buy',
																		})}
																	>
																			<span
																				className="bg-gray-200 font-semibold inline-flex items-center justify-center mr-1 px-1 rounded text-gray-800 text-xs">
																				{props.conversationsCounter.unreadBuyThreadsCount}
																			</span>
																		<span>Buy</span>
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="flex flex-col h-full justify-between max-h-full">
												<div className="relative max-h-full h-full">
													<div
														ref={conversationSideContainer => {
															this.conversationSideContainer = conversationSideContainer
														}}
														className="absolute overflow-scroll overflow-x-auto w-full h-full divide-y hidden-scrollbar"
														onScroll={(event) => this.loadMoreData(event)}
													>
														{state.loadingData && (
															<div
																className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
																<button
																	type="button"
																	className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
																	disabled
																>
																	<svg
																		className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
																		xmlns="http://www.w3.org/2000/svg" fill="none"
																		viewBox="0 0 24 24">
																		{' '}
																		<circle className="opacity-25" cx="12" cy="12"
																				r="10" stroke="currentColor"
																				strokeWidth="4" />
																		<path
																			className="opacity-75"
																			fill="currentColor"
																			d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
																		/>
																	</svg>
																	<span>Loading... </span>
																</button>
															</div>
														)}

														{!state.loadingData && (
															<div
																className="absolute overflow-scroll overflow-x-auto w-full h-full divide-y hidden-scrollbar"
																onScroll={(event) => {
																	this.loadMoreData(event);
																}}
																ref={(ref) => {
																	this.conversationMobileContainer = ref;
																}}
															>
																{state.activeThreadTab === 'sell' && (
																	<div>
																		{isEmpty(state.threads) && !this.state.showUnReadOnly && (
																			<div className="py-6 text-sm">You have no
																				selling request conversation yet!</div>
																		)}
																		{isEmpty(state.threads) && this.state.showUnReadOnly && (
																			<div
																				className='py-6 text-sm flex justify-center'>You
																				have no unread messages</div>
																		)}
																		{!isEmpty(state.threads) && state.threads.map((thread, index) => (
																			<SidebarItem
																				thread={thread}
																				key={index}
																				selectedThread={state.selectedThread}
																				onOpenConversation={this.onOpenConversation}
																				toggleReadStatus={this.toggleReadStatus}
																				tab={state.activeThreadTab}
																			/>
																		))}
																	</div>
																)}

																{state.activeThreadTab === 'buy' && (
																	<div>
																		{isEmpty(state.threads) && !this.state.showUnReadOnly && (
																			<div className="py-6 text-sm">You didn't
																				start any buying conversation yet!</div>
																		)}
																		{isEmpty(state.threads) && this.state.showUnReadOnly && (
																			<div
																				className='py-6 text-sm flex justify-center'>You
																				have no unread messages</div>
																		)}
																		{!isEmpty(state.threads) && state.threads.map((thread, index) => (
																			<SidebarItem
																				thread={thread}
																				key={index}
																				selectedThread={state.selectedThread}
																				onOpenConversation={this.onOpenConversation}
																				toggleReadStatus={this.toggleReadStatus}
																				tab={state.activeThreadTab}
																			/>
																		))}
																	</div>
																)}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									)}
								</div>

								<div className="bg-gray-100 h-full relative w-full overflow-hidden min-w-[500px]">
									<div className="flex flex-col h-full justify-between max-h-full">
										{state.isTryingToReachout && isEmpty(state.selectedThread) && (
											<React.Fragment>
												<div className="relative">
													<div className="p-5 pb-0">
														<div className="bg-white p-3 rounded-2xl">
															<div className="flex items-center gap-3 justify-between">
																<div className="flex">
																	<div
																		className="inline-block rounded-full text-white overflow-hidden bg-blue-500">
																		<div
																			style={{
																				backgroundImage: state.reachoutProduct && !!head(state.reachoutProduct.images) ? `url('${head(state.reachoutProduct.images).url}')` : `url('/watch_icon.png')`,
																				width: 60,
																				height: 60,
																				backgroundSize: 'contain',
																				backgroundPosition: 'center',
																				backgroundRepeat: 'no-repeat',
																				backgroundColor: '#fff',
																			}}
																		></div>
																	</div>
																</div>
																<div className="min-w-0">
																	<div>
																		<h4 className="font-medium truncate text-gray-800 text-lg m-0 max-w-[650px]">{state.reachoutProduct.message_title}</h4>
																	</div>

																	<div className='flex justify-between'>
																		{this.state.selectedThread?.status === 'archived' ? (
																			<span
																				className='font-medium text-lg text-gray-800 mt-1'>
																					No Longer Available
																				</span>
																		) : (
																			<span
																				className='font-medium text-lg text-blue-500 mt-1'>
																					{
																						state.reachoutProduct.wholesale_price ?
																							Globals.readCurrencyNumber(state.reachoutProduct.wholesale_price) :
																							'Contact for Price'
																					}
																				</span>
																		)}
																		<span
																			onClick={() => this.handleOpenUserProfileModal(state.reachoutProduct)}
																			className="truncate text-gray-800 text-sm m-0 text-right cursor-pointer">
																				With <u>{state.reachoutProduct.space?.name}</u>{' '}
																			</span>
																	</div>
																</div>
																<div className="ml-auto">
																	{!state.isTryingToReachout && (
																		<button
																			className="bg-blue-500 px-3 py-2 rounded-full space-x-1 text-blue-500 text-white text-xs"
																			style={{
																				width: 'max-content',
																			}}
																			onClick={this.handleVisibleDetails}
																		>
																				<span
																					className="inline-block bg-zinc-500 w-6 h-6 text-xs p-2 text-white rounded-full inline-flex items-center justify-center">
																					<small
																						className="uppercase">{state.reachoutProduct.space.name[0]}</small>
																					<small
																						className="uppercase">{props.currentSpace.name[0]}</small>
																				</span>
																			<span
																				className="inline-block">Details</span>
																		</button>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* Main Messenger Frame */}
												<div className="relative  max-h-full h-full">
													<div
														className="px-10 py-0 absolute overflow-scroll overflow-x-auto w-full h-full text-sm"
														ref={conversationContents => {
															this.conversationContents = conversationContents
														}}
														onScroll={this.handleConversationScroll}
													></div>
												</div>
												{/* Bottom Messenger Frame */}
												<div>
													<div className="w-full relative">
														<div className="p-5 py-2 pt-0">
															<div className="flex gap-2">
																<div
																	className="bg-white border border-gray-200 flex gap-2 items-center w-full rounded-2xl">
																	<div
																		className="w-full relative py-2 min-w-0 text-sm">
																		<SlateEditor
																			defaultText={this.createTextMessage()}
																			onChange={$event => {
																				this.handleScrollConversation()
																			}}
																			hitEnter={($content, $event, $attachments) => {
																				this.setState({
																					chatInputText: $content,
																					attachments: $attachments
																				}, () => this.handleOpeningThread($content, $attachments))
																			}}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</React.Fragment>
										)}
										{!isEmpty(state.selectedThread) && (
											<React.Fragment>
												{/* Header Messenger Frame */}
												<div className="relative">
													<div className="p-5 pb-0">
														<div className="bg-white p-3 rounded-2xl">
															<div className="flex items-center gap-3 justify-between">
																<div className="flex">
																	<div
																		className="inline-block rounded-full text-white overflow-hidden bg-blue-500">
																		<div
																			style={{
																				backgroundImage: state.selectedThread?.watch && !!head(state.selectedThread.watch.images) ? `url('${head(state.selectedThread.watch.images).url}')` : `url('/watch_icon.png')`,
																				width: 60,
																				height: 60,
																				backgroundSize: 'contain',
																				backgroundPosition: 'center',
																				backgroundRepeat: 'no-repeat',
																				backgroundColor: '#fff',
																			}}
																		></div>
																	</div>
																</div>
																<div className="min-w-0">
																	<div>
																		<Tooltip
																			title={state.selectedThread.message_title}
																			placement="bottom" arrow>
																			<h4 className="font-medium truncate text-gray-800 text-lg m-0 cursor-pointer">{state.selectedThread.message_title}</h4>
																		</Tooltip>
																	</div>
																	<div className='flex justify-between'>
																		{this.state.selectedThread?.status === 'archived' ? (
																			<span
																				className='font-medium text-lg text-gray-800 mt-1'>
																					No Longer Available
																				</span>
																		) : (
																			<span
																				className='font-medium text-lg text-blue-500 mt-1'>
																				{

																					state.selectedThread.watch?.wholesale_price ?
																						Globals.readCurrencyNumber(state.selectedThread.watch.wholesale_price) :
																						'Contact for Price'
																				}
																			</span>
																		)}
																		{
																			state.activeThreadTab === 'buy' && (
																				<span
																					onClick={() => this.handleOpenUserProfileModal(state.selectedThread.watch)}
																					className="truncate text-gray-800 text-sm m-0 text-right cursor-pointer">
																						With <u>{state.selectedThread.watch?.space?.name}</u>{' '}
																					</span>
																			)
																		}
																		{
																			state.activeThreadTab === 'sell' && (
																				<div className='flex flex-col'>
																						<span
																							onClick={() => this.handleOpenUserProfileModal(state.selectedThread)}
																							className="truncate text-gray-800 text-sm m-0 text-right cursor-pointer">
																							With {state.selectedThread.sender_space.name}
																						</span>
																				</div>
																			)
																		}
																	</div>
																</div>
																<div className="ml-auto">
																	<button
																		className="bg-blue-500 px-3 py-2 rounded-full space-x-1 text-blue-500 text-white text-xs"
																		style={{
																			width: 'max-content',
																		}}
																		onClick={this.handleVisibleDetails}
																	>
																		<span className="inline-block">Details</span>
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="relative max-h-full h-full">
													<div
														className="px-10 py-0 absolute overflow-scroll overflow-x-auto w-full h-full text-sm"
														ref={conversationContents => {
															this.conversationContents = conversationContents
														}}
														onScroll={this.handleConversationScroll}
													>
														<div>
															<div className="text-center text-xs my-3">
																<strong>{state.selectedThread.sender_space.name}</strong>
																<span>Started a conversation</span>
															</div>
														</div>

														<div className="hidden">
															<div className="flex flex-row items-end gap-2 py-4">
																<div
																	className="bg-blue-100 text-blue-500 rounded-full font-normal text-sm w-12 h-12 flex items-center justify-center">
																	<span>K</span>
																	<span>T</span>
																</div>
																<div className="relative space-y-2">
																	<div
																		className="bg-blue-100 px-4 py-4 max-w-sm text-gray-800 rounded-2xl">
																		<div>Hey</div>
																		<div className="-mb-2 text-right text-gray-500">
																			<small>22:32</small>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div className="hidden">
															<div
																className="flex flex-row flex-row-reverse items-end gap-2 py-4">
																<div
																	className="bg-gray-200 text-grey-500 rounded-full font-normal text-sm w-12 h-12 flex items-center justify-center">
																	<span>K</span>
																	<span>T</span>
																</div>
																<div className="relative space-y-2">
																	<div
																		className="bg-gray-200 px-4 py-4 max-w-sm text-gray-800 rounded-2xl">
																		<div>Hi there</div>
																		<div className="-mb-2 text-left text-gray-500">
																			<small>22:32</small>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div className="hidden">
															<div className="flex flex-row items-end gap-2 py-4">
																<div
																	className="bg-blue-100 text-blue-500 rounded-full font-normal text-sm w-12 h-12 flex items-center justify-center">
																	<span>K</span>
																	<span>T</span>
																</div>
																<div className="relative space-y-2">
																	<div
																		className="max-w-sm bg-white border border-gray-200 rounded-2xl overflow-hidden">
																		<div
																			className="bg-center bg-cover py-24"
																			style={{
																				backgroundImage: `url(https://flowbite.com/docs/images/blog/image-1.jpg)`,
																			}}
																		></div>
																		<div className="p-5">
																			<h5 className="mb-2 text-md font-bold tracking-tight text-gray-900">Noteworthy
																				technology acquisitions 2021</h5>
																			<p className="mb-3 text-xs font-normal text-gray-700">
																				Here are the biggest enterprise
																				technology acquisitions of 2021 so far,
																				in reverse chronological order.
																			</p>
																		</div>
																	</div>
																	<div
																		className="bg-blue-100 px-4 py-4 max-w-sm text-gray-800 rounded-2xl">
																		<div>
																			Hey can you send me photos or videos please?
																			Hey can you send me photos or videos
																			please?Hey can you send me photos or
																			videos please? Hey can you send me photos or
																			videos please?Hey can you send me photos or
																			videos please? Hey can you send me
																			photos or videos please?
																		</div>
																		<div className="-mb-2 text-right text-gray-500">
																			<small>22:32</small>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div className="hidden">
															<div
																className="flex flex-row flex-row-reverse items-end gap-2 py-4">
																<div
																	className="bg-gray-200 text-grey-500 rounded-full font-normal text-sm w-12 h-12 flex items-center justify-center">
																	<span>K</span>
																	<span>T</span>
																</div>
																<div className="relative space-y-2">
																	<div
																		className="max-w-sm bg-white border border-gray-200 rounded-2xl overflow-hidden">
																		<div
																			className="bg-center bg-cover py-24"
																			style={{
																				backgroundImage: `url(https://flowbite.com/docs/images/blog/image-1.jpg)`,
																			}}
																		></div>
																		<div className="p-5">
																			<h5 className="mb-2 text-md font-bold tracking-tight text-gray-900">Noteworthy
																				technology acquisitions 2021</h5>
																			<p className="mb-3 text-xs font-normal text-gray-700">
																				Here are the biggest enterprise
																				technology acquisitions of 2021 so far,
																				in reverse chronological order.
																			</p>
																		</div>
																	</div>
																	<div
																		className="bg-gray-200 px-4 py-4 max-w-sm text-gray-800 rounded-2xl">
																		<div>Hey can you send me photos or videos
																			please?
																		</div>
																		<div className="-mb-2 text-left text-gray-500">
																			<small>22:32</small>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<React.Fragment>
															{!isEmpty(state.messages) && state.conversationLoaded && (
																<React.Fragment>
																	{state.messages.map((message, index) => {
																		const alternate = message.space_id === props.currentSpace.id //this will move the message to the right side.
																		const images = message.attachments?.map(attachment => attachment.url)
																		return (
																			<React.Fragment key={index}>
																				{index % 20 === 0 && (
																					<div
																						className="sticky top-2 text-center z-10">
																						<div
																							className="text-xs bg-gray-200 inline-block px-3 py-2 rounded-2xl">
																							{Moment(message.created_at).local().format('Do of MMMM YYYY')}
																						</div>
																					</div>
																				)}

																				<div>
																					{message.kind === 'by_system' ? (
																						<div
																							className="text-center text-gray-500 text-xs py-2">
																							{message.content}
																						</div>
																					) : (
																						<div
																							className={ClassNames('flex flex-row items-end gap-2 py-4', {
																								'flex-row-reverse': alternate,
																							})}
																						>
																							<div
																								className={ClassNames(
																									'bg-gray-200 text-grey-500 rounded-full font-normal text-sm w-12 h-12 flex items-center justify-center',
																									{
																										'bg-blue-100 text-blue-500': !alternate,
																									}
																								)}
																							>
																								<span
																									className="uppercase">{message.space.name.slice(0, 2)}</span>
																							</div>
																							<div
																								className="relative space-y-2">
																								{alternate && (
																									<div
																										className="text-xs">
																										<small>
																											Sent
																											by {message.sender?.full_name}
																										</small>
																									</div>
																								)}

																								{!isEmpty(images) && (
																									<div
																										className="mt-2">
																										{images.map((image, index) => {
																											return (
																												<div
																													key={index}
																													className="mb-2"
																												>
																													<img
																														className="rounded-lg"
																														src={image}
																														alt=""
																														width={200}
																														height={200}
																														loading="lazy"
																													/>
																												</div>
																											)
																										})}
																									</div>
																								)}
																								{!this.isEmptyContent(message.content) && (
																									<div
																										className={ClassNames('bg-gray-200 px-4 py-4 max-w-sm text-gray-800 rounded-2xl', {
																											'bg-blue-100': !alternate,
																										})}
																									>
																										<div
																											className="break-words">
																											{
																												this.formatMessage(message.content).map((ms, index) => {
																													return (
																														<React.Fragment
																															key={index}>
																															{ms.children.map(child => child.text)}
																															<br />
																														</React.Fragment>
																													)
																												})
																											}
																										</div>

																										<div
																											className="-mb-2 text-right text-gray-500">
																											<small>{Moment(message.created_at).local().format('hh:mm A')}</small>
																										</div>
																									</div>
																								)}
																							</div>
																						</div>
																					)}
																				</div>
																			</React.Fragment>
																		)
																	})}
																</React.Fragment>
															)}
														</React.Fragment>
													</div>
												</div>
												<div>
													<div className="w-full relative">
														<div className="p-5 py-2 pt-0">
															<div className="flex gap-2">
																<div
																	className="bg-white border border-gray-200 flex gap-2 items-center w-full rounded-2xl">
																	<div
																		className="w-full relative py-2 min-w-0 text-sm">
																		<SlateEditor
																			resetFlag={this.state.resetEditor}
																			onChange={$event => {
																				this.handleScrollConversation()
																			}}
																			hitEnter={($content, $event, $attachments) => {
																				this.setState(
																					{
																						chatInputText: $content,
																						attachments: $attachments,
																					},
																					this.emitMessage
																				)
																			}}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</React.Fragment>
										)}
									</div>
								</div>
								{state.visibleDetailsDrawer && (
									<div className="fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-50">
										<div
											className="bg-white h-full max-w-md absolute right-0 top-0 w-full rounded-tl-2xl">
											{!state.isTryingToReachout && (
												<React.Fragment>
													<div className="flex items-center gap-3 py-3 px-5">
														<div
															className="flex flex-row items-center justify-between w-full">
															<div className="flex gap-1">
																<button onClick={this.handleVisibleDetails}>
																	<svg className="h-4 inline-block w-4"
																		 viewBox="0 0 22 22" fill="currentColor"
																		 xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
																	</svg>
																</button>
															</div>
														</div>
													</div>
													<div className="col-span-5 border-b">
														<div className="sticky top-0">
															<ProductMessageGallery
																product={state.selectedThread.watch} />

															<div
																style={{
																	width: '100%',
																	display: 'flex',
																	flexDirection: 'column',
																	justifyContent: 'center',
																	alignItems: 'center',
																}}
															>
																{this.state.selectedThread?.status === 'archived' ? (
																	<p style={{
																		fontSize: '14px',
																		fontWeight: '600',
																		textDecoration: 'underline'
																	}}>
																		No Longer Available
																	</p>
																) : (
																	<p className="text-blue-500" style={{
																		fontSize: '14px',
																		fontWeight: '600',
																		textDecoration: 'underline'
																	}}>
																		{!isNil(state.selectedThread.watch.wholesale_price) ?
																			`$${Globals.readCurrencyNumber(state.selectedThread.watch.wholesale_price)}` :
																			'Contact for Price'
																		}
																	</p>
																)}
															</div>

															<div className="px-10 py-7">
																<div
																	className="grid grid-flow-row-dense grid-cols-3 gap-6">
																	<div className="col-span-4">
																		<div className="sticky top-0">
																			<div className="mb-1">
																				<Info title="Dealer SKU"
																					  detail={state.selectedThread.watch.sku} />
																				<Info title="Reference Number"
																					  detail={state.selectedThread.watch.reference_number} />
																				{visibleFields.includes('serial_number') && (
																					<Info
																						title="Serial Number"
																						detail={state.activeThreadTab === 'buy' ?
																							`${state.selectedThread.watch.serial_number ?
																								state.selectedThread.watch.serial_number.slice(0, 3) : ''}*****` : state.selectedThread.watch.serial_number}
																					/>
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div
														className="single-product-details grid grid-cols-1 h-screen pb-40 overflow-y-scroll conversation-product-details">
														<div className="px-10 py-7">
															<div className="grid grid-flow-row-dense grid-cols-3 gap-6">
																<div className="col-span-4">
																	<div className="sticky top-0">
																		<div className="mb-1 overflow-y-scroll">
																			<h2 className="text-sm font-bold tracking-wider mb-0">Watch
																				Detail</h2>
																			<Info title="Brand"
																				  detail={state.selectedThread.watch.full_brand} />
																			{visibleFields.includes('series') &&
																				<Info title="Series"
																					  detail={state.selectedThread.watch.series} />}

																			{visibleFields.includes('dial') &&
																				<Info title="Dial"
																					  detail={state.selectedThread.watch.dial} />}
																			{visibleFields.includes('bezel') &&
																				<Info title="Bezel"
																					  detail={state.selectedThread.watch.bezel} />}
																			{visibleFields.includes('bracelet') &&
																				<Info title="Bracelet"
																					  detail={state.selectedThread.watch.bracelet} />}
																			{visibleFields.includes('condition') &&
																				<Info title="Condition"
																					  detail={state.selectedThread.watch.condition} />}
																			{visibleFields.includes('warranty_papers') &&
																				<Info title="Warranty Paper/Card"
																					  detail={state.selectedThread.watch.warranty_papers} />}
																			{visibleFields.includes('warranty') &&
																				<Info title="Warranty Date"
																					  detail={state.selectedThread.watch.display_warranty} />}
																			{visibleFields.includes('box') &&
																				<Info title="Box"
																					  detail={state.selectedThread.watch.box ? 'Yes' : 'No'} />}
																			{visibleFields.includes('link_count') &&
																				<Info title="Link Count"
																					  detail={state.selectedThread.watch.link_count} />}
																			{
																				state.activeThreadTab === 'sell' &&
																				(<Info title="Status"
																					   detail={state.selectedThread.watch.status} />)
																			}
																			{visibleFields.includes('addition_details') &&
																				<Info title="Additional Details"
																					  detail={state.selectedThread.watch.addition_details} />}
																		</div>
																		{
																			state.selectedThread.receiver_space_id === props.currentSpace.id && (
																				<>
																					<div
																						className="border-b grid grid-cols-2 gap-2 py-4"></div>
																					<div className="mb-8">

																						<h2 className="text-sm font-bold tracking-wider mb-0 mt-7">Additional
																							Details</h2>
																						{visibleFields.includes('cost') &&
																							<Info title="Cost"
																								  detail={Globals.readCurrencyNumber(state.selectedThread?.watch?.cost)} />}
																						<Info title="Age"
																							  detail={state.selectedThread?.watch?.age} />
																						<Info title="Views"
																							  detail={state.selectedThread.watch?.views_count} />
																						<Info title="Message Number"
																							  detail={state.selectedThread.messages_count} />
																					</div>
																				</>
																			)
																		}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</React.Fragment>
											)}
										</div>
									</div>
								)}
							</div>
						</main>
					</div>
				</section>


				{/* Mobile view: Conversations view */}
				{
					this.state.isVisibleUserProfile &&
					<UserProfileModal
						singleDataProvider={this.state.singleDataProvider}
						handleCloseUserProfileModal={this.handleCloseUserProfileModal}
						openShareViaEmail={this.emailDialog}
						props={this.props}
						isMessage={true}
						handleFilterBySpace={this.handleFilterBySpace}
					/>
				}
				{this.state.openEmail && <ShareEmailDialog emailDialog={this.emailDialog} {...props}
														   singleDataProvider={this.state.singleDataProvider} />}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		isOpenSideBar: state.isOpenSideBar,
		loggedin: state.loggedin,
		currentSpace: state.currentSpace,
		conversationsCounter: state.conversationsCounter,
		globalColumnConfigs: state.globalColumnConfigs
	}
}

const mapActionsToProps = {
	addToast,
	toggleSidebar,
	getConversationsCount,
	getUnopenedConversationCount,
	setMarketplaceFilters
}

export default connect(mapStateToProps, mapActionsToProps)(withTheme(withRouter(withParams(Discussions))))
