import React, {useMemo} from 'react'
import moment from 'moment'
import {dummyWatchData} from '../qrcodeConsts'
import {Globals} from '../../../../../Context'

const pricesKeysArray = ['price_key_1', 'price_key_2', 'price_key_3']
const rightColumnKeys = ['brand', 'series', 'reference_number', 'serial_number', 'scope_of_delivery']

const spaceToMM = spacePrinting => {
	return (spacePrinting * 25.4) / 203
}

Number.prototype.formatPrice = function (n, x, decimal = false) {
	var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (decimal ? '\\.' : '$') + ')'

	const formattedNumber = decimal ? this.toFixed(2) : Math.round(this).toString()

	return formattedNumber.replace(new RegExp(re, 'g'), '$&,')
}

export const handlePrice = (price, rounding) => {
	const numberValue = Globals.revertPrice(price || '')
	if (!numberValue) return ''
	const roundedToNearestFive = Math.ceil(numberValue / 5) * 5
	const outputValue = rounding ? roundedToNearestFive : numberValue
	return `${outputValue.formatPrice(0, 3)}`
}

const QrPreviewBlock = ({watchData, qrCodeURL, configData, selectedQrType, hideBorder, hideTitle, mocked}) => {
	const incomeData = useMemo(() => watchData || dummyWatchData, [watchData])

	const configObject = useMemo(() => {
		return (configData || []).reduce((acc, item) => {
			acc[item.key] = {
				value: item.value,
				visible: item.visible,
			}
			return acc
		}, {})
	}, [configData])

	const scopeOfDeliveryValue = useMemo(() => {
		return `${incomeData.box ? 'B' : ''} ${(incomeData.box && incomeData.warranty_papers && incomeData.warranty_papers !== 'no' && '+ ') || ''}${
			incomeData.warranty_papers && incomeData.warranty_papers !== 'no' ? 'P' : ''
		} ${incomeData.warranty ? moment(incomeData.warranty).format('YYYY') : ''}`
	}, [incomeData])
	const {spacing} = configObject.printer_settings?.value || {}
	return (
		<div className={'flex-1 flex flex-row justify-center'}>
			<div>
				{!hideTitle && <p className={'text-[12px] font-thin italic'}>Preview</p>}

				<div
					className={'flex flex-row justify-center items-center p-[20px]'}
					style={{
						minHeight: 160,
						minWidth: 520,
						border: `${hideBorder ? '0' : '1'}px solid rgba(0, 0, 0, 0.5)`,
						borderRadius: 8,
					}}
				>
					{selectedQrType === 'qr_code' ? (
						<img src="./Images/qr.png" alt="" className="w-[150px] h-[150px]" />
					) : (
						<>
							<div
								className={`flex bg-white relative overflow-hidden`}
								id={"qr-render"}
								style={{
									border: '1px solid rgba(0, 0, 0, 0.5)',
									width: `${(configObject?.printer_settings?.value.sizing.width * 25.4) / 2}mm`,
									height: `${configObject?.printer_settings?.value.sizing?.height * 25.4}mm`,
								}}
							>
								<div className={"flex-1 relative overflow-hidden"}>
									<div
										style={{
											position: 'absolute',
											left: `${spaceToMM(spacing?.col1?.x)}mm`,
											top: `${spaceToMM(spacing?.col1?.y)}mm`,
											height: '100%',
											width: '50%',
										}}
										className={'overflow-hidden'}
									>
										<div
											style={{
												lineHeight: 1,
												position: 'absolute',
												left: 0,
												top: 0,
												width: '100%',
												height: '100%',
											}}
										>
											<p
												style={{
													display: 'block',
													fontSize: (spacing?.col1.font_size || 18) / 2 + 1 + "px",
													width: '100%',
													color: '#000000',
													whiteSpace: 'nowrap',
													lineHeight: 1,
												}}
												className={'font-medium'}
											>
												{incomeData.sku}
											</p>
										</div>

										{pricesKeysArray.map((key, index) => {
											if (configObject[key]?.visible && (incomeData[configObject[key].value] || incomeData.custom_column_values?.[configObject[key].value] || key === 'price_key_1' || mocked)) {
												return (
													<div
														style={{
															color: '#000000',
															lineHeight: 1,
															position: 'absolute',
															top: `${spaceToMM(spacing?.col1.gap * (index + 1))}mm`,
														}}
													>
														<p
															style={{
																color: '#000000',
																lineHeight: 1,
																fontSize: (spacing?.col1.font_size || 18) / 2 + "px",
															}}
															key={index}
															className={'font-regular text-[9px]'}
														>
															{key === 'price_key_1'
																? `${handlePrice(incomeData.cost * (configObject[key].value || 1), true)}`
																: (handlePrice(incomeData[configObject[key].value]) || '' || incomeData[configObject[key].value] || incomeData.custom_column_values?.[configObject[key].value] || "")}
															{mocked && !incomeData[configObject[key].value] && key !== 'price_key_1' && '1,000'}
														</p>
													</div>
												)
											}
										})}
									</div>
									<div
										style={{
											position: 'absolute',
											left: `${spaceToMM(spacing?.col2?.x)}mm`,
											top: `${spaceToMM(spacing?.col2?.y)}mm`,
										}}
									>
										<img
											src={qrCodeURL || './Images/qr.png'}
											style={{
												width: `${10 + 10 * ((spacing?.col2.qrSize || 3) - 3) * 0.3}mm`,
												height: `${10 + 10 * ((spacing?.col2.qrSize || 3) - 3) * 0.3}mm`,
											}}
										/>
									</div>
									<div
										style={{
											height: '100%',
											width: 2,
											borderLeftWidth: 1,
											borderLeftColor: '#D1D3D4',
											borderStyle: 'dashed',
											position: 'absolute',
											left: '50%',
											top: 0,
											transform: 'translateX(-50%)',
										}}
									></div>
									<div
										style={{
											position: 'absolute',
											left: `${spaceToMM(spacing?.col3?.x)}mm`,
											top: `${spaceToMM(spacing?.col3?.y)}mm`,
										}}
									>
										{rightColumnKeys.map((key, index) => {
											if (configObject[key]?.visible && (incomeData[key] || key === 'scope_of_delivery')) {
												return (
													<div
														style={{
															color: '#000000',
															lineHeight: 1,
															position: 'absolute',
															top: `${spaceToMM(spacing?.col3.gap * index)}mm`,
														}}
													>
														<p
															style={{
																textOverflow: 'ellipsis',
																whiteSpace: 'nowrap',
																display: 'block',
																// overflow: 'hidden',
																width: '100%',
																color: '#000000',
																lineHeight: 1,
																fontSize: (spacing?.col3.font_size || 18) / 2 + "px"
															}}
															key={index}
															className={'font-regular w-full '}
														>
															{key === 'scope_of_delivery' ? scopeOfDeliveryValue : incomeData[key] || ''}
														</p>
													</div>
												)
											}
										})}
									</div>
								</div>

							</div>
							<div
								className={'w-[50px] h-[10px] bg-white'}
								style={{
									border: '1px solid rgba(93, 110, 129, 1)',
									borderLeftColor: 'white',
									zIndex: 2,
									marginLeft: -2,
								}}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default QrPreviewBlock
