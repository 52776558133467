import {useMemo, useState} from 'react'
import {connect} from 'react-redux'
import {Globals} from '../../../Context'
import {keyToContent, settingsOptions} from './settingsConsts'

const Settings = props => {
	const [contentValue, setContentValue] = useState(props.profile.role === 'manager' ? 'paymentReminders' : 'general')

	const ContentComponent = useMemo(() => {
		return keyToContent[contentValue] || null
	}, [contentValue])

	return (
		<div className="w-full">
			<div className="flex border-b-2 w-full">
				{settingsOptions.map(option => {
					if (option.managerRestricted && props.profile.role === 'manager') {
						return
					}
					if (option.adminOrOwner && !Globals.adminOrOwner(props.profile)) {
						return
					}
					if (option.owner && props.profile.role !== 'owner') {
						return
					}
					return (
						<button
							key={option.key}
							className="cursor-pointer flex flex-row pt-4 pb-3 justify-center items-center border-b-2 mr-5 sm:mr-4"
							style={{
								fontWeight: contentValue === option.key ? '700' : '500',
								borderColor: contentValue === option.key ? '#2C79E0' : 'transparent',
							}}
							onClick={() => setContentValue(option.key)}
						>
							<span className="lg:text-sm text-xxs w-full">{option.label}</span>
						</button>
					)
				})}
			</div>
			{ContentComponent && <ContentComponent profile={props.profile} />}
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(Settings)
