import {store} from '../../../app/store'
import {Globals} from '../../../Context'
import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'
import {addToast, logOut, storeProfile} from '../../../app/appActions'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TeamTable from './components/TeamTable'
import RolesPermissionsModal from './components/RolesPermissionsModal'

const ManageTeam = props => {
	const emailInput = useRef()
	const [spaceUsers, setSpaceUsers] = useState([])

	const [isVisibleInvitationDialog, setVisibleInvitationDialog] = useState(false)
	const [sendingInvitation, setSendingInvitation] = useState(false)
	const [roleOptions, setRoleOptions] = useState([])
	const [selectedRole, setSelectedRole] = useState('')
	const [rolesModalVisible, setRolesModalVisible] = useState(false)

	useEffect(() => {
		getSpaceUsers()
	}, [])

	const sendInvitationCall = $event => {
		$event.preventDefault()
		const emailAddress = emailInput.current.value

		if (sendingInvitation) return
		if (!Globals.validateEmail(emailAddress)) {
			return props.addToast({
				title: 'Invite users',
				text: 'Invalid email address.',
				type: 'error',
			})
		}

		setSendingInvitation(true)

		return Globals.New_Axios()
			.post(`${Globals.baseUri}/spaces_users/invite`, {
				email: emailAddress,
				role: selectedRole,
			})
			.then(async response => {
				setSendingInvitation(false)
				setSelectedRole('')

				//Check on the response validation.
				setVisibleInvitationDialog(false)
				emailInput.current.value = null
				//Reload users list.
				Globals.getProfile()
					.then(profile => {
						//Dispatch profile actions first.
						store.dispatch(storeProfile(profile))
					})
					.catch(error => {
						console.warn(error)
					})

				getSpaceUsers()

				return props.addToast({
					title: 'Invite users',
					text: `The invitation has been sent to ${emailAddress}.`,
					type: 'success',
				})
			})
			.catch(error => {
				setSendingInvitation(false)
				return props.addToast({
					title: 'Invite users',
					text: error.response.data.message,
					type: 'error',
				})
			})
	}

	const cancelInvitaionView = $event => {
		$event.preventDefault()
		setVisibleInvitationDialog(false)
		setSendingInvitation(false)
		setSelectedRole('')
		emailInput.current.value = null
	}

	const getSpaceUsers = async () => {
		Globals.New_Axios()
			.get(`${Globals.baseUri}/spaces_users?per_page=1000&q[s]=created_at+asc`)
			.then(response => {
				if (response.data.role_options) setRoleOptions(response.data.role_options)

				setSpaceUsers(response.data.spaces_users)
			})
			.catch(error => {
				return props.addToast({
					text: error.response?.data?.message || 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const isCurrentUserAnOwner = () => {
		return props.profile.role === 'owner'
	}

	const handleRolesDetailsDisplay = () => {
		setRolesModalVisible(true)
	}

	return (
		<div className="w-full">
			<div className="relative pt-2">
				<div className="my-4 px-5">
					<h2 className="font-bold text-2xl mb-3">Space Users</h2>
					<div className="bg-gray-100 flex p-4 rounded text-gray-800 text-sm" role="alert">
						{/*<svg className="flex-shrink-0 inline w-5 h-5" fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">*/}
						{/*	<path*/}
						{/*		strokeLinecap="round"*/}
						{/*		strokeLinejoin="round"*/}
						{/*		d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"*/}
						{/*	/>*/}
						{/*</svg>*/}
						<div className="text-gray-700">
							<p className="ml-2">
								<span className="font-medium">
									You can add new users to your account and assign them specific roles. Each role is tied to unique permissions and access levels, ensuring your team members only
									access the features they need.
								</span>
							</p>
							<p className="ml-2 mt-2">
								<span className="font-medium">
									To view detailed role permissions,{' '}
									<span onClick={handleRolesDetailsDisplay} className={'underline cursor-pointer'}>
										click here
									</span>
									.
								</span>
							</p>
						</div>
					</div>
				</div>

				<div className="px-5">
					<div className="my-5 text-right">
						{isCurrentUserAnOwner() && (
							<button
								className="text-white bg-blue-600 hover:opacity-90 font-medium rounded text-sm px-4 py-1.5 pr-3 text-center inline-flex items-center capitalize"
								onClick={e => setVisibleInvitationDialog(true)}
							>
								Invite new user
							</button>
						)}
					</div>
					<TeamTable spaceUsers={spaceUsers} getSpaceUsers={getSpaceUsers} setVisibleInvitationDialog={setVisibleInvitationDialog} setSendingInvitation={setSendingInvitation} />
				</div>

				{rolesModalVisible && <RolesPermissionsModal setRolesModalVisible={setRolesModalVisible} />}
				{isVisibleInvitationDialog && (
					<div className="fixed left-0 right-0 bottom-0 top-0 h-screen bg-white/50 flex items-center justify-center z-[50] py-10">
						<div className="bg-white drop-shadow-xl rounded-xl w-full overflow-hidden" style={{maxWidth: '850px'}}>
							<div className="w-full borderCardChrono relative px-8 py-4 flex items-center">
								<span className="text-white font-bold text-lg">Invite users</span>
								<button className="absolute right-0 mr-5 cursor-pointer" onClick={cancelInvitaionView}>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M14.8672 0.194897C14.625 0.200615 14.3949 0.301995 14.2271 0.47688L8.00026 6.70377L1.77337 0.47688C1.68784 0.38896 1.58554 0.319098 1.47252 0.271431C1.35951 0.223764 1.23807 0.199261 1.11541 0.199373C0.933136 0.199599 0.755062 0.254158 0.60394 0.35608C0.452819 0.458003 0.335512 0.602661 0.267004 0.771577C0.198497 0.940492 0.181898 1.126 0.21933 1.30439C0.256762 1.48278 0.346524 1.64597 0.47715 1.7731L6.70404 7.99999L0.47715 14.2269C0.389174 14.3113 0.318936 14.4125 0.27055 14.5245C0.222163 14.6364 0.196601 14.7569 0.19536 14.8789C0.194119 15.0008 0.217224 15.1218 0.263323 15.2347C0.309421 15.3476 0.377586 15.4502 0.463825 15.5364C0.550064 15.6227 0.652643 15.6908 0.765556 15.7369C0.878469 15.783 0.999445 15.8061 1.1214 15.8049C1.24335 15.8037 1.36383 15.7781 1.47578 15.7297C1.58774 15.6813 1.68891 15.6111 1.77337 15.5231L8.00026 9.29622L14.2271 15.5231C14.3116 15.6111 14.4128 15.6813 14.5247 15.7297C14.6367 15.7781 14.7572 15.8037 14.8791 15.8049C15.0011 15.8061 15.1221 15.783 15.235 15.7369C15.3479 15.6908 15.4505 15.6227 15.5367 15.5364C15.6229 15.4502 15.6911 15.3476 15.7372 15.2347C15.7833 15.1218 15.8064 15.0008 15.8052 14.8789C15.8039 14.7569 15.7784 14.6364 15.73 14.5245C15.6816 14.4125 15.6113 14.3113 15.5234 14.2269L9.29648 7.99999L15.5234 1.7731C15.6568 1.6452 15.7484 1.47997 15.7862 1.29908C15.824 1.11819 15.8063 0.93009 15.7354 0.759448C15.6644 0.588807 15.5436 0.443591 15.3886 0.342846C15.2337 0.2421 15.052 0.19053 14.8672 0.194897Z"
											fill="white"
										/>
									</svg>
								</button>
							</div>
							<div className="flex flex-col max-h-96 overflow-y-scroll p-5">
								<form onSubmit={sendInvitationCall}>
									<div className="bg-gray-100 flex p-4 rounded text-gray-800 text-sm" role="alert">
										<div className="text-gray-700">
											<p className="ml-2">
												<span className="font-medium">It's important to note that the invitation email will expire after 12 hours.</span>
											</p>
										</div>
									</div>
									<div className="flex flex-row items-center py-4 space-x-4">
										<TextField
											label="Email Address"
											variant="standard"
											className="flex-grow"
											inputRef={emailInput}
											sx={{
												mr: 4,
											}}
										/>
										<FormControl variant="standard" sx={{minWidth: 160}} required>
											<InputLabel id="select-user-role">Select User Role</InputLabel>
											<Select
												labelId="select-user-role"
												id="select-user-role"
												label="Role"
												onChange={e => setSelectedRole(e.target.value)}
												IconComponent={props => <KeyboardArrowDownIcon {...props} />}
												value={selectedRole}
											>
												{roleOptions.map((role, index) => {
													if (role.key === 1 || role.key === 5) return null
													return (
														<MenuItem key={index} sx={{fontWeight: '700', color: '#5D6E81'}} value={role.key}>
															{role.label && upperFirst(toLower(role.label))}
														</MenuItem>
													)
												})}
											</Select>
										</FormControl>
										<button type={"button"} style={{marginLeft: 0}} className={"px-3 pt-2 ml-0"} onClick={handleRolesDetailsDisplay}>
											<svg
												className="inline w-6 h-6"
												fill="none"
												stroke="currentColor"
												strokeWidth={1.5}
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
												aria-hidden="true"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
												/>
											</svg>
										</button>
										{rolesModalVisible && <RolesPermissionsModal setRolesModalVisible={setRolesModalVisible} />}
									</div>
									<div className="py-4 text-right space-x-4">
										<a
											href=".."
											className="transition-all text-red-700 font-bold bg-red-200 hover:bg-red-300 font-medium rounded text-sm px-4 py-2 pr-3 text-center inline-flex items-center capitalize"
											onClick={cancelInvitaionView}
										>
											Cancel
										</a>
										<button
											className="transition-all text-white font-bold bg-blue-600 hover:opacity-90 font-medium rounded text-sm px-4 py-2 pr-3 text-center inline-flex items-center capitalize"
											type="submit"
										>
											{sendingInvitation && (
												<span role="status">
													<svg className="inline w-4 h-4 mr-2 text-white fill-blue-300 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
															fill="currentColor"
														/>
														<path
															d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
															fill="currentFill"
														/>
													</svg>
												</span>
											)}
											<span>Send invitation</span>
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	addToast,
	logOut,
}

export default connect(mapStateToProps, mapActionsToProps)(ManageTeam)
