import {useState} from 'react'
import {Globals} from '../../Context'
import moment from 'moment'
import DatePickerFlat from '../Inventory/DatePickerFlat'
import sumBy from 'lodash/sumBy'

const CreditPayment = ({hideCreditPayment, props, singleOrderItem, loadSingleOrder, currentSpace, handleOpenPerformanceLimitAccessModal, handleStatusUpdate ,singleCreditPayment, isAddCreditPayment =  true, shippingPrice}) => {
	const currentDate = () => {
		const defaultDate = new Date()
		const day = String(defaultDate.getDate()).padStart(2, '0')
		const month = String(defaultDate.getMonth() + 1).padStart(2, '0')
		const year = defaultDate.getFullYear()
		return `${month}-${day}-${year}`
	}
	const [creditPayment, setCreditPayment] = useState({payment: (singleCreditPayment?.amount ? Globals.formatCurrency(singleCreditPayment?.amount) :  -singleOrderItem.balance), paymentDate: singleCreditPayment?.date ? moment(singleCreditPayment?.date).format('MM-DD-YYYY') : moment().format("MM-DD-YYYY"), balance: 0})
	const handlechangePicker = (date, name) => {
		setCreditPayment({...creditPayment, paymentDate: moment(date).format("MM-DD-YYYY")})
	}

	//submit payment
	const handleSubmitPayment = () => {
		if(isAddCreditPayment) {
			if((!!creditPayment?.payment ? creditPayment?.payment : 0) > Math.abs(singleOrderItem.balance)) {
				return false
			}
			return Globals.New_Axios()
				.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales/${singleOrderItem.id}/credit_payments`, {
					credit_payment: {
						amount: creditPayment.payment,
						date: moment(creditPayment.paymentDate, "MM-DD-YYYY").format("DD-MM-YYYY")
					},
				})
				.then(response => {
					loadSingleOrder(singleOrderItem.id, true)

					props.addToast({
						text: 'Credit Payment Added Successfully',
						type: 'success',
					})

					closeCreditPayment()
				})
				.catch(error => {
					props.addToast({
						text: 'Something went wrong please try again later.',
						type: 'error',
					})
				})
		} else {
			if(Number(caculateNewBalance()) > 0) {
				return false
			}
			const patmentsExceptCurrentPayment = singleOrderItem.payments.filter((p) => p.id !== singleCreditPayment.id)
			const totalPaymentsExceptCurrentPayment = sumBy(patmentsExceptCurrentPayment, (p) => p.amount)
			return Globals.New_Axios()
				.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales/${singleOrderItem.id}/credit_payments/${singleCreditPayment.id}`, {
					credit_payment: {amount: caculatePayment(), date: moment(creditPayment.paymentDate).format("DD-MM-YYYY")},
				})
				.then(response => {
					if (parseInt(creditPayment?.payment) === parseInt(singleOrderItem.total - totalPaymentsExceptCurrentPayment)) {
						handleStatusUpdate('payment_received')
					}

					loadSingleOrder(singleOrderItem.id, true)
					props.addToast({
						text: 'Payment Edit Successfully',
						type: 'success',
					})
					closeCreditPayment()
				})
				.catch(error => {
					props.addToast({
						text: 'Something went wrong please try again later.',
						type: 'error',
					})
				})
		}
	}

	const parsePositiveNumber = (value) => {
		const parsedValue = Math.abs(parseFloat(value.replace(/,/g, '')));
		return isNaN(parsedValue) ? 0 : parsedValue;
	  };

	const caculateNewBalance = () => {
		let currentPayment = !!creditPayment?.payment ? creditPayment?.payment : 0
		if(typeof creditPayment?.payment === 'string') {
			currentPayment = !!creditPayment?.payment ? creditPayment?.payment.replaceAll(',', '') : 0
		}
		if(!!singleCreditPayment) {
			let newShippingPrice = singleOrderItem.shipping_price
			if(singleOrderItem.shipping_price !== Number(shippingPrice) && !!shippingPrice) {
				newShippingPrice = Number(shippingPrice)
			}
			const patmentsExceptCurrentPayment = singleOrderItem.payments.filter((p) => p.id !== singleCreditPayment.id)
			const totalPaymentsExceptCurrentPayment = sumBy(patmentsExceptCurrentPayment, (p) => p.amount)
			return Globals.moneyFormatter.format((singleOrderItem.sub_total + newShippingPrice) + (totalPaymentsExceptCurrentPayment + Number(currentPayment)))
		} else {
			const parsedNumber = parsePositiveNumber((Globals.moneyFormatter.format(singleOrderItem.balance).replace('.00', '').replace('$', '')));
			return 	Number(currentPayment) - parsedNumber
		}
	}

	const caculatePayment = () => {
		let currentPayment = !!creditPayment?.payment ? creditPayment?.payment : 0
		if(typeof creditPayment?.payment === 'string') {
			currentPayment = !!creditPayment?.payment ? creditPayment?.payment.replaceAll(',', '') : 0
		}
		return Number(currentPayment)
	}

	const closeCreditPayment = () => {
		hideCreditPayment()
		setCreditPayment({payment: 0, paymentDate: currentDate(), balance: 0})
	}

	const showErorAmount = () => {
		if(isAddCreditPayment) {
			return (!!(creditPayment?.payment) ? creditPayment?.payment : 0) > (-singleOrderItem.balance) && 'Payment Cannot Exceed Balance'
		} else {
			return Number(caculateNewBalance()) > 0 && 'Payment Cannot Exceed Balance'
		}
	}

	return (
		<>
			<div className="hidden bg-black/50 bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50 p-3 md:flex">
				<div className="max-w-xl w-full">
					<div className="bg-slate-600 flex items-center px-8 py-4 relative rounded-t-xl text-white w-full">
						<span className="text-white font-bold text-lg"> {isAddCreditPayment ?  'Add' : 'Edit'} Credit Payment</span>
						<button className="absolute right-0 mr-5 cursor-pointer" onClick={closeCreditPayment}>
							<img src="/Images/close.svg" alt="close" width={22} height={22} />
						</button>
					</div>
					<div className="bg-white text-gray-500 rounded-b-xl px-8 py-4 w-full">
						<div>
							<div className="grid grid-cols-2 gap-3 mt-5">
								<div className="mb-2">
									<label htmlFor="productName" className="font-normal">
										Amount
									</label>
									<div className="relative">
										<input
											value={creditPayment?.payment ? Globals.formatCurrency(creditPayment?.payment) : ''}
											onChange={e => {
												setCreditPayment({...creditPayment, payment: Globals.revertPrice(e.target.value)})
											}}
											type="text"
											name="productName"
											className="block py-3 p-4 w-full border rounded text-sm"
										/>
										<span className=" text-red-800">
											{showErorAmount()}
										</span>
									</div>
								</div>

								<div className="mb-2">
									<div className="flex flex-col ml-4 gap-2">
										<div>
											<div className="flex flex-row justify-between">
												<div>Balance:</div>
												<div className="">{Globals.moneyFormatter.format(singleOrderItem.balance).replace('.00', '').replace('$', '')}</div>
											</div>
										</div>
										<div>
											<div className="flex flex-row justify-between">
												<div>Credit Payment:</div>
												<div>
													{Globals.moneyFormatter.format(caculatePayment()).replace('.00', '').replace('$', '')}
												</div>
											</div>
										</div>
										<div className="border-t-4">
											<div className="flex flex-row justify-between">
												<div>New Balance:</div>
												<div>
													{caculateNewBalance()}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="grid grid-cols-2 gap-3 mt-5">
								<div className="mb-2">
									<label htmlFor="price" className="font-normal">
										Date
									</label>

									<DatePickerFlat name="SoldDate" title="Sold Date" handlechangePicker={handlechangePicker} defaultValue={creditPayment.paymentDate} />
								</div>
							</div>
							<div className="flex flex-row mt-9 justify-end space-x-4">
								<button
									className="border-2 border-red-500 cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text-red-500 text-gray text-xs uppercase"
									onClick={closeCreditPayment}
								>
									Cancel
								</button>
								<button className="bg-blue cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text-white text-xs uppercase" onClick={() => handleSubmitPayment()} type="submit">
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-black/50 bottom-0 fixed flex items-center justify-center left-0 right-0 top-0 z-50 md:hidden">
				<div className="max-w-xl w-full">
					<div className="bg-[#4B7CBE] flex items-center px-8 py-4 relative rounded-t-xl text-white w-full max-h-[43px]">
						<span className="text-white font-bold text-[22px]">Credit Payment</span>
						<button className="absolute right-0 mr-5 cursor-pointer" onClick={closeCreditPayment}>
							<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
							<path d="M11.4874 14.1231L6.06906 8.51228L8.47885 6.18518L13.8972 11.796L19.8677 6.03036L22.1948 8.44015L16.2243 14.2058L21.6774 19.8526L19.2676 22.1797L13.8145 16.5329L7.80802 22.3333L5.48091 19.9235L11.4874 14.1231Z" fill="white"/>
							</svg>
						</button>
					</div>
					<div className="bg-white text-gray-500 px-8 py-4 w-full">
						<div>
							<div className="grid grid-cols-2 gap-3 mt-5">
								<div className="mb-2">
									<input
										value={creditPayment?.payment ? Globals.formatCurrency(creditPayment?.payment) : ''}
										onChange={e => {
											setCreditPayment({...creditPayment, payment: Globals.revertPrice(e.target.value)})
										}}
										type="text"
										name="amount"
										placeholder="Amount"
										className="block py-3 p-4 w-full border rounded-[5px] text-sm max-w-[163px] max-h-[34px]"
									/>
									<span className="text-xs text-red-800">
										{showErorAmount()}
									</span>
								</div>
								<div className='border-2 flex items-center max-w-[124px] max-h-[34px] rounded-[5px]'>
									<DatePickerFlat name="SoldDate" title="Sold Date" handlechangePicker={handlechangePicker} defaultValue={isAddCreditPayment ? '' : creditPayment.paymentDate} isMobile={true}/>
								</div>
							</div>
							<div className="mb-2 grid grid-cols-2">
								<div className='ml-8 text-[#000000] text-xs font-normal'>
									<div>Balance</div>
									<div>Payment</div>
									<div className='text-[#4B7CBE] font-bold'>New Balance</div>

								</div>
								<div className='flex flex-col items-center text-[#000000] text-xs font-normal'>
									<div>{Globals.moneyFormatter.format(singleOrderItem.balance)}</div>
									<div className='w-2/5 flex items-center justify-center border-b-2'>
										{Globals.moneyFormatter.format(caculatePayment()).replace('$', '')}
									</div>
									<div className='text-[#4B7CBE]'>
										{caculateNewBalance()}
									</div>
								</div>
							</div>
							<div className="flex flex-row mt-4 justify-center space-x-4">
								<button
									className="border-2 cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text[#000000] text-xs"
									onClick={closeCreditPayment}
								>
									Cancel
								</button>
								<button className="bg-blue cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text-white text-xs uppercase" onClick={() => handleSubmitPayment()} type="submit">
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CreditPayment
