import {useState, forwardRef} from 'react'
import {NavLink} from 'react-router-dom'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import classNames from 'classnames'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import * as collections from '../utils/collections'
import TermsOfUse from './TermsOfUse'
import {Globals} from '../Context'
import './FormRegister.css'
import PricingCard from './PricingCard'
import ServerAutoSuggestAddress from '../Components/ServerAutoSuggestAddress'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import {useNavigate, useSearchParams} from 'react-router-dom'
import PaymentForm from './PaymentForm'
import {useEffect} from 'react'
import CheckIcon from '@mui/icons-material/Check'
import map from 'lodash/map'
import {handleTrimInputOnBlur} from '../utils/InputHelper'
import {renderValidationItem} from '../utils/validationItem'
import { set } from 'lodash'

let isSubmitRegistrationSession = false

const FormRegister = forwardRef(
	(
		{
			handleSubmit,
			handleChange,
			isVisbleCompanyAddressAutoComplete,
			setIsVisbleCompanyAddressAutoComplete,
			formData,
			showPassword,
			textCompanyAddress,
			handleOnChangePhoneNumber,
			displayError,
			handleShowPassword,
			updateFormData,
			showTerms,
			setShowTerms,
			hideTerms,
			updateFormErrors,
			formErrors,
			setBillingPeriod,
			billingPeriod,
			setLoading,
			handleChangeGoogleAddress,
			handleChangePassword,
			validations
		},
		ref
	) => {
		const navigate = useNavigate()
		const [step, setStep] = useState(1)
		const [isOpenPaymentForm, setIsOpenPaymentForm] = useState(false)
		const [checkoutSession, setCheckoutSession] = useState()
		const [currentChoosePlan, setCurrentChoosePlan] = useState()
		const [disableButton, setDisableButton] = useState(true)
		const [searchParams] = useSearchParams()
		const [disableEditPassword, setDisableEditPassword] = useState(false)
		const [registrationUID, setRegistrationUID] = useState(searchParams.get('registration_uid'))
		const [registrationSession, setRegistrationSession] = useState(null)
		const [regularUserId, setRegularUserId] = useState(null)
		const [isSubmitting, setIsSubmitting] = useState(false)

		useEffect(() => {
			if (step === 1 && !!formData.name && !!formData.lastname && !!formData.email && !!formData.password && !!formData.terms) {
				const isValid = handleDisableButton()
				setDisableButton(isValid)
			} else if (step === 2 && !!formData.company_name && !!formData.company_address && !!formData.phone) {
				setDisableButton(false)
			} else if (step === 3) {
				setDisableButton(false)
			} else {
				setDisableButton(true)
			}
		}, [step, formData, registrationUID])

		useEffect(() => {
			if (isSubmitRegistrationSession) {
				return
			}

			const registrationSessionParams = {}
			if (step >= 1) {
				registrationSessionParams.user_information = {
					name: formData.name,
					lastname: formData.lastname,
					email: formData.email,
					terms: formData.terms,
				}
			}

			if (step >= 2) {
				registrationSessionParams.space_information = {
					company_name: formData.company_name,
					company_address: formData.company_address,
					phone: formData.phone,
					certificate: formData.certificate,
					full_address: formData.full_address,
					city: formData.city,
					state: formData.state,
					zip_code: formData.zip_code,
					country: formData.country,
					reference_company_name_1: formData.reference_company_name_1,
					reference_email_or_phone_number_1: formData.reference_email_or_phone_number_1,
					reference_company_name_2: formData.reference_company_name_2,
					reference_email_or_phone_number_2: formData.reference_email_or_phone_number_2,
				}
			}

			if (step >= 3) {
				registrationSessionParams.subscription_information = {
					plan_type: currentChoosePlan?.value,
					billing_period: billingPeriod,
				}
			}

			if (regularUserId) {
				registrationSessionParams.user_id = regularUserId
			}

			isSubmitRegistrationSession = true
			debounceSubmitRegistrationSession(registrationSessionParams)
		}, [formData, currentChoosePlan, billingPeriod, regularUserId])

		useEffect(() => {
			if (checkoutSession) {
				setIsOpenPaymentForm(true)
			}
		}, [checkoutSession])

		const debounceSubmitRegistrationSession = debounce((registrationSessionParams) => {

			if (registrationSession) {
				Globals.New_Axios().put(`${Globals.NEW_API_URL}/registration_sessions/${registrationSession.uid}`, {
					registration_session: {
						...registrationSessionParams
					},
				}).then(response => {
					const {data} = response
					setRegistrationSession(data)
					isSubmitRegistrationSession = false
				}).catch(error => {
					console.log(error)
					isSubmitRegistrationSession = false
				})
			} else {
				Globals.New_Axios().post(`${Globals.NEW_API_URL}/registration_sessions`, {
					registration_session: {
						...registrationSessionParams
					},
				}).then(response => {
					const {data} = response
					setRegistrationSession(data)
					isSubmitRegistrationSession = false
				}).catch(error => {
					console.log(error)
					isSubmitRegistrationSession = false
				})
			}
		}, 1000)

		const handleDisableButton = () => {
			if (!validations.length || !validations.uppercase || !validations.lowercase || !validations.digit || !validations.specialChar) {
				return true
			}

			return false
		}

		const handleChangePlan = (event, plan) => {
			if (plan.value === 'starter') {
				handleSubmitForm(event, plan)
			} else {
				handleSubmitForm(event, plan)
			}
		}

		const handleSubmitForm = (event, plan) => {
			event.preventDefault()
			let errors = {}

			if (step === 3) {
				setLoading(true)
				setIsSubmitting(true)

				return Globals.New_Axios()
					.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/generate_payment_link`, {
						registration_uid: registrationUID,
						plan_type: plan.value,
						billing_period: billingPeriod,
						registering: true,
					})
					.then(response => {
						setLoading(false)
						setIsSubmitting(false)

						if (plan.value === 'starter') {
							setCurrentChoosePlan(plan)
							navigate('/thank-you')
						} else {
							setCurrentChoosePlan(plan)
							setCheckoutSession(response.data.client_secret)
						}
					})
					.catch(error => {
						setLoading(false)
						setIsSubmitting(false)
						errors = {
							...errors,
							error: error.response?.data?.message || 'Something went wrong please try again later.',
						}
						updateFormErrors(errors)
					})
			} else if (step === 1) {
				if (isEmpty(formData.name)) errors = {...errors, name: 'Please fill in your name'}
				if (isEmpty(formData.lastname)) errors = {...errors, lastname: 'Please fill in your last name'}
				if (isEmpty(formData.email) || !Globals.validateEmail(formData.email)) errors = {...errors, email: 'Company email is required'}
				if (!formData.terms) errors = {...errors, terms: 'Accept terms and conditions'}

                if (isEmpty(formData.password)) {
                    errors = {...errors, password: 'Password cannot be empty'}
                } else if (formData.password.length < 6) {
                    errors = {...errors, password: 'password must be at least 6 characters long'}
                }

				updateFormErrors(errors)
				if (!isEmpty(errors)) return
				const registrationParams = {
					user: {
						email: formData.email,
						first_name: formData.name,
						last_name: formData.lastname,
						password: formData.password,
						password_confirmation: formData.password,
						accepted_tos: formData.terms,
					},
					step: 1,
				}

				setIsSubmitting(true)

				return Globals.New_Axios()
					.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/users/regular_registration`, registrationParams)
					.then(response => {
						setStep(2)
						if (showPassword.password) {
							handleShowPassword('password')
						}
						setDisableEditPassword(true)
						setRegistrationUID(response.data.registration_uid)
						updateFormErrors({})
						setIsSubmitting(false)
					})
					.catch(error => {
						console.log(error)
						errors = {
							...errors,
							error: error.response?.data?.message || 'Something went wrong please try again later.',
						}
						updateFormErrors(errors)
						setIsSubmitting(false)
					})
			} else if (step === 2) {
				if (isEmpty(formData.company_name)) errors = {...errors, company_name: 'Company name is required'}
				if (isEmpty(formData.company_address) && isVisbleCompanyAddressAutoComplete) errors = {...errors, company_address: 'Company address is required'}
				if (isEmpty(formData.full_address) && !isVisbleCompanyAddressAutoComplete) errors = {...errors, company_address: 'Company address is required'}
				if (isEmpty(formData.phone)) errors = {...errors, phone: 'Company phone is required'}
				updateFormErrors(errors)
				if (!isEmpty(errors)) return

				const registrationParams = {
					user: {
						email: formData.email,
						spaces_attributes: [
							{
								email: formData.email,
								name: formData.company_name,
								street: formData.company_address,
								apt_unit_number: formData.apt_unit_number,
								city: formData.city,
								state: formData.state,
								zip_code: formData.zip_code,
								country: formData.country,
								phone_number: formData.phone,
								resale_certificate: formData.certificate,
                                selected_autocomplete_address: formData.full_address,
							},
						],
						info_references_attributes: [
							{
								company_name: formData.reference_company_name_1,
								email_or_phone: formData.reference_email_or_phone_number_1,
							},
							{
								company_name: formData.reference_company_name_2,
								email_or_phone: formData.reference_email_or_phone_number_2,
							},
						],
					},
					step: 2,
				}

				setIsSubmitting(true)

				return Globals.New_Axios()
					.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/users/regular_registration`, registrationParams)
					.then(response => {
						setStep(3)
						setRegularUserId(response.data.id)
						updateFormErrors({})
						setIsSubmitting(false)
					})
					.catch(error => {
						console.log(error)
						errors = {
							...errors,
							error: error.response?.data?.message || 'Something went wrong please try again later.',
						}
						updateFormErrors(errors)
						setIsSubmitting(false)
					})
			}
		}

		const handlePreviousStep = () => {
			updateFormErrors({})
			setStep(step - 1)
		}

		const backPaymentForm = () => {
			setIsOpenPaymentForm(false)
			setCheckoutSession(null)
		}

		return (
			<>
				<div className="md:w-2/3 flex flex-col items-center bgLogin md:pb-0 pb-16 md:pt-0 pt-10">
					{isOpenPaymentForm ? (
						<>
							<div className="pb-4 relative w-full">
								<div className="flex flex-col font-bold items-center md:items-start justify-center text-xl w-full">
									<div className="pt-7 mb-7 w-full">
										<h1 className="text-center w-96 text-2xl md:text-[35px] md:w-full">Checkout</h1>
										<div className="cursor-pointer top-0 left-0 pl-10 font-semibold	text-xl md:text-[18px]" onClick={backPaymentForm}>
											<img src="/Images/back-icon.svg" alt="back-icon.svg" width={30} className="inline" />
											Back
										</div>
									</div>
								</div>
							</div>
							<div className="flex flex-col-reverse md:flex-row gap-10 w-full px-10">
								<div
									className={classNames(
										'relative lg:max-w-auto lg:w-full mb-5 md:mb-0 flex flex-col rounded-[10px] text-[12px] lg:text-[16px] border-gray-300 border-[2px] bg-[#fff]',
										{
											'lg:h-[400px]': currentChoosePlan.value !== 'platinum',
											'lg:h-[550px]': currentChoosePlan.value === 'platinum',
										}
									)}
								>
									<div className="w-full h-full flex flex-col justify-between p-2">
										<div className="flex flex-col">
											<span className="text-black font-bold text-[12px] lg:text-[20px] text-center">{currentChoosePlan.title}</span>
											<span className="text-[#4b4b8c] text-[20px] font-bold text-center">
												{billingPeriod === 'monthly' ? currentChoosePlan.price.monthly.value : currentChoosePlan.price.yearly.value}
											</span>
											<div className="flex flex-col items-center pb-[15px]">
												<span className="text-[12px] text-gray-700 font-bold text-center">{currentChoosePlan.price[billingPeriod].subtitle}</span>
												{billingPeriod === 'yearly' && (
													<span className="text-[12px] text-gray-700 text-center italic">
														{currentChoosePlan.price.full_amount.value} to be billed, Renewal day will be 1 year after approved
													</span>
												)}
											</div>
											<div className="flex flex-col mt-3 text-[12px] border-t-[1px]">
												{map(currentChoosePlan.descriptions, (feature, index) => (
													<div className="flex items-start mt-3" key={index}>
														<CheckIcon style={{fontSize: '16px', color: '#ff6a39'}} />
														<span key={index} className="text-black ml-2">
															{feature}
														</span>
													</div>
												))}
											</div>
										</div>
										<div className="flex flex-col items-center">
											<span className="text-[12px] text-gray-700 font-bold text-center">You will not be charged until your account is approved</span>
										</div>
									</div>
								</div>
								<PaymentForm checkoutSession={checkoutSession} />
							</div>
						</>
					) : (
						<form
							action=""
							autoComplete="off"
							className={classNames('flex flex-col mt-5 md:mt-0', {
								'md:max-w-2xl max-w-[640px]': step !== 3,
								'lg:px-20 justify-center items-center': step === 3,
							})}
							onSubmit={handleSubmitForm}
						>
							<div>
								<div className="flex flex-col font-bold items-center md:items-start justify-center text-xl w-full">
									<div className="pt-7 pb-7 w-full">
										<h1 className="text-center w-96 text-2xl md:text-[35px] md:w-full">Registration Form</h1>
										<h3 className="font-normal text-center pt-[20px] text-[13px] tracking-wider">
											Already have an account ?{' '}
											<NavLink to={'/login'}>
												{' '}
												<strong className="text-blue-brand font-semibold">Sign in Now!</strong>
											</NavLink>
										</h3>
									</div>
								</div>
							</div>
							<div className="relative flex justify-center pb-[65px] w-[384px] lg:w-[632px]">
								<div className="absolute top-4 h-0.5 bg-gray-300 z-0 w-[416px]"></div>
								<div className=" absolute left-0 lg:left-36 flex flex-col items-center text-center text-[12px]">
									<div
										className={classNames('w-8 h-8 flex items-center justify-center rounded-full', {
											'bg-blue-500 text-white': step === 1,
											'bg-[#fff] text-[#979797] border-[#979797] border-[1px]': step !== 1,
										})}
									>
										1
									</div>
									<span className="mt-2 max-w-[96px] text-[#979797]">Personal Information</span>
								</div>
								<div className=" absolute flex flex-col items-center text-center text-[12px]">
									<div
										className={classNames('w-8 h-8 flex items-center justify-center rounded-full', {
											'bg-blue-500 text-white': step === 2,
											'bg-[#fff] text-[#979797] border-[#979797] border-[1px]': step !== 2,
										})}
									>
										2
									</div>
									<span className="mt-2 max-w-[96px] text-[#979797]">Company Information</span>
								</div>
								<div className="right-0 lg:right-36 absolute flex flex-col items-center text-center text-[12px]">
									<div
										className={classNames('w-8 h-8 flex items-center justify-center rounded-full', {
											'bg-blue-500 text-white': step === 3,
											'bg-[#fff] text-[#979797] border-[#979797] border-[1px]': step !== 3,
										})}
									>
										3
									</div>
									<span className="mt-2 max-w-[96px] text-[#979797]">Account Type</span>
								</div>
							</div>
							<TransitionGroup>
								<CSSTransition key={step} timeout={500} classNames="fade">
									<div>
										{step === 1 && (
											<div className="mt-8">
												<div>
													<div className="mt-2 grid sm:grid-cols-1 lg:grid-cols-2 gap-2">
														<div>
															<label htmlFor="name" className="sm:block 2xl:hidden lg:hidden mb-3">
																{' '}
																Name*
															</label>
															<div className="outlin relative w-full md:min-w-[316px]">
																<input
																	type="text"
																	name="name"
																	value={formData.name}
																	placeholder=" "
																	className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																	onChange={handleChange}
																/>
																<label htmlFor="name" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
																	First Name
																</label>
															</div>
															{displayError('name')}
														</div>
														<div className=" 2xl:block lg:block">
															<label htmlFor="lastname" className="hidden text-base bg-transparent font-normal py-2">
																Last Name*
															</label>
															<div className="outlin relative w-full md:min-w-[316px]">
																<input
																	type="text"
																	name="lastname"
																	value={formData.lastname}
																	placeholder=" "
																	className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																	onChange={handleChange}
																/>
																<label htmlFor="lastname" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
																	Last Name
																</label>
															</div>
															{displayError('lastname')}
														</div>
													</div>
												</div>
												<div className="mt-2">
													<div className="grid sm:grid-cols-1 gap-2 lg:grid-cols-2">
														<label htmlFor="email" className="sm:block 2xl:hidden lg:hidden mt-1">
															Email*
														</label>
														<label htmlFor="email" className="hidden text-base bg-transparent font-normal py-2"></label>
														<div className="outlin relative w-full">
															<input
																type="email"
																name="email"
																placeholder=" "
																value={formData.email}
																className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																onChange={handleChange}
															/>
															<label htmlFor="email" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
																Email
															</label>
															{displayError('email')}
														</div>

														<div className=" 2xl:block lg:block">
															<label htmlFor="password" className="sm:block 2xl:hidden lg:hidden text-base bg-transparent font-normal py-2">
																Password*
															</label>
															<div className="outlin relative w-full">
																<div
																	onClick={() => handleShowPassword('password')}
																	className="absolute right-[20px] top-[50%] cursor-pointer translate-y-[-50%] text-[#A2ACBA]"
																>
																	{showPassword.password ? <VisibilityOffIcon /> : <VisibilityIcon />}
																</div>
																<input
																	type={showPassword.password ? 'text' : 'password'}
																	name="password"
																	disabled={disableEditPassword}
																	value={formData.password}
																	placeholder=" "
																	className="block pl-4 pr-[50px] pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium disabled:opacity-50"
																	onChange={handleChangePassword}
																/>
																<label htmlFor="password" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
																	Password
																</label>
															</div>
															{formData.password && formData.password.length > 0 && (
																<>
																	{renderValidationItem('Minimum Length (7 characters)', validations.length)}
																	{renderValidationItem('Must include at least one uppercase letter (A-Z).', validations.uppercase)}
																	{renderValidationItem('Must include at least one lowercase letter (a-z).', validations.lowercase)}
																	{renderValidationItem('Must include at least one numeric digit (0-9).', validations.digit)}
																	{renderValidationItem(
																		'Must include at least one special character (e.g., !@#$%^&*()_+-=~`{}[]|:;"<>,.?/)',
																		validations.specialChar
																	)}
																</>
															)}
															{displayError('password')}
														</div>
													</div>
												</div>
											</div>
										)}
										{step === 2 && (
											<div className="mt-4 md:mt-8">
												<div className="mt-2 2xl:block lg:block">
													<h2 className="md:hidden">
														<strong>Company Details</strong>
													</h2>
													<div className="outlin relative w-full">
														<input
															type="text"
															name="company_name"
															placeholder=" "
															value={formData.company_name}
															className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
															onChange={handleChange}
														/>
														<label htmlFor="company_name" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
															Company Name
														</label>
													</div>
													{displayError('company_name')}
												</div>
												{!isVisbleCompanyAddressAutoComplete ? (
													<>
														<div className="flex gap-2">
															<div className="2xl:block lg:block w-full">
																<label htmlFor="company address" className="hidden text-base bg-transparent font-normal py-2"></label>
																<div className="outlin relative w-full">
																	<ServerAutoSuggestAddress
																		formData={formData}
																		value={formData.selected_autocomplete_address}
																		setValue={newValue => {
																			updateFormData({
																				...formData,
																				selected_autocomplete_address: newValue,
																			})
																		}}
																		handleChangeGoogleAddress={handleChangeGoogleAddress}
																		className={
																			'custom-input block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium h-[54px]'
																		}
																		view={'register'}
																	/>
																</div>
																{displayError('company_address')}
															</div>
															<div className="mt-2">
																<label htmlFor="apt_unit_number" className="hidden text-base bg-transparent font-normal py-2">
																	Apt/Unit #
																</label>
																<div className="outlin relative w-full">
																	<input
																		type="text"
																		name="apt_unit_number"
																		value={formData.apt_unit_number}
																		placeholder=" "
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium h-[54px]"
																		onChange={handleChange}
																	/>
																	<label
																		htmlFor="apt_unit_number"
																		className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60"
																	>
																		Apt/Unit #
																	</label>
																</div>
																{displayError('apt_unit_number')}
															</div>
														</div>
														<span
															onClick={() => {
																setIsVisbleCompanyAddressAutoComplete(true)
															}}
															className="text-xs mt-[1px] cursor-pointer underline"
														>
															Add Manually
														</span>
														<div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2">
															<div className="mt-2">
																<label htmlFor="phone" className="hidden text-base bg-transparent font-normal py-2">
																	Phone Number
																</label>
																<div className="outlin relative w-full">
																	<input
																		type="tel"
																		name="phone"
																		placeholder=" "
																		value={formData.phone ? formData.phone : ''}
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																		onChange={handleOnChangePhoneNumber}
																	/>
																	<label htmlFor="phone" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
																		Phone Number
																	</label>
																</div>
																{displayError('phone')}
															</div>

															<div className="mt-2">
																<label htmlFor="Resale Certificate" className="hidden text-base bg-transparent font-normal py-2">
																	Resale Certificate
																</label>
																<div className="outlin relative w-full">
																	<input
																		type="text"
																		name="certificate"
																		placeholder=" "
																		value={formData.certificate ? formData.certificate : ''}
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																		onChange={handleChange}
																	/>
																	<label
																		htmlFor="certificate"
																		className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60"
																	>
																		Resale Certificate
																	</label>
																</div>
																{displayError('certificate')}
															</div>
														</div>
													</>
												) : (
													<>
														<div className="flex gap-2">
															<div className="mt-2 2xl:block lg:block w-full">
																<div className="outlin relative w-full">
																	<input
																		type="text"
																		id="1"
																		name="company_address"
																		placeholder=" "
																		autocomplete="off"
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																		value={formData.company_address}
																		onChange={handleChange}
																	/>
																	<label
																		htmlFor="company address"
																		className="pointer-events-none absolute top-1  text-sm bg-transparent p-4 duration-300 origin-0 opacity-60"
																	>
																		Street
																	</label>
																</div>
																{displayError('company_address')}
															</div>
															<div className="mt-2">
																<label htmlFor="apt_unit_number" className="hidden text-base bg-transparent font-normal py-2">
																	Apt/Unit #
																</label>
																<div className="outlin relative w-full">
																	<input
																		type="text"
																		name="apt_unit_number"
																		value={formData.apt_unit_number}
																		placeholder=" "
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium h-[54px]"
																		onChange={handleChange}
																	/>
																	<label
																		htmlFor="apt_unit_number"
																		className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60"
																	>
																		Apt/Unit #
																	</label>
																</div>
																{displayError('apt_unit_number')}
															</div>
														</div>
														<span
															onClick={() => {
																setIsVisbleCompanyAddressAutoComplete(false)
															}}
															className="text-xs mt-[1px] cursor-pointer underline"
														>
															Use Google autocomplete
														</span>
														<div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2">
															<div className="mt-2">
																<label htmlFor="city" className="hidden text-base bg-transparent font-normal py-2">
																	City
																</label>
																<div className="outlin relative w-full">
																	<input
																		type="text"
																		name="city"
																		value={formData.city}
																		placeholder=" "
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																		onChange={handleChange}
																	/>
																	<label htmlFor="city" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
																		City
																	</label>
																</div>
																{displayError('city')}
															</div>
															<div className="mt-2">
																<label htmlFor="state" className="hidden text-base bg-transparent font-normal py-2">
																	State
																</label>
																<div className="outlin relative w-full">
																	<input
																		type="text"
																		value={formData.state}
																		name="state"
																		placeholder=" "
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																		onChange={handleChange}
																	/>
																	<label htmlFor="state" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
																		State
																	</label>
																</div>
																{displayError('state')}
															</div>

															<div className="mt-2">
																<label htmlFor="zip_code" className="hidden text-base bg-transparent font-normal py-2">
																	Zip Code
																</label>
																<div className="outlin relative w-full">
																	<input
																		type="text"
																		value={formData.zip_code}
																		name="zip_code"
																		placeholder=" "
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																		onChange={handleChange}
																	/>
																	<label
																		htmlFor="zip_code"
																		className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60"
																	>
																		Zip Code
																	</label>
																</div>
																{displayError('zip_code')}
															</div>
															<div className="mt-2">
																<label htmlFor="country" className="hidden text-base bg-transparent font-normal py-2">
																	Choose a country
																</label>
																<div className="outlin relative w-full">
																	<select
																		id="country"
																		name="country"
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																		defaultValue={
																			!isEmpty(formData.country_code) ? find(Globals.countries, value => value.code === formData.country_code).code || '' : ''
																		}
																		onChange={handleChange}
																	>
																		<option value="" disabled>
																			Choose a country
																		</option>
																		{Globals.countries.map(c => (
																			<option key={c.code} value={c.code}>
																				{c.name}
																			</option>
																		))}
																	</select>
																</div>
																{displayError('country')}
															</div>
															<div className="mt-2">
																<label htmlFor="phone" className="hidden text-base bg-transparent font-normal py-2">
																	Phone Number
																</label>
																<div className="outlin relative w-full">
																	<input
																		type="tel"
																		name="phone"
																		placeholder=" "
																		value={formData.phone}
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																		onChange={handleOnChangePhoneNumber}
																	/>
																	<label htmlFor="phone" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
																		Phone Number
																	</label>
																</div>
																{displayError('phone')}
															</div>

															<div className="mt-2">
																<label htmlFor="Resale Certificate" className="hidden text-base bg-transparent font-normal py-2">
																	Resale Certificate
																</label>
																<div className="outlin relative w-full">
																	<input
																		type="text"
																		name="certificate"
																		placeholder=" "
																		value={formData.certificate}
																		className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																		onChange={handleChange}
																	/>
																	<label
																		htmlFor="certificate"
																		className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60"
																	>
																		Resale Certificate
																	</label>
																</div>
																{displayError('certificate')}
															</div>
														</div>
													</>
												)}
												<>
													<div className="mt-4 md:mt-8">
														<h2>
															<strong>References</strong>
															<span className="text-[9px] italic underline pl-[4px]">This section is optional but will speed up the approval process</span>
														</h2>
														<div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2 mt-2">
															<div className="outlin relative w-full">
																<input
																	type="text"
																	placeholder=""
																	name="reference_company_name_1"
																	className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																	value={formData.reference_company_name_1}
																	onChange={handleChange}
																	onBlur={e => handleTrimInputOnBlur(e, formData, updateFormData)}
																/>
																<label
																	htmlFor="reference_company_name_1"
																	className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60"
																>
																	Company and Contact Name
																</label>
																{displayError('reference_company_name_1')}
															</div>
															<div className="outlin relative w-full">
																<input
																	type="text"
																	placeholder=""
																	name="reference_email_or_phone_number_1"
																	value={formData.reference_email_or_phone_number_1}
																	className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																	onChange={handleOnChangePhoneNumber}
																	onBlur={e => handleTrimInputOnBlur(e, formData, updateFormData)}
																/>
																<label
																	htmlFor="reference_email_or_phone_number_1"
																	className="pointer-events-none absolute top-1  text-sm bg-transparent p-4 duration-300 origin-0 opacity-60"
																>
																	Phone Number
																</label>
																{displayError('reference_email_or_phone_number_1')}
															</div>
														</div>
														<div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2 mt-2">
															<div className="outlin relative w-full">
																<input
																	type="text"
																	placeholder=""
																	name="reference_company_name_2"
																	value={formData.reference_company_name_2}
																	className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																	onChange={handleChange}
																	onBlur={e => handleTrimInputOnBlur(e, formData, updateFormData)}
																/>
																<label
																	htmlFor="reference_company_name_2"
																	className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60"
																>
																	Company and Contact Name
																</label>
															</div>
															{displayError('reference_company_name_2')}
															<div className="outlin relative w-full">
																<input
																	type="text"
																	placeholder=""
																	value={formData.reference_email_or_phone_number_2}
																	name="reference_email_or_phone_number_2"
																	className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
																	onChange={handleOnChangePhoneNumber}
																	onBlur={e => handleTrimInputOnBlur(e, formData, updateFormData)}
																/>
																<label
																	htmlFor="reference_email_or_phone_number_2"
																	className="pointer-events-none absolute top-1  text-sm bg-transparent p-4 duration-300 origin-0 opacity-60"
																>
																	Phone Number
																</label>
															</div>
															{displayError('reference_email_or_phone_number_2')}
														</div>
													</div>
												</>
											</div>
										)}
										{step === 3 && (
											<>
												<div className="flex items-center justify-center py-8">
													<div className="flex border-2 border-[#e0c789] rounded-full">
														<div
															className={classNames('px-6 py-3 rounded-full transition-colors duration-300 cursor-pointer', {
																'bg-[#5D6E81] text-white cursor-pointer': billingPeriod === 'monthly',
																'bg-transparent text-black cursor-pointer': billingPeriod !== 'monthly',
															})}
															onClick={() => {
																setBillingPeriod('monthly')
															}}
														>
															Monthly Billing
														</div>
														<div
															className={classNames('px-6 py-3 rounded-full transition-colors duration-300 cursor-pointer relative', {
																'bg-[#5D6E81] text-white cursor-pointer': billingPeriod === 'yearly',
																'bg-transparent text-black cursor-pointer': billingPeriod !== 'yearly',
															})}
															onClick={() => {
																setBillingPeriod('yearly')
															}}
														>
															Yearly Billing
															<div className="absolute z-[999] w-full text-center left-0 bottom-[-12px]">
																<span className="bg-[#ffc800] pl-[10px] pr-[10px] rounded-[10px] font-[13px]">Save 20%</span>
															</div>
														</div>
													</div>
												</div>
												<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
													{collections.plansCollection.map((plan, index) => (
														<PricingCard
															key={index}
															plan={plan}
															onClick={() => {
																updateFormData({
																	...formData,
																	membership: plan.value,
																})
															}}
															handleChangePlan={handleChangePlan}
															billingPeriod={billingPeriod}
														/>
													))}
												</div>
											</>
										)}
									</div>
								</CSSTransition>
							</TransitionGroup>

							<div className="mt-4 text-center">{displayError('error')}</div>
							<div
								className={classNames('w-full mb-[10px] mt-[30px] flex items-start', {
									'justify-between': step === 1,
									'justify-end': step !== 1,
								})}
							>
								{step === 1 && (
									<div className="flex flex-col ">
										<div className="outlin relative w-full  items-center flex">
											<label htmlFor="Terms and conditions*" className=" text-base bg-transparent font-normal py-2">
												<input
													type="checkbox"
													name="terms"
													defaultChecked={formData.terms}
													style={{opacity: 1}}
													checked={formData.terms}
													onClick={() => {
														if (!formData.terms) {
															formErrors['terms'] = ''
														}
														updateFormData({
															...formData,
															terms: !formData.terms,
														})
													}}
												/>
												<a
													onClick={() => {
														setShowTerms(true)
													}}
													className="font-normal text-[14px] pl-[30px] underline"
													href="#"
												>
													Terms and conditions*
												</a>
												{showTerms && <TermsOfUse hideTerms={hideTerms} formData={formData} updateFormData={updateFormData} showButtons={true} />}
											</label>
										</div>
										<div className="mt-1">{displayError('terms')}</div>
									</div>
								)}

								<div className="flex justify-end gap-[16px] pb-[80px]">
									{step !== 1 && (
										<div
											onClick={handlePreviousStep}
											className="w-full p-4 rounded text-[#C8C8D0] hover:text-[#5d6e81] cursor-pointer bg-[#fff] font-normal md:text-[14px] md:w-[90px] md:rounded-[4px] md:h-[40px] md:p-0 flex items-center justify-center"
										>
											<span>Previous</span>
										</div>
									)}
									{step !== 3 && (
										<button
											onClick={handleSubmitForm}
											disabled={disableButton || isSubmitting}
											className={classNames(
												'w-full p-4 rounded text-white cursor-pointer bg-[#2C79E0] font-normal md:text-[14px] md:w-[90px] md:rounded-[4px] md:h-[40px] md:p-0',
												{
													'opacity-[0.7]': disableButton || isSubmitting,
												}
											)}
											type="submit"
										>
											<span>Next</span>
										</button>
									)}
								</div>
							</div>
						</form>
					)}
				</div>
			</>
		)
	}
)

export default FormRegister
