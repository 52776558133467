import React, { useState, useEffect, useCallback, useRef } from 'react';
import ActionCable from "actioncable";
import TableCell from "@mui/material/TableCell";
import tableCellClasses from "@mui/material/TableCell/tableCellClasses";
import FilterWebStore from './FilterWebStore';
import FilterModal from './FilterModal';
import FilterByOptionModal from './FilterByOptionModal';
import ContactCartDialog from "./ContactCartDialog";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CircularProgress from '@mui/material/CircularProgress';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { useParams } from 'react-router-dom';
import { Globals } from '../../Context'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import Classnames from 'classnames'
import SearchIcon from '@mui/icons-material/Search';

import { getConditionLabel, toYesNo } from '../../utils/LabelsHelper'
import ListViewCard from './ListViewCard'
import isEqual from 'lodash/isEqual'
import compact from 'lodash/compact'
import cloneDeep from 'lodash/cloneDeep'
import uniq from 'lodash/uniq'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import Paper from '@mui/material/Paper'
import { Helmet } from 'react-helmet';
import { Tooltip } from '@mui/material';

const filtersConfigData = [
    { id: 'brand', label: 'Brand', options: [] },
    { id: 'reference_number', label: 'Reference Number', options: [] },
    { id: 'series', label: 'Series', options: [] },
    { id: 'condition', label: 'Condition', options: ['Unworn', 'Like New', 'Pre-Owned'] },
    { id: 'box_papers', label: 'Box & Papers', options: ['None', 'Box', 'Papers', 'Box & Papers'] }
];

let CableApp = {}

const initialHeaderTable =  [
    { id: 'refNumber', label: 'Ref. Number' },
    { id: 'brand', label: 'Brand' },
]

const withTheme = (Component) => {
    return (props) => {
      const theme = useTheme();
      const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
      return <Component {...props} theme={theme} isMobile={isMobile} />;
    };
};

let initialOffsetTopFilter;
let tableHeaderFixedEl;

let allWatches = []
const WebStoreList = (props) => {
    const [isSetFirstLoadFilterData, setIsSetFirstLoadFilterData] = useState(false)
    const [visibleFields, setVisibleFields] = useState([])
    const [headerTable, setHeaderTable] = useState([])

    const { isMobile } = props;
    const [firstLoadFilterData, setFirstLoadFilterData] = useState([])
    const [currentDropdownFilter, setCurrentDropdownFilter] = useState('')
    const [webStoreSetting, setWebStoreSetting] = useState({})
    const [openContactCartDialog, setOpenContactCartDialog] = useState(false);
    const [visibleGallery, setVisibleGallery] = useState(false);
    const [activeGalleryItem, setActiveGalleryItem] = useState([]);
    const [visibleItem, setVisibleItem] = useState(false);
    const [activeDataItem, setActiveDataItem] = useState();
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [openFilterByOptionModal, setOpenFilterByOptionModal] = useState(false);
    const [activeGalleryItemIndex, setActiveGalleryItemIndex] = useState(0);
    const [expandedRows, setExpandedRows] = useState([]);
    const [loading, setLoading] = useState(false)
    const [dataProvider, setDataProvider] = useState([])
    const [contactCardData, setContactCardData] = useState({})
    const [keySearch, setKeySearch] = useState('')
    const [filterData, setFilterData] = useState(null)
    const [webStoreChannel, setWebStoreChannel] = useState(null)
    const [loadingMoreData, setLoadingMoreData] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [nextPage, setNextPage] = useState(null)
    const [totalEntries, setTotalEntries] = useState(0)
    const [space, setSpace] = useState({})
    const [logo, setLogo] = useState('')
    const [logo_thumb, setLogoThumb] = useState('')
    const [filterDataSelected, setFilterDataSelected] = useState({
        brand: [],
        reference_number: [],
        series: [],
        condition: [],
        box_papers: [],
    })
    const [currentFilterDataSelected, setCurrentFilterDataSelected] = useState({
        brand: [],
        reference_number: [],
        series: [],
        condition: [],
        box_papers: [],
    })
    const [filterOption, setFilterOption] = useState();
    const [isSearching, setIsSearching] = useState(false);
    const { slug, uid } = props;

    const webStoreContainer = useRef(null);
    const inputSearchMobile = useRef(null);
    const listViewCardFilterRef = useRef(null);
    const gridViewCardFilterRef = useRef(null);
    const gridTableContainerRef = useRef(null);
    const tableHeaderRef = useRef(null);
    const isFirstRun = useRef(true);
    const [filterPriorities, setFilterPriorities] = useState([]);

    const newFiltersConfigData = filterData ? filtersConfigData.map((item) => {
        if (item.id === 'box_papers' || item.id === 'condition') {
          return item
        } else {
          return {
            ...item,
            options: compact(uniq(filterData.map((data) => data[item.id]))).sort()
          }
        }
      }) : filtersConfigData

    const refetchData = useCallback(
        async (withParams = true) => {
          setLoadingMoreData(true);
          setLoading(true);

          const webStoreUrl = `${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/store/${uid}/${slug}`;

          const params = withParams
            ? {
                brand: filterDataSelected.brand,
                reference_number: filterDataSelected.reference_number,
                series: filterDataSelected.series,
                condition: filterDataSelected.condition,
                box_papers: filterDataSelected.box_papers,
                search_term: String(keySearch),
              }
            : {};

          try {
            const response = await Globals.New_Axios().get(webStoreUrl, { params });
            const { watches, contact_card, filter_list, space, paginate, logo, logo_thumb, web_store_setting } = response.data;

            if (!isSetFirstLoadFilterData) {
                // firstLoadFilterData = filter_list
                setFirstLoadFilterData(filter_list)
                setIsSetFirstLoadFilterData(true)
            }

            if (allWatches.length === 0) {
                allWatches = cloneDeep(filter_list)
            }

            setFilterData(filter_list || []);
            setDataProvider(watches || []);
            setContactCardData(contact_card || []);
            setWebStoreSetting(web_store_setting || {});
            setNextPage(paginate?.next_page || null);
            setTotalEntries(paginate?.total_entries || 0);
            setSpace(space || {});
            setLogo(logo || '');
            setLogoThumb(logo_thumb || '');
          } catch (error) {
            console.log('error', error);
          } finally {
            setLoading(false);
            setLoadingMoreData(false);
          }
        },
        [slug, filterDataSelected, keySearch]
    );

    useEffect(() => {
        if (filterPriorities.length === 0) {
            // Note: Visit webstore => search with keywork => clear search
            setIsSetFirstLoadFilterData(false)
        }

        if (!keySearch) {
            // Note: Test steps to run into this case: Search => select a filter => clear search
            setFirstLoadFilterData(allWatches)
        }
    }, [keySearch]);

    useEffect(() => {
        let debouncedRefetchData

        if (isFirstRun.current) {
            refetchData(false);
            isFirstRun.current = false;
        } else {
            debouncedRefetchData = debounce(()=> refetchData(true), 600)
            debouncedRefetchData()
        }

        return () => {
            debouncedRefetchData?.cancel();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterDataSelected, keySearch]);

    useEffect(() => {
        if (visibleGallery) {
            document.addEventListener('keydown', handleKeyPress);
        } else {
            document.removeEventListener('keydown', handleKeyPress);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleGallery]);

    useEffect(() => {
        CableApp.cable = ActionCable.createConsumer(`${Globals.actionCableURL}?web_store_slug_id=${slug}&uid=${uid}`)

        const subscribeToWebStore = () => {
            if (CableApp.cable) {
                const channel = CableApp.cable.subscriptions.create(
                    {
                        channel: 'WebStoreChannel',
                        web_store_slug_id: slug,
                        uid: uid,
                    },
                    {
                        connected: () => {
                        },
                        disconnected: () => {
                        },
                        received: (data) => {
                            const { watch_params } = data;
                            if (!watch_params) return;

                            setDataProvider(prev => {
                                const index = prev.findIndex(item => item.id === watch_params.id);
                                const isAvailableOnWebStore = watch_params.is_available_on_webstore;

                                if (index !== -1) {
                                  if (isAvailableOnWebStore) {
                                    return prev.map(item =>
                                        item.id === watch_params.id ? watch_params : item
                                    );
                                  } else {
                                    const watches = prev.filter(item => item.id !== watch_params.id);
                                    if (watches.length !== dataProvider.length) {
                                      refetchData();
                                    }

                                    return watches;
                                  }
                                }

                                return prev;
                            });
                        },
                    }
                );

                setWebStoreChannel(channel);
            }
        }

        subscribeToWebStore();

        return () => {
            if (webStoreChannel) {
                webStoreChannel.unsubscribe();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSearching) {
            inputSearchMobile.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            inputSearchMobile.current.querySelector('input').focus();
        }
    }, [isSearching]);

    useEffect(() => {
        handleGetGlobalColumnConfigs()
    }, []);


    const handleGetGlobalColumnConfigs = async () => {
		Globals.New_Axios().get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/store_watch_columns/${uid}`).then(async response => {
			const globalColumnConfigs = response.data
			setVisibleFields(globalColumnConfigs.filter(item => item.visible).map(item => item.key))
		}).catch(error => {
		})
	}

    useEffect(() => {
        let currentHeaderTable = [...initialHeaderTable]

        if (visibleFields.includes('series')) {
            currentHeaderTable.push({ id: 'series', label: 'Series' })
        }
        if (visibleFields.includes('condition')) {
            currentHeaderTable.push({ id: 'condition', label: 'Condition' })
        }
        if (visibleFields.includes('dial')) {
            currentHeaderTable.push({ id: 'dial', label: 'Dial' })
        }
        if (visibleFields.includes('bezel')) {
            currentHeaderTable.push({ id: 'bezel', label: 'Bezel' })
        }
        if (visibleFields.includes('bracelet')) {
            currentHeaderTable.push({ id: 'bracelet', label: 'Bracelet' })
        }
        if (visibleFields.includes('warranty_papers')) {
            currentHeaderTable.push({ id: 'papers', label: 'Papers' })
        }
        if (visibleFields.includes('box')) {
            currentHeaderTable.push({ id: 'box', label: 'Box' })
        }

        setHeaderTable(currentHeaderTable)
    }, [visibleFields])

    const handleKeyPress = (e) => {
        switch (e.keyCode) {
            case 39:
                paginateSlider('next');
                break;
            case 37:
                paginateSlider('previous');
                break;
            default:
                break;
        }
    };

    const handleChangeFilterData = useCallback((type, value) => {
        const indexOfFilter = filterPriorities.indexOf(type);
        let newFilterPriorities = cloneDeep(filterPriorities);

        if (indexOfFilter < 0) {
            newFilterPriorities.push(type);
        } else if (isEqual(filterDataSelected[type], [value])) {
            newFilterPriorities = filterPriorities.filter((filter) => filter !== type);
        }

        setFilterPriorities(newFilterPriorities)

        if (filterDataSelected[type].includes(value)) {
            setFilterDataSelected(prev => ({
                ...prev,
                [type]: prev[type].filter(item => item !== value)
            }))
            return
        } else {
            setFilterDataSelected(prev => ({
                ...prev,
                [type]: [...prev[type], value]
            }))
        }
    }, [filterDataSelected, setFilterDataSelected])

    const handleSearch = (e) => {
        setKeySearch(e.target.value)
    }

    const handleClearSearch = () => {
        setKeySearch('')
    }

    const handleClearAllFilter = () => {
        setCurrentFilterDataSelected({
            brand: [],
            reference_number: [],
            series: [],
            condition: [],
            box_papers: [],
        })

        setFilterDataSelected({
            brand: [],
            reference_number: [],
            series: [],
            condition: [],
            box_papers: [],
        })

        setFilterPriorities([])
    }

    const toggleContactCartDialog = () => {
        setOpenContactCartDialog(prev => !prev)
    }

    const handleRowExpandToggle = (index) => {
        setExpandedRows(prev =>
            prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
        );
    };

    const openGalleryItems = data => {
        setActiveGalleryItem(data.images)
        setVisibleGallery(true);
    }

    const openGalleryItemCardView = (data, index) => {
        setActiveGalleryItem(data.images)
        setVisibleGallery(true);
        setActiveGalleryItemIndex(index)
    }

    const closeGallery = () => {
        setVisibleGallery(false)
        setActiveGalleryItem([])
        setActiveGalleryItemIndex(0)
    }

    const closeGalleryCardView = () => {
        setVisibleGallery(false)
    }

    const openItemDetail = data => {
        setActiveGalleryItem(data.images)
        setActiveDataItem(data)
        setVisibleItem(true);
    }

    const closeItemDetail = () => {
        setVisibleItem(false)
        setActiveGalleryItemIndex(0)
    }


    const closeFilterModal = () => {
        setCurrentFilterDataSelected(filterDataSelected)
        setOpenFilterModal(false)
    }

    const handleChooseFilterOption = (typeFilter) => {
        setOpenFilterModal(false)
        setFilterOption(newFiltersConfigData.find(filter => filter.id === typeFilter))
        setOpenFilterByOptionModal(true)
    }

    const closeFilterByOptionModal = () => {
        setOpenFilterByOptionModal(false)
        setOpenFilterModal(true)
    }

    const handleFirstOpenFilterModal = () => {
        setCurrentFilterDataSelected(filterDataSelected)
        setIsSearching(false)
        setOpenFilterModal(true)
    }

    const handleApplyFilterByOption = (filterId, dataSelected) => {
        setCurrentFilterDataSelected(prev => {
            return {
                ...prev,
                [filterId]: dataSelected
            }
        })
    }

    const handleApplyFilter = () => {
        if( JSON.stringify(currentFilterDataSelected) !== JSON.stringify(filterDataSelected) ) {
            setFilterDataSelected(currentFilterDataSelected)
        }
    }

    const paginateSlider = (direction) => {
        setActiveGalleryItemIndex(prevIndex => {
            if (direction === 'previous') {
                return prevIndex === 0 ? activeGalleryItem.length - 1 : prevIndex - 1;
            } else {
                return prevIndex === activeGalleryItem.length - 1 ? 0 : prevIndex + 1;
            }
        });
    };

    const isPresentFilterDataSelected = () => {
        return filterDataSelected.brand.length > 0 ||
        filterDataSelected.reference_number.length > 0 ||
        filterDataSelected.series.length > 0 ||
        filterDataSelected.condition.length > 0 ||
        filterDataSelected.box_papers.length > 0
    }

    useEffect(() => {
      const initialOffsetTopFilterInterval = setInterval(() => {
        const filterRef = listViewCardFilterRef.current?.filterRef() || gridViewCardFilterRef.current;
        if (filterRef) {
          initialOffsetTopFilter = filterRef.offsetTop;
          clearInterval(initialOffsetTopFilterInterval);
        }
      }
      , 500);

      return () => {
        clearInterval(initialOffsetTopFilterInterval);
      }
    }, [])

    const handleScroll = async (e) => {
      const filterEl = listViewCardFilterRef.current?.filterRef() || gridViewCardFilterRef.current;
      tableHeaderFixedEl = tableHeaderFixedEl || tableHeaderRef.current?.cloneNode(true);

      if (filterEl) {
        if (e.target.scrollTop >= initialOffsetTopFilter) {
          filterEl.style.position = 'fixed';
          filterEl.style.width = '100%';
          filterEl.style.backgroundColor = '#fff';
          filterEl.style.zIndex = '999';
          filterEl.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 16px 0px';
        } else {
          filterEl.style.position = 'relative';
          filterEl.style.width = '100%';
          filterEl.style.backgroundColor = 'transparent';
          filterEl.style.boxShadow = 'none';
        }
      }

      if (tableHeaderFixedEl) {
        const originalThs = tableHeaderRef.current?.querySelectorAll('th') || [];
        const clonedThs = tableHeaderFixedEl.querySelectorAll('th');

        originalThs.forEach((th, index) => {
          const width = window.getComputedStyle(th).width;
          if (clonedThs[index]) {
            Object.assign(clonedThs[index].style, {
              width: width,
              display: 'inline-block',
            });
          }
        });

        if (e.target.scrollTop >= initialOffsetTopFilter) {
          tableHeaderFixedEl.style.display = 'block';
          tableHeaderFixedEl.style.margin = '0 25px';
          tableHeaderFixedEl.style.width = tableHeaderRef.current?.offsetWidth + 'px';
          tableHeaderFixedEl.style.height = tableHeaderRef.current?.offsetHeight + 'px';
          filterEl.appendChild(tableHeaderFixedEl);

          handleScrollXTable();
        } else {
          tableHeaderFixedEl.style.display = 'none';
        }
      }

      if (loadingData || dataProvider.length >= totalEntries) return

      const { scrollTop, scrollHeight, clientHeight } = webStoreContainer.current
      const isDistanceToBottomEqualHalf = scrollTop + clientHeight >= scrollHeight / 2

      if (isDistanceToBottomEqualHalf && dataProvider.length < totalEntries) {
        await handleLoadMoreData()
      }
    }

    const handleScrollXTable = () => {
      if (!tableHeaderFixedEl) return;

      const scrollLeft = gridTableContainerRef.current?.scrollLeft;
      tableHeaderFixedEl.style.marginLeft = (-scrollLeft + 25) + 'px';
    }

    const handleClickSearchMobile = () => {
        setIsSearching((prev) => !prev);
    };

    const handleLoadMoreData = async () => {
        setLoadingData(true)
        const webStoreUrl = `${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/store/${uid}/${slug}`;
        try {
            const response = await Globals.New_Axios().get(webStoreUrl, {
                params: {
                    brand: filterDataSelected.brand,
                    reference_number: filterDataSelected.reference_number,
                    series: filterDataSelected.series,
                    condition: filterDataSelected.condition,
                    box_papers: filterDataSelected.box_papers,
                    search_term: keySearch ? String(keySearch) : null,
                    page: nextPage
                }
            });
            const { watches, paginate } = response.data

            if (watches && watches.length > 0) {
                setDataProvider(prev => [...prev, ...watches])
            }

            if (paginate && paginate.next_page) {
                setNextPage(paginate.next_page)
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            setLoadingData(false)
        }
    }

    const renderSerialNumber = (serialNumber) => {
        if (!serialNumber) return ''
        if (serialNumber.length <= 5) return '*****'

        return `${serialNumber.slice(0, 3)}*****`
    }

    return (

            (webStoreSetting.view_ui === 'card' && !isMobile) ?
                (
                    <>
                        <Helmet>
                            <title>{webStoreSetting.store_company_name}</title>
                            <meta property="og:image" content={logo_thumb.url} />
                            <meta name="twitter:image" content={logo_thumb.url} />
                            <link rel="icon" type="image/png" sizes="32x32" href={logo_thumb.url} />
                            <link rel="icon" type="image/png" sizes="16x16" href={logo_thumb.url} />
                            <link rel="apple-touch-icon" sizes="180x180" href={logo_thumb.url} />
                            <meta name="msapplication-TileImage" content={logo_thumb.url} />
                        </Helmet>

                        <ListViewCard
                            handleScroll={handleScroll}
                            filterDataSelected={filterDataSelected}
                            handleChangeFilterData={handleChangeFilterData}
                            handleClearAllFilter={handleClearAllFilter}
                            webStoreContainer={webStoreContainer}
                            logo={logo}
                            space={space}
                            toggleContactCartDialog={toggleContactCartDialog}
                            currentDropdownFilter={currentDropdownFilter}
                            setCurrentDropdownFilter={setCurrentDropdownFilter}
                            handleSearch={handleSearch}
                            filterData={filterData}
                            keySearch={keySearch}
                            handleClearSearch={handleClearSearch}
                            webStoreSetting={webStoreSetting}
                            isPresentFilterDataSelected={isPresentFilterDataSelected}
                            loadingMoreData={loadingMoreData}
                            dataProvider={dataProvider}
                            loading={loading}
                            openContactCartDialog={openContactCartDialog}
                            setOpenContactCartDialog={setOpenContactCartDialog}
                            contactCardData={contactCardData}
                            isMobile={isMobile}
                            visibleItem={visibleItem}
                            closeItemDetail={closeItemDetail}
                            activeDataItem={activeDataItem}
                            renderSerialNumber={renderSerialNumber}
                            openGalleryItemCardView={openGalleryItemCardView}
                            closeFilterModal={closeFilterModal}
                            handleChooseFilterOption={handleChooseFilterOption}
                            filtersConfigData={filtersConfigData}
                            currentFilterDataSelected={currentFilterDataSelected}
                            handleApplyFilter={handleApplyFilter}
                            openFilterModal={openFilterModal}
                            openFilterByOptionModal={openFilterByOptionModal}
                            closeFilterByOptionModal={closeFilterByOptionModal}
                            filterOption={filterOption}
                            handleApplyFilterByOption={handleApplyFilterByOption}
                            visibleGallery={visibleGallery}
                            activeGalleryItem={activeGalleryItem}
                            openItemDetail={openItemDetail}
                            activeGalleryItemIndex={activeGalleryItemIndex}
                            paginateSlider={paginateSlider}
                            closeGalleryCardView={closeGalleryCardView}
                            firstLoadFilterData={firstLoadFilterData}
                            filterPriorities={filterPriorities}
                            visibleFields={visibleFields}
                            ref={listViewCardFilterRef}
                        />
                    </>
                )
            :
            (
                <>
                    <Helmet>
                        <title>{webStoreSetting.store_company_name}</title>
                        <meta property="og:image" content={logo_thumb.url} />
                        <meta name="twitter:image" content={logo_thumb.url} />
                        <link rel="icon" type="image/png" sizes="32x32" href={logo_thumb.url} />
                        <link rel="icon" type="image/png" sizes="16x16" href={logo_thumb.url} />
                        <link rel="apple-touch-icon" sizes="180x180" href={logo_thumb.url} />
                        <meta name="msapplication-TileImage" content={logo_thumb.url} />
                    </Helmet>

                    <div className="bg-white w-full h-screen overflow-auto" onScroll={handleScroll} ref={webStoreContainer}>
                        {(webStoreSetting.view_ui === 'grid' || isMobile) &&
                            <>
                                <div className="h-[200px] w-full bg-[#E8E8E8] md:bg-white px-5 md:px-10 text-black flex flex-col md:flex-row justify-between pt-6 items-center">
                                    <div className="flex w-full justify-start items-center mb-4 md:mb-0 md:w-auto">
                                        {logo.url &&
                                            <div className="h-[89px] w-[89px] md:h-[120px] md:w-[120px]">
                                                <img src={logo.url} alt={webStoreSetting.store_company_name} className="w-auto h-auto max-h-[89px] max-w-[89px] md:max-h-[120px] md:max-w-[120px] object-cover" />
                                            </div>
                                        }
                                        <div className="ml-3">
                                            <div className="font-bold text-2xl mb-3 md:text-[40px]">{webStoreSetting.store_company_name}</div>
                                            <div className="flex items-center">
                                                <div className="text-black hidden md:block">{totalEntries} Watches Listed</div>
                                                {
                                                    (contactCardData.phone || contactCardData.email || contactCardData.address) ?
                                                    <div
                                                        className="border border-[#5D6E81] rounded-[5px] md:rounded-[0px] md:border-[#D9D9D9] py-[6px] px-[7px] text-[9px] md:text-[10px] md:ml-4 cursor-pointer font-bold text-[#5D6E81] w-[85px] md:w-[90px] text-center"
                                                        onClick={toggleContactCartDialog}
                                                    >
                                                        Contact Card
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {
                                      !isEmpty(webStoreSetting) && !webStoreSetting.white_label && (
                                        <div className="w-auto h-[45px] max-h-[45px] md:max-h-[130px] md:w-auto md:h-[130px] md:relative absolute bottom-0 right-0  flex justify-end md:mt-[-20px]">
                                          <img src="/Images/powered-by-elefta.png" className="w-[180px] h-[38px] m-1 lg:m-4 object-cover" alt="" />
                                        </div>
                                      )
                                    }
                                </div>
                                {
                                    !isMobile && (
                                        <div className="z-50 w-full bg-white top-0" ref={gridViewCardFilterRef}>
                                            <FilterWebStore
                                                onChangeFilterData={handleChangeFilterData}
                                                filterDataSelected={filterDataSelected}
                                                currentDropdownFilter={currentDropdownFilter}
                                                setCurrentDropdownFilter={setCurrentDropdownFilter}
                                                onSearch={handleSearch}
                                                filterData={filterData}
                                                keySearch={keySearch}
                                                onClearSearch={handleClearSearch}
                                                firstLoadFilterData={firstLoadFilterData}
                                                filterPriorities={filterPriorities}
                                                visibleFields={visibleFields}
                                            />
                                            <div className="flex flex-wrap items-center max-w-full px-20 mt-2 gap-2">
                                                {isPresentFilterDataSelected() && (
                                                    <>
                                                        <div className="italic text-xs flex-shrink-0">Filtered By:</div>
                                                        {Object.keys(filterDataSelected).flatMap(key =>
                                                            filterDataSelected[key].map((item, index) => (
                                                                <div key={`${key}-${item}-${index}`} className="flex items-center px-3 py-1 rounded-md italic text-xs">
                                                                    <span className="mr-1">{item}</span>
                                                                    <button
                                                                        className="font-bold italic ml-2"
                                                                        onClick={() => handleChangeFilterData(key, item)}
                                                                    >
                                                                        X
                                                                    </button>
                                                                </div>
                                                            ))
                                                        )}
                                                        <div
                                                            className="italic text-[10px] flex-shrink-0 cursor-pointer hover:font-bold"
                                                            onClick={handleClearAllFilter}
                                                        >
                                                            RESET FILTERS
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                                {/* Filter Mobile Screen */}

                                {/* Loading Effect */}


                                {/* List Item */}
                                {
                                    isMobile ?
                                    (
                                        <React.Fragment>
                                            { isSearching ? (
                                                <div ref={inputSearchMobile} className="mx-[46px] text-[12px] font-normal text-[#5D6E81] my-[15px]">
                                                    <TextField
                                                        placeholder="Search here"
                                                        sx={{
                                                            width: '100%',
                                                            textAlign: 'center',
                                                            margin: '12px auto 0px auto',
                                                            height: '40px',
                                                            color: 'secondary'
                                                        }}
                                                        value={keySearch}
                                                        InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="start" sx={{color: '#2C79E0'}}>
                                                                        {   keySearch.length > 0 ? (
                                                                                <CloseIcon onClick={handleClearSearch} />
                                                                        ) : (
                                                                            <SearchIcon />
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                                style: { height: '40px' },
                                                                classes: {
                                                                    notchedOutline: "!border-[#2C79E0]",
                                                                },
                                                            }}
                                                        onInput={handleSearch}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="flex justify-between mx-[20px] my-[15px]">
                                                    {
                                                        !loading &&
                                                        <div className="text-[12px] font-normal text-[#5D6E81]">
                                                            {totalEntries} Watches Listed
                                                        </div>
                                                    }
                                                    { isPresentFilterDataSelected() &&
                                                        <div className="italic font-semibold text-[#2C79E0] font-xs">
                                                            Filter Applied
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                            <div className="watch-list-container mx-[20px]">
                                                <Grid container rowSpacing={2} columnSpacing={2}>
                                                    {
                                                        (!isEmpty(dataProvider)) ?
                                                            dataProvider.map((row, index) => (
                                                                <Grid item xs={6} key={index}>
                                                                    <div
                                                                        className={Classnames('watch-list-item max-w-[173px] max-h-[224px] border border-[#E8E8E8] px-[10px] pb-[5px]', {'pt-[5px]': !webStoreSetting.images_visible})}
                                                                        onClick={() => openItemDetail(row)
                                                                    }>
                                                                        {webStoreSetting.images_visible &&
                                                                            <div className="watch-list-item-image flex justify-center mt-[7px] h-[108px]">
                                                                                {
                                                                                    row.images && row.images.length > 0
                                                                                        ?
                                                                                        <img src={row.images[0].url} className="object-contain max-h-full" alt="" />
                                                                                        :
                                                                                        <img src="/watch_icon.png" className="object-contain max-h-full" alt="" />
                                                                                }
                                                                            </div>
                                                                        }
                                                                        <div className="text-[12px] font-bold text-center">{row.brand}</div>
                                                                        <div className="mx-4 h-[1px] bg-[#5D6E81]"></div>
                                                                        <div className="watch-list-item-content text-[10px] text-[#000] mt-1">
                                                                            {
                                                                                visibleFields.includes('series') && (
                                                                                    <div className="flex justify-between">
                                                                                        <span>Model</span>
                                                                                        <span className="ml-[5px] truncate">{row.series}</span>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            <div className="flex justify-between">
                                                                                    <span>Ref.</span>
                                                                                    <span className="ml-[5px] truncate">{row.reference_number}</span>
                                                                            </div>
                                                                            {
                                                                                visibleFields.includes('dial') && (
                                                                                    <div className="flex justify-between">
                                                                                            <span>Dial</span>
                                                                                            <span className="ml-[5px] truncate">{row.dial}</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                visibleFields.includes('bracelet') && (
                                                                                    <div className="flex justify-between">
                                                                                            <span>Bracelet</span>
                                                                                            <span className="ml-[5px] truncate">{row.bracelet}</span>
                                                                                    </div>
                                                                                )
                                                                            }


                                                                            <div className="flex justify-between my-1">
                                                                                <div className="flex items-center">
                                                                                    {visibleFields.includes('warranty_papers') && row.papers &&
                                                                                        <span className="me-2">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                                                <path d="M4 12.8H12V11.2H4V12.8ZM4 9.6H12V8H4V9.6ZM2 16C1.45 16 0.979167 15.8433 0.5875 15.53C0.195833 15.2167 0 14.84 0 14.4V1.6C0 1.16 0.195833 0.783333 0.5875 0.47C0.979167 0.156667 1.45 0 2 0H10L16 4.8V14.4C16 14.84 15.8042 15.2167 15.4125 15.53C15.0208 15.8433 14.55 16 14 16H2ZM9 5.6V1.6H2V14.4H14V5.6H9Z" fill="#5D6E81"/>
                                                                                            </svg>
                                                                                        </span>
                                                                                    }
                                                                                    {visibleFields.includes('box') && row.box &&
                                                                                        <span>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                <path d="M4.16666 18.3334C3.70832 18.3334 3.31596 18.1702 2.98957 17.8438C2.66318 17.5174 2.49999 17.125 2.49999 16.6667V7.27085C2.24999 7.11808 2.0486 6.92016 1.89582 6.6771C1.74305 6.43405 1.66666 6.1528 1.66666 5.83335V3.33335C1.66666 2.87502 1.82985 2.48266 2.15624 2.15627C2.48263 1.82988 2.87499 1.66669 3.33332 1.66669H16.6667C17.125 1.66669 17.5174 1.82988 17.8437 2.15627C18.1701 2.48266 18.3333 2.87502 18.3333 3.33335V5.83335C18.3333 6.1528 18.2569 6.43405 18.1042 6.6771C17.9514 6.92016 17.75 7.11808 17.5 7.27085V16.6667C17.5 17.125 17.3368 17.5174 17.0104 17.8438C16.684 18.1702 16.2917 18.3334 15.8333 18.3334H4.16666ZM4.16666 7.50002V16.6667H15.8333V7.50002H4.16666ZM3.33332 5.83335H16.6667V3.33335H3.33332V5.83335ZM7.49999 11.6667H12.5V10H7.49999V11.6667Z" fill="#5D6E81"/>
                                                                                            </svg>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                                {visibleFields.includes('wholesale_price') && webStoreSetting?.price_visible &&
                                                                                    row.price ? (
                                                                                        <span className="flex items-center ml-[5px] text-[#5D6E81] font-bold text-[12px]">
                                                                                            {`$${row.price}`}
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span className="flex items-center text-[10px] font-bold text-[#5D6E81] whitespace-pre">
                                                                                            Contact for price
                                                                                        </span>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            ))
                                                    :
                                                        (
                                                            !loading &&
                                                            <Grid item xs={12} className="flex justify-center">
                                                                No data found
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </div>
                                            <Stack
                                                sx={{}}
                                                direction="row"
                                                divider={<Divider orientation="vertical" flexItem />}
                                                spacing={2}
                                                className="bg-[#2C79E0] text-white w-[225px] h-[36px] rounded-[30px] absolute bottom-[25px] left-1/2 transform -translate-x-1/2 flex justify-around items-center px-2 font-medium"
                                            >
                                                <div
                                                    className="cursor-pointer flex ps-[12px] w-1/2"
                                                    onClick={handleFirstOpenFilterModal}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M4.24209 16.2606V10.7824H2.685V9.21715H7.35628V10.7824H5.79918V16.2606H4.24209ZM4.24209 7.65194V3.73889H5.79918V7.65194H4.24209ZM7.35628 7.65194V6.08672H8.91337V3.73889H10.4705V6.08672H12.0276V7.65194H7.35628ZM8.91337 16.2606V9.21715H10.4705V16.2606H8.91337ZM13.5847 16.2606V13.9128H12.0276V12.3476H16.6988V13.9128H15.1417V16.2606H13.5847ZM13.5847 10.7824V3.73889H15.1417V10.7824H13.5847Z" fill="white"/>
                                                    </svg>
                                                    <span>Filters</span>
                                                </div>
                                                <div
                                                    className="cursor-pointer pe-[12px] w-1/2 flex"
                                                    onClick={() => handleClickSearchMobile()}
                                                >
                                                    <span>Search</span>
                                                </div>
                                            </Stack>
                                        </React.Fragment>
                                    )
                                    :
                                    (
                                        <TableContainer
                                            component={Paper}
                                            elevation={0}
                                            className="mt-4 px-[25px]"
                                            ref={gridTableContainerRef}
                                            onScroll={handleScrollXTable}
                                        >
                                            <Table className="max-h-full" sx={{
                                                [`& .${tableCellClasses.root}`]: {
                                                    borderBottom: "none",
                                                }
                                            }}>
                                                <TableHead
                                                    sx={{
                                                        backgroundColor: '#5D6E81',
                                                        margin: '20px 60px',
                                                        whiteSpace: 'nowrap',
                                                        borderTop: '0px',
                                                        position: 'sticky',
                                                        top: 0,
                                                        left: 0,
                                                    }}
                                                    ref={tableHeaderRef}
                                                >
                                                        <TableRow>
                                                            {webStoreSetting?.images_visible && (
                                                                <TableCell align="center" sx={{ color: '#FFFFFF', fontWeight: '700', whiteSpace: "nowrap", borderRight: '1px solid white',padding: "5px 35px"}}>
                                                                    Images
                                                                </TableCell>
                                                            )}
                                                            {headerTable.map((header, _) => (
                                                                <TableCell align="center" key={header.id} sx={{ color: '#FFFFFF', fontWeight: '700', whiteSpace: "nowrap", borderLeft: '1px solid white', padding: "5px 35px" }}>
                                                                    {header.label}
                                                                </TableCell>
                                                            ))}
                                                            {webStoreSetting?.price_visible && (
                                                                <TableCell align="center" sx={{ color: '#FFFFFF', fontWeight: '700', whiteSpace: "nowrap", borderLeft: '1px solid white', padding: "5px 35px" }}>
                                                                    Price
                                                                </TableCell>
                                                            )}
                                                            <TableCell align="center" sx={{ color: '#FFFFFF', fontWeight: '700', whiteSpace: "nowrap", borderLeft: '1px solid #5D6E81', padding: "0px" }}></TableCell>
                                                        </TableRow>
                                                </TableHead>
                                                <TableBody className="h-full overflow-auto">
                                                    <TableRow>
                                                        <TableCell sx={{height: '18px', p: 0}} colSpan={headerTable.length + 2}>
                                                        </TableCell>
                                                    </TableRow>
                                                    {loading ? (
                                                        <TableRow>
                                                            <TableCell colSpan={headerTable.length + 3} align="center" rowSpan={3}>

                                                            </TableCell>
                                                        </TableRow>
                                                    ) : !isEmpty(dataProvider) && !loading
                                                        ?
                                                        dataProvider.map((row, index) => (
                                                            <React.Fragment key={index}>
                                                                {
                                                                    expandedRows.includes(index)
                                                                        ?
                                                                        (<TableRow
                                                                            key={`${row.refNumber}-${index}expanded`}
                                                                            sx={{
                                                                                '&:nth-of-type(even)': { backgroundColor: '#F4F4F4' },
                                                                                '&:last-child td, &:last-child th': { border: 0 },
                                                                                padding: '0px',
                                                                            }}
                                                                            onClick={() => handleRowExpandToggle(index)}
                                                                        >
                                                                            {webStoreSetting?.images_visible && (
                                                                                <TableCell
                                                                                    align="center"
                                                                                    sx={{
                                                                                        paddingTop: '11px',
                                                                                        paddingBottom: '60px',
                                                                                        px: '35px',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        if (row.images.length > 0) {
                                                                                            openGalleryItems(row)
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <div className="w-[75px] h-[75px] justify-items-center content-center">
                                                                                        {row.images && row.images.length > 0 ?
                                                                                            (
                                                                                                <img src={row.images[0].url} className="w-auto h-auto object-cover cursor-pointer max-w-[75px] max-h-[75px]" alt="" />
                                                                                            ) :
                                                                                            (
                                                                                                <img src="/watch_icon.png" className="w-auto h-auto object-cover cursor-pointer max-w-[75px] max-h-[75px]" alt="" />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </TableCell>
                                                                            )}
                                                                            <TableCell colSpan={headerTable.length} sx={{ color: '#5D6E81', fontWeight: '700', py: '30px' }}>
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={3}>
                                                                                        <div className="flex">
                                                                                            <span className="font-normal flex-1">Brand</span>
                                                                                            <span className="flex-1">{row.full_brand}</span>
                                                                                        </div>
                                                                                        <div className="flex">
                                                                                            <span className="font-normal flex-1">Ref. Number</span>
                                                                                            <span className="flex-1">{row.reference_number}</span>
                                                                                        </div>
                                                                                        <div className="flex">
                                                                                            <span className="font-normal flex-1">Dealer SKU</span>
                                                                                            <span className="flex-1">{row.sku}</span>
                                                                                        </div>

                                                                                        {
                                                                                            visibleFields.includes('serial_number') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Serial Number</span>
                                                                                                    <span className="flex-1">{renderSerialNumber(row.serial_number)}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        {
                                                                                            visibleFields.includes('series') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Series</span>
                                                                                                    <span className="flex-1">{row.series}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            visibleFields.includes('dial') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Dial</span>
                                                                                                    <span className="flex-1">{row.dial}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            visibleFields.includes('bezel') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Bezel</span>
                                                                                                    <span className="flex-1">{row.bezel}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            visibleFields.includes('bracelet') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Bracelet</span>
                                                                                                    <span className="flex-1">{row.bracelet}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </Grid>

                                                                                    <Grid item xs={3}>
                                                                                        {
                                                                                            visibleFields.includes('condition') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Condition</span>
                                                                                                    <span className="flex-1">{row.display_condition}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            visibleFields.includes('warranty_papers') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Papers</span>
                                                                                                    <span className="flex-1">{toYesNo(row.papers)}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            visibleFields.includes('warranty') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Warranty Date</span>
                                                                                                    <span className="flex-1">{row.display_warranty}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            visibleFields.includes('box') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Box</span>
                                                                                                    <span className="flex-1">{toYesNo(row.box)}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        {
                                                                                            visibleFields.includes('link_count') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Link Count</span>
                                                                                                    <span className="flex-1">{row.link_count}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            visibleFields.includes('addition_details') && (
                                                                                                <div className="flex">
                                                                                                    <span className="font-normal flex-1">Additional Details</span>
                                                                                                    <span className="flex-1 break-all">{row.addition_details}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </TableCell>
                                                                            <TableCell align="center" colSpan={2} sx={{
                                                                                color: '#5D6E81',
                                                                                fontWeight: '700',
                                                                                whiteSpace: 'nowrap',
                                                                                py: '30px'
                                                                            }}>
                                                                            {row.on_hold && (
                                                                                        <Tooltip
                                                                                            title="This item is on hold"
                                                                                            placement="bottom" arrow>
                                                                                        <span
                                                                                            className="inline mx-1 text-orange-500">
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                fill="none"
                                                                                                viewBox="0 0 24 24"
                                                                                                strokeWidth={1.5}
                                                                                                stroke="currentColor"
                                                                                                className="w-6 h-6"
                                                                                                style={{
                                                                                                    display: 'initial',
                                                                                                    verticalAlign: 'top'
                                                                                                }}
                                                                                            >
                                                                                                <path
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                                                                                />
                                                                                            </svg>
                                                                                        </span>
                                                                                        </Tooltip>
                                                                                )}
                                                                                {row.price ? (
                                                                                    <span>{row.price}</span>
                                                                                ) : (
                                                                                    <span>Contact for price</span>
                                                                                )}
                                                                                <IconButton sx={{ mr: 2, float: 'right', padding: 0 }}>
                                                                                    {expandedRows.includes(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>)
                                                                        :
                                                                        (<TableRow
                                                                            key={index}
                                                                            sx={{
                                                                                '&:nth-of-type(even)': { backgroundColor: '#F4F4F4' },
                                                                                '&:last-child td, &:last-child th': { border: 0 },
                                                                                height: '91px',
                                                                                padding: '0px',
                                                                            }}
                                                                            onClick={() => handleRowExpandToggle(index)}
                                                                        >
                                                                            {webStoreSetting?.images_visible && (
                                                                                <TableCell
                                                                                align="center"
                                                                                sx={{
                                                                                    paddingTop: '11px',
                                                                                    paddingBottom: '5px',
                                                                                    px: '35px',
                                                                                }}
                                                                                onClick={() => {
                                                                                    if (row.images.length > 0) {
                                                                                        openGalleryItems(row)
                                                                                    }
                                                                                }}
                                                                                >

                                                                                    <div className="w-[75px] h-[75px] justify-items-center content-center">
                                                                                        {row.images && row.images.length > 0 ?
                                                                                            (
                                                                                                <img src={row.images[0].url} className="w-auto h-auto object-cover cursor-pointer max-w-[75px] max-h-[75px]" alt="" />
                                                                                            ) :
                                                                                            (
                                                                                                <img src="/watch_icon.png" className="w-auto h-auto object-cover cursor-pointer max-w-[75px] max-h-[75px]" alt="" />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </TableCell>
                                                                            )}
                                                                            <TableCell sx={{ color: '#5D6E81', fontWeight: '700', padding: '0px 0px 0px 35px' }}>{row.reference_number}</TableCell>
                                                                            <TableCell sx={{ color: '#5D6E81', fontWeight: '700', padding: '0px 0px 0px 35px' }}>{row.brand}</TableCell>
                                                                            {
                                                                                visibleFields.includes('series') && (
                                                                                    <TableCell sx={{ color: '#5D6E81', fontWeight: '700', padding: '0px 0px 0px 35px' }}>{row.series}</TableCell>
                                                                                )
                                                                            }
                                                                            {
                                                                                visibleFields.includes('condition') && (
                                                                                    <TableCell sx={{ color: '#5D6E81', fontWeight: '700', padding: '0px 0px 0px 35px' }}>{getConditionLabel(row.condition)}</TableCell>
                                                                                )
                                                                            }
                                                                            {
                                                                                visibleFields.includes('dial') && (
                                                                                    <TableCell sx={{ color: '#5D6E81', fontWeight: '700', padding: '0px 0px 0px 35px' }}>{row.dial}</TableCell>
                                                                                )
                                                                            }
                                                                            {
                                                                                visibleFields.includes('bezel') && (
                                                                                    <TableCell sx={{ color: '#5D6E81', fontWeight: '700', padding: '0px 0px 0px 35px' }}>{row.bezel}</TableCell>
                                                                                )
                                                                            }
                                                                            {
                                                                                visibleFields.includes('bracelet') && (
                                                                                    <TableCell sx={{ color: '#5D6E81', fontWeight: '700', padding: '0px 0px 0px 35px' }}>{row.bracelet}</TableCell>
                                                                                )
                                                                            }
                                                                            {
                                                                                visibleFields.includes('warranty_papers') && (
                                                                                    <TableCell align="center" sx={{ color: '#5D6E81', fontWeight: '700'}}>{toYesNo(row.papers)}</TableCell>
                                                                                )
                                                                            }
                                                                            {
                                                                                visibleFields.includes('box') && (
                                                                                    <TableCell align="center" sx={{ color: '#5D6E81', fontWeight: '700'}}>{row.box ? "Yes" : "No"}</TableCell>
                                                                                )
                                                                            }

                                                                            <TableCell align="center" colSpan={2} sx={{
                                                                                color: '#5D6E81',
                                                                                fontWeight: '700',
                                                                                whiteSpace: 'nowrap',
                                                                            }}>
                                                                                {row.on_hold && (
                                                                                            <Tooltip
                                                                                                title="This item is on hold"
                                                                                                placement="bottom" arrow>
                                                                                            <span
                                                                                                className="inline mx-1 text-orange-500">
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    fill="none"
                                                                                                    viewBox="0 0 24 24"
                                                                                                    strokeWidth={1.5}
                                                                                                    stroke="currentColor"
                                                                                                    className="w-6 h-6"
                                                                                                    style={{
                                                                                                        display: 'initial',
                                                                                                        verticalAlign: 'top'
                                                                                                    }}
                                                                                                >
                                                                                                    <path
                                                                                                        strokeLinecap="round"
                                                                                                        strokeLinejoin="round"
                                                                                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                                                                                    />
                                                                                                </svg>
                                                                                            </span>
                                                                                            </Tooltip>
                                                                                    )}
                                                                                {row.price ? (
                                                                                    <span>{row.price}</span>
                                                                                ) : (
                                                                                    <span>Contact for price</span>
                                                                                )}
                                                                                <IconButton sx={{ mr: 2, float: 'right', padding: 0 }} >
                                                                                    {expandedRows.includes(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>)
                                                                }
                                                            </React.Fragment>
                                                        )) : (
                                                            <TableRow>
                                                                <TableCell colSpan={headerTable.length + 2} align="center" rowSpan={3}>
                                                                    No data found
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )
                                }

                                {/* Dialog */}
                                <ContactCartDialog
                                    open={openContactCartDialog}
                                    setOpen={setOpenContactCartDialog}
                                    space={space}
                                    logo={logo}
                                    contactCardData={contactCardData}
                                    isMobile={isMobile}
                                />

                                <Dialog
                                    fullScreen
                                    open={visibleGallery}
                                    classes={{ paper: 'transparent-dialog' }}
                                    onKeyUp={e => {
                                        if (e.key === 'Escape') closeGallery()
                                    }}
                                >
                                    <div className="bg-white sm:bg-[#D9D9D9E5] sm:h-full w-full p-10">
                                        <div className="h-full w-full relative">
                                            {activeGalleryItem && (
                                                <div className="h-full w-full md:p-10">
                                                    {activeGalleryItem.map((image, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                {index === activeGalleryItemIndex && (
                                                                    <div className="flex h-full items-center justify-center w-full relative">
                                                                        <div className="relative h-full w-full flex justify-center">
                                                                            {isMobile ?
                                                                                (
                                                                                <>
                                                                                    <div className='relative max-h-full w-full content-center'>
                                                                                        <Swiper
                                                                                            spaceBetween={50}
                                                                                            slidesPerView={1}
                                                                                            pagination={{
                                                                                                type: 'progressbar',
                                                                                                horizontalClass: "custom-swiper-pagination",
                                                                                                progressbarFillClass: "swiper-pagination-progressbar-fill !bg-black"
                                                                                            }}
                                                                                            loop={true}
                                                                                            modules={[Pagination]}
                                                                                        >
                                                                                            {activeGalleryItem.map((image, index) => (
                                                                                                <SwiperSlide key={index}>
                                                                                                    {image.type.includes('video') ? (
                                                                                                        <video controls className="max-h-full max-w-full">
                                                                                                            <source src={image.video_url} type="video/mp4" />
                                                                                                        </video>
                                                                                                    ) : (
                                                                                                        <img src={image.url} alt="." className="h-full w-full object-contain" />
                                                                                                    )}
                                                                                                </SwiperSlide>
                                                                                            ))}
                                                                                        </Swiper>
                                                                                    </div>
                                                                                    <button
                                                                                        className="absolute right-0 bg-white rounded-full p-2 z-10 m-4 active:scale-90"
                                                                                        onClick={closeGallery}
                                                                                    >
                                                                                        <CloseIcon sx={{ color: 'black', fontSize: 32 }} />
                                                                                    </button>
                                                                                </>
                                                                                ) :
                                                                                (
                                                                                    <React.Fragment>
                                                                                        <div className="h-full w-full max-w-[550px] max-h-[550px] md:max-w-[793px] md:max-h-[791px] flex justify-center relative">
                                                                                            {activeGalleryItem.length > 1 &&
                                                                                                <button
                                                                                                    className="border-black border-2 rounded-full p-2 absolute z-10 bottom-1/2 transform left-[-60px] translate-y-1/2 active:scale-90"
                                                                                                    onClick={paginateSlider.bind(this, 'previous')}
                                                                                                >
                                                                                                    <ChevronLeftIcon sx={{ fontSize: '32px' }} />
                                                                                                </button>
                                                                                            }
                                                                                            <div className="relative w-auto h-auto max-w-[550px] max-h-[550px] md:max-w-[793px] md:max-h-[791px]">
                                                                                                {image.type.includes('video') ? (
                                                                                                    <video controls className="w-full h-full">
                                                                                                        <source src={image.video_url} type="video/mp4" />
                                                                                                    </video>
                                                                                                ) : (
                                                                                                    <img key={image.url} src={image.url} alt="." className="w-full h-full"/>
                                                                                                )}
                                                                                                <button
                                                                                                    className="bg-black rounded-full p-2 absolute top-0 right-0 z-20 m-4 active:scale-90"
                                                                                                    onClick={closeGallery}
                                                                                                >
                                                                                                    <CloseIcon sx={{ color: 'white', fontSize: '32px' }} />
                                                                                                </button>
                                                                                            </div>
                                                                                            {activeGalleryItem.length > 1 &&
                                                                                                <button
                                                                                                    className="border-black border-2 rounded-full p-2 absolute z-10 right-[-60px] bottom-1/2 transform translate-y-1/2 active:scale-90"
                                                                                                    onClick={paginateSlider.bind(this, 'next')}
                                                                                                >
                                                                                                    <ChevronRightIcon sx={{ fontSize: '32px' }} />
                                                                                                </button>
                                                                                            }
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Dialog>
                                {isMobile &&
                                    <>
                                        <Dialog
                                            fullScreen
                                            open={visibleItem}
                                            classes={{ paper: 'transparent-dialog' }}
                                            onKeyUp={e => {
                                                if (e.key === 'Escape') closeItemDetail()
                                            }}
                                        >
                                            <div className="bg-[#D9D9D9E5] sm:h-full w-full p-10">
                                                <div className="h-full w-full relative">
                                                    {activeDataItem && (
                                                        <div className="h-full w-full md:p-10">
                                                            <div className="bg-white min-h-[604px] w-full border-[1px] border-black flex">
                                                                <button
                                                                    className="bg-white rounded-full mt-1 p-2 absolute right-0 mr-4 active:scale-90 z-[999]"
                                                                    onClick={closeItemDetail}
                                                                >
                                                                    <CloseIcon sx={{ color: 'black', fontSize: 32 }} />
                                                                </button>
                                                                <div
                                                                    className={Classnames('watch-list-item px-[20px] sm:px-[54px] pt-[15px] pb-[5px] text-[#5d6e81] mx-[auto] w-full', {'mt-[40px]': !webStoreSetting.images_visible})}
                                                                >
                                                                    {
                                                                        webStoreSetting.images_visible && (

                                                                            <div
                                                                                className="relative watch-list-item-image flex justify-center"
                                                                                onClick={() => {
                                                                                    if (activeDataItem.images && activeDataItem.images.length > 0) {
                                                                                        openGalleryItems(activeDataItem)}
                                                                                    }
                                                                                }
                                                                            >
                                                                                {
                                                                                    activeDataItem.images && activeDataItem.images.length > 0
                                                                                        ?
                                                                                        <React.Fragment>
                                                                                            <img src={`${activeDataItem.images[0].url}`} className="max-h-[140px]" alt="" />
                                                                                            <div className='preview absolute top-0 left-[80px] right-[80px] bottom-0 flex items-center justify-center cursor-pointer'>
                                                                                                <button className='preview-btn bg-blue p-[8px] rounded-full'>
                                                                                                    <svg
                                                                                                        width="20"
                                                                                                        height="15"
                                                                                                        viewBox="0 0 22 15"
                                                                                                        fill="white"
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                    >
                                                                                                        <path d="M11 2C14.79 2 18.17 4.13 19.82 7.5C18.17 10.87 14.79 13 11 13C7.21 13 3.83 10.87 2.18 7.5C3.83 4.13 7.21 2 11 2ZM11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 5C12.38 5 13.5 6.12 13.5 7.5C13.5 8.88 12.38 10 11 10C9.62 10 8.5 8.88 8.5 7.5C8.5 6.12 9.62 5 11 5ZM11 3C8.52 3 6.5 5.02 6.5 7.5C6.5 9.98 8.52 12 11 12C13.48 12 15.5 9.98 15.5 7.5C15.5 5.02 13.48 3 11 3Z" />
                                                                                                    </svg>
                                                                                                </button>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        <img src="/watch_icon.png" className="max-h-[140px]" alt="" />
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                    <div className="text-xl font-bold text-center">{activeDataItem.brand}</div>
                                                                    <div className="mx-4 h-[1px] bg-[#5D6E81]"></div>
                                                                    <div className="watch-list-item-content text-xs mt-1 space-y-2">
                                                                        {
                                                                            visibleFields.includes('series') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Model</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.series}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('reference_number') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Ref. Number</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.reference_number}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('sku') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Dealer SKU</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.sku}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('wholesale_price') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Price</span>
                                                                                    {
                                                                                        !!activeDataItem?.price ?
                                                                                        <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.price}</span> :
                                                                                        <span className="ml-[5px] font-bold break-words overflow-break-word">Contact for price</span>

                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('serial_number') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Serial Number</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{renderSerialNumber(activeDataItem.serial_number)}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('condition') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Condition</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.display_condition}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('dial') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Dial</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.dial}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('bezel') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Bezel</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.bezel}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('bracelet') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Bracelet</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.bracelet}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('warranty_papers') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Papers</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.display_warranty_papers}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('warranty') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Warranty Date</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.display_warranty}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('box') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Box</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.box ? "Yes" : "No"}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('link_count') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Link Count</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.link_count}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            visibleFields.includes('addition_details') && (
                                                                                <div className="flex">
                                                                                    <span className="basis-1/2 shrink-0">Additional Details:</span>
                                                                                    <span className="ml-[5px] font-bold break-words overflow-break-word">{activeDataItem.addition_details}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Dialog>
                                        <FilterModal
                                            open={openFilterModal}
                                            onClearFilter={handleClearAllFilter}
                                            onClose={closeFilterModal}
                                            onChooseFilter={handleChooseFilterOption}
                                            filtersConfigData={filtersConfigData}
                                            dataSelected={currentFilterDataSelected}
                                            onApplyFilter={handleApplyFilter}
                                        />
                                        {filterOption &&
                                            <FilterByOptionModal
                                                open={openFilterByOptionModal}
                                                onClose={closeFilterByOptionModal}
                                                filterOption={filterOption}
                                                dataSelected={currentFilterDataSelected}
                                                onApplyFilter={handleApplyFilterByOption}
                                            />
                                        }
                                    </>
                                }
                            </>
                        }
                        {loadingMoreData && (
                            <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
                                <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
                                    disabled
                                >
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        {' '}
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        />
                                    </svg>
                                    <span>Loading... </span>
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )
    )
}

export default withTheme(WebStoreList);
