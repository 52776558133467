import React, {useEffect, useRef, useState} from 'react'
import {Globals} from '../../Context'
import ConfirmPopUp from '../ConfirmPopUp'
import Tooltip from '@mui/material/Tooltip'
import Moment from 'moment'
import Classnames from 'classnames'
import Dialog from '@mui/material/Dialog'
import ActionCable from "actioncable";

import EditMemo from './EditMemo'
import SingleItemDetail from '../Sales/SingleItemDetail'
import { focusElement } from '../../utils/keyboardHandler'
import {findOptionByCharacter} from '../../utils/keyboardHandler'
import useOutsideClick from '../../hooks/useOutsideClick'
import CommentForm from './CommentForm'

import find from 'lodash/find'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isNil from 'lodash/isNil'

let cableApp = {}

const SingleMemoDetail = ({
	singleMemoItemId,
	closeSingleMemoDetail,
	props,
	memosList,
	updateMemoList,
	handleLoadFirstData,
	handleClickOut,
	handleLoadMemos,
	handleOpenPerformanceLimitAccessModal,
	fromInventory = false,
	updateItemInInventories,
	updateItemInMemos,
	currentSpace,
	profile,
	handlePrimaryTab
}) => {
	const [singleMemoItem, setSingleMemoItem] = useState()
	const [visibleStatusDropDown, setVisibleStatusDropDown] = useState(false)
	const [sendingData, setSendingData] = useState(false)
	const [checkedItems, setCheckedItems] = useState([])
	const [memoComments, setMemoComments] = useState([])
	const [openEdit, setOpenEdit] = useState(false)
	const [showOrderDetail, setShowOrderDetail] = useState(false)
	const [clickedOrderId, setClickedOrderId] = useState()
	const [actionButton, setActionButton] = useState(false)

	const [sendingComment, setSendingComment] = useState(false)
	const [selectAllCheckBox, setSelectallCheckBox] = useState(false)
	const [commentField, setCommentField] = useState({content: '', visible_on_pdf: false})
	const [loading, setLoading] = useState()
	const [isVisibleShippingDialog, setIsVisibleShippingDialog] = useState(false)
	const [isCreateInvoiceShiping, setIsCreateInvoiceShipping] = useState(false)
	const [trackingNumber, setTrackingNumber] = useState('')
	const [isFlagTrackingNumber, setIsFlagTrackingNumber] = useState(false)
	const [loadedSpaceUsers, setLoadedSpaceUsers] = useState(null)

	const selectRef = useRef(null)
	const returnButtonRef = useRef(null);
 	const invoiceButtonRef = useRef(null);
 	const actionButtonRef = useRef(null);
	const trackingNumberInputRef = useRef(null)
	const itemRefs = [actionButtonRef, invoiceButtonRef, returnButtonRef];

	const [selectedBillingAddress, setSelectedBillingAddress] = useState()
	const [selectedShippingAddress, setSelectedShippingAddress] = useState()
	const [selectedComment, setSelectedComment] = useState()
	const [isVisibleComments, setIsVisibleComments] = useState(false)
	const [customer, setCustomer] =useState()

	useOutsideClick(trackingNumberInputRef, () => handleClickOutTrackingNumber());

	const goToNextOption = (event, currentIndex, nextIndex) => {
		event.preventDefault();
		if(actionButtonRef.current === document.activeElement) setVisibleStatusDropDown(true)

		if(currentIndex < itemRefs.length - 1) {
			nextIndex = currentIndex + 1
			itemRefs[nextIndex]?.current?.focus();
		} else {
			actionButtonRef.current.focus()
		}
	}

	const goToPreviousOption = (event, currentIndex, nextIndex) => {
		event.preventDefault();
		if(currentIndex > 0) {
			nextIndex =  currentIndex - 1
			itemRefs[nextIndex]?.current?.focus();
		} else {
			actionButtonRef.current.focus()
		}
	}

	const closeDropDown = (event)=> {
		event.preventDefault();
		setVisibleStatusDropDown(false)
		focusElement('.single-customer-view')
		event.stopPropagation();
	}

	const handleKeyDown = (event) => {
		if(openEdit) return
		const currentIndex = itemRefs.findIndex(ref => ref.current === document.activeElement);
		let nextIndex = currentIndex;
		switch (event.key) {
			case Globals.keyboardButton.arrowDown:
				goToNextOption(event, currentIndex, nextIndex)
				break;
			case Globals.keyboardButton.arrowUp:
				goToPreviousOption(event, currentIndex, nextIndex)
				break;
			case Globals.keyboardButton.escape:
				const currentItem = find(itemRefs, (value) => value.current ===  document.activeElement)
				if(currentItem) {
					closeDropDown(event, currentItem)
				}
				break;
			default:
				findOptionByCharacter(event, itemRefs)
			  break;
		  }
	  };


	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		cableApp?.cable?.disconnect()
		cableApp.cable = ActionCable.createConsumer(`${Globals.actionCableURL}?access_token=${Globals.getAccessToken()}`)
		cableApp.cable.subscriptions.create({channel: "MemoChannel", space_id: currentSpace.id}, {
			received: (data) => {
				if ((data.action === 'update' || data.action === 'delete') && data.created_by_id !== profile.id) {
					Globals.New_Axios()
					.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos/${data.memo_id}`)
					.then(async response => {
						if(singleMemoItemId === response.data.id) {
							setSingleMemoItem(response.data)
						}
					}).catch(error => {
						console.log(error)
					})
				}
			}
		})
		focusElement('.single-customer-view')
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const setAction = () => {
		setActionButton(!actionButton)
	}

	useEffect(() => {
		const handleClickOutside = event => {
			if (visibleStatusDropDown && !actionButton && selectRef.current && !selectRef.current.contains(event.target)) {
				setVisibleStatusDropDown(false)
			}
		}
		if (visibleStatusDropDown) {
			document.addEventListener('mousedown', handleClickOutside)
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [visibleStatusDropDown, actionButton])

	useEffect(() => {
		loadSingleMemo(singleMemoItemId)
		loadSingleMemoComments(singleMemoItemId)
		handleLoadUsers()
	}, [])

	useEffect(() => {
		if (singleMemoItem) {
			setSelectedBillingAddress(singleMemoItem.billing_address)
			setSelectedShippingAddress(singleMemoItem.shipping_address)
			setCustomer(singleMemoItem.customer)
			setTrackingNumber(singleMemoItem.tracking_number)
		}
	}, [singleMemoItem])


	const handleChangeTrackingNumber = (event) => {
		setIsFlagTrackingNumber(true)
		setTrackingNumber(event.target.value)
	}

	const emailMemo = async isSend => {
		let requestHeader = ''
		let isLoader = 'email'
		let urlParm = 'send_memo'
		if (!isSend) {
			requestHeader = {responseType: 'arraybuffer', headers: {Accept: 'application/pdf'}}
			isLoader = 'download'
			urlParm = 'download.pdf'
		}
		setLoading(isLoader)
		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos/${singleMemoItemId}/${urlParm}`, '', requestHeader)
			.then(async response => {
				setLoading(false)

				if (isSend) {
					const { data } = response
					const recipient = data.receipient;
					const subject = encodeURIComponent(data.subject);
					const body = encodeURIComponent(data.body);
					// Create the mailto link
					const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;

					// Open the default email client
					window.location.href = mailtoLink;
				} else {
					const blob = new Blob([response.data], {type: 'application/pdf'})
					const url = window.URL.createObjectURL(blob)
					const link = document.createElement('a')
					const customerIdentifyer = singleMemoItem.customer.company_name ? singleMemoItem.customer.company_name : singleMemoItem.customer.name
					link.href = url
					link.setAttribute('download', `${customerIdentifyer}-Memo-${singleMemoItem.memo_number}.pdf`)
					document.body.appendChild(link)
					link.click()
					link.parentNode.removeChild(link)
				}
			})
			.catch(error => {
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const loadSingleMemo = memoId => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos/${memoId}`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					setSingleMemoItem(response.data)
				}
			})
			.catch(error => {
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const loadSingleMemoComments = memoId => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/Memo/${memoId}/comments`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					setMemoComments(response.data.comments)
				}
			})
			.catch(error => {
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const handleUpdateItemInInventory = (memo) => {
		if (fromInventory) {
			memo.memo_items.map((item) => {
				if (!!item.memoable) {
					updateItemInInventories(item.memoable)
				}
			})
		}
	}

	const handleReturningItems = ($event, useShipping=false) => {
		$event.preventDefault()
		setVisibleStatusDropDown(false)

		if (sendingData) return

		setSendingData(true)

		return Globals.New_Axios()
			.post(`${Globals.baseUri}/memos/${singleMemoItemId}/return`, {
					memo_item_ids: checkedItems,
					create_invoice_shipping: useShipping
			})
			.then(response => {
				setSendingData(false)
				const memo = response.data
				setSingleMemoItem(memo)
				setCheckedItems([])
				if (!!updateItemInMemos) {
					updateItemInMemos(memo)
				}
				handleUpdateItemInInventory(memo)
			})
			.catch(error => {
				setSendingData(false)
				return props.addToast({
					text: error.response?.data?.message || 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const handleCreateInvoice = $event => {
		$event.preventDefault()
		if (currentSpace?.internal_subscription.status === 'active' && currentSpace?.internal_subscription.plan_type === 'performance') {
			handleOpenPerformanceLimitAccessModal()
		} else {
			setVisibleStatusDropDown(false)

			if (sendingData) return

			setSendingData(true)

			return Globals.New_Axios()
				.post(`${Globals.baseUri}/memos/${singleMemoItemId}/invoice`, {
					memo_item_ids: checkedItems,
				})
				.then(response => {
					setSendingData(false)
					const memo = response.data
					setSingleMemoItem(memo)
					setCheckedItems([])

					if (!!updateItemInMemos) {
						updateItemInMemos(memo)
					}
					handleUpdateItemInInventory(memo)
				})
				.catch(error => {
					setSendingData(false)
					return props.addToast({
						text: error.response?.data?.message || 'Something went wrong please try again later.',
						type: 'error',
					})
				})
		}
	}

	const handleClosingMemo = (useShipping=false) => {
		let _signleMemo = singleMemoItem
		_signleMemo.status = 'closed'
		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos/${singleMemoItemId}`, {
				memo: {..._signleMemo},
				create_invoice_shipping: useShipping
			})
			.then(response => {
				if (isFunction(handleLoadFirstData)) {
					handleLoadFirstData()
				}

				if (memosList) {
					const memos = memosList.map(memo => {
						if (singleMemoItemId === memo.id) {
							memo.status = 'closed'
						}

						return memo
					})

					const closedMemos = memos.filter(memo => memo.status === 'closed'),
						openMemos = memos.filter(memo => memo.status === 'published')

					updateMemoList(memos, openMemos, closedMemos)
				}
				setCheckedItems([])
				setSingleMemoItem(response.data)
				handleUpdateItemInInventory(response.data)
			})
			.catch(error => {
				this.setState({sendingData: false})
				return this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const handleCheckShippingForCloseMemo = () => {
		if (currentSpace?.internal_subscription.status === 'active' && currentSpace?.internal_subscription.plan_type === 'performance') {
			handleOpenPerformanceLimitAccessModal()
		} else {
			setIsCreateInvoiceShipping(false)
			if(singleMemoItem.shipping_price > 0 ) {
				setIsVisibleShippingDialog(true)
			} else {
				handleClosingMemo()
			}
		}
	}

	const handleSubmitComment = $event => {
		$event.preventDefault()

		setSendingComment(true)

		if (sendingComment) return

		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/Memo/${singleMemoItemId}/comments`, {
				comment: commentField,
			})
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					setCommentField({content: '', visible_on_pdf: false})
					setSendingComment(false)
					setIsVisibleComments(!isVisibleComments)

					return setMemoComments([...memoComments, response.data])
				}
			})
			.catch(error => {
				setSendingComment(false)
        const messageErr = error.response?.data?.message || 'Something went wrong please try again later.'
				return props.addToast({
					text: messageErr,
					type: 'error',
				})
			})
	}

	const handleUpdateComment = $event => {
		$event.preventDefault()

		setSendingComment(true)

		if (sendingComment) return

		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/Memo/${singleMemoItemId}/comments/${selectedComment.id}`, {
				comment: commentField,
			})
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					setCommentField({content: '', visible_on_pdf: false})
					setSendingComment(false)
					setSelectedComment(null)
					setIsVisibleComments(!isVisibleComments)

					return setMemoComments(memoComments.map(comment => (comment.id === response.data.id ? response.data : comment)))
				}
			})
			.catch(error => {
				setSendingComment(false)
        const messageErr = error.response?.data?.message || 'Something went wrong please try again later.'

				return props.addToast({
					text: messageErr,
					type: 'error',
				})
			})

	}

	const handleDeleteComment = commentId => {
		return Globals.New_Axios()
			.delete(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/Memo/${singleMemoItemId}/comments/${commentId}`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					setMemoComments(memoComments.filter(comment => comment.id !== commentId))
          if (commentId === selectedComment?.id) {
            setCommentField({content: '', visible_on_pdf: false})
            setSelectedComment(null)
          }
        }
      }).catch(error => {
        const messageErr = error.response?.data?.message || 'Something went wrong please try again later.'

				return props.addToast({
					text: messageErr,
					type: 'error',
				})
			})
	}

	const handleToogleInputComment = (type, selectedComment = null) => {
		if (currentSpace?.internal_subscription.status === 'active' && currentSpace?.internal_subscription.plan_type === 'performance') {
			handleOpenPerformanceLimitAccessModal()
			return
		}

		setIsVisibleComments(!isVisibleComments)

		if (type === 'new') {
      setSelectedComment(null)
      setCommentField({content: '', visible_on_pdf: false})
		}

		if (type === 'edit') {
			setSelectedComment(selectedComment)
			setCommentField(selectedComment)
		}
	}

	const allMemoItemsNotReturnedNorInvoiced = () => {
		return singleMemoItem.memo_items.filter(item => !(item.returned || item.invoiced))
	}

	const allMiscItemsNotReturnedNorInvoiced = () => {
		return singleMemoItem.misc_items.filter(item => !(item.returned || item.invoiced))
	}

	const handleCheckAllRows = ($event, value) => {
		setSelectallCheckBox(value)
		if(!value) {
			return setCheckedItems([])
		}
		setCheckedItems([...allMemoItemsNotReturnedNorInvoiced().map(item => item.id), ...allMiscItemsNotReturnedNorInvoiced().map(item => item.id)])
	}


	const handleCheckRow = data => {
		let checkedItem = checkedItems
		if (checkedItem.includes(data.id)) {
			//remove it from the rows checked.
			checkedItem = checkedItems.filter(row => row !== data.id)
		} else {
			//Add to the rows checked.
			checkedItem = [...checkedItem, data.id]
		}
		setCheckedItems(checkedItem)
	}
	const handleOpenEdit = () => {
		if ( currentSpace?.internal_subscription.status === 'active' && currentSpace?.internal_subscription.plan_type === 'performance') {
			handleOpenPerformanceLimitAccessModal()
		} else {
			setOpenEdit(!openEdit)
			focusElement('.single-customer-view')
		}
	}
	const updateMemoItem = (memoItems, shipping, miscItems) => {
		setSingleMemoItem(memoItems)
		if (fromInventory) {
			handleLoadFirstData()
		} else {
			if (!!updateItemInMemos) {
				updateItemInMemos(memoItems)
				// setMemoComments
			}
		}
	}
	const openInvoice = orderId => {
		setShowOrderDetail(true)
		setClickedOrderId(orderId)
	}
	const closeSingleOrderItem = () => {
		setShowOrderDetail(false)
	}

	const isAllReturnedOrInvoiced = () => {
		const allMemoItemsReturnedOrInvoiced = singleMemoItem.memo_items.filter(item => item.returned || item.invoiced)
		const allMiscItemsReturnedOrInvoiced = singleMemoItem.misc_items.filter(item => item.returned  || item.invoiced)

		if (allMemoItemsReturnedOrInvoiced.length === singleMemoItem.memo_items.length && allMiscItemsReturnedOrInvoiced.length === singleMemoItem.misc_items.length) {
			return true
		}
		return false
	}

	const handleCheckLastItemWhenReturnMemo = (event) => {
		if (currentSpace?.internal_subscription.status === 'active' && currentSpace?.internal_subscription.plan_type === 'performance') {
			handleOpenPerformanceLimitAccessModal()
		} else {
			setIsCreateInvoiceShipping(true)
			const allMemoItemsReturnedOrInvoiced = singleMemoItem.memo_items.filter(item => item.returned || item.invoiced)
			const allMiscItemsReturnedOrInvoiced = singleMemoItem.misc_items.filter(item => item.returned  || item.invoiced)
			if(((singleMemoItem.memo_items.length + singleMemoItem.misc_items.length) - (allMemoItemsReturnedOrInvoiced.length + allMiscItemsReturnedOrInvoiced.length) === checkedItems.length) && singleMemoItem.shipping_price > 0 ) {
				setIsVisibleShippingDialog(true)
			} else {
				handleReturningItems(event)
			}
		}
	}

	const handleRemoveShippingPrice = (event) => {
		if(isCreateInvoiceShiping) {
			handleReturningItems(event)
		} else {
			handleClosingMemo()
		}
		setIsVisibleShippingDialog(false)
	}

	const handleCreateInvoiceForShippingPrice = (event) => {
		if(isCreateInvoiceShiping) {
			handleReturningItems(event, true)
		} else {
			handleClosingMemo(true)
		}
		setIsVisibleShippingDialog(false)
	}

	const handleClickOutTrackingNumber = () => {
		if(openEdit) return
		if(isFlagTrackingNumber) {
			handleSubmitTrackingNumber()
			setIsFlagTrackingNumber(false)
		}
	}

	const handleSubmitTrackingNumber = () => {
		Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos/${singleMemoItem.id}`, {
				memo: {
					tracking_number: trackingNumber,
				},
			})
			.then(response => {
				setSingleMemoItem(response.data)
				props.addToast({
					text: 'Tracking Number Updated Successfully',
					type: 'success',
				})

			}).catch(error => {
				console.log(error, 'error')
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const handleLoadUsers = () => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/users`, {
				params: {
					q: {
						s: 'created_at desc',
					},
					per_page: 10000,
				},
			})
			.then(response => {
				let {users} = response.data
				setLoadedSpaceUsers(users)
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return props.addToast({
					text: message,
					type: 'error',
				})
			})
	}

	const handleKeyDownTrackingNumber = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			trackingNumberInputRef.current.blur();
			handleSubmitTrackingNumber()
		}
	};

	const sendAndDownloadMemo = (value) => {
		if (currentSpace?.internal_subscription.status === 'active' && currentSpace?.internal_subscription.plan_type === 'performance') {
			handleOpenPerformanceLimitAccessModal()
			return
		}
		emailMemo(value)
	};
	console.log("customer")
	console.log(customer)
	return (
		<div
			onClick={handleClickOut}
			onKeyDown={e => {
				e.key === 'Escape' && e.target.classList.contains('single-customer-view') ? handleClickOut(e) : ''
			}}
			className="single-customer-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-40"
			tabIndex="-1"
		>
			{openEdit && <EditMemo handleOpenEdit={handleOpenEdit} memoData={singleMemoItem} props={props} updateMemoItem={updateMemoItem} handleUpdateItemInInventory={handleUpdateItemInInventory} handlePrimaryTab={handlePrimaryTab} loadedUsers={loadedSpaceUsers}/>}
			{
				isVisibleShippingDialog &&
				<Dialog
					open={open}
					onKeyUp={e => {
						if (e.key === 'Escape') setIsVisibleShippingDialog(false)
					}}
				>
					<div className="confirmation-dialog-box-inner p-8">
						<div className='flex justify-end items-end'>
							<button
								onClick={() => {
									setIsVisibleShippingDialog(false)
								}}
							>
							<svg className="w-4 h-4" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
							</svg>
						</button>
						</div>
						<div className="confirmation-dialog-box-head">
							{<h2 className="font-bold text-xl mb-2 text-black">Memo update</h2>}
						</div>
						<div className="confirmation-dialog-box-content mb-8 text-slate-500">You are attempting to close a memo that has a shipping charge. Would you like to generate an invoice for the shipping amount or forgive the shipping charge?</div>
						<div className="confirmation-dialog-box-footer mt-5 grid grid-cols-2 gap-2">
							<button className="transition-all hover:bg-red-300 bg-red-200 font-semibold md:py-2.5 md:px-4 rounded text-red-700 text-sm uppercase" autoFocus w-full onClick={handleRemoveShippingPrice}>
								Remove Shipping Charge
							</button>
							<button className="transition-all hover:bg-gray-300  bg-gray-200 font-semibold md:py-2.5 md:px-4 rounded text-gray-700 text-sm uppercase w-full" onClick={handleCreateInvoiceForShippingPrice}>
								Create Invoice
							</button>
						</div>
					</div>
				</Dialog>
			}
			<div className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-hidden h-full" style={{maxWidth: '1100px', width: '100%'}}>
				<div className="flex items-center gap-3 bg-white py-6 px-5 md:px-[24px]">
					<div className="flex flex-col md:flex-row items-center justify-between w-full">
						<div className="flex justify-start content-start gap-1">
							<button
								onClick={() => {
									closeSingleMemoDetail()
									setVisibleStatusDropDown(false)
								}}
							>
								<svg className="w-6 h-6" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
								</svg>
							</button>
							{!isEmpty(singleMemoItem) && <h2 className="font-semibold text-2xl uppercase">Memo: #{singleMemoItem.memo_number}</h2>}
						</div>
						{!isEmpty(singleMemoItem) && (
							<div className="flex">
								<div className="relative flex gap-1.5">
									<button
										disabled={isEmpty(checkedItems) || sendingData}
										onClick={e => setVisibleStatusDropDown(!visibleStatusDropDown)}
										className={Classnames('text-white bg-[#2863EB] hover:opacity-90 font-medium rounded text-sm px-4 py-1.5 pr-3 text-center inline-flex items-center capitalize', {
											'opacity-70 hover:opacity-70': isEmpty(checkedItems) || sendingData,
										})}
										type="button"
										ref={actionButtonRef}
									>
										<span>Actions</span>
										<svg className="ml-1 w-4 h-4 inline" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>

									{!(singleMemoItem.status === 'closed') && (
										<ConfirmPopUp title="Memo Edit" content="Are you sure you want to edit this memo?" onConfirm={handleOpenEdit}>
											<button
												className={Classnames(
													'text-white bg-[#2863EB] hover:opacity-90 font-medium rounded text-sm px-4 py-1.5 pr-3 text-center inline-flex items-center capitalize'
												)}
												type="button"
											>
												<span>Edit</span>
											</button>
										</ConfirmPopUp>
									)}


									{visibleStatusDropDown && (
										<div ref={selectRef} className="absolute bg-white divide-gray-100 divide-y rounded shadow top-11 w-[120px] z-10 right-[129px]">
											<ul className="py-1 text-sm text-gray-700">
												<li onClick={() => setActionButton(!actionButton)}>
													<ConfirmPopUp
														title="Memo update"
														content="Are you sure you want to return these items to inventory?."
														onConfirm={handleCheckLastItemWhenReturnMemo}
														onCancel={setAction}
													>
														<button
															ref={returnButtonRef}
														 	className="flex justify-between w-full text-left block py-2 px-4 hover:bg-gray-100">
																<p>Return</p>
																<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
																	<path d="M4.63847 17.4442C4.03564 17.4442 3.52323 17.215 3.10124 16.7566C2.67925 16.2982 2.46826 15.7416 2.46826 15.0868V12.7293H4.63847V1.72803L5.72358 2.90674L6.80869 1.72803L7.89379 2.90674L8.9789 1.72803L10.064 2.90674L11.1491 1.72803L12.2342 2.90674L13.3193 1.72803L14.4044 2.90674L15.4895 1.72803V15.0868C15.4895 15.7416 15.2785 16.2982 14.8566 16.7566C14.4346 17.215 13.9222 17.4442 13.3193 17.4442H4.63847ZM13.3193 15.8726C13.5243 15.8726 13.6961 15.7973 13.8348 15.6466C13.9734 15.496 14.0427 15.3094 14.0427 15.0868V4.08545H6.08528V12.7293H12.5959V15.0868C12.5959 15.3094 12.6652 15.496 12.8039 15.6466C12.9426 15.7973 13.1144 15.8726 13.3193 15.8726ZM6.80869 7.22868V5.65706H11.1491V7.22868H6.80869ZM6.80869 9.5861V8.01449H11.1491V9.5861H6.80869ZM12.5959 7.22868C12.391 7.22868 12.2191 7.15337 12.0805 7.00276C11.9418 6.85215 11.8725 6.66552 11.8725 6.44287C11.8725 6.22023 11.9418 6.0336 12.0805 5.88298C12.2191 5.73237 12.391 5.65706 12.5959 5.65706C12.8009 5.65706 12.9727 5.73237 13.1113 5.88298C13.25 6.0336 13.3193 6.22023 13.3193 6.44287C13.3193 6.66552 13.25 6.85215 13.1113 7.00276C12.9727 7.15337 12.8009 7.22868 12.5959 7.22868ZM12.5959 9.5861C12.391 9.5861 12.2191 9.51079 12.0805 9.36018C11.9418 9.20957 11.8725 9.02294 11.8725 8.80029C11.8725 8.57765 11.9418 8.39102 12.0805 8.24041C12.2191 8.08979 12.391 8.01449 12.5959 8.01449C12.8009 8.01449 12.9727 8.08979 13.1113 8.24041C13.25 8.39102 13.3193 8.57765 13.3193 8.80029C13.3193 9.02294 13.25 9.20957 13.1113 9.36018C12.9727 9.51079 12.8009 9.5861 12.5959 9.5861ZM4.63847 15.8726H11.1491V14.3009H3.91507V15.0868C3.91507 15.3094 3.9844 15.496 4.12305 15.6466C4.2617 15.7973 4.43351 15.8726 4.63847 15.8726Z" fill="#5D6E81"/>
																</svg>
														</button>
													</ConfirmPopUp>
													<ConfirmPopUp title="Memo update" content="Are you sure you want to invoice these items?." onConfirm={handleCreateInvoice} onCancel={setAction}>
														<button
															ref={invoiceButtonRef}
															className="flex justify-between w-full text-left block py-2 px-4 hover:bg-gray-100">
															<p>Invoice</p>
															<svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
																<path d="M6.80869 16.5742L2.46826 11.3047L6.80869 6.03516L7.82145 7.26471L5.2172 10.4264H14.0427V6.91341H15.4895V12.1829H5.2172L7.82145 15.3447L6.80869 16.5742Z" fill="#5D6E81"/>
															</svg>
														</button>
													</ConfirmPopUp>
												</li>
											</ul>
										</div>
									)}
									<Tooltip title="Email Memo pdf to customer" placement="bottom" arrow>
										<button
											className="bg-[#2863EB] hover:opacity-90 px-4 py-1.5 rounded text-white transition-all"
											onClick={() => {sendAndDownloadMemo(true)}}
										>
											{loading === 'email' ? (
												<svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-6 w-6" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24">
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
													/>
												</svg>
											) : (
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
													/>
												</svg>
											)}
										</button>
									</Tooltip>

								</div>
								<Tooltip title="Download Memo" placement="bottom" arrow>
									<button
										className="bg-[#2863EB] hover:opacity-90 px-4 py-1.5 rounded text-white transition-all ml-2"
										onClick={() => {sendAndDownloadMemo(false)}}
									>
										{loading === 'download' ? (
											<svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-6 w-6" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24">
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
												/>
											</svg>
										) : (
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
												/>
											</svg>
										)}
									</button>
								</Tooltip>



								{singleMemoItem.status !== 'published' && <span className="flex items-center ml-2.5 px-[1px] text-[#2863EB] border-[1px] border-[#2863EB] text-xs rounded-[5px] font-semibold">Memo closed</span>}

								{singleMemoItem.status === 'published' && (
									<ConfirmPopUp
										title="Memo update"
										content="Are you sure you want to close this memo? all the products in this memo will go back to inventory."
										onConfirm={handleCheckShippingForCloseMemo}
									>
										<button className="bg-[#2863EB] hover:opacity-90 px-4 py-1.5 rounded text-white transition-all ml-2">Close Memo</button>
									</ConfirmPopUp>
								)}
							</div>
						)}
					</div>
				</div>

				<div className="h-screen divide-y divide-gray-200 pb-72 md:pb-28 overflow-y-scroll">
					{isEmpty(singleMemoItem) && (
						<div className="flex h-full items-center justify-center">
							<button disabled type="button" className="text-white bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 inline-flex items-center">
								<svg role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										fill="#E5E7EB"
									/>{' '}
									<path
										d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										fill="currentColor"
									/>
								</svg>
								<span>Loading memo...</span>
							</button>
						</div>
					)}
					{!isEmpty(singleMemoItem) && (
						<div>
							<div className='pb-5 pt-2 px-[24px]'>
								<table className="relative border-b bg-transparent overflow-scroll w-full">
									<thead className="bg-[#5D6E81] text-white uppercase font-semibold text-[14px] w-full">
										<tr className='w-full'>
											<th className="px-[15px] py-2 text-sm font-semibold  w-8/12	rounded-tl-[5px] rounded-bl-[5px]">Product</th>
											<th className="px-[15px] py-2 text-sm font-semibold  w-1/4">SKU</th>
											<th className="px-[15px] py-2 text-sm font-semibold  w-1/4  rounded-tr-[5px] rounded-br-[5px]">Price</th>
										</tr>
									</thead>
									<tbody className="divide-y">
										{isEmpty(singleMemoItem.memo_items) && isEmpty(singleMemoItem.misc_items) && (
											<tr>
												<td colSpan="6" className="bg-white cursor-pointer hover:bg-gray-100 py-5 text-center text-sm">
													Nothing to show
												</td>
											</tr>
										)}

										{!isEmpty(singleMemoItem.memo_items) &&
											singleMemoItem.memo_items.map((memoItem, index) => {
												return (
													<tr
														key={index}
														className={Classnames('cursor-pointer hover:bg-gray-100 w-full', {
															//'opacity-70': memoItem.disable,
															// 'bg-gray-200': memoItem?.returned,
															// 'bg-white': !memoItem?.returned,
														})}
													>
														<td className='flex items-center px-[15px] py-6 w-full'>
															<div className='flex items-center w-full'>
																{memoItem?.returned && (
																	<Tooltip title="This product was returned to inventory" placement="bottom" arrow>
																		<span className="inline mx-1 text-yellow-500">
																		    <img src="/Images/image_return.png" alt="image_sold" className="w-[22px] h-[22px]" />
																		</span>
																	</Tooltip>
																)}
																{memoItem?.invoiced && (
																	<Tooltip title="This product was sold" placement="bottom" arrow>
																		<span
																			className="inline mx-1 text-green-500"
																			onClick={() => {
																				openInvoice(memoItem.sale_id)
																			}}
																		>
																		    <img src="/Images/image_sold.png" alt="image_sold" className="w-[22px] h-[22px]" />
																		</span>
																	</Tooltip>
																)}
																{!memoItem?.returned && !memoItem?.invoiced && (
																	<div className="__checkbox pr-2 flex items-center">
																		<input
																			id={`checkbox-memo-items-${index}`}
																			type="checkbox"
																			checked={checkedItems.includes(memoItem.id)}
																			onChange={() => handleCheckRow(memoItem)}
																		/>
																		<label htmlFor={`checkbox-memo-items-${index}`}></label>
																	</div>
																)}
																<div className='ml-2'>
																	<p
																		className={Classnames('text-[14px] font-semibold', {
																			'line-through': memoItem.invoiced || memoItem.returned,
																		})}
																	>
																	{!isNull(memoItem?.memoable) && memoItem?.memoable?.name}</p>
                                  <div className='flex gap-1'>
                                    {memoItem?.box && (
                                    <p className={Classnames('text-[14px] font-semibold', {'line-through': memoItem.invoiced || memoItem.returned})}>With Box</p>
                                    )}
                                    {memoItem?.box && !(isEmpty(memoItem?.note)) && "/"}
                                    {!isEmpty(memoItem?.note) && (
                                      <p className={Classnames('text-[14px] font-semibold', {'line-through': memoItem.invoiced || memoItem.returned})}>{memoItem?.note}</p>
                                    )}
                                  </div>
																</div>
															</div>
														</td>
														<td
															className={Classnames('px-[15px] py-2 text-sm font-semibold  w-1/4',
																{
																		'line-through': memoItem.invoiced || memoItem.returned,
																}
															)}
														>
															{memoItem.memoable?.sku}
														</td>
														<td
															className={Classnames('px-[15px] py-2 text-sm font-semibold  w-1/4',
																{
																		'line-through': memoItem.invoiced || memoItem.returned,
																}
															)}>
															{!isNull(memoItem.price) && memoItem.price >= 0 ? `${Globals.readCurrencyNumber(memoItem.price)}` : ''}
														</td>
													</tr>
												)
											})}

											{!isEmpty(singleMemoItem.misc_items) &&
												singleMemoItem.misc_items.map((memoItem, index) => {
												return (
													<tr
														key={index}
														className={Classnames('cursor-pointer hover:bg-gray-100 w-full', {
															//'opacity-70': memoItem.disable,
															// 'bg-gray-200': memoItem?.returned,
															// 'bg-white': !memoItem?.returned,
														})}
													>
														<td className='flex items-center px-[15px] py-6 w-full'>
															<div className='flex items-center w-full'>
																{memoItem?.returned && (
																	<Tooltip title="This product was returned to inventory" placement="bottom" arrow>
																		<span className="inline mx-1 text-yellow-500">
																		    <img src="/Images/image_return.png" alt="image_sold" className="w-[22px] h-[22px]" />
																		</span>
																	</Tooltip>
																)}
																{memoItem?.invoiced && (
																	<Tooltip title="This product was sold" placement="bottom" arrow>
																		<span
																			className="inline mx-1 text-green-500"
																			onClick={() => {
																				openInvoice(memoItem.sale_id)
																			}}
																		>
																		<img src="/Images/image_sold.png" alt="image_sold" className="w-[22px] h-[22px]" />
																		</span>
																	</Tooltip>
																)}
																{!memoItem?.returned && !memoItem?.invoiced && (
																	<div className="__checkbox pr-2 flex">
																		<input
																			id={`checkbox-memo-items-${memoItem.id}`}
																			type="checkbox"
																			checked={checkedItems.includes(memoItem.id)}
																			onChange={() => handleCheckRow(memoItem)}
																		/>
																		<label htmlFor={`checkbox-memo-items-${memoItem.id}`}></label>
																	</div>
																)}
																<div className='ml-2'>
																	<p className='text-[14px] font-semibold'>{!isNull(memoItem?.product_name) && memoItem.product_name}</p>
																</div>
															</div>
														</td>
														<td className="px-[15px] py-2 text-sm font-semibold  w-1/4">{memoItem.sku}</td>
														<td className="px-[15px] py-2 text-sm font-semibold  w-1/4">
															{Globals.readCurrencyNumber(memoItem.price)}
														</td>
													</tr>
												)
											})}
									</tbody>
								</table>
								{(!isEmpty(singleMemoItem.memo_items) || !isEmpty(singleMemoItem.misc_items)) && (
										<div className="flex justify-between items-start w-full pt-2">
											<div className="w-1/4 text-[13px] font-normal px-[35px] tracking-worderer flex">
												<p className='mr-2'>Total Products: {singleMemoItem.memo_items.length + singleMemoItem.misc_items.length}</p>
												<p onClick={(event) => handleCheckAllRows(event, !selectAllCheckBox)} className='cursor-pointer	font-normal underline italic'>{!selectAllCheckBox ? 'Select All' : 'Clear All'}</p>
											</div>
											<div className="w-[400px] pr-[30px] text-sm font-semibold tracking-worderer">
												<br />
												<div className="flex justify-between items-center">
													<div className="text-black text-base font-normal">Subtotal</div>
													<div className="text-black text-base font-normal">
														{singleMemoItem.status === 'closed' ?
															'$0'
															:
															`$${Globals.formatCurrency(singleMemoItem.sub_total)}`
														}
													</div>
												</div>
												<div className="flex justify-between items-center pb-[4px]">
													<div className="text-black text-base font-normal">Shipping</div>
													<div className="text-black text-base font-normal">
														{singleMemoItem.customer_label ? 'Customer Label' : `$${Globals.readCurrencyNumber(singleMemoItem.shipping_price)}` }
													</div>
												</div>
												<hr className="m-0 border-black w-4/12 float-right"/>
												<div className="flex justify-between items-center w-full pt-[4px] text-[#4B7CBE] text-bold">
													<div className="text-base font-bold">Total</div>
													<div className="text-base font-bold">
														{singleMemoItem.status === 'closed' ? '$0' : `$${Globals.readCurrencyNumber(singleMemoItem.total)}`}
													</div>
												</div>
											</div>
										</div>
									)}
							</div>
							<div className="mt-[48px] bg-[#F4F4F4]">
								<div className="px-10 py-5">
									<div className="text-[#5D6E81] text-[22px] font-bold">Customer Details</div>
									<div className="w-full flex justify-between mt-[15px]">
										<div className="flex flex-col justify-between">
											<div className="flex items-center gap-x-[13px]">
												<img src="./Images/company.png" alt="" className="h-[24px] w-[24px]" />
												<span>{customer?.company_name || customer?.name}</span>
											</div>
											<div className="flex items-center gap-x-[13px]">
												<img src="./Images/phone.png" alt="" className="h-[24px] w-[24px]" />
												<div className="flex flex-col">
													{
														customer?.customer_phone_numbers && customer?.customer_phone_numbers[0] && (
															<div>
																<span>{customer.customer_phone_numbers[0].phone_number_formatted}</span>
															</div>
														)
													}
												</div>
											</div>
											<div className="flex items-center gap-x-[13px]">
												<img src="./Images/mail.png" alt="" className="h-[24px] w-[24px]" />
												<span>{customer?.email}</span>
											</div>
										</div>
										<div className="flex flex-col">
											<div className="flex items-center gap-x-[13px] mb-[11px]">
												<img src="./Images/people.png" alt="" className="h-[24px] w-[24px]" />
												<span className="text-[#5D6E81] text-[14px] font-semibold">Billing</span>
											</div>
											<div className="ms-[43px] max-w-[235px]">
												<p>{selectedBillingAddress?.company_name}</p>
												<p>{selectedBillingAddress?.contact_name}</p>
												<p>{selectedBillingAddress?.full_address}</p>
												<p>{selectedBillingAddress?.phone_number_formatted}</p>
											</div>
										</div>
										<div className="flex flex-col">
											<div className="flex items-center gap-x-[13px] mb-[11px]">
												<img src="./Images/location_detail.png" alt="" className="h-[24px] w-[24px]" />
												<span className="text-[#5D6E81] text-[14px] font-semibold">Shipping</span>
											</div>
											<div className="ms-[43px] max-w-[235px]">
												<p>{selectedShippingAddress?.company_name}</p>
												<p>{selectedShippingAddress?.contact_name}</p>
												<p>{selectedShippingAddress?.full_address}</p>
												<p>{selectedShippingAddress?.phone_number_formatted}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="px-10 w-full flex mt-[21px] min-h-[153px] relative">
								<div className="w-1/2">
									<div className="text-[22px] text-[#5D6E81] font-bold">Memo Details</div>
									<div className="flex flex-col pl-[22px] pr-[37px] pt-[20px] gap-y-[13px]">
										<div className="flex justify-between">
											<div>Memo Date:</div>
											<div>{Moment(singleMemoItem.created_at).local().format('MM/DD/YYYY')}</div>
										</div>
										{
											currentSpace.space_general_setting?.visible_salesperson_on_memo &&
											<div className="flex justify-between">
												<div>Salesperson: </div>
												<div>{singleMemoItem?.salesperson?.full_name}</div>
											</div>
										}
										<div className="flex justify-between">
											<div>Tracking Number</div>
											{ currentSpace.internal_subscription.status === 'active' && currentSpace.internal_subscription.plan_type === 'performance' ? (
												<div>{trackingNumber}</div>
											) : (
												<input
													name="tracking_number"
													value={trackingNumber}
													onChange={handleChangeTrackingNumber}
													onKeyDown={handleKeyDownTrackingNumber}
													ref={trackingNumberInputRef}
													className='bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] max-w-[184px] text-end px-1'
												/>
											)}
										</div>
									</div>
								</div>
								<hr className="w-[1px] h-[153px] bg-[#E5E7EB] mt-10 mx-[20px]"/>
								<div className="w-1/2 pl-[22px]">
									<div className="text-[22px] text-[#5D6E81] font-bold flex items-center gap-x-[37px]">
										<span>Comments</span>
										<img
											src="./Images/plus_radius.png"
											alt=""
											className="w-[22px] h-[22px]"
											onClick={() => handleToogleInputComment('new')}
										/>
									</div>
									<div className="flex flex-col">
										{
											memoComments.map((comment, index) => {
												return (
                          <>
                            <div className="py-3 text-sm flex items-center justify-between gap-x-2 px-3" key={index}>
                              <div className="">
                                <div className="text-[#5D6E81] font-medium">{comment.content}</div>
                                <div className="text-xs">{Moment(comment.created_at).format('M/DD/YYYY')} - {comment.user.full_name}</div>
                              </div>
                              <div className="flex gap-x-3 ">
                                <button
                                  className="underline font-normal text-[12px] cursor-pointer"
                                  onClick={() => handleToogleInputComment('edit', comment)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="underline font-normal text-[12px] cursor-pointer"
                                  onClick={() => handleDeleteComment(comment.id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                            {isVisibleComments && selectedComment?.id === comment.id && (
                              <CommentForm
                                commentField={commentField}
                                setCommentField={setCommentField}
                                sendingComment={sendingComment}
                                selectedComment={selectedComment}
                                handleUpdateComment={handleUpdateComment}
                                handleSubmitComment={handleSubmitComment}
                              />
                            )}
                          </>
												)
											})
										}

										{isVisibleComments && isEmpty(selectedComment) && (
											<CommentForm
                        commentField={commentField}
                        setCommentField={setCommentField}
                        sendingComment={sendingComment}
                        selectedComment={selectedComment}
                        handleUpdateComment={handleUpdateComment}
                        handleSubmitComment={handleSubmitComment}
                      />
										)}
									</div>
								</div>
							</div>
							{/* <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
								<div>
									<div className="flex justify-between items-center border-b">
										<h3 className="font-medium text-md my-4">Customer</h3>
									</div>
									<div className="pt-3">
										<div className="grid grid-cols-2 md:grid-cols-3 gap-3 text-md">
											<div className="font-semibold">Email:</div>
											<div className="col-span-4">{singleMemoItem.customer.email}</div>
										</div>
									</div>
									<div className="pt-3">
										<div className="grid grid-cols-2 md:grid-cols-3 gap-3 text-md">
											<div className="font-semibold">Name:</div>
											<div className="col-span-4">{`${singleMemoItem.customer.name}`}</div>
										</div>
									</div>
									<div className="pt-3">
										<div className="grid grid-cols-2 md:grid-cols-3 gap-3 text-md">
											<div className="font-semibold">Phone:</div>
											<div className="col-span-4">{singleMemoItem.customer.phone_number}</div>
										</div>
									</div>
									<div className="pt-3">
										<div className="grid grid-cols-2 md:grid-cols-3 gap-3 text-md">
											<div className="font-semibold">Website:</div>
											<div className="col-span-4">{singleMemoItem.customer.website}</div>
										</div>
									</div>
									<div className="pt-3">
										<div className="grid grid-cols-2 md:grid-cols-3 gap-3 text-md">
											<div className="font-semibold">Certificate:</div>
											<div className="col-span-4">{singleMemoItem.customer.certificate}</div>
										</div>
									</div>
								</div>
								<div>
									<div className="flex justify-between items-center border-b">
										<h3 className="font-medium text-md my-4">Company details</h3>
									</div>
									<div className="pt-3">
										<div className="grid grid-cols-2 md:grid-cols-3 gap-3 text-md">
											<div className="font-semibold">Company name:</div>
											<div className="col-span-4">{singleMemoItem.customer.company_name}</div>
										</div>
									</div>
									<div className="pt-3">
										<div className="grid grid-cols-2 md:grid-cols-3 gap-3 text-md">
											<div className="font-semibold">Address:</div>
											<div className="col-span-4">
												<div>{singleMemoItem.customer.company_address}</div>
												<div>{`${singleMemoItem.customer.state} ${singleMemoItem.customer.city} ${singleMemoItem.customer.zip_code}, ${
													head(Globals.countries.filter(c => c.code === singleMemoItem.customer.country))?.name
												}`}</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}

							{/* <div>
								<div className="mt-6">
									<div className="flex justify-between items-center border-b">
										<h3 className="font-medium text-md my-4">Memo Comment</h3>
									</div>
									<div
										className="divide-y"
										style={{
											maxWidth: 600,
										}}
									>
										{isEmpty(memoComments) && <p className="text-sm pt-3">No memo comments yet</p>}
										{!isEmpty(memoComments) &&
											memoComments.map((comment, index) => {
												return (
													<div className="py-3 text-sm" key={index}>
														<div>{comment.content}</div>
														<div className="text-xs">{Moment(comment.created_at).format('Do of MMMM YYYY @HH:mm')}</div>
													</div>
												)
											})}
									</div>
								</div>
								<div className="mt-6">
									<div className="flex justify-between items-center border-b pb-1">
										<h3 className="font-medium text-md my-4">Write a Comment</h3>
									</div>
									<div className="mt-1">
										<form className="text-right">
											<textarea
												value={commentField}
												onChange={e => setCommentField(e.target.value)}
												placeholder="write a comment"
												className="border mb-2 mt-3 p-3 resize-none rounded text-left w-full"
												spellCheck="false"
											/>
											<button
												className="bg-blue-600 capitalize font-medium hover:opacity-90 inline-flex items-center px-4 py-1.5 rounded text-center text-sm text-white"
												disabled={sendingComment}
												onClick={e => handleSubmitComment(e)}
											>
												Comment
											</button>
										</form>
									</div>
								</div>
							</div> */}
						</div>
					)}
					{showOrderDetail && <SingleItemDetail
						handleClickOut={handleClickOut}
						orderId={clickedOrderId}
						props={props}
						updateItemInInventories={updateItemInInventories}
						closeSingleOrderItem={closeSingleOrderItem}
						fromInventory={true}
						handlePrimaryTab={handlePrimaryTab}
						currentSpace={currentSpace}
					/>}
				</div>
			</div>
		</div>
	)
}

export default SingleMemoDetail
