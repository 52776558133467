import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {connect} from 'react-redux'
import Classnames from 'classnames'
import {useNavigate} from 'react-router-dom'
import {Globals} from '../../../../Context'
import UploadMedia from '../../../UploadMedia'
import {addToast, setCurrentSpace} from '../../../../app/appActions'
import useInvoiceSettings from './hooks/useInvoiceSettings'
import TemplateSwitcher from './TemplateSwitcher'
import isEmpty from 'lodash/isEmpty'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Switch from '@mui/material/Switch'
import ServerAutoSuggestAddress from '../../../..//Components/ServerAutoSuggestAddress'
import {formatPhoneNumber} from '../../../../utils/PhoneNumberFormatter'
import {deepCompare} from '../../../../utils/deepCompare'

const maxRows = 8

const Invoice = ({currentSpace, profile, setCurrentSpace, addToast}) => {
	const initAddress = {
		street: '',
		apt_unit_number: '',
		city: '',
		state: '',
		country: '',
		country_code: '',
		zip_code: '',
		full_address: '',
		isManual: false
	}
	const [addresses, setAddresses] = React.useState([initAddress])

	const {
		isShowLimitReached,
		setIsShowLimitReached,
		formErrors,
		updateFormErrors,
		invoiceSettingsTemplates,
		setInvoiceSettingsTemplates,
		selectedTemplate,
		setSelectedTemplate,
		isSpaceDataReady,
		isProcessingLogo,
		setIsProcessingLogo,
		loadBackendInvoiceSettings,
		refreshCurrentSpace,
	} = useInvoiceSettings({currentSpace, profile, setCurrentSpace, addToast})
	const [focusedPosition, setFocusedPosition] = React.useState(0)
	const [isManual, setIsManual] = React.useState(false)

	const textAreaRef = useRef()

	useEffect(() => {
		loadBackendInvoiceSettings()
	}, [])

	useEffect(() => {
		if (!Globals.adminOrOwner(profile)) {
			navigate('/inventory')
		}
	}, [])

	const navigate = useNavigate()

	const displayError = key => {
		if (!isEmpty(formErrors[key])) return <div className="inline-block text-sm my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{formErrors[key]}</div>
	}

	useEffect(() => {
		if (!isEmpty(formErrors) && invoiceSettingsTemplates){
			updateFormErrors({})
		}
	}, [invoiceSettingsTemplates])

	const handleSubmit = useCallback(
		(showToast = true) => {
			let errors = {}
			const formData = invoiceSettingsTemplates[selectedTemplate]
			updateFormErrors(errors)
			if (!formData.company_name) {
				errors = {
					...errors,
					companyName: 'Company name is required',
				}
			}

			if (!formData.street) {
				errors = {
					...errors,
					street: 'Street is required',
				}
			}
			if (!formData.city) {
				errors = {
					...errors,
					city: 'City is required',
				}
			}
			if (!formData.state) {
				errors = {
					...errors,
					state: 'State is required',
				}
			}
			if (!formData.zip_code) {
				errors = {
					...errors,
					zip_code: 'Zip code is required',
				}
			}
			if (!formData.country) {
				errors = {
					...errors,
					country: 'Country is required',
				}
			}

			if ((formData.payment_term_method === 'days' || formData.payment_term_method === 'months') && (!formData.payment_term_days || Number(formData.payment_term_days) < 1)) {
				addToast({
					title: 'Invoice settings',
					text: `Payment term ${formData.payment_term_method} count should be greater than 0`,
					type: 'warning',
				})
				return
			}
			if (isEmpty(errors)) {
				const payload = {
					space_id: currentSpace.id,
					invoice_setting: formData,
				}

				return Globals.New_Axios()
					.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/invoice_settings/${formData.id}`, payload)
					.then(async response => {
						const updatedSetting = response.data

						if (updatedSetting) {
							const templateType = updatedSetting.template_type || selectedTemplate
							refreshCurrentSpace()
							setInvoiceSettingsTemplates(prev => {
								const newState = {
									...prev,
									[templateType]: {
										...prev[templateType],
										...updatedSetting,
									},
								}

								return newState
							})
						}

						if (showToast) {
							addToast({
								title: 'Invoice settings',
								text: 'Your invoice settings have been updated successfully',
								type: 'success',
							})
						}

						return updateFormErrors({})
					})
					.catch(error => {
						errors = {
							...errors,
							error: error.response?.data?.message || 'Something went wrong please try again later.',
						}

						const updatedErrors = error.response?.data?.message?.replace('Phone number is invalid', 'Please check phone number in company details')

						addToast({
							text: updatedErrors || 'Something went wrong please try again later.',
							type: 'error',
						})

						updateFormErrors(errors)
					})
			}else {
				// For each error, I need to display a toast
				Object.keys(errors).forEach((key)=> {
					addToast({
						text: errors[key] || 'Something went wrong please try again later.',
						type: 'error',
					})
				})

				updateFormErrors(errors)
			}
		},
		[invoiceSettingsTemplates, selectedTemplate]
	)

	const handleChange = (field, value, showToast = true) => {
		if (!Globals.adminOrOwner(profile)) {
			return
		}

		setInvoiceSettingsTemplates(prev => ({
			...prev,
			[selectedTemplate]: {
				...prev[selectedTemplate],
				[field]: value,
			},
		}))
	}

	const uploadImages = pictures => {
		if (!isSpaceDataReady || isProcessingLogo) {
			return
		}

		setIsProcessingLogo(true)
		if (pictures.length === 0) {
			// sending out of handleChange to avoid stale closure of template data
			setInvoiceSettingsTemplates(prev => ({
				...prev,
				[selectedTemplate]: {
					...prev[selectedTemplate],
					logo_id: null,
					logo: null,
				},
			}))
		}

		// get the last uploaded picture
		const lastPicture = pictures[pictures.length - 1]?.post_id

		if (!lastPicture) {
			setIsProcessingLogo(false)
			return
		}

		// sending out of handleChange to avoid stale closure of template data
		setInvoiceSettingsTemplates(prev => ({
			...prev,
			[selectedTemplate]: {
				...prev[selectedTemplate],
				logo_id: lastPicture.id,
				logo: {
					id: lastPicture.id,
					url: lastPicture.url,
				},
			},
		}))

		setTimeout(() => setIsProcessingLogo(false), 2000)
	}

	const onPressEnterTermsAndConditions = e => {
		const terms_and_conditions_content = invoiceSettingsTemplates[selectedTemplate]?.terms_and_conditions_content || ''
		const lines = terms_and_conditions_content.split('\n')
		setFocusedPosition(e.target.selectionStart)
		if (lines.length >= maxRows && e.key === 'Enter') {
			setIsShowLimitReached(true)
			e.preventDefault()
			return
		}
	}

	const handleFocus = e => {
		setFocusedPosition(e.target.selectionStart)
	}
	const handleChangeTermsAndConditions = e => {
		const value = e.target.value
		const processedText = processTermsAndConditions(value)
		const currentTextValue = invoiceSettingsTemplates[selectedTemplate]?.terms_and_conditions_content || ''
		const isAdd = currentTextValue.length < e.target.value.length
		const incrementer = isAdd ? 1 : -1
		setTimeout(()=>{
			textAreaRef.current.setSelectionRange(focusedPosition +incrementer, focusedPosition+incrementer);
		}, 100)
		setFocusedPosition(focusedPosition+incrementer)
		if (!Globals.adminOrOwner(profile)) {
			e.preventDefault()
			return
		}

		handleChange('terms_and_conditions_content', processedText, false)
	}

	const processTermsAndConditions = value => {
		const maxCharactersPerLine = 140

		let lines = value.split('\n')

		let resultLines = []
		let isMaxCharacters = false
		for (let line of lines) {
			while (line.length > maxCharactersPerLine) {
				const substring = line.substring(0, maxCharactersPerLine)
				resultLines.push(substring)
				line = line.substring(maxCharactersPerLine)
				if (resultLines.length >= maxRows) {
					isMaxCharacters = true
				}
			}

			resultLines.push(line)

			if (resultLines.length >= maxRows) {
				resultLines = resultLines.slice(0, maxRows)
				break
			}
		}

		if (resultLines.join('\n') === value) {
			setIsShowLimitReached(false)
		} else {
			if (isMaxCharacters) setIsShowLimitReached(true)
		}

		return resultLines.join('\n')
	}

	const handleChangeGoogleAddress = place => {
		let form = {
			zip_code: '',
			city: '',
			state: '',
			country: '',
			country_code: '',
			street: '',
			full_address: '',
			apt_unit_number: ''
		}

		let city
		let apt_unit_number
		let address_component_types = []
		let streetNumber
		let streetName

		place.address_components.map(value => {
			address_component_types.push(value.types)

			if (value.types.includes('postal_code')) {
				form = {
					...form,
					zip_code: value.long_name
				}
			}
			if (value.types.includes('subpremise')) {
				const match = value.long_name.match(/\d+$/)
				apt_unit_number = match ? match[0] : value.long_name
				form = {
					...form,
					apt_unit_number: apt_unit_number
				}
			}
			if (value.types.includes('locality')) {
								city = value.long_name
				form = {
					...form,
					city: city
				}
			}
			if (value.types.includes('administrative_area_level_1')) {
				if (!city) { 
						form = {
								...form,
								state: value.short_name,
								city: value.long_name
						}
				} else {
						form = {
								...form,
								state: value.short_name
						}
				}
			}
			if (value.types.includes('country')) {
				form = {
					...form,
					country: value.long_name,
					country_code: value.short_name
				}
			}

			if (value.types.includes('street_number')){
				streetNumber = value.long_name
			}

			if (value.types.includes('route')){
				streetName = value.long_name
			}
		})

		if (!address_component_types.flat().includes('subpremise')) {
			apt_unit_number = ''
		}

		form = {
			...form,
			street: (streetNumber ? streetNumber + ' ' : '' ) + streetName
		}

		setInvoiceSettingsTemplates(prev => ({
			...prev,
			[selectedTemplate]: {
				...prev[selectedTemplate],
				full_address: typeof place === 'string' ? place : place.formatted_address.replace(` ${apt_unit_number}`, ''),
				street: form.street,
				apt_unit_number: form.apt_unit_number,
				city: form.city,
				state: form.state,
				country: form.country,
				zip_code: form.zip_code
			},
		}))
	}

	const isUpdated = useMemo(() => {
		const template = invoiceSettingsTemplates[selectedTemplate]
		const spaceTemplate = currentSpace?.invoice_settings?.find(invoice_setting => invoice_setting.template_type === selectedTemplate)

		if (!template || !spaceTemplate) return false
		const compareResult = deepCompare(template, spaceTemplate)
		return compareResult.different.length > 0 || compareResult.missing_from_first.length > 0
	}, [invoiceSettingsTemplates, selectedTemplate, currentSpace])

	return (
		<div className="relative pb-24 w-full h-full">
			<div className="h-screen ">
				<form className="flex flex-col">
					<div className="flex flex-col items-center">
						<TemplateSwitcher
							templateOptions={invoiceSettingsTemplates}
							selectedTemplate={selectedTemplate}
							setInvoiceSettingsTemplates={setInvoiceSettingsTemplates}
							onTemplateChange={setSelectedTemplate}
						/>
						<div className="w-full flex items-center flex-row gap-3 mt-2">
							<label className=" text-[18px] text-[#5D6E81] font-bold py-2">Enabled</label>
							<Switch
								disabled={!Globals.adminOrOwner(profile)}
								name={'enabled'}
								checked={invoiceSettingsTemplates[selectedTemplate]?.enabled ?? false}
								onChange={e => handleChange('enabled', e.target.checked)}
								color="primary"
							/>
							<label className=" text-[18px] text-[#5D6E81] font-bold py-2">Name</label>
							<input
								value={invoiceSettingsTemplates[selectedTemplate]?.name}
								type="text"
								name={'name'}
								placeholder="Template Name"
								className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-60"
								onChange={e => handleChange('name', e.target.value)}
								disabled={!Globals.adminOrOwner(profile)}
							/>
						</div>
						{displayError('enabled')}
					</div>
					<div className="mt-8">
						<label className="text-[18px] text-[#5D6E81] font-bold py-4">Company Details</label>
						<div className="flex flex-row w-full">
							<div className="flex flex-col w-full md:w-6/10 pl-2">
								<div className="w-full flex items-center flex-row mt-2">
									<Typography sx={{fontWeight: 400, fontSize: 14, color: '#5D6E81', width: '120px'}}>Company Name*</Typography>
									<input
										value={invoiceSettingsTemplates[selectedTemplate]?.company_name}
										type="text"
										name={'company_name'}
										placeholder="Company Name"
										className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-64"
										onChange={e => handleChange('company_name', e.target.value)}
										disabled={!Globals.adminOrOwner(profile)}
									/>
								</div>
								{displayError('companyName')}
								<div className="w-full flex flex-row mt-2">
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: 14,
											color: '#5D6E81',
											width: '120px',
										}}
									>
										Address*
									</Typography>
									{!isManual ? (
										<>
											<div className="flex flex-col">
												<ServerAutoSuggestAddress
  												key={`${selectedTemplate}-${isManual ? 'manual' : 'auto'}`}
													value={invoiceSettingsTemplates[selectedTemplate]?.full_address}
													setValue={(newValue) => {
														setInvoiceSettingsTemplates(prev => ({
															...prev,
															[selectedTemplate]: {
																...prev[selectedTemplate],
																full_address: newValue
															}
														}))
													}}
													hideLabel={true}
													formData={{full_address: invoiceSettingsTemplates[selectedTemplate]?.full_address || ''}}
													handleChangeGoogleAddress={handleChangeGoogleAddress}
													className={
														'block pl-[4px] pr-[16px] pt-3 pb-3 w-64 h-[30px] inputLogin appearance-none outline-none text-[#000] !font-regular text-[14px] border-[#D9E2EE] nowrap truncate overflow-hidden hover:text-clip'
													}
													view={'memo'}
												/>
												<input 
													key={selectedTemplate}
													type="text"
													name="apt_unit_number"
													placeholder="Apt Unit Number"
													value={invoiceSettingsTemplates[selectedTemplate]?.apt_unit_number || ''}
													onChange={e => handleChange('apt_unit_number', e.target.value)}
													disabled={!Globals.adminOrOwner(profile)}
													className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-32 mt-2"
												/>
												<span
													onClick={() => setIsManual(!isManual)}
													className="cursor-pointer underline text-sm text-[#5D6E81] mt-2"
												>
													{isManual
														? 'Switch to Auto-Suggest'
														: 'Add Address Manually'
													}
												</span>
											</div>
										</>
									) : (
										<>
											<div className="flex flex-col">
												<div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px'}}>
													{/* Full Address Input */}
													<input
														type="text"
														name="street"
														placeholder="Street"
														value={invoiceSettingsTemplates[selectedTemplate]?.street || ''}
														onChange={e => handleChange('street', e.target.value)}
														disabled={!Globals.adminOrOwner(profile)}
														className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-48"
													/>
													{/* Apt/Unit Number */}
													<input
														type="text"
														name="apt_unit_number"
														placeholder="Apt/Unit #"
														value={invoiceSettingsTemplates[selectedTemplate]?.apt_unit_number || ''}
														onChange={e => handleChange('apt_unit_number', e.target.value)}
														disabled={!Globals.adminOrOwner(profile)}
														className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-48"
													/>
													{/* City */}
													<input
														type="text"
														name="city"
														placeholder="City"
														value={invoiceSettingsTemplates[selectedTemplate]?.city || ''}
														onChange={e => handleChange('city', e.target.value)}
														disabled={!Globals.adminOrOwner(profile)}
														className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-48"
													/>
													{/* State */}
													<input
														type="text"
														name="state"
														placeholder="State"
														value={invoiceSettingsTemplates[selectedTemplate]?.state || ''}
														onChange={e => handleChange('state', e.target.value)}
														disabled={!Globals.adminOrOwner(profile)}
														className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-48"
													/>
													{/* Zip Code */}
													<input
														type="text"
														name="zip_code"
														placeholder="Zip Code"
														value={invoiceSettingsTemplates[selectedTemplate]?.zip_code || ''}
														onChange={e => handleChange('zip_code', e.target.value)}
														disabled={!Globals.adminOrOwner(profile)}
														className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-48"
													/>
													{/* Country Dropdown */}
													<select
														id="country"
														name="country"
														value={invoiceSettingsTemplates[selectedTemplate]?.country || ''}
														onChange={e => handleChange('country', e.target.value)}
														disabled={!Globals.adminOrOwner(profile)}
														className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-48"
													>
														<option value="" disabled>
																Choose a country
														</option>
														{Globals.countries.map((c) => (
															<option key={c.code} value={c.code}>
																{c.name}
															</option>
														))}
													</select>
												</div>
												<span
													onClick={() => setIsManual(!isManual)}
													className="cursor-pointer underline text-sm text-[#5D6E81] mt-2"
												>
													{isManual
														? 'Switch to Auto-Suggest'
														: 'Add Address Manually'
													}
												</span>
											</div>
										</>
									)}
								</div>
								{displayError('address')}
								<div className="w-full flex items-center flex-row mt-2">
									<Typography
										sx={{
											fontWeight: 400,
											fontSize: 14,
											color: '#5D6E81',
											width: '120px',
										}}
									>
										E-mail
									</Typography>
									<input
										value={invoiceSettingsTemplates[selectedTemplate]?.email}
										type="text"
										name={'email'}
										placeholder="Email"
										className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-64"
										onChange={e => handleChange('email', e.target.value)}
										disabled={!Globals.adminOrOwner(profile)}
									/>
								</div>
								{displayError('email')}
								<div className="w-full flex items-center flex-row mt-2">
									<Typography sx={{fontWeight: 400, fontSize: 14, color: '#5D6E81', width: '120px'}}>Phone Number</Typography>
									<input
										value={formatPhoneNumber(invoiceSettingsTemplates[selectedTemplate]?.phone_number)}
										type="text"
										name={'phone_number'}
										placeholder="Phone Number"
										className="block p-1 inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium w-64"
										onChange={e => handleChange('phone_number', e.target.value)}
										disabled={!Globals.adminOrOwner(profile)}
									/>
								</div>
								{displayError('phone_number')}
							</div>
							<div
								style={{
									marginTop: '-40px',
								}}
								className="flex flex-col w-full md:w-4/10 md:pl-4"
							>
								<div className="outlin relative md:max-w-[340px]">
									<UploadMedia
										key={invoiceSettingsTemplates[selectedTemplate]?.logo_id + invoiceSettingsTemplates[selectedTemplate]?.logo?.id}
										innerClassNames="gap-2 grid grid-cols-2 mb-5 mt-2"
										text="Select file or drag and drop"
										maximum={1}
										disabled={!Globals.adminOrOwner(profile)}
										uploadUpdate={uploadImages}
										hidePlaceholder={!!invoiceSettingsTemplates[selectedTemplate]?.logo?.url}
										uploadTo="uploadlogo"
										allowedTypes={['image/jpeg', 'image/jpg', 'image/png']}
										defaultValue={invoiceSettingsTemplates[selectedTemplate]?.logo?.id ? [invoiceSettingsTemplates[selectedTemplate]?.logo] : []}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="outlin relative w-full mt-[2.75rem]">
						<label htmlFor="Resale Certificate*" className=" text-[18px] text-[#5D6E81] font-bold py-2">
							Order Details
						</label>
						<div className="flex items-center p-2">
							<Typography sx={{fontWeight: 400, fontSize: 14, color: '#5D6E81'}}>Do you want your terms and condition visible on the invoice?</Typography>
							<RadioGroup
								value={invoiceSettingsTemplates[selectedTemplate]?.visible_terms_and_conditions ?? false}
								onChange={e => handleChange('visible_terms_and_conditions', e.target.value === 'true')}
								name="visible_terms_and_conditions"
								row
								sx={{ml: 6}}
							>
								<FormControlLabel
									value={true}
									control={
										<Radio
											sx={{
												'& .MuiSvgIcon-root': {
													fontSize: 14,
												},
											}}
										/>
									}
									label="Yes"
								/>
								<FormControlLabel
									value={false}
									control={
										<Radio
											sx={{
												'& .MuiSvgIcon-root': {
													fontSize: 14,
												},
											}}
										/>
									}
									label="No"
								/>
							</RadioGroup>
						</div>
					</div>

					<div className="p2">
						<div className="p-5 w-[100%] max-w-[1000px]">
							<textarea
								onClick={handleFocus}
								ref={textAreaRef}
								name="terms_and_conditions_content"
								className={Classnames('w-full border-[1px] p-[5px] rounded-lg text-[#5D6E81] text-[12px]', {
									'text-opacity-75': !invoiceSettingsTemplates[selectedTemplate]?.visible_terms_and_conditions,
								})}
								rows={8}
								disabled={!invoiceSettingsTemplates[selectedTemplate]?.visible_terms_and_conditions ?? true}
								onKeyDown={onPressEnterTermsAndConditions}
								onChange={handleChangeTermsAndConditions}
								value={invoiceSettingsTemplates[selectedTemplate]?.terms_and_conditions_content || ''}
								placeholder={`We are an independent watch company, not an authorized dealer of any brand we sell. All watches sold by ${currentSpace.name} are second hand watches. The merchandise on this invoice will remain ${currentSpace.name} property until this invoice is paid in full.\nAny fees incurred to collect payment or property will be paid by the purchaser listed on this invoice. All items are sold 'as-is' and all sales are final, no exchanges or returns. By accepting this merchandise, you agree to these terms.\nWe have the right to take our property back if the terms are not followed.`}
							/>
							<div className="text-[13px] text-right">{isShowLimitReached && <span className="text-red-500">Limit reached</span>}</div>
						</div>
					</div>

					<div className="outlin relative w-full mt-4">
						<label htmlFor="Resale Certificate*" className=" text-[18px] text-[#5D6E81] font-bold py-2">
							Payment Details
						</label>
						<div className="flex items-center p-2 mb-8">
							<div className="grid gap-2 sm:grid-cols-1 lg:grid-cols-2 w-full">
								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										Bank Name
									</label>
									<div className="outlin relative w-full">
										<input
											value={invoiceSettingsTemplates[selectedTemplate]?.bank_name}
											type="text"
											name="bank_name"
											style={{fontSize: 12}}
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black font-medium"
											onChange={e => handleChange('bank_name', e.target.value)}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Bank Name
										</label>
									</div>
									{displayError('bank_name')}
								</div>

								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										Bank Address
									</label>
									<div className="outlin relative w-full">
										<input
											value={invoiceSettingsTemplates[selectedTemplate]?.bank_address}
											type="text"
											style={{fontSize: 12}}
											name="bank_address"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={e => handleChange('bank_address', e.target.value)}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Bank Address
										</label>
									</div>
									{displayError('bank_address')}
								</div>

								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										City, State Zip
									</label>
									<div className="outlin relative w-full">
										<input
											value={invoiceSettingsTemplates[selectedTemplate]?.bank_city_state_zip}
											type="text"
											style={{fontSize: 12}}
											name="bank_city_state_zip"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={e => handleChange('bank_city_state_zip', e.target.value)}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											City, State Zip
										</label>
									</div>
									{displayError('bank_city_state_zip')}
								</div>

								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										Account Name
									</label>
									<div className="outlin relative w-full">
										<input
											value={invoiceSettingsTemplates[selectedTemplate]?.account_name}
											type="text"
											style={{fontSize: 12}}
											name="account_name"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={e => handleChange('account_name', e.target.value)}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Account Name
										</label>
									</div>
									{displayError('account_name')}
								</div>

								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										Routing Number
									</label>
									<div className="outlin relative w-full">
										<input
											value={invoiceSettingsTemplates[selectedTemplate]?.account_routing_number}
											type="text"
											style={{fontSize: 12}}
											name="account_routing_number"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={e => handleChange('account_routing_number', e.target.value)}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Routing Number
										</label>
									</div>
									{displayError('account_routing_number')}
								</div>

								<div className="mt-2">
									<label htmlFor="Resale Certificate*" className="hidden text-base bg-transparent font-normal py-2">
										Account Number
									</label>
									<div className="outlin relative w-full">
										<input
											value={invoiceSettingsTemplates[selectedTemplate]?.account_number}
											type="text"
											style={{fontSize: 12}}
											name="account_number"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={e => handleChange('account_number', e.target.value)}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label htmlFor="certificate" className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Account Number
										</label>
									</div>
									{displayError('account_number')}
								</div>

								<div className="mt-2">
									<label className="hidden text-base bg-transparent font-normal py-2">ACH Routing</label>
									<div className="outlin relative w-full">
										<input
											value={invoiceSettingsTemplates[selectedTemplate]?.bank_ach_routing_number}
											type="text"
											style={{fontSize: 12}}
											name="bank_ach_routing_number"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={e => handleChange('bank_ach_routing_number', e.target.value)}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">ACH Routing</label>
									</div>
									{displayError('bank_ach_routing_number}')}
								</div>

								<div className="mt-2">
									<label className="hidden text-base bg-transparent font-normal py-2">Swift Code</label>
									<div className="outlin relative w-full">
										<input
											value={invoiceSettingsTemplates[selectedTemplate]?.bank_swift_code}
											type="text"
											style={{fontSize: 12}}
											name="bank_swift_code"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={e => handleChange('bank_swift_code', e.target.value)}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">Swift Code</label>
									</div>
									{displayError('bank_swift_code')}
								</div>

								<div className="mt-2">
									<label className="hidden text-base bg-transparent font-normal py-2">Zelle Email of Phone (Leave Blank to Omit)</label>
									<div className="outlin relative w-full">
										<input
											value={invoiceSettingsTemplates[selectedTemplate]?.zelle_email_of_phone}
											type="text"
											style={{fontSize: 12}}
											name="zelle_email_of_phone"
											placeholder=" "
											className="block px-4 pt-5 pb-3 w-full inputLogin appearance-none outline-none bg-transparent text-black text-sm font-medium"
											onChange={e => handleChange('zelle_email_of_phone', e.target.value)}
											disabled={!Globals.adminOrOwner(profile)}
										/>
										<label className="pointer-events-none absolute top-1 text-sm bg-transparent p-4 duration-300 origin-0 opacity-60">
											Zelle Email of Phone (Leave Blank to Omit)
										</label>
									</div>
									{displayError('zelle_email_of_phone')}
								</div>
							</div>
						</div>
					</div>
					<div className="flex items-center justify-center p-10">
						<button
							style={{
								borderColor: isUpdated ? '#2C79E0' : 'rgb(93, 110, 129)',
								color: isUpdated ? '#2C79E0' : 'rgb(93, 110, 129)',
								opacity: isUpdated ? 1 : 0.4,
							}}
							disabled={!isUpdated}
							className=" border-[1px] px-3 py-1.5 cursor-pointer rounded-[5px] w-fit text-sm font-bold"
							type="button"
							onClick={handleSubmit}
						>
							Apply
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	setCurrentSpace,
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(Invoice)