import React from "react";
import Tooltip from '@mui/material/Tooltip';
import isEmpty from 'lodash/isEmpty';
import Classnames from 'classnames';
import Moment from 'moment';
import head from 'lodash/head';
import isNull from 'lodash/isNull';
import { Globals } from "../../Context";
import ArrowDownSVG from "./ArrowDownSVG";
import StatusLabel from "./StatusLabel";
import { dataTableColumns } from "./Constants";

const DataTable = ({ dataList, loadSingleOrder, handleShowDetailWatch }) => {
  return (
    <table
      className="relative min-w-full border-b bg-transparent overflow-scroll">
      <thead className="drop-shadow-md sticky top-0 bg-white border-b">
        <tr className="divide-x">
          {
            dataTableColumns.map((column) => {
              return (
                <th key={column.index} className={`${column.columnStyleClasses} p-4 text-sm font-bold tracking-worderer`}>
                  <button className="flext text-gray-500">
                    <span>{column.heading}</span>
                    <ArrowDownSVG />
                  </button>
                </th>
              )
            })
          }
        </tr>
      </thead>

      {!isEmpty(dataList) && (
        <tbody className="divide-y">
          {dataList.map((order, index) => {
            return (
              <tr
                key={index}
                className={Classnames('cursor-pointer divide-x hover:bg-gray-100 bg-gray-50', {
                  'bg-white': (index + 1) % 2 === 0,
                })}
                onClick={() => loadSingleOrder(order)}
              >
                <th className="w-[135px] p-4 text-sm font-normal tracking-worderer">#{order.order_number}</th>
                <th className="w-[175px] p-4 text-sm font-normal tracking-worderer">{Moment(order.created_at).format('MM/DD/YYYY')}</th>
                <th className="w-[260px] p-4 text-sm font-normal tracking-worderer">
                  {!isEmpty(order.customer) &&
                    <span>{order.customer.company_name ? order.customer?.company_name : order.customer?.name}</span>
                  }
                </th>
                <th className="w-[260px] p-4 text-sm font-normal tracking-worderer">
                  {
                    order.sale_items.map((saleItem, index) => {
                      return (
                        <Tooltip
                          key={index}
                          title={
                            <div
                              style={{
                                backgroundImage: saleItem.saleable && saleItem.saleable.images && !isEmpty(saleItem.saleable.images) && head(saleItem.saleable.images) && !isNull(saleItem.saleable.images) ? `url('${head(saleItem.saleable.images).url}')` : `url('/watch_icon.png')`,
                                width: 80,
                                height: 80,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                              }}
                            ></div>
                          }
                          placement="bottom"
                          followCursor={true}
                          arrow
                        >
                          <span
                            onClick={(event) => {
                              event.stopPropagation()
                              saleItem.saleable && handleShowDetailWatch(saleItem.saleable)
                            }}
                            className="hover:text-blue-500" key={index}
                          >
                            <span>{saleItem.saleable?.reference_number}</span>
                            <span>{` (${saleItem.saleable?.sku})`}</span>
                            {index < order.sale_items.length - 1 && <span>,&nbsp;</span>}
                          </span>
                        </Tooltip>
                      )
                    })
                  }

                  {order.sale_items.length > 0 && (order.misc_items || []).length > 0 && <span>,&nbsp;</span>}
                  {
                    (order.misc_items || []).map((miscItem, index) => {
                      return (
                        <React.Fragment key={index}>
                          <span className="hover:text-blue-500" key={index}>
                            <span>{miscItem.product_name}</span>
                          </span>

                          {index < order.misc_items.length - 1 && <span>,&nbsp;</span>}
                        </React.Fragment>
                      )
                    })
                  }
                </th>
                <th className="p-4 text-sm font-normal tracking-worderer">
                  {Globals.readCurrencyNumber(order?.total)}
                </th>
                <th className="p-4 text-sm font-normal tracking-worderer text-center">
                  <div className="w-29">
                    <StatusLabel orderStatus={order.status} />
                  </div>
                </th>
              </tr>
            )
          })}
        </tbody>
      )}
    </table>
  )
}

export default DataTable;
