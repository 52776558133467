import React, {useState} from 'react'
import Classnames from 'classnames'
import moment from 'moment'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'

import ConfirmPopUp from '../../Components/ConfirmPopUp'
import {Globals, priceFieldKeys} from '../../Context'
import SelectOptionsFlat from './SelectOptionsFlat'
import UploadMedia from '../../Components/UploadMedia'
import DatePickerFlat from './DatePickerFlat'
import {connect} from 'react-redux'
import {addToast} from './../../app/appActions'
import * as collections from '../../utils/collections'
import AddNewQuickMemo from './AddNewQuickMemo'
import AddNewQuickSale from './AddNewQuickSale'
import DoubleEditModal from './DoubleEditModal'
import EditWatchModal from '../../Components/common/EditWatchModal'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SearchIcon from '@mui/icons-material/Search'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'
import isNil from 'lodash/isNil'
import toNumber from 'lodash/toNumber'
import uniqBy from 'lodash/uniqBy'
import values from 'lodash/values'
import isNull from 'lodash/isNull'
import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'
import isUndefined from 'lodash/isUndefined'
import ServerAutoSuggest from './AutoSuggest'
import cloneDeep from 'lodash/cloneDeep'

const RenderCustomColumn = ({
	customColumn,
	state,
	handleChangeCustomColumn,
	handleTrimText,
	handleChangeListOption,
	handleChangePickerCustomColumn,
	editMode,
	formData,
	handleKeyDownDropDownCustom,
	focusedItemIndex,
	resetFocusItem,
}) => {
	const [search, setSearch] = useState('')
	const [menuAnchorEl, setMenuAnchorEl] = useState('')
	const [currentMenuIndex, setCurrentMenuIndex] = useState(null)
	const [currentListOption, setCurrentListOption] = useState([])

	const handleSearchChange = (event, customColumn) => {
		setSearch(event.target.value)
		setCurrentListOption(customColumn.custom_column.list_options.filter(option => option.value.toLowerCase().includes(event.target.value.toLowerCase())))
	}

	const handleMenuOpen = (event, index) => {
		setMenuAnchorEl(event.currentTarget)
		setCurrentMenuIndex(index)
		setCurrentListOption(customColumn.custom_column.list_options)
	}

	const handleMenuClose = () => {
		setMenuAnchorEl(null)
		setCurrentMenuIndex(null)
		setSearch('')
		resetFocusItem()
	}

	const handleChoseOption = (field, customColumn) => {
		setCurrentMenuIndex(null)
		setMenuAnchorEl(null)
		handleChangeListOption(field.value, customColumn)
	}

	const displayError = key => {
		if (!isEmpty(state.formErrors[key])) return <div className="my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{state.formErrors[key]}</div>
	}

	return (
		<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
			<div>{customColumn.label}</div>
			<div>
				{customColumn.type === 'boolean' && (
					<div className="flex gap-4 justify-start" key={formData.custom_column_values?.[customColumn.key]}>
						{editMode && (
							<React.Fragment>
								<div className="flex gap-1.5 items-center">
									<input
										onChange={handleChangeCustomColumn}
										onSelect={handleChangeCustomColumn}
										type="radio"
										value={true}
										id={`${customColumn.key}-yes`}
										name={customColumn.key}
										defaultChecked={formData.custom_column_values?.[customColumn.key] === true}
									/>
									<label htmlFor={`${customColumn.key}-yes`}>Yes</label>
								</div>
								<div className="flex gap-1.5 items-center">
									<input
										onChange={handleChangeCustomColumn}
										onSelect={handleChangeCustomColumn}
										type="radio"
										value={false}
										id={`${customColumn.key}-no`}
										name={customColumn.key}
										defaultChecked={formData.custom_column_values?.[customColumn.key] === false}
									/>
									<label htmlFor={`${customColumn.key}-no`}>No</label>
								</div>
							</React.Fragment>
						)}
					</div>
				)}

				{customColumn.type === 'text' && (
					<input
						key={customColumn.key}
						value={editMode ? formData.custom_column_values?.[customColumn.key] : ''}
						onChange={handleChangeCustomColumn}
						onBlur={handleTrimText}
						type="text"
						name={customColumn.key}
						placeholder={customColumn.label}
						className="border-0 m-0 p-0 w-full"
						maxLength={50}
					/>
				)}

				{customColumn.type === 'float' && (
					<input
						key={customColumn.key}
						value={editMode ? formData.custom_column_values?.[customColumn.key] : ''}
						onChange={handleChangeCustomColumn}
						type="number"
						name={customColumn.key}
						placeholder={customColumn.label}
						className="border-0 m-0 p-0 w-full"
					/>
				)}

				{customColumn.type === 'date' && (
					<DatePickerFlat
						name={customColumn.key}
						title={customColumn.label}
						handlechangePicker={handleChangePickerCustomColumn}
						defaultValue={editMode ? formData.custom_column_values?.[customColumn.key] : null}
					/>
				)}
				{customColumn.type === 'list' && (
					<div onKeyDown={event => handleKeyDownDropDownCustom(event, currentListOption)} className={Classnames('w-3/12 flex items-center justify-start ')}>
						<button onClick={event => handleMenuOpen(event, customColumn.key)} className="flex items-center whitespace-nowrap">
							{formData.custom_column_values?.[customColumn.key] ? formData.custom_column_values?.[customColumn.key] : 'Please Select'}
							<ArrowDropDownIcon />
						</button>

						<Menu
							anchorEl={currentMenuIndex === customColumn.key ? menuAnchorEl : null}
							open={Boolean(currentMenuIndex === customColumn.key)}
							onClose={handleMenuClose}
							MenuListProps={{
								style: {padding: 0},
							}}
							sx={{
								'.MuiMenu-paper': {
									borderRadius: '5px',
									border: '1px solid #5D6E81',
								},
							}}
						>
							<div className="flex items-center relative border-b border-[#5D6E81]">
								<input className="ps-10 py-2 w-full text-[#5D6E81]" type="text" placeholder="Search..." value={search} onChange={e => handleSearchChange(e, customColumn)} />
								<div className="absolute left-2 text-[#5D6E81]">
									<SearchIcon className="rotate-90" />
								</div>
							</div>
							<div className="max-h-[200px] overflow-y-scroll">
								{currentListOption.map((field, fieldIndex) => (
									<MenuItem
										name={customColumn.key}
										key={fieldIndex}
										onClick={() => handleChoseOption(field, customColumn)}
										ref={el => {
											if (fieldIndex === focusedItemIndex && el) el.focus()
										}}
									>
										<span className="text-[#5D6E81]">{field.value}</span>
									</MenuItem>
								))}
							</div>
						</Menu>
					</div>
				)}
			</div>
		</div>
	)
}

class EditInventoryComponent extends React.Component {
	constructor() {
		super()
		this.state = {
			sendingData: false,
			updateSendingDate: false,
			formData: {},
			formErrors: {},
			success: false,
			editMode: false,
			presetPost: {},
			warrantyCardsList: Globals.warrantyCardsList.other,
			isWarningShown: false,
			isAddNewQuickMemo: false,
			isAddNewQuickSale: false,
			customerForAddQuick: {},
			images: [],
			imagesMobile: [],
			isVisibleDuplicateEditModal: false,
			editedColumns: [],
			initData: {},
			focusedItemIndex: -1,
			visibleFields: [],
      showEditSkuSuggest: false,
		}
		this.onChangePrice = this.onChangePrice.bind(this)
		this.renderInputWithConfig = this.renderInputWithConfig.bind(this)
	}

	formatInventoryData = inventoryData => {

		if (inventoryData.warranty_no_date || inventoryData.warranty === '1/1/1970') {
			inventoryData.warranty = null
			inventoryData.warranty_no_date = true
		}

		if (!!inventoryData.date_purchased) {
			inventoryData.date_purchased = moment(inventoryData.date_purchased).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
		}

		if (!!inventoryData.warranty) {
			inventoryData.warranty = moment(inventoryData.warranty).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
		}

		if (!!inventoryData.sold_date) {
			inventoryData.sold_date = moment(inventoryData.sold_date).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
		}

		return inventoryData
	}

	UNSAFE_componentWillMount() {
		this.setState(
			{
				_presetPost: this.props.post ? JSON.stringify(this.props.post) : null, //Create unimmutable state
			},
			() => {
				if (this.state._presetPost) {
					const pp = JSON.parse(this.state._presetPost)
					if (isNull(pp.sale_id) && isNull(pp.memo_id)) {
						this.setState({isWarningShown: true})
					}
					const brand = pp.brand.toLowerCase()

          const form = this.formatInventoryData(JSON.parse(this.state._presetPost))

          if (this.isAutoSuggestSKU()) {
            if (isEmpty(form.prefix_sku) && isEmpty(form.suffix_sku) && isEmpty(form.number_sku)) {
              const { prefix, number, suffix } = this.splitSku(form.sku);
              form.suggest_prefix = prefix;
              form.suggest_number = number;
              form.suggest_suffix = suffix;
            } else {
              form.suggest_prefix = form.prefix_sku;
              form.suggest_number = form.number_sku;
              form.suggest_suffix = form.suffix_sku;
            }
          }

					this.setState({
						presetPost: this.formatInventoryData(JSON.parse(this.state._presetPost)),
						editMode: true,
						formData: form,
						warrantyCardsList: Globals.warrantyCardsList[brand] || Globals.warrantyCardsList.other,
						initData: JSON.parse(this.state._presetPost)
					})
				}
			}
		)

		this.setState({
			visibleFields: this.props.globalColumnConfigs.filter(item => item.visible).map(item => item.key)
		})
	}

  splitSku = (sku) => {
    const match = sku.match(/^([^0-9]*)(\d+)?(.*)$/);
    return {
      prefix: match[1] || '',
      number: match[2] || '',
      suffix: match[3] || ''
    };
  };

	handleChange = e => {
		const watchStatus = find(collections.inventoryStatusesCollection, {value: this.state.presetPost.status})
		if (
			e.target.getAttribute('name') === 'status_details' &&
			!this.state.isWarningShown &&
			(this.state.presetPost.status === 'pending_sale_to' || this.state.presetPost.status === 'memo_to') &&
			e.target.value.trim() !== this.state.presetPost.status_details
		) {
			this.setState({isWarningShown: true})
			this.props.addToast({
				title: 'Inventory Status detail',
				text: `This item is on ${watchStatus.option}. Changing the status detail will remove this item from the memo/invoice. Are you sure you want to proceed?`,
				type: 'warning',
			})
		}

		if (e.target.name === "sold_price"){
			this.setState({
				formData: {
					...this.state.formData,
					[e.target.name]: Globals.formatCurrency(e.target.value),
				}
				})
			return
		}

		if (e.target.getAttribute('maxLength')) {
			const maxLength = parseInt(e.target.getAttribute('maxLength'))
			const content = e.target.value.slice(0, maxLength)
			e.target.value = content
		}

		this.setState(
			{
				formData: {
					...this.state.formData,
					[e.target.name]: e.target.value,
				},
			},
			() => {
				//Update the box checkbox.
				if (e.target.name === 'box' && this.state.editMode) {
					const presetPost = this.state.presetPost
					presetPost.box = e.target.value === 'true'
					this.setState({
						presetPost: presetPost,
					})
				}

				//Update the warranty checkbox.
				if (e.target.name === 'warranty_no_date') {
					this.setState({
						formData: {
							...this.state.formData,
							[e.target.name]: e.target.checked,
						},
					})

					const presetPost = this.state.presetPost
					presetPost.warranty_no_date = e.target.checked
					if (e.target.checked) {
						presetPost.warranty = null
					}

					this.setState({
						presetPost: presetPost,
					})
				}

				//Update visibility
				if (e.target.name === 'visible_in_marketplace' && this.state.editMode) {
					const presetPost = this.state.presetPost
					presetPost.visible_in_marketplace = e.target.value === 'true'
					this.setState({
						presetPost: presetPost,
					})
				}
			}
		)
	}

	handleChangeAutoComplete = (event, value) => {
		if(this.state.formData.status === 'memo_to') {
			this.setState({
				formData: {
					...this.state.formData,
					status_details: value.company_name || value.name,
				},
				isAddNewQuickMemo: true,
				customerForAddQuick: value
			})
		} else {
			this.setState({
				formData: {
					...this.state.formData,
					status_details: value.company_name || value.name,
				},
				isAddNewQuickSale: true,
				customerForAddQuick: value
			})
		}
	}

	onChangePrice = (e, inputName) => {
		const price = Globals.formatCurrency(e.target.value, true)
		this.setState({
			formData: {
				...this.state.formData,
				[inputName]: price,
			},
		});
	}

	handleChangeSelect = e => {
		const watchStatus = find(collections.inventoryStatusesCollection, {value: this.state.presetPost.status})
		if (
			e.target.getAttribute('name') === 'status' &&
			!this.state.isWarningShown &&
			(this.state.presetPost.status === 'pending_sale_to' || this.state.presetPost.status === 'memo_to' || this.state.presetPost.status === 'sold_to') &&
			e.target.getAttribute('value').trim() !== this.state.presetPost.status
		) {
			this.setState({isWarningShown: true})
			this.props.addToast({
				title: 'Inventory Status',
				text: `This item is on ${watchStatus.option}. Changing the status will remove this item from the memo/invoice. Are you sure you want to proceed?`,
				type: 'warning',
			})
		}

		let newFormState = {
			...this.state.formData,
			[e.target.getAttribute('name')]: e.target.getAttribute('value').trim(),
		}

		if (e.target.getAttribute('name') === 'status' && e.target.getAttribute('value') === 'sold_to' && !newFormState.sold_date) {
			newFormState['sold_date'] = moment().local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
		}

		this.setState({
			formData: newFormState,
		})
	}

	handlechangePicker = (date, name) => {
		this.setState({
			formData: {...this.state.formData, [name]: date},
		})
	}

	handleChangeCustomColumn = (e) => {
		const { name, type, value } = e.target;
		const customColumnsValues = { ...this.state.formData.custom_column_values };

		if (type === 'radio') {
			customColumnsValues[name] = value === 'true'
		} else if (type==='text') {
			customColumnsValues[name] = value
		} else {
			customColumnsValues[name] = value.trim();
		}
		if (type === 'checkbox') {
			customColumnsValues[name] = value === 'true'
		}

		this.setState(prevState => ({
			formData: {
				...prevState.formData,
				custom_column_values: customColumnsValues
			}
		}));
	};

	handleTrimText = (e) => {
		const { name, type, value } = e.target;
		const customColumnsValues = { ...this.state.formData.custom_column_values };

		if (type === 'text') {
			customColumnsValues[name] = value.trim();
		}

		this.setState(prevState => ({
			formData: {
				...prevState.formData,
				custom_column_values: customColumnsValues
			}
		}));
	}

	handleChangeListOption = (field, customColumn) => {
		const customColumnsValues = { ...this.state.formData.custom_column_values };
		customColumnsValues[customColumn.key] = field

		this.setState(prevState => ({
			formData: {
				...prevState.formData,
				custom_column_values: customColumnsValues
			}
		}))
	}

	handleChangePickerCustomColumn = (date, name) => {
		const formData = this.state.formData

		if (!isNil(date)) {
			formData.custom_column_values[name] = moment(date).format('MM/DD/YYYY')
		} else {
			formData.custom_column_values[name] = null
		}

		this.setState({
			formData: formData,
		})
	}

	setFormValue = fromData => {
		this.setState(
			{
				formData: {
					...this.state.formData,
					...fromData,
				},
			},
			() => {
				if (fromData.brand) {
					this.setState({
						warrantyCardsList: Globals.warrantyCardsList[fromData.brand.toLowerCase()] || Globals.warrantyCardsList.other,
					})
				}
				if (fromData.image_url) {
					this.addImageToUploadMedia(fromData.image_url);
				}
			}
		)
	}

	addImageToUploadMedia = image_url => {
		if (!image_url || typeof image_url !== 'string' || !image_url.startsWith('http')) {
			console.error('Invalid URL provided.');
			return;
		}

		const uploadData = {
			file_url: image_url,
		};

		Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/attachments_url`, uploadData)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					const newImage = {
						id: response.data.id,
						name: response.data.name,
						post_id: response.data,
						done: true,
						file: undefined,
						size: undefined,
						type: response.data.type,
						progress: 100,
						src: response.data.url
					};

					let updatedImages = [...this.state.images];
					const stockFileIndex = updatedImages.findIndex(file => file.name?.startsWith('stock'));
					if (stockFileIndex !== -1) {
						updatedImages[stockFileIndex] = newImage;
					} else {
						updatedImages = [...updatedImages, newImage];
					}
					this.uploadImages(updatedImages);
				} else {
					console.error('Unexpected response from the server.');
				}
			})
			.catch(err => {
				console.error('Failed to upload URL:', err);
			});
	};

	uploadImages = pictures => {
		let _pictures = []
		const formData = this.state.formData
		pictures.forEach((p, index) => {
			if (p.done) {
				let newPic = {id: p.post_id.id, name: p.post_id.name, order: index + 1 }
				_pictures = [..._pictures, newPic]
			}
		})
		formData.images = _pictures
		formData.removed_images = this.state.presetPost.images.filter(
			image => !find(_pictures, {id: image.id})
		).map(image => ({id: image.id}))
		this.setState({formData: formData, images: pictures})
	}

	uploadImagesMobile = pictures => {
		let _pictures = []
		const formData = this.state.formData
		pictures.forEach((p, index) => {
			if (p.done) {
				let newPic = {id: p.post_id.id, order: index + 1, url: p.post_id.url }
				_pictures = [..._pictures, newPic]
			}
		})
		formData.images = _pictures
		formData.removed_images = this.state.presetPost.images.filter(
			image => !find(_pictures, {id: image.id})
		).map(image => ({id: image.id}))
		this.setState({formData: formData,imagesMobile: [..._pictures]})
	}

	isNotANumber = (value) => {
		const price = value.toString().replace(/,/g, '');
		return isNaN(toNumber(price));
	}

	hideAddNewQuickMemo = () => {
		this.setState({isAddNewQuickMemo: false})
	}

	hideAddNewQuickSale = () => {
		this.setState({isAddNewQuickSale: false})
	}

	handleVisibleDuplicateEditModal = () => {
		this.setState({isVisibleDuplicateEditModal: !this.state.isVisibleDuplicateEditModal})
	}

	// NOTE: https://www.figma.com/design/dFbC6soroIlrf7aBhlmE7E/Elefta-Desktop---Master?node-id=244-6218&t=BqUfxtZOvwWTilr0-0
	getChangedKeys = (data) => {
		const { initData } = this.state
		const relevantKeys = ['removed_images', 'space'];
		const keysToUpdate = [];
		Object.keys(data).forEach((key) => {
			if (relevantKeys.includes(key)) return;
			switch (key) {
				case 'images':
					if (data[key].length !== initData[key].length) {
						keysToUpdate.push(key);
					}
					break;
				case 'custom_column_values':
					Object.keys(data[key]).forEach((value) => {
						if (data[key][value] !== initData[key][value]) {
							keysToUpdate.push(value);
						}
					});
					break;
				default:
					if (data[key] !== initData[key]) {
						keysToUpdate.push(key);
					}
					break;
			}
		});
		return keysToUpdate
	}

	handleCheckDataDuplicateBeforeSubmitting = async (_event) => {
		_event.preventDefault()
		const props = this.props

		const validate = this.handleValidate()
		if(!!validate) return

		const response = await Globals.New_Axios().get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${this.props.post.id}`)
		if(response?.data) {
			const data = response?.data

			const changegKeys = this.getChangedKeys(data)
			const columns = changegKeys.map(key => {
				if(key === 'status_details') {
					return 'Status Details'
				} else if(key === 'sold_price') {
					return 'Sold Price'
				} else if(key === 'sold_date') {
					return 'Sold Date'
				} else {
					const match = props.columns.find(item => item.key === key);
					if(match) {
						return match.label
					}
				}
			});

			if(columns.length > 0) {
				this.setState({
					editedColumns: columns,
					isVisibleDuplicateEditModal: true
				})
			} else {
				this.handleSubmit(_event)
			}
		}
	}

	handleValidate = () => {
		const state = this.state

		if (state.updateSendingDate) return //Block user from sending data before the previous request is processed.

		//Make sure all the data is being correct.
		let errors = []
		if (isEmpty(state.formData.sku)) errors = {...errors, sku: 'Dealer SKU is required'}
		if (isEmpty(state.formData.reference_number))
			errors = {
				...errors,
				reference_number: 'Reference Number is required',
			}
		if (isEmpty(state.formData.brand)) errors = {...errors, brand: 'Brand is required'}
		// if (isEmpty(state.formData.series)) errors = {...errors, series: 'Series is required'}
		// if (isEmpty(state.formData.SerialNumber)) errors = { ...errors, SerialNumber: 'Serial Number is required' };
		// if (isEmpty(state.formData.Condition)) errors = { ...errors, Condition: 'Condition is required' };
		// if (isEmpty(state.formData.Dial)) errors = { ...errors, Dial: 'Dial is required' };
		if (isEmpty(state.formData.status)) errors = {...errors, status: 'Status is required'}

		if (state.formData.status?.toLowerCase() === 'sold_to') {
			if (!state.formData.sold_price) {
				errors = {...errors, sold_price: 'Sold Price is required'}
			}

			if (isNil(state.formData.sold_date)) {
				errors = {...errors, sold_date: 'Sold Date is required'}
			}
		}

		if (!!state.formData.status && state.formData.status?.toLowerCase() !== 'on_hand' && state.formData.status?.toLowerCase() !== 'other') {
			if (isEmpty(state.formData.status_details)) {
				errors = {...errors, status_details: 'Name is required'}
			}
		}

		if (!!state.formData.sold_price && this.isNotANumber(state.formData.sold_price)) {
			errors = {...errors, sold_price: 'Sold Price must be a number'}
		}

		if (!!state.formData.msrp_price && this.isNotANumber(state.formData.msrp_price)) {
			errors = {...errors, msrp_price: 'MSRP Price must be a number'}
		}

		if (!!state.formData.wholesale_price && this.isNotANumber(state.formData.wholesale_price)) {
			errors = {...errors, wholesale_price: 'Wholesale Price must be a number'}
		}

		if (!!state.formData.cost && this.isNotANumber(state.formData.cost)) {
			errors = {...errors, cost: 'Cost must be a number'}
		}

		// if (isEmpty(state.formData.Bezel)) errors = { ...errors, Bezel: 'Bezel is required' };
		// if (isEmpty(state.formData.Bracelet)) errors = { ...errors, Bracelet: 'Bracelet is required' };
		// if (isEmpty(state.formData.WarrantyPaper)) errors = { ...errors, WarrantyPaper: 'Warranty Paper/Card is required' };
		// if (isEmpty(state.formData.Box)) errors = { ...errors, Box: 'Box is required' };
		//if (isEmpty(state.formData.visible_in_marketplace)) errors = {...errors, visible_in_marketplace: 'Visibility is required'}
		// if (isEmpty(state.formData.Images)) errors = { ...errors, Images: 'at least one picture required' };

		this.setState({formErrors: errors})

		if (state.formData.warranty) {
			const formData = this.state.formData
			formData.warranty_date = 'No Date'
			this.setState({formData: formData})
		}

		if (!isEmpty(errors)) {
			return Object.values(errors)
				.reverse()
				.map(error => {
					return this.props.addToast({
						title: 'Cannot add item',
						text: error,
						type: 'error',
					})
				})
		}
	}

	handleSubmit = _event => {
		_event.preventDefault()
		const formData = cloneDeep(this.state.formData)
		priceFieldKeys.forEach(key => {
			if (formData[key] && typeof formData[key] === 'string') {
				formData[key] = Globals.revertPrice(formData[key])
			}
		})
		this.handleValidate()
		let errors = []
		if (this.state.editMode) {
			this.setState({updateSendingDate: true})

			if (formData.warranty_no_date) {
				formData.warranty = null
			}

			if (formData.warranty_papers === 'no') {
				formData.warranty = null
				formData.warranty_no_date = false
			}

			if (!isNil(formData.warranty)) {
				formData.warranty = moment(formData.warranty).format('DD/MM/YYYY')
			}

			if (!isNil(formData.sold_date)) {
				formData.sold_date = new Date(
					moment(formData.sold_date).year(),
					moment(formData.sold_date).month(),
					moment(formData.sold_date).date(),
					0,
					0,
					0
				)
			}

			if (!isNil(formData.date_purchased)) {
				formData.date_purchased = new Date(
					moment(formData.date_purchased).year(),
					moment(formData.date_purchased).month(),
					moment(formData.date_purchased).date(),
					0,
					0,
					0
				)
			}
			if (isFunction(this.props.handlePrimaryTab))  this.props.handlePrimaryTab()

      if(this.isAutoSuggestSKU()) {
        formData.prefix_sku = formData.suggest_prefix;
        formData.number_sku = formData.suggest_number;
        formData.suffix_sku = formData.suggest_suffix;
      }

			return Globals.New_Axios()
				.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${this.state.presetPost.id}`, {
					watch: {...formData},
				})
				.then(async response => {
					this.setState({updateSendingDate: false})
					this.props.getConditions()

					if (response.status && response.status >= 200 && response.status < 300) {
						this.props.addToast({
							title: 'Add Inventory',
							text: 'Your item has been successfully updated',
							type: 'success',
						})
						if (!isEmpty(this.props.searchKey)) {
							await this.props.handleSearchByValue(this.props.searchKey)
							await this.props.onDone(response.data)
							return
						}
						if (this.props?.filter_values_overall?.length > 0) {
							this.props.filter_values_overall.forEach(async filter => {
								return await this.props.handleFilterAction({key: filter.key}, filter.request, false)
							})
						}

						if (!isEmpty(this.props?.searchKey)) {
							return this.props.handleSearchByValue(this.props.searchKey)
						}

						if (isFunction(this.props.onDone)) return await this.props.onDone(response.data)

						if (!isNil(this.props?.userSelectedFilter)) {
							return this.props.loadSelectedFilter(this.props.userSelectedFilter)
						}
					}

					errors = {...errors, error: response.data.errors}
					Object.values(errors)
						.reverse()
						.map(error => {
							return this.props.addToast({
								title: 'Cannot add item',
								text: error,
								type: 'error',
							})
						})
					return this.setState({formErrors: errors})
				})
				.catch(error => {
					this.setState({updateSendingDate: false})
					errors = {
						...errors,
						error: error.response?.data?.message || 'Something went wrong please try again later.',
					}

					if (error.response?.data?.message) {
						this.props.addToast({
							text: error.response?.data?.message,
							type: 'error',
						})
						return this.setState({formErrors: errors})
					}

					this.props.addToast({
						text: error.response?.data?.message || 'Something went wrong please try again later.',
						type: 'error',
					})
					return this.setState({formErrors: errors})
				})
		}

		this.setState({sendingData: true})

		return Globals.Axios()
			.post(`${Globals.wsurl}/inventory/add/inventory`, {
				data: formData,
			})
			.then(response => {
				this.setState({sendingData: false})

				if (response.data.status) {
					if (isFunction(this.props.onDone)) this.props.onDone(response.data)
					return this.props.addToast({
						title: 'Add Inventory',
						text: 'Your item has been successfully uploaded',
						type: 'success',
					})
				}

				errors = {...errors, error: response.data.errors}
				return this.setState({formErrors: errors})
			})
			.catch(error => {
				this.setState({sendingData: false})
				errors = {
					...errors,
					error: 'Something went wrong please try again later.',
				}
				this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
				return this.setState({formErrors: errors})
			})
	}

	handleDeleteProduct = $event => {
		$event.preventDefault()

		const state = this.state
		let errors = []

		if (state.sendingData) return //Block user from sending data before the previous request is processed.

		this.setState({sendingData: true})
		if (isFunction(this.props.handlePrimaryTab)) this.props.handlePrimaryTab()

		return Globals.New_Axios()
			.delete(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${this.state.formData.id}`)
			.then(response => {
				this.setState({sendingData: false})
				response.data['_destroy'] = true
				if (isFunction(this.props.onDone)) this.props.onDone(response.data, true)

				return this.props.addToast({
					title: 'Delete Inventory',
					text: 'Your item has been successfully deleted',
					type: 'success',
				})
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wront please try again later.'

				this.props.addToast({
					text: message,
					type: 'error',
				})
				this.setState({sendingData: false})
				errors = {
					...errors,
					error: message,
				}
				return this.setState({formErrors: errors})
			})
	}

	goToNextOption = (event, options) => {
		this.setState(prevState => ({
			focusedItemIndex: Math.min(prevState.focusedItemIndex + 1, options.length - 1)
		}));
		event.stopPropagation();
	}

	goToPreviousOption = (event) => {
		this.setState(prevState => ({
			focusedItemIndex: Math.max(prevState.focusedItemIndex - 1, 0)
		  }));
		event.stopPropagation();
	}

	resetFocusItem = () => {
		this.setState({focusedItemIndex : -1});
	}

	handleKeyDownDropDownCustom = (event, currentListOption) => {
		switch (event.key) {
			case Globals.keyboardButton.arrowDown:
				event.preventDefault();
				this.goToNextOption(event, currentListOption)
				break;
				case Globals.keyboardButton.arrowUp:
				event.preventDefault();
				this.goToPreviousOption(event)
				break;
			case Globals.keyboardButton.enter:
				event.preventDefault();
				if(this.state.focusedItemIndex === -1) return
				this.setState({focusedItemIndex: -1})
				break;
			default:
		}
	};

  isAutoSuggestSKU = () => {
    return !!this.props.currentSpace.space_general_setting?.auto_suggest_sku_enabled
  }

  handleEditSkuSuggest = (e) => {
    this.setState({
      showEditSkuSuggest: !this.state.showEditSkuSuggest,
      formData: {
        ...this.state.formData,
        sku: `${this.state.formData?.suggest_prefix}${this.state.formData?.suggest_number}${this.state.formData?.suggest_suffix}`
      }
    })
  }

	handleWarrantyCard = debounce(e => {
		const brand = e.target.value.trim().toLowerCase()
		this.setState({warrantyCardsList: Globals.warrantyCardsList[brand] || Globals.warrantyCardsList.other})
	}, 500)

	renderInputWithConfig = (config, displayError) => {
		const { state, props } = this

		const customColumns = props.columns.filter(header => header.is_custom_column)
		let inputConfig = {onChange: this.handleChange, onSelect: this.handleChange, type: 'text'}

		if (config.key === 'sku') {
			inputConfig = Object.assign(inputConfig, { label: 'Dealer SKU*', name: 'sku', placeholder: 'SKU' })

      if (this.isAutoSuggestSKU()) {
        return (
          <div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
            <div>{inputConfig.label || inputConfig?.labelWithTooltip()}</div>
            <div>
              {this.state.showEditSkuSuggest ? (
                <div className='flex' onBlur={this.handleEditSkuSuggest}>
                  <input
                    type="text"
                    name='suggest_prefix'
                    value={state.formData?.suggest_prefix}
                    onChange={this.handleChange}
                    className="border border-slate-700 px-1"
                    style={{
                      textAlign: 'end',
                      width: `${(state.formData?.suggest_prefix?.length || 1 ) * 12 + 12}px`,
                      maxWidth: `calc((100% - ${(state.formData?.suggest_number?.length || 1 ) * 12 + 16}px)/2)`
                    }}
                  />
                  <span className='mx-1'>
                    {state.formData?.suggest_number}
                  </span>
                  <input
                    type="text"
                    name='suggest_suffix'
                    value={state.formData?.suggest_suffix}
                    onChange={this.handleChange}
                    className="border border-slate-700 px-1"
                    style={{
                      width: `${(state.formData?.suggest_suffix?.length || 1 ) * 12 + 12}px`,
                      maxWidth: `calc((100% - ${(state.formData?.suggest_number?.length || 1 ) * 12 + 16}px)/2)`
                    }}
                  />
                </div>
              ) : (
                <span
                  className="border-0 m-0 p-0 w-full"
                  onClick={this.handleEditSkuSuggest}
                >
                  {state.formData ? state.formData?.[config.key] : ''}
                </span>
              )}
              {
                inputConfig.reminderText && (
                  <small className="opacity-75">{inputConfig.reminderText}</small>
                )
              }
              {displayError(inputConfig.name)}
            </div>
          </div>
        )
      }
		} else if (config.key === 'reference_number') {
			return(
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Reference Number*</div>
					<div className="flex relative">
						<ServerAutoSuggest setFormValue={this.setFormValue} formData={state.formData} />
					</div>
					{displayError('reference_number')}
				</div>
			)
		} else if (config.key === 'brand') {
			inputConfig = Object.assign(inputConfig, {
				label: 'Brand*', name: 'brand', placeholder: 'Brand',
				onSelect: e => {
					this.handleChange(e)
					this.handleWarrantyCard(e)
				},
				onChange: e => {
					this.handleChange(e)
					this.handleWarrantyCard(e)
				}
			})
		} else if (config.key === 'series') {
			inputConfig = Object.assign(inputConfig, { label: 'Series', name: 'series', placeholder: 'Series' })
		} else if (config.key === 'serial_number') {
			inputConfig = Object.assign(inputConfig, { label: 'Serial Number', name: 'serial_number', placeholder: 'Serial Number' })
		} else if (config.key === 'dial') {
			inputConfig = Object.assign(inputConfig, { label: 'Dial', name: 'dial', placeholder: 'Dial' })
		} else if (config.key === 'bezel') {
			inputConfig = Object.assign(inputConfig, { label: 'Bezel', name: 'bezel', placeholder: 'Bezel' })
		} else if (config.key === 'bracelet') {
			inputConfig = Object.assign(inputConfig, { label: 'Bracelet', name: 'bracelet', placeholder: 'Bracelet' })
		} else if (config.key === 'condition') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Condition</div>
					<div>
						{state.editMode && (
							<SelectOptionsFlat
								name={'condition'}
								handleChangeSelect={this.handleChangeSelect}
								selectedValue={state.presetPost.condition ? state.presetPost.condition : ''}
								options={collections.inventoryConditionsCollection}
							/>
						)}
						{displayError('condition')}
					</div>
				</div>
			)
		} else if (config.key === 'warranty_papers') {
			let selectedWarrantyPaperOption = find(state.warrantyCardsList, {
				value: state.formData.warranty_papers,
			})

			let selectedWarrantyPaperValue

			if (selectedWarrantyPaperOption) {
				selectedWarrantyPaperValue = selectedWarrantyPaperOption.value
			} else {
				const allWarrantyCardsList = uniqBy(flatten(values(Globals.warrantyCardsList)), 'value')

				const warrantyPapers = find(allWarrantyCardsList, {
					value: state.formData.warranty_papers,
				})

				selectedWarrantyPaperValue = warrantyPapers?.option
			}

			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Warranty Paper/Card</div>
					<div>
						{state.editMode && (
							<SelectOptionsFlat
								name="warranty_papers"
								handleChangeSelect={this.handleChangeSelect}
								selectedValue={state.editMode && state.presetPost ? selectedWarrantyPaperValue : 'Card'}
								options={state.warrantyCardsList}
							/>
						)}
						{displayError('warranty_papers')}
					</div>
				</div>
			)
		} else if (config.key === 'warranty') {
			return(
				state.formData.warranty_papers !== 'no' && (
					<div
						className={Classnames('border-b grid grid-cols-2 gap-2 py-2 text-sm', {
							'opacity-50 pointer-events-none': state.formData.warranty_papers === 'no',
						})}
					>
						<div>Warranty Date</div>
						<div
							style={{
								width: '100%',
								display: 'inline-flex',
							}}
						>
							{state.editMode && (
								<>
									<div
										style={{
											width: '50%',
										}}
									>
										{state.presetPost.warranty_no_date ? (
											<input
												defaultValue={'No Date'}
												value={'No Date'}
												onChange={this.handleChange}
												onSelect={this.handleChange}
												type="text"
												name="warranty"
												readOnly={true}
												placeholder="No Date"
												className="border-0 m-0 p-0 w-full"
											/>
										) : (
											<DatePickerFlat
												name="warranty"
												title="Warranty Date*"
												handlechangePicker={this.handlechangePicker}
												defaultValue={state.presetPost ? state.presetPost.warranty : null}
											/>
										)}
									</div>

									<div
										style={{
											width: '50% !important',
											marginLeft: '5px',
										}}
									>
										<input
											onChange={this.handleChange}
											onSelect={this.handleChange}
											style={{
												opacity: '1',
												width: '15px',
												zIndex: 1,
											}}
											type="checkbox"
											name="warranty_no_date"
											defaultChecked={state.editMode && state.presetPost.warranty_no_date}
										/>
										<span
											htmlFor="no"
											className="pl-5"
											style={{
												width: '30px !important',
											}}
										>
											No Date
										</span>
									</div>
								</>
							)}
							{displayError('warranty_date')}
						</div>
					</div>
				)
			)
		} else if (config.key === 'box') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Box</div>
					<div className="flex gap-4 justify-start">
						{state.editMode && (
							<React.Fragment>
								<div className="flex gap-1.5 items-center">
									<input
										onChange={this.handleChange}
										onSelect={this.handleChange}
										type="radio"
										value={true}
										id="boxYes"
										name="box"
										checked={state.editMode && state.presetPost?.box}
									/>
									<label htmlFor="boxYes">Yes</label>
								</div>
								<div className="flex gap-1.5 items-center">
									<input
										onChange={this.handleChange}
										type="radio"
										value={false}
										id="boxNo"
										name="box"
										checked={state.editMode && state.presetPost?.box === false}
									/>
									<label htmlFor="boxNo">No</label>
								</div>
							</React.Fragment>
						)}
						{displayError('box')}
					</div>
				</div>
			)
		} else if (config.key === 'link_count') {
			inputConfig = Object.assign(inputConfig, { label: 'Link Count', name: 'link_count', placeholder: 'Link Count' })
		} else if (config.key === 'addition_details') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Additional Details</div>
					<div>
						<textarea
							placeholder="Maximum of 20000 characters."
							maxLength="20000"
							defaultValue={state.editMode && state.presetPost ? state.presetPost.addition_details : ''}
							onSelect={this.handleChange}
							onChange={this.handleChange}
							type="text"
							name="addition_details"
							className="border-0 m-0 p-0 w-full"
						></textarea>
						<div>
							<small>{!isNil(state.formData.addition_details) ? state.formData.addition_details.length : 0}</small>
							<small>
								<small>/</small>
							</small>
							<small>
								<small>20000</small>
							</small>
						</div>
						{displayError('addition_details')}
					</div>
				</div>
			)
		} else if (config.key === 'msrp_price') {
			inputConfig = Object.assign(inputConfig, {
				label: 'MSRP Price',
				name: 'msrp_price',
				placeholder: 'MSRP Price',
				value: Globals.formatCurrency(state.formData.msrp_price, true),
				onChange: (e) => this.onChangePrice(e, 'msrp_price'),
			})
		} else if (config.key === 'wholesale_price') {
			inputConfig = Object.assign(inputConfig, {
				labelWithTooltip: () => {
					return(
					<>
						<div className="whitespace-nowrap">
							Wholesale/Marketplace Price
							<Tooltip title="This is the price that will be shown in the Elefta Marketplace for other dealers to see" placement="bottom" arrow>
								<span className="ml-1 inline-block">
									<InfoOutlinedIcon fontSize="small" />
								</span>
							</Tooltip>
						</div>
					</>
				)
			},
				name: 'wholesale_price',
				placeholder: 'Marketplace Price',
				onChange: (e) => this.onChangePrice(e, 'wholesale_price'),
			})
		} else if (config.key === 'cost') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Cost</div>
					<div>
						<input
							value={Globals.formatCurrency(state.formData.cost, true)}
							onChange={(e) => this.onChangePrice(e, 'cost')}
							type="text"
							name="cost"
							placeholder="Cost"
							className="border-0 m-0 p-0 w-full"
						/>
					</div>
				</div>
			)
		} else if (config.key === 'date_purchased') {
			return(
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Date Purchased</div>
					<div>
						{state.editMode && (
							<DatePickerFlat
								name="date_purchased"
								title="MM/DD/YYYY"
								handlechangePicker={this.handlechangePicker}
								defaultValue={state.presetPost && state.presetPost.date_purchased !== '1/1/1970' ? state.presetPost.date_purchased : ' '}
							/>
						)}
						{displayError('date_purchased')}
					</div>
				</div>
			)
		} else if (config.key === 'vendor') {
			inputConfig = Object.assign(inputConfig, { label: 'Vendor/Partner', name: 'vendor', placeholder: 'Vendor' })
		} else if (config.key === 'visible_in_marketplace') {
			return (
				<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
					<div>Visible in marketplace*</div>
					<div className="flex gap-4 justify-start">
						{state.editMode && (
							<React.Fragment>
								<div className="flex gap-1.5 items-center">
									<input
										onChange={this.handleChange}
										onSelect={this.handleChange}
										type="radio"
										value={true}
										id="yes"
										name="visible_in_marketplace"
										checked={state.editMode && state.presetPost?.visible_in_marketplace}
									/>
									<label htmlFor="yes">Yes</label>
								</div>
								<div className="flex gap-1.5 items-center">
									<input
										onChange={this.handleChange}
										onSelect={this.handleChange}
										type="radio"
										value={false}
										id="no"
										name="visible_in_marketplace"
										checked={state.editMode && state.presetPost?.visible_in_marketplace === false}
									/>
									<label htmlFor="no">No</label>
								</div>
							</React.Fragment>
						)}
						{displayError('visible_in_marketplace')}
					</div>
				</div>
			)
		} else if (config.key === 'online_price') {
			inputConfig = Object.assign(inputConfig, {
				label: 'Website Price',
				name: 'online_price',
				placeholder: 'Website Price',
				value: Globals.formatCurrency(state.formData.online_price, true),
				onChange: (e) => this.onChangePrice(e, 'online_price')
			})
		} else if (config.is_custom_column === true) {
			let customColumn = customColumns.find(header => header.key === config.key)

			if (!customColumn || !customColumn.label) {
				return null;
			}

			return(
				<RenderCustomColumn
					customColumn={customColumn}
					state={state}
					presetPost={state.presetPost}
					editMode={state.editMode}
					handleChangeCustomColumn={this.handleChangeCustomColumn}
					handleTrimText={this.handleTrimText}
					handleChangeListOption={this.handleChangeListOption}
					handleChangeSelect={this.handleChangeSelect}
					handleChange={this.handleChange}
					handleChangeAutoComplete={this.handleChangeAutoComplete}
					handleChangePickerCustomColumn={this.handleChangePickerCustomColumn}
					formData={state.formData}
					handleKeyDownDropDownCustom={this.handleKeyDownDropDownCustom}
					focusedItemIndex={this.state.focusedItemIndex}
					resetFocusItem={this.resetFocusItem}
				/>
			)
		}

		if (!inputConfig.name) return

		return (
			<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
				<div>{inputConfig.label || inputConfig?.labelWithTooltip()}</div>
				<div>
					{ ['cost', 'online_price', 'wholesale_price'].includes(config.key) ? (
						<input
							{...inputConfig}
								defaultValue={state.editMode && state.presetPost ? state.presetPost?.[config.key] : ''}
								value={inputConfig.value ? Globals.formatCurrency(inputConfig.value, true) : Globals.formatCurrency(state.formData?.[config.key], true) || ''}
								type={inputConfig.type}
								name={inputConfig.name}
								placeholder={inputConfig.placeholder}
								className="border-0 m-0 p-0 w-full"
						/>
					) : (
						<input
							{...inputConfig}
								defaultValue={state.editMode && state.presetPost ? state.presetPost?.[config.key] : ''}
								value={inputConfig.value ? inputConfig.value : state.formData?.[config.key] || ''}
								type={inputConfig.type}
								name={inputConfig.name}
								placeholder={inputConfig.placeholder}
								className="border-0 m-0 p-0 w-full"
						/>
					)}

					{
						inputConfig.reminderText && (
							<small className="opacity-75">{inputConfig.reminderText}</small>
						)
					}
					{displayError(inputConfig.name)}
				</div>
			</div>
		)
	}

	render() {
		const props = this.props
		const state = this.state
		const { visibleFields } = this.state
		const { globalColumnConfigs } = props

		const defaultDate = new Date()
		const day = String(defaultDate.getDate()).padStart(2, '0')
		const month = String(defaultDate.getMonth() + 1).padStart(2, '0')
		const year = defaultDate.getFullYear()
		const formattedDate = `${month}/${day}/${year}`
		const spaceData = JSON.parse(Globals.Cache.get(Globals.USERSELECTEDSPACE))

		const customColumns = props.columns.filter(header => header.is_custom_column)

		const displayError = key => {
			if (!isEmpty(state.formErrors[key])) return <div className="my-2 rounded bg-red-200 p-1 px-4 font-medium text-red-700">{state.formErrors[key]}</div>
		}

		let selectedWarrantyPaperOption = find(state.warrantyCardsList, {
			value: state.formData.warranty_papers,
		})

		let selectedWarrantyPaperValue

		if (selectedWarrantyPaperOption) {
			selectedWarrantyPaperValue = selectedWarrantyPaperOption.value
		} else {
			const allWarrantyCardsList = uniqBy(flatten(values(Globals.warrantyCardsList)), 'value')

			const warrantyPapers = find(allWarrantyCardsList, {
				value: state.formData.warranty_papers,
			})

			selectedWarrantyPaperValue = warrantyPapers?.option
		}

		const basicInfo = globalColumnConfigs.filter(column => column.block === 'basic_info' && column.visible)
		const scopeOfDelivery = globalColumnConfigs.filter(column => column.block === 'scope_of_delivery' && column.visible)
		const priceInfo = globalColumnConfigs.filter(column => column.block === 'price_info' && column.visible)
		const marketplaceInfo = globalColumnConfigs.filter(column => column.block === 'marketplace_info' && column.visible)
		const customData = globalColumnConfigs.filter(column => column.block === 'custom_data' && column.visible)


		return (
			<React.Fragment>
				{/* <form action="#" method="POST" className="py-2 pb-10 bg-white"> */}
				{this.state.isAddNewQuickMemo &&
					<AddNewQuickMemo
						formData={this.state.formData}
						hideAddNewQuickMemo={this.hideAddNewQuickMemo}
						customer={this.state.customerForAddQuick}
						handleUpdateInventoriesAfterAddQuickMemoOrSale={this.props.handleUpdateInventoriesAfterAddQuickMemoOrSale}
						closeEditInventory={this.props.closeEditInventory}
						handleSubmit={this.handleSubmit}
					/>
				}
				{this.state.isVisibleDuplicateEditModal &&
					<DoubleEditModal
						editedColumns={this.state.editedColumns}
						handleVisibleDuplicateEditModal={this.handleVisibleDuplicateEditModal}
						handleSubmit={this.handleSubmit}
					/>
				}
				{this.state.isAddNewQuickSale &&
					<AddNewQuickSale
						formData={this.state.formData}
						hideAddNewQuickSale={this.hideAddNewQuickSale}
						customer={this.state.customerForAddQuick}
						handleUpdateInventoriesAfterAddQuickMemoOrSale={this.props.handleUpdateInventoriesAfterAddQuickMemoOrSale}
						closeEditInventory={this.props.closeEditInventory}
						handleSubmit={this.handleSubmit}
						currentSpace={this.props.currentSpace}
					/>
				}
				<div className="hidden py-2 pb-10 bg-white md:block">
					<div className="single-product-details grid grid-cols-1 h-screen pb-24 overflow-y-scroll">
						<div className="px-9 py-4">
							<div className="grid grid-flow-row-dense grid-cols-5 gap-6">
								<div className="col-span-3">
									<div className="sticky top-0">
										<div>
											{
												basicInfo.length > 0 && (
													<h2 className="text-md font-bold tracking-wider py-2 border-b">Basic Info</h2>
												)
											}

											{
												basicInfo.map(config => {
													return this.renderInputWithConfig(config, displayError)
												})
											}


										</div>

										<div>
											{
												scopeOfDelivery.length > 0 && (
													<h2 className="text-md font-bold tracking-wider py-2 border-b">Scope of Delivery</h2>
												)
											}

											{
												scopeOfDelivery.map(config => {
													return this.renderInputWithConfig(config, displayError)
												})
											}


										</div>

										<div>
											{
												priceInfo.length > 0 && (
													<h2 className="text-md font-bold tracking-wider py-2 border-b">Price Info</h2>
												)
											}

											{
												priceInfo.map(config => {
													return this.renderInputWithConfig(config, displayError)
												})
											}

										</div>

										<div>
											{
												marketplaceInfo.length > 0 && (
													<h2 className="text-md font-bold tracking-wider py-2 border-b">Marketplace Info</h2>
												)
											}

											{
												marketplaceInfo.map(config => {
													return this.renderInputWithConfig(config, displayError)
												})
											}
										</div>

										<div>
											{
												customData.length > 0 && (
													<h2 className="text-md font-bold tracking-wider py-2 border-b">Custom Data</h2>
												)
											}

											{
												customData.map(config => {
													return this.renderInputWithConfig(config, displayError)
												})
											}
										</div>
									</div>
								</div>
								<div className="col-span-2">
									<div className="sticky top-0">
										<div className="mb-12">
											<h2 className="text-md font-bold tracking-wider py-2">Status</h2>
											<div className="relative border-b grid grid-cols-2 gap-2 py-2 text-sm">
												<div>Status*</div>
												<div>
													{state.presetPost.status && (
														<SelectOptionsFlat
															extraValueErrors={state.formErrors.status_details}
															extraValue={state.presetPost.status_details}
															name={'status'}
															handleChangeSelect={this.handleChangeSelect}
															handleChange={this.handleChange}
															handleChangeAutoComplete={this.handleChangeAutoComplete}
															showCustomerAutoSuggest={true}
															selectedValue={state.editMode && state.presetPost ? state.presetPost.status : 'on_hand'}
															options={collections.inventoryStatusesCollection}
														/>
													)}
													{displayError('status')}
												</div>
											</div>
										</div>

										{state.formData.status === 'sold_to' && (
											<>
												<div className="mt-5 mb-4">
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Sold Price*</div>
														<div>
															<input
																value={typeof state.formData.sold_price !== 'number' ? state.formData.sold_price : Globals.formatCurrency(state.formData.sold_price, true)}
																onSelect={this.handleChange}
																onChange={this.handleChange}
																type="text"
																name="sold_price"
																placeholder="Sold price"
																className="border-0 m-0 p-0 w-full"
															/>
															{displayError('sold_price')}
														</div>
													</div>
												</div>

												<div className="mt-5 mb-4">
													<div className="border-b grid grid-cols-2 gap-2 py-2 text-sm">
														<div>Sold Date</div>
														<div
															style={{
																width: '100%',
																display: 'inline-flex',
															}}
														>
															{state.editMode && (
																<>
																	<div>
																		<DatePickerFlat
																			name="sold_date"
																			title="Sold Date"
																			handlechangePicker={this.handlechangePicker}
																			defaultValue={state.formData.sold_date}
																		/>
																	</div>
																</>
															)}
														</div>
														{displayError('sold_date')}
													</div>
												</div>
											</>
										)}

										<div className="mt-5 mb-4">
											<h2 className="text-md font-bold tracking-wider py-2">Media</h2>
											{state.editMode && state.presetPost.images.length > 0 && (
												<UploadMedia
													innerClassNames="gap-5 grid grid-cols-2 my-5 upload-media-container"
													text="Select files or drag and drop"
													maximum={40}
													uploadUpdate={this.uploadImages}
													multipe={true}
													initialFilesList={state.images}
													defaultValue={state.editMode && state.presetPost.images ? state.presetPost.images : []}
												/>
											)}
											{!state.editMode && (
												<UploadMedia
													innerClassNames="gap-5 grid grid-cols-2 my-5 upload-media-container"
													text="Select files or drag and drop"
													maximum={40}
													multipe={true}
													initialFilesList={state.images}
													uploadUpdate={this.uploadImages}
												/>
											)}
											{state.editMode && (isUndefined(state.presetPost.images) || isEmpty(state.presetPost.images)) && (
												<UploadMedia
													innerClassNames="gap-5 grid grid-cols-2 my-5 upload-media-container"
													text="Select files or drag and drop"
													maximum={40}
													multipe={true}
													initialFilesList={state.images}
													uploadUpdate={this.uploadImages}
												/>
											)}
											{displayError('images')}
										</div>

										<div className="mb-4">
											<h2 className="text-md font-bold tracking-wider py-2">Internal Note</h2>
											<div className="border-b py-2 text-sm">
												<textarea
													placeholder="Maximum of 20000 characters."
													maxLength="20000"
													defaultValue={state.editMode && state.presetPost ? state.presetPost.internal_note : ''}
													onSelect={this.handleChange}
													onChange={this.handleChange}
													type="text"
													name="internal_note"
													className="border-0 m-0 p-0 w-full"
												></textarea>
												<div>
													<small>{!isEmpty(state.formData.internal_note) ? state.formData.internal_note.length : 0}</small>
													<small>
														<small>/</small>
													</small>
													<small>
														<small>20000</small>
													</small>
												</div>
												<div className="opacity-75">
													<small>This will be visible only for your team members.</small>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* <div className="my-9">{displayError('error')}</div> */}
						<div className={"-bottom-2 bg-white sticky flex items-end"}>
							<div
								className={Classnames("flex w-full border-blue-500 border-t-2 px-8", {
									'justify-end': ['editor'].includes(this.props.profile.role),
									'justify-between': !['editor'].includes(this.props.profile.role),
								})}
							>
								{state.editMode && !['editor'].includes(this.props.profile.role) && (
									<ConfirmPopUp onConfirm={this.handleDeleteProduct} title="Confirm deleting product" content="Are you sure you want to delete this product?">
										<button tabIndex="1" className="px-8 py-3 my-2 lg:my-6 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer bg-red-700 uppercase">
											{state.sendingData && (
												<svg className="inline-block animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													{' '}
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
													<path
														className="opacity-75"
														fill="currentColor"
														d="M4 12a8 8 0 018-8V0C5.3mn73 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
													/>
												</svg>
											)}
											Delete Product
										</button>
									</ConfirmPopUp>
								)}
								<button
									onClick={this.handleCheckDataDuplicateBeforeSubmitting}
									tabIndex="0"
									type="submit"
									className="px-8 py-3 my-2 lg:my-6 rounded-md text-xs font-bold leading-5 lg:text-sm text-white cursor-pointer bg-blue uppercase"
								>
									{state.updateSendingDate &&  (
										<svg className="inline-block animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											{' '}
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
											<path
												className="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											/>
										</svg>
									)}
									{state.editMode ? 'Update Product' : 'Add Product'}
								</button>
							</div>
						</div>
					</div>
				</div>
				{!!state.formData && state.presetPost &&
					<EditWatchModal
						isOpen={props.openEditInventory}
						onClose={props.closeEditInventory}
						handleChangeSelect={this.handleChangeSelect}
						data={state.presetPost}
						selectedWarrantyPaperValue={selectedWarrantyPaperValue}
						warrantyCardsList={state.warrantyCardsList}
						handleChange={this.handleChange}
						handlechangePicker={this.handlechangePicker}
						formData={state.formData}
						displayError={displayError}
						handleChangeAutoComplete={this.handleChangeAutoComplete}
						formErrors={state.formErrors}
						onChangePrice={this.onChangePrice}
						customColumns={customColumns}
						handleChangeCustomColumn={this.handleChangeCustomColumn}
						handleChangePickerCustomColumn={this.handleChangePickerCustomColumn}
						handleSubmit={this.handleSubmit}
						uploadUpdate={this.uploadImagesMobile}
						imagesMobile={state.imagesMobile}
						handleDeleteProduct={this.handleDeleteProduct}
					/>
				}
				{/* </form> */}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		globalColumnConfigs: state.globalColumnConfigs,
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	addToast,
}

export default connect(mapStateToProps, mapActionsToProps)(EditInventoryComponent)
