import React, {useEffect, useMemo, useRef, useState} from 'react'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import {useSelector} from 'react-redux'
import {qrFieldsConfig} from '../qrcodeConsts'
import useQRCodeSettings from '../../../../../hooks/useQRCodeSettings'
import cloneDeep from 'lodash/cloneDeep'
import PrinterAdjustments from './PrinterAdjustments'

const QrStickerConfigList = ({setConfigData, configData}) => {
	const globalColumnConfigs = useSelector(state => state.globalColumnConfigs)
	const {updateQRSettingsField} = useQRCodeSettings()
	const columnsConfig = useSelector(state => state.globalColumnConfigs)

	const price3Options = useMemo(() => {
		return columnsConfig?.filter(column => column.is_custom_column || column.type === 'float' || column.key === 'online_price' || column.key === 'msrp_price' || column.key === 'wholesale_price' ).map(column => {
			return {
				value: column.key,
				label: column.key === 'wholesale_price' ? 'Wholesale Price' : column.label,
			}
		})
	}, [columnsConfig]);

	const toggleShown = item => {
		setConfigData(prevState => {
			const index = prevState.findIndex(config => config.key === item.key)
			const newConfigData = cloneDeep(prevState)
			newConfigData[index].visible = !newConfigData[index].visible
			updateQRSettingsField(newConfigData[index]).then(response => {
				if (response.error) {
					setConfigData(prevState)
				}
			})
			return newConfigData
		})
	}

	const inputRef = useRef(null)
	const [inputPlaceholderVisible, setInputPlaceholderVisible] = useState(!configData[2]?.value)

	const currentPriceOptions = useMemo(() => {
		const outputData = []
		globalColumnConfigs.forEach(column => {
			if (column.type === 'float' || column.key === 'online_price' || column.key === 'msrp_price' || column.key === 'wholesale_price') {
				outputData.push({
					value: column.key,
					label: column.key === 'wholesale_price' ? 'Wholesale Price' : column.label,
				})
			}
		})
		return outputData
	}, [globalColumnConfigs])

	const handleCoeficientChange = e => {
		const numberValue = Number(e.target.value)
		if (e.target.value === '' || (numberValue > 0 && numberValue <= 10)) {
			setConfigData(prevState => {
				const newState = cloneDeep(prevState)
				let coefficientValue = e.target.value
				if (coefficientValue.length > 4) {
					coefficientValue = coefficientValue.slice(0, 4)
				}
				const index = newState.findIndex(item => item.key === 'price_key_1')
				newState[index].value = coefficientValue
				newState[index].visible = !!coefficientValue
				updateQRSettingsField({
					...configData[index],
					value: coefficientValue,
					visible: !!coefficientValue,
				}).then(response => {
					if (response.error) {
						setConfigData(prevState)
					}
				})
				return newState
			})
		}
	}

	const handleSelect = (config, event) => {
		setConfigData(prevState => {
			const newState = [...prevState]
			const index = newState.findIndex(item => item.key === config.key)
			if (index === -1) return prevState
			newState[index].value = event.target.value
			updateQRSettingsField({
				...configData[index],
				value: event.target.value,
			})
			return newState
		})
	}

	const handleInputClick = () => {
		setInputPlaceholderVisible(false)
		inputRef.current.focus()
	}

	useEffect(() => {
		if (!configData?.[2]?.value) {
			setInputPlaceholderVisible(true)
		}
	}, [configData?.[2]?.value])

	const handlePrintingOptionsChange = async newPrintValue => {
		let initialData = null
		setConfigData(prevState => {
			initialData = cloneDeep(prevState)
			const index = configData.length - 1
			const newConfigData = cloneDeep(prevState)
			newConfigData[index] = newPrintValue
			return newConfigData
		})
		const postingData = {
			...newPrintValue,
			value: JSON.stringify(newPrintValue.value),
		}
		const result = await updateQRSettingsField(postingData)
		if (result.error) {
			setConfigData(initialData)
		}
	}

	const handleSizeChange = option => {
		setConfigData(prevState => {
			const newState = cloneDeep(prevState)
			const index = newState.findIndex(item => item.key === 'size')
			newState[index].value = option.value
			updateQRSettingsField({
				...configData[configData.length - 2],
				value: option.value,
			}).then(response => {
				if (response.error) {
					setConfigData(prevState)
				}
			})
			return newState
		})
	}

	return (
		<div className="flex flex-row justify-between mt-5">
			<div className={'flex-1'} style={{maxWidth: 434}}>
				<Typography variant="h6" sx={{fontWeight: 'bold'}}>
					Customization
				</Typography>
				{configData.map((config, index) => (
					<>
						{config.key !== 'size' && config.key !== 'printer_settings' && (
							<div className={'flex font-regular flex-row items-center justify-between mt-4'}>
								<div className={'flex items-center'}>
									<button
										onClick={() => {
											toggleShown(config)
										}}
										className={classNames('cursor-pointer text-[#cccccc] pl-3 pr-2', {'!text-[#5d6e81]': config.visible})}
									>
										{config.visible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
									</button>
									<p className={''} style={{fontSize: 14, paddingRight: 12}}>
										{qrFieldsConfig[config.key].label}
									</p>
								</div>

								{qrFieldsConfig[config.key].fieldType === 'select' && (
									<div className={'flex-1 relative'}>
										<select
											onChange={value => handleSelect(config, value)}
											className={'flex-1 border border-gray-300 pl-[12px] bg-transparent text-[10px] rounded-md pt-[3px]'}
											value={config.value}
											style={{height: 22}}
										>
											{(config.key === "price_key_3" ? price3Options :currentPriceOptions ).map(option => (
												<option value={option.value}>{option.label}</option>
											))}
										</select>
										{qrFieldsConfig[config.key].defaultValue === config.value && (
											<div className={'absolute z-0 left-[16px] top-[1px]'}>
												<p style={{lineHeight: '10px'}} className={'text-[6px] text-[#D1D6DB]'}>
													Default
												</p>
											</div>
										)}
									</div>
								)}
								{qrFieldsConfig[config.key].fieldType === 'numeric' && (
									<div className={'flex flex-row justify-between items-center'}>
										{qrFieldsConfig[config.key].subLabel && (
											<span className={'pr-3 '} style={{fontSize: 12}}>
												{qrFieldsConfig[config.key].subLabel}
											</span>
										)}
										<div className={'relative'}>
											<input
												ref={inputRef}
												onChange={handleCoeficientChange}
												value={config.value}
												type="text"
												className={'border h-[24px] pl-2 border-gray-300 rounded-md'}
												style={{width: 64, fontSize: 12}}
											/>
											{inputPlaceholderVisible && !config.value && (
												<div onClick={handleInputClick} className={'absolute left-0 top-0 w-full pt-[4px]'}>
													<p style={{lineHeight: '10px'}} className={'w-full text-center text-[8px]'}>
														Input a <br />
														number 1-10
													</p>
												</div>
											)}
										</div>
									</div>
								)}
							</div>
						)}
					</>
				))}
			</div>
			<PrinterAdjustments configData={configData} handleSizeChange={handleSizeChange} updatePrintingData={handlePrintingOptionsChange} setConfigData={setConfigData} />
		</div>
	)
}

export default QrStickerConfigList
