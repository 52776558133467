import React, { useMemo } from 'react'
import { useSelector} from 'react-redux'
import { Controller } from 'react-hook-form'
import {Globals} from '../../../../Context'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import SelectSalepersonDropdown from './SelectSalepersonDropdown'
import useGeneralSettings from './hooks/useGeneralSettings'
import { useEffect } from 'react'
import { addToast } from '../../../../app/appActions'
import { first } from 'lodash'

const MemoConfig = ( { control, triggerSubmit }) => {
  const { currentSpace, profile } = useSelector(state => ({currentSpace: state.currentSpace, profile: state.profile}))
  const { space_general_setting } = currentSpace
  const {
		loadedSpaceUsers,
    handleLoadUsers
	} = useGeneralSettings({addToast})
  const [visibleSalespersonOnMemo, setVisibleSalespersonOnMemo] = React.useState(space_general_setting.visible_salesperson_on_memo)
  const [selectedSalePersonId, setSelectedSalePersonId] = React.useState(space_general_setting.memo_saleperson_id)
  const [selectedPerson, setSelectedPerson] = React.useState({ first_name: '', last_name: '' })

  useEffect(() => {
    handleLoadUsers()
  }, [currentSpace])

  useEffect(() => {
    if (loadedSpaceUsers && loadedSpaceUsers.length > 0) {
      setSelectedPerson((loadedSpaceUsers || []).find(user => user.id === selectedSalePersonId))
    }
  }, [loadedSpaceUsers])

  return (
    <div className="mt-[17px]">
      {Globals.adminOrOwnerOrEditor(profile) &&
        <>
          <div className='flex items-center'>
            <div className="mx-6 text-[16px]">Do you want your salesperson visible on the memo?</div>
            <Controller
                name="visible_salesperson_on_memo"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby="visible-salesperson-on-memo-group"
                    sx={{
                      display: 'contents'
                    }}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      setVisibleSalespersonOnMemo(!visibleSalespersonOnMemo)
                      handleLoadUsers()
                      triggerSubmit()
                    }}
                    row
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label={
                        <Box component="div">
                          Yes
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" />}
                      label={
                        <Box component="div">
                          No
                        </Box>
                      }
                    />
                  </RadioGroup>
                )}
            />
            {visibleSalespersonOnMemo && (
              <>
                <Controller
                  name="memo_saleperson_id"
                  control={control}
                  defaultValue={selectedPerson}
                  render={({ field }) => (
                    <SelectSalepersonDropdown
                      disabled={!Globals.adminOrOwner(profile)}
                      userOptions={loadedSpaceUsers}
                      selectedUser={selectedPerson}
                      onSelect={(value) => {
                        field.onChange(value.id)
                        setSelectedSalePersonId(value ? value.id : null)
                        triggerSubmit()
                      }}
                      className={'bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] text-center min-w-[120px] truncate'}
                    />
                  )}
                />
              </>
            )}
          </div>
        </>
      }
    </div>
  );
};

export default MemoConfig
