import React from 'react'
import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'
import isEmpty from 'lodash/isEmpty'

const capitalizeFirstLetter = string => {
	if (typeof string !== 'string') return ''
	return `(${upperFirst(toLower(string))})`
}

const ContactInformation = ({singleCustomerView}) => {
	const InfoDeskop = ({title, detail, isPhoneType = false, phoneType, phoneCustomType}) => {
		return (
			<div className="grid grid-cols-2 gap-2 xl:grid md:grid-cols-[140px_max-content] md:gap-[102px] pb-[5px]">
				<div>
					<span className='font-poppins font-normal leading-[6px] text-[12px] text-[#5C6E81]'>{title}</span>
				</div>
				<div className='md:flex md:items-center'>
					<span className="font-poppins font-semibold leading-[6px] text-[12px] text-[#5C6E81] contents">{detail === '0' ? '' : detail}</span>
					{isPhoneType &&
						<div>
							<span className="ml-2 font-poppins font-semibold leading-[6px] text-[12px] text-[#5C6E81]">
								{!isEmpty(phoneCustomType) ? capitalizeFirstLetter(phoneCustomType) : capitalizeFirstLetter(phoneType) }
							</span>
						</div>
					}
				</div>
			</div>
		)
	}

	const InfoMobile = ({title, detail, isPhoneType = false, phoneType, phoneCustomType}) => {
		return (
			<div className="flex py-2 text-sm">
				<div className="w-[200px]">
					<label>{title}</label>
				</div>
				<div className="font-inter break-all whitespace-pre-line font-bold text-[#5C6E81]">{detail === '0' ? '' : detail}</div>
				{isPhoneType &&
				<span className="ml-2 font-semibold">
					{!isEmpty(phoneCustomType) ? capitalizeFirstLetter(phoneCustomType) : capitalizeFirstLetter(phoneType) }
				</span>
				}
			</div>
		)
	}

	const renderTextBillingAndShipping = (value) => {
		if(value.is_billing_default && value.is_shipping_default) {
			return 'Default Billing and Shipping'
		} else if(value.is_billing_default) {
			return 'Default Billing'
		} else if(value.is_shipping_default) {
			return 'Default Shipping'
		}
	}

	return (
			<>
				<div className="bg-white overflow-hidden md:hidden">
					<div className="single-product-details h-screen h-[calc(100vh-200px)] pb-10 overflow-y-scroll ">
						<h2 className="text-md font-bold tracking-wider py-2 border-b px-5">Personal Information</h2>
						<div className="w-max px-8 py-2">
							<InfoMobile title="Name" detail={`${singleCustomerView.first_name || ""} ${singleCustomerView.last_name || ""}`} />
							<InfoMobile title="Email" detail={singleCustomerView.email || ""} />
							{singleCustomerView.customer_phone_numbers.map((phone, index) => (
								<InfoMobile key={index} title={index === 0 ? 'Phone Number' : ''} detail={phone.phone_number_formatted} isPhoneType={true} phoneCustomType={phone.phone_custom_type} phoneType={phone.phone_type} />
							))}
							<InfoMobile title="Website" detail={singleCustomerView.website} />
							<InfoMobile title="Resale Certificate" detail={singleCustomerView.certificate} />
						</div>
						<h2 className="text-md font-bold tracking-wider py-2 border px-5">Address</h2>
						{singleCustomerView.customer_addresses.map((address, index) => (
							<div key={index}>
								<div className="w-max px-8 py-2">
									{address.company_name && <InfoMobile title="Company Name" detail={address.company_name || ""} />}
									{address.contact_name && <InfoMobile title="Contact Name" detail={address.contact_name || ""} />}
									{address.full_address && <InfoMobile title="Company Address" detail={address.full_address} />}
									{index === 0 && (
										<>
											{address.city && <InfoMobile title="City" detail={address.city} />}
											{address.state && <InfoMobile title="State" detail={address.state} />}
											{address.zip_code && <InfoMobile title="Zip Code" detail={address.zip_code} />}
										</>
									)}
									{address.phone_number_formatted && <InfoMobile title="Phone Number" detail={address.phone_number_formatted} />}
								</div>
								{singleCustomerView.customer_addresses.length > 1 && index < singleCustomerView.customer_addresses.length - 1 && <hr className="mx-8" />}
							</div>
						))}
						{!singleCustomerView.use_company_address_as_default && (
							<>
								<h2 className="text-md font-bold tracking-wider py-2 border px-5">Shipping Information</h2>
								<div className="w-max px-8 py-2">
									<InfoMobile title="Shipping Address" detail={singleCustomerView.shipping_address} />
									<InfoMobile title="City" detail={singleCustomerView.shipping_city} />
									<InfoMobile title="State" detail={singleCustomerView.shipping_state} />
									<InfoMobile title="Zip Code*" detail={singleCustomerView.shipping_zip_code} />
									<InfoMobile title="Country" detail={singleCustomerView.shipping_country} />
									<InfoMobile title="Phone Number" detail={singleCustomerView.shipping_phone_number_formatted} />
									<InfoMobile title="Secondary Number" detail={singleCustomerView.secondary_shipping_phone_number_formatted} />
								</div>
							</>
						)}
					</div>
				</div>
				<div className="hidden bg-white overflow-hidden md:block">
					<div className="single-product-details h-screen pb-40 overflow-y-scroll">
						<div className='pt-[28px] px-[48px]'>
							<span className="font-poppins font-bold text-[15px] leading-[6px] underline text-[#5D6E81]">Company Information</span>
						</div>
						<div className="px-[68px] pt-[10px] w-full md:w-1/2">
							<InfoDeskop title="Company Name" detail={singleCustomerView.company_name || ""} />
							<InfoDeskop title="Contact Name" detail={`${singleCustomerView.first_name || ""} ${singleCustomerView.last_name || ""}`} />
							<InfoDeskop title="Email" detail={singleCustomerView.email || ""} />
							{singleCustomerView.customer_phone_numbers.map((phone, index) => (
								<InfoDeskop key={index} title={index === 0 ? 'Phone Number' : ''} detail={phone.phone_number_formatted} isPhoneType={true} phoneCustomType={phone.phone_custom_type} phoneType={phone.phone_type} />
							))}
							<InfoDeskop title="Resale Certificate" detail={singleCustomerView.certificate} />
						</div>
						<div className='pt-[10px] px-[48px] text-[#5D6E81]'>
							<span className="font-poppins font-bold text-[15px] leading-[6px] underline">Address(es)</span>
							<div class="grid grid-cols-3 gap-4 pt-[22px]">
								{singleCustomerView.customer_addresses.map((address, index) => (
									<div
										key={index}
										className='flex flex-col justify-between border-[1px] max-w-[265px] md:h-[173px] 2xl:max-w-full border-[#5D6E8150] rounded-[5px]'
									>
										<div className="flex w-full px-[11px] flex-col">
											<p className=' font-semibold text-[12px] leading-[18px] break-words pt-[13px] min-h-[18px]'>{address.company_name || ""}</p>
											<p className='font-semibold text-[12px] leading-[18px] break-words mt-1 min-h-[18px]'>{address.contact_name || ""}</p>
											<p className='font-semibold text-[12px] leading-[18px] break-words mt-1 min-h-[18px]'>{address.full_address || ""}</p>
											<p className='font-semibold text-[12px] leading-[18px] break-words mt-1 min-h-[18px]'>{address.phone_number_formatted || ""}</p>
										</div>
										{address.is_billing_default || address.is_shipping_default ?
											<div className='flex w-full px-[11px] flex-col p-[11px]'>
												<span className='block font-semibold text-[12px] leading-[6px] text-[#4B7CBE]'>{renderTextBillingAndShipping(address)}</span>
											</div> : <></>
										}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</>

	)
}
export default ContactInformation
