import isNil from 'lodash/isNil'
import isBoolean from 'lodash/isBoolean'

export const toYesNo = (value, falseOnEmpty = false) => {
	if (falseOnEmpty && isNil(value)) {
		return false
	}

	if (isBoolean(value)) {
		if (value) {
			return 'Yes'
		} else {
			return 'No'
		}
	} else {
		return value
	}
}

export const getConditionLabel = key => {
	switch (key) {
		case 'unworn':
			return 'Unworn'
		case 'like_new':
			return 'Like New'
    case 'pre_owned':
      return 'Pre-Owned'
		case 'excellent':
			return 'Excellent'
		case 'very_good':
			return 'Very Good'
		case 'good':
			return 'Good'
		case 'fair':
			return 'Fair'
		default:
			return key
	}
}

export const formatText = (text) => {
	if (!text || text.trim() === '') {
		return text;
	}

	// Check if the original text ended with a period
	const endedWithPeriod = text.trim().endsWith('.');

	// Split by '.' and process each sentence
	const sentences = text.split('.').map(sentence => {
		sentence = sentence.trim().toLowerCase();

		// If the sentence isn't empty, capitalize its first letter
		if (sentence.length > 0) {
		sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);
		}

		return sentence;
	});

	// Join the processed sentences with '. ' and re-add the trailing period if needed
	let formatted = sentences.join('. ');
	if (endedWithPeriod) {
		formatted += '.';
	}

	return formatted;
}