import React, {useEffect, useState} from 'react'
import {Globals} from '../Context'
import {useParams} from 'react-router'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import isNull from 'lodash/isNull'
import findIndex from 'lodash/findIndex'
import isUndefined from 'lodash/isUndefined'
import head from 'lodash/head'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

const StyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1.5, 4),
    fontSize: '1rem',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0px 4px 6px rgba(0,0,0,0.3)',
    },
    '&.MuiButton-containedPrimary': {
        backgroundColor: '#2563eb',
        '&:hover': {
            backgroundColor: '#1d4ed8',
        },
    },
}));

const InventoryItemShare = () => {
	const [item, setItem] = useState()
	const {id} = useParams()
	const [buildColumn, setColumn] = useState([])
	const [loading, setLoading] = useState(true)

	const loadData = () => {
		const urlParams = new URLSearchParams(window.location.search)
		let type = 'uid'
		if (!isNull(urlParams.get('share'))) {
			type = 'sharing_code'
		}
		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/marketplaces/shared_watch?${type}=${id}`)
			.then(async response => {
				setItem(response.data)
				const columns = [
					{key: 'sku', title: 'SKU'},
					{key: 'wholesale_price', title: 'Price'},
					{key: 'reference_number', title: 'Reference Number'},
					{key: 'brand', title: 'Brand'},
					{key: 'series', title: 'Series'},
					{key: 'serial_number', title: 'Serial Number'},
					{key: 'condition', title: 'Condition'},
					{key: 'dial', title: 'Dial'},
					{key: 'bezel', title: 'Bezel'},
					{key: 'bracelet', title: 'Bracelet'},
					{key: 'warranty_papers', title: 'Warranty Paper'},
					{key: 'warranty', title: 'Warranty Date'},
					{key: 'box', title: 'Box'},
					{key: 'link_count', title: 'Link Count'},
					{key: 'addition_details', title: 'Additional Details'},
				]
				let customColumn = []
				if(type === 'sharing_code') {
					response.data.selected_attributes.map(key => {
						const index = findIndex(columns, (value => value.key === key))
						if(index > -1) {
							customColumn.push(columns[index])
						}
						setColumn(customColumn)
					})
				} else {
					setColumn(columns)
				}
			}).catch(error => {
				console.error('Error:', error)
			}).finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		loadData()
	}, [])

	const buildData = (item, column) => {
		if(column.key === 'box') {
			return item[column.key] ? 'Yes' : 'No'
		} else if (column.key === 'wholesale_price') {
			return item[column.key] ? `$${Globals.readCurrencyNumber(item[column.key])}` : ''
		} else {
			return item[column.key]
		}
	}

	return (
		<React.Fragment>
			{loading && (
				<div className="flex justify-center items-center h-screen">
				<Box textAlign="center">
					<CircularProgress color="primary" />
					<Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
					<Typography variant="body2" color="textSecondary">We're fetching the latest data for you. Hang tight!</Typography>
				</Box>
			</div>
			)}

			{!loading && (
				<React.Fragment>
					{!isUndefined(item) && (
						<table className='max-w-full w-full'  role="presentation" border="0" cellPadding="0" cellSpacing="0" width="100%">
							<tbody>
								<tr>
									<td>
										<table className='md:w-[600px] w-full' align="center" border="0" cellPadding="0" cellSpacing="0" style={{borderCollapse: 'collapse', border: '1px solid #cccccc'}}>
											<tbody>
												<tr>
													<td align="left" className='flex p-1.5'>
													<div className='flex items-center md:h-[100px] h-[66px] w-full'>
														{item.space?.logo?.url && (
															<img
																src={item.space?.logo?.url}
																className='h-full max-h-[77px] max-w-[30%]'
																style={{ display: 'block', maxWidth: "30%", objectFit: 'contain' }}
															/>
														)}
														<div className='flex justify-center items-center pl-2 ml-1 font-semibold text-[22px] text-[#000000]'>
															<span style={{lineHeight: 1.2}} >{item.space.name}</span>
														</div>
													</div>
													</td>
												</tr>
												<tr>
													<td bgcolor="#ffffff">
														<table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{borderCollapse: 'collapse'}}>
															<tbody>
																<tr>
																	<td>
																		<table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{borderCollapse: 'collapse'}}>
																			<tr>
																				<td width="260" valign="top">
																					<table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{borderCollapse: 'collapse'}}>
																						<tbody>
																							<tr>
																								<td className='flex justify-center pt-3'>
																									<img
																										onClick={() =>
																											head(item.images)?.url ? window.location.href = `/inventory/share/gallery/${item.uid}` : ''
																										}
																										src={head(item.images)?.url ? head(item.images)?.url : `/watch_icon.png`}
																										width="60%"
																										style={{display: 'block'}}
																										className='cursor-pointer'
																									/>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</td>
																			</tr>
																		</table>
																	</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
												<tr>
													<td bgcolor="#ffffff" style={{padding: '20px 30px 40px 30px'}}>
														<table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{borderCollapse: 'collapse'}}>
															<tbody>
																<tr>
																	{
																		head(item.images)?.url ?
																			<td align="center" className='text-[#000000] font-semibold' style={{fontFamily: 'Arial, sans-serif'}}>
																				{<a href={`/inventory/share/gallery/${item.uid}`}>
																					<p className='text-[15px] md:text-[24px]' style={{margin: '0', textDecoration: 'underline'}}>View More Images</p>
																				</a>}
																			</td> : ''
																	}
																</tr>
																<tr>
																	<td>
																		<table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{borderCollapse: 'collapse'}}>
																			<tbody>
																				{buildColumn.map((column, index) => (
																					<tr className='grid grid-cols-2 gap-4' key={index}>
																						<td
																							className='text-[15px] md:text-[16px] font-normal text-[#5D6E81] md:pt-[25px] pt-[10px] leading-8'
																							style={{
																								fontFamily: 'Arial, sans-serif',
																							}}
																						>
																							<p style={{margin: '0'}}>{column.title}:</p>
																						</td>
																						<td
																							className='font-semibold text-[15px] md:text-[16px] leading-8 md:pt-[25px] pt-[10px]'
																							style={{
																								color: '#153643',
																								fontFamily: 'Arial, sans-serif',
																							}}
																						>
																							<p style={{margin: '0'}}>
																								{buildData(item, column)}
																							</p>
																						</td>
																					</tr>
																				))}
																				<tr key={58225}>
																					<td className='w-full'>
																							<div
																								className='w-full flex justify-end items-center mt-[20px]'
																							>
																								<div className='flex  bg-[#5D6E811A] p-1 rounded-[10px]'>
																									<span className='text-[10px] font-semibold leading-[15px]' >Powered By</span>
																									<img className='h-[16px]'  src="/Images/logo_footer.png"/>
																								</div>
																							</div>
																					</td>
																				</tr>

																				{/* Add more rows with similar structure for other details */}
																			</tbody>
																		</table>
																	</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
												<tr>
													<td style={{padding: '0px 30px'}}>
														<table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{borderCollapse: 'collapse'}}>
															<tbody>
																<tr>
																	<td align="center">
																		<a href="#"></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
												{/* <tr>
													<td style={{padding: '30px 30px'}}>
														<table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{borderCollapse: 'collapse'}}>
															<tr>
																<td style={{fontFamily: 'Arial, sans-serif', fontSize: '14px'}}>
																	<p style={{margin: '0'}}>&reg; Copyright Elefta. All Rights Reserved.</p>
																</td>
															</tr>
														</table>
													</td>
												</tr> */}
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
					)}
					{isUndefined(item) && (
						<Dialog open={true}>
						<DialogTitle className="flex justify-center items-center">
							<LockOutlinedIcon className="mr-2 text-blue-500" />
							This page does not exist
						</DialogTitle>
						<DialogContent className="flex flex-col items-center">
							<Typography variant="body2" color="textSecondary">
								The link you are trying to access does not exist or expired.
							</Typography>
						</DialogContent>
						<DialogActions className="!flex !justify-around p-4 w-full mb-4">
							<StyledButton href="/" color="primary" variant="contained">
								Go to home
							</StyledButton>
						</DialogActions>
					</Dialog>
					)}
				</React.Fragment>
			)}

		</React.Fragment>
	)
}
export default InventoryItemShare
